import {
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Theme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getElement } from "ionicons/dist/types/stencil-public-runtime";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../../config/locations";
import {
    getBrandsByCategory,
    getCities,
    getDistricts,
    getModelsByBrand,
    getYears,
    selectBrands,
    selectCity,
    selectCurrentStep,
    selectDistrict,
    selectedCategories,
    selectLocation,
    selectModels,
    selectYears,
} from "../../../../../../features/addPost/addPostSlice";
import { currencyConverter } from "../../../../../../features/helper";
import {
    addNewPostDesciption,
    addNewPostDistrict,
    addNewPostPrice,
    addNewPostTitle,

    selectNewPosts,
    setHideFooter,
} from "../../../../../../features/post/postSlice";
import { setSubCatMandatory, setValuesMandatory } from "../../../FillDetails";
import { apartment_rent, apartment_sale, audio_speakers, bikes, cameras, cars, commercial_rent, commercial_sale, computers, daily, house_rent, house_sale, land_rent, land_sale, mobiles, mobile_parts, monthly, other_electronics, room_rent, tab, tv } from "../../../../../../constants/categories";
import { selectLanguage } from "../../../../../../features/language/language_slice";
import { Language } from "../../../../../../language/Language";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        borderColor: {
            "& .MuiInput-underline:before": {
                // borderBottom: "1px solid #DDDDDD",
                borderColor: "#DDDDDD"
            },
            "&:hover .MuiInput-underline": {
                borderColor: "#0080FF"
            },
        },
        autoComplete: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DDDDDD"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#0080FF"
            },
        },
        textField: {

        }
    })
);

const FillDetailsCars = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selctedCat = useSelector(selectedCategories);
    const locs = useSelector(selectLocations);
    const brands = useSelector(selectBrands);
    const models = useSelector(selectModels);
    const district = useSelector(selectDistrict);
    const years = useSelector(selectYears);
    const newPost = useSelector(selectNewPosts);
    const [values, setValues] = useState({} as any);

    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;



    const condition: any = [
        { id: 0, name: "Brand New", label: lan.BRAND_NEW },
        { id: 1, name: "Registered", label: lan.REGISTERED },
        { id: 2, name: "Unregistered", label: lan.UNREGISTERED },
    ];
    const transmission: any = [
        { id: 0, name: "Automatic", label: lan.AUTOMATIC },
        { id: 1, name: "Manual", label: lan.MANUAL },
        { id: 2, name: "Tiptronic", label: lan.TIPTONIC },
    ];
    const [year, setYear] = useState();
    const [selectedCondition, setSelectedCondition] = useState();
    const [selectedTransmission, setSelectedTransmission] = useState();
    const [mileage, setMileage] = useState<any>(0);
    const cities = useSelector(selectCity);
    const [sugesstedTitile, setsugesstedTitile] = useState({} as any);


    useEffect(() => {
        dispatch(getDistricts());
        dispatch(getYears());
        dispatch(getBrandsByCategory(selctedCat.level2.id));


    }, []);

    useEffect(() => {
        const brand = sugesstedTitile?.brand ? sugesstedTitile.brand : '';
        const model = sugesstedTitile?.model ? sugesstedTitile.model : '';
        const year = sugesstedTitile?.year ? sugesstedTitile.year : '';

        if (model?.split(' ')?.length > 1 && brand == model.split(' ')[0]) {
            const title = brand + ' ' + model.split(' ')[1] + ' ' + year;
            dispatch(addNewPostTitle(title));

        } else {
            const title = brand + ' ' + model + ' ' + year;
            dispatch(addNewPostTitle(title));
        }

    }, [sugesstedTitile]);


    const onBrandSelect = (brand: any) => {
        setValues((v: any) => {
            return { ...v, brand: brand };
        });
        dispatch(getModelsByBrand(brand.id, selctedCat.level2.id));
        setValues((v: any) => {
            return { ...v, model: "" };
        });
        setsugesstedTitile((v: any) => {
            return { ...v, brand: brand.name.charAt(0).toUpperCase() + brand.name.slice(1).toLowerCase() };
        });

    };
    const onModelSelect = (model: any) => {
        setValues((v: any) => {
            return { ...v, model: model };
        });
        setsugesstedTitile((v: any) => {
            return { ...v, model: model.name.charAt(0).toUpperCase() + model.name.slice(1).toLowerCase() };
        });
    };
    const onDistrictSelect = (location: any) => {


        dispatch(getCities(location.id, location.name));
        setValues((v: any) => {
            return { ...v, district: location };
        });



    };
    const onCitySelect = (location: any) => {

        setValues((v: any) => {
            return { ...v, city: location };
        });
        (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

    };

    const onYearsSelect = (years: any) => {
        setYear(years);
        setValues((v: any) => {
            return { ...v, years: years };
        });
        setsugesstedTitile((v: any) => {
            return { ...v, year: years.name };
        });
    };

    const onConditionSelect = (condition: any) => {
        setSelectedCondition(condition);
        setValues((v: any) => {
            return { ...v, condition: condition };
        });
    };
    const onTransmissionSelect = (transmission: any) => {
        setSelectedTransmission(transmission);
        setValues((v: any) => {
            return { ...v, transmission: transmission };
        });
    };
    const isSavedDistrict = () => {

        if (Object.keys(locs).length > 0) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
                    </div>
                    <Autocomplete
                        // className={classes.autoComplete}
                        id="district"
                        options={district}
                        disableClearable
                        onChange={(event: any, district: any) => {
                            onDistrictSelect(district);

                        }}
                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                        getOptionLabel={(option: any) => option.name}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                            <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
                        )}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
                    </div>
                    <Autocomplete
                        // className={classes.autoComplete}
                        id="city"
                        options={cities}
                        disableClearable
                        disabled={!values.district}

                        onChange={(event: any, location: any) => {
                            onCitySelect(location);

                        }}

                        getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                        style={{ width: "100%" }}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="district"
                                label={lan.CITY}
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-brand-ad-post.svg" className="filldetails-icon"></img>
                    </div>
                    <Autocomplete
                        // className={classes.autoComplete}
                        id="brands"
                        disableClearable
                        options={brands}
                        onChange={(event: any, brand: any) => {
                            onBrandSelect(brand);
                        }}
                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                        getOptionLabel={(option: any) => option.name}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={lan.BRAND_NAME}
                                name="Cars_Brand"
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-model-16x16.svg" className="filldetails-icon"></img>
                    </div>
                    <Autocomplete
                        // className={classes.autoComplete}
                        id="models"
                        options={models}
                        disableClearable
                        disabled={!values.brand}
                        onChange={(event: any, model: any) => {
                            onModelSelect(model);
                        }}
                        getOptionLabel={(option: any) => option.name}
                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={lan.MODEL}
                                name="Cars_Model"
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-year-ad-post.svg" className="filldetails-icon"></img>
                    </div>
                    <Autocomplete
                        // className={classes.autoComplete}
                        id="year"
                        options={years}
                        disableClearable
                        onChange={(event: any, year: any) => {
                            onYearsSelect(year);
                        }}
                        getOptionLabel={(option: any) => option.name}
                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={lan.MODEL_YEAR}
                                name="Year"
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-km-18x14.svg" className="filldetails-icon"></img>
                    </div>
                    <TextField
                        id="filled-number"
                        label={lan.MILEAGE}
                        name="Motors_Mileage"
                        placeholder="10000"
                        type="text"
                        required={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ pattern: "^\\d{1,7}$" }}
                        variant="outlined"
                        value={mileage || ""}
                        style={{ width: "100%" }}
                        onChange={(e: any) => {
                            setMileage(e.target.value);
                            setSubCatMandatory(selctedCat.level2.name);
                            setValuesMandatory(
                                { "Mileage": e.target.value }
                            )

                        }
                        }
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    className="filldetails-radio-grid-item"
                    style={{ paddingLeft: "16px", paddingTop: "5px" }}
                >
                    <div className="filldetails-radio-icon-wrapper">
                        <img src="assets/addpost/icon-condition-ad-post.svg" className="filldetails-icon"></img>
                    </div>
                    <FormControl component="fieldset" id="condition">
                        <FormLabel component="legend">{lan.CONDITION}</FormLabel>
                        <RadioGroup
                            aria-label={lan.CONDITION}
                            name="Motor_Condition"
                            value={selectedCondition || ""}
                            onChange={(e: any) => onConditionSelect(e.target.value)}
                        >
                            {condition.map((item: any) => (
                                <FormControlLabel
                                    value={item.name}
                                    key={item.id}
                                    control={<Radio color="primary" size="small" required />}
                                    label={item.label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={6}
                    className="filldetails-radio-grid-item"
                    style={{ paddingLeft: "16px", paddingTop: "5px" }}
                >
                    <div className="filldetails-radio-icon-wrapper">
                        <img src="assets/addpost/icon-gear-type-16x16.svg" className="filldetails-icon"></img>
                    </div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            {/* Transmission */}
                            {lan.TRANSMISSION}
                        </FormLabel>
                        <RadioGroup
                            aria-label={lan.CONDITION}
                            name="Motors_Transmission"
                            value={selectedTransmission}
                            onChange={(e: any) => onTransmissionSelect(e.target.value)}
                        >
                            {transmission.map((item: any) => (
                                <FormControlLabel
                                    value={item.name}
                                    key={item.id}
                                    control={<Radio color="primary" size="small" required />}
                                    label={item.label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className="filldetails-grid-item">
                    <div className="filldetails-fullwidth-icon-wrapper">
                        <img src="assets/addpost/icon-title-ad-post.svg" className="filldetails-icon"></img>
                    </div>
                    <TextField
                        id="title"
                        label={lan.TITTLE}
                        placeholder={lan.MAX_72_CHARACTERS}
                        name="title"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        inputProps={{ maxLength: 72 }}
                        variant="outlined"
                        value={newPost.title || ''}
                        style={{ width: "100%", textTransform: "capitalize" }}
                        onChange={(e: any) => dispatch(addNewPostTitle(e.target.value))}
                        onInput={(e: any) => dispatch(addNewPostTitle(e.target.value))}
                    />
                </Grid>
                <Grid item xs={12} className="filldetails-grid-item">
                    <TextField
                        id="description"
                        // label="Description"
                        label={lan.DESCRIPTION}

                        multiline
                        placeholder={lan.MAX_4000_CHARACTERS}
                        name="description"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={newPost.description || ""}
                        inputProps={{ maxLength: 4000 }}
                        style={{ width: "100%" }}
                        onChange={(e: any) =>
                            dispatch(addNewPostDesciption(e.target.value))
                        }
                        onInput={(e: any) => dispatch(addNewPostDesciption(e.target.value))}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    <div className="filldetails-icon-wrapper">
                        <img src="assets/addpost/icon-price-ad-post.svg" className="filldetails-icon"></img>
                    </div>
                    <TextField
                        id="price"
                        label={lan.PRICE}
                        placeholder="2500000"
                        name="price"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        variant="outlined"
                        value={newPost.price || ""}
                        inputProps={{ pattern: "^\\d{1,12}(\\.\\d{1,2})?$", maxLength: 15 }}
                        style={{ width: "100%" }}
                        onChange={(e: any) => dispatch(addNewPostPrice(e.target.value))}
                        onInput={(e: any) => dispatch(addNewPostPrice(e.target.value))}
                    />
                </Grid>
                <Grid item xs={6} className="filldetails-grid-item">
                    {
                        (newPost.price == 0) ?
                            <span className="price" >{lan.ASK_FOR_PRICE}</span>
                            :
                            <div color="primary" className="price">
                                <span className="ru-in-price">{lan.RS}. </span>{" "}
                                {newPost?.price === "" || !isNaN(newPost?.price)
                                    ? currencyConverter().format(newPost?.price)
                                    : "Invalid"}
                            </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
};
export default FillDetailsCars;
