import { Button, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { image } from "ionicons/icons";
import React from "react";
import Carousel from 'react-gallery-carousel';
// import { Carousel } from 'react-responsive-carousel';
import { GET_SPOTLIGHTS } from '../../../config/urls';



import 'react-gallery-carousel/dist/index.css';
import MyCarousel from "./MyCarousel";
import { useSelector } from "react-redux";
import { selectSpotlights } from "../../../features/post/postSlice";
import { selectSpotlightsSearch } from "../../../features/search/searchSlice";
import { useLocation } from "react-router-dom";

export default function Spotlight() {
    const location = useLocation();
    const items = useSelector(selectSpotlights);
    const itemsSearch = useSelector(selectSpotlightsSearch);
    // , border: (items !== null || items !== "" || itemsSearch !== null || itemsSearch !== "" ? "1px solid #0080FF" : "1px solid #0080FF")
    return (
        <div style={{ marginLeft: "5px", marginRight: "5px", marginBottom: "5px", marginTop: "5px", border: "1px solid #0080FF" }}>
            {/* <Carousel playIcon={null} pauseIcon={null} hasThumbnailsAtMax={false} hasThumbnails={false} maxIcon={false} hasIndexBoard={false} isAutoPlaying={true} images={images}  style={{ height: 300, width: '100%' }}  /> */}
            {
                (location.pathname == '/home' ? <MyCarousel items={items} /> : <MyCarousel items={itemsSearch} />)

            }
        </div>
    );


    //     return(
    //         <Example></Example>

    //     );

    //     function Example(props:any)
    // {
    //     var items = [
    //         {
    //             name: "Random Name #1",
    //             description: "Probably the most random thing you have ever seen!"
    //         },
    //         {
    //             name: "Random Name #2",
    //             description: "Hello World!"
    //         }
    //     ]

    //     return (
    //         <Carousel >
    //             {
    //                 items.map( (item, i) => <Item key={i} item={item} /> )
    //             }
    //         </Carousel>
    //     )
    // }

    // function Item(props:any)
    // {
    //     return (
    //         <Paper>
    //             <h2>{props.item.name}</h2>
    //             <p>{props.item.description}</p>


    //         </Paper>

    //     )
    // }
}