// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fd-avatar-details{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: left;
    border: 1px solid #DDDDDD;
    overflow: hidden;

}
#fd-avatar-icon-details{
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

#fd-meta-author-wrapper{
    font-size: 14px;
    line-height: 18px;
    color:#5A5A5A;
    font-weight: 400;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

#fd-meta-author-wrapper > span{
    font-weight: 400;
    letter-spacing: 0.5px;
}
#fd-meta-author-wrapper > span::first-letter{
    text-transform: capitalize;
}

#auther-container{
    height: 60px;
    border-bottom: 1px solid #DDDDDD;
    cursor: pointer;
}
#see-seller{
    height: 30px;
    display: flex;
    align-items: center;
}
#avatar-wrap{
    padding-left: 11px;
    padding-top: 10px;
    display: flex;
}

#txt-postedby{
font-size: 12px;
color: #5A5A5A;
font-weight: 400;
display: flex;
align-items: center;
}
#txt-postedby-name{
font-size: 14px;
font-weight: 600 !important;
color: #5A5A5A;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailsSideBar/DetailsSideBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;;AAEpB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,gCAAgC;IAChC,eAAe;AACnB;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;AACA,eAAe;AACf,cAAc;AACd,gBAAgB;AAChB,aAAa;AACb,mBAAmB;AACnB;AACA;AACA,eAAe;AACf,2BAA2B;AAC3B,cAAc;AACd,mBAAmB;AACnB,gBAAgB;AAChB,uBAAuB;AACvB,gBAAgB;AAChB","sourcesContent":["#fd-avatar-details{\n    width: 35px;\n    height: 35px;\n    border-radius: 50%;\n    text-align: left;\n    border: 1px solid #DDDDDD;\n    overflow: hidden;\n\n}\n#fd-avatar-icon-details{\n    width: 40px;\n    height: 40px;\n    border-radius: 100%;\n}\n\n#fd-meta-author-wrapper{\n    font-size: 14px;\n    line-height: 18px;\n    color:#5A5A5A;\n    font-weight: 400;\n    margin-left: 10px;\n    display: flex;\n    flex-direction: column;\n}\n\n#fd-meta-author-wrapper > span{\n    font-weight: 400;\n    letter-spacing: 0.5px;\n}\n#fd-meta-author-wrapper > span::first-letter{\n    text-transform: capitalize;\n}\n\n#auther-container{\n    height: 60px;\n    border-bottom: 1px solid #DDDDDD;\n    cursor: pointer;\n}\n#see-seller{\n    height: 30px;\n    display: flex;\n    align-items: center;\n}\n#avatar-wrap{\n    padding-left: 11px;\n    padding-top: 10px;\n    display: flex;\n}\n\n#txt-postedby{\nfont-size: 12px;\ncolor: #5A5A5A;\nfont-weight: 400;\ndisplay: flex;\nalign-items: center;\n}\n#txt-postedby-name{\nfont-size: 14px;\nfont-weight: 600 !important;\ncolor: #5A5A5A;\nwhite-space: nowrap;\noverflow: hidden;\ntext-overflow: ellipsis;\nmax-width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
