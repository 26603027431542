import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { selectJumpAdValues } from './AddOnValues';
import { setJumpAd, setAddOnCodesJumpAd, selectJumpAd } from '../features/addOnServices';
import { BorderRight } from '@material-ui/icons';


const useStyles = makeStyles({

    root: {
        minWidth: 275,
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none",
        padding: 0,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 12
    },

    large: {
        // width: theme.spacing(7),
        // height: theme.spacing(7),
    },
    jumpAd: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    f3d: {
        fontSize: 12,
    },
    rs200: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    lableTitle: {
        fontSize: 12,

    },
    lablePrice: {
        fontSize: 15,
        fontWeight: 500
    },
    descp: {
        fontSize: 14
    },
    promosPrice:{

    },
    promosNames:{

    }
});

export default function JumpAd(props: any) {

    const classes = useStyles();
    const JumpAdValues = useSelector(selectJumpAdValues);

    const dispatch = useDispatch();


    const JumpAd = useSelector(selectJumpAd);
    const [day3, setDay3] = useState(false);
    const [day7, setDay7] = useState(false);
    const [day15, setDay15] = useState(false);


    function isJA() {
        if (JumpAd == 0) {
            return false;
        }
        return true;
        // if (JumpAd == 1) {
        //     return true;
        // }
        // return false;
    }



    const isJumpAdValueNull = () => {
        if (JumpAdValues.length > 0) {
            return false;
        }
        return true;
    }
    const setJumpAdValue = (price: string) => {
        // dispatch(setJumpAd(parseInt(price)));
        dispatch(setJumpAd(parseInt(price)));
    }
    const setJumpAdCode = (code: string) => {
        dispatch(setAddOnCodesJumpAd(code));
    }


    return (
        <Card className={classes.root} variant="outlined" >
            <CardContent>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} md={12} lg={5}>
                        <Grid container xs={12}>
                            <Grid item xs={3} md={3} lg={2}>

                                <Avatar alt="Bundle Pack" src="../../assets/addOn/icon-jump-up.svg" className={classes.large} />
                            </Grid>
                            <Grid item xs={9} md={9} lg={10}>
                                <div >
                                    <Typography className={classes.jumpAd} >
                                        Jump Ad
                                    </Typography>
                                    <Typography className={classes.descp}>
                                        To get a 10 times more responses by giving your ad a fresh start.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={12} md={12} lg={6}>
                        <Grid container xs={12}>
                            {/* <Grid item xs={12} >
                                <Grid container direction="row" style={{ justifyContent: "center" }}> */}
                            <FormControl component="fieldset" disabled={(props.available)}>

                                <RadioGroup id="jumpRadioGroup" row aria-label="position" name="position" defaultValue="top">
                                    <Grid item xs={4}>
                                        <FormControlLabel checked={(isJA() ? (day3 ? true : false) : false)} value="for3days" style={(String(isJumpAdValueNull() ? "450" : JumpAdValues[0].price).length >= 6 ? { paddingRight: 20 } : { paddingRight: 55 })} control={<Radio color="primary" />}

                                            onChange={() => {
                                                setDay3(true);
                                                setDay7(false);
                                                setDay15(false);
                                                setJumpAdValue((isJumpAdValueNull() ? "450" : JumpAdValues[0].price));
                                                setJumpAdCode(JumpAdValues[0].code);
                                            }
                                            }

                                            label={
                                                <div>

                                                    {

                                                        (isJumpAdValueNull() ?
                                                            <Typography className={classes.lablePrice} >
                                                                Rs. 19950
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.lablePrice} >
                                                                Rs.{JumpAdValues[0].price}

                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography className={classes.lableTitle}>
                                                        For 3 days
                                                    </Typography>


                                                </div>
                                            }

                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <FormControlLabel checked={(isJA() ? (day7 ? true : false) : false)} value="for7days" style={(String(isJumpAdValueNull() ? "450" : JumpAdValues[1].price).length >= 6 ? { paddingRight: 20 } : { paddingRight: 20, marginRight: 10 })} control={<Radio color="primary" />}

                                            onChange={() => {
                                                setDay3(false);
                                                setDay7(true);
                                                setDay15(false);
                                                setJumpAdValue((isJumpAdValueNull() ? "650" : JumpAdValues[1].price))
                                                setJumpAdCode(JumpAdValues[1].code);
                                                // setDay3(false);
                                                // setDay7(false);
                                                // setDay15(false);
                                                // setJumpAdValue((isJumpAdValueNull() ? "650" : JumpAdValues[1].price))
                                                // setJumpAdCode(JumpAdValues[1].code);

                                            }
                                            }

                                            label={
                                                <div>

                                                    {

                                                        (isJumpAdValueNull() ?
                                                            <Typography className={classes.lablePrice} >
                                                                Rs. 650
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.lablePrice} >
                                                                Rs.{JumpAdValues[1].price}
                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography className={classes.lableTitle}>
                                                        For 7 days
                                                    </Typography>
                                                </div>
                                            } />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel checked={(isJA() ? (day15 ? true : false) : false)} value="for15days" control={<Radio color="primary" />}
                                            onChange={() => {
                                                setDay3(false);
                                                setDay7(false);
                                                setDay15(true);
                                                setJumpAdValue((isJumpAdValueNull() ? "900" : JumpAdValues[2].price))
                                                setJumpAdCode(JumpAdValues[2].code);
                                                // setDay3(false);
                                                // setDay7(false);
                                                // setDay15(false);
                                                // setJumpAdValue((isJumpAdValueNull() ? "900" : JumpAdValues[2].price))
                                                // setJumpAdCode(JumpAdValues[2].code);

                                            }
                                            }

                                            label={
                                                <div>
                                                    {

                                                        (isJumpAdValueNull() ?
                                                            <Typography className={classes.lablePrice} >
                                                                Rs. 900
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.lablePrice} >
                                                                Rs.{JumpAdValues[2].price}
                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography className={classes.lableTitle}>
                                                        For 15 days
                                                    </Typography>
                                                </div>
                                            } />
                                    </Grid>
                                    {/* <FormControlLabel checked={(isJA() ? (day3 ? true : false) : false)} value="for3days" style={{ paddingRight: 20 }} control={<Radio color="primary" />}

                                                onChange={() => {
                                                    setDay3(true);
                                                    setDay7(false);
                                                    setDay15(false);
                                                    setJumpAdValue((isJumpAdValueNull() ? "450" : JumpAdValues[0].price));
                                                    setJumpAdCode(JumpAdValues[0].code);
                                                }
                                                }

                                                label={
                                                    <div>
                                                        <Typography className={classes.lableTitle}>
                                                            For 3 days
                                                        </Typography>


                                                        {

                                                            (isJumpAdValueNull() ?
                                                                <Typography className={classes.lablePrice} >
                                                                    Rs. 19950
                                                                </Typography>
                                                                :
                                                                <Typography className={classes.lablePrice} >
                                                                    Rs.{JumpAdValues[0].price}

                                                                </Typography>
                                                            )
                                                        }



                                                    </div>
                                                }

                                            />
                                            <FormControlLabel checked={(isJA() ? (day7 ? true : false) : false)} value="for7days" style={{ paddingRight: 20 }} control={<Radio color="primary" />}

                                                onChange={() => {
                                                    setDay3(false);
                                                    setDay7(true);
                                                    setDay15(false);
                                                    setJumpAdValue((isJumpAdValueNull() ? "650" : JumpAdValues[1].price))
                                                    setJumpAdCode(JumpAdValues[1].code);
                                                    // setDay3(false);
                                                    // setDay7(false);
                                                    // setDay15(false);
                                                    // setJumpAdValue((isJumpAdValueNull() ? "650" : JumpAdValues[1].price))
                                                    // setJumpAdCode(JumpAdValues[1].code);

                                                }
                                                }

                                                label={
                                                    <div>
                                                        <Typography className={classes.lableTitle}>
                                                            For 7 days
                                                        </Typography>
                                                        {

                                                            (isJumpAdValueNull() ?
                                                                <Typography className={classes.lablePrice} >
                                                                    Rs. 650
                                                                </Typography>
                                                                :
                                                                <Typography className={classes.lablePrice} >
                                                                    Rs.{JumpAdValues[1].price}
                                                                </Typography>
                                                            )
                                                        }
                                                    </div>
                                                } />
                                            <FormControlLabel checked={(isJA() ? (day15 ? true : false) : false)} value="for15days" style={{ paddingRight: 20 }} control={<Radio color="primary" />}
                                                onChange={() => {
                                                    setDay3(false);
                                                    setDay7(false);
                                                    setDay15(true);
                                                    setJumpAdValue((isJumpAdValueNull() ? "900" : JumpAdValues[2].price))
                                                    setJumpAdCode(JumpAdValues[2].code);
                                                    // setDay3(false);
                                                    // setDay7(false);
                                                    // setDay15(false);
                                                    // setJumpAdValue((isJumpAdValueNull() ? "900" : JumpAdValues[2].price))
                                                    // setJumpAdCode(JumpAdValues[2].code);

                                                }
                                                }

                                                label={
                                                    <div>
                                                        <Typography className={classes.lableTitle}>
                                                            For 15 days
                                                        </Typography>
                                                        {

                                                            (isJumpAdValueNull() ?
                                                                <Typography className={classes.lablePrice} >
                                                                    Rs. 900
                                                                </Typography>
                                                                :
                                                                <Typography className={classes.lablePrice} >
                                                                    Rs.{JumpAdValues[2].price}
                                                                </Typography>
                                                            )
                                                        }
                                                    </div>
                                                } /> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {/* </Grid>
                </Grid> */}


            </CardContent>

        </Card>
    );
}


