import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  MARK_SOLD,
  REPOST,
} from "../../config/urls";
import { setBusy } from "../navigation/navigationSlice";

export const slice = createSlice({
  name: "search",
  initialState: {},

  reducers: {},
});

export const {} = slice.actions;

export const markSold = (hash: string, callback: Function, error: any) => (
  dispatch: any
) => {
  dispatch(setBusy(true));
  axios
    .post(MARK_SOLD ,hash )
    .then((response: any) => {
      //console.log("in callback sold 1");
      //if (response.data.response) {
      if (response) {
        //console.log("in callback sold");
        callback(response.data.next);
      }
    })
    .catch((err) => error(err))
    .finally(() => dispatch(setBusy(false)));
};

export const repost = (hash: string, callback: Function, error: any) => (
  dispatch: any
) => 
{
  dispatch(setBusy(true));
  axios
    .patch(REPOST + hash)
    //.post(REPOST + hash)
    .then((response: any) => {
      if (response.data.response) {
        callback();
      }
    })
    .catch((err) => error(err))
    .finally(() => dispatch(setBusy(false)));
};

export default slice.reducer;
