// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-text-search-mobile{
   
        width: 100%;
        height: 39px !important;
        margin-left: 7px;
        /* margin-right: 7px; */
        border-radius: 4px 0  0 4px !important;
    
}`, "",{"version":3,"sources":["webpack://./src/components/KeywordSearch/KeywordSearchMobile.css"],"names":[],"mappings":"AAAA;;QAEQ,WAAW;QACX,uBAAuB;QACvB,gBAAgB;QAChB,uBAAuB;QACvB,sCAAsC;;AAE9C","sourcesContent":[".full-text-search-mobile{\n   \n        width: 100%;\n        height: 39px !important;\n        margin-left: 7px;\n        /* margin-right: 7px; */\n        border-radius: 4px 0  0 4px !important;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
