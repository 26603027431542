// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card-backgroud{
    --ion-background-color: #EBEEF1;
}`, "",{"version":3,"sources":["webpack://./src/components/Likes/MyLikes.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC","sourcesContent":["#card-backgroud{\n    --ion-background-color: #EBEEF1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
