import React from "react";
import PaymentOverviewSideBar from "../../a_components/components/PayementOverviewSideBar";
import SearchTagCloud from "../../pages/SearchPage/SearchTagCloud/SearchTagCloud";
import AddPostSidebar from "../AddPostSidebar/AddPostSidebar";
import DetailsSideBar from "../DetailsSideBar/DetailsSideBar";
import likesSidebar from "../LikesSidebar/LikesSidebar";
import ProfileSidebarComponent from "../ProfileSidebarComponent/ProfileSidebarComponent";
import MyAdsSidebarComponent from "../MyAdsSidebarComponent/MyAdsSidebarComponent";
import SellerSidebarComponent from "../SellerSidebarComponent/SellerSidebarComponent";
import PaymentSuccessSidebar from "../../a_components/components/PaymentSuccessSidebar";
import { Hidden } from "@material-ui/core";
import PayementMembershipSideBar from "../../a_components/components/PayementMembershipSideBar";
import SellerPageSettingSideBar from "../SellerPageSetting/SellerPageSettingSideBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FiltersModal from "../Filters/FiltersModal";

const SideBarContent = () => {
  return (
    <div style={{ paddingRight: '20px' }}>
      <Routes>
        <Route path="/home" element={<SearchTagCloud/>} />
        <Route
          path="/profile"
          element={<ProfileSidebarComponent/>}
        />
        <Route
          path="/myads"
          element={<MyAdsSidebarComponent/>}
        />
        <Route path="/search" element={<FiltersModal  />} />

        <Route
          path="/:mainCat/:subCat/:id"
          element={<DetailsSideBar/>}

        />
        <Route
          path="/seller/:email"
          element={<SellerSidebarComponent/>}

        />
        <Route path="/add-post" element={<AddPostSidebar/>} />

        {/* <Route path="/likes" element={likesSidebar} /> */}
        <Route path="/payment/:id" element={<PaymentOverviewSideBar/>} />
        <Route path="/promote/:id" element={<PaymentOverviewSideBar/>} />
        <Route path="/paymentSummery/" element={<PaymentSuccessSidebar/>} />
        <Route path="/membershipPaymentSummery" element={<PaymentSuccessSidebar/>} />
        <Route path="/paymentMembership/:id" element={<PayementMembershipSideBar/>} />
        <Route path="/sellerPageSetting" element={<SellerPageSettingSideBar/>} />

      </Routes>

    </div>
  );
};

export default SideBarContent;
