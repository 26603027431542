// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login-modal{
    height: 100%;
}
#login-modal-content{
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

#btn-facebook{
    text-transform: capitalize;
    --background: #4267B2;
    width: 100%;
    height: 100%;
}

#fb-logo{
    width: 24px;
    height: 24px;
    margin-right: 30px;
}

.login-btn-wrapper{
    /* width: 165px; */
    width:100%;
    height: 50px;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginRequired/LoginRequired.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;IAC1B,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,YAAY;AAChB","sourcesContent":["#login-modal{\n    height: 100%;\n}\n#login-modal-content{\n    height: 100%;\n    flex-direction: column;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n#btn-facebook{\n    text-transform: capitalize;\n    --background: #4267B2;\n    width: 100%;\n    height: 100%;\n}\n\n#fb-logo{\n    width: 24px;\n    height: 24px;\n    margin-right: 30px;\n}\n\n.login-btn-wrapper{\n    /* width: 165px; */\n    width:100%;\n    height: 50px;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
