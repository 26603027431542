// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nav-toolbar{
    height:50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#logo{
    width: 134px;
    position: absolute;
    top: 0px;
    z-index: 999;
}

#titlebar-wrapper{
    top: 0px;
    height:50px;
    max-width: "1260px";
    position: relative;
    background-color: #0080FF;
    box-shadow:0px 0px 0px #0000001A;
}

#btn-newad{
    height: 40px;
    width: 126px;
    border-radius: 4px;
    margin-bottom: 14px;
    text-transform: capitalize;
    margin-right: 10px;
    font-weight: 600;
    font-family: 'Titillium Web';
    padding:0 10px;
    letter-spacing: unset;
}
#btn-login{
    margin-bottom: 14px;
    margin-right: 50px;
    color: #5A5A5A;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Titillium Web';
}`, "",{"version":3,"sources":["webpack://./src/components/TitleBar/TitleBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,QAAQ;IACR,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,4BAA4B;AAChC","sourcesContent":["#nav-toolbar{\n    height:50px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n#logo{\n    width: 134px;\n    position: absolute;\n    top: 0px;\n    z-index: 999;\n}\n\n#titlebar-wrapper{\n    top: 0px;\n    height:50px;\n    max-width: \"1260px\";\n    position: relative;\n    background-color: #0080FF;\n    box-shadow:0px 0px 0px #0000001A;\n}\n\n#btn-newad{\n    height: 40px;\n    width: 126px;\n    border-radius: 4px;\n    margin-bottom: 14px;\n    text-transform: capitalize;\n    margin-right: 10px;\n    font-weight: 600;\n    font-family: 'Titillium Web';\n    padding:0 10px;\n    letter-spacing: unset;\n}\n#btn-login{\n    margin-bottom: 14px;\n    margin-right: 50px;\n    color: #5A5A5A;\n    text-transform: capitalize;\n    font-size: 14px;\n    font-weight: 400;\n    font-family: 'Titillium Web';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
