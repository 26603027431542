// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#icon-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-left: 20px;
}

#icon-section div{
    display: flex;
    align-items: center;
}

#icon-milage{
    width: 20px;
    height: 20px;
}
#icon-year{
    width: 15px;
    height: 15px;
}
#icon-location{
    width: 15px;
    height: 15px;
}
#txt-location{
    margin-left: 0px;
    /* margin-right: 20px; */
    font-size: 14px;
    color: #5A5A5A;
}
#txt-year{
     margin-left: 8px;
    margin-right: 20px;
    font-size: 14px;
    color: #5A5A5A;
}
#txt-milage{
     margin-left: 8px;
    margin-right: 20px;
    font-size: 14px;
    color: #5A5A5A;
}

.discount-price{
    font-size: 14px;
    color: #999999;
    text-decoration: line-through;
}
.discount-price-txt{
    font-size: 13px;
    color: #999999;
    text-decoration: line-through;
}

.discount-percentage{
    color: #FF5454;
    font-size: 14px;
    font-weight: 700;
}
.discount-txt{
    color: #FF5454;
    font-size: 14px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/Post/PostCardFixed/DetailSection/DefaultSerpMobile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,wBAAwB;IACxB,eAAe;IACf,cAAc;AAClB;AACA;KACK,gBAAgB;IACjB,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB;AACA;KACK,gBAAgB;IACjB,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,6BAA6B;AACjC;AACA;IACI,eAAe;IACf,cAAc;IACd,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["#icon-section{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 8px;\n    margin-left: 20px;\n}\n\n#icon-section div{\n    display: flex;\n    align-items: center;\n}\n\n#icon-milage{\n    width: 20px;\n    height: 20px;\n}\n#icon-year{\n    width: 15px;\n    height: 15px;\n}\n#icon-location{\n    width: 15px;\n    height: 15px;\n}\n#txt-location{\n    margin-left: 0px;\n    /* margin-right: 20px; */\n    font-size: 14px;\n    color: #5A5A5A;\n}\n#txt-year{\n     margin-left: 8px;\n    margin-right: 20px;\n    font-size: 14px;\n    color: #5A5A5A;\n}\n#txt-milage{\n     margin-left: 8px;\n    margin-right: 20px;\n    font-size: 14px;\n    color: #5A5A5A;\n}\n\n.discount-price{\n    font-size: 14px;\n    color: #999999;\n    text-decoration: line-through;\n}\n.discount-price-txt{\n    font-size: 13px;\n    color: #999999;\n    text-decoration: line-through;\n}\n\n.discount-percentage{\n    color: #FF5454;\n    font-size: 14px;\n    font-weight: 700;\n}\n.discount-txt{\n    color: #FF5454;\n    font-size: 14px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
