import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../features/language/language_slice";
import { selectCurrentLocation } from "../../features/navigation/navigationSlice";
import { Language } from "../../language/Language";
import { NAV_HOME, NAV_LANDING } from "../../providers/NavigationProvider";
import "./KeywordSearch.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      boxShadow: "0px 0px 0px #00000026",
      "& .makeStyles-shadow-2 .MuiPaper-elevation1": {
        boxShadow: "0px 0px 0px #00000026",
      },
    },
  })
);

const KeywordSearch = (props: any) => {
  const classes = useStyles();
  const [phrase, setPhrase] = useState("");
  const dispatch = useDispatch();
  const location = useSelector(selectCurrentLocation);

  const handleSearch = () => {
    const term = phrase.replace(/[^\w\s]/g, "").replace(/\s\s+/g, " ");
    if (term.length > 0) {
      props.onSearchEnter(term);
    }
  };
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <SearchBar
        className="full-text-search"
        onChange={setPhrase}
        placeholder={lan.SEARCH}
        onRequestSearch={handleSearch}
        cancelOnEscape={false}
        searchIcon={<></>}
        value={props.term || ""}
        style={
          location == NAV_HOME || location == NAV_LANDING
            ? { boxShadow: "0px 0px 0px #00000026", width: "100%" }
            : { boxShadow: "0px 0px 0px #00000026" }
        }
      />
      <IconButton
        style={{ padding: "0", marginRight: "7px" }}
        onClick={handleSearch}
      >
        <img src="assets/icon/btn-search.svg"></img>
      </IconButton>
    </div>
  );
};

export default KeywordSearch;
