import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { logEvent } from "firebase/analytics"
import { NAV_MEMBERSHIPS } from "../../providers/NavigationProvider";
import { VIEW_MEMBERSHIP_PAGE } from "../../config/events";
import { ACTION_VIEW } from "../../config/actions";
import { returnUpBackOutline } from "ionicons/icons";
import { Avatar, Button, Grid, Hidden, MenuItem, Snackbar, TextField, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { getAllCategories, selectCategories } from "../../features/tags/tagsSlice";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import CheckIcon from '@material-ui/icons/CheckSharp';
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { analytics } from "../..";
import { SEND_MEMBERSHIP_REQUEST } from "../../config/urls";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            color: "#000000",
            fontSize: "32px",
            fontWeight: 600
        },
        headingParagraph: {
            color: "#5A5A5A",
            margingTop: "20px"
        },
        headingButton: {
            backgroundColor: "#0080FF",
            color: "#FFFFFF",
            borderRadius: "8px",
            padding: "15px 35px 15px 35px",
            fontSize: "16px",
            width: "100%",
            marginTop: "20px"
        },
        headingImage: {

        },
        benefitPanel: {
            backgroundImage: "url(../assets/images/shadow-away.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "150px",
        },
        benefitPanelBackground: {
            backgroundImage: "linear-gradient(180deg, #fcfcfc, #ffffff)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
        },
        benefictHeadings: {
            color: "#000000",
            fontSize: "17px",
            fontWeight: 600
        },
        benefictDetails: {
            color: "#5A5A5A",
            fontSize: "14px"
        },
        benefictImages: {
            maxWidth: "60px",
            maxHeight: "50px",
            // minWidth: "60px",
            // minHeight: "auto",
        },
        callBackImage: {
            maxWidth: "128px",
            maxHeight: "122px"
        },
        callBackPanelIntro: {
            color: "#0080FF",
            fontSize: "24px"
        },
        callBackPanel: {
            backgroundImage: "url(../assets/images/rounded_rectangle_1260x330.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            marginBottom: "20px"

        },
        callBackInnerPanel: {
            padding: "10px 10px"

        },
        contactFields: {
            width: "100%"
        },
        contactSubmit: {
            width: "100%",
            backgroundColor: "#0080FF",
            color: "#FFFFFF",
            borderRadius: "6px",
            float: "right"
        }
    })
);

export function Membership() {

    const [requestEligible, setRequestEligible] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const scrollContent = document.getElementById('scrollable-root');

    const [page, setPage] = useState(1);
    const classes = useStyles();
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const categories = useSelector(selectCategories);
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = React.useState(false);

    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;


    useEffect(() => {
        setRequestEligible(true);
        dispatch(getAllCategories());
        dispatch(setCurrentLocation(NAV_MEMBERSHIPS));
        scrollContent?.scrollTo(0, 0);
        logEvent(analytics, VIEW_MEMBERSHIP_PAGE, { action: ACTION_VIEW });
        return () => {
            setRequestEligible(false);
            setPage(1);
        };
    }, []);



    const submitMembership = () => {

        // var data = JSON.stringify({ "userEmail": { email }, "name": { name }, "mobile": { mobile }, "category": { category }, "city": { location }, "data1": "", "data2": "" });
        // axios.post("https://api.saruwata.lk/membership/senRequest/AJSDSH232364CC7858N5864B94056", {
        //     "userEmail": { email },
        //     "name": { name },
        //     "mobile": { mobile },
        //     "category": { category },
        //     "city": { location }
        // }
        // )
        //     .then(data => {
        //         alert(data);
        //     }
        //     ).catch(e => alert(e));
        if (email != '' && location != '' && category != '' && mobile != '' && name != '') {

            var axios = require('axios');
            var data = JSON.stringify({
                "userEmail": email,
                "city": location,
                "category": category,
                "mobile": mobile,
                "name": name,
                "requestStatus": "0"
            });

            var config = {
                method: 'post',
                url: SEND_MEMBERSHIP_REQUEST,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response: any) {

                    if (String(response.data.userEmail).trim() === email.trim()) {
                        setOpen(true);
                        setName('');
                        sendMSG();
                        // setMobile('');
                        setEmail('');
                        setCategory('');
                        setLocation('')
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });

        } else {

        }
    }
    const scrollBottom = () => {

        const scrollContent: any = document.getElementById("scrollable-root");
        var bottom = document.getElementById('form')?.offsetTop;
        scrollContent.scrollBy({ top: bottom!, behavior: 'smooth' })


    }
    const sendMSG = () => {
        // https://sms.saruwata.lk/send/oneRecipient/jhbdfchsb8943oe88hdgwsu2837y498274383745r0345/0766439166/Ona
        const msg = "Ayubhowan from Saruwata! We're excited about your new membership request. Hold on tight till we customize the membership package just for you. One of our agent will assist you shortly.";
        const link = `https://sms.saruwata.lk/send/oneRecipient/jhbdfchsb8943oe88hdgwsu2837y498274383745r0345/${mobile}/${msg}`;
        axios.get(link).then(data => {
            setMobile('');
        }).catch(e => console.log(e));
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* 1260 */}
            <Grid container style={{ maxWidth: "1260px", padding: (isTabletOrMobile ? "20px 5%" : "20px 0"), }}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container>
                        <Hidden lgUp={true} mdUp={true}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <img className={classes.headingImage} src="../assets/images/slider_img-400x354.png" />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className={classes.heading} style={{ fontSize: (getLanguage() == 'english' ? '32px' : '28px') }}>
                                        {/* Increase your sales with a Saruwata.lk Membership! */}
                                        {lan.INCREASE_YOUR_SALES}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <p className={classes.headingParagraph} style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>
                                        {/* Memberships provide your company a stronger profile on Saruwata.lk, allowing you to reach out to more people. Our membership packages are tailored to provide you with the resources you need to grow your business and enhance your sales. */}
                                        {lan.MEMEBRSHIP_PROVIDE}
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <p className={classes.headingParagraph} style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>
                                        {/* As a member, you'll also receive our newsletter, which contains valuable advice on how to grow your business on Saruwata.lk. */}
                                        {lan.AS_A_MEMBER}
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <button id="bm" className={classes.headingButton} style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }} onClick={scrollBottom}>
                                        {/* Become a Member */}
                                        {lan.BECOME_A_MEMBER}
                                    </button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden xsDown={true} smDown={true}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <img style={{ width: "380px", marginLeft: "50px" }} className={classes.headingImage} src="../assets/images/slider_img-400x354.png" />
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={7} style={{ marginTop: "28px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.benefitPanel}>
                            <Typography style={{ color: "#000000", fontSize: (getLanguage() == 'english' ? '23px' : '20px'), fontWeight: 600, paddingTop: "50px" }} align="center">
                                {/* Benefits of Membership */}
                                {lan.BENEFITS_OF_MEMBERSHIP}
                            </Typography>
                            <Typography style={{ color: "#5A5A5A", fontSize: (getLanguage() == 'english' ? '16px' : '14px') }} align="center">
                                {/* Step up your sales with Membership services and make money easier! */}
                                {lan.STEP_UP_YOUR_SALES}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <img src="../assets/icon/icon_post_more_ads.png" className={classes.benefictImages} />
                                <div style={{ marginLeft: "20px" }}>
                                    <span className={classes.benefictHeadings} style={{ fontSize: (getLanguage() == 'english' ? '17px' : '15px') }}>
                                        {/* Post More Ads */}
                                        {lan.POST_MORE_ADS}
                                    </span><br />
                                    <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* With a Membership, you may post more adverts! Rather than worrying about ad constraints, concentrate on designing good advertising that efficiently sells your products. You'll sell more if you publish more advertising! */}
                                        {lan.POST_MORE_ADS_DESC}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <img src="../assets/icon/icon_voucher_70x55.png" className={classes.benefictImages} />
                                <div style={{ marginLeft: "20px" }}>
                                    <span className={classes.benefictHeadings} style={{ fontSize: (getLanguage() == 'english' ? '17px' : '15px') }}>
                                        {/* Free Promotions Vouchers */}
                                        {lan.FREE_PROMOTION_VOUCHERS}
                                    </span><br />
                                    <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Free advertising promotion! Every month, you'll receive free vouchers that you can use to add Super Ad, Jump Ad, Quick Sell, or Featured Ad promotions to your ads and get even more responses. */}
                                        {lan.FREE_PROMOTION_VOUCHERS_DESC}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <img src="../assets/icon/icon-recognize-as-a-member.png" className={classes.benefictImages} />
                                <div style={{ marginLeft: "20px" }}>
                                    <span className={classes.benefictHeadings} style={{ fontSize: (getLanguage() == 'english' ? '17px' : '15px') }}>
                                        {/* Recognize as a Member */}
                                        {lan.RECOGNIZE_AS_A_MEMBER}
                                    </span><br />
                                    <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* The member badge appears on all member ads, distinguishing your ads from those posted by individual sellers! We also provide special badges to make your ads more unique. */}
                                        {lan.RECOGNIZE_AS_A_MEMBER_DESC}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <img src="../assets/icon/icon-recognize-amember.png" className={classes.benefictImages} />
                                <div style={{ marginLeft: "20px" }}>
                                    <span className={classes.benefictHeadings} style={{ fontSize: (getLanguage() == 'english' ? '17px' : '15px') }}>
                                        {/* Member Shop */}
                                        {lan.MEMBER_SHOP}
                                    </span><br />
                                    <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Create your own brand on saruwata by dedicating an entire page to your company! This is similar to having your own online store in that all of your ads are listed in one location, and you can customize it by adding your tagline, logo, cover picture, and other business information. */}
                                        {lan.MEMBER_SHOP_DESC}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <img src="../assets/icon/icon-track-your-ad.png" className={classes.benefictImages} />
                                <div style={{ marginLeft: "20px" }}>
                                    <span className={classes.benefictHeadings} style={{ fontSize: (getLanguage() == 'english' ? '17px' : '15px') }}>
                                        {/* Track your Ad */}
                                        {lan.TRACK_YOUR_AD}
                                    </span><br />
                                    <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* On a daily basis, see how much interest your ad has generated among buyers! To understand the performance of an ad, you can track the number of views. */}
                                        {lan.TRACK_YOUR_AD_DESC}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{ display: "flex" }}>
                                <img src="../assets/icon/icon-dedicate-account-manager.png" className={classes.benefictImages} />
                                <div style={{ marginLeft: "20px" }}>
                                    <span className={classes.benefictHeadings} style={{ fontSize: (getLanguage() == 'english' ? '17px' : '15px') }}>
                                        {/* Dedicate Account Manager */}
                                        {lan.DEDICATE_ACCOUNT_MANAGER}
                                    </span><br />
                                    <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Get a personal executive to assist you with all of your needs, including ad posting! Throughout your membership, you will also have access to our sales and customer support teams. */}
                                        {lan.DEDICATE_ACCOUNT_MANAGER_DESC}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid id='form' item xs={12} sm={12} md={12} lg={12} className={classes.callBackPanel} >
                            <Grid container className={classes.callBackInnerPanel}  >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={4} md={5} lg={4}>
                                            <img src="../assets/icon/img-shop.png" className={classes.callBackImage} />
                                        </Grid>
                                        <Grid item xs={6} sm={8} md={7} lg={8}>
                                            <span className={classes.callBackPanelIntro} style={{ fontSize: (getLanguage() == 'english' ? '24px' : '20px') }}>
                                                {/* Advertise your products  */}
                                                {lan.ADVERTISE_YOUR_PRODUCTS}
                                            </span><br />
                                            <span className={classes.callBackPanelIntro} style={{ fontSize: (getLanguage() == 'english' ? '24px' : '20px') }}>
                                                {/* and services on  */}
                                                {lan.AND_SERVICES_ON}
                                            </span><br />
                                            <span className={classes.callBackPanelIntro} style={{ fontSize: (getLanguage() == 'english' ? '24px' : '20px') }}>
                                                {/* Saruwata.lk now! */}
                                                {lan.SARUWATA_LK_NOW}
                                            </span><br />
                                            <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                                {/* We will help create your shop and list your products on Saruwata.lk */}
                                                {lan.WE_WILL_HELP_YOU}
                                            </span>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <span style={{ fontWeight: 600, fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                                {/* Request a call back now! */}
                                                {lan.REQUEST_A_CALL_BACK}
                                            </span><br />
                                            <span className={classes.benefictDetails} style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                                {/* Let us help you! */}
                                                {lan.LET_US_HELP}
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextField id="contact-name" label={lan.YOUR_NAME} variant="outlined" value={name} onChange={(e: any) => { setName(e.target.value) }} className={classes.contactFields} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextField id="contact-number" label={lan.PHONE_NUMBER} variant="outlined" value={mobile} onChange={(e: any) => { setMobile(e.target.value) }} className={classes.contactFields} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextField id="email" label={lan.YOUR_EMAIL} variant="outlined" value={email} onChange={(e: any) => { setEmail(e.target.value) }} className={classes.contactFields} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                className={classes.contactFields}
                                                label={lan.SELECT_CATEGORY}
                                                value={category}
                                                onChange={(e: any) => { setCategory(e.target.value) }}
                                                variant="outlined"
                                            // onChange={handleChange}
                                            >
                                                {/* {categories.map((m: any) => (
                                                    <MenuItem key={m.id} value={m.name}>
                                                        {m?.name}
                                                    </MenuItem>
                                                ))} */}
                                                <MenuItem key={1} value={"Motors"}>
                                                    {/* Motors */}
                                                    {lan.MOTORS}
                                                </MenuItem>
                                                <MenuItem key={1} value={" Mobile And Electronics"}>
                                                    {/* Mobile And Electronics */}
                                                    {lan.MOBILE_AND_ELECTRONICS}
                                                </MenuItem>
                                                <MenuItem key={1} value={"Property"}>
                                                    {/* Property */}
                                                    {lan.PROPERTY}
                                                </MenuItem>
                                                <MenuItem key={1} value={"Classifieds"}>
                                                    {/* Classifieds */}
                                                    {lan.CLASSIFIEDS}
                                                </MenuItem>


                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextField
                                                id="contact-business-location"

                                                className={classes.contactFields}
                                                label={lan.BUSINESS_LOCATION}
                                                value={location}
                                                variant="outlined"
                                                onChange={(e: any) => { setLocation(e.target.value) }}
                                            >


                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  >
                                                <Alert style={{ backgroundColor: "green", color: "white" }} icon={<CheckIcon fontSize="inherit" />} severity="success">
                                                    Your Request Has Been Submited
                                                </Alert>
                                            </Snackbar>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6}> </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                            <Button className={classes.contactSubmit} onClick={submitMembership}>{lan.SUBMIT}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ height: "30px" }}></div>
        </>

    )
}