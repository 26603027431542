import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import sessionReducer from '../features/session/sessionSlice';
import firebaseReducer from '../features/session/firebaseSlice';
import postReducer from '../features/post/postSlice';
import navigationReducer from '../features/navigation/navigationSlice';
import scrollReducer from '../features/scroll/scrollSlice';
import addPostReducer from '../features/addPost/addPostSlice';
import searchReducer from '../features/search/searchSlice';
import tagsReducer from '../features/tags/tagsSlice';
import filterReducer from '../features/filter/filterSlice';
import powerReducer from '../features/powers/powerSlice';
import sellerReducer from '../features/seller/sellerSlice';
import paymentReducer from '../a_components/features/addOnServices';
import paymentMembership from '../a_components/features/membershipPayment';
import addOnValues from '../a_components/AddOn/AddOnValues';
import locations from '../config/locations';
import filter from '../features/search/FilterSlice';
import imageUploadreducer from '../features/post/imageUpload';
import sms from '../features/sms/smsVerifications';
import language from '../features/language/language_slice';



export default configureStore({
    reducer: {
        session: sessionReducer,
        firebase: firebaseReducer,
        post: postReducer,
        navigation: navigationReducer,
        scroll: scrollReducer,
        addPost: addPostReducer,
        search: searchReducer,
        tags: tagsReducer,
        filter: filterReducer,
        power: powerReducer,
        seller: sellerReducer,
        payment: paymentReducer,
        addOnValues: addOnValues,
        locations: locations,
        filters: filter,
        upload: imageUploadreducer,
        paymentMembership: paymentMembership,
        sms: sms,
        language:language
    },
    middleware: [...getDefaultMiddleware({ immutableCheck: false })]
});
