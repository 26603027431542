import { BottomNavigationAction, Button } from "@material-ui/core";
import React from "react";
import { NavigationContext, NAV_ADD_POST } from "../../../providers/NavigationProvider";
import { UserContext } from "../../../providers/UserProvider";

const AddPostButtonMobile = (props: any) => {
    return (
        <UserContext.Consumer>
            {(user: any) => (
                <NavigationContext.Consumer>
                    {(nav: any) => (

                        <BottomNavigationAction
                            label="Add Post"
                            value="add post"
                            onClick={() => props.handleNewAd(user)} disabled={nav.current === NAV_ADD_POST || props.authFreeze}
                            icon={
                                (nav.current === NAV_ADD_POST || props.authFreeze) ?
                                    <img
                                        src="assets/icon/menu-icon-add-post.svg"
                                       
                                    ></img>
                                    :
                                    <img
                                        src="assets/icon/menu-icon-add-post.svg"
                                       
                                    ></img>
                            }
                        />

                        // <Button id="btn-newad" style={{ padding: 0 }} onClick={() => props.handleNewAd(user)} disabled={nav.current === NAV_ADD_POST || props.authFreeze}>
                        //     {
                        //         (nav.current === NAV_ADD_POST || props.authFreeze) ?
                        //             <img
                        //                 src="assets/icon/btn-add-post-deact.svg"
                        //                 style={{ height: "40px" }}
                        //             ></img>
                        //             :
                        //             <img
                        //                 src="assets/icon/btn-add-post-act.svg"
                        //                 style={{ height: "40px" }}
                        //             ></img>
                        //     }
                        // </Button>
                    )}
                </NavigationContext.Consumer>
            )}
        </UserContext.Consumer>
    );
}
export default AddPostButtonMobile;