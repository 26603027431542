// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#devider-line{
    height: 2px;
    border-bottom: 2px solid #EBEEF1;
    width: 100%;
    margin-bottom: 24px;
}
#wall{
    position: sticky;
    top: 10px;
    width: 160px;
    height: 600px;
}`, "",{"version":3,"sources":["webpack://./src/components/Page/Page.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gCAAgC;IAChC,WAAW;IACX,mBAAmB;AACvB;AACA;IAEI,gBAAgB;IAChB,SAAS;IACT,YAAY;IACZ,aAAa;AACjB","sourcesContent":["#devider-line{\n    height: 2px;\n    border-bottom: 2px solid #EBEEF1;\n    width: 100%;\n    margin-bottom: 24px;\n}\n#wall{\n    position: -webkit-sticky;\n    position: sticky;\n    top: 10px;\n    width: 160px;\n    height: 600px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
