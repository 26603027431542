import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import keyInterceptor from './interceptors/key-interceptor';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './config/store';
import { firebaseConfig } from './config/firebaseConfig';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setupConfig } from '@ionic/react';
// import { SplashScreen } from '@capacitor/core';
import { BrowserRouter } from 'react-router-dom';
import { version } from '../package.json';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';


const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app)
GoogleAuth.initialize();

setupConfig({
  swipeBackEnabled: false,
  hardwareBackButton: false
});



// SplashScreen.hide();

ReactDOM.render(

  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

keyInterceptor.setUpInterceptors();
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
