import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLocation } from "../../features/navigation/navigationSlice";
import { NAV_HOME, NAV_LANDING } from "../../providers/NavigationProvider";
import SearchIcon from "@material-ui/icons/SearchSharp";
import "./KeywordSearchMobile.css";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      boxShadow: "0px 0px 0px #00000026",
      "& .makeStyles-shadow-2 .MuiPaper-elevation1": {
        boxShadow: "0px 0px 0px #00000026",
      },
    },
  })
);

const KeywordSearchMobile = (props: any) => {
  const classes = useStyles();
  const [phrase, setPhrase] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useSelector(selectCurrentLocation);

  const handleSearch = () => {
    const term = phrase.replace(/[^\w\s]/g, "").replace(/\s\s+/g, " ");
    if (term.length > 0) {
      props.onSearchEnter(term);
    } else {
      navigate('/home');
    }
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <SearchBar
        className="full-text-search-mobile"
        onChange={setPhrase}
        onRequestSearch={handleSearch}
        cancelOnEscape={false}
        searchIcon={<></>}
        value={props.term || ""}
        style={
          location == NAV_HOME || location == NAV_LANDING
            ? { boxShadow: "0px 0px 0px #00000026", width: "100%" }
            : { boxShadow: "0px 0px 0px #00000026" }
        }
      />

      <IconButton
        style={{ padding: "0", marginRight: "7px" }}
        onClick={handleSearch}
      >
        <img src="assets/icon/btn-search.svg"></img>
      </IconButton>
    </div>
  );
};

export default KeywordSearchMobile;
