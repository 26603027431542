// const server = 'http://192.168.1.100:8080/';
//const server = 'https://i1.saruwata.lk/';
// const server = "https://alpha.hiruads.lk:8080/";
//const server = 'https://vesta.saruwata.lk/'
//const server = 'https://test.saruwata.lk/'
//const server = 'http://saruwata-env.eba-wzxf2rhg.ap-southeast-1.elasticbeanstalk.com/'
//const prefix = 'vesta/v1/'; 


const server = 'https://uat.saruwata.lk/';
//const server = 'http://localhost:5000/';
// const server = 'https://uatdata1.saruwata.lk/';
// const server = "https://data.saruwata.lk/";
const prefix = 'zeus/api/v1/';
//const server_path = "https://vesta.saruwata.lk/vesta/v1/"
const server_path = server + prefix;


const service_server_path = 'https://uat.saruwata.lk/service/api/';

// export const frontend = 'https://saruwata.lk';

//export const frontend = 'http://47.129.203.247';
// export const frontend = 'http://localhost:3000';
export const frontend = 'https://uat.saruwata.lk';

export const LOGIN = server_path + 'users/login';
export const POST_CREATE = server_path + 'posts/with-multiple-images';
// export const POST_EDIT = server_path + 'posts/edit/';
export const POST_EDIT = server_path + 'posts/editPost';
export const POST_GET_ALL = server_path + 'posts/all';
export const POST_GET_SINGLE = server_path + 'posts/';
export const GET_ACCOUNT_LIKED_POSTS = server_path + 'posts/liked';
export const GET_ACCOUNT_POSTED_ADS = server_path + 'posts/account/ads';
export const POSTS = server_path + 'posts';
export const CATEGORIES_GET_ALL = server_path + 'categories/tree';
export const BRANDS_GET_BY_CATEGORY = server_path + 'brands/categories/';
export const MODELS_GET_BY_BRAND = server_path + 'models/categories/';
export const GET_YEARS = server_path + 'tags/specs/years';
export const GET_LOCATION = server_path + 'locations/all';
export const GET_DISTRICTS = server_path + 'locations/districts';
export const GET_CITIES = server_path + 'locations/cities';
export const GET_MOBILE_MEMORY = server_path + 'tags/specs/mobile-memory';
export const GET_SELLER_PROFILE = server_path + 'sellers/';

export const FILTER_POSTS = server_path + 'posts/fil';

export const ADMIN_POSTS = server_path + 'admin/posts';
export const ADMIN_POST_GET_ALL = server_path + 'admin/posts/all';
export const SELF_PROFILE = server_path + 'me/profile-with-posts';
export const GET_VERIFIED_PHONE = server_path + 'me/phones';
export const SELF_PHONE_VERIFY = server_path + 'me/verified-phones';

export const POPULAR_TAGS = server_path + 'tags/popular-tags-by-sections'
export const SEARCH_RESULT_POPULAR_TAGS = server_path + 'search/phrase'

export const MARK_SOLD = server_path + 'posts/sold/';
export const REPOST = server_path + 'powers/reposting/posts/';
export const ENDPOINT = server_path + 'paymentEndpoint/endPoint';

export const ENDPOINT_MEMBERSHIP_SAMPATH = server_path + 'membership-payment-gateways/sampath';
export const CONFIRM_PAYMENT = server_path + 'postPayment/updatePayment';
export const CONFIRM_MEMBERSHIP_PURCHASE_PAYMENT = server_path + 'membership-new/confirmMembershipPayment';
export const CONFIRM_PAYMENT_MEMBER = server_path + 'postPayment/updateMembersPostPayment';
export const ZERO_PAYMENT = server_path + 'postPayment/updatePaymentForZero';
export const GET_SPOTLIGHTS = server_path + 'posts/getSpotLight';
export const GET_SPOTLIGHTS_CATEGORY = server_path + 'posts/getSpotLightForCategory/';

export const GET_REFRESHED_PAYMENT = server_path + 'posts/postPayDetailsforPay/';
export const GET_MY_AD_SIDEBAR_SUMMARY = server_path + 'posts/userPostDetails';
export const PAYMENT_SUMMERY_DETAILS = server_path + 'payments/getPostPaymentById';
export const PAYMENT_SUMMERY_DETAILS_MEMBER = server_path + 'membership-new/getMembershipPaymentSummary';
export const PAYMENT_UPDATE_REQUEST_STATUS_FOR_PAYMENT_MEMBERSHIP = service_server_path +'membership/updateRequestStatusForPayment/AJSDSH232364CC7858N5864B94056/';
export const GET_CURRENT_MEMBERSHIP_DETAILS = server_path + 'membership-new/getUserCurrentMembership';
export const UPDATE_BUSINESS_NAME = server_path + 'member-shop/updateBusinessName';
export const UPDATE_BUSINESS_DESCRIPTION = server_path + 'member-shop/updateDescription';
export const UPDATE_BUSINESS_LOCATION = server_path + 'member-shop/updateLocation';
export const UPDATE_BUSINESS_PROFILE_IMAGE = server_path + 'member-shop/updateProfilePic';
export const UPDATE_BUSINESS_COVER_IMAGE = server_path + 'member-shop/updateCoverPic';
export const UPDATE_BUSINESS_UPDATE_TIME = server_path + 'member-shop/updateOpenTimes';
export const UPDATE_BUSINESS_MOBILE_NUMBER = server_path + 'member-shop/addMobile';
export const GET_SHOP_DETAILS = server_path + 'member-shop/getShopDetailsForView';
export const GET_SELLER_AD_COUNT = server_path + 'sellers/getAdCount/';
export const UPDATE_CALL_COUNT = server_path + 'posts/updateCallCount/';
export const MEMBERSHIP_PAYMENT_DETAILS = server_path + 'membership-new/getDetailsForPayment/';

export const MY_MEMBERSHIP_DETAILS = server_path + 'membership-new/getMyMembershipDetails/';
// export const MY_MEMBERSHIP_GET_USER_REQUEST = 'https://api.saruwata.lk/membership/getUserRequestByEmail/';
// export const MY_MEMBERSHIP_GET_USER_REQUEST = 'http://localhost:8000/membership/getUserRequestByEmail';
export const MY_MEMBERSHIP_GET_USER_REQUEST = service_server_path +'membership/getUserRequestByEmail';
export const MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN = '/AJSDSH232364CC7858N5864B94056';

export const generatePresignedS3Url = server_path + "generate-presigned-url";