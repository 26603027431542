import classes from "*.module.sass";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../../config/locations";
import {
  getBrandsByCategory,
  getCities,
  getDistricts,
  getMobileMemory,
  getModelsByBrand,
  selectBrands,
  selectCity,
  selectDistrict,
  selectedCategories,
  selectMobileMemory,
  selectModels,
} from "../../../../../../features/addPost/addPostSlice";
import { currencyConverter } from "../../../../../../features/helper";
import { selectLanguage } from "../../../../../../features/language/language_slice";
import {
  addNewPostDesciption,
  addNewPostDistrict,
  addNewPostPrice,
  addNewPostTitle,

  selectNewPosts,
} from "../../../../../../features/post/postSlice";
import { Language } from "../../../../../../language/Language";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderColor: {
      "& .MuiInput-underline:before": {
        borderBottom: "1px solid #DDDDDD",
      },
    },
    lineRadio: {
      "& .MuiFormGroup-root": {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row !important',
        marginLeft: '8px',
      },
      "& .MuiFormControlLabel-root": {
        marginRight: '30px'
      },
      "& .MuiButtonBase-root.MuiRadio-root": {
        padding: '5px 7px 7px 0 !important'
      }
    },
  })
);

const FillDetailsMobilesMobile = () => {
  const classes = useStyles();
  const [showListModal, setShowListModal] = useState({
    status: false,
    placeholder: "",
    list: [],
    ref: "",
  });
  const dispatch = useDispatch();
  const selctedCat = useSelector(selectedCategories);
  const brands = useSelector(selectBrands);
  const models = useSelector(selectModels);
  const memory = useSelector(selectMobileMemory);
  const newPost = useSelector(selectNewPosts);
  const [fillDetailsDisable, setFillDetailsDisable] = useState(true);
  const [values, setValues] = useState({} as any);
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  const condition: any = [
    { id: 0, name: "New", label: lan.NEW },
    { id: 1, name: "Used", label: lan.USED },
  ];
  const [selectedMemory, setSelectedMemory] = useState<any>("");
  const [selectedCondition, setSelectedCondition] = useState<any>();
  const cities = useSelector(selectCity);
  const district = useSelector(selectDistrict);
  const locs = useSelector(selectLocations);

  const [sugesstedTitile, setsugesstedTitile] = useState({} as any);


  useEffect(() => {
    dispatch(getBrandsByCategory(selctedCat.level2.id));
    dispatch(getDistricts());
    dispatch(getMobileMemory());
    setSelectedCondition('New');
    onConditionSelect('New');
  }, []);

  useEffect(() => {
    const brand = sugesstedTitile?.brand ? sugesstedTitile.brand : '';
    const model = sugesstedTitile?.model ? sugesstedTitile.model : '';
    const memory = sugesstedTitile?.memory ? sugesstedTitile.memory : '';
    const condition = sugesstedTitile?.condition ? sugesstedTitile.condition : '';

    if (model?.split(' ')?.length > 1 && brand == model.split(' ')[0]) {
      const title = brand + ' ' + model.split(' ')[1] + ' ' + memory + ' ' + condition;
      dispatch(addNewPostTitle(title));

    } else {
      const title = brand + ' ' + model + ' ' + memory + ' ' + condition;
      dispatch(addNewPostTitle(title));
    }

  }, [sugesstedTitile]);

  const onBrandSelect = (brand: any) => {
    setValues((v: any) => {
      return { ...v, brand: brand };
    });
    dispatch(getModelsByBrand(brand.id, selctedCat.level2.id));
    setShowListModal({ status: false, list: [], placeholder: "", ref: "" });
    setFillDetailsDisable(false);
    setValues((v: any) => {
      return { ...v, model: "" };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, brand: brand.name.charAt(0).toUpperCase() + brand.name.slice(1).toLowerCase() };
    });
  };
  const onModelSelect = (model: any) => {
    setValues((v: any) => {
      return { ...v, model: model };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, model: model.name.charAt(0).toUpperCase() + model.name.slice(1).toLowerCase() };
    });
  };
  const onDistrictSelect = (location: any) => {
    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });
  };
  const onCitySelect = (location: any) => {
    setValues((v: any) => {
      return { ...v, city: location };
    });
    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

  };

  const onMemorySelect = (memory: any) => {
    setSelectedMemory(memory.name);
    setValues((v: any) => {
      return { ...v, memory: memory.name };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, memory: memory.name };
    });
    // setSelectedMemory(memory);
    // setValues((v: any) => {
    //   return { ...v, memory: memory };
    // });
    // setsugesstedTitile((v: any) => {
    //   return { ...v, memory: memory };
    // });
  };

  const onConditionSelect = (condition: any) => {
    setSelectedCondition(condition);
    setValues((v: any) => {
      return { ...v, condition: condition };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, condition: condition };
    });
  };
  const isSavedDistrict = () => {

    if (Object.keys(locs).length > 0) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="district"
            options={district}
            disableClearable
            onChange={(event: any, district: any) => {
              onDistrictSelect(district);
            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="city"
            options={cities}
            disableClearable
            disabled={!values.district}

            onChange={(event: any, location: any) => {
              onCitySelect(location);
            }}
            getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="district"
                label={lan.CITY}
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-brand-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="brands"
            disableClearable
            options={brands}
            onChange={(event: any, brand: any) => {
              onBrandSelect(brand);
            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.BRAND_NAME}
                name="Mobile_Brand"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-model-16x16.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="models"
            options={models}
            disableClearable
            disabled={!values.brand}
            onChange={(event: any, model: any) => {
              onModelSelect(model);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.MODEL}
                name="Mobile_Model"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-brand-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="memory"
            disableClearable
            options={memory}
            onChange={(event: any, memory: any) => {
              onMemorySelect(memory);
            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Memory Capacity"
                name="Mobile_Memory"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-memory-card-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <FormControl style={{ width: "100%", height: '54px' }}>
            <InputLabel style={{ marginLeft: "15px" }}>Memory Capacity</InputLabel>
            <Select
              labelId="memory"
              id="memory"
              name="Mobile_Memory"
              variant="outlined"
              placeholder="Memory Capacity"
              value={selectedMemory || ""}
              onChange={(e) => onMemorySelect(e.target.value)}
            >
              {memory.map((item: any) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} className="filldetails-radio-grid-item" style={{ paddingLeft: "16px", paddingTop: "16px" }}>
          <div className="filldetails-radioline-icon-wrapper">
            <img src="assets/addpost/icon-condition-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <FormControl component="fieldset" id="condition" className={classes.lineRadio}>
            <FormLabel component="legend">{lan.CONDITION}</FormLabel>
            <RadioGroup
              aria-label={lan.CONDITION}
              name="Electronic_Condition"
              value={selectedCondition || ""}
              onChange={(e: any) => onConditionSelect(e.target.value)}
            >
              {condition.map((item: any) => (
                <FormControlLabel
                  value={item.name}
                  key={item.id}
                  control={<Radio color="primary" size="small" required />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-fullwidth-icon-wrapper">
            <img src="assets/addpost/icon-title-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="title"
            label={lan.TITTLE}
            placeholder={lan.MAX_72_CHARACTERS}
            name="title"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            required
            inputProps={{ maxLength: 72 }}
            variant="outlined"
            value={newPost.title || ""}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostTitle(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <TextField
            id="description"
            // label="Description"
            label={lan.DESCRIPTION}
            multiline
            placeholder={lan.MAX_4000_CHARACTERS}
            name="description"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.description || ""}
            inputProps={{ maxLength: 4000 }}
            style={{ width: "100%" }}
            onChange={(e: any) =>
              dispatch(addNewPostDesciption(e.target.value))
            }
            onInput={(e: any) => dispatch(addNewPostDesciption(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-price-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="price"
            label={lan.PRICE}
            placeholder="2500000"
            name="price"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            required
            variant="outlined"
            value={newPost.price || ""}
            inputProps={{ pattern: "^\\d{1,12}(\\.\\d{1,2})?$", maxLength: 15 }}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostPrice(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          {
            (newPost.price == 0) ?
              <span className="price" >{lan.ASK_FOR_PRICE}</span>
              :
              <div color="primary" className="price">
                <span className="ru-in-price">{lan.RS}. </span>{" "}
                {newPost?.price === "" || !isNaN(newPost?.price)
                  ? currencyConverter().format(newPost?.price)
                  : "Invalid"}
              </div>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default FillDetailsMobilesMobile;
