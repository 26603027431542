import React from "react";
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Provider, useDispatch } from "react-redux";
import JumpAd from "../AddOn/JumpAd";
import ReactDOM from "react-dom";
import { setAddOnCodesBoostAd, setAddOnCodesBundlePack, setAddOnCodesFeatureAd, setAddOnCodesJumpAd, setAddOnCodesQuickSell, setBoostAd, setBundlePack, setFeatureAd, setJumpAd, setQuickSell } from "../features/addOnServices";

export default function ResetRadios() {



    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                flexGrow: 1,
            },
            ml: {
                [theme.breakpoints.up("md")]: {
                    marginLeft: "50px"
                }, [theme.breakpoints.down("md")]: {
                    marginLeft: "0px",
                    marginTop: "18px"
                }

            }

        }),
    );

    const classes = useStyles();
    const dispatch = useDispatch();

    function resetAddOns() {
        dispatch(setJumpAd(0));
        dispatch(setBoostAd(0));
        dispatch(setFeatureAd(0));
        dispatch(setQuickSell(0));
        dispatch(setBundlePack(0));

        dispatch(setAddOnCodesJumpAd(''));
        dispatch(setAddOnCodesBoostAd(''));
        dispatch(setAddOnCodesBundlePack(''));
        dispatch(setAddOnCodesFeatureAd(''));
        dispatch(setAddOnCodesQuickSell(''));


    }

    return (
        <Grid container style={{ marginBottom: "10px" }}>
            <Grid item xs={9} md={9} lg={10}>
                <Typography style={{ paddingTop: "8px" }} color="textSecondary" >
                    Please, choose one of the following options to boost your ad. (Optional)
                </Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={1}>
                <Button variant="outlined" color="primary" className={classes.ml} onClick={() => { resetAddOns(); }} endIcon={<RotateLeftIcon />}>
                    Reset
                </Button>
            </Grid>
        </Grid>

    );

}