import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../config/locations";
import {
  selectedCategories,
  selectLocation,
  getBrandsByCategory,
  selectCity,
  selectDistrict,
  getCities,
  getDistricts,
} from "../../../../../features/addPost/addPostSlice";
import { currencyConverter } from "../../../../../features/helper";
import { selectLanguage } from "../../../../../features/language/language_slice";
import {
  selectNewPosts,
  addNewPostTitle,
  addNewPostPrice,
  addNewPostDistrict,
  addNewPostDesciption,

} from "../../../../../features/post/postSlice";
import { Language } from "../../../../../language/Language";

const FillDetailsShorttermRent = () => {
  const dispatch = useDispatch();
  const selctedCat = useSelector(selectedCategories);
  const location = useSelector(selectLocation);
  const newPost = useSelector(selectNewPosts);
  const locs = useSelector(selectLocations);


  const [fillDetailsDisable, setFillDetailsDisable] = useState(true);

  const [values, setValues] = useState({} as any);
  const numbers: any = [
    { id: 0, name: "0" },
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
    { id: 13, name: "13" },
    { id: 14, name: "14" },
    { id: 99, name: "14+" },
  ];
  const [checked, setChecked] = useState(false);

  const [selectedBedroom, setSelectedBedroom] = useState<any>(0);
  const [selectedBathroom, setSelectedBathroom] = useState<any>(0);
  const [selectedParking, setSelectedParking] = useState<any>(0);

  const [floorArea, setFloorArea] = useState<any>(0);

  const cities = useSelector(selectCity);
  const district = useSelector(selectDistrict);

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  useEffect(() => {
    dispatch(getDistricts());
    setFillDetailsDisable(false);
  }, []);

  useEffect(() => {
    dispatch(getBrandsByCategory(selctedCat.level2.id));
  }, []);

  const onDistrictSelect = (location: any) => {
    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });
  };
  const onCitySelect = (location: any) => {
    setValues((v: any) => {
      return { ...v, city: location };
    });
    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

  };

  const onBedroomSelect = (bedrooms: any) => {
    // setSelectedBedroom(bedrooms);
    // setValues((v: any) => {
    //   return { ...v, bedrooms: bedrooms };
    // });
    setSelectedBedroom(bedrooms.name);
    setValues((v: any) => {
      return { ...v, bedrooms: bedrooms.name };
    });
  };

  const onBathroomSelect = (bathrooms: any) => {
    setSelectedBathroom(bathrooms.name);
    setValues((v: any) => {
      return { ...v, bathrooms: bathrooms.name };
    });
    // setSelectedBathroom(bathrooms);
    // setValues((v: any) => {
    //   return { ...v, bathrooms: bathrooms };
    // });
  };
  const onParkingSelect = (parking: any) => {
    setSelectedParking(parking.name);
    setValues((v: any) => {
      return { ...v, parking: parking.name };
    });
    // setSelectedParking(parking);
    // setValues((v: any) => {
    //   return { ...v, parking: parking };
    // });
  };
  const isSavedDistrict = () => {

    if (Object.keys(locs).length > 0) {
      return true;
    }
    return false;
  }


  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} className="filldetails-grid-item" >
          <div className="filldetails-fullwidth-icon-wrapper">
            <img src="assets/addpost/icon-title-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="title"
            label={lan.TITTLE}
            placeholder={lan.MAX_72_CHARACTERS}
            name="title"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: 72 }}
            variant="outlined"
            value={newPost.title || ""}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            required
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="district"
            options={district}
            disableClearable
            onChange={(event: any, district: any) => {
              onDistrictSelect(district);

            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="city"
            options={cities}
            disableClearable
            disabled={!values.district}

            onChange={(event: any, location: any) => {
              onCitySelect(location);
            }}
            getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="district"
                label={lan.CITY}
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-bedrooms-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="bedrooms"
            options={numbers}
            disableClearable
            onChange={(event: any, bedrooms: any) => {
              onBedroomSelect(bedrooms);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.BED_ROOMS}
                name="Property_Bedroom"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-bedrooms-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <FormControl style={{ width: "100%", height: '55px' }}>
            <InputLabel style={{ marginLeft: '15px' }}>Bed Rooms</InputLabel>
            <Select
              labelId="bedrooms"
              id="bedrooms"
              name="Property_Bedroom"
              variant="outlined"
              placeholder="Bed Rooms"
              value={selectedBedroom || 0}
              onChange={(e) => onBedroomSelect(e.target.value)}
            >
              {numbers.map((item: any) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-bathrooms-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="bathrooms"
            options={numbers}
            disableClearable
            onChange={(event: any, bathrooms: any) => {
              onBathroomSelect(bathrooms);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.BATH_ROOMS}
                name="Property_Bathroom"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-bathrooms-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <FormControl style={{ width: "100%", height: '55px' }}>
            <InputLabel style={{ marginLeft: '15px' }}>Bath Rooms</InputLabel>
            <Select
              labelId="bathrooms"
              id="bathrooms"
              name="Property_Bathroom"
              variant="outlined"
              placeholder="Bath Rooms"
              value={selectedBathroom || 0}
              onChange={(e) => onBathroomSelect(e.target.value)}
            >
              {numbers.map((item: any) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-floor-area-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="floorarea"
            label={lan.FLOOR_AREA_SQFT}
            placeholder="2500"
            name="Property_Floor_Area"
            type="text"
            inputProps={{ pattern: "^\\d{1,7}(\\.\\d{1,2})?$" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={floorArea || ""}
            style={{ width: "100%" }}
            onChange={(e) => setFloorArea(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item" style={{ width: "100%" }}>
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-furnished-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <div className='switch-wrapper'>
            <div className='switch-text'>{lan.FURNISHED}</div>
            <Switch
              checked={checked || false}
              onChange={(e: any) => setChecked(e.target.checked)}
              name="Property_Furnished"
              color="primary"
            />
          </div>
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-car-parking-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="parking-space"
            options={numbers}
            disableClearable
            onChange={(event: any, parking: any) => {
              onParkingSelect(parking);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.PARKING_SPACE}
                name="Property_Parking_Space_Num"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-car-parking-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <FormControl style={{ width: "100%", height: '55px' }}>
            <InputLabel style={{ marginLeft: '15px' }}>Parking Space</InputLabel>
            <Select
              labelId="ParkingSpace"
              id="parking-space"
              name="Property_Parking_Space_Num"
              variant="outlined"
              placeholder="Bath Rooms"
              value={selectedParking || 0}
              onChange={(e) => onParkingSelect(e.target.value)}
            >
              {numbers.map((item: any) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            id="description"
            // label="Description"
            label={lan.DESCRIPTION}
            multiline
            placeholder={lan.MAX_4000_CHARACTERS}
            name="description"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.description || ""}
            inputProps={{ maxLength: 4000 }}
            style={{ width: "100%" }}
            onChange={(e: any) =>
              dispatch(addNewPostDesciption(e.target.value))
            }
            onInput={(e: any) => dispatch(addNewPostDesciption(e.target.value))}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-price-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="price"
            label={lan.PRICE}
            placeholder="2500000"
            name="price"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.price || ""}
            inputProps={{ pattern: "^\\d{1,12}(\\.\\d{1,2})?$", maxLength: 15 }}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            required
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          {
            (newPost.price == 0) ?
              <span className="price" >{lan.ASK_FOR_PRICE}</span>
              :
              <div color="primary" className="price">
                <span className="ru-in-price">{lan.RS}. </span>{" "}
                {newPost?.price === "" || !isNaN(newPost?.price)
                  ? currencyConverter().format(newPost?.price)
                  : "Invalid"}
              </div>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default FillDetailsShorttermRent;
