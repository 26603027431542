// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#likes-content{
    --ion-background-color: #EBEEF1;
}
#likes-header{
    z-index: 0;
}

#likes-toolbar{
    --ion-background-color: #ffffff;
    --box-shadow : none;
}

#likes-title{
    color: white;
    font-weight: 400;
    font-size: 18px;
}

.likes-back-button{
    color: white;
}
.btn-likeads-switch{
    --background: white;
    --color : #5A5A5A;
    --color-checked : #000000;
    --indicator-color : #0080FF;
    --indicator-height : 4px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;

}
#segment{
    --background: white;
}

.no-text{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.cactus{
    width: 204px;
    height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/LikesPage/LikesPage.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC;AACA;IACI,UAAU;AACd;;AAEA;IACI,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,2BAA2B;IAC3B,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,0BAA0B;;AAE9B;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":["#likes-content{\n    --ion-background-color: #EBEEF1;\n}\n#likes-header{\n    z-index: 0;\n}\n\n#likes-toolbar{\n    --ion-background-color: #ffffff;\n    --box-shadow : none;\n}\n\n#likes-title{\n    color: white;\n    font-weight: 400;\n    font-size: 18px;\n}\n\n.likes-back-button{\n    color: white;\n}\n.btn-likeads-switch{\n    --background: white;\n    --color : #5A5A5A;\n    --color-checked : #000000;\n    --indicator-color : #0080FF;\n    --indicator-height : 4px;\n    font-size: 18px;\n    font-weight: 600;\n    text-transform: capitalize;\n\n}\n#segment{\n    --background: white;\n}\n\n.no-text{\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    font-size: 24px;\n    font-weight: 600;\n    text-align: center;\n}\n\n.cactus{\n    width: 204px;\n    height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
