import { Button } from "@material-ui/core";
import React from "react";
import { NavigationContext, NAV_ADD_POST } from "../../../providers/NavigationProvider";
import { UserContext } from "../../../providers/UserProvider";

const AddPostButton = (props: any) => {
    return (
        <UserContext.Consumer>
            {(user: any) => (
                <NavigationContext.Consumer>
                    {(nav: any) => (
                        <Button id="btn-newad" style={{ padding: 0 }} onClick={() => props.handleNewAd(user)} disabled={nav.current === NAV_ADD_POST || props.authFreeze}>
                            {
                                (nav.current === NAV_ADD_POST || props.authFreeze) ?
                                    <img
                                        src="assets/icon/btn-add-post-deact.svg"
                                        style={{ height: "40px" }}
                                    ></img>
                                    :

                                    <img
                                        src={(props.language == 'sinhala' ? "assets/icon/btn_sn_post_your_ad.svg" : (props.language == 'tamil' ? "assets/icon/btn_tm_post_your_ad.svg" : "assets/icon/btn-add-post-act.svg"))}
                                        style={{ height: (props.language == 'sinhala' ? "auto" : (props.language == 'tamil' ? "auto" : "40px")) }}
                                    ></img>
                            }
                        </Button>
                    )}
                </NavigationContext.Consumer>
            )}
        </UserContext.Consumer>
    );
}
export default AddPostButton;