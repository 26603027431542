// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap'); */
/* *{font-family:'Roboto', sans-serif;} */

#home-content{
  max-width: 550px;
  margin: 0 auto;
  align-self: center;
  --ion-background-color: #EBEEF1;
}
#hide-titlebar{
    margin-top: -60px;
}
#active-titlebar{
    display: block;
    width: 100%;
    height: 50px;
    background-color: #EBEEF1;
    transition: 2s top ease-in-out;
}

#feed-headroom{height: 50px;}

#banner{
  height: 190px;
}

#btn-load-more{
  /* style={{width:"148px", height:"48px", border:"2px solid #0080FF"}} */
  width: 148px;
  height: 48px;
  border: 2px solid #0080FF;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  color: #0080FF;
  border-radius: 24px;

}



`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA,kGAAkG;AAClG,yCAAyC;;AAEzC;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,+BAA+B;AACjC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA,eAAe,YAAY,CAAC;;AAE5B;EACE,aAAa;AACf;;AAEA;EACE,uEAAuE;EACvE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;;AAErB","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap'); */\n/* *{font-family:'Roboto', sans-serif;} */\n\n#home-content{\n  max-width: 550px;\n  margin: 0 auto;\n  align-self: center;\n  --ion-background-color: #EBEEF1;\n}\n#hide-titlebar{\n    margin-top: -60px;\n}\n#active-titlebar{\n    display: block;\n    width: 100%;\n    height: 50px;\n    background-color: #EBEEF1;\n    transition: 2s top ease-in-out;\n}\n\n#feed-headroom{height: 50px;}\n\n#banner{\n  height: 190px;\n}\n\n#btn-load-more{\n  /* style={{width:\"148px\", height:\"48px\", border:\"2px solid #0080FF\"}} */\n  width: 148px;\n  height: 48px;\n  border: 2px solid #0080FF;\n  background-color: #fff;\n  font-size: 16px;\n  font-weight: 600;\n  color: #0080FF;\n  border-radius: 24px;\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
