import { Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPosition } from "../../features/scroll/scrollSlice";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import "./MainNav.css";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1260px",
      padding: 0,

    },
  },
  menuitem: {
    textTransform: "none",
    fontFamily: "Titillium Web",
    color: "#000000",
    fontWeight: 600,
  },
  menuitemActive: {
    textTransform: "none",
    fontFamily: "Titillium Web",
    color: "#0080FF",
    fontWeight: 600,
  },
  active: {
    borderBottom: "3px solid #0080FF",
    position: "absolute",
    bottom: "-2px",
    transition: "all 0.5s ease",
    zIndex: 999,
  },
  menuname: {
    textTransform: "none",
    fontFamily: "Titillium Web",
    marginLeft: 10,
    color: "#000000",
    fontWeight: 600,
  },
  menunameActive: {
    textTransform: "none",
    fontFamily: "Titillium Web",
    marginLeft: 10,
    color: "#0080FF",
    fontWeight: 600,
  },
}));

const PAGE_ALL = 0;
const PAGE_MOTORS = 1;
const PAGE_MOBILE_ELECTRONICS = 2;
const PAGE_PROPERTY_SALE = 3;
const PAGE_PROPERTY_RENT = 4;
const PAGE_CLASSIFIEDS = 5;
const PAGE_OTHER = -1;

const MainNav = () => {
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [mainNavPage, setmainNavPage] = useState(PAGE_ALL);
  const scrollContent = document.getElementById("scrollable-root");
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    const path = location.pathname;
    const url = new URLSearchParams(window.location.search);
    const c1 =
      url
        .get("c1")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || "";
    switch (decodeURIComponent(c1)) {
      case "motors":
        setmainNavPage(PAGE_MOTORS);
        break;
      case "mobiles and electronics":
        setmainNavPage(PAGE_MOBILE_ELECTRONICS);
        break;
      case "property for sale":
        setmainNavPage(PAGE_PROPERTY_SALE);
        break;
      case "property for rent":
        setmainNavPage(PAGE_PROPERTY_RENT);
        break;
      case "classifieds":
        setmainNavPage(PAGE_CLASSIFIEDS);
        break;
      default:
        if (path === "/search" || path === "/home") setmainNavPage(PAGE_ALL);
        else setmainNavPage(PAGE_OTHER);
        break;
    }
  }, [window.location.search, location.pathname]);

  const checkNav = (cat: any) => {
    dispatch(resetPosition(0));
    handleScroll();
    navigate(`/search?c1=${encodeURIComponent(cat)}`);
  };

  const handleScroll = () => {
    scrollContent?.scrollTo(0, 0);
  };



  return (
    <div id="mainnav-back" style={{
      position: 'sticky',
      top: 50
    }}>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container justify="flex-end">
          {smallViewPort && <Box flexGrow={1}></Box>}

          <Box id="mainnav-btns">
            <Button
              className={`btn-name ${smallViewPort ? "mainnav-items" : "mainnav-items-mobile"
                }`}
              onClick={() => {

                navigate("/home");
                handleScroll();
              }}
              disableTouchRipple
              disableFocusRipple
              style={{
                backgroundColor: mainNavPage === PAGE_ALL ? "#F9F9F9" : "",
              }}
            >
              {mainNavPage === PAGE_ALL ? (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuitemActive}> {lan.ALL_ADS}</span>
              ) : (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuitem}>{lan.ALL_ADS}</span>
              )}

              {mainNavPage === PAGE_ALL ? (
                <span
                  className={classes.active}
                  style={{ width: "100%" }}
                ></span>
              ) : null}
            </Button>

            <Button
              className={`btn-name ${smallViewPort ? "mainnav-items" : "mainnav-items-mobile"
                }`}
              onClick={() => checkNav("motors")}
              disableTouchRipple
              disableFocusRipple
              style={{
                backgroundColor: mainNavPage === PAGE_MOTORS ? "#F9F9F9" : "",
              }}
            >
              <img
                src="assets/icon/icon-motors.svg"
                style={{ width: "24px", height: "17px" }}
                alt=""
              />
              {mainNavPage === PAGE_MOTORS ? (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menunameActive}>{lan.MOTORS}</span>
              ) : (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuname}>{lan.MOTORS}</span>
              )}

              {mainNavPage === PAGE_MOTORS ? (
                <span
                  className={classes.active}
                  style={{ width: "100%" }}
                ></span>
              ) : null}
            </Button>

            <Button
              className={`btn-name ${smallViewPort ? "mainnav-items" : "mainnav-items-mobile"
                }`}
              onClick={() => checkNav("mobiles and electronics")}
              disableTouchRipple
              disableFocusRipple
              style={{
                backgroundColor:
                  mainNavPage === PAGE_MOBILE_ELECTRONICS ? "#F9F9F9" : "",
              }}
            >
              <img
                src="assets/icon/icon-electronics.svg"
                style={{ width: "12px", height: "20px" }}
                alt=""
              />
              {mainNavPage === PAGE_MOBILE_ELECTRONICS ? (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menunameActive}>
                  {lan.MOBILE_AND_ELECTRONICS}
                </span>
              ) : (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuname}> {lan.MOBILE_AND_ELECTRONICS}</span>
              )}

              {mainNavPage === PAGE_MOBILE_ELECTRONICS ? (
                <span
                  className={classes.active}
                  style={{ width: "100%" }}
                ></span>
              ) : null}
            </Button>

            <Button
              className={`btn-name ${smallViewPort ? "mainnav-items" : "mainnav-items-mobile"
                }`}
              onClick={() => checkNav("property for sale")}
              disableTouchRipple
              disableFocusRipple
              style={{
                backgroundColor:
                  mainNavPage === PAGE_PROPERTY_SALE ? "#F9F9F9" : "",
              }}
            >
              <img
                src="assets/icon/icon-property-for-sale.svg"
                style={{ width: "20px", height: "20px" }}
                alt=""
              />
              {mainNavPage === PAGE_PROPERTY_SALE ? (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menunameActive}>
                  {lan.PROPERTY_FOR_SALE}
                </span>
              ) : (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuname}> {lan.PROPERTY_FOR_SALE}</span>
              )}
              {mainNavPage === PAGE_PROPERTY_SALE ? (
                <span
                  className={classes.active}
                  style={{ width: "100%" }}
                ></span>
              ) : null}
            </Button>

            <Button
              className={`btn-name ${smallViewPort ? "mainnav-items" : "mainnav-items-mobile"
                }`}
              onClick={() => checkNav("property for rent")}
              disableTouchRipple
              disableFocusRipple
              style={{
                backgroundColor:
                  mainNavPage === PAGE_PROPERTY_RENT ? "#F9F9F9" : "",
              }}
            >
              <img
                src="assets/icon/icon-proerty-for-rent.svg"
                style={{ width: "19px", height: "19px" }}
                alt=""
              />
              {mainNavPage === PAGE_PROPERTY_RENT ? (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menunameActive}>
                  {lan.PROPERTY_FOR_RENT}
                </span>
              ) : (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuname}> {lan.PROPERTY_FOR_RENT}</span>
              )}

              {mainNavPage === PAGE_PROPERTY_RENT ? (
                <span
                  className={classes.active}
                  style={{ width: "100%" }}
                ></span>
              ) : null}
            </Button>

            <Button
              className={`btn-name ${smallViewPort ? "mainnav-items" : "mainnav-items-mobile"
                }`}
              onClick={() => checkNav("classifieds")}
              disableTouchRipple
              disableFocusRipple
              style={{
                backgroundColor:
                  mainNavPage === PAGE_CLASSIFIEDS ? "#F9F9F9" : "",
              }}
            >
              <img
                src="assets/icon/icon-classifies.svg"
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
              {mainNavPage === PAGE_CLASSIFIEDS ? (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menunameActive}>{lan.CLASSIFIEDS}</span>
              ) : (
                <span style={{ fontSize: (getLanguage() !== 'english' ? '11px' : '13px') }} className={classes.menuname}>{lan.CLASSIFIEDS}</span>
              )}
              {mainNavPage === PAGE_CLASSIFIEDS ? (
                <span
                  className={classes.active}
                  style={{ width: "100%" }}
                ></span>
              ) : null}
            </Button>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default MainNav;
