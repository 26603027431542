import { createSlice } from "@reduxjs/toolkit";
import React, { useState } from "react";


export const slice = createSlice({
    name: "paymentMembership",
    initialState: {
        refNo: "",
        categories: [],
        membershipType: "",
        discount: 0,
        membershipDiscountedAmount: 0,
        membershipActualAmount: 0,
        userEmail: "",
        statusText:""

    },
    reducers: {
        setRefNo: (state, action) => {
            state.refNo = action.payload;
           
        },
        setCategory: (state, action) => {
            state.categories = action.payload;
        },
        setMembershipType: (state, action) => {
            state.membershipType = action.payload;
        },
        setDiscount: (state, action) => {
            state.discount = action.payload;
        },
        setMembershipDiscountedAmount: (state, action) => {
            state.membershipDiscountedAmount = action.payload;
        },
        setMembershipActualAmount: (state, action) => {
            state.membershipActualAmount = action.payload;
        },
        setMembershipUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setStatusText:(state,action)=>{
            state.statusText=action.payload;
        }

    }


});


export const {
    setRefNo,
    setCategory,
    setMembershipType,
    setDiscount,
    setMembershipDiscountedAmount,
    setMembershipActualAmount,
    setMembershipUserEmail,
    setStatusText


} = slice.actions;

export const selectRefNo = (state: any) => state.paymentMembership.refNo;
export const selectStatusText = (state: any) => state.paymentMembership.statusText;
export const selectCategory = (state: any) => state.paymentMembership.categories;
export const selectMembershipType = (state: any) => state.paymentMembership.membershipType;
export const selectDiscount = (state: any) => state.paymentMembership.discount;
export const selectMembershipEmail = (state: any) => state.paymentMembership.userEmail;
export const selectMembershipDiscountedAmount = (state: any) => state.paymentMembership.membershipDiscountedAmount;
export const selectSetMembershipActualAmount = (state: any) => state.paymentMembership.membershipActualAmount;

export default slice.reducer;

