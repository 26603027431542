import * as React from 'react';
import { Box, Stepper, Step, StepLabel, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';

const steps = ['Requested', 'Assign Draft', 'Payment Pending', 'Review', 'Complete'];
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiStepIcon-active": { color: "#0080FF" },
            "& .MuiStepIcon-completed": { color: "#0080FF" },
            "& .Mui-disabled .MuiStepIcon-root": { color: "#999999" }
        },
        paymentPending: {
            color: "#FF9C00"
        },
        stepperOutline: {
            border: '1px solid #0080FF',
            borderRadius: '4px',
        }

    })
);


export default function MyMembershipStepper(props: any) {
    const classes = useStyles();
    var step = 0;
    if (props.activeStep == '0') {
        step = 0;
    }else  if (props.activeStep == '1') {
        step = 1;
    }else  if (props.activeStep == '3') {
        step = 2;
    }else  if (props.activeStep == '2') {
        step = 3;
    }
    return (
        <div className={classes.stepperOutline}>
            <Stepper className={classes.root} activeStep={step} orientation={props.orientation}>
                <Step>
                    <StepLabel>Requested</StepLabel>
                </Step>
                {/* <Step>
                    <StepLabel>Assign Draft</StepLabel>
                </Step> */}
                <Step>
                    <StepLabel>Payment Pending</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Reviewing</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Complete</StepLabel>
                </Step>
            </Stepper>
        </div>
    );
}
