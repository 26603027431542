

export const translateTime = (time: string, language: string): string => {
    var t = '';
    if (time.includes('seconds ago')) {
        var amount = time.split('seconds ago')[0];
        t = (language == 'english' ? time : (language == 'sinhala' ? 'තත්පර ' + amount + 'කට පෙර' :  'நொடிகள் ' + amount));
    } else if (time.includes('minute ago')) {
        t = (language == 'english' ? time : (language == 'sinhala' ? 'මිනිත්තුවකට පෙර' : 'ஒரு நிமிடம் முன்பு'));
    }
    else if (time.includes('minutes ago')) {
        var amount = time.split('minutes ago')[0];
        t = (language == 'english' ? time : (language == 'sinhala' ? 'මිනිත්තු ' + amount + 'කට පෙර' : 'நிமிடங்கள் ' + amount));

    }
    else if (time.includes('hour ago')) {
        t = (language == 'english' ? time : (language == 'sinhala' ? 'පැයකට පෙර' : 'ஒரு மணி நேரம் முன்பு'));

    }
    else if (time.includes('hours ago')) {
        var amount = time.split('hours ago')[0];
        t = (language == 'english' ? time : (language == 'sinhala' ? 'පැය ' + amount + 'කට පෙර' : 'மணி ' + amount ));


    }
    else if (time.includes('day ago')) {
        t = (language == 'english' ? time : (language == 'sinhala' ? 'දිනකට පෙර' : 'முந்தைய நாள்'));

    }
    else if (time.includes('days ago')) {
        var amount = time.split('days ago')[0];
        t = (language == 'english' ? time : (language == 'sinhala' ? 'දින ' + amount + 'කට පෙර' : 'நாள் ' + amount));

    }
    else if (time.includes('month ago')) {
        t = (language == 'english' ? time : (language == 'sinhala' ? 'මසකට පෙර' : 'ஒரு மாதத்திற்கு முன்'));

    }
    else if (time.includes('months ago')) {
        var amount = time.split('months ago')[0];
        t = (language == 'english' ? time : (language == 'sinhala' ? 'මාස ' + amount + 'කට පෙර' : 'மாதங்கள் ' + amount));

    }else{
        t = (language == 'english' ? time : (language == 'sinhala' ? 'දැන්' : 'இப்போது'));

    }

    return t;
}