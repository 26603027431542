import React from "react";
import { NavigationContext } from "../../providers/NavigationProvider";
import { UserContext } from "../../providers/UserProvider";
import LoginRequired from "../LoginRequired/LoginRequired";
import SignUp from "../LoginRequired/SignUp";

const Boxes = (props: any) => {

  return (
    <>
      <NavigationContext.Consumer>
        {(navigation: any) => (
          <UserContext.Consumer>
            {(user: any) => (
              <><LoginRequired navigation={navigation} />
                <SignUp navigation={navigation}></SignUp></>
            )}
          </UserContext.Consumer>
        )}
      </NavigationContext.Consumer>
    </>
  );
};

export default Boxes;
