import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentLocation } from "../../../features/navigation/navigationSlice";
import {
  setInSearch,
  setSelectedCategories,
  setSelectedSubCategory,
} from "../../../features/search/searchSlice";
import {
  getAllCategories,
  selectCategories,
} from "../../../features/tags/tagsSlice";
import { NAV_SEARCH } from "../../../providers/NavigationProvider";
import "./SearchTagCloud.css";
import Chip from "@material-ui/core/Chip";
import { Add } from "@material-ui/icons";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  Box,
  Button,
  createStyles,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  getAllCities,
  getLocation,
  selectLocation,
} from "../../../features/addPost/addPostSlice";
import PuffLoader from "react-spinners/PuffLoader";
import { resetPosition } from "../../../features/scroll/scrollSlice";
import { SELECT_TAG, CLICK_ON_SIDEBAR_BANNER } from "../../../config/events";
import { ACTION_CLICK } from "../../../config/actions";
import { logEvent } from "firebase/analytics"
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { useNavigate } from "react-router-dom";
import { analytics } from "../../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      "& .MuiFilledInput-root": {
        background: "#F5F6F8",
      },
    },
    select: {
      "&.MuiInputBase-root": {
        backgroundColor: "#EBEEF1",
      },
    },
    catLink: {
      color: "#000000",
      "&:hover": {
        color: "#0080FF",
      },
    },
  })
);

const SearchTagCloud: React.FC = () => {
  const classes = useStyles();

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }


  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selection, setSelection] = useState("");
  const location = useSelector(selectLocation);
  const [selectedLocation, setselectedLocation] = useState("");

  const scrollContent = document.getElementById("scrollable-root");

  useEffect(() => {
    dispatch(setInSearch(false));
    dispatch(setInSearch(false));
    setSelection("");
    if (location.length <= 0) dispatch(getAllCities());
  }, []);

  useEffect(() => {
    if (categories.length <= 0) dispatch(getAllCategories());
    // console.log(categories);

  }, [categories]);

  useEffect(() => {
    if (selection) navigate("/tags/" + encodeURIComponent(selection));
  }, [selection]);

  const refreshSearch = (tags: any) => {
    const loc = tags.toLowerCase();
    navigate(`/search?tags=${loc}`);
  };

  const checkNav = (cat: any) => {
    const category = cat.toLowerCase();
    handleScroll();
    navigate(`/search?c1=${encodeURIComponent(category)}`);
  };

  const handleScroll = () => {
    scrollContent?.scrollTo(0, 0);
  };

  const buildTagSection = (
    tags: any[],
    title: string,
    name: string,
    icon: string,
    cls: string
  ) => {
    if (tags == undefined) return null;
    return (
      <section className="search-tag-section-wrapper">
        <header style={{ paddingLeft: "14px" }}>
          <img
            className={"search-tag-section-icon " + cls}
            src={icon}
            alt=""
          ></img>
          <span
            className="search-tag-section-title"
            onClick={() => checkNav(title)}
          >
            {name}
          </span>
        </header>
        <div className="search-tag-section-body-wrapper">
          <div style={{ paddingLeft: "20px", paddingBottom: "5px" }}>
            <List style={{ padding: "0" }}>
              {tags
                .filter((tag) => tag.usage_count > 0)
                .sort((x: any, y: any) => y.usage_count - x.usage_count)
                .map((tag) => buildTag(tag, tag.name))}
            </List>
          </div>
        </div>
      </section>
    );
  };

  const buildTag = (tag: any, name: string) => {
    return (
      <ListItem style={{ padding: "4px 0" }} key={tag?.id}>
        <Link
          underline="none"
          color="textPrimary"
          component="button"
          variant="body2"
          onClick={() => {
            handleTagClick(tag);
          }}
          className={classes.catLink}
        >
          {name[0].toUpperCase() + name.substring(1).toLowerCase()}
        </Link>
      </ListItem>
    );
  };

  const handleTagClick = (tag: any) => {
    dispatch(resetPosition({}));

    const cat = tag.name.toLowerCase();
    const res = categories.filter((c1: any) =>
      c1.sub_categories.map((sc: any) => sc.name).includes(tag.name)
    );
    navigate(
      `/search?c1=${res[0]?.name?.toLowerCase()}&c2=${encodeURIComponent(cat)}`
    );
    logEvent(analytics, SELECT_TAG, { action: ACTION_CLICK, platform: "DESKTOP" });
  };

  const renderCategories = () => {
    return (
      <div style={{ backgroundColor: "#F5F6F8", cursor: "pointer" }}>
        {categories.length != 0 ? null : (
          <Box display="flex" justifyContent="center" padding={5}>
            <PuffLoader loading={true} color="#0080ff" />
          </Box>
        )}

        {buildTagSection(
          categories?.filter((c: any) => c?.name === "MOTORS")[0]
            ?.sub_categories,
          "Motors",
          `${lan.MOTORS}`,
          "assets/icon/icon-motors.svg",
          "wider-icon"
        )}
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "PROPERTY FOR SALE")[0]
            ?.sub_categories,
          "Property for Sale",
          `${lan.PROPERTY_FOR_SALE}`,
          "assets/icon/icon-property-for-sale.svg",
          ""
        )}
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "PROPERTY FOR RENT")[0]
            ?.sub_categories,
          "Property for Rent",
          `${lan.PROPERTY_FOR_RENT}`,
          "assets/icon/icon-proerty-for-rent.svg",
          ""
        )}
        {buildTagSection(
          categories?.filter(
            (c: any) => c?.name === "MOBILES AND ELECTRONICS"
          )[0]?.sub_categories,
          "Mobiles and Electronics",
          `${lan.MOBILE_AND_ELECTRONICS}`,
          "assets/icon/icon-electronics.svg",
          ""
        )}
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "CLASSIFIEDS")[0]
            ?.sub_categories,
          "Classifieds",
          `${lan.CLASSIFIEDS}`,
          "assets/icon/icon-classifies.svg",
          ""
        )}

        {/* <div style={{ height: "135px" }}></div> */}
      </div>
    );
  };

  const onLocationSelect = (location: any) => {

    setselectedLocation(location);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  const handleBannerClick = () => {
    logEvent(analytics, CLICK_ON_SIDEBAR_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };

  return (
    <div style={{ position: 'relative', paddingTop: '30px' }}>
      <div style={{ marginBottom: "23px" }}>
        <div id="tag-title-header">
          <div>
            {/* Search by Location */}
            {lan.SEACRH_BY_LOCATION}
          </div>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              className={classes.color}
              id="location"
              options={location}
              inputValue={selectedLocation}
              onInputChange={(event: any, location: string | null) => {
                onLocationSelect(location);
              }}
              getOptionLabel={(option: any) => option.name}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label={lan.LOCATION} variant="filled" />
              )}
            />
          </Grid>
          <Grid item xs={12} id="btn-apply-cover">
            <Button
              variant="contained"
              color="primary"
              id="btn-apply"
              onClick={() => refreshSearch(selectedLocation)}
            >
              <span id="btn-apply-name">
                {/* Apply Location */}
                {lan.APPLY_LOCATION}
              </span>
            </Button>
          </Grid>
          {/* onClick={(e) => getValues(selectedCat.level2, e)}  */}
        </Grid>
      </div>
      <div>
        <div id="tag-title-header">
          <div>
            {/* Search by Category */}
            {lan.SEARCH_BY_CATEGORY}
          </div>
        </div>
        {renderCategories()}
      </div>
      <div style={{ marginTop: "30px" }} onClick={handleBannerClick}>
        {/* <img src="assets/walls/electronics-300x250.png" alt=""></img> */}

        <iframe scrolling="no"

          style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
          src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Medium-Rectangle-300x250/index.html" />
      </div>
    </div>
  );
};

export default SearchTagCloud;
