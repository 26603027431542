import { Post } from "./post/Post";
import { Plugins } from "@capacitor/core";
import { SHARE_POST, SHARE_POST_CANCEL } from "../config/events";
import { ACTION_CLICK } from "../config/actions";
import { analytics } from "..";
import { logEvent } from "firebase/analytics";

const { Share } = Plugins;

export const currencyConverter = () => {
  return new Intl.NumberFormat("si-LK", {
    currency: "LKR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const sharePost = async (item: Post, events: any) => {
  await Share.share({
    title: item.title,
    text: item.description,
    url: "http://ionicframework.com/",
    dialogTitle: "Share this Ad",
  })
    .then((e: any) =>
      logEvent(analytics, SHARE_POST, { item: item, action: ACTION_CLICK })
    )
    .catch((e: any) =>
      logEvent(analytics, SHARE_POST_CANCEL, { item: item, action: ACTION_CLICK })
    );
};

export const isPostResellable = (item: any, unlimitedReselling: any) => {
  return (
    item.live_time + item.resell_reload < new Date().getTime() &&
    (unlimitedReselling || item.approval === 1)
  );
};


export const isPostResold = (item: any) => {
  return (
    (item.approval === 1) && item.created_at + item.resell_reload < new Date().getTime()
  );
};