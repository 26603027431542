import { logEvent } from "firebase/analytics"
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../components/Footer/Footer";
import TitleBar from "../../components/TitleBar/TitleBar";
import { ACTION_VIEW } from "../../config/actions";
import { VIEW_LANDING_PAGE } from "../../config/events";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { NAV_LANDING } from "../../providers/NavigationProvider";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Container,
  Typography,
} from "@material-ui/core";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: "1260px",
        padding: 0,
      },
    },
    maintenance: {
      width: "450px",
    },
    text: { fontSize: "24px", color: "#000000", fontWeight: 700 },
  })
);

const Maintenance: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setCurrentLocation(NAV_LANDING));
    logEvent(analytics, VIEW_LANDING_PAGE, {
      action: ACTION_VIEW,
      platform: "DESKTOP",
    });
  }, []);

  return (
    <div>
      <TitleBar></TitleBar>

      <div style={{ maxHeight: "100vh", overflowY: "scroll" }}>
        <Container maxWidth="lg" className={classes.root}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            py={5}
            style={{
              height: "500px",
            }}
          >
            <Box className={classes.maintenance} mb={3}>
              <img src="assets/images/maintenance.png" alt="" />
            </Box>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.text}
              style={{
                marginBottom: 0,
              }}
            >
              Hang on!
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.text}
              style={{
                fontSize: "22px",
              }}
            >
              We are under maintenance.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                color: "#5a5a5a",
                fontSize: "18px",
              }}
            >
              Sorry for the inconvenience but we’re performing some maintenance
              at the moment. We’ll be back soon !
            </Typography>
          </Box>
        </Container>

        <Footer></Footer>
      </div>
    </div>
  );
};

export default Maintenance;
