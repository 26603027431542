import React, { useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Post } from "../../features/post/Post";
import PostCard from "../Post/PostCardFixed/PostCard";
import "./MyLikes.css";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Fab,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoader from "react-spinners/PulseLoader";
import Alert from "@material-ui/lab/Alert";
import { ArrowUpward } from "@material-ui/icons";
import PostCardMobile from "../Post/PostCardFixed/PostCardMobile/PostCardMobile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnApply: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
  })
);

const MyLikes = (props: any) => {
  const items = props.items;
  const styles = useStyles();

  return (
    <div id="card-backgroud">
      {items.length > 0 ? (
        
        <InfiniteScroll
          hasMore={true}
          loader={
            <Box display="flex" justifyContent="center">
              {props.busy ? (
                <PulseLoader color="#0080ff" loading={true} />
              ) : null}
            </Box>
          }
          next={() => { }}
          dataLength={items.length}
          scrollableTarget="scrollable-root"
          endMessage={
            <Alert severity="success" color="info">
              You have seen all
            </Alert>
          }
        >
          {/* {
          items.map((item: Post, index: number) => (
            <div key={item.id} style={{ padding: "10px 5px" }}>
              <LazyLoadComponent
                placeholder={
                  <img
                    src="assets/images/Plase-holder-listing.png"
                    style={{ width: "903px", height: "194px" }}
                  ></img>
                }
                key={item.id}
              >
                <PostCard
                  post={item}
                  position={index + 1}
                  detailsAction={() => { }}
                ></PostCard>
              </LazyLoadComponent>
            </div>
          ))} */}


                
            <Hidden xsDown={true} smDown={true}>
              {items.map((item: Post, index: number) => (
                <div key={item.id} style={{ padding: "1px 5px" }}>
                  <LazyLoadComponent
                    placeholder={
                      <img
                        src="assets/images/Place-holder-listing.png"
                        style={{ width: "707px", height: "194px" }}
                      ></img>
                    }
                    key={item.id}
                  >
                    <PostCard
                      post={item}
                      position={index + 1}
                      detailsAction={() => { }}
                    ></PostCard>

                  </LazyLoadComponent>
                </div>
              ))}
            </Hidden>


            <Hidden lgUp={true} mdUp={true}>
              {items.map((item: Post, index: number) => (
                <div key={item.id} style={{ padding: "5px 0px" }}>
                  <LazyLoadComponent
                    placeholder={
                      <img
                        src="assets/images/Place-holder-listing.png"
                        style={{ width: "auto", height: "100px" }}
                      ></img>
                    }
                    key={item.id}
                  >
                    <PostCardMobile
                      post={item}
                      position={index + 1}
                      detailsAction={() => { }}
                    ></PostCardMobile>
                  </LazyLoadComponent>
                </div>
              ))}
            </Hidden>


        </InfiniteScroll>
      ) : (
        <Box display="flex" justifyContent="center">
          <PulseLoader color="#0080ff" loading={true} />
        </Box>
      )}
      {items.length > 0 ? (
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "50px 50px 0 50px",
              marginBottom: -53,
            }}
          >
            {props.busy ? null : (
              <Button
                color="primary"
                id="btn-load-more"
                onClick={props.loadMorePosts}
                className={styles.btnApply}
              >
                <span>Load More</span>
              </Button>
            )}
          </Grid>
        </Grid>
      ) : null}
      {props.page > 0 ? (
        <div
          style={{
            position: "sticky",
            bottom: "100px",
            zIndex: 999,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginLeft: "340px",
            pointerEvents: "none",
          }}
        >
          {props.showScrollTop ? (
            <Fab
              color="primary"
              aria-label="add"
              onClick={props.handleScrollToTopRequest}
              style={{ pointerEvents: "all" }}
            >
              <ArrowUpward></ArrowUpward>
            </Fab>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default MyLikes;
