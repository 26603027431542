import { Avatar, Button, Card, CardActionArea, CardMedia, CircularProgress, CssBaseline, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentLocation } from "../../../features/navigation/navigationSlice";
import { PAYMENT_SUMMERY } from "../../../providers/NavigationProvider";
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import axios from "axios";
import { frontend, PAYMENT_SUMMERY_DETAILS, PAYMENT_SUMMERY_DETAILS_MEMBER, PAYMENT_UPDATE_REQUEST_STATUS_FOR_PAYMENT_MEMBERSHIP } from "../../../config/urls";
import { setRef } from "../../../config/locations";


const useStyles = makeStyles({
    cardroot: {
        width: "60%",
        minWidth: 345,
        margin: "auto"
    },
    media: {
        height: 400,
    },

});
var ref = '';
const MembershipSummery = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [view, setView] = useState(false);
    const [date, setDate] = useState('');
    const [responseText, setResponseText] = useState('');
    const [currenceyTpe, setCurrencyType] = useState('');
    const [amount, setAmount] = useState(0);
    const [adOns, setAdOns] = useState([]);
    const [clientRef, setClientRef] = useState('');
    const [transactionRef, setTransactionRef] = useState('');
    const m = useLocation().search.split("?msg=")[1];
    const msg = m.split("-")[0];


    // var adOns = [] as any[];

    var addOnTotal = 0;

    // const [view, setView] = useState(false);

    useEffect(() => {

        dispatch(setCurrentLocation(PAYMENT_SUMMERY));
        getSummeryDetails();
    }, []);
    // const setAdOns = (ar: []) => {
    //     adOns = ar;
    // }
    var refNo = m.split("-")[1];
    var viewSideBar = false;
    const getSummeryDetails = () => {
        // axios.get(PAYMENT_SUMMERY_DETAILS + `/${paymentId}/`)
        //     .then((response) => {
        //         // data=JSON.stringify(response.data);
        //     });




        if (refNo !== '') {
            axios.get(PAYMENT_SUMMERY_DETAILS_MEMBER + `/${refNo}`)
                .then((response) => {
                    // alert(response.data.id);


                    setDate(response.data.actualDate);
                    setResponseText(response.data.statusText);
                    setCurrencyType(response.data.paymentType);
                    setAmount(response.data.totalAmount);
                    if (msg === "0") {
                        axios.put(PAYMENT_UPDATE_REQUEST_STATUS_FOR_PAYMENT_MEMBERSHIP + response.data.userHasMembershipId).then().catch(error => console.log(error))

                    }

                    setView(true);
                    sendMsg(response.data.userHasMembershipId, response.data.salesId, response.data.totalAmount);
                });
        }
    }

    const sendMsg = (uhm: string, salesId: string, amount: string) => {
        const key = 'AJSDSH232364CC7858N5864B94056';
        axios.post(`https://api.saruwata.lk/membership/sendSMSToCustomer/${uhm}/${salesId}/${key}`, { amount: amount })
            .then(data => '').catch(e => console.log(e))
    }


    return (
        <React.Fragment>
            <CssBaseline />
            <>
                {(view ?
                    <div>
                        <Typography style={{ fontSize: 17, fontWeight: 'bold', paddingBottom: '12px' }}>
                            {((msg === "0" || msg == "4") ? "Payment Success" : " Payment Error")}

                        </Typography>
                        <Divider></Divider>



                        {

                            ((msg === "0" || msg == "4") ?
                                <>
                                    <div style={{ border: "1px solid #DDDDDD", borderRadius: "8px", marginTop: "5px", paddingTop: "20px", paddingBottom: "20px" }}>
                                        <Grid container>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Avatar src="../../assets/payment/icon-payments-success.svg" variant="square" style={{ width: '56px', height: "56px", marginLeft: "auto", marginRight: "auto" }} />
                                                <Typography style={{ fontWeight: 600, fontSize: "17px" }}>Payment Successfully Received! </Typography>
                                                <Typography style={{ fontSize: "17px", marginTop: "15px" }}>Thank you for your payment.</Typography>

                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{ backgroundColor: "#F5F6F8", padding: "1px 5px", marginTop: "5px" }}>
                                        <Typography style={{ padding: "3px 20px", color: "#5A5A5A" }}>
                                            Here is a summary of your recent payment.
                                        </Typography>
                                        <div style={{ backgroundColor: "#FFFF", margin: "10px 0" }}>

                                            <Grid container>

                                                <Grid item xs={12} style={{ padding: "0px 20px" }}>
                                                    <Typography style={{ color: "#0080FF" }}>
                                                        Payment Details
                                                    </Typography>
                                                    <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                            <div style={{ fontWeight: "bold" }}>Date / Time:</div>
                                                            {/* <div>2021-08-31 | 11:12:10 AM</div> */}
                                                            {(date != '' && date != undefined && date != null ? <div>{date.split(" ")[0]} | {date.split(" ")[1]}</div> : null)}

                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                            <div style={{ fontWeight: "bold" }}>Status:</div>
                                                            {/* <div>Payment Success</div> */}
                                                            <div>{responseText}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%" }}>
                                                            <div style={{ fontWeight: "bold" }}>Payment Method:</div>
                                                            {/* <div>Visa</div> */}
                                                            <div>{currenceyTpe}</div>
                                                        </div>
                                                    </div>
                                                    <Divider variant="middle" />
                                                </Grid>
                                                <Grid item xs={12} style={{ padding: "0px 20px" }}>



                                                    <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "60%" }}>
                                                            <div style={{ fontWeight: "bold" }}>Total you paid</div>

                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%", textAlign: "right" }}>
                                                            {/* <div style={{ color: "#0080FF", fontWeight: 600 }}>Rs. 1,825</div> */}
                                                            <div style={{ color: "#0080FF", fontWeight: 600 }}>Rs. {amount}</div>

                                                        </div>

                                                    </div>

                                                </Grid>
                                            </Grid>

                                        </div>
                                    </div>

                                </>

                                :
                                <div style={{ border: "1px solid #DDDDDD", borderRadius: "8px", marginTop: "5px", paddingTop: "20px", paddingBottom: "20px" }}>
                                    <Grid container>
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Avatar src="../../assets/payment/icon-payment-failed.svg" variant="square" style={{ width: '56px', height: "56px", marginLeft: "auto", marginRight: "auto" }} />
                                            <Typography style={{ fontWeight: 600, fontSize: "17px" }}>Oops! Payment Failed</Typography>
                                            <Typography style={{ fontSize: "14px", marginTop: "15px", color: "#F5222D" }}>We aren't able to process your payment.</Typography>
                                            <Typography style={{ fontSize: "14px", color: "#F5222D" }}>Please try again. </Typography>
                                            <Typography style={{ marginTop: "10px", fontSize: "12px", color: "#000000", fontWeight: 600 }}>Date / Time</Typography>
                                            <Typography style={{ fontSize: "12px", color: "#333333" }}>{(date != '' && date != undefined && date != null ? <div>{date.split(" ")[0]} | {date.split(" ")[1]}</div> : null)}
                                            </Typography>
                                            <Typography style={{ marginTop: "10px", fontSize: "12px", color: "#000000", fontWeight: 600 }}>Total amount</Typography>
                                            <Typography style={{ fontSize: "16px", color: "#0080FF", fontWeight: 600 }}>Rs.{amount}</Typography>
                                            <a href={`${frontend}/myads`}><Button style={{ marginTop: "10px", fontSize: "16px", color: "#FFFFFF", backgroundColor: "#0080FF", width: "180px", height: "40px", transform: "" }}>Try again</Button></a>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }




                    </div>
                    :
                    <>
                        <CircularProgress style={{ position: "absolute", left: "45%", top: "30%" }} />
                    </>
                )}

            </>
        </React.Fragment>
    );
}

export default MembershipSummery;