// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#filters-header{
    width: 100%;
    padding-bottom: 7px;
    border-bottom: 2px solid #0080FF;
    font-size: 15px;
    color: #000000;
    font-weight: 600;
}
#btn-apply-cover{
    padding: 22px 0px;
    display: flex;
    justify-content: center; 
    align-items: center;
    background-color: #EBEEF1 ;
}
#btn-apply{
    width: 262px;
    height: 46px;
    display: flex;
    justify-content: center; 
    padding: 11px 0px;
    border-color: #0080FF;
    background-color: #fff ;
}
#btn-apply-name{
    color: #0080FF;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
}

#location{
    font-size: 14px;
}

.MuiButtonBase-root.MuiRadio-root{
    padding: 5px 9px !important;
}

.MuiFormLabel-root, input.MuiInputBase-input, .MuiInputBase-input, .MuiTypography-root.MuiFormControlLabel-label{
    font-size: 14px !important;
}

.MuiFilledInput-underline:before{
    border-bottom-color: #ddd !important;
}

.MuiInputLabel-filled{
    padding-left: 10px !important;
}

.MuiFilledInput-root.MuiFilledInput-underline{
    padding-left: 8px !important;
}

.MuiFilledInput-input.MuiAutocomplete-input{
    padding-left: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/FiltersModal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,gCAAgC;IAChC,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;AAC9B;AACA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":["#filters-header{\n    width: 100%;\n    padding-bottom: 7px;\n    border-bottom: 2px solid #0080FF;\n    font-size: 15px;\n    color: #000000;\n    font-weight: 600;\n}\n#btn-apply-cover{\n    padding: 22px 0px;\n    display: flex;\n    justify-content: center; \n    align-items: center;\n    background-color: #EBEEF1 ;\n}\n#btn-apply{\n    width: 262px;\n    height: 46px;\n    display: flex;\n    justify-content: center; \n    padding: 11px 0px;\n    border-color: #0080FF;\n    background-color: #fff ;\n}\n#btn-apply-name{\n    color: #0080FF;\n    font-size: 16px;\n    text-transform: capitalize;\n    font-weight: 600;\n}\n\n#location{\n    font-size: 14px;\n}\n\n.MuiButtonBase-root.MuiRadio-root{\n    padding: 5px 9px !important;\n}\n\n.MuiFormLabel-root, input.MuiInputBase-input, .MuiInputBase-input, .MuiTypography-root.MuiFormControlLabel-label{\n    font-size: 14px !important;\n}\n\n.MuiFilledInput-underline:before{\n    border-bottom-color: #ddd !important;\n}\n\n.MuiInputLabel-filled{\n    padding-left: 10px !important;\n}\n\n.MuiFilledInput-root.MuiFilledInput-underline{\n    padding-left: 8px !important;\n}\n\n.MuiFilledInput-input.MuiAutocomplete-input{\n    padding-left: 12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
