export const cars = 'CARS';
export const bikes = 'MOTORBIKES';
export const vans = 'VANS';
export const three_wheelers = 'THREE WHEELERS';
export const busses_lorries = 'BUSES & LORRIES';
export const heavey_vehicles = 'HEAVY VEHICLES';

export const mobiles = 'MOBILE PHONES';
export const mobile_parts = 'MOBILE ACCESSORIES';
export const tab = 'LAPTOP DESKTOP & TABLETS';
export const audio_speakers = 'AUDIO & SPEAKERS';
export const tv = 'ELECTRONIC HOME APPLIANCES';
export const computers = 'COMPUTER ACCESSORIES';
export const cameras = 'CAMERAS & CAMCORDERS';
export const other_electronics = 'OTHER ELECTRONICS';




export const apartment_sale = 'APARTMENTS FOR SALE';
export const house_sale = 'HOUSES & VILLAS FOR SALE';
export const commercial_sale = 'COMMERCIAL FOR SALE';
export const commercial_sale_ = 'COMMERCIAL PROPERTY FOR SALE';
export const land_sale = 'LANDS FOR SALE';

export const apartment_rent = 'APARTMENTS FOR RENT';
export const land_rent = 'LANDS FOR RENT';
export const house_rent = 'HOUSES FOR RENT';
export const commercial_rent = 'COMMERCIAL PROPERTY FOR RENT';
export const room_rent = 'ROOMS FOR RENT';
export const daily = 'SHORT TERM (DAILY)';
export const monthly = 'SHORT TERM (MONTHLY)';
