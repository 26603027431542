import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice(
    {
        name: "language",
        initialState: {
            lan: 'en',
        },
        reducers: {

            setLanguage: (state, action) => {
                
                state.lan = action.payload;

            }
        }
    }
);

export const {
    setLanguage
} = slice.actions;

export const selectLanguage = (state: any) => state.language.lan;
export default slice.reducer;