import React from "react";

const locationsAll={
    "BATTICALOA": [
        "ADDALAICHENAI - BATTICALOA",
        "AMPILANTHURAI - BATTICALOA",
        "ARAIPATTAI - BATTICALOA",
        "AYITHIYAMALAI - BATTICALOA",
        "BAKIELLA - BATTICALOA",
        "BATTICALOA - BATTICALOA",
        "CHEDDIPALAYAM - BATTICALOA",
        "CHENKALADI - BATTICALOA",
        "ERAVUR - BATTICALOA",
        "KALUWANCHIKUDI - BATTICALOA",
        "KALUWANKEMY - BATTICALOA",
        "KANNANKUDAH - BATTICALOA",
        "KARADIYANARU - BATTICALOA",
        "KATHIRAVELI - BATTICALOA",
        "KATTANKUDI - BATTICALOA",
        "KIRAN - BATTICALOA",
        "KIRANKULAM - BATTICALOA",
        "KODDAIKALLAR - BATTICALOA",
        "KOKKADDICHCHOLAI - BATTICALOA",
        "KURUKKALMADAM - BATTICALOA",
        "MANDUR - BATTICALOA",
        "MIRAVODAI - BATTICALOA",
        "MURAKOTTANCHANAI - BATTICALOA",
        "NAVAGIRINAGAR - BATTICALOA",
        "NAVATKADU - BATTICALOA",
        "ODDAMAVADI - BATTICALOA",
        "PALAMUNAI - BATTICALOA",
        "PANKUDAVELY - BATTICALOA",
        "PERIYAPORATIVU - BATTICALOA",
        "PERIYAPULLUMALAI - BATTICALOA",
        "PILLAIYARADI - BATTICALOA",
        "PUNANAI - BATTICALOA",
        "THANNAMUNAI - BATTICALOA",
        "THETTATIVU - BATTICALOA",
        "THIKKODAI - BATTICALOA",
        "THIRUPALUGAMAM - BATTICALOA",
        "UNNICHCHAI - BATTICALOA",
        "VAKANERI - BATTICALOA",
        "VAKARAI - BATTICALOA",
        "VALAICHENAI - BATTICALOA",
        "VANTHARUMOOLAI - BATTICALOA",
        "VELLAVELY - BATTICALOA"
    ],
    "PUTTALAM": [
        "ADIPPALA - PUTTALAM",
        "ALUTWEWA - PUTTALAM",
        "AMBAKANDAWILA - PUTTALAM",
        "ANAMADUWA - PUTTALAM",
        "ANDIGAMA - PUTTALAM",
        "ANGUNAWILA - PUTTALAM",
        "ATTAWILLUWA - PUTTALAM",
        "BANGADENIYA - PUTTALAM",
        "BARANANKATTUWA - PUTTALAM",
        "BATTULUOYA - PUTTALAM",
        "BUJJAMPOLA - PUTTALAM",
        "CHILAW - PUTTALAM",
        "DALUKANA - PUTTALAM",
        "DANKOTUWA - PUTTALAM",
        "DEWAGALA - PUTTALAM",
        "DUMMALASURIYA - PUTTALAM",
        "DUNKANNAWA - PUTTALAM",
        "ELUWANKULAMA - PUTTALAM",
        "ETTALE - PUTTALAM",
        "GALAMUNA - PUTTALAM",
        "GALMURUWA - PUTTALAM",
        "HANSAYAPALAMA - PUTTALAM",
        "IHALA KOTTARAMULLA - PUTTALAM",
        "ILIPPADENIYA - PUTTALAM",
        "INGINIMITIYA - PUTTALAM",
        "ISMAILPURAM - PUTTALAM",
        "JAYASIRIPURA - PUTTALAM",
        "KAKKAPALLIYA - PUTTALAM",
        "KALKUDAH - PUTTALAM",
        "KALLADIYA - PUTTALAM",
        "KANDAKULIYA - PUTTALAM",
        "KARATHIVU - PUTTALAM",
        "KARAWITAGARA - PUTTALAM",
        "KARUWALAGASWEWA - PUTTALAM",
        "KATUNERIYA - PUTTALAM",
        "KOSWATTA - PUTTALAM",
        "KOTTANTIVU - PUTTALAM",
        "KOTTAPITIYA - PUTTALAM",
        "KOTTUKACHCHIYA - PUTTALAM",
        "KUMARAKATTUWA - PUTTALAM",
        "KURINJANPITIYA - PUTTALAM",
        "KURUKETIYAWA - PUTTALAM",
        "LUNUWILA - PUTTALAM",
        "MADAMPE - PUTTALAM",
        "MADURANKULIYA - PUTTALAM",
        "MAHAKUMBUKKADAWALA - PUTTALAM",
        "MAHAUSWEWA - PUTTALAM",
        "MAMPITIYA - PUTTALAM",
        "MAMPURI - PUTTALAM",
        "MANGALAELIYA - PUTTALAM",
        "MARAWILA - PUTTALAM",
        "MUDALAKKULIYA - PUTTALAM",
        "MUGUNUWATAWANA - PUTTALAM",
        "MUKKUTODUWAWA - PUTTALAM",
        "MUNDEL - PUTTALAM",
        "MUTTIBENDIWILA - PUTTALAM",
        "NAINAMADAMA - PUTTALAM",
        "NALLADARANKATTUWA - PUTTALAM",
        "NATTANDIYA - PUTTALAM",
        "NAWAGATTEGAMA - PUTTALAM",
        "NELUMWEWA - PUTTALAM",
        "NORACHCHOLAI - PUTTALAM",
        "PALLAMA - PUTTALAM",
        "PALLIWASALTURAI - PUTTALAM",
        "PANIRENDAWA - PUTTALAM",
        "PARAKRAMASAMUDRAYA - PUTTALAM",
        "POTHUWATAWANA - PUTTALAM",
        "PUTTALAM - PUTTALAM",
        "PUTTALAM CEMENT FACTORY - PUTTALAM",
        "RAJAKADALUWA - PUTTALAM",
        "SALIYAWEWA JUNCTION - PUTTALAM",
        "SERUKELE - PUTTALAM",
        "SIYAMBALAGASHENE - PUTTALAM",
        "TABBOWA - PUTTALAM",
        "TALAWILA CHURCH - PUTTALAM",
        "TODUWAWA - PUTTALAM",
        "UDAPPUWA - PUTTALAM",
        "URIDYAWA - PUTTALAM",
        "VANATHAWILLUWA - PUTTALAM",
        "WAIKKAL - PUTTALAM",
        "WATUGAHAMULLA - PUTTALAM",
        "WENNAPPUWA - PUTTALAM",
        "WIJEYAKATUPOTHA - PUTTALAM",
        "WILPOTHA - PUTTALAM",
        "YODAELA - PUTTALAM",
        "YOGIYANA - PUTTALAM"
    ],
    "KALUTARA": [
        "AGALAWATTA - KALUTARA",
        "ALUBOMULLA - KALUTARA",
        "ALUTHGAMA - KALUTARA",
        "ANGURUWATOTA - KALUTARA",
        "ATALE - KALUTARA",
        "BADURALIYA - KALUTARA",
        "BANDARAGAMA - KALUTARA",
        "BATUGAMPOLA - KALUTARA",
        "BELLANA - KALUTARA",
        "BERUWALA - KALUTARA",
        "BOLOSSAGAMA - KALUTARA",
        "BOMBUWALA - KALUTARA",
        "BORALUGODA - KALUTARA",
        "BULATHSINHALA - KALUTARA",
        "DANAWALA THINIYAWALA - KALUTARA",
        "DELMELLA - KALUTARA",
        "DHARGA TOWN - KALUTARA",
        "DIWALAKADA - KALUTARA",
        "DODANGODA - KALUTARA",
        "DOMBAGODA - KALUTARA",
        "ETHKANDURA - KALUTARA",
        "GALPATHA - KALUTARA",
        "GAMAGODA - KALUTARA",
        "GONAGALPURA - KALUTARA",
        "GONAPOLA JUNCTION - KALUTARA",
        "GOVINNA - KALUTARA",
        "GURULUBADDA - KALUTARA",
        "HALKANDAWILA - KALUTARA",
        "HALTOTA - KALUTARA",
        "HALVITIGALA COLONY - KALUTARA",
        "HALWALA - KALUTARA",
        "HALWATURA - KALUTARA",
        "HANDAPANGODA - KALUTARA",
        "HEDIGALLA COLONY - KALUTARA",
        "HENEGAMA - KALUTARA",
        "HETTIMULLA - KALUTARA",
        "HORANA - KALUTARA",
        "ITTAPANA - KALUTARA",
        "KAHAWALA - KALUTARA",
        "KALAWILA KIRANTHIDIYA - KALUTARA",
        "KALUTARA - KALUTARA",
        "KANANWILA - KALUTARA",
        "KANDANAGAMA - KALUTARA",
        "KELINKANDA - KALUTARA",
        "KITULGODA - KALUTARA",
        "KOHOLANA - KALUTARA",
        "KUDA UDUWA - KALUTARA",
        "LABBALA - KALUTARA",
        "LHALAHEWESSA - KALUTARA",
        "LNDURUWA - KALUTARA",
        "LNGIRIYA - KALUTARA",
        "MAGGONA - KALUTARA",
        "MAHAGAMA - KALUTARA",
        "MAHAKALUPAHANA - KALUTARA",
        "MAHARANGALLA - KALUTARA",
        "MALGALLA TALANGALLA - KALUTARA",
        "MATUGAMA - KALUTARA",
        "MEEGAHATENNA - KALUTARA",
        "MEEGAMA - KALUTARA",
        "MEEGODA - KALUTARA",
        "MILLANIYA - KALUTARA",
        "MILLEWA - KALUTARA",
        "MIWANAPALANA - KALUTARA",
        "MOLKAWA - KALUTARA",
        "MORAGAHAHENA - KALUTARA",
        "MORAPITIYA - KALUTARA",
        "MORONTUDUWA - KALUTARA",
        "NAWATTUDUWA - KALUTARA",
        "NEBODA - KALUTARA",
        "PADAGODA - KALUTARA",
        "PAHALAHEWESSA - KALUTARA",
        "PAIYAGALA - KALUTARA",
        "PANADURA - KALUTARA",
        "PARAGASTOTA - KALUTARA",
        "PARAGODA - KALUTARA",
        "PARAIGAMA - KALUTARA",
        "PELANDA - KALUTARA",
        "PIMBURA - KALUTARA",
        "PITAGALDENIYA - KALUTARA",
        "POKUNUWITA - KALUTARA",
        "PORUWEDANDA - KALUTARA",
        "RATMALE - KALUTARA",
        "REMUNAGODA - KALUTARA",
        "TALGASWELA - KALUTARA",
        "TEBUWANA - KALUTARA",
        "THANTHIRIMULLA - KALUTARA",
        "UDUWARA - KALUTARA",
        "UTUMGAMA - KALUTARA",
        "VEYANGALLA - KALUTARA",
        "WADDUWA - KALUTARA",
        "WALAGEDARA - KALUTARA",
        "WALALLAWITA - KALUTARA",
        "WALANA - KALUTARA",
        "WASKADUWA - KALUTARA",
        "WELIPENNA - KALUTARA",
        "WELIVERIYA - KALUTARA",
        "WELMILLA JUNCTION - KALUTARA",
        "WERAGALA - KALUTARA",
        "YAGIRALA - KALUTARA",
        "YATADOLAWATTA - KALUTARA",
        "YATAWARA JUNCTION - KALUTARA"
    ],
    "GALLE": [
        "AGALIYA - GALLE",
        "AHANGAMA - GALLE",
        "AHUNGALLA - GALLE",
        "AKMEEMANA - GALLE",
        "ALAWATUGODA - GALLE",
        "ALUTHWALA - GALLE",
        "AMBALANGODA - GALLE",
        "AMPEGAMA - GALLE",
        "AMUGODA - GALLE",
        "ANANGODA - GALLE",
        "ANGULUGAHA - GALLE",
        "ANKOKKAWALA - GALLE",
        "ASELAPURA - GALLE",
        "BADDEGAMA - GALLE",
        "BALAPITIYA - GALLE",
        "BANAGALA - GALLE",
        "BATAPOLA - GALLE",
        "BENTOTA - GALLE",
        "BOOSSA - GALLE",
        "DELLAWA - GALLE",
        "DIKKUMBURA - GALLE",
        "DODANDUWA - GALLE",
        "ELLA TANABADDEGAMA - GALLE",
        "ELPITIYA - GALLE",
        "GALLE - GALLE",
        "GINIMELLAGAHA - GALLE",
        "GINTOTA - GALLE",
        "GODAHENA - GALLE",
        "GONAMULLA JUNCTION - GALLE",
        "GONAPINUWALA - GALLE",
        "HABARADUWA - GALLE",
        "HABURUGALA - GALLE",
        "HIKKADUWA - GALLE",
        "HINIDUMA - GALLE",
        "HIYARE - GALLE",
        "KAHADUWA - GALLE",
        "KAHAWA - GALLE",
        "KARAGODA - GALLE",
        "KARANDENIYA - GALLE",
        "KOSGODA - GALLE",
        "KOTTAWAGAMA - GALLE",
        "KOTTEGODA - GALLE",
        "KULEEGODA - GALLE",
        "MAGEDARA - GALLE",
        "MAHAWELA SINHAPURA - GALLE",
        "MAPALAGAMA - GALLE",
        "MAPALAGAMA CENTRAL - GALLE",
        "MATTAKA - GALLE",
        "MEETIYAGODA - GALLE",
        "NAGODA - GALLE",
        "NAKIYADENIYA - GALLE",
        "NAWADAGALA - GALLE",
        "NELUWA - GALLE",
        "NINDANA - GALLE",
        "PAHALA MILLAWA - GALLE",
        "PANANGALA - GALLE",
        "PANNIMULLA PANAGODA - GALLE",
        "PARANA THANAYAMGODA - GALLE",
        "PATANA - GALLE",
        "PITIGALA - GALLE",
        "PODDALA - GALLE",
        "POLGAMPOLA - GALLE",
        "PORAWAGAMA - GALLE",
        "RANTOTUWILA - GALLE",
        "TALAGAMPOLA - GALLE",
        "TALGASPE - GALLE",
        "TALPE - GALLE",
        "TAWALAMA - GALLE",
        "TIRANAGAMA - GALLE",
        "UDALAMATTA - GALLE",
        "UDUGAMA - GALLE",
        "ULUVITIKE - GALLE",
        "UNAWATUNA - GALLE",
        "UNENWITIYA - GALLE",
        "URAGAHA - GALLE",
        "URAGASMANHANDIYA - GALLE",
        "WAKWELLA - GALLE",
        "WALAHANDUWA - GALLE",
        "WANCHAWELA - GALLE",
        "WANDURAMBA - GALLE",
        "WARUKANDENIYA - GALLE",
        "WATUGEDARA - GALLE",
        "WEIHENA - GALLE",
        "WELIKANDA - GALLE",
        "WILANAGAMA - GALLE",
        "YAKKALAMULLA - GALLE",
        "YATALAMATTA - GALLE"
    ],
    "NUWARA ELIYA": [
        "AGARAPATHANA - NUWARA ELIYA",
        "AMBATALAWA - NUWARA ELIYA",
        "AMBEWELA - NUWARA ELIYA",
        "BOGAWANTALAWA - NUWARA ELIYA",
        "BOPATTALAWA - NUWARA ELIYA",
        "DAGAMPITIYA - NUWARA ELIYA",
        "DAYAGAMA BAZAAR - NUWARA ELIYA",
        "DIKOYA - NUWARA ELIYA",
        "DORAGALA - NUWARA ELIYA",
        "DUNUKEDENIYA - NUWARA ELIYA",
        "EGODAWELA - NUWARA ELIYA",
        "EKIRIYA - NUWARA ELIYA",
        "ELAMULLA - NUWARA ELIYA",
        "GINIGATHENA - NUWARA ELIYA",
        "GONAKELE - NUWARA ELIYA",
        "HAGGALA - NUWARA ELIYA",
        "HALGRANOYA - NUWARA ELIYA",
        "HANGARAPITIYA - NUWARA ELIYA",
        "HAPUGASTALAWA - NUWARA ELIYA",
        "HARASBEDDA - NUWARA ELIYA",
        "HATTON - NUWARA ELIYA",
        "HEWAHETA - NUWARA ELIYA",
        "HITIGEGAMA - NUWARA ELIYA",
        "JANGULLA - NUWARA ELIYA",
        "KALAGANWATTA - NUWARA ELIYA",
        "KANDAPOLA - NUWARA ELIYA",
        "KARANDAGOLLA - NUWARA ELIYA",
        "KEERTHI BANDARAPURA - NUWARA ELIYA",
        "KIRIBATHKUMBURA - NUWARA ELIYA",
        "KOTIYAGALA - NUWARA ELIYA",
        "KOTMALE - NUWARA ELIYA",
        "KOTTELLENA - NUWARA ELIYA",
        "KUMBALGAMUWA - NUWARA ELIYA",
        "KUMBUKWELA - NUWARA ELIYA",
        "KURUPANAWELA - NUWARA ELIYA",
        "LABUKELE - NUWARA ELIYA",
        "LAXAPANA - NUWARA ELIYA",
        "LINDULA - NUWARA ELIYA",
        "MADULLA - NUWARA ELIYA",
        "MANDARAM NUWARA - NUWARA ELIYA",
        "MASKELIYA - NUWARA ELIYA",
        "MASWELA - NUWARA ELIYA",
        "MATURATA - NUWARA ELIYA",
        "MIPANAWA - NUWARA ELIYA",
        "MIPILIMANA - NUWARA ELIYA",
        "MORAHENAGAMA - NUWARA ELIYA",
        "MUNWATTA - NUWARA ELIYA",
        "NAYAPANA JANAPADAYA - NUWARA ELIYA",
        "NILDANDAHINNA - NUWARA ELIYA",
        "NISSANKA UYANA - NUWARA ELIYA",
        "NORWOOD - NUWARA ELIYA",
        "NUWARA ELIYA - NUWARA ELIYA",
        "PADIYAPELELLA - NUWARA ELIYA",
        "PALLEBOWALA - NUWARA ELIYA",
        "PANVILA - NUWARA ELIYA",
        "PITAWALA - NUWARA ELIYA",
        "PUNDALUOYA - NUWARA ELIYA",
        "RAMBODA - NUWARA ELIYA",
        "RIKILLAGASKADA - NUWARA ELIYA",
        "ROZELLA - NUWARA ELIYA",
        "RUPAHA - NUWARA ELIYA",
        "RUWANELIYA - NUWARA ELIYA",
        "SANTHIPURA - NUWARA ELIYA",
        "TALAWAKELE - NUWARA ELIYA",
        "TAWALANTENNA - NUWARA ELIYA",
        "TERIPEHA - NUWARA ELIYA",
        "UDAMADURA - NUWARA ELIYA",
        "UDAPUSSALLAWA - NUWARA ELIYA",
        "UVA DEEGALLA - NUWARA ELIYA",
        "UVA UDUWARA - NUWARA ELIYA",
        "UVAPARANAGAMA - NUWARA ELIYA",
        "WALAPANE - NUWARA ELIYA",
        "WATAWALA - NUWARA ELIYA",
        "WIDULIPURA - NUWARA ELIYA",
        "WIJEBAHUKANDA - NUWARA ELIYA"
    ],
    "TRINCOMALEE": [
        "AGBOPURA - TRINCOMALEE",
        "BUCKMIGAMA - TRINCOMALEE",
        "CHINA BAY - TRINCOMALEE",
        "DEHIWATTE - TRINCOMALEE",
        "ECHCHILAMPATTAI - TRINCOMALEE",
        "GALMETIYAWA - TRINCOMALEE",
        "GOMARANKADAWALA - TRINCOMALEE",
        "KADDAIPARICHCHAN - TRINCOMALEE",
        "KALLAR - TRINCOMALEE",
        "KANNIYA - TRINCOMALEE",
        "KANTALAI - TRINCOMALEE",
        "KANTALAI SUGAR FACTORY - TRINCOMALEE",
        "KILIVEDDY - TRINCOMALEE",
        "KINNIYA - TRINCOMALEE",
        "KUCHCHAVELI - TRINCOMALEE",
        "KUMBURUPIDDY - TRINCOMALEE",
        "KURINCHAKEMY - TRINCOMALEE",
        "LANKAPATUNA - TRINCOMALEE",
        "MAHADIVULWEWA - TRINCOMALEE",
        "MAHARUGIRAMAM - TRINCOMALEE",
        "MALLIKATIVU - TRINCOMALEE",
        "MAWADICHENAI - TRINCOMALEE",
        "MULLIPOTHANA - TRINCOMALEE",
        "MUTUR - TRINCOMALEE",
        "NEELAPOLA - TRINCOMALEE",
        "NILAVELI - TRINCOMALEE",
        "PANKULAM - TRINCOMALEE",
        "PULMODDAI - TRINCOMALEE",
        "ROTTAWEWA - TRINCOMALEE",
        "SAMPALTIVU - TRINCOMALEE",
        "SAMPOOR - TRINCOMALEE",
        "SERUNUWARA - TRINCOMALEE",
        "SERUWILA - TRINCOMALEE",
        "SIRAJNAGAR - TRINCOMALEE",
        "SOMAPURA - TRINCOMALEE",
        "TAMPALAKAMAM - TRINCOMALEE",
        "THURAINEELAVANAI - TRINCOMALEE",
        "TIRIYAYI - TRINCOMALEE",
        "TOPPUR - TRINCOMALEE",
        "TRINCOMALEE - TRINCOMALEE",
        "WANELA - TRINCOMALEE"
    ],
    "GAMPAHA": [
        "AKARAGAMA - GAMPAHA",
        "AMBAGASPITIYA - GAMPAHA",
        "AMBEPUSSA - GAMPAHA",
        "ANDIAMBALAMA - GAMPAHA",
        "ATTANAGALLA - GAMPAHA",
        "BADALGAMA - GAMPAHA",
        "BANDURAGODA - GAMPAHA",
        "BATUWATTA - GAMPAHA",
        "BEMMULLA - GAMPAHA",
        "BIYAGAMA IPZ - GAMPAHA",
        "BOKALAGAMA - GAMPAHA",
        "BOLLETE (WP) - GAMPAHA",
        "BOPAGAMA - GAMPAHA",
        "BUTHPITIYA - GAMPAHA",
        "DAGONNA - GAMPAHA",
        "DANOWITA - GAMPAHA",
        "DEBAHERA - GAMPAHA",
        "DEKATANA - GAMPAHA",
        "DELGODA - GAMPAHA",
        "DELWAGURA - GAMPAHA",
        "DEMALAGAMA - GAMPAHA",
        "DEMANHANDIYA - GAMPAHA",
        "DEWALAPOLA - GAMPAHA",
        "DIVULAPITIYA - GAMPAHA",
        "DIVULDENIYA - GAMPAHA",
        "DOMPE - GAMPAHA",
        "DUNAGAHA - GAMPAHA",
        "EKALA - GAMPAHA",
        "ELLAKKALA - GAMPAHA",
        "ESSELLA - GAMPAHA",
        "GALEDANDA - GAMPAHA",
        "GAMPAHA - GAMPAHA",
        "GANEMULLA - GAMPAHA",
        "GIRIULLA - GAMPAHA",
        "GONAWALA - GAMPAHA",
        "HALPE - GAMPAHA",
        "HAPUGASTENNA - GAMPAHA",
        "HEIYANTHUDUWA - GAMPAHA",
        "HINATIYANA MADAWALA - GAMPAHA",
        "HISWELLA - GAMPAHA",
        "HORAMPELLA - GAMPAHA",
        "HUNUMULLA - GAMPAHA",
        "HUNUPOLA - GAMPAHA",
        "IHALA MADAMPELLA - GAMPAHA",
        "IMBULGODA - GAMPAHA",
        "JA ELA - GAMPAHA",
        "KADAWATHA - GAMPAHA",
        "KAHATOWITA - GAMPAHA",
        "KALAGEDIHENA - GAMPAHA",
        "KALELIYA - GAMPAHA",
        "KANDANA - GAMPAHA",
        "KATANA - GAMPAHA",
        "KATUDENIYA - GAMPAHA",
        "KATUNAYAKE - GAMPAHA",
        "KATUNAYAKE AIR FORCE CAMP - GAMPAHA",
        "KATUNAYAKE(FTZ) - GAMPAHA",
        "KATUWELLEGAMA - GAMPAHA",
        "KELANIYA - GAMPAHA",
        "KIMBULAPITIYA - GAMPAHA",
        "KIRIBATHGODA - GAMPAHA",
        "KIRINDIWELA - GAMPAHA",
        "KITALAWALANA - GAMPAHA",
        "KOCHCHIKADE - GAMPAHA",
        "KOTADENIYAWA - GAMPAHA",
        "KOTUGODA - GAMPAHA",
        "KUMBALOLUWA - GAMPAHA",
        "LOLUWAGODA - GAMPAHA",
        "MABODALE - GAMPAHA",
        "MADELGAMUWA - GAMPAHA",
        "MAKEWITA - GAMPAHA",
        "MAKOLA - GAMPAHA",
        "MALWANA - GAMPAHA",
        "MANDAWALA - GAMPAHA",
        "MARANDAGAHAMULA - GAMPAHA",
        "MELLAWAGEDARA - GAMPAHA",
        "MINUWANGODA - GAMPAHA",
        "MIRIGAMA - GAMPAHA",
        "MIRISWATTA - GAMPAHA",
        "MITHIRIGALA - GAMPAHA",
        "MUDDARAGAMA - GAMPAHA",
        "MUDUNGODA - GAMPAHA",
        "MULLERIYAWA NEW TOWN - GAMPAHA",
        "NARANWALA - GAMPAHA",
        "NAWANA - GAMPAHA",
        "NEDUNGAMUWA - GAMPAHA",
        "NEGOMBO - GAMPAHA",
        "NIKADALUPOTHA - GAMPAHA",
        "NIKAHETIKANDA - GAMPAHA",
        "NITTAMBUWA - GAMPAHA",
        "NIWANDAMA - GAMPAHA",
        "OPATHA - GAMPAHA",
        "PAMUNUGAMA - GAMPAHA",
        "PAMUNUWATTA - GAMPAHA",
        "PANAWALA - GAMPAHA",
        "PASYALA - GAMPAHA",
        "PELIYAGODA - GAMPAHA",
        "PEPILIYAWALA - GAMPAHA",
        "PETHIYAGODA - GAMPAHA",
        "POLPITHIMUKULANA - GAMPAHA",
        "PUWAKPITIYA - GAMPAHA",
        "RADAWADUNNA - GAMPAHA",
        "RADAWANA - GAMPAHA",
        "RADDOLUGAMA - GAMPAHA",
        "RAGAMA - GAMPAHA",
        "RUGGAHAWILA - GAMPAHA",
        "SEEDUWA - GAMPAHA",
        "SIYAMBALAPE - GAMPAHA",
        "TALAHENA - GAMPAHA",
        "THAMBAGALLA - GAMPAHA",
        "THIMBIRIGASKATUWA - GAMPAHA",
        "TITTAPATTARA - GAMPAHA",
        "UDATHUTHIRIPITIYA - GAMPAHA",
        "UDUGAMPOLA - GAMPAHA",
        "UGGALBODA - GAMPAHA",
        "URAPOLA - GAMPAHA",
        "USWETAKEIYAWA - GAMPAHA",
        "VEYANGODA - GAMPAHA",
        "WALGAMMULLA - GAMPAHA",
        "WALPITA - GAMPAHA",
        "WALPOLA (WP) - GAMPAHA",
        "WATHURUGAMA - GAMPAHA",
        "WATINAPAHA - GAMPAHA",
        "WATTALA - GAMPAHA",
        "WEBODA - GAMPAHA",
        "WEGOWWA - GAMPAHA",
        "WELIWERIYA - GAMPAHA",
        "WEWELDENIYA - GAMPAHA",
        "YAKKALA - GAMPAHA",
        "YATIYANA - GAMPAHA"
    ],
    "COLOMBO": [
        "AKARAWITA - COLOMBO",
        "ANGODA - COLOMBO",
        "ATHURUGIRIYA - COLOMBO",
        "ATTIDIYA - COLOMBO",
        "AVISSAWELLA - COLOMBO",
        "BATAWALA - COLOMBO",
        "BATTARAMULLA - COLOMBO",
        "BIYAGAMA - COLOMBO",
        "BOPE - COLOMBO",
        "BORALESGAMUWA - COLOMBO",
        "COLOMBO 1 - COLOMBO",
        "COLOMBO 10 - COLOMBO",
        "COLOMBO 11 - COLOMBO",
        "COLOMBO 12 - COLOMBO",
        "COLOMBO 13 - COLOMBO",
        "COLOMBO 14 - COLOMBO",
        "COLOMBO 15 - COLOMBO",
        "COLOMBO 2 - COLOMBO",
        "COLOMBO 3 - COLOMBO",
        "COLOMBO 4 - COLOMBO",
        "COLOMBO 5 - COLOMBO",
        "COLOMBO 6 - COLOMBO",
        "COLOMBO 7 - COLOMBO",
        "COLOMBO 8 - COLOMBO",
        "COLOMBO 9 - COLOMBO",
        "DEDIGAMUWA - COLOMBO",
        "DEHIWALA - COLOMBO",
        "DELKANDA - COLOMBO",
        "DELTARA - COLOMBO",
        "ETHUL KOTTE - COLOMBO",
        "GODAGAMA - COLOMBO",
        "HABARAKADA - COLOMBO",
        "HANWELLA - COLOMBO",
        "HIRIPITYA - COLOMBO",
        "HOKANDARA - COLOMBO",
        "HOMAGAMA - COLOMBO",
        "HORAGALA - COLOMBO",
        "KADUWELA - COLOMBO",
        "KAHATHUDUWA - COLOMBO",
        "KALUAGGALA - COLOMBO",
        "KAPUGODA - COLOMBO",
        "KATUBEDDA - COLOMBO",
        "KEHELWATTA - COLOMBO",
        "KESBEWA - COLOMBO",
        "KIRIWATTUDUWA - COLOMBO",
        "KOHUWALA - COLOMBO",
        "KOLONNAWA - COLOMBO",
        "KOSGAMA - COLOMBO",
        "KOSWATTA - COLOMBO",
        "KOTTAWA - COLOMBO",
        "KOTTE - COLOMBO",
        "MADAPATHA - COLOMBO",
        "MAHARAGAMA - COLOMBO",
        "MALABE - COLOMBO",
        "MATTEGODA - COLOMBO",
        "MEEGODA - COLOMBO",
        "MORATUWA - COLOMBO",
        "MOUNT LAVINIA - COLOMBO",
        "MULLEGAMA - COLOMBO",
        "NAPAWELA - COLOMBO",
        "NAWALA - COLOMBO",
        "NUGEGODA - COLOMBO",
        "PADUKKA - COLOMBO",
        "PANNIPITIYA - COLOMBO",
        "PELAWATTA - COLOMBO",
        "PILIYANDALA - COLOMBO",
        "PITA KOTTE - COLOMBO",
        "PITIPANA HOMAGAMA - COLOMBO",
        "POLGASOWITA - COLOMBO",
        "PUGODA - COLOMBO",
        "RAJAGIRIYA - COLOMBO",
        "RANALA - COLOMBO",
        "RATHMALANA - COLOMBO",
        "SIDDAMULLA - COLOMBO",
        "SIYAMBALAGODA - COLOMBO",
        "SRI JAYAWARDENEPU - COLOMBO",
        "TALAWATUGODA - COLOMBO",
        "THALAWATHUGODA - COLOMBO",
        "TUMMODARA - COLOMBO",
        "WAGA - COLOMBO",
        "WELLAMPITIYA - COLOMBO"
    ],
    "RATNAPURA": [
        "AKARELLA - RATNAPURA",
        "AMUNUMULLA - RATNAPURA",
        "ATAKALANPANNA - RATNAPURA",
        "AYAGAMA - RATNAPURA",
        "BALANGODA - RATNAPURA",
        "BATATOTA - RATNAPURA",
        "BERALAPANATHARA - RATNAPURA",
        "BOGAHAKUMBURA - RATNAPURA",
        "BOLTHUMBE - RATNAPURA",
        "BOMLUWAGEAINA - RATNAPURA",
        "BOWALAGAMA - RATNAPURA",
        "BULUTOTA - RATNAPURA",
        "DAMBULUWANA - RATNAPURA",
        "DAUGALA - RATNAPURA",
        "DELA - RATNAPURA",
        "DELWALA - RATNAPURA",
        "DODAMPE - RATNAPURA",
        "DOLOSWALAKANDA - RATNAPURA",
        "DUMBARA MANANA - RATNAPURA",
        "EHELIYAGODA - RATNAPURA",
        "EKAMUTUGAMA - RATNAPURA",
        "ELAPATHA - RATNAPURA",
        "ELLAGAWA - RATNAPURA",
        "ELLAULLA - RATNAPURA",
        "ELLAWALA - RATNAPURA",
        "EMBILIPITIYA - RATNAPURA",
        "ERATNA - RATNAPURA",
        "EREPOLA - RATNAPURA",
        "GABBELA - RATNAPURA",
        "GANGEYAYA - RATNAPURA",
        "GAWARAGIRIYA - RATNAPURA",
        "GILLIMALE - RATNAPURA",
        "GODAKAWELA - RATNAPURA",
        "GURUBEWILAGAMA - RATNAPURA",
        "HALWINNA - RATNAPURA",
        "HANDAGIRIYA - RATNAPURA",
        "HATANGALA - RATNAPURA",
        "HATARABAGE - RATNAPURA",
        "HEWANAKUMBURA - RATNAPURA",
        "HIDELLANA - RATNAPURA",
        "HIRAMADAGAMA - RATNAPURA",
        "HOREWELAGODA - RATNAPURA",
        "ITTAKANDA - RATNAPURA",
        "KAHANGAMA - RATNAPURA",
        "KAHAWATTA - RATNAPURA",
        "KALAWANA - RATNAPURA",
        "KALTOTA - RATNAPURA",
        "KALUBULULANDA - RATNAPURA",
        "KANANKE BAZAAR - RATNAPURA",
        "KANDEPUHULPOLA - RATNAPURA",
        "KARANDANA - RATNAPURA",
        "KARANGODA - RATNAPURA",
        "KELLA JUNCTION - RATNAPURA",
        "KEPPETIPOLA - RATNAPURA",
        "KIRIELLA - RATNAPURA",
        "KIRIIBBANWEWA - RATNAPURA",
        "KOLAMBAGEARA - RATNAPURA",
        "KOLOMBUGAMA - RATNAPURA",
        "KOLONNA - RATNAPURA",
        "KUDAWA - RATNAPURA",
        "KURUWITA - RATNAPURA",
        "LELLOPITIYA - RATNAPURA",
        "LMADUWA - RATNAPURA",
        "LMBULPE - RATNAPURA",
        "MAHAGAMA COLONY - RATNAPURA",
        "MAHAWALATENNA - RATNAPURA",
        "MAKANDURA SABARA - RATNAPURA",
        "MALWALA JUNCTION - RATNAPURA",
        "MALWATTA - RATNAPURA",
        "MATUWAGALAGAMA - RATNAPURA",
        "MEDAGALATUR - RATNAPURA",
        "MEDDEKANDA - RATNAPURA",
        "MINIPURA DUMBARA - RATNAPURA",
        "MITIPOLA - RATNAPURA",
        "MORAGALA KIRILLAPONE - RATNAPURA",
        "MORAHELA - RATNAPURA",
        "MULENDIYAWALA - RATNAPURA",
        "MULGAMA - RATNAPURA",
        "NAWALAKANDA - RATNAPURA",
        "NAWINNAPINNAKANDA - RATNAPURA",
        "NIRALAGAMA - RATNAPURA",
        "NIVITIGALA - RATNAPURA",
        "OMALPE - RATNAPURA",
        "OPANAYAKA - RATNAPURA",
        "PADALANGALA - RATNAPURA",
        "PALLEBEDDA - RATNAPURA",
        "PALLEKANDA - RATNAPURA",
        "PAMBAGOLLA - RATNAPURA",
        "PANAMURA - RATNAPURA",
        "PANAPOLA - RATNAPURA",
        "PARAGALA - RATNAPURA",
        "PARAKADUWA - RATNAPURA",
        "PEBOTUWA - RATNAPURA",
        "PELMADULLA - RATNAPURA",
        "PINNAWALA - RATNAPURA",
        "POTHDENIYA - RATNAPURA",
        "RAJAWAKA - RATNAPURA",
        "RANWALA - RATNAPURA",
        "RASSAGALA - RATNAPURA",
        "RATGAMA - RATNAPURA",
        "RATNA HANGAMUWA - RATNAPURA",
        "RATNAPURA - RATNAPURA",
        "SEWANAGALA - RATNAPURA",
        "SRI PALABADDALA - RATNAPURA",
        "SUDAGALA - RATNAPURA",
        "TALAKOLAHINNA - RATNAPURA",
        "TANJANTENNA - RATNAPURA",
        "TEPPANAWA - RATNAPURA",
        "TUNKAMA - RATNAPURA",
        "UDAKARAWITA - RATNAPURA",
        "UDANIRIELLA - RATNAPURA",
        "UDAWALAWE - RATNAPURA",
        "ULLINDUWAWA - RATNAPURA",
        "VEDDAGALA - RATNAPURA",
        "VIJERIYA - RATNAPURA",
        "WALEBODA - RATNAPURA",
        "WATAPOTHA - RATNAPURA",
        "WATURAWA - RATNAPURA",
        "WELIGEPOLA - RATNAPURA",
        "WELIPATHAYAYA - RATNAPURA",
        "WIKILIYA - RATNAPURA"
    ],
    "AMPARA": [
        "AKKARAIPATTU - AMPARA",
        "AMBAGAHAWATTA - AMPARA",
        "AMPARA - AMPARA",
        "BAKMITIYAWA - AMPARA",
        "DEEGAWAPIYA - AMPARA",
        "DEVALAHINDA - AMPARA",
        "DIGAMADULLA WEERAGODA - AMPARA",
        "DORAKUMBURA - AMPARA",
        "GONAGOLLA - AMPARA",
        "HULANNUGE - AMPARA",
        "KALMUNAI - AMPARA",
        "KANNAKIPURAM - AMPARA",
        "KARATIVU - AMPARA",
        "KEKIRIHENA - AMPARA",
        "KOKNAHARA - AMPARA",
        "KOLAMANTHALAWA - AMPARA",
        "KOMARI - AMPARA",
        "LAHUGALA - AMPARA",
        "LMKKAMAM - AMPARA",
        "MAHAOYA - AMPARA",
        "MARATHAMUNE - AMPARA",
        "NAMALOYA - AMPARA",
        "NAVITHANVELI - AMPARA",
        "NEETHTHA - AMPARA",
        "NINTAVUR - AMPARA",
        "OLUVIL - AMPARA",
        "PADIYATALAWA - AMPARA",
        "PAHALALANDA - AMPARA",
        "PANAMA - AMPARA",
        "PANNALAGAMA - AMPARA",
        "PARAGAHAKELE - AMPARA",
        "PERIYANEELAVANAI - AMPARA",
        "POLWAGA JANAPADAYA - AMPARA",
        "POTTUVIL - AMPARA",
        "SAINTHAMARUTHU - AMPARA",
        "SAMANTHURAI - AMPARA",
        "SERANKADA - AMPARA",
        "TEMPITIYA - AMPARA",
        "THAMBILUVIL - AMPARA",
        "TIRUKOVIL - AMPARA",
        "UHANA - AMPARA",
        "WADINAGALA - AMPARA",
        "WANAGAMUWA - AMPARA"
    ],
    "BADULLA": [
        "AKKARASIYAYA - BADULLA",
        "ALUKETIYAWA - BADULLA",
        "ALUTTARAMMA - BADULLA",
        "AMBADANDEGAMA - BADULLA",
        "AMBAGASDOWA - BADULLA",
        "ARAWA - BADULLA",
        "ARAWAKUMBURA - BADULLA",
        "ARAWATTA - BADULLA",
        "ATAKIRIYA - BADULLA",
        "BADULLA - BADULLA",
        "BADULUOYA - BADULLA",
        "BALLAKETUWA - BADULLA",
        "BAMBARAPANA - BADULLA",
        "BANDARAWELA - BADULLA",
        "BERAMADA - BADULLA",
        "BIBILEGAMA - BADULLA",
        "BORAGAS - BADULLA",
        "BORALANDA - BADULLA",
        "BOWELA - BADULLA",
        "CENTRAL CAMP - BADULLA",
        "DAMANEWELA - BADULLA",
        "DAMBANA - BADULLA",
        "DEHIATTAKANDIYA - BADULLA",
        "DEMODARA - BADULLA",
        "DIGANATENNA - BADULLA",
        "DIKKAPITIYA - BADULLA",
        "DIMBULANA - BADULLA",
        "DIVULAPELESSA - BADULLA",
        "DIYATALAWA - BADULLA",
        "DULGOLLA - BADULLA",
        "EKIRIYANKUMBURA - BADULLA",
        "ELLA - BADULLA",
        "ETTAMPITIYA - BADULLA",
        "GALAUDA - BADULLA",
        "GALPORUYAYA - BADULLA",
        "GAWARAWELA - BADULLA",
        "GIRANDURUKOTTE - BADULLA",
        "GODUNNA - BADULLA",
        "GURUTALAWA - BADULLA",
        "HALDUMMULLA - BADULLA",
        "HALI ELA - BADULLA",
        "HANGUNNAWA - BADULLA",
        "HAPUTALE - BADULLA",
        "HEBARAWA - BADULLA",
        "HEELOYA - BADULLA",
        "HELAHALPE - BADULLA",
        "HELAPUPULA - BADULLA",
        "HOPTON - BADULLA",
        "IDALGASHINNA - BADULLA",
        "KAHATARUPPA - BADULLA",
        "KALUGAHAKANDURA - BADULLA",
        "KALUPAHANA - BADULLA",
        "KEBILLAWELA - BADULLA",
        "KENDAGOLLA - BADULLA",
        "KESELPOTHA - BADULLA",
        "KETAWATTA - BADULLA",
        "KIRIWANAGAMA - BADULLA",
        "KOSLANDA - BADULLA",
        "KURUWITENNA - BADULLA",
        "KUTTIYAGOLLA - BADULLA",
        "LANDEWELA - BADULLA",
        "LIYANGAHAWELA - BADULLA",
        "LUNUGALA - BADULLA",
        "LUNUWATTA - BADULLA",
        "MADULSIMA - BADULLA",
        "MAHIYANGANAYA - BADULLA",
        "MAKULELLA - BADULLA",
        "MALGODA - BADULLA",
        "MAPAKADAWEWA - BADULLA",
        "MASPANNA - BADULLA",
        "MAUSSAGOLLA - BADULLA",
        "MAWANAGAMA - BADULLA",
        "MEDAWELA UDUKINDA - BADULLA",
        "MEEGAHAKIULA - BADULLA",
        "METIGAHATENNA - BADULLA",
        "MIRAHAWATTA - BADULLA",
        "MIRIYABEDDA - BADULLA",
        "NAWAMEDAGAMA - BADULLA",
        "NELUMGAMA - BADULLA",
        "NIKAPOTHA - BADULLA",
        "NUGATALAWA - BADULLA",
        "OHIYA - BADULLA",
        "PAHALARATHKINDA - BADULLA",
        "PALLEKIRUWA - BADULLA",
        "PASSARA - BADULLA",
        "PATTIYAGEDARA - BADULLA",
        "PELAGAHATENNA - BADULLA",
        "PERAWELLA - BADULLA",
        "PITAMARUWA - BADULLA",
        "PITAPOLA - BADULLA",
        "PUHULPOLA - BADULLA",
        "RAJAGALATENNA - BADULLA",
        "RATKARAWWA - BADULLA",
        "RIDIMALIYADDA - BADULLA",
        "SILMIYAPURA - BADULLA",
        "SIRIMALGODA - BADULLA",
        "SIRIPURA - BADULLA",
        "SORABORA COLONY - BADULLA",
        "SORAGUNE - BADULLA",
        "SORANATOTA - BADULLA",
        "TALDENA - BADULLA",
        "TIMBIRIGASPITIYA - BADULLA",
        "UDUHAWARA - BADULLA",
        "URANIYA - BADULLA",
        "UVA KARANDAGOLLA - BADULLA",
        "UVA MAWELAGAMA - BADULLA",
        "UVA TENNA - BADULLA",
        "UVA TISSAPURA - BADULLA",
        "WELIMADA - BADULLA",
        "WERUNKETAGODA - BADULLA",
        "WEWATTA - BADULLA",
        "WINEETHAGAMA - BADULLA",
        "YALAGAMUWA - BADULLA",
        "YALWELA - BADULLA"
    ],
    "MATALE": [
        "AKURAMBODA - MATALE",
        "ALAWATUWALA - MATALE",
        "ALWATTA - MATALE",
        "AMBANA - MATALE",
        "ARALAGANWILA - MATALE",
        "ATARAGALLEWA - MATALE",
        "BAMBARAGASWEWA - MATALE",
        "BARAWARDHANA OYA - MATALE",
        "BELIGAMUWA - MATALE",
        "DAMANA - MATALE",
        "DAMBULLA - MATALE",
        "DAMMINNA - MATALE",
        "DANKANDA - MATALE",
        "DELWITE - MATALE",
        "DEVAGIRIYA - MATALE",
        "DEWAHUWA - MATALE",
        "DIVULDAMANA - MATALE",
        "DULLEWA - MATALE",
        "DUNKOLAWATTA - MATALE",
        "ELKADUWA - MATALE",
        "ERAWULA JUNCTION - MATALE",
        "ETANAWALA - MATALE",
        "GALEWELA - MATALE",
        "GALOYA JUNCTION - MATALE",
        "GAMMADUWA - MATALE",
        "GANGALA PUWAKPITIYA - MATALE",
        "HASALAKA - MATALE",
        "HATTOTA AMUNA - MATALE",
        "IMBULGOLLA - MATALE",
        "INAMALUWA - MATALE",
        "IRIYAGOLLA - MATALE",
        "KAIKAWALA - MATALE",
        "KALUNDAWA - MATALE",
        "KANDALAMA - MATALE",
        "KAVUDUPELELLA - MATALE",
        "KIBISSA - MATALE",
        "KIWULA - MATALE",
        "KONGAHAWELA - MATALE",
        "LAGGALA PALLEGAMA - MATALE",
        "LELIAMBE - MATALE",
        "LENADORA - MATALE",
        "LHALA HALMILLEWA - MATALE",
        "LLLUKKUMBURA - MATALE",
        "MADIPOLA - MATALE",
        "MADURUOYA - MATALE",
        "MAHAWELA - MATALE",
        "MANANWATTA - MATALE",
        "MARAKA - MATALE",
        "MATALE - MATALE",
        "MELIPITIYA - MATALE",
        "METIHAKKA - MATALE",
        "MILLAWANA - MATALE",
        "MUWANDENIYA - MATALE",
        "NALANDA - MATALE",
        "NAULA - MATALE",
        "OPALGALA - MATALE",
        "PALLEPOLA - MATALE",
        "PIMBURATTEWA - MATALE",
        "PULASTIGAMA - MATALE",
        "RANAMUREGAMA - MATALE",
        "RATTOTA - MATALE",
        "SELAGAMA - MATALE",
        "SIGIRIYA - MATALE",
        "SINHAGAMA - MATALE",
        "SUNGAVILA - MATALE",
        "TALAGODA JUNCTION - MATALE",
        "TALAKIRIYAGAMA - MATALE",
        "TAMANKADUWA - MATALE",
        "UDASGIRIYA - MATALE",
        "UDATENNA - MATALE",
        "UKUWELA - MATALE",
        "WAHACOTTE - MATALE",
        "WALAWELA - MATALE",
        "WEHIGALA - MATALE",
        "WELANGAHAWATTE - MATALE",
        "WEWALAWEWA - MATALE",
        "YATAWATTA - MATALE"
    ],
    "KURUNEGALA": [
        "AKURANA - KURUNEGALA",
        "ALAHENGAMA - KURUNEGALA",
        "ALAHITIYAWA - KURUNEGALA",
        "AMBAKOTE - KURUNEGALA",
        "AMBANPOLA - KURUNEGALA",
        "ANDIYAGALA - KURUNEGALA",
        "ANUKKANE - KURUNEGALA",
        "ARAGODA - KURUNEGALA",
        "ATARAGALLA - KURUNEGALA",
        "AWULEGAMA - KURUNEGALA",
        "BALALLA - KURUNEGALA",
        "BAMUNUKOTUWA - KURUNEGALA",
        "BANDARA KOSWATTA - KURUNEGALA",
        "BINGIRIYA - KURUNEGALA",
        "BOGAMULLA - KURUNEGALA",
        "BORALUWEWA - KURUNEGALA",
        "BOYAGANE - KURUNEGALA",
        "BUJJOMUWA - KURUNEGALA",
        "BULUWALA - KURUNEGALA",
        "DADAYAMTALAWA - KURUNEGALA",
        "DAMBADENIYA - KURUNEGALA",
        "DARALUWA - KURUNEGALA",
        "DEEGALLA - KURUNEGALA",
        "DEMATALUWA - KURUNEGALA",
        "DEMUWATHA - KURUNEGALA",
        "DIDDENIYA - KURUNEGALA",
        "DIGANNEWA - KURUNEGALA",
        "DIVULLEGODA - KURUNEGALA",
        "DIYASENPURA - KURUNEGALA",
        "DODANGASLANDA - KURUNEGALA",
        "DOLUWA - KURUNEGALA",
        "DORAGAMUWA - KURUNEGALA",
        "DORATIYAWA - KURUNEGALA",
        "DUNUMADALAWA - KURUNEGALA",
        "DUNUWILAPITIYA - KURUNEGALA",
        "EHETUWEWA - KURUNEGALA",
        "ELIBICHCHIYA - KURUNEGALA",
        "EMBOGAMA - KURUNEGALA",
        "ETUNGAHAKOTUWA - KURUNEGALA",
        "GALADIVULWEWA - KURUNEGALA",
        "GALGAMUWA - KURUNEGALA",
        "GALLELLAGAMA - KURUNEGALA",
        "GALLEWA - KURUNEGALA",
        "GANEGODA - KURUNEGALA",
        "GIRATHALANA - KURUNEGALA",
        "GOKARALLA - KURUNEGALA",
        "GONAWILA - KURUNEGALA",
        "HALMILLAWEWA - KURUNEGALA",
        "HANDUNGAMUWA - KURUNEGALA",
        "HARANKAHAWA - KURUNEGALA",
        "HELAMADA - KURUNEGALA",
        "HENGAMUWA - KURUNEGALA",
        "HETTIPOLA - KURUNEGALA",
        "HEWAINNA - KURUNEGALA",
        "HILOGAMA - KURUNEGALA",
        "HINDAGOLLA - KURUNEGALA",
        "HIRIYALA LENAWA - KURUNEGALA",
        "HIRUWALPOLA - KURUNEGALA",
        "HORAMBAWA - KURUNEGALA",
        "HULOGEDARA - KURUNEGALA",
        "HULUGALLA - KURUNEGALA",
        "IHALA GOMUGOMUWA - KURUNEGALA",
        "IHALA KATUGAMPALA - KURUNEGALA",
        "INDULGODAKANDA - KURUNEGALA",
        "ITHANAWATTA - KURUNEGALA",
        "KADIGAWA - KURUNEGALA",
        "KALANKUTTIYA - KURUNEGALA",
        "KALATUWAWA - KURUNEGALA",
        "KALUGAMUWA - KURUNEGALA",
        "KANADENIYAWALA - KURUNEGALA",
        "KANATTEWEWA - KURUNEGALA",
        "KANDEGEDARA - KURUNEGALA",
        "KARAGAHAGEDARA - KURUNEGALA",
        "KARAMBE - KURUNEGALA",
        "KATIYAWA - KURUNEGALA",
        "KATUPOTA - KURUNEGALA",
        "KAWUDULLA - KURUNEGALA",
        "KAWUDULUWEWA STAGELL - KURUNEGALA",
        "KEKUNAGOLLA - KURUNEGALA",
        "KEPPITIWALANA - KURUNEGALA",
        "KIMBULWANAOYA - KURUNEGALA",
        "KIRIMETIYAWA - KURUNEGALA",
        "KIRINDAWA - KURUNEGALA",
        "KIRINDIGALLA - KURUNEGALA",
        "KITHALAWA - KURUNEGALA",
        "KITULWALA - KURUNEGALA",
        "KOBEIGANE - KURUNEGALA",
        "KOHILAGEDARA - KURUNEGALA",
        "KONWEWA - KURUNEGALA",
        "KOSDENIYA - KURUNEGALA",
        "KOSGOLLA - KURUNEGALA",
        "KOTAGALA - KURUNEGALA",
        "KOTAWEHERA - KURUNEGALA",
        "KUDAGALGAMUWA - KURUNEGALA",
        "KUDAKATNORUWA - KURUNEGALA",
        "KULIYAPITIYA - KURUNEGALA",
        "KUMARAGAMA - KURUNEGALA",
        "KUMBUKGETA - KURUNEGALA",
        "KUMBUKWEWA - KURUNEGALA",
        "KURATIHENA - KURUNEGALA",
        "KURUNEGALA - KURUNEGALA",
        "LBBAGAMUWA - KURUNEGALA",
        "LHALA KADIGAMUWA - KURUNEGALA",
        "LIHIRIYAGAMA - KURUNEGALA",
        "LLLAGOLLA - KURUNEGALA",
        "LLUKHENA - KURUNEGALA",
        "LONAHETTIYA - KURUNEGALA",
        "MADAHAPOLA - KURUNEGALA",
        "MADAKUMBURUMULLA - KURUNEGALA",
        "MADALAGAMA - KURUNEGALA",
        "MADAWALA ULPOTHA - KURUNEGALA",
        "MADURAGODA - KURUNEGALA",
        "MAELIYA - KURUNEGALA",
        "MAGULAGAMA - KURUNEGALA",
        "MAHA AMBAGASWEWA - KURUNEGALA",
        "MAHAGALKADAWALA - KURUNEGALA",
        "MAHAGIRILLA - KURUNEGALA",
        "MAHAMUKALANYAYA - KURUNEGALA",
        "MAHANANNERIYA - KURUNEGALA",
        "MAHAPALLEGAMA - KURUNEGALA",
        "MAHARACHCHIMULLA - KURUNEGALA",
        "MAHATALAKOLAWEWA - KURUNEGALA",
        "MAHAWEWA - KURUNEGALA",
        "MAHO - KURUNEGALA",
        "MAKULEWA - KURUNEGALA",
        "MAKULPOTHA - KURUNEGALA",
        "MAKULWEWA - KURUNEGALA",
        "MALAGANE - KURUNEGALA",
        "MANDAPOLA - KURUNEGALA",
        "MASPOTHA - KURUNEGALA",
        "MAWATHAGAMA - KURUNEGALA",
        "MEDIRIGIRIYA - KURUNEGALA",
        "MEDIVAWA - KURUNEGALA",
        "MEEGALAWA - KURUNEGALA",
        "MEEGASWEWA - KURUNEGALA",
        "MEEWELLAWA - KURUNEGALA",
        "MELSIRIPURA - KURUNEGALA",
        "METIKUMBURA - KURUNEGALA",
        "METIYAGANE - KURUNEGALA",
        "MINHETTIYA - KURUNEGALA",
        "MINUWANGETE - KURUNEGALA",
        "MIRIHANAGAMA - KURUNEGALA",
        "MONNEKULAMA - KURUNEGALA",
        "MORAGANE - KURUNEGALA",
        "MORAGOLLAGAMA - KURUNEGALA",
        "MORATHIHA - KURUNEGALA",
        "MUNAMALDENIYA - KURUNEGALA",
        "MURUTHENGE - KURUNEGALA",
        "MUTUGALA - KURUNEGALA",
        "NABADEWA - KURUNEGALA",
        "NAGOLLAGAMA - KURUNEGALA",
        "NAGOLLAGODA - KURUNEGALA",
        "NAKKAWATTA - KURUNEGALA",
        "NARAMMALA - KURUNEGALA",
        "NAWASENAPURA - KURUNEGALA",
        "NAWATALWATTA - KURUNEGALA",
        "NELLIYA - KURUNEGALA",
        "NIKAWERATIYA - KURUNEGALA",
        "NUGAGOLLA - KURUNEGALA",
        "NUGAWELA - KURUNEGALA",
        "PADENIYA - KURUNEGALA",
        "PADIWELA - KURUNEGALA",
        "PAHALAGIRIBAWA - KURUNEGALA",
        "PAHAMUNE - KURUNEGALA",
        "PALAGALA - KURUNEGALA",
        "PALAPATHWELA - KURUNEGALA",
        "PALAVIYA - KURUNEGALA",
        "PALLEWELA - KURUNEGALA",
        "PALUKADAWALA - KURUNEGALA",
        "PANADARAGAMA - KURUNEGALA",
        "PANAGAMUWA - KURUNEGALA",
        "PANALIYA - KURUNEGALA",
        "PANAPITIYA - KURUNEGALA",
        "PANLIYADDA - KURUNEGALA",
        "PANNALA - KURUNEGALA",
        "PANSIYAGAMA - KURUNEGALA",
        "PARAPE - KURUNEGALA",
        "PATHANEWATTA - KURUNEGALA",
        "PATTIYA WATTA - KURUNEGALA",
        "PERAKANATTA - KURUNEGALA",
        "PERIYAKADNELUWA - KURUNEGALA",
        "PIHIMBIYA RATMALE - KURUNEGALA",
        "PIHIMBUWA - KURUNEGALA",
        "PILESSA - KURUNEGALA",
        "POLGAHAWELA - KURUNEGALA",
        "POLGOLLA - KURUNEGALA",
        "POLPITIGAMA - KURUNEGALA",
        "POTHUHERA - KURUNEGALA",
        "POTHUPITIYA - KURUNEGALA",
        "PUJAPITIYA - KURUNEGALA",
        "RAKWANA - KURUNEGALA",
        "RANORAWA - KURUNEGALA",
        "RATHUKOHODIGALA - KURUNEGALA",
        "RIDIBENDIELLA - KURUNEGALA",
        "RIDIGAMA - KURUNEGALA",
        "SALIYA ASOKAPURA - KURUNEGALA",
        "SANDALANKAWA - KURUNEGALA",
        "SEVANAPITIYA - KURUNEGALA",
        "SIRAMBIADIYA - KURUNEGALA",
        "SIRISETAGAMA - KURUNEGALA",
        "SIYAMBALANGAMUWA - KURUNEGALA",
        "SIYAMBALAWEWA - KURUNEGALA",
        "SOLEPURA - KURUNEGALA",
        "SOLEWEWA - KURUNEGALA",
        "SUNANDAPURA - KURUNEGALA",
        "TALAWATTEGEDARA - KURUNEGALA",
        "TAMBUTTA - KURUNEGALA",
        "TENNEPANGUWA - KURUNEGALA",
        "THALAHITIMULLA - KURUNEGALA",
        "THALAKOLAWEWA - KURUNEGALA",
        "THALWITA - KURUNEGALA",
        "THARANA UDAWELA - KURUNEGALA",
        "THIMBIRIYAWA - KURUNEGALA",
        "TISOGAMA - KURUNEGALA",
        "TORAYAYA - KURUNEGALA",
        "TULHIRIYA - KURUNEGALA",
        "TUNTOTA - KURUNEGALA",
        "TUTTIRIPITIGAMA - KURUNEGALA",
        "UDAGALDENIYA - KURUNEGALA",
        "UDAHINGULWALA - KURUNEGALA",
        "UDAWATTA - KURUNEGALA",
        "UDUBADDAWA - KURUNEGALA",
        "UDUMULLA - KURUNEGALA",
        "UHUMIYA - KURUNEGALA",
        "ULPOTHA PALLEKELE - KURUNEGALA",
        "ULPOTHAGAMA - KURUNEGALA",
        "USGALA SIYABMALANGAMUWA - KURUNEGALA",
        "VIJITHAPURA - KURUNEGALA",
        "WADAKADA - KURUNEGALA",
        "WADUMUNNEGEDARA - KURUNEGALA",
        "WALAKUMBURUMULLA - KURUNEGALA",
        "WANNIGAMA - KURUNEGALA",
        "WANNIKUDAWEWA - KURUNEGALA",
        "WANNILHALAGAMA - KURUNEGALA",
        "WANNIRASNAYAKAPURA - KURUNEGALA",
        "WARAWEWA - KURUNEGALA",
        "WARIYAPOLA - KURUNEGALA",
        "WATAREKA - KURUNEGALA",
        "WATTEGAMA - KURUNEGALA",
        "WATUWATTA - KURUNEGALA",
        "WEERAPOKUNA - KURUNEGALA",
        "WELAWA JUNCTON - KURUNEGALA",
        "WELIPENNAGAHAMULLA - KURUNEGALA",
        "WELLAGALA - KURUNEGALA",
        "WELLARAWA - KURUNEGALA",
        "WELLAWA - KURUNEGALA",
        "WELPALLA - KURUNEGALA",
        "WENNORUWA - KURUNEGALA",
        "WEUDA - KURUNEGALA",
        "WEWAGAMA - KURUNEGALA",
        "WILGAMUWA - KURUNEGALA",
        "YAKWILA - KURUNEGALA",
        "YATIGALOLUWA - KURUNEGALA"
    ],
    "MATARA": [
        "AKURESSA - MATARA",
        "ALAPALADENIYA - MATARA",
        "APAREKKA - MATARA",
        "ATHURALIYA - MATARA",
        "BENGAMUWA - MATARA",
        "BOPAGODA - MATARA",
        "DAMPAHALA - MATARA",
        "DEEGALA LENAMA - MATARA",
        "DEIYANDARA - MATARA",
        "DENAGAMA - MATARA",
        "DENIPITIYA - MATARA",
        "DENIYAYA - MATARA",
        "DERANGALA - MATARA",
        "DEVINUWARA (DONDRA) - MATARA",
        "DIKWELLA - MATARA",
        "DIYAGAHA - MATARA",
        "DIYALAPE - MATARA",
        "GANDARA - MATARA",
        "GODAPITIYA - MATARA",
        "GOMILAMAWARALA - MATARA",
        "HAWPE - MATARA",
        "HORAPAWITA - MATARA",
        "KALUBOWITIYANA - MATARA",
        "KAMBURUGAMUWA - MATARA",
        "KAMBURUPITIYA - MATARA",
        "KARAGODA UYANGODA - MATARA",
        "KARAPUTUGALA - MATARA",
        "KARATOTA - MATARA",
        "KEKANADURRA - MATARA",
        "KIRIWELDOLA - MATARA",
        "KIRIWELKELE - MATARA",
        "KOLAWENIGAMA - MATARA",
        "KOTAPOLA - MATARA",
        "LANKAGAMA - MATARA",
        "MAKANDURA - MATARA",
        "MALIDUWA - MATARA",
        "MARAMBA - MATARA",
        "MATARA - MATARA",
        "MEDIRIPITIYA - MATARA",
        "MIELLA - MATARA",
        "MIRISSA - MATARA",
        "MORAWAKA - MATARA",
        "MULATIYANA JUNCTION - MATARA",
        "NADUGALA - MATARA",
        "NAIMANA - MATARA",
        "PALATUWA - MATARA",
        "PARAPAMULLA - MATARA",
        "PASGODA - MATARA",
        "PENETIYANA - MATARA",
        "PITABEDDARA - MATARA",
        "PUHULWELLA - MATARA",
        "RADAWELA - MATARA",
        "RANSEGODA - MATARA",
        "ROTUMBA - MATARA",
        "SULTANAGODA - MATARA",
        "TELIJJAWILA - MATARA",
        "THIHAGODA - MATARA",
        "URUBOKKA - MATARA",
        "URUGAMUWA - MATARA",
        "URUMUTTA - MATARA",
        "VIHARAHENA - MATARA",
        "WALAKANDA - MATARA",
        "WALASGALA - MATARA",
        "WARALLA - MATARA",
        "WELIGAMA - MATARA",
        "WILPITA - MATARA",
        "YATIYANA - MATARA"
    ],
    "KEGALLE": [
        "ALAWALA - KEGALLE",
        "ALAWATURA - KEGALLE",
        "ALAWWA - KEGALLE",
        "ALGAMA - KEGALLE",
        "ALUTNUWARA - KEGALLE",
        "AMBALAKANDA - KEGALLE",
        "AMBULUGALA - KEGALLE",
        "AMITIRIGALA - KEGALLE",
        "AMPAGALA - KEGALLE",
        "ANHANDIYA - KEGALLE",
        "ANHETTIGAMA - KEGALLE",
        "ARANAYAKA - KEGALLE",
        "ARUGGAMMANA - KEGALLE",
        "BATUWITA - KEGALLE",
        "BELIGALA(SAB) - KEGALLE",
        "BELIHULOYA - KEGALLE",
        "BERANNAWA - KEGALLE",
        "BOPITIYA (SAB) - KEGALLE",
        "BOPITIYA - KEGALLE",
        "BORALANKADA - KEGALLE",
        "BOSSELLA - KEGALLE",
        "BULATHKOHUPITIYA - KEGALLE",
        "DAMUNUPOLA - KEGALLE",
        "DEBATHGAMA - KEGALLE",
        "DEDUGALA - KEGALLE",
        "DEEWALA PALLEGAMA - KEGALLE",
        "DEHIOWITA - KEGALLE",
        "DELDENIYA - KEGALLE",
        "DELOLUWA - KEGALLE",
        "DERANIYAGALA - KEGALLE",
        "DEWALEGAMA - KEGALLE",
        "DEWANAGALA - KEGALLE",
        "DOMBEMADA - KEGALLE",
        "DORAWAKA - KEGALLE",
        "DUNUMALA - KEGALLE",
        "GALAPITAMADA - KEGALLE",
        "GALATARA - KEGALLE",
        "GALIGAMUWA TOWN - KEGALLE",
        "GALLELLA - KEGALLE",
        "GALPATHA(SAB) - KEGALLE",
        "GANTUNA - KEGALLE",
        "GETAHETTA - KEGALLE",
        "GODAGAMPOLA - KEGALLE",
        "GONAGALA - KEGALLE",
        "HAKAHINNA - KEGALLE",
        "HAKBELLAWAKA - KEGALLE",
        "HALLOLUWA - KEGALLE",
        "HEDUNUWEWA - KEGALLE",
        "HEMMATAGAMA - KEGALLE",
        "HEWADIWELA - KEGALLE",
        "HINGULA - KEGALLE",
        "HINGURALAKANDA - KEGALLE",
        "HINGURANA - KEGALLE",
        "HIRIWADUNNA - KEGALLE",
        "IHALA WALPOLA - KEGALLE",
        "IHALAGAMA - KEGALLE",
        "IMBULANA - KEGALLE",
        "IMBULGASDENIYA - KEGALLE",
        "KABAGAMUWA - KEGALLE",
        "KAHAPATHWALA - KEGALLE",
        "KANDAKETYA - KEGALLE",
        "KANNATTOTA - KEGALLE",
        "KARAGAHINNA - KEGALLE",
        "KEGALLE - KEGALLE",
        "KEHELPANNALA - KEGALLE",
        "KETAWALA LEULA - KEGALLE",
        "KITULGALA - KEGALLE",
        "KONDENIYA - KEGALLE",
        "KOTIYAKUMBURA - KEGALLE",
        "LEWANGAMA - KEGALLE",
        "MAHABAGE - KEGALLE",
        "MAKEHELWALA - KEGALLE",
        "MALALPOLA - KEGALLE",
        "MALDENIYA - KEGALLE",
        "MALIBODA - KEGALLE",
        "MALIYADDA - KEGALLE",
        "MALMADUWA - KEGALLE",
        "MARAPANA - KEGALLE",
        "MAWANELLA - KEGALLE",
        "MEETANWALA - KEGALLE",
        "MIGASTENNA SABARA - KEGALLE",
        "MIYANAWITA - KEGALLE",
        "MOLAGODA - KEGALLE",
        "MORONTOTA - KEGALLE",
        "NARANGALA - KEGALLE",
        "NARANGODA - KEGALLE",
        "NATTARAMPOTHA - KEGALLE",
        "NELUNDENIYA - KEGALLE",
        "NIYADURUPOLA - KEGALLE",
        "NOORI - KEGALLE",
        "PANNILA - KEGALLE",
        "PATTAMPITIYA - KEGALLE",
        "PILAWALA - KEGALLE",
        "POTHUKOLADENIYA - KEGALLE",
        "PUSWELITENNA - KEGALLE",
        "RAMBUKKANA - KEGALLE",
        "RILPOLA - KEGALLE",
        "RUKMALE - KEGALLE",
        "RUWANWELLA - KEGALLE",
        "SAMANALAWEWA - KEGALLE",
        "SEAFORTH COLONY - KEGALLE",
        "SPRING VALLEY - KEGALLE",
        "TALGASPITIYA - KEGALLE",
        "TELIGAMA - KEGALLE",
        "THOLANGAMUWA - KEGALLE",
        "THOTAWELLA - KEGALLE",
        "UDAHA HAWUPE - KEGALLE",
        "UDAPOTHA - KEGALLE",
        "UDUWA - KEGALLE",
        "UNDUGODA - KEGALLE",
        "USSAPITIYA - KEGALLE",
        "WAHAKULA - KEGALLE",
        "WAHARAKA - KEGALLE",
        "WANALUWEWA - KEGALLE",
        "WARAKAPOLA - KEGALLE",
        "WATURA - KEGALLE",
        "WEEOYA - KEGALLE",
        "WEGALLA - KEGALLE",
        "WELIGALLA - KEGALLE",
        "WELIHELATENNA - KEGALLE",
        "WEWELWATTA - KEGALLE",
        "YATAGAMA - KEGALLE",
        "YATAPANA - KEGALLE",
        "YATIYANTOTA - KEGALLE",
        "YATTOGODA - KEGALLE"
    ],
    "KANDY": [
        "ALUDENIYA - KANDY",
        "AMBAGAHAPELESSA - KANDY",
        "AMBAGAMUWA UDABULATHGAMA - KANDY",
        "AMBATENNA - KANDY",
        "AMPITIYA - KANDY",
        "ANKUMBURA - KANDY",
        "ATABAGE - KANDY",
        "BALANA - KANDY",
        "BAMBARAGAHAELA - KANDY",
        "BATAGOLLADENIYA - KANDY",
        "BATUGODA - KANDY",
        "BATUMULLA - KANDY",
        "BAWLANA - KANDY",
        "BOPANA - KANDY",
        "DANTURE - KANDY",
        "DEDUNUPITIYA - KANDY",
        "DEKINDA - KANDY",
        "DELTOTA - KANDY",
        "DIGANA - KANDY",
        "DIVULANKADAWALA - KANDY",
        "DOLAPIHILLA - KANDY",
        "DOLOSBAGE - KANDY",
        "DUNUWILA - KANDY",
        "ETULGAMA - KANDY",
        "GALABODA - KANDY",
        "GALAGEDARA - KANDY",
        "GALAHA - KANDY",
        "GALHINNA - KANDY",
        "GAMPOLA - KANDY",
        "GELIOYA - KANDY",
        "GODAMUNNA - KANDY",
        "GOMAGODA - KANDY",
        "GONAGANTENNA - KANDY",
        "GONAWALAPATANA - KANDY",
        "GUNNEPANA - KANDY",
        "GURUDENIYA - KANDY",
        "HAKMANA - KANDY",
        "HANDAGANAWA - KANDY",
        "HANDAWALAPITIYA - KANDY",
        "HANDESSA - KANDY",
        "HANGURANKETHA - KANDY",
        "HARANGALAGAMA - KANDY",
        "HATARALIYADDA - KANDY",
        "HINDAGALA - KANDY",
        "HONDIYADENIYA - KANDY",
        "HUNNASGIRIYA - KANDY",
        "INGURUWATTA - KANDY",
        "JAMBUGAHAPITIYA - KANDY",
        "KADUGANNAWA - KANDY",
        "KAHATALIYADDA - KANDY",
        "KALUGALA - KANDY",
        "KANDY - KANDY",
        "KAPULIYADDE - KANDY",
        "KATUGASTOTA - KANDY",
        "KATUKITULA - KANDY",
        "KELANIGAMA - KANDY",
        "KENGALLA - KANDY",
        "KETABOOLA - KANDY",
        "KETAKUMBURA - KANDY",
        "KOBONILA - KANDY",
        "KOLABISSA - KANDY",
        "KOLONGODA - KANDY",
        "KULUGAMMANA - KANDY",
        "KUMBUKKANDURA - KANDY",
        "KUMBUREGAMA - KANDY",
        "KUNDASALE - KANDY",
        "LEEMAGAHAKOTUWA - KANDY",
        "LHALA KOBBEKADUWA - KANDY",
        "LUNUGAMA - KANDY",
        "LUNUKETIYA MADITTA - KANDY",
        "MADAWALA BAZAAR - KANDY",
        "MADAWALALANDA - KANDY",
        "MADUGALLA - KANDY",
        "MADULKELE - KANDY",
        "MAHADORALIYADDA - KANDY",
        "MAHAMEDAGAMA - KANDY",
        "MAHANAGAPURA - KANDY",
        "MAILAPITIYA - KANDY",
        "MAKKANIGAMA - KANDY",
        "MAKULDENIYA - KANDY",
        "MANGALAGAMA - KANDY",
        "MAPAKANDA - KANDY",
        "MARASSANA - KANDY",
        "MARYMOUNT COLONY - KANDY",
        "MAWATURA - KANDY",
        "MEDAMAHANUWARA - KANDY",
        "MEDAWALA HARISPATTUWA - KANDY",
        "MEETALAWA - KANDY",
        "MEGODA KALUGAMUWA - KANDY",
        "MENIKDIWELA - KANDY",
        "MENIKHINNA - KANDY",
        "MIMURE - KANDY",
        "MINIGAMUWA - KANDY",
        "MINIPE - KANDY",
        "MORAGAHAPALLAMA - KANDY",
        "MURUTALAWA - KANDY",
        "MURUTHAGAHAMULLA - KANDY",
        "NANUOYA - KANDY",
        "NARANPANAWA - KANDY",
        "NARAWELPITA - KANDY",
        "NAWALAPITIYA - KANDY",
        "NAWATHISPANE - KANDY",
        "NILLAMBE - KANDY",
        "NUGALIYADDA - KANDY",
        "OVILIKANDA - KANDY",
        "PALLEKOTUWA - KANDY",
        "PANWILATENNA - KANDY",
        "PARADEKA - KANDY",
        "PASBAGE - KANDY",
        "PATTITALAWA - KANDY",
        "PERADENIYA - KANDY",
        "PILIMATALAWA - KANDY",
        "POHOLIYADDA - KANDY",
        "PUBBILIYA - KANDY",
        "PUPURESSA - KANDY",
        "PUSSELLAWA - KANDY",
        "PUTUHAPUWA - KANDY",
        "RAJAWELLA - KANDY",
        "RAMBUKPITIYA - KANDY",
        "RAMBUKWELLA - KANDY",
        "RANGALA - KANDY",
        "RANTEMBE - KANDY",
        "SANGARAJAPURA - KANDY",
        "SENARATHWELA - KANDY",
        "TALATUOYA - KANDY",
        "TELDENIYA - KANDY",
        "TENNEKUMBURA - KANDY",
        "UDA PERADENIYA - KANDY",
        "UDAHENTENNA - KANDY",
        "UDATALAWINNA - KANDY",
        "UDISPATTUWA - KANDY",
        "UDUDUMBARA - KANDY",
        "UDUWAHINNA - KANDY",
        "UDUWELA - KANDY",
        "ULAPANE - KANDY",
        "UNUWINNA - KANDY",
        "VELAMBODA - KANDY",
        "WATAGODA - KANDY",
        "WATAGODA HARISPATTUWA - KANDY",
        "WATTAPPOLA - KANDY",
        "WELIGAMPOLA - KANDY",
        "WENDARUWA - KANDY",
        "WERAGANTOTA - KANDY",
        "WERAPITYA - KANDY",
        "WERELLAGAMA - KANDY",
        "WETTAWA - KANDY",
        "YAHALATENNA - KANDY",
        "YATIHALAGALA - KANDY"
    ],
    "HAMBANTOTA": [
        "AMBALANTOTA - HAMBANTOTA",
        "ANGUNAKOLAPELESSA - HAMBANTOTA",
        "ANGUNAKOLAWEWA - HAMBANTOTA",
        "BANDAGIRIYA COLONY - HAMBANTOTA",
        "BARAWAKUMBUKA - HAMBANTOTA",
        "BELIATTA - HAMBANTOTA",
        "BERAGAMA - HAMBANTOTA",
        "BERALIHELA - HAMBANTOTA",
        "BUNDALA - HAMBANTOTA",
        "ELLAGALA - HAMBANTOTA",
        "GANGULANDENIYA - HAMBANTOTA",
        "GETAMANNA - HAMBANTOTA",
        "GODA KOGGALLA - HAMBANTOTA",
        "GONAGAMUWA UDUWILA - HAMBANTOTA",
        "GONNORUWA - HAMBANTOTA",
        "HAKURUWELA - HAMBANTOTA",
        "HAMBANTOTA - HAMBANTOTA",
        "HANDUGALA - HAMBANTOTA",
        "HUNGAMA - HAMBANTOTA",
        "IHALA BELIGALLA - HAMBANTOTA",
        "ITTADEMALIYA - HAMBANTOTA",
        "JULAMPITIYA - HAMBANTOTA",
        "KAHANDAMODARA - HAMBANTOTA",
        "KARIYAMADITTA - HAMBANTOTA",
        "KATUWANA - HAMBANTOTA",
        "KAWANTISSAPURA - HAMBANTOTA",
        "KIRAMA - HAMBANTOTA",
        "KIRINDA - HAMBANTOTA",
        "LUNAMA - HAMBANTOTA",
        "LUNUGAMWEHERA - HAMBANTOTA",
        "MAGAMA - HAMBANTOTA",
        "MAHAGALWEWA - HAMBANTOTA",
        "MAMADALA - HAMBANTOTA",
        "MEDAMULANA - HAMBANTOTA",
        "MIDDENIYA - HAMBANTOTA",
        "MIGAHAJANDUR - HAMBANTOTA",
        "MODARAWANA - HAMBANTOTA",
        "MULKIRIGALA - HAMBANTOTA",
        "NAKULUGAMUWA - HAMBANTOTA",
        "NETOLPITIYA - HAMBANTOTA",
        "NIHILUWA - HAMBANTOTA",
        "PADAWKEMA - HAMBANTOTA",
        "PAHALA ANDARAWEWA - HAMBANTOTA",
        "RAMMALAWARAPITIYA - HAMBANTOTA",
        "RANAKELIYA - HAMBANTOTA",
        "RANMUDUWEWA - HAMBANTOTA",
        "RANNA - HAMBANTOTA",
        "RATMALWALA - HAMBANTOTA",
        "RU/RIDIYAGAMA - HAMBANTOTA",
        "SOORIYAWEWA TOWN - HAMBANTOTA",
        "TANGALLA - HAMBANTOTA",
        "TISSAMAHARAMA - HAMBANTOTA",
        "UDA GOMADIYA - HAMBANTOTA",
        "UDAMATTALA - HAMBANTOTA",
        "USWEWA - HAMBANTOTA",
        "VITHARANDENIYA - HAMBANTOTA",
        "WALASMULLA - HAMBANTOTA",
        "WEERAKETIYA - HAMBANTOTA",
        "WEERAWILA - HAMBANTOTA",
        "WEERAWILA NEWTOWN - HAMBANTOTA",
        "WEKANDAWELA - HAMBANTOTA",
        "WELIGATTA - HAMBANTOTA",
        "YATIGALA - HAMBANTOTA"
    ],
    "ANURADHAPURA": [
        "ANGAMUWA - ANURADHAPURA",
        "ANURADHAPURA - ANURADHAPURA",
        "AWUKANA - ANURADHAPURA",
        "BOGAHAWEWA - ANURADHAPURA",
        "DEMATAWEWA - ANURADHAPURA",
        "DIMBULAGALA - ANURADHAPURA",
        "DUTUWEWA - ANURADHAPURA",
        "ELAYAPATTUWA - ANURADHAPURA",
        "ELLEWEWA - ANURADHAPURA",
        "EPPAWALA - ANURADHAPURA",
        "ETAWATUNUWEWA - ANURADHAPURA",
        "ETAWEERAGOLLEWA - ANURADHAPURA",
        "GALAPITAGALA - ANURADHAPURA",
        "GALENBINDUNUWEWA - ANURADHAPURA",
        "GALKADAWALA - ANURADHAPURA",
        "GALKIRIYAGAMA - ANURADHAPURA",
        "GALKULAMA - ANURADHAPURA",
        "GALNEWA - ANURADHAPURA",
        "GAMBIRIGASWEWA - ANURADHAPURA",
        "GANEWALPOLA - ANURADHAPURA",
        "GEMUNUPURA - ANURADHAPURA",
        "GETALAWA - ANURADHAPURA",
        "GNANIKULAMA - ANURADHAPURA",
        "GONAHADDENAWA - ANURADHAPURA",
        "HABARANA - ANURADHAPURA",
        "HALMILLAWA DAMBULLA - ANURADHAPURA",
        "HALMILLAWETIYA - ANURADHAPURA",
        "HIDOGAMA - ANURADHAPURA",
        "HORAWPATANA - ANURADHAPURA",
        "HORIWILA - ANURADHAPURA",
        "HURIGASWEWA - ANURADHAPURA",
        "HURULUNIKAWEWA - ANURADHAPURA",
        "IHALA PULIYANKULAMA - ANURADHAPURA",
        "IHALAGAMA - ANURADHAPURA",
        "IPOLOGAMA - ANURADHAPURA",
        "KAGAMA - ANURADHAPURA",
        "KAHATAGASDIGILIYA - ANURADHAPURA",
        "KAHATAGOLLEWA - ANURADHAPURA",
        "KALAKARAMBEWA - ANURADHAPURA",
        "KALAOYA - ANURADHAPURA",
        "KALAWEDI ULPOTHA - ANURADHAPURA",
        "KALLANCHIYA - ANURADHAPURA",
        "KALPITIYA - ANURADHAPURA",
        "KALUKELE BADANAGALA - ANURADHAPURA",
        "KAPUGALLAWA - ANURADHAPURA",
        "KARAGAHAWEWA - ANURADHAPURA",
        "KASHYAPAPURA - ANURADHAPURA",
        "KEBITHIGOLLEWA - ANURADHAPURA",
        "KEKIRAWA - ANURADHAPURA",
        "KENDEWA - ANURADHAPURA",
        "KIRALOGAMA - ANURADHAPURA",
        "KIRIGALWEWA - ANURADHAPURA",
        "KIRIMUNDALAMA - ANURADHAPURA",
        "KITULHITIYAWA - ANURADHAPURA",
        "KURUNDANKULAMA - ANURADHAPURA",
        "LABUNORUWA - ANURADHAPURA",
        "MADATUGAMA - ANURADHAPURA",
        "MAHA ELAGAMUWA - ANURADHAPURA",
        "MAHABULANKULAMA - ANURADHAPURA",
        "MAHAILLUPPALLAMA - ANURADHAPURA",
        "MAHAKANADARAWA - ANURADHAPURA",
        "MAHAPOTHANA - ANURADHAPURA",
        "MAHASENPURA - ANURADHAPURA",
        "MAHAWILACHCHIYA - ANURADHAPURA",
        "MAILAGASWEWA - ANURADHAPURA",
        "MALWANAGAMA - ANURADHAPURA",
        "MANERUWA - ANURADHAPURA",
        "MARADANKADAWALA - ANURADHAPURA",
        "MARADANKALLA - ANURADHAPURA",
        "MEDAWACHCHIYA - ANURADHAPURA",
        "MEGODAWEWA - ANURADHAPURA",
        "MIHINTALE - ANURADHAPURA",
        "MORAKEWA - ANURADHAPURA",
        "MULKIRIYAWA - ANURADHAPURA",
        "MURIYAKADAWALA - ANURADHAPURA",
        "NACHCHADUWA - ANURADHAPURA",
        "NAMALPURA - ANURADHAPURA",
        "NEGAMPAHA - ANURADHAPURA",
        "NOCHCHIYAGAMA - ANURADHAPURA",
        "NUWARAGALA - ANURADHAPURA",
        "PADAVI MAITHRIPURA - ANURADHAPURA",
        "PADAVI PARAKRAMAPURA - ANURADHAPURA",
        "PADAVI SRIPURA - ANURADHAPURA",
        "PADAVI SRITISSAPURA - ANURADHAPURA",
        "PADAVIYA - ANURADHAPURA",
        "PADIKARAMADUWA - ANURADHAPURA",
        "PAHALA HALMILLEWA - ANURADHAPURA",
        "PAHALA MARAGAHAWE - ANURADHAPURA",
        "PAHALAGAMA - ANURADHAPURA",
        "PALUGASWEWA - ANURADHAPURA",
        "PANDUKABAYAPURA - ANURADHAPURA",
        "PANDULAGAMA - ANURADHAPURA",
        "PARAKUMPURA - ANURADHAPURA",
        "PARANGIYAWADIYA - ANURADHAPURA",
        "PARASANGAHAWEWA - ANURADHAPURA",
        "PELATIYAWA - ANURADHAPURA",
        "PEMADUWA - ANURADHAPURA",
        "PERIMIYANKULAMA - ANURADHAPURA",
        "PIHIMBIYAGOLEWA - ANURADHAPURA",
        "PUBBOGAMA - ANURADHAPURA",
        "PUNEWA - ANURADHAPURA",
        "RAJANGANAYA - ANURADHAPURA",
        "RAMBEWA - ANURADHAPURA",
        "RAMPATHWILA - ANURADHAPURA",
        "RATHMALGAHAWEWA - ANURADHAPURA",
        "SALIYAPURA - ANURADHAPURA",
        "SEEPPUKULAMA - ANURADHAPURA",
        "SENAPURA - ANURADHAPURA",
        "SIVALAKULAMA - ANURADHAPURA",
        "SIYAMBALEWA - ANURADHAPURA",
        "SRAVASTHIPURA - ANURADHAPURA",
        "TALAWA - ANURADHAPURA",
        "TAMBUTTEGAMA - ANURADHAPURA",
        "TAMMENNAWA - ANURADHAPURA",
        "TANTIRIMALE - ANURADHAPURA",
        "TELHIRIYAWA - ANURADHAPURA",
        "THAMBUTHTHEGAMA - ANURADHAPURA",
        "TIRAPPANE - ANURADHAPURA",
        "TITTAGONEWA - ANURADHAPURA",
        "UDUNUWARA COLONY - ANURADHAPURA",
        "UPULDENIYA - ANURADHAPURA",
        "UTTIMADUWA - ANURADHAPURA",
        "VELLAMANAL - ANURADHAPURA",
        "VIHARAPALUGAMA - ANURADHAPURA",
        "WAHALKADA - ANURADHAPURA",
        "WAHAMALGOLLEWA - ANURADHAPURA",
        "WALAGAMBAHUWA - ANURADHAPURA",
        "WALAHAVIDDAWEWA - ANURADHAPURA",
        "WELIMUWAPOTANA - ANURADHAPURA",
        "WELIOYA PROJECT - ANURADHAPURA"
    ],
    "POLONNARUWA": [
        "ATTANAKADAWALA - POLONNARUWA",
        "BAKAMUNA - POLONNARUWA",
        "DIYABEDUMA - POLONNARUWA",
        "ELAHERA - POLONNARUWA",
        "GIRITALE - POLONNARUWA",
        "HINGURAKDAMANA - POLONNARUWA",
        "HINGURAKGODA - POLONNARUWA",
        "JAYANTHIPURA - POLONNARUWA",
        "KALINGAELA - POLONNARUWA",
        "LAKSHAUYANA - POLONNARUWA",
        "MANKEMI - POLONNARUWA",
        "MINNERIYA - POLONNARUWA",
        "ONEGAMA - POLONNARUWA",
        "ORUBENDI SIYAMBALAWA - POLONNARUWA",
        "PALUGASDAMANA - POLONNARUWA",
        "PANICHANKEMI - POLONNARUWA",
        "POLONNARUWA - POLONNARUWA",
        "TALPOTHA - POLONNARUWA",
        "TAMBALA - POLONNARUWA",
        "UNAGALAVEHERA - POLONNARUWA",
        "WIJAYABAPURA - POLONNARUWA"
    ],
    "MONARAGALA": [
        "AYIWELA - MONARAGALA",
        "BADALKUMBURA - MONARAGALA",
        "BADULUWELA - MONARAGALA",
        "BAKINIGAHAWELA - MONARAGALA",
        "BALAHARUWA - MONARAGALA",
        "BIBILE - MONARAGALA",
        "BUDDAMA - MONARAGALA",
        "BUTTALA - MONARAGALA",
        "DAMBAGALLA - MONARAGALA",
        "DIYAKOBALA - MONARAGALA",
        "DOMBAGAHAWELA - MONARAGALA",
        "ETHIMALEWEWA - MONARAGALA",
        "ETTILIWEWA - MONARAGALA",
        "GALABEDDA - MONARAGALA",
        "GAMEWELA - MONARAGALA",
        "HAMBEGAMUWA - MONARAGALA",
        "HINGURUKADUWA - MONARAGALA",
        "HULANDAWA - MONARAGALA",
        "INGINIYAGALA - MONARAGALA",
        "KANDAUDAPANGUWA - MONARAGALA",
        "KANDAWINNA - MONARAGALA",
        "KATARAGAMA - MONARAGALA",
        "KOTAGAMA - MONARAGALA",
        "KOTAMUDUNA - MONARAGALA",
        "KOTAWEHERA MANKADA - MONARAGALA",
        "KUDAWEWA - MONARAGALA",
        "KUMBUKKANA - MONARAGALA",
        "MARAWA - MONARAGALA",
        "MARIARAWA - MONARAGALA",
        "MEDAGANA - MONARAGALA",
        "MEDAWELAGAMA - MONARAGALA",
        "MIYANAKANDURA - MONARAGALA",
        "MONARAGALA - MONARAGALA",
        "MORETUWEGAMA - MONARAGALA",
        "NAKKALA - MONARAGALA",
        "NAMUNUKULA - MONARAGALA",
        "NANNAPURAWA - MONARAGALA",
        "NELLIYADDA - MONARAGALA",
        "NILGALA - MONARAGALA",
        "OBBEGODA - MONARAGALA",
        "OKKAMPITIYA - MONARAGALA",
        "PANGURA - MONARAGALA",
        "PITAKUMBURA - MONARAGALA",
        "RANDENIYA - MONARAGALA",
        "RUWALWELA - MONARAGALA",
        "SELLA KATARAGAMA - MONARAGALA",
        "SIYAMBALAGUNE - MONARAGALA",
        "SIYAMBALANDUWA - MONARAGALA",
        "SURIARA - MONARAGALA",
        "TANAMALWILA - MONARAGALA",
        "UVA GANGODAGAMA - MONARAGALA",
        "UVA KUDAOYA - MONARAGALA",
        "UVA PELWATTA - MONARAGALA",
        "WARUNAGAMA - MONARAGALA",
        "WEDIKUMBURA - MONARAGALA",
        "WEHERAYAYA HANDAPANAGALA - MONARAGALA",
        "WELLAWAYA - MONARAGALA",
        "WILAOYA - MONARAGALA",
        "YUDAGANAWA - MONARAGALA"
    ],
    "JAFFNA": [
        "JAFFNA - JAFFNA"
    ],
    "KILINOCHCHI": [
        "KANDAVALAI - KILINOCHCHI",
        "KARACHCHI - KILINOCHCHI",
        "KILINOCHCHI - KILINOCHCHI",
        "PACHCHILAIPALLI - KILINOCHCHI",
        "POONAKARY - KILINOCHCHI"
    ],
    "MANNAR": [
        "MANNAR - MANNAR",
        "PUTHUKUDIYIRUPPU - MANNAR"
    ],
    "KEEMBIYA": [
        "MEDA-KEEMBIYA - GALLE"
    ],
    "MULLAITIVU": [
        "MULLATIVU - MULLAITIVU"
    ],
    "VAVUNIYA": [
        "VAVUNIYA - VAVUNIYA"
    ]
} as any;

export function getAllLocation(){
return locationsAll;
    
}
