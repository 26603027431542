import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { NAV_PRIVACY_POLICY } from "../../providers/NavigationProvider";

const PrivacyPolicy = () => {

    const dispatch = useDispatch();
    const scrollContent = document.getElementById('scrollable-root');

    useEffect(() => {
        dispatch(setCurrentLocation(NAV_PRIVACY_POLICY));
        scrollContent?.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ padding: '10px 20px', maxWidth: "924px", marginLeft: "auto", marginRight: "auto" }}>
            <h1>Privacy Policy</h1>

            At saruwata.lk, we know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy.
            By using or accessing the Service in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.
            Remember that your use of saruwata.lk’s Service is at all times subject to our Terms of Service, which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the Terms of Service. Our Privacy Policy applies to all users of the saruwata website: saruwata.lk  (and any subdomains).
            <br /><br />
            <h1>What does this Privacy Policy Cover?</h1>
            This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Service. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.
            <br />
            <h1>Changes to this Privacy Policy</h1>
            saruwata.lk may change this Privacy Policy from time to time. Laws, regulations, and industry standards evolve, which may make those changes necessary, or we may make changes to our Service or business.  We will post the changes to this page and encourage you to review our Privacy Policy to stay informed. If we make changes that materially alter your privacy rights, we will do our best to alert you to changes by placing a notice on the saruwata website, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Service, and you are still responsible for reading and understanding them.
            If you use the Service after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. The use of the information we collect is subject to the Privacy Policy in effect at the time such information is collected.
            <br />
            <h1>What Personal data does saruwata.lk Collect?</h1>
            Saruwata.lk also creates profiles of people and companies, which we call “Profiles,” from different sources. Once we have collected information (primarily business-related information) about a person or company, we combine multiple mentions of the same person or company into a Profile. Profiles are then made available to users of the Service, and our customers and strategic partners.
            When we create or enhance Profiles about individuals, we may collect this information from multiple sources, including:
            Publicly available web sources that we scan using technology or manual methods.
            User contributions about themselves or other people and companies.
            Research conducted internally by saruwata.lk
            <br />
            <h1>What Categories of Personal Data We Collect.</h1>
            Throughout this Privacy Policy, we will refer back to the categories of Personal Data listed below,
            Name, email, username, phone number, demographic data, information about devices, social media profile IDs/links.
            The following sections provide additional information about how we collect your Personal Data.
            Information You Provide to Us: We receive and store any information you knowingly provide to us.  For example, through the registration process and/or through your account settings, we may collect Personal Data such as your name, email address, phone number.
            When you log in using your third-party account credentials (such as Twitter, Facebook, or Google), you understand some content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your account with us, and that Third Party Account Information transmitted to our Service is covered by this Privacy Policy. Certain information may be required to register with us or to take advantage of some of our features.
            We may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers on behalf of other businesses, or email you about your use of the Service.  Also, we may receive a confirmation when you open an email from us, which helps us improve our Service.
            You can decide whether or not to accept cookies through your internet browser’s settings. Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new cookie in a variety of ways. You can also delete all cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site, and this may cause some services and functionalities to not work.

            <h1></h1>
            We process Personal Data to operate, improve, understand and personalize our Service. We use Personal Data for the following purposes:
            <br></br>
            To meet or fulfill the reason you provided the information to us.
            To communicate with you about the Service, including Service announcements, updates, or offers.
            To provide support and assistance for the Service.
            To create and manage your Account or other user profiles.
            To personalize your experience, website content, and communications based on your preferences, including targeted offers and ads, served through the Service.
            To process orders or other transactions.
            To respond to user inquiries and fulfill user requests.
            To market, improve, and develop the Service, including testing, research, analysis, and product development (including creation and enhancement of Profiles).
            To protect against or deter fraudulent, illegal, or harmful actions and maintain the safety, security, and integrity of our Service.
            To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Service.
            To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
            <br /><br />
            We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice.
            As noted in the list above, we may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers on behalf of other businesses or email you about your use of the Service.  Also, we may receive a confirmation when you open an email from us, which helps us improve our Service. If you do not want to receive communications from us, please indicate your preference on the Account Information page or contact us at support@saruwata.lk.
            <br /><br />
            <h1>How We Share Your Personal Data</h1>

            We disclose your Personal Data to service providers and other parties for the following business purposes:
            <br />
            Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.
            Auditing related to a current interaction and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards.
            Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.
            Debugging to identify and repair errors that impair existing intended functionality.
            Short-term, transient use of Personal Data that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction.
            Undertaking internal research for technological development and demonstration.
            Undertaking activities to verify or maintain the quality or safety of a service or device that we own, manufacture, was manufactured for us, or control.
            We disclose your Personal Data to the following categories of service providers and other parties:
            Service providers, including:
            Subscription management providers and payment processors
            Security and fraud prevention consultants
            Hosting and other technology and communications providers
            Analytics providers
            Customer relationship management and customer support providers
            Ad networks
            Parties acquire your Personal Data through an acquisition or other change of control.
            Personal Data may be transferred to a third party if we undergo a merger, acquisition, bankruptcy, or other transaction in which that third party assumes control of our business (in whole or in part)
            Other parties in your direction.
            Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Service)
            Social media services (if you intentionally interact with them through your use of the Service)
            Other parties authorized by you
            We have disclosed the following categories of your Personal Data to service providers or other parties for the business purposes listed above:
            A. Personal identifiers.
            B. Customer records identified by state law.
            C. Protected classification characteristics under state or federal law.
            D. Internet or other similar network activity information.
            E. Professional or employment-related information.
            <br /><br />
            <h1>Sales of Personal Data</h1>

            At saruwata.lk, we collect information from a variety of sources (including information contributed directly by you and/or our user community) in order to compile Profiles about entities and individuals. Please refer to the chart above titled “Categories of Personal Data We Collect” for a better understanding of the Personal Data we collect that constitutes Profile data.
            <br />
            <h2>Data Security and Retention</h2>
            We seek to protect your Personal Data from unauthorized access, use, and disclosure using appropriate physical, technical, organizational, and administrative security measures based on the type of Personal Data and how we are processing that data.
            <br /><br />
            You should also help protect your data by appropriately selecting and protecting your password and/or another sign-on mechanism; limiting access to your computer or device and browser, and signing off after you have finished accessing your account. Although we work to protect the privacy of your account and other Personal Data that we hold in our records, no security system is impenetrable, and unanticipated system failures or the efforts of malicious actors are an unfortunate reality on the Internet. Therefore, saruwata.lk cannot guarantee that Personal Data during transmission through the Internet or while stored on our systems or otherwise in our care will be absolutely safe from intrusion by others.
            <br /><br />
            <h2>Personal Data of Children</h2>
            As noted in the Terms of Service, we do not knowingly collect or solicit Personal Data from children under 16. If you are a child under 16, please do not attempt to register for or otherwise use the Service or send us any Personal Data. If we learn we have collected Personal Data from a child under 16, we will delete that information as quickly as possible. If you believe that a child under 16 may have provided us Personal Data, please contact us at support@saruwata.lk
            <br />
            <h2>Access</h2>
            You have the right to request more information about the Personal Data we hold about you and request a copy of such Personal Data by emailing support@saruwata.lk  or submitting a request through our website.
            <br />
            <h2>Deletion</h2>
            You have the right to request that we delete the Personal Data that we have collected from you. This right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Service or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.
            <br />
            <h2>Exercising Your Rights</h2>
            To exercise the rights described above, you must send us a request that,
            <br></br>
            (1) provides sufficient information to allow us to verify that you are the person about whom we have collected Personal Data.
            <br></br>
            (2) describes your request in sufficient detail to allow us to understand, evaluate, and respond to it.
            <br></br>
            Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify you and complete your request. You do not need an account to submit a Valid Request.
            We will work to respond to your Valid Request within 48 hours of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive, or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
            <br></br>
            You may submit a Valid Request using the following methods:
            <br></br>
            Emailing us at support@saruwata.lk
            <br></br>
            Submitting a request at:https://saruwata.lk
            <br></br>
            <h2>Contact Information:</h2>
            If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data, your choices, and rights regarding such use, please do not hesitate to contact saruwata.lk representative or email us at support@saruwata.lk
            <h2>Unsubscribe information</h2>
            If at any time you wish to have your information reviewed or removed from our active databases, please contact us at support@saruwata.lk.
            We will work to respond to your Valid Request within 48 hours of receipt. We will not charge you a fee for sending and unsubscribing requests. unless your unsubscribing request(s) is excessive, repetitive, or manifestly unfounded. If we determine that your unsubscribing request warrants a fee, we will notify you of the fee and explain that decision before completing your request.


            <div style={{ height: '50px' }}></div>
        </div>
    );
}

export default PrivacyPolicy;