import React from "react";

export class SDistricts {

    constructor() { }

    district_sinhala_json = {
    'AMPARA': 'අම්පාර',
    'ANURADHAPURA': 'අනුරාධපුර',
    'BADULLA': 'බදුල්ල',
    'BATTICALOA': 'මඩකලපුව',
    'COLOMBO': 'කොළඹ',
    'GALLE': 'ගාල්ල',
    'VAVUNIYA': 'වවුනියාව',
    'TRINCOMALEE': 'ත්‍රිකුණාමලය',
    'RATNAPURA': 'රත්නපුර',
    'PUTTALAM': 'පුත්තලම',
    'POLONNARUWA': 'පොළොන්නරුව',
    'NUWARA ELIYA': 'නුවරඑලිය',
    'MULLAITIVU': 'මුලතිව්',
    'MONARAGALA': 'මොනරාගල',
    'MATARA': 'මාතර',
    'MATALE': 'මාතලේ',
    'MANNAR': 'මන්නාරම',
    'KURUNEGALA': 'කුරුණෑගල',
    'KILINOCHCHI': 'කිලිනොච්චි',
    'KEGALLE': 'කෑගල්ල',
    'KANDY': 'මහනුවර',
    'KALUTARA': 'කළුතර',
    'JAFFNA': 'යාපනය',
    'HAMBANTOTA': 'හම්බන්තොට',
    'GAMPAHA': 'ගම්පහ',

} as any;

district_tamil_json = {
    'AMPARA': 'அம்பாறை',
    'ANURADHAPURA': 'அனுராதபுரம்',
    'BADULLA': 'பதுளை',
    'BATTICALOA': 'மட்டக்களப்பு',
    'COLOMBO': 'கொழும்பு',
    'GALLE': 'காலி',
    'VAVUNIYA': 'வவுனியா',
    'TRINCOMALEE': 'திருகோணமலை',
    'RATNAPURA': 'இரத்தினபுரி',
    'PUTTALAM': 'புத்தளம்',
    'POLONNARUWA': 'பொலன்னறுவை',
    'NUWARA ELIYA': 'நுவரெலியா',
    'MULLAITIVU': 'முல்லைத்தீவு',
    'MONARAGALA': 'மொனராகலை',
    'MATARA': 'மாத்தறை',
    'MATALE': 'மாத்தளை',
    'MANNAR': 'மன்னார்',
    'KURUNEGALA': 'குருநாகல்',
    'KILINOCHCHI': 'கிளிநொச்சி',
    'KEGALLE': 'கேகாலை',
    'KANDY': 'மேலாண்மை கண்டி',
    'KALUTARA': 'களுத்துறையில்',
    'JAFFNA': 'யாழ்',
    'HAMBANTOTA': 'ஹம்பாந்தோட்டை',
    'GAMPAHA': 'கம்பஹா',

} as any;

   getSinhalaDistrictName(name: string){

        return this.district_sinhala_json[`${name.trim()}`];
    }
   getTamilDistrictName(name: string){

        return this.district_tamil_json[`${name.trim()}`];
    }

}