import { IonSpinner } from "@ionic/react";
import React from "react";
import { InteractionContext } from "../../providers/InteractionProvider";
import "./Loader.css";
import PulseLoader from "react-spinners/PulseLoader";

const Loader = (props: any) => {
  return (
    <InteractionContext.Consumer>
      {(value) => (value?.busy ? (
          <div id="loader-wrapper">
              <div style={{height:'200px'}}></div>
              <PulseLoader color="#0080ff" loading={value?.busy} />
          </div>
      ) : null)}
    </InteractionContext.Consumer>
  );
};

export default Loader;
