

import React, { useEffect, useState } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { UserContext } from "./providers/UserProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  readUserFromLocalStorage,
  selectLoggedUser,
  setAuthFreeze,
} from "./features/session/sessionSlice";
import { NavigationContext } from "./providers/NavigationProvider";
import {
  selectBusy,
  selectCurrentBehavior,
  selectCurrentLocation,
} from "./features/navigation/navigationSlice";
import "./App.css";
import { checkUserState, signOut } from "./features/session/firebaseSlice";
import { InteractionContext } from "./providers/InteractionProvider";
import { getAllAvailablePosts } from "./features/post/postSlice";
import Layout from "./Layouts/Layout";
import { createMuiTheme, createTheme, ThemeProvider } from "@material-ui/core";
import LandingPage from "./pages/LandingPage/LandingPage";
import Maintenance from "./pages/Maintenance/Maintenance";
import axios from "axios";
import { GET_DISTRICTS, GET_LOCATION } from "./config/urls";
import { setCityToDistricts, setDistricts } from "./config/locations";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import NotFound from "./pages/ErrorPages/NotFound";
import TimeOut from "./pages/ErrorPages/TimeOut";
// import { useClearCacheCtx } from 'react-clear-cache';
import { isMobile, MobileView } from 'react-device-detect';
import packageJson from '../package.json';
import { auth } from ".";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import SearchResult from "./pages/SearchPage/SearchResult/SearchResult";
import DetailsPage from "./pages/DetailsPage/DetailsPage";
import SellerPage from "./pages/SellerPage/SellerPage";
import LikesPage from "./pages/LikesPage/LikesPage";
import MyAds from "./pages/MyAds/MyAds";
import { Membership } from "./components/Membership/Membership";
import MyMembership from "./components/Membership/MyMembership";
import { AdPromotions } from "./components/AdPromotion/AdPromotion";
import AddPost from "./pages/AddPost/AddPost";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/PrivacyPolicy/TermsAndConditions";
import { Support } from "aws-sdk";
import PaymentOverview from "./a_components/pages/payment/PaymentOverview";
import Summery from "./a_components/pages/paymentSummery/Summery";
import BankDeposit from "./a_components/pages/deposit/BankDeposit";
import MembershipSummery from "./a_components/pages/paymentSummery/MembershipSummery";
import PaymentMembership from "./a_components/pages/payment/PaymentMembership";
import SellerPageSetting from "./components/SellerPageSetting/SellerPageSetting";

const theme = createTheme({
  typography: {
    fontFamily: ["Titillium Web", "sans-serif"].join(","),
  },
  palette: {
    primary: { main: "#0080ff" },
  },
});

const App: React.FC = (props: any) => {
  const user = useSelector(selectLoggedUser);
  const navigation: string = useSelector(selectCurrentLocation);
  const behavior: string = useSelector(selectCurrentBehavior);
  const busy = useSelector(selectBusy);
  const dispatch = useDispatch();
  const [isSignedIn, setSignedIn] = useState(false);
  const version = packageJson.version;



  useEffect(() => {
    saveLocations();

    dispatch(
      checkUserState(
        () => { },
        () => { }
      )
    );
    dispatch(readUserFromLocalStorage(""));
  }, []);

  useEffect(() => {
    checkVersion();

    // if (user == null) loginCurrentUser();
  }, [user]);


  function checkVersion() {
    if (localStorage.getItem("lk.saruwata.version") !== version || localStorage.getItem("lk.saruwata.version") === null) {
      localStorage.clear();
      signOut(() => { });
      localStorage.setItem("lk.saruwata.version", version);
    }
  }



  async function saveLocations() {

    const district = [{ "slug": null, "usageCount": null, "name": "AMPARA", "id": 60 }, { "slug": null, "usageCount": null, "name": "ANURADHAPURA", "id": 61 }, { "slug": null, "usageCount": null, "name": "BADULLA", "id": 62 }, { "slug": null, "usageCount": null, "name": "BATTICALOA", "id": 63 }, { "slug": null, "usageCount": null, "name": "COLOMBO", "id": 64 }, { "slug": null, "usageCount": null, "name": "GALLE", "id": 65 }, { "slug": null, "usageCount": null, "name": "GAMPAHA", "id": 66 }, { "slug": null, "usageCount": null, "name": "HAMBANTOTA", "id": 67 }, { "slug": null, "usageCount": null, "name": "JAFFNA", "id": 68 }, { "slug": null, "usageCount": null, "name": "KALUTARA", "id": 69 }, { "slug": null, "usageCount": null, "name": "KANDY", "id": 70 }, { "slug": null, "usageCount": null, "name": "KEGALLE", "id": 71 }, { "slug": null, "usageCount": null, "name": "KILINOCHCHI", "id": 72 }, { "slug": null, "usageCount": null, "name": "KURUNEGALA", "id": 73 }, { "slug": null, "usageCount": null, "name": "MANNAR", "id": 74 }, { "slug": null, "usageCount": null, "name": "MATALE", "id": 75 }, { "slug": null, "usageCount": null, "name": "MATARA", "id": 76 }, { "slug": null, "usageCount": null, "name": "MONARAGALA", "id": 77 }, { "slug": null, "usageCount": null, "name": "MULLAITIVU", "id": 78 }, { "slug": null, "usageCount": null, "name": "NUWARA ELIYA", "id": 79 }, { "slug": null, "usageCount": null, "name": "POLONNARUWA", "id": 80 }, { "slug": null, "usageCount": null, "name": "PUTTALAM", "id": 81 }, { "slug": null, "usageCount": null, "name": "RATNAPURA", "id": 82 }, { "slug": null, "usageCount": null, "name": "TRINCOMALEE", "id": 83 }, { "slug": null, "usageCount": null, "name": "VAVUNIYA", "id": 84 }];

    dispatch(setDistricts(district));

    await axios.get(GET_LOCATION).then((result) => {
      if (result.status === 200) {

        dispatch(setCityToDistricts(result.data));

      }
    });

    // await axios.get(GET_DISTRICTS).then((result) => {
    //   if (result.status === 200) {

    //     dispatch(setDistricts(result.data));


    //   }
    // });
  }




  return (

    <InteractionContext.Provider value={{ busy: busy }}>

      <UserContext.Provider value={user}>
        <NavigationContext.Provider
          value={{ current: navigation, behavior: behavior }}
        >
          <ThemeProvider theme={theme}>
            <BrowserRouter basename="/" >

              <Routes>
                {/* <Route
                path="/start"
                render={() => <LandingPage />}
                exact={true}
              /> */}


                {isMobile ? (
                  <Route path="/start" element={<Navigate to="/home" replace />} />
                ) : (
                  <Route path="/start" element={<LandingPage />} />
                )}

                <Route
                  path="/maintenance"
                  element={<Maintenance />}

                />

                <Route path="/" element={<Navigate to="/home" />} />

                <Route path="/" element={<Layout />} >

                  <Route path="/home"  element={<Home {...props} />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route
                    path="/search"
                    element={<SearchResult {...props} />}

                  />
                  <Route
                    path="/:mainCat/:subCat/:id"
                    element={<DetailsPage />}

                  />
                  <Route path="/seller/:email" element={<SellerPage />} />
                  <Route
                    path="/likes"
                    element={<LikesPage {...props} />}

                  />
                  <Route
                    path="/myads"
                    element={<MyAds />}

                  />
                  <Route
                    path="/membership"
                    element={<Membership />}

                  />
                  <Route
                    path="/my-membership"
                    element={<MyMembership />}

                  />
                  <Route
                    path="/adPromotions"
                    element={<AdPromotions />}

                  />

                  <Route path="/add-post" element={<AddPost />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-conditions"
                    element={<TermsAndConditions />}

                  />
                  {/* <Route path="/support/faq" element={<Support />} /> */}
                  <Route path="/payment/:id" element={<PaymentOverview />} />
                  <Route path="/promote/:id" element={<PaymentOverview />} />
                  <Route path="/paymentSummery/" element={<Summery />} />
                  <Route path="/diectPayment/bankDeposit" element={<BankDeposit />} />
                  <Route path="/membershipPaymentSummery" element={<MembershipSummery />} />
                  <Route path="/paymentMembership/:id" element={<PaymentMembership />} />
                  <Route path="/sellerPageSetting" element={<SellerPageSetting />} />
                  {/* <Route path="*" Component={NotFound} /> */}
                  <Route path="*" element={<NotFound />} />


                </Route>
                <Route path="/checkConnection" element={<TimeOut />} />

              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </NavigationContext.Provider>
      </UserContext.Provider>
    </InteractionContext.Provider>


  );
};

export default App;
