import axios from 'axios';
import { TOKEN } from '../config/constants';

export default
  {
    setUpInterceptors: () => {

      axios.interceptors.request.use(config => {
        const jwt = localStorage.getItem(TOKEN);
        if (!config.headers.skipAuth && jwt) {
          config.headers.Authorization = jwt;
        }
        delete config.headers.skipAuth;
        // if (jwt) { config.headers.Authorization = jwt; }
        config.headers.imusing = 'Web';
        config.headers.imusing_version = 10;
        return config;
      }, error => {
        return Promise.reject(error);
      });

      axios.interceptors.response.use((response) => {
        if (response?.headers.authorization !== undefined) {
          localStorage.setItem(TOKEN, response.headers.authorization);
        }
        return response;
      }
        , error => {
          return Promise.reject(error);
        });
    }
  }