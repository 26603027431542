import React, { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { setCurrentLocation } from '../../../features/navigation/navigationSlice';
import { PAYMENT } from '../../../providers/NavigationProvider';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { Avatar, Button, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormLabel, Hidden, List, ListItem, ListItemText, Paper, Slide } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import JumpAd from '../../AddOn/JumpAd';
import BundlePack from '../../AddOn/BundlePack';
import BoostAd from '../../AddOn/BoostAd';
import QuickSell from '../../AddOn/QuickSell';
import FeaturedAd from '../../AddOn/FeaturedAd';
import ResetRadios from '../../components/ResetRadios';
import { selectIsMember, selectJumpAdValues, setIsMember, setReferenceMember, setUhmId, setVoucherFreeAmount } from '../../AddOn/AddOnValues';
import axios from 'axios';
import { GET_REFRESHED_PAYMENT } from '../../../config/urls';
import { selectLoggedUser } from '../../../features/session/sessionSlice';
import { useLocation } from 'react-router-dom';
import { getAddOnCodes, selectClientRef, selectImg, selectIsFeature, selectIsJump, selectIsQuick, selectIsTop, selectPostStatus, selectPrice, selectTitle, setIsFeature, setIsJump, setIsQuick, setIsTop, setPostStatus } from '../../features/addOnServices';
import { spawnSync } from 'child_process';
import { setAmount, setClientRef, setImg, setPrice, setSeotag, setTitle } from "../../../a_components/features/addOnServices";
import { setBoostAdValues, setBundleValues, setFeaturedAdValues, setJumpAdValues, setQuickSellValues } from "../../../a_components/AddOn/AddOnValues";
import LineProgress, { setProgress } from '../../../components/loader/LineProgress';
import PaymentOverviewSideBar from '../../components/PayementOverviewSideBar';



const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    title: {
      fontSize: 16,
      fontWeight: "bold"
    },
    secondary: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    header: {
      fontSize: 17,
      fontWeight: 'bold',
      paddingBottom: '12px'

    },
    ml: {
      [theme.breakpoints.up("md")]: {
        marginLeft: "40px"
      }, [theme.breakpoints.down("md")]: {
        marginLeft: "0px"
      }

    }

  }),
);



export default function PaymentOverview() {
  const img = useSelector(selectImg);
  const title = useSelector(selectTitle);
  const price = useSelector(selectPrice);
  const clientRef = useSelector(selectClientRef);
  const isJump = useSelector(selectIsJump);
  const isQuick = useSelector(selectIsQuick);
  const isTop = useSelector(selectIsTop);
  const user = useSelector(selectLoggedUser);
  const isFeautre = useSelector(selectIsFeature);
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  var isMember = useSelector(selectIsMember);
  const postStatus = useSelector(selectPostStatus);



  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentLocation(PAYMENT));

  });




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isMembershipCustomer = () => {
    if (isMember) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    handleClickOpen()
  }, [location])


  const seoTag = useLocation().pathname.split("/")[2];
  const state = useLocation().pathname.split("/")[1];


  const JumpAdValues = useSelector(selectJumpAdValues);
  const isJumpAdValueNull = () => {
    if (JumpAdValues.length > 0) {
      return false;
    }

    return true;
  }
  function x(token: string) {
    axios.get(GET_REFRESHED_PAYMENT + seoTag, {
      headers: {
        accept: "*/*",
        connection: "keep-alive",
        authorization: token
      }

    }).then((jsonData) => {
      dispatch(setSeotag(jsonData.data.seoTag));
      dispatch(setTitle(jsonData.data.title));
      dispatch(setPrice(jsonData.data.price));
      dispatch(setImg(jsonData.data.img));
      dispatch(setAmount(jsonData.data.amount));
      dispatch(setClientRef(jsonData.data.ref));
      // dispatch(setBundleValues(jsonData.data.addons.bundle));
      dispatch(setJumpAdValues(jsonData.data.addons.jump));
      dispatch(setBoostAdValues(jsonData.data.addons.boost));
      dispatch(setQuickSellValues(jsonData.data.addons.quik));
      dispatch(setFeaturedAdValues(jsonData.data.addons.feat));
      dispatch(setIsJump(jsonData.data.isJump));
      dispatch(setIsQuick(jsonData.data.isQuick));
      dispatch(setIsTop(jsonData.data.isTop));
      // console.log(JSON.stringify(jsonData.data));
      dispatch(setIsFeature(jsonData.data.isSpot));
      dispatch(setPostStatus(jsonData.data.postCurrentStatus));


      dispatch(setVoucherFreeAmount(jsonData.data.voucherFreeAmount));
      dispatch(setReferenceMember(jsonData.data.reference));
      dispatch(setUhmId(jsonData.data.uhmId));

      dispatch(setIsMember(jsonData.data.member));



      setRequest(false);
    }
    ).catch((e) => console.log(e));

  }
  function convertPrice() {
    if (String(price).includes('E')) {
      var p = String(price).split('E')[1];

      return (parseFloat(String(price).split('E')[0]) * Math.pow(10, parseInt(p))).toLocaleString("en-US");
    }
    return price;
  }

  const refresh = () => {
    if (!request) {

      if (user.token != '') {

        x(user.token);
        setRequest(true);

      }
    }



  }

  const classes = useStyles();


  return (
    <div>

      <React.Fragment>
        <CssBaseline />

        {

          (isJumpAdValueNull() ? refresh() : <>
            <Typography className={classes.header}>
              Promote your ad
            </Typography>
            <Divider></Divider>
            <div style={{ display: "flex", margin: "20px 0" }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                  <div style={{ border: "1px solid #91D5FF", backgroundColor: "#EFFAFF", borderRadius: "5px", padding: "15px", marginTop: '10px', fontSize: "14px" }}>
                    <div style={{ display: "flex" }}>
                      <Avatar
                        alt="Profile Image"
                        src='../../assets/addOn/icon-bullet-point.svg'
                        style={{ marginRight: "13px", height: "20px", width: "20px" }}
                      />
                      <div style={{ fontSize: "16px", color: "#000000", fontWeight: 'bold' }}>

                        {(postStatus == '0' ?
                          (state == 'promote' ? "Your ad is Approved!" : "Your ad is Approved!")
                          : (postStatus == '1' ? (state == 'promote' ? "Your ad is Approved!" : "Your ad is Approved!")
                            : (postStatus == '5' ?
                              "Your ad is Pending Payment!"
                              : (postStatus == '10' ? "Your ad is under review!" : (postStatus == '4' ? "Your ad is Rejected!" : "Your ad is Pending Payment!")))))}



                        {/* Your ad is under review! */}
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: '14px', marginLeft: "35px" }}>
                      {/* <Avatar
                    alt="Profile Image"
                    src='../../assets/icon/icon-info.svg'
                    style={{ marginRight: "13px", height: "20px", width: "20px" }}
                  /> */}
                      <span>


                        {(postStatus === '0' ?
                          (state == 'promote' ? "Our promotions are an exciting way to get your items sold quickly" : "Our promotions are an exciting way to get your items sold quickly")
                          : (postStatus == '1' ? (state == 'promote' ? "Our promotions are an exciting way to get your items sold quickly" : "Our promotions are an exciting way to get your items sold quickly")
                            : (postStatus == '5' ?
                              "Now you may pay for your ad by using any of our payment options listed here. Also our promotions are an exciting way to get your items sold quickly."
                              : (postStatus == '10' ? <>
                                Please note that can take up to 3 to 4 hours for your ad to be published <span style={{ fontStyle: "italic" }}> (Office hours 7 am - 9 pm)</span>.
                              </> : (postStatus == '4' ? "Your can edit Your ad and try again" : "Now you may pay for your ad by using any of our payment options listed here. Also our promotions are an exciting way to get your items sold quickly.")))))}



                        {/* Please note that can take up to 3 to 4 hours for your ad to be published <span style={{ fontStyle: "italic" }}> (Office hours 7 am - 9 pm)</span>. */}
                      </span>
                      <br />
                      <br />
                      {/* <span>
                  We will contact you for the payment requirements shortly.
                </span> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div className={classes.ml}>
                    <div style={{ display: "flex", border: "0.2px solid #DDDDDD", borderRadius: "5px", padding: "5px", marginTop: '10px', fontSize: "14px" }}>
                      <div style={{ position: "relative" }}>

                        <Avatar
                          variant="rounded"
                          style={{ height: "100px", width: "155.26px" }}
                          // image="https://global.toyota/pages/models/images/20191018/kv/vitz_ogp_01.jpg"
                          src={img[0]}
                          alt="Ad Image"
                        >
                        </Avatar>
                        <img src="../../assets/images/saruwata-water-mark_mob_480x320.png" style={{ zIndex: 100, position: "absolute", top: 0, width: "100%" }} />
                      </div>

                      <div style={{ marginLeft: "15px" }}>
                        <Typography style={{ fontWeight: "bold", fontSize: "16px" }} >
                          {title}
                        </Typography>
                        <Typography>
                          <div color="primary" className="price">
                            {/* <span className="ru-in-price">Rs. </span>{" "}{price} */}
                            <span className="ru-in-price">Rs. </span>{" "}{convertPrice()}
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>


            </div>
            <Typography className={classes.title} color="textSecondary" gutterBottom style={{ backgroundColor: "#F5F6F8", marginBottom: "0px", color: "#000000", fontSize: "16px", padding: "12px 16px", fontWeight: 600 }}>
              Sell your items quickly at the best price by making your ads stand out on saruwata.lk
            </Typography>
            <Card variant="outlined">
              <CardContent>

                {/*
           <BundlePack></BundlePack>     */}

                <ResetRadios></ResetRadios>

                <div id="addOnRoot">
                  <JumpAd available={(isJump === "1" ? true : false)}></JumpAd>

                  {/* <BoostAd available={(isTop === "1" ? true : false)}></BoostAd> */}

                  <QuickSell available={(isQuick === "1" ? true : false)}></QuickSell>

                  <FeaturedAd available={(isFeautre === "FEATURE" ? true : false)}></FeaturedAd>

                </div>
              </CardContent>
            </Card>

          </>)
        }
        <CircularProgress hidden={(isJumpAdValueNull() ? false : true)} style={{ position: "absolute", top: "45%", left: "45%", zIndex: 1500 }} />


        <Hidden mdUp={true}>
          <PaymentOverviewSideBar />
        </Hidden>
        <br></br>
      </React.Fragment >

    </div>
  )

}

