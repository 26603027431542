import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Avatar, Grid, Hidden } from '@material-ui/core';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        width: "100%",
        marginTop: "100px",

        backgroundColor: theme.palette.background.paper,

    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        flexShrink: 0,
        paddingTop: "50px"
    },
}));

export default function AdPromotionTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>


            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}

                className={classes.tabs}
            >

                <Tab label="JUMP AD" {...a11yProps(0)} />
                <Tab label="SUPER AD" {...a11yProps(1)} />
                <Tab label="FEATURED AD" {...a11yProps(2)} />
                <Tab label="QUICK SELL AD" {...a11yProps(3)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <Grid style={{ backgroundColor: "blue" }} container>
                    <Grid lg={6} item>
                        <img style={{ maxWidth: "424px" }} src='../../../assets/images/img-jumpr-ad-424x330px.png' />
                    </Grid>
                    <Grid lg={6} item style={{ paddingTop: "25px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar style={{ width: "32px", height: "32px" }} src="../../../assets/addOn/icon-jump-up.svg" />
                                <Typography style={{ fontWeight: "bold", fontSize: "20px", paddingLeft: "10px" }}>Jump Ad Promotion</Typography>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div>
                                    Applying a Jump Ad promotion in your ad will gain the opportunity to get results up to 10 times or more views as compared to regular ads!
                                </div>
                                <br />
                                <div>
                                    Jump ad appears once a day at the top of the regular ad listings and move down the page as new ads are posted. Jump Ad promotion will push your ad to the top of the page and receive up to ten times the number of views as regular ads. This process is repeated on a daily basis for the duration of the promotion.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container>
                    <Grid lg={6} item>
                        <img style={{ maxWidth: "424px" }} src='../../../assets/images/img-super-ad-424x330px.png' />
                    </Grid>
                    <Grid lg={6} item style={{ paddingTop: "25px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar style={{ width: "32px", height: "32px" }} src="../../../assets/addOn/icon_super-ad.png" />
                                <Typography style={{ fontWeight: "bold", fontSize: "20px", paddingLeft: "10px" }}>Super Ad Promotion</Typography>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div>
                                    Applying a Super Ad promotion in your ad will gain the opportunity to get results up to 5 times or more views as compared to regular ads!  </div>
                                <br />
                                <div>
                                    Super Ads are highlighted in yellow, larger than regular ads and clearly labelled as "Super Ad." Each Super Ad has an equal chance of appearing in the Super Ad slots on the top of the ad listing page. This translates to thousands of additional appearances for each Super Ad per day for the duration of the promotion.
                                    <br />
                                    Your ad will be displayed as a regular ad in addition to the Super Ad slots.  </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container>
                    <Grid lg={6} item>
                        <img style={{ maxWidth: "424px" }} src='../../../assets/images/img-featured-ad-424x330px.png' />
                    </Grid>
                    <Grid lg={6} item style={{ paddingTop: "25px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar style={{ width: "30px", height: "30px" }} src="../../../assets/addOn/icon_featured-ad.png" />
                                <Typography style={{ fontWeight: "bold", fontSize: "20px", paddingLeft: "10px" }}>Featured Ad Promotion</Typography>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div>
                                    Using a Featured Ad will reserve you a Premium space to showcase your high value products to get quick attention from buyers. Each Featured Ad has an equal chance of being shown in this super visible spot with 5 ads shown to every user automatically. Adding attractive photos to the Featured ads are visually appealing which will enhance the chances to get more buyer attention.</div>
                                <br />
                                <div>
                                    In addition to Featured Ad, your ad is also published as a regular ad.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Grid container>
                    <Grid lg={6} item>
                        <img style={{ maxWidth: "424px" }} src='../../../assets/images/img-quick-sale-424x330px.png' />
                    </Grid>
                    <Grid lg={6} item style={{ paddingTop: "25px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar variant="square" style={{ width: "30px", height: "30px" }} src="../../../assets/addOn/icon_quick-sale-lable.png" />
                                <Typography style={{ fontWeight: "bold", fontSize: "20px", paddingLeft: "10px" }}>Quick Sell Promotion</Typography>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div>
                                    Stand out from the rest of the ads to show the urgency by marking your ad on a bright red line in the regular ad listing page.     </div>
                                <br />
                                <div>
                                    Combine this promotion with a Super Ad or Jump Ad to ensure your ad remains at the top of regular listings and receives up to ten times thenumber of views. </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>

        </div>
    );
}