// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loader-wrapper{
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    z-index: 1050;
}

#loader-wrapper > ion-spinner{
    transform: scale(2);
}`, "",{"version":3,"sources":["webpack://./src/components/loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,KAAK;IACL,OAAO;IACP,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["#loader-wrapper{\n    position: absolute;\n    top:0;\n    left: 0;\n    width:100%;\n    height:100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    background-color: #fff;\n    z-index: 1050;\n}\n\n#loader-wrapper > ion-spinner{\n    transform: scale(2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
