import { Avatar, createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSelector } from "react-redux";
import { selectedCategories } from "../../features/addPost/addPostSlice";
import { postSpecs, selectNewPosts } from "../../features/post/postSlice";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { UserContext } from "../../providers/UserProvider";
import LinearProgressBar from "../loader/LinearProgress";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 752,
        },
        wrap: {
            backgroundColor: "#F9F9F9",
        },
        title: {
            margin: theme.spacing(4, 0, 2),
        },
        text: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineClamp: 4,
            boxOrient: 'vertical',
            display: '-webkit-box',
            '& .MuiTypography-body1': {
                fontSize: "12px",
                fontWeight: 600,
                color: "#000000"
            },
            '& .MuiTypography-body2': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#333333"
            }
        },
        multiline: {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiTypography-body1': {
                fontSize: "12px",
                fontWeight: 600,
                color: "#000000",
            },
            '& .MuiTypography-body2': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#333333"
            }
        },
        multilinewrapper: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiListItemText-multiline': {
                marginTop: '0px !important'
            }
        },
        header: {
            '& .MuiTypography-body1': {
                fontSize: "16px",
                fontWeight: 600,
                color: "#000000",
                marginLeft: "4px"
            },
        },
        listItemText: {
            display: 'flex',
            alignItems: 'center',
            position: "relative"
        },
        listIcon: {
            minWidth: 36
        }
    }),
);

const AddPostSidebar = () => {
    const classes = useStyles();
    const selectedCat = useSelector(selectedCategories);
    const specs = useSelector(postSpecs);
    const newPost = useSelector(selectNewPosts);
    const navigate = useNavigate();

    const createHeader = (spec: any) => {
        const parts = spec.split('_');
        return parts.length > 1 ? parts.slice(1).join(' ') : parts[0];
    }
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    const getSubVariableName = (name: string) => {
        return name.toUpperCase().replace(/ /g, "_").replace('&', 'AND');
    }
    return (
        <div>
            <div style={{ height: "39px" }}></div>
            <Grid container>
                <UserContext.Consumer>
                    {(user: any) => (
                        <>{
                            user == null ? navigate('/home') :
                                <div style={{ height: "77px", width: "100%", border: "1px solid #DDDDDD", display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        alt="Profile Image"
                                        src={

                                            user?.image == ""
                                                ? "assets/icon/img-blank-profile-avatar.jpg"
                                                : user.image
                                        }
                                        style={{ marginLeft: "20px", marginRight: "13px" }}
                                    />
                                    <div style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px'), color: "#000000" }}>
                                        {lan.HELLO} <span style={{ fontWeight: 600 }}> {user?.name}!</span><br /> {lan.WHAT_ARE_YOU_LISTING_TODAY}
                                    </div>
                                </div>
                        }
                        </>
                    )}
                </UserContext.Consumer>
                <Grid item xs={12}>
                    <div className={classes.wrap}>
                        <List style={{ backgroundColor: "#F9F9F9", padding: "0" }}>
                            <ListItem divider={true}>
                                <ListItemText
                                    primary={lan.LISTING_SUMMARY}
                                    className={classes.header}
                                    style={{ fontSize: (getLanguage() == 'english' ? '16px' : '13px') }}
                                />
                            </ListItem>
                            {
                                (selectedCat.level1) ?
                                    <ListItem key={1} divider={true} className={classes.listItemText}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <img style={{ width: 16, height: 16 }} src='assets/icon/icon-bullet-point-3.svg'></img>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={lan.MAIN_CATEGORY}
                                            // secondary={selectedCat?.level1?.charAt(0).toUpperCase() + selectedCat?.level1?.slice(1).toLowerCase()}
                                            secondary={lan[getSubVariableName(selectedCat?.level1)]}
                                            className={classes.multiline}
                                        />
                                    </ListItem>
                                    :
                                    null
                            }
                            {
                                (selectedCat.level2) ?
                                    <ListItem divider={true} className={classes.listItemText}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <img style={{ width: 16, height: 16 }} src='assets/icon/icon-bullet-point-3.svg'></img>
                                        </ListItemIcon>
                                        <ListItemText
                                            // primary="Sub Category"
                                            // secondary={selectedCat?.level2?.name?.charAt(0).toUpperCase() + selectedCat?.level2?.name?.slice(1).toLowerCase()}
                                            primary={lan.SUB_CATEGORY}
                                            secondary={lan[getSubVariableName(selectedCat?.level2?.name)]}
                                            className={classes.multiline}
                                        />
                                    </ListItem>
                                    :
                                    null
                            }

                            {
                                (newPost.images?.length > 0) ?
                                    <ListItem divider={true} className={classes.listItemText}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <img style={{ width: 16, height: 16 }} src='assets/icon/icon-photos-ad-post.svg'></img>
                                        </ListItemIcon>
                                        <ListItemText
                                            className={classes.text}
                                            // secondary={newPost?.images.length + " photos added"}
                                            secondary={(getLanguage() == 'english' ? newPost?.images.length + " " + lan.PHOTOS_ADDED : lan.PHOTOS_ADDED_1 + " " + newPost?.images.length + " " + lan.PHOTOS_ADDED_2)}
                                        />
                                        <LinearProgressBar />
                                    </ListItem>
                                    :
                                    null
                            }
                            {
                                (newPost.title?.length > 0) ?
                                    <ListItem divider={true} className={classes.multilinewrapper}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <img style={{ width: 16, height: 16 }} src='assets/icon/icon-bullet-point-3.svg'></img>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={lan.TITTLE}
                                            secondary={newPost?.title}
                                            className={classes.multiline}
                                        />
                                    </ListItem>
                                    :
                                    null
                            }
                            {
                                (specs.length > 0) ?
                                    specs
                                        .filter((cat: any) => cat.spec != "Cat_Lev_1")
                                        .filter((cat: any) => cat.spec != "Cat_Lev_2")
                                        .map((spec: any) => (
                                            <ListItem divider={true} key={spec.spec} className={classes.listItemText}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <img style={{ width: 16, height: 16 }} src='assets/icon/icon-bullet-point-3.svg'></img>
                                                </ListItemIcon>
                                                <ListItemText
                                                    // split('_').length > 0)? spec.spec.split('_')[1] : spec.spec
                                                    // primary={createHeader(spec?.spec)}
                                                    primary={lan[getSubVariableName(createHeader(spec?.spec))]}
                                                    secondary={(spec?.value == "99") ? "4+" : spec?.value}
                                                    className={classes.text}
                                                />
                                            </ListItem>
                                        ))
                                    :
                                    null
                            }

                            {
                                (newPost.description) ?
                                    <ListItem divider={true} className={classes.listItemText}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <img style={{ width: 16, height: 16 }} src='assets/icon/icon-bullet-point-3.svg'></img>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={lan.DESCRIPTION}
                                            secondary={newPost?.description}
                                            className={classes.text}
                                        />
                                    </ListItem>
                                    :
                                    null
                            }

                            {
                                (newPost.price) ?
                                    <ListItem divider={true} className={classes.listItemText}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <img style={{ width: 16, height: 16 }} src='assets/icon/icon-bullet-point-3.svg'></img>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={lan.PRICE}
                                            secondary={newPost?.price}
                                            className={classes.text}
                                        />
                                    </ListItem>
                                    :
                                    null
                            }


                        </List>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
export default AddPostSidebar;