import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SELLER_PROFILE } from "../../config/urls";
import { setBusy } from "../navigation/navigationSlice";
import { Post } from "../post/Post";

export const slice = createSlice({
  name: "seller",
  initialState: {
    profile: {} as any,
    posts:[] as Post[]
  },

  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    clearProfile: (state, action) => {
      state.profile = {};
    },
    clearAllPosts: (state, action) => {
      state.posts = [];
      
    },
    setPosts:(state,action)=>{
      const posts = state.posts
      .concat(action.payload)
      .filter((obj: any, pos: any, arr: any) => {
        return (
          arr.map((mapObj: any) => mapObj["id"]).indexOf(obj["id"]) === pos
        );
      });

    state.posts = posts;
    }
  },
});

export const { setProfile, clearProfile,setPosts,clearAllPosts} = slice.actions;

export const getSellerProfileWithPosts = (email: string, callback: any, errCallback: any,page:number) => (dispatch: any) => {
  dispatch(setBusy(true));
  axios
    .get(`${GET_SELLER_PROFILE}${email}/profile-with-posts/` + page)
    .then((result) => {
      if (result.status === 200) {
        dispatch(setProfile(result.data));
        dispatch(setPosts(result.data.posts));
        callback(result.data);
      } else if (result.status === 404) {
        errCallback('Seller not found');
      } else errCallback('Error occured')
    })
    .catch((e) => errCallback(e.response))
    .finally(() => dispatch(setBusy(false)));
};

export const selectSellerProfile = (state: any) => state.seller.profile;
export const selectPosts = (state: any) => state.seller.posts;

export default slice.reducer;
