import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlideUpTransition from "../../animations/SlideUpTransition";
import { selectLanguage } from "../../features/language/language_slice";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import {
  addNewPostDesciption,
  addNewPostMobile,
  addNewPostPrice,
  addNewPostRawImages,
  addNewPostTitle,
  addNewPostWhatsapp,
  clearNewPost,
  EditPost,
  getMyAds,
  selectedPost,
  selectNewPostRawImages,
  selectNewPosts,
  setSelectedPost,
} from "../../features/post/postSlice";
import { selectSelfProfile } from "../../features/session/sessionSlice";
import { Language } from "../../language/Language";
import { setEditOpen } from "../../pages/MyAds/MyAds";
import { BEHAVE_EDIT_POST } from "../../providers/NavigationProvider";
import "./EditPost.css";
import AddImagesS3 from "../../components/AddPost/Images/AddImagesS3";
import AddImagesS3Edit from "../AddPost/Images/AddImagesS3Edit";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      "& div": {
        marginTop: "10px",
      },
    },
    processing: {
      backgroundColor: '#ddd',
      colorPrimary: '#000'
    },
    actionbtns: {
      textTransform: 'none'
    }
  })
);



const EditPage = (props: any) => {
  const dispatch = useDispatch();
  const item = useSelector(selectedPost);

  const [fTitle, setFirstTitle] = useState("");
  const [fDescription, setFirstDescription] = useState("");
  const [fPrice, setFirstPrice] = useState(0);
  const [fImageCount, setFirstImageCount] = useState(0);
  const [fImageIndex, setFirstImageIndex] = useState(0);
  const [fMobile, setFirstMobile] = useState("");
  const [fWhatsapp, setFirstWhatsapp] = useState("");
  const [images, setImages] = useState([]);

  const [eTitle, setEditedTitle] = useState(false);
  const [eDescription, setEditedDescription] = useState(false);
  const [ePrice, setEditedPrice] = useState(false);
  const [eMobile, setEditedMobile] = useState(false);
  const [eWhatsapp, setEditedWhatsapp] = useState(false);
  const [eImageIndex, setEditedImageIndex] = useState(0);
  const uploadedImages = [] as any;

  const post = useSelector(selectNewPosts);
  const navigate = useNavigate();
  const formRef: any = useRef();
  const imgBtn: any = useRef();
  const classes = useStyles();
  const submitBtn: any = useRef();
  const profile = useSelector(selectSelfProfile);
  const [processing, setProcessing] = useState(false);
  // const images = props.images;
  const isMember = props.isMember;
  const selectedLanguage = useSelector(selectLanguage);

  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;




  useEffect(() => {
    dispatch(setCurrentBehavior(BEHAVE_EDIT_POST));
    return () => {
      dispatch(clearNewPost({}));
    };
  }, []);



  useEffect(() => {

    setFirstTitle(item.title);

    if (item.description !== 'No Description Given') {
      if (item.description !== undefined) {

        setFirstDescription(item.description);
      }
    }
    setFirstMobile(item.mobile);
    setFirstWhatsapp(item.whatsapp);
    setFirstPrice(item.price);

    setImages(item.images);


    setFirstImageIndex(item.cover_image);
    setFirstImageCount(item.images?.length)

    dispatch(addNewPostDesciption(item.description));
    dispatch(addNewPostTitle(item.title));
    dispatch(addNewPostPrice(item.price));
    dispatch(addNewPostMobile(item.mobile));

    if (item.whatsapp != undefined) {
      // console.log(item.whatsapp.slice(0, 1));
      if (item.whatsapp.slice(0, 1) == 0) {
        dispatch(addNewPostWhatsapp(item.whatsapp));
      } else if (item.whatsapp.slice(0, 2) == 94) {
        dispatch(addNewPostWhatsapp(item.whatsapp));
      } else if (item.whatsapp.slice(0, 3) == +94) {
        dispatch(addNewPostWhatsapp(item.whatsapp));
      } else if (item.whatsapp.slice(0, 2) == 71 || item.whatsapp.slice(0, 2) == 70 || item.whatsapp.slice(0, 2) == 77 || item.whatsapp.slice(0, 2) == 76 || item.whatsapp.slice(0, 2) == 74 || item.whatsapp.slice(0, 2) == 75 || item.whatsapp.slice(0, 2) == 78 || item.whatsapp.slice(0, 2) == 72) {
        dispatch(addNewPostWhatsapp(item.whatsapp));
      } else {
        dispatch(addNewPostWhatsapp(""));
      }
    }
    // if (item.whatsapp) {
    //   console.log(item.whatsapp)
    //   dispatch(addNewPostWhatsapp(0 + item.whatsapp?.split("+94")[1]));
    // } else {
    //   dispatch(addNewPostWhatsapp(""));
    // }
  }, [item]);
  const handleUpdate = (e: any) => {
    e.preventDefault();
    const formVal = formRef.current.reportValidity();
    if (formVal) {
      setProcessing(true);
      imgBtn.current?.trigger();
      // if (imageUploadStatus) {
      //   dispatch(
      //     EditPost(
      //       post,
      //       post.images,
      //       item.hash,
      //       handleUpdateSucess,
      //       handleUpdateFailed,
      //       () => {
      //         handleExit();
      //         setProcessing(false);
      //         setEditOpen();
      //         // window.location.href = ('/myads');


      //       }
      //     )
      //   );
      // }

    }
  };
  const update = () => {


    if (post.images.length === (uploadedImages.length + item.images.length)) {
      item.images?.map((item: any) => uploadedImages.push(item));
      uploadedImages.sort();

      dispatch(
        EditPost(
          post,
          uploadedImages,
          item.hash,
          handleUpdateSucess,
          handleUpdateFailed,
          () => {
            handleExit();
            setProcessing(false);
            setEditOpen();
            dispatch(clearNewPost({}))
            window.location.href = ('/myads');


          }
        )
      );

    }
  }
  const handleUpdateSucess = () => {
    dispatch(getMyAds(0, () => { }));
  };
  const handleUpdateFailed = () => { };
  const handleExit = () => {
    dispatch(setSelectedPost({}));
    props.onDismiss();
  };
  const handleUpdateClick = () => {

    submitBtn.current.click();
  };
  const updateImage = (imageUrl: string) => {

    uploadedImages.push(imageUrl);
  };




  return (
    <Dialog
      open={props.show}
      onEntered={() => { }}
      TransitionComponent={SlideUpTransition}
      onExit={handleExit}
      fullWidth={true}
    >
      {
        processing ? <LinearProgress className={classes.processing} /> : null
      }
      <DialogTitle>Edit Advertisement</DialogTitle>
      <DialogContent>
        <form className={classes.form} ref={formRef} onSubmit={handleUpdate}>
          <div>
            <TextField
              label={lan.TITTLE}
              placeholder="Enter Title"
              name="title"
              value={post.title || ''}
              type="text"
              fullWidth={true}
              required
              // inputProps={{ pattern: '[\\w\\d\- \\W]{5,72}', maxLength: 72 }}
              onInput={(e: any) => {
                if (fTitle.trim() !== e.target.value.trim()) {
                  setEditedTitle(true);

                } else {
                  setEditedTitle(false);

                }
                dispatch(addNewPostTitle(e.target.value))
              }}
            />
          </div>
          <div>
            {(post.description == 'No Description Given' ? <TextField
              // label="Description"
              label={lan.DESCRIPTION}
              placeholder="Enter Description"
              name="description"
              multiline
              fullWidth={true}
              rows={5}
              inputProps={{ maxLength: 4000 }}
              type="text"
              onChange={(e: any) => {

                if (fDescription.trim() !== e.target.value.trim()) {

                  setEditedDescription(true);
                } else {

                  setEditedDescription(false);

                }
                dispatch(addNewPostDesciption(e.target.value))
              }
              }
            /> : <TextField
              // label="Description"
              label={lan.DESCRIPTION}
              placeholder="Enter Description"
              name="description"
              multiline
              fullWidth={true}
              rows={5}
              value={post.description || ''}
              inputProps={{ maxLength: 4000 }}
              type="text"
              onChange={(e: any) => {

                if (fDescription.trim() !== e.target.value.trim()) {
                  setEditedDescription(true);

                } else {

                  setEditedDescription(false);
                }
                dispatch(addNewPostDesciption(e.target.value))
              }
              }
            />)}
            {/* <CKEditor editor={CKBEditor} data={post.description || ''}>
            </CKEditor> */}
          </div>
          <div>
            <TextField
              label={lan.PRICE}
              placeholder="Enter Price In {lan.RS}"
              disabled={(isMember == 1 ? false : true)}
              name="price"
              inputProps={{ pattern: '[\\d]{1,10}(\.?\\d{1,2})?' }}
              value={post.price || '0.00'}
              type="numeric"
              onInput={(e: any) => {
                if (fPrice != e.target.value.trim()) {
                  setEditedPrice(true);

                } else {
                  setEditedPrice(false);

                }
                dispatch(addNewPostPrice(e.target.value))
              }}
            />
          </div>
          <div>
            <TextField
              label="Mobile"
              placeholder="+947xxxxxxxx"
              name="mobile"
              required
              inputProps={{ maxLength: 13 }}

              // disabled={!profile?.skip_phones_verify}
              value={post.mobile || ''}
              type="text"
              helperText={!profile?.skip_phones_verify ? 'Only the verified phone number is applicable' : ''}
              onInput={(e: any) => {
                const re = /^[0-9+\b]+$/;
                if (!(e.target.value === '' || re.test(e.target.value))) {
                  e.target.value = e.target.value.slice(0, -1);;
                }
                if (fMobile.trim() !== e.target.value.trim()) {
                  setEditedMobile(true);

                } else {
                  setEditedMobile(false);

                }
                dispatch(addNewPostMobile(e.target.value))
              }}
            />
          </div>
          <div>
            <TextField
              label="WhatsApp"
              placeholder="+947xxxxxxxx"
              name="whatsapp"
              inputProps={{ minLength: 12, maxLength: 13 }}
              value={post.whatsapp || ""}
              type="text"
              onInput={(e: any) => {
                const re = /^[0-9+\b]+$/;
                if (!(e.target.value === '' || re.test(e.target.value))) {
                  e.target.value = e.target.value.slice(0, -1);;
                }
                if (e.target.value != null && fWhatsapp != null) {
                  if (fWhatsapp.trim() !== e.target.value.trim()) {
                    setEditedWhatsapp(true);

                  } else {
                    setEditedWhatsapp(false);

                  }
                } else {
                  setEditedWhatsapp(true);

                }

                dispatch(addNewPostWhatsapp(e.target.value))
              }
              }
            />
          </div>
          <div>

            <AddImagesS3Edit images={images} ref={imgBtn} index={item.cover_image} setEditedImageIndex={setEditedImageIndex} updateImage={updateImage} update={update} />
          </div>
          <button
            type="submit"
            style={{ visibility: "hidden" }}
            ref={submitBtn}
          ></button>
        </form>
      </DialogContent>
      <DialogActions>

        {(eTitle || eDescription || ePrice || eMobile || eWhatsapp || (post.images.length != fImageCount) || (fImageIndex != eImageIndex) ?
          <Button onClick={handleUpdateClick} color="primary" disabled={processing} className={classes.actionbtns}>
            Update Advertisement
          </Button> : null)}

        <Button
          disabled={processing}
          onClick={() => {
            props.onDismiss(null);
            setEditedTitle(false);
            setEditedPrice(false);
            setEditedDescription(false);
            setEditedMobile(false);
            setEditedWhatsapp(false);
          }}
          color="secondary"
          autoFocus
          className={classes.actionbtns}
        >
          Cancel
        </Button>
      </DialogActions>


    </Dialog>
  );
};
export default EditPage;