import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { markSold } from "../../features/powers/powerSlice";
import RemoveCircleSharpRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    soldbtn: {
      color: "#FF7675",
      border: '1px solid #FF7675',
      width: 84,
      height: 22,
      marginLeft: 10,
      textTransform: 'capitalize',
      '& .MuiButton-startIcon': {
        display: 'block'
      },
      '& .MuiButton-label': {
        alignItems: 'center'
      }
    },
    disabled: {
      backgroundColor: '#EBEEF1',
      '& .sold-text': {
        color: '#999999'
      },
      '& .MuiButton-startIcon': {
        display: 'block'
      },
      border: '1px solid #DDDDDD',
      '& .MuiButton-label': {
        alignItems: 'center'
      }
    }
  })
);

const SoldOutBtn = (props: any) => {
  const item = props.item;
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [sold, setSold] = useState(false);
  const classes = useStyles();

  const SoldIconCustom = (
    <Icon>
      {
        (sold || !props.enabled) ?
          <img alt="edit" src="assets/icon/icon-sold-out-deactive.svg" />
          :
          <img alt="edit" src="assets/icon/icon-sold-out-active.svg" />
      }
    </Icon>
  );

  const handleClick = () => {
    dispatch(
      markSold(
        item.hash,
        (next: number) => {
          // props.sold();
          // setSold(true);
          window.location.href = ("/myads");
          //console.log("number is "+Number);
          toast.success("Marked as sold out", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });

        },
        (error: any) => {
          toast.success("Can't sold out at this time, try later", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      )
    );
    confirmDismiss();
  };

  const confirmDismiss = () => {
    setConfirm(false);
  };

  return (
    <>
      <Button
        // variant="outlined"
        color="secondary"
        size="small"
        onClick={() => setConfirm(true)}
        startIcon={SoldIconCustom}
        classes={{ root: classes.soldbtn, disabled: classes.disabled }}
        disabled={sold || !props.enabled}
      >
        <span className="sold-text"> Sold</span>
      </Button>

      <Dialog open={confirm} onClose={confirmDismiss}>
        <DialogTitle id="alert-dialog-title">Sold-out</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDismiss} color="secondary" autoFocus>
            No
          </Button>
          <Button onClick={handleClick} color="primary">
            Sold-out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SoldOutBtn;
