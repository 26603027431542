import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLocation } from "../features/navigation/navigationSlice";
import { selectPosition,setPosition } from "../features/scroll/scrollSlice";
import { NAV_HOME, NAV_LIKES, NAV_MYADS, NAV_SEARCH_RESULT, NAV_SELLER_PROFILE } from "../providers/NavigationProvider";


const ScrollRestore=(props: any) =>  {
    const scrollPosition = useSelector(selectPosition);
    const location=useSelector(selectCurrentLocation);
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const extra=(isTabletOrMobile?0:615);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (
            location === NAV_HOME ||
            location === NAV_SEARCH_RESULT ||
            location === NAV_MYADS ||
            location === NAV_LIKES ||
            location === NAV_SELLER_PROFILE
          ) {  
              props.scroll?.current?.scrollBy({ top: scrollPosition , behavior: 'smooth', });
          }
    }, [location]);

    return null;
}

export default ScrollRestore;