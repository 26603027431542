import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import "./TitleBar.css";
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SwipeableDrawer,
  TextField,
  Typography
} from "@material-ui/core";
import ListItemButton from '@material-ui/core/ListItem'
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../providers/UserProvider";
import { logEvent } from "firebase/analytics"
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import FilterListIcon from '@material-ui/icons/FilterList';
import LocationIcon from '@material-ui/icons/LocationOnSharp';
import Tag from '@material-ui/icons/LocalOfferSharp';
import { selectLocation } from "../../features/addPost/addPostSlice";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { selectDistricts } from "../../config/locations";
import { getAllLocation } from "../../config/AllCitiesWithDistricts";
import BulletIcon from "@material-ui/icons/FiberManualRecordSharp"
import FiltersModal from "../Filters/FiltersModal";
import FiltersModalMobile from "../Filters/FiltersModalMobile";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { setIsSideBarOpen } from "../../features/navigation/navigationSlice";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      "& .MuiFilledInput-root": {
        background: "#F5F6F8",
      },
    },
    select: {
      "&.MuiInputBase-root": {
        backgroundColor: "#EBEEF1",
      },
    },
    catLink: {
      color: "#000000",
      "&:hover": {
        color: "#0080FF",
      },
    },
    menuitem: {
      textTransform: "none",
      fontFamily: "Titillium Web",
      color: "#000000",
      fontWeight: 600,
    },
    menuitemActive: {
      textTransform: "none",
      fontFamily: "Titillium Web",
      color: "#0080FF",
      fontWeight: 600,
    },
    active: {
      borderBottom: "3px solid #0080FF",
      position: "absolute",
      bottom: "-2px",
      transition: "all 0.5s ease",
      zIndex: 999,
    },
    menuname: {
      textTransform: "none",
      fontFamily: "Titillium Web",
      color: "#000000",
      fontWeight: 600,
    },
    menunameActive: {
      textTransform: "none",
      fontFamily: "Titillium Web",
      color: "#0080FF",
      fontWeight: 600,
    },
  })


);


export default function TitleBarMobileSecondary() {

  const classes = useStyles();
  const navigate = useNavigate();
  const [selection, setSelection] = useState("");
  const districts = useSelector(selectDistricts);
  const locations = getAllLocation();
  const [selectedLocation, setselectedLocation] = useState("");
  const scrollContent = document.getElementById("scrollable-root");
  const dispatch = useDispatch();


  const [state, setState] = React.useState({
    right: false
  });
  const [state2, setState2] = React.useState({
    right: false
  });
  const [state3, setState3] = React.useState({
    right: false
  });
  const toggleDrawer =
    (anchor: string, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };
  const toggleDrawer2 =
    (anchor: string, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {

          return;
        }

        setState2({ ...state2, [anchor]: open });

      };

  const toggleDrawer3 =
    (anchor: string, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState3({ ...state3, [anchor]: open });
      };


  const onLocationSelect = (location: any) => {

    setselectedLocation(location);
  };

  const [selectedIndex, setSelectedIndex] = React.useState("")

  const handleClick = (index: any) => {
    if (selectedIndex === index) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(index)
    }
  }
  const handleScroll = () => {
    scrollContent?.scrollTo(0, 0);
  };

  const checkNav = (cat: any) => {
    const category = cat.toLowerCase();
    handleScroll();
    dispatch(setIsSideBarOpen(false));
    navigate(`/search?c1=${encodeURIComponent(category)}`);
  };

  const closeDrawer3 = () => {
    dispatch(toggleDrawer3('right', false));

  }
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  return (
    <AppBar style={{ backgroundColor: "white" }} position="static">

      <Toolbar>
        <Grid container>
          <Grid item xs={4} style={{ display: "flex", borderRight: "1px solid #DDDDDD" }}>
            <ListItemIcon onClick={() => {
              dispatch(setIsSideBarOpen(true));
              dispatch(toggleDrawer3('right', true));
            }} style={{ margin: "auto", cursor: "pointer" }}>
              <LocationIcon color='primary' fontSize="medium" />
              <span style={{ paddingLeft: "7px", fontSize: (getLanguage() == 'english' ? '15px' : '12px') }}> {lan.LOCATION}</span>

            </ListItemIcon>
            {/* <Divider variant={"middle"} orientation={"vertical"} /> */}
          </Grid>
          <Grid item xs={4} style={{ display: "flex", borderRight: "1px solid #DDDDDD" }}>
            <ListItemIcon onClick={() => {
              dispatch(setIsSideBarOpen(true));
              dispatch(toggleDrawer2('right', true));
            }} style={{ margin: "auto" }}>
              <Tag color='primary' fontSize="medium" />
              <span style={{ paddingLeft: "10px", fontSize: (getLanguage() == 'english' ? '15px' : '12px') }}> {lan.CATEGORY}</span>
            </ListItemIcon>
            {/* <Divider variant={"middle"} orientation={"vertical"} /> */}
          </Grid>
          <Grid item xs={4} style={{ display: "flex" }}>
            <ListItemIcon onClick={() => {
              dispatch(setIsSideBarOpen(true));
              dispatch(toggleDrawer3('right', true));
            }} style={{ margin: "auto" }}>
              <FilterListIcon color='primary' fontSize="medium" />
              <span style={{ paddingLeft: "10px", fontSize: (getLanguage() == 'english' ? '15px' : '12px') }}> {lan.FILTERS}</span>
            </ListItemIcon>
            {/* <Divider variant={"middle"} hidden orientation={"vertical"} /> */}
          </Grid>
        </Grid>
      </Toolbar>

      <div>
        {/* location drawer */}
        <Drawer
          SlideProps={{ style: { width: "85%" } }}
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        // onOpen={toggleDrawer('right', true)}
        >

          <div>

            <Grid container>
              <Grid item xs={12}>

                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader disableSticky={true} component="div" >
                      {lan.CHOOSE_LOCATION}
                    </ListSubheader>
                  }
                >

                  {districts.map((item: any) => (

                    <>
                      <ListItemButton key={item.id} style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => handleClick(item.id)}>

                        <ListItemText primary={item.name} />
                        {item.id === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={item.id === selectedIndex} timeout="auto" unmountOnExit style={{ paddingLeft: "20px" }}>
                        <List component="div" disablePadding>

                          {

                            locations[item.name].map((i: string) => (
                              <ListItemButton style={{ paddingTop: 3, paddingBottom: 3 }} >
                                {/* <ListItemIcon>
                          <BulletIcon />
                        </ListItemIcon> */}
                                <ListItemText primary={i.split("-")[0]} />
                              </ListItemButton>

                            ))

                          }

                        </List>
                      </Collapse>
                    </>
                  ))}

                </List>
              </Grid>
            </Grid>
          </div>


        </Drawer>



        {/* catergory drawer */}

        <Drawer
          SlideProps={{ style: { width: "85%" } }}
          anchor={'right'}
          open={state2['right']}
          onClose={() => {
            dispatch(toggleDrawer2('right', false));
            dispatch(setIsSideBarOpen(false));
          }}

        >

          <div>

            <Grid container>
              <Grid item xs={12}>

                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                // subheader={
                //   <ListSubheader disableSticky={true} component="div" >
                //     Choose Category
                //   </ListSubheader>
                // }
                >

                  <Grid container style={{ marginTop: "60px" }}>
                    <Grid item xs={12}>
                      <Typography style={{ textAlign: "center", fontSize: "22px", fontWeight: "bold", paddingBottom: "20px" }}>{lan.SELECT_CATEGORY}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Paper onClick={() => { dispatch(toggleDrawer2('right', false)); checkNav("Motors") }} variant="outlined" style={{ width: "75%", height: "120px", marginLeft: "auto", marginRight: "5px", padding: "20px 10px", marginTop: "5px" }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                              <img style={{ maxWidth: "60px", objectFit: "contain" }} src="../../assets/icon/icon-motors.svg" />
                            </div>
                            <Typography style={{ textAlign: "center", padding: "5px 0" }}>{lan.MOTORS}</Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper onClick={() => { checkNav("Mobiles and Electronics"); dispatch(toggleDrawer2('right', false)) }} variant="outlined" style={{ width: "75%", height: "120px", marginLeft: "5px", marginRight: "auto", padding: "20px 10px", marginTop: "5px" }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                              <img style={{ maxWidth: "60px", objectFit: "contain" }} src="../../assets/icon/icon-electronics.svg" />
                            </div>
                            <Typography style={{ textAlign: "center", padding: "5px 0" }}>{lan.MOBILE_AND_ELECTRONICS}</Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Paper onClick={() => { checkNav("Property for Sale"); dispatch(toggleDrawer2('right', false)); }} variant="outlined" style={{ width: "75%", height: "120px", marginLeft: "auto", marginRight: "5px", padding: "20px 10px", marginTop: "5px" }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                              <img style={{ maxWidth: "60px", objectFit: "contain" }} src="../../assets/icon/icon-property-for-sale.svg" />
                            </div>
                            <Typography style={{ textAlign: "center", padding: "5px 0" }}>{lan.PROPERTY_FOR_SALE}</Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper onClick={() => { checkNav("Property for Rent"); dispatch(toggleDrawer2('right', false)); }} variant="outlined" style={{ width: "75%", height: "120px", marginLeft: "5px", marginRight: "auto", padding: "20px 10px", marginTop: "5px" }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                              <img style={{ maxWidth: "60px", objectFit: "contain" }} src="../../assets/icon/icon-proerty-for-rent.svg" />
                            </div>
                            <Typography style={{ textAlign: "center", padding: "5px 0" }}>{lan.PROPERTY_FOR_RENT}</Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Paper onClick={() => { checkNav("Classifieds"); dispatch(toggleDrawer2('right', false)); }} variant="outlined" style={{ width: "75%", height: "120px", marginLeft: "auto", marginRight: "auto", padding: "20px 10px", marginTop: "10px" }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                              <img style={{ maxWidth: "60px", objectFit: "contain" }} src="../../assets/icon/icon-classifies.svg" />
                            </div>
                            <Typography style={{ textAlign: "center", padding: "5px 0" }}>{lan.CLASSIFIEDS}</Typography>
                          </Paper>
                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>


                </List>
              </Grid>
            </Grid>
          </div>


        </Drawer>






        {/* filter drawer */}

        <Drawer
          SlideProps={{ style: { width: "85%", backgroundColor: "#F5F6F8" } }}
          anchor={'right'}
          open={state3['right']}
          onClose={() => {
            dispatch(
              toggleDrawer3('right', false));
            dispatch(setIsSideBarOpen(false));
          }}

        >

          <div>

            <Grid container >
              <Grid item xs={12}>

                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                // subheader={
                //   <ListSubheader disableSticky={true} component="div" >
                //     Choose Category
                //   </ListSubheader>
                // }
                >

                  <Grid container style={{ marginTop: "10px", padding: "10px 15px" }}>
                    <Grid item xs={12}>
                      <FiltersModalMobile toggle={closeDrawer3} />

                    </Grid>

                  </Grid>


                </List>
              </Grid>
            </Grid>
          </div>


        </Drawer>

      </div >
    </AppBar >
  );
}
