import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice({
    name: "filters",
    initialState: {
        filters: {
            tittle: null,
            minPrice: 0,
            maxPrice: null,
            category: null,
            subCategory: null,
            dateOrderBy: null,
            priceOrderBy: null,
            location: null,
            brand: null,
            modal: null,
            motorMileageMax: null,
            motorMileageMin: 0,
            motorCondition: null,
            motorTransmission: null,
            engineCapacity: null,
            year: null,
            electronicCondition: null,
            bedRooms: null,
            bathRooms: null,
            floorAreaMin: 0,
            floorAreaMax: null,
            furnished: null,
            parkingSpace: null,
            minLandSize: 0,
            maxLandSize: null,
            mobileCondition:null
        }
    },

    reducers: {
        addTittle: (state, action) => {
            const tittle = action.payload;
            state.filters = { ...state.filters, tittle: tittle };
        },
        addMinPrice: (state, action) => {
            const minPrice = action.payload;
            state.filters = { ...state.filters, minPrice: minPrice };
        },
        addMaxPrice: (state, action) => {
            const maxPrice = action.payload;
            state.filters = { ...state.filters, maxPrice: maxPrice };
        },
        addCategory: (state, action) => {
            const category = action.payload;
            state.filters = { ...state.filters, category: category };
        },
        addSubCategory: (state, action) => {
            const subCategory = action.payload;
            state.filters = { ...state.filters, subCategory: subCategory };
        },
        addDateOrderBy: (state, action) => {
            const dateOrderBy = action.payload;
            state.filters = { ...state.filters, dateOrderBy: dateOrderBy };
        },
        addPriceOrderBy: (state, action) => {
            const priceOrderBy = action.payload;
            state.filters = { ...state.filters, priceOrderBy: priceOrderBy };
        },
        addLocation: (state, action) => {
            const location = action.payload;
            state.filters = { ...state.filters, location: location };
        },
        addBrand: (state, action) => {
            const brand = action.payload;
            state.filters = { ...state.filters, brand: brand };
        },
        addModal: (state, action) => {
            const modal = action.payload;
            state.filters = { ...state.filters, modal: modal };
        },
        addMotorMileageMax: (state, action) => {
            const motorMileageMax = action.payload;
            state.filters = { ...state.filters, motorMileageMax: motorMileageMax };
        },
        addMotorMileageMin: (state, action) => {
            const motorMileageMin = action.payload;
            state.filters = { ...state.filters, motorMileageMin: motorMileageMin };
        },
        addMotorCondition: (state, action) => {
            const motorCondition = action.payload;
            state.filters = { ...state.filters, motorCondition: motorCondition };
        },
        addMotorTransmission: (state, action) => {
            const motorTransmission = action.payload;
            state.filters = { ...state.filters, motorTransmission: motorTransmission };
        },
        addengineCapacity: (state, action) => {
            const engineCapacity = action.payload;
            state.filters = { ...state.filters, engineCapacity: engineCapacity };
        },
        addYear: (state, action) => {
            const year = action.payload;
            state.filters = { ...state.filters, year: year };
        },
        addElectronicCondition: (state, action) => {
            const electronicCondition = action.payload;
            state.filters = { ...state.filters, electronicCondition: electronicCondition };
        },
        addBedRooms: (state, action) => {
            const bedRooms = action.payload;
            state.filters = { ...state.filters, bedRooms: bedRooms };
        },
        addBathRooms: (state, action) => {
            const bathRooms = action.payload;
            state.filters = { ...state.filters, bathRooms: bathRooms };
        },
        addFloorAreaMin: (state, action) => {
            const floorAreaMin = action.payload;
            state.filters = { ...state.filters, floorAreaMin: floorAreaMin };
        },
        addFloorAreaMax: (state, action) => {
            const floorAreaMax = action.payload;
            state.filters = { ...state.filters, floorAreaMax: floorAreaMax };
        },
        addFurnished: (state, action) => {
            const furnished = action.payload;
            state.filters = { ...state.filters, furnished: furnished };
        },
        addParkingSpace: (state, action) => {
            const parkingSpace = action.payload;
            state.filters = { ...state.filters, parkingSpace: parkingSpace };
        },
        addMinLandSize: (state, action) => {
            const minLandSize = action.payload;
            state.filters = { ...state.filters, minLandSize: minLandSize };
        },
        addMaxLandSize: (state, action) => {
            const maxLandSize = action.payload;
            state.filters = { ...state.filters, maxLandSize: maxLandSize };
        },
        addMobileCondition: (state, action) => {
            const mobileCondition = action.payload;
            state.filters = { ...state.filters, mobileCondition: mobileCondition };
        }
    }
});

export const {
    addTittle,
    addMinPrice,
    addMaxPrice,
    addCategory,
    addSubCategory,
    addDateOrderBy,
    addPriceOrderBy,
    addLocation,
    addBrand,
    addModal,
    addMotorMileageMax,
    addMotorMileageMin,
    addMotorCondition,
    addMotorTransmission,
    addengineCapacity,
    addYear,
    addElectronicCondition,
    addBedRooms,
    addBathRooms,
    addFloorAreaMin,
    addFloorAreaMax,
    addFurnished,
    addParkingSpace,
    addMinLandSize,
    addMaxLandSize,
    addMobileCondition
} = slice.actions;



export const selectFiltersss = (state: any) => state.filters.filters;

export default slice.reducer;
