import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  BRANDS_GET_BY_CATEGORY,
  FILTER_POSTS,
  GET_SPOTLIGHTS_CATEGORY,
  MODELS_GET_BY_BRAND,
  POPULAR_TAGS,
  SEARCH_RESULT_POPULAR_TAGS,
} from "../../config/urls";
import { Post } from "../post/Post";
import { setBusy } from "../navigation/navigationSlice";
import { selectFiltersss } from "./FilterSlice";


export const slice = createSlice({
  name: "search",
  initialState: {
    suggestions: [],
    selectedTags: [],
    popularTags: [] as any,
    posts: [] as Post[],
    relTags: [],
    serpPage: 0,
    inSearch: false,
    selectedCategories: {
      level1: "",
      level2: "",
    },
    brands: [],
    models: [],
    sort: "chronological",
    filters: "",
    spotlights: []
  },

  reducers: {

    setSerpPage: (state, action) => {
      state.serpPage = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories.level1 = action.payload;
    },
    setSelectedSubCategory: (state, action) => {
      state.selectedCategories.level2 = action.payload;
    },
    savePopularTags: (state, action) => {
      state.popularTags = action.payload;
    },
    saveRelevantTags: (state, action) => {
      state.relTags = action.payload.sort(
        (x: any, y: any) => y.usage_count - x.usage_count
      );
    },
    saveSortOption: (state, action) => {
      state.sort = action.payload;
    },
    saveResultPosts: (state, action) => {
      state.posts = action.payload;
    },
    addResult: (state, action) => {
      const posts = state.posts
        .concat(action.payload)
        .filter((obj: any, pos: any, arr: any) => {
          return (
            arr.map((mapObj: any) => mapObj["id"]).indexOf(obj["id"]) === pos
          );
        });

      // posts.sort(function (a: any, b: any) {
      //   if (state.sort === "chronological") return b.live_time - a.live_time;
      //   else if (state.sort === "price_low_to_high") return a.price - b.price;
      //   else if (state.sort === "price_high_to_low") return b.price - a.price;
      // });
      state.posts = posts;
    },
    clearSearchResult: (state, action) => {
      state.posts = [];
    },
    setInSearch: (state, action) => {
      state.inSearch = action.payload;
    },
    saveBrands: (state, action) => {
      state.brands = action.payload;
    },
    saveModels: (state, action) => {
      state.models = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSpotlight: (state, action) => {
      const spotlights = action.payload;
      state.spotlights = spotlights;
    },
    saveSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    saveSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
  },
});

export const {
  savePopularTags,
  saveResultPosts,
  clearSearchResult,
  addResult,
  setInSearch,
  saveRelevantTags,
  setSelectedCategories,
  setSelectedSubCategory,
  saveBrands,
  saveModels,
  saveSortOption,
  setFilters,
  setSerpPage,
  setSpotlight,
  saveSelectedTags,
  saveSuggestions
} = slice.actions;

export const getPopularTags = () => (dispatch: any) => {
  axios
    .get(POPULAR_TAGS)
    .then((response: any) => {
      dispatch(savePopularTags(response.data.tags));
    })
    .catch((err) => { });
};

export const getBrandsByCategory = (category: any) => (dispatch: any) => {
  axios
    .get(BRANDS_GET_BY_CATEGORY + category)
    .then((result) => {
      if (result.status === 200) {
        dispatch(saveBrands(result.data));
      }
    });
};

export const getModelsByBrand = (brandId: any, category: any) => (
  dispatch: any
) => {
  axios
    .get(MODELS_GET_BY_BRAND + category + "/brands/" + brandId)
    .then((result) => {
      if (result.status === 200) {
        dispatch(saveModels(result.data));
      }
    });
};

export const searchResultByTags = (
  term: string,
  tags: string,
  page: number,
  filters: string,
  sort: string,
  noresultCallback: any,
  resultsSuccessCallback: any = () => { },
  ff: any

) => (dispatch: any) => {
  if (page <= 0) dispatch(clearSearchResult({}));
  if (page <= 0) dispatch(setBusy(true));

  dispatch(setSpotlight([]));
  var cat = tags.split(",")[0];
  axios.get(GET_SPOTLIGHTS_CATEGORY + cat).then(
    (d) => {

      dispatch(setSpotlight(d.data));
    }

  ).catch(e => console.log(e));


  //



  // const filterResult = "?tittle=" + ff.tittle + "&minPrice=" + ff.minPrice + "&maxPrice=" + ff.maxPrice + "&category=" + ff.category + "&subCategory=" + ff.subCategory + "&dateOrderBy=" + ff.dateOrderBy + "&priceOrderBy=" + ff.priceOrderBy + "&location=" + ff.location + "&brand=" + ff.brand + "&modal=" + ff.modal + "&motorMileageMax=" + ff.motorMileageMax + "&motorMileageMin=" + ff.motorMileageMin + "&motorCondition=" + ff.motorCondition + "&motorTransmission=" + ff.motorTransmission + "&engineCapacity="+ff.engineCapacity+"&year="+ff.year+"&electronicCondition="+ff.electronicCondition+"&bedRooms="+ff.bedRooms+"&bathRooms="+ff.bathRooms+"&floorAreaMin="+ff.floorAreaMin+"&floorAreaMax="+ff.floorAreaMax+"&furnished="+ff.furnished+"&parkingSpace="+ff.parkingSpace+"&minLandSize="+ff.minLandSize+"&maxLandSize="+ff.maxLandSize+"&mobileCondition="+ff.mobileCondition;
  // axios
  //   .get(FILTER_POSTS+filterResult )
  //   .then((resp: any) => console.log(resp))

  axios
    .get(SEARCH_RESULT_POPULAR_TAGS, {
      params: {
        tags: tags,
        page: page,
        filters: filters,
        sort: sort,
        text: term,
      },
    })
    .then((response) => {
      if (page > 0 && response.data?.posts.length > 0) {
        resultsSuccessCallback();
        dispatch(addResult(response.data.posts));
      } else if (response.data?.posts.length == 0) noresultCallback();
      else if (page == 0) {
        resultsSuccessCallback();
        dispatch(saveResultPosts(response.data.posts));
        dispatch(saveRelevantTags(response.data.tags));
      }
    })
    .catch((err) => noresultCallback())
    .finally(() => {
      dispatch(setBusy(false));
    });
};

export const selectSuggestions = (state: any) => state.search.suggestions;
export const selectPopularTags = (state: any) => state.search.popularTags;
export const selectSearcedPosts = (state: any) => state.search.posts;
export const selectSelectedTags = (state: any) => state.search.selectedTags;
export const selectRelevantTags = (state: any) => state.search.relTags;
export const selectInSearch = (state: any) => state.search.inSearch;
export const selectedCategories = (state: any) =>
  state.search.selectedCategories;
export const selectBrands = (state: any) => state.search.brands;
export const selectModels = (state: any) => state.search.models;
export const selectSortOption = (state: any) => state.search.sort;
export const selectFilters = (state: any) => state.search.filters;
export const selectSerpPage = (state: any) => state.search.serpPage;
export const selectSpotlightsSearch = (state: any) => state.search.spotlights;

export default slice.reducer;
