import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "sms",
    initialState: {
        verification: ""
    },
    reducers: {
        setVerificationCode: (state, action) => {
            state.verification = action.payload;

        }
    }
});

export const {
    setVerificationCode,

} = slice.actions;


export const selectVerification = (state: any) => state.sms.verification;


export default slice.reducer;

