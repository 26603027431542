import { createContext } from "react";

export const NAV_LOGIN = '/login';
export const NAV_HOME = '/home';
export const NAV_ACCOUNT = '/profile';
export const NAV_SEARCH = '/search/tags-cloud';
export const NAV_SEARCH_RESULT = '/search-result';
export const NAV_LIKES = '/likes';
export const NAV_ADD_POST = '/create-post';
export const NAV_FULL_DETAIL = '/full-detail/';
export const NAV_MYADS = "/myads";
export const NAV_MEMBERSHIPS = "/membership";
export const NAV_FULL_DETAIL_FROM_EXTERNAL = 'nav_full_detail_from_external';
export const NAV_LANDING = '/start';
export const NAV_BANK_DEPOSIT = '/paymentBank';
export const PAYMENT = '/payment';
export const PAYMENT_SUMMERY = '/paymentSummery/';
export const PAYMENT_SUMMERY_MEMBERSHIP = '/paymentSummeryMembership/';
export const SELLER_PAGE_SETTINGS = '/sellerPageSetting/';
// export const NAV_ADD_POST_DETAILS = 'nav_add_post_details';
export const NAV_SELLER_PROFILE = "/seller-profile";
export const NAV_MY_MEMBERSHIP = "/my-membership";

export const NAV_PRIVACY_POLICY = '/privacy-policy';
export const NAV_TERMS_CONDITIONS = '/terms-conditions';
export const NAV_SIDE_BAR = '/sideBar';
export const NOT_FOUND = '/';



export const BEHAVE_FLOAT_DETAIL = 'behave_float_detail';
export const BEHAVE_SCROLL_DOWN = 'behave_scroll_down';
export const BEHAVE_SCROLL_UP = 'behave_scroll_up';
export const BEHAVE_SCROLLING = 'behave_scrolling';
export const BEHAVE_SCROLL_TOP = 'behave_scroll_top';
export const BEHAVE_LOGIN_REQUIRED = 'behave_login_required';
export const BEHAVE_SIGNUP_REQUIRED = 'behave_signup_required';
export const BEHAVE_EDIT_POST = 'behave_edit-post';

export const BEHAVE_SEARCH_SCROLL_UP = 'behave_search_scroll_up';
export const BEHAVE_SEARCH_SCROLL_DOWN = 'behave_search_scroll_down';
export const BEHAVE_SEARCH_SCROLLING = 'behave_search_scrolling';

export const NavigationContext = createContext({ current: NAV_LOGIN, behavior: "" });
// export const MyAdsContext = createContext({ current: NAV_MYADS, behavior: "" });
// export const NavigationContext = createContext({ current: NAV_ACCOUNT, behavior: "" });