// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#all-rows{
    display: flex;
}
.brand-name-sinhala{
    font-size: 16px;
    color: #5A5A5A;
}
.brand-name-english{
    font-size: 16px;
    color: #5A5A5A;
    font-weight: 600;
    margin-left: 60px;
}
.icons-fds{
    width: 16px;
    height: 16px;
    margin-right: 18px;
    margin-left: 10px;
}
.brand-names{ 
    padding-right: 10px;
    text-align: right;
}
.col-left{
    padding-right: 20px;
}
.row-devider{
    border-bottom: 1px solid #DDDDDD;
    width: 90%;
    margin-left: 15px;
    margin-right: 25px;
    padding-bottom: 10px;
}
.row-header{
    padding-top : 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DetailsPage/PostSpecifications/Houses/FullDetailHouses.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gCAAgC;IAChC,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB","sourcesContent":["#all-rows{\n    display: flex;\n}\n.brand-name-sinhala{\n    font-size: 16px;\n    color: #5A5A5A;\n}\n.brand-name-english{\n    font-size: 16px;\n    color: #5A5A5A;\n    font-weight: 600;\n    margin-left: 60px;\n}\n.icons-fds{\n    width: 16px;\n    height: 16px;\n    margin-right: 18px;\n    margin-left: 10px;\n}\n.brand-names{ \n    padding-right: 10px;\n    text-align: right;\n}\n.col-left{\n    padding-right: 20px;\n}\n.row-devider{\n    border-bottom: 1px solid #DDDDDD;\n    width: 90%;\n    margin-left: 15px;\n    margin-right: 25px;\n    padding-bottom: 10px;\n}\n.row-header{\n    padding-top : 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
