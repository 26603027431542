import * as React from 'react';
import { Avatar, Box, Grid, makeStyles, Tab, Tabs, Theme, Typography, Divider, Hidden, Button, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectRefNo } from '../../a_components/features/membershipPayment';
import { useLocation, useNavigate } from 'react-router-dom';
function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme: Theme) => ({
    mainTabs: {
        // background: 'green',
        // '&.Mui-selected': {
        //     background: 'red'
        // }
        width: "60%"
    },
    mainTab: {
        border: "1px solid #DDDDDD",
        borderRadius: "6px",
        boxShadow: "0px 3px 6px #00000029",

    },
    imgBankImages: {
        maxWidth: "170px",
        maxHeight: "40px",
        margin: "10px 10px"
    },
    divRefNumber: {
        border: "1px solid #8FC7FF",
        backgroundColor: "#ECF5FF",
        borderRadius: "6px",
    },
    divAccountDetails: {
        border: "1px solid #DDDDDD",
        backgroundColor: "#ECF5FF",
        borderRadius: "8px",
    },
    stepsMainDiv: {
        backgroundColor: "#FFFFFF",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "5px",
        marginRight: "5px",
        marginBottom: "5px",
        borderRadius: "6px"
    },
    stepsImg: {
        height: "24px",
        width: "20px",
        marginRight: "10px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    stepsTitle: {
        color: "#000000",
        margin: "5px 25px",
        fontWeight: 600

    },
    stepsDetails: {
        color: "#000000",
        fontSize: "14px",
        fontWeight: 600
    },
    stepsDetailsPrice: {
        color: "#0080FF",
        fontSize: "14px",
        fontWeight: 600
    },
    contactMainDiv: {
        backgroundColor: "#FFFFFF",
        padding: "10px 25px",
        borderRadius: "8px",
        border: "1px solid #87C3FF",
        fontSize: "15px",
        color: "#5A5A5A",
    },
    contactImg: {
        height: "32px",
        width: "160px",
        display: "block",
        margin: "0 auto"
    },
    bankTransferDiv: {
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #DDDDDD",
        borderRadius: "6px",
        padding: "0px 20px"
    },
    bankTransferMainDiv: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 25px",
        border: "1px solid #DDDDDD",
        borderRadius: "8px",
    },
    bankTransferImg: {
        maxWidth: "96px",
        maxHeight: "38px",
        margin: "10px 20px"
    },
    backButton: {
        color: "#0080FF",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #0080FF",
        borderRadius: "6px",
        width: "100%"
    },
    publishButton: {
        color: "#FFFFFF",
        backgroundColor: "#0080FF",
        border: "1px solid #0080FF",
        borderRadius: "6px",
        width: "100%",
        '&:hover': {
            color: "#FFFFFF",
            backgroundColor: "#0080FF",
            border: "1px solid #0080FF",
        },
    },
    disableImages: {
        '&.Mui-selected': {
            filter: "grayscale(100%)",
        }
    },

    dialogFooterDiv: {
        backgroundColor: "#EFEFEF",
        fontSize: "12px",
        color: "#5A5A5A",
        padding: "10px 20px",
        justifyContent: "center",
        textAlign: "center"
    },
    dialogRightImage: {
        maxHeight: "56px",
        maxWidth: "56px",
        display: "block",
        margin: "0 auto"
    },
    dialogTittle: {
        color: "#000000",
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center"
    },
    dialogTittleSub: {
        color: "#5A5A5A",
        fontSize: "14px",
        textAlign: "center"
    },
    dialogContent: {
        color: "#5A5A5A",
        fontSize: "15px",
        textAlign: "center"
    },
    dialog: {
        border: "1px solid #707070",
        borderRadius: "6px",
        boxShadow: "1px 3px 8px #000000CC",
        padding: "20px 30px"
    }


}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BankdepositTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const MemberRef = useSelector(selectRefNo);
    const navigate = useNavigate();
    const m = useLocation().search.split("?price=")[1];
    const price = m.split("&")[0];
    const m1 = m.split("&")[1];
    const ref = m1.split("ref=")[1];

    React.useEffect(() => {

    }, []);


    const backToPayment = () => {
        navigate(-1)

    };
    const isMemberPayment = () => {
        if (MemberRef !== "") {
            return true;
        }
        return false;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }} className={classes.mainTab}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogContent style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <img src="../assets/payment/icon_payments_success-56x56px.png" className={classes.dialogRightImage} />
                    <Typography className={classes.dialogTittle}>Your ad is under review!</Typography>
                    {/* <DialogContentText className={classes.dialogTittleSub}>This process can take up to 3 to 4 hours for your ad to be published.</DialogContentText> */}
                    <Typography className={classes.dialogContent}>Please send us your bank slip with required</Typography>
                    <Typography className={classes.dialogContent} style={{ marginBottom: "10px" }}>details to our WhatsApp number.</Typography>
                    <img src="../assets/payment/btn_whatsapp_add-160x32px.png" className={classes.contactImg} />
                </DialogContent>
                <div className={classes.dialogFooterDiv}>
                    By confirming payment, you are approving our <span style={{ color: "#0080FF" }}>Terms & Conditions</span> and acknowledging our <span style={{ color: "#0080FF" }}>Privacy Policy</span>. Your payment information and details will remain confidential.
                </div>
            </Dialog>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    TabIndicatorProps={{ style: { background: '#0080FF', } }}
                >
                    <Tab className={classes.mainTabs} {...a11yProps(0)} icon={<img className={classes.imgBankImages} src="../assets/payment/logo-cargills-bank-142x32px.png" />} style={{ filter: (value == 4 ? "grayscale(0%)" : "grayscale(100%)") }} />
                    <Tab className={classes.mainTabs} {...a11yProps(1)} icon={<img className={classes.imgBankImages} src="../assets/payment/logo-sampath-bank-126x40px.svg" />} style={{ filter: (value == 0 ? "grayscale(0%)" : "grayscale(100%)") }} />
                    <Tab className={classes.mainTabs} {...a11yProps(2)} icon={<img className={classes.imgBankImages} src="../assets/payment/Pan-Asia-Bank.svg" />} style={{ filter: (value == 1 ? "grayscale(0%)" : "grayscale(100%)") }} />
                    {/* <Tab className={classes.mainTabs} {...a11yProps(3)} icon={<img className={classes.imgBankImages} src="../assets/payment/logo-nations-trustBank-123x40px.svg" />} style={{ filter: (value == 2 ? "grayscale(0%)" : "grayscale(100%)") }} />
                    <Tab className={classes.mainTabs} {...a11yProps(4)} icon={<img className={classes.imgBankImages} src="../assets/payment/logo-boc-70x40px.svg" />} style={{ filter: (value == 3 ? "grayscale(0%)" : "grayscale(100%)") }} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography style={{ color: "#000000", fontWeight: 600 }}>Transfer your payment at any Cargills Bank branch.</Typography>
                                <Typography style={{ color: "#5A5A5A", fontSize: "14px" }}>Your Ad will be published once your payment get updated on our system.</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className={classes.divRefNumber} >
                                    <Typography align="center" style={{ color: "#5A5A5A", fontSize: "14px" }}>Reference number</Typography>
                                    <Typography align="center" style={{ color: "#000000", fontWeight: 600, fontSize: "13px" }}>{ref}</Typography>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <Avatar src="../assets/icon/icon-info.svg" style={{ width: "21px", height: "21px" }} />
                                    <Typography style={{ color: "#5A5A5A", fontWeight: 600, marginLeft: "20px" }}>How to fill your bank slip</Typography>
                                </div>

                                <img src="../assets/payment/img-cargills-bank-slip.png" width="100%" />
                            </Grid>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton} onClick={backToPayment}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {/* <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-cargills-bank-142x32px.png" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.divAccountDetails}>
                                    <Typography className={classes.stepsTitle}>Cargills bank account details</Typography>
                                    <div className={classes.stepsMainDiv}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_1-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Reference no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails} style={{ fontSize: "13px" }}>{ref}</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_2-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Business name:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>Saruwata (Pvt) Ltd.</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_3-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Account no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>001952000021</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_4-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Total amount:</span>
                                            </div>
                                            <span className={classes.stepsDetailsPrice}>Rs. {price}.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.contactMainDiv}>
                                    <Typography style={{ textAlign: "center" }}>Please send us your bank slip with required</Typography>
                                    <Typography style={{ textAlign: "center" }}>details to our WhatsApp number.</Typography>
                                    <img className={classes.contactImg} src="../assets/payment/btn_whatsapp_add-160x32px.png" style={{ marginTop: "5px" }} />
                                    <br />
                                    <Typography style={{ textAlign: "center" }}>Once you complete the bank transfer please call our customer support on <b>+94 117 455 955</b>.</Typography>
                                    <Typography style={{ textAlign: "center" }}>or</Typography>
                                    <Typography style={{ textAlign: "center" }}>drop us an E-mail with your transaction detail to <span style={{ color: "#0080FF" }}>payments@saruwata.lk</span></Typography>
                                </div>
                            </Grid>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-cargills-bank-142x32px.png" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton} onClick={backToPayment}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {/* <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography style={{ color: "#000000", fontWeight: 600 }}>Transfer your payment at any Sampath bank branch.</Typography>
                                <Typography style={{ color: "#5A5A5A", fontSize: "14px" }}>Your Ad will be published once your payment get updated on our system.</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className={classes.divRefNumber} >
                                    <Typography align="center" style={{ color: "#5A5A5A", fontSize: "14px" }}>Reference number</Typography>
                                    <Typography align="center" style={{ color: "#000000", fontWeight: 600, fontSize: "13px" }}>{ref}</Typography>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <Avatar src="../assets/icon/icon-info.svg" style={{ width: "21px", height: "21px" }} />
                                    <Typography style={{ color: "#5A5A5A", fontWeight: 600, marginLeft: "20px" }}>How to fill your bank slip</Typography>
                                </div>

                                <img src="../assets/payment/img-sampath-bank-slip.png" width="100%" />
                            </Grid>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton} onClick={backToPayment}> Back to Payment</Button>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {/* <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-sampath-vishwa-96x38px.png" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.divAccountDetails}>
                                    <Typography className={classes.stepsTitle}>Sampath bank account details</Typography>
                                    <div className={classes.stepsMainDiv}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_1-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Reference no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails} style={{ fontSize: "13px" }}>{ref}</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_2-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Business name:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>Saruwata (Pvt) Ltd.</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_3-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Account no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>021910010732</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_4-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Total amount:</span>
                                            </div>
                                            <span className={classes.stepsDetailsPrice}>Rs. {price}.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.contactMainDiv}>
                                    <Typography style={{ textAlign: "center" }}>Please send us your bank slip with required</Typography>
                                    <Typography style={{ textAlign: "center" }}>details to our WhatsApp number.</Typography>
                                    <img className={classes.contactImg} src="../assets/payment/btn_whatsapp_add-160x32px.png" style={{ marginTop: "5px" }} />
                                    <br />
                                    <Typography style={{ textAlign: "center" }}>Once you complete the bank transfer please call our customer support on <b>+94 117 455 955</b>.</Typography>
                                    <Typography style={{ textAlign: "center" }}>or</Typography>
                                    <Typography style={{ textAlign: "center" }}>drop us an E-mail with your transaction detail to <span style={{ color: "#0080FF" }}>payments@saruwata.lk</span></Typography>
                                </div>
                            </Grid>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-sampath-vishwa-96x38px.png" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton} onClick={backToPayment}> Back to Payment</Button>

                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {/* <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button> */}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography style={{ color: "#000000", fontWeight: 600 }}>Transfer your payment at any PAN Asia Bank branch.</Typography>
                                <Typography style={{ color: "#5A5A5A", fontSize: "14px" }}>Your Ad will be published once your payment get updated on our system.</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className={classes.divRefNumber} >
                                    <Typography align="center" style={{ color: "#5A5A5A", fontSize: "14px" }}>Reference number</Typography>
                                    <Typography align="center" style={{ color: "#000000", fontWeight: 600, fontSize: "13px" }}>{ref}</Typography>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <Avatar src="../assets/icon/icon-info.svg" style={{ width: "21px", height: "21px" }} />
                                    <Typography style={{ color: "#5A5A5A", fontWeight: 600, marginLeft: "20px" }}>How to fill your bank slip</Typography>
                                </div>

                                <img src="../assets/payment/img-pan-bank-slip.png" width="100%" />
                            </Grid>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton} onClick={backToPayment}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {/* <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/Pan-Asia-Bank.svg" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.divAccountDetails}>
                                    <Typography className={classes.stepsTitle}>PAN Asia Bank account details</Typography>
                                    <div className={classes.stepsMainDiv}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_1-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Reference no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails} style={{ fontSize: "13px" }}>{ref}</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_2-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Business name:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>Saruwata (Pvt) Ltd.</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_3-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Account no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>100111005120</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_4-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Total amount:</span>
                                            </div>
                                            <span className={classes.stepsDetailsPrice}>Rs. {price}.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.contactMainDiv}>
                                    <Typography style={{ textAlign: "center" }}>Please send us your bank slip with required</Typography>
                                    <Typography style={{ textAlign: "center" }}>details to our WhatsApp number.</Typography>
                                    <img className={classes.contactImg} src="../assets/payment/btn_whatsapp_add-160x32px.png" style={{ marginTop: "5px" }} />
                                    <br />
                                    <Typography style={{ textAlign: "center" }}>Once you complete the bank transfer please call our customer support on <b>+94 117 455 955</b>.</Typography>
                                    <Typography style={{ textAlign: "center" }}>or</Typography>
                                    <Typography style={{ textAlign: "center" }}>drop us an E-mail with your transaction detail to <span style={{ color: "#0080FF" }}>payments@saruwata.lk</span></Typography>
                                </div>
                            </Grid>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/Pan-Asia-Bank.svg" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton} onClick={backToPayment}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {/* <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography style={{ color: "#000000", fontWeight: 600 }}>Transfer your payment at any Nations Trust Bank branch.</Typography>
                                <Typography style={{ color: "#5A5A5A", fontSize: "14px" }}>Your Ad will be published once your payment get updated on our system.</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className={classes.divRefNumber} >
                                    <Typography align="center" style={{ color: "#5A5A5A", fontSize: "14px" }}>Reference number</Typography>
                                    <Typography align="center" style={{ color: "#000000", fontWeight: 600 }}>188536-156232-458223</Typography>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <Avatar src="../assets/icon/icon-info.svg" style={{ width: "21px", height: "21px" }} />
                                    <Typography style={{ color: "#5A5A5A", fontWeight: 600, marginLeft: "20px" }}>How to fill your bank slip</Typography>
                                </div>

                                <img src="../assets/payment/img-ntb-bank-slip.png" width="100%" />
                            </Grid>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-nations-trustBank-123x40px.svg" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.divAccountDetails}>
                                    <Typography className={classes.stepsTitle}>Nations Trust Bank account details</Typography>
                                    <div className={classes.stepsMainDiv}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_1-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Reference no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>188536-156232-458223</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_2-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Business name:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>Saruwata (Pvt) Ltd.</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_3-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Account no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>458931230041</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_4-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Total amount:</span>
                                            </div>
                                            <span className={classes.stepsDetailsPrice}>Rs. 1,850.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.contactMainDiv}>
                                    <Typography style={{ textAlign: "center" }}>Please send us your bank slip with required</Typography>
                                    <Typography style={{ textAlign: "center" }}>details to our WhatsApp number.</Typography>
                                    <img className={classes.contactImg} src="../assets/payment/btn_whatsapp_add-160x32px.png" style={{ marginTop: "5px" }} />
                                    <br />
                                    <Typography style={{ textAlign: "center" }}>Once you complete the bank transfer please call our customer support on <b>+94 117 455 955</b>.</Typography>
                                    <Typography style={{ textAlign: "center" }}>or</Typography>
                                    <Typography style={{ textAlign: "center" }}>drop us an E-mail with your transaction detail to <span style={{ color: "#0080FF" }}>payments@saruwata.lk</span></Typography>
                                </div>
                            </Grid>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-nations-trust-bank-122x40px.png" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Typography style={{ color: "#000000", fontWeight: 600 }}>Transfer your payment at any Bank Of Ceylon branch.</Typography>
                                <Typography style={{ color: "#5A5A5A", fontSize: "14px" }}>Your Ad will be published once your payment get updated on our system.</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className={classes.divRefNumber} >
                                    <Typography align="center" style={{ color: "#5A5A5A", fontSize: "14px" }}>Reference number</Typography>
                                    <Typography align="center" style={{ color: "#000000", fontWeight: 600 }}>188536-156232-458223</Typography>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <Avatar src="../assets/icon/icon-info.svg" style={{ width: "21px", height: "21px" }} />
                                    <Typography style={{ color: "#5A5A5A", fontWeight: 600, marginLeft: "20px" }}>How to fill your bank slip</Typography>
                                </div>

                                <img src="../assets/payment/img-boc-bank-slip.png" width="100%" />
                            </Grid>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.publishButton} onClick={handleClickOpen}> Publish</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Hidden xsDown={true} smDown={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-boc-70x40px.svg" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.divAccountDetails}>
                                    <Typography className={classes.stepsTitle}>Bank Of Ceylon account details</Typography>
                                    <div className={classes.stepsMainDiv}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_1-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Reference no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>188536-156232-458223</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_2-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Business name:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>Saruwata (Pvt) Ltd.</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_3-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Account no.:</span>
                                            </div>
                                            <span className={classes.stepsDetails}>458931230041</span>
                                        </div>
                                        <Divider />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img className={classes.stepsImg} src="../assets/payment/icon_no_4-20x24px.png" />
                                                <span style={{ fontSize: "14px" }}>Total amount:</span>
                                            </div>
                                            <span className={classes.stepsDetailsPrice}>Rs. 1,850.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.contactMainDiv}>
                                    <Typography style={{ textAlign: "center" }}>Please send us your bank slip with required</Typography>
                                    <Typography style={{ textAlign: "center" }}>details to our WhatsApp number.</Typography>
                                    <img className={classes.contactImg} src="../assets/payment/btn_whatsapp_add-160x32px.png" style={{ marginTop: "5px" }} />
                                    <br />
                                    <Typography style={{ textAlign: "center" }}>Once you complete the bank transfer please call our customer support on <b>+94 117 455 955</b>.</Typography>
                                    <Typography style={{ textAlign: "center" }}>or</Typography>
                                    <Typography style={{ textAlign: "center" }}>drop us an E-mail with your transaction detail to <span style={{ color: "#0080FF" }}>payments@saruwata.lk</span></Typography>
                                </div>
                            </Grid>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.bankTransferMainDiv}>
                                        <div>
                                            <Typography style={{ color: "#5A5A5A" }}>Pay via online </Typography>
                                            <Typography style={{ color: "#5A5A5A" }}>bank transfer</Typography>
                                        </div>
                                        <div className={classes.bankTransferDiv}>
                                            <img src="../assets/payment/logo-boc-70x40px.svg" className={classes.bankTransferImg} />
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Hidden lgUp={true} mdUp={true}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button className={classes.backButton}> Back to Payment</Button>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            {(isMemberPayment() ? null
                                                : <Button className={classes.publishButton} onClick={handleClickOpen}>Publish</Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel> */}


        </Box>
    );
}