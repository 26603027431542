import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logEvent } from "firebase/analytics";
import { ACTION_VIEW } from "../../config/actions";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import {
  NAV_SELLER_PROFILE,
} from "../../providers/NavigationProvider";
import PostSkelton from "../../components/PostSkelton/PostSkelton";
import { Post } from "../../features/post/Post";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { VIEW_SELLER_PAGE } from "../../config/events";
import { useLocation } from "react-router-dom";
import { clearAllPosts, getSellerProfileWithPosts, selectPosts, selectSellerProfile } from "../../features/seller/sellerSlice";
import PostCard from "../../components/Post/PostCardFixed/PostCard";
// import classes from "*.module.css";
import { Box, Button, Fab, Grid, Hidden, IconButton, InputBase, Paper, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import PostCardMobile from "../../components/Post/PostCardFixed/PostCardMobile/PostCardMobile";
import SearchIcon from "@material-ui/icons/SearchSharp";
import SellerSidebarComponent from "../../components/SellerSidebarComponent/SellerSidebarComponent";
import axios from "axios";
import { GET_SELLER_AD_COUNT, GET_SHOP_DETAILS } from "../../config/urls";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ArrowUpward } from "@material-ui/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@material-ui/lab/Alert";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    btnApply: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
  })
);
const SellerPage = (props: any) => {
  const dispatch = useDispatch();
  const topref: any = useRef();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null) as any;
  const posts = useSelector(selectPosts);
  const [searchedPosts, setSearchedPosts] = useState([]);
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const email = useLocation().pathname.split('/')[2];
  const [end, setEnd] = useState(false);
  const [page, setPage] = useState(0);
  const [busy, setbusy] = useState(false);
  const [searching, setSearching] = useState(false);
  const [adCount, setAdCount] = useState(0);
  const styles = useStyles();
  const [showScrollTop, setshowScrollTop] = useState(false);
  const scrollContent = document.getElementById("scrollable-root");
  const searchedPostsArray = [] as any;


  useEffect(() => {
    if (email == "") {
      navigate(-1);
      return;
    }

    dispatch(setCurrentLocation(NAV_SELLER_PROFILE));
    dispatch(clearAllPosts([]))
    loadData(page);
    getAdCount();
    topref?.current?.scrollIntoView();
    logEvent(analytics, VIEW_SELLER_PAGE, { action: ACTION_VIEW, platfrom: 'DESKTOP' });
  }, []);

  // useEffect(() => {

  //   if (email != null && email != '') {

  //       axios.get(GET_SHOP_DETAILS + "?email=" + email).then(resp => {

  //           console.log(resp.data)
  //       }).catch(e => console.log(e));
  //   }
  // }, []);



  const handleScrollToTopRequest = () => {
    setshowScrollTop(false);
    scrollContent?.scrollTo(0, 0);


  };
  const getAdCount = () => {
    axios.get(GET_SELLER_AD_COUNT + email).then(data => setAdCount(data.data)).catch(e => console.log(e));
  }

  const loadData = (page: number) => {
    setbusy(true);
    setshowScrollTop(true);
    dispatch(
      getSellerProfileWithPosts(
        email,
        (data: any) => { setProfile(data); setbusy(false); if (data.posts.length == 0) { setEnd(true) } },
        (err: any) => {
          navigate(-1);
        }, page
      )
    );
  };


  const loadSearchedCardView = () => {
    return (
      <>


        <Hidden xsDown={true} smDown={true}>
          {searchedPosts?.length > 0
            ? searchedPosts.map((item: Post, index: number) => (
              <div key={item.id} style={{ padding: "1px 5px" }}>
                <LazyLoadComponent
                  placeholder={
                    <img
                      src="assets/images/Place-holder-listing.png"
                      style={{ width: "707px", height: "194px" }}
                    ></img>
                  }
                  key={item.id}
                >
                  <PostCard
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                  ></PostCard>

                </LazyLoadComponent>
              </div>
            )) : [...Array(5)].map((_, n) => <PostSkelton key={n} />)}
        </Hidden>


        <Hidden lgUp={true} mdUp={true}>
          {searchedPosts?.length > 0
            ? searchedPosts.map((item: Post, index: number) => (
              <div key={item.id} style={{ padding: "5px 0px" }}>
                <LazyLoadComponent
                  placeholder={
                    <img
                      src="assets/images/Place-holder-listing.png"
                      style={{ width: "auto", height: "100px" }}
                    ></img>
                  }
                  key={item.id}
                >
                  <PostCardMobile
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                  ></PostCardMobile>
                </LazyLoadComponent>
              </div>
            )) : [...Array(5)].map((_, n) => <PostSkelton key={n} />)}
        </Hidden>



      </>
    );
  };





  const loadCardView = () => {
    return (
      <>


        <Hidden xsDown={true} smDown={true}>
          {posts?.length > 0
            ? posts.map((item: Post, index: number) => (
              <div key={item.id} style={{ padding: "1px 5px" }}>
                <LazyLoadComponent
                  placeholder={
                    <img
                      src="assets/images/Place-holder-listing.png"
                      style={{ width: "707px", height: "194px" }}
                    ></img>
                  }
                  key={item.id}
                >
                  <PostCard
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                  ></PostCard>

                </LazyLoadComponent>
              </div>
            )) : [...Array(5)].map((_, n) => <PostSkelton key={n} />)}
        </Hidden>


        <Hidden lgUp={true} mdUp={true}>
          {posts?.length > 0
            ? posts.map((item: Post, index: number) => (
              <div key={item.id} style={{ padding: "5px 0px" }}>
                <LazyLoadComponent
                  placeholder={
                    <img
                      src="assets/images/Place-holder-listing.png"
                      style={{ width: "auto", height: "100px" }}
                    ></img>
                  }
                  key={item.id}
                >
                  <PostCardMobile
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                  ></PostCardMobile>
                </LazyLoadComponent>
              </div>
            )) : [...Array(5)].map((_, n) => <PostSkelton key={n} />)}
        </Hidden>


        {/* {profile?.posts.length > 0
          ? profile?.posts.map((item: Post, index: number) => (
            <div key={item.id} style={{ paddingBottom: "10px" }}>

              <LazyLoadComponent
                placeholder={
                  <img src="assets/images/imgplaceholder.jpg"></img>
                }
                key={item.id}
              >
                <PostCard
                  post={item}
                  position={index + 1}
                  detailsAction={() => { }}
                ></PostCard>
              </LazyLoadComponent>
            </div>
          ))
          : [...Array(5)].map((_, n) => <PostSkelton key={n} />)} */}

      </>
    );
  };

  return (
    <>
      <div ref={topref}></div>

      {profile?.member ?
        <Grid container style={{ marginTop: "20px", }}>
          <Grid item lg={12} md={12} xs={12} style={{ position: "relative" }}>
            <img style={{ width: "100%", maxWidth: "904px", zIndex: 100, maxHeight: "335px", height: "auto", border: "1px solid #DDDDDD" }} src={(profile.shpDetails.coverImgPath != null ? profile.shpDetails.coverImgPath : '../../assets/images/cover-imge-seller-page.png')} />
            {/* {(isTabletOrMobile ?
              <img style={{ position: "absolute", width: "100%", bottom: "0", left: "3%", maxWidth: "200px", maxHeight: "135px", border: "1px solid #DDDDDD" }} src={(profile.shpDetails.prfileImgPath != null ? profile.shpDetails.prfileImgPath : '../../assets/logo/seller-page-logo-img.png')} />
              : <img style={{ position: "absolute", width: "100%", bottom: "-16%", left: "3%", maxWidth: "200px", maxHeight: "135px", border: "1px solid #DDDDDD" }} src={(profile.shpDetails.prfileImgPath != null ? profile.shpDetails.prfileImgPath : '../../assets/logo/seller-page-logo-img.png')} />
            )} */}

          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container >
              <Grid item lg={3} md={3} xs={12} style={{ position: "relative" }}>
                {/* <img style={{ width: "100%", bottom: "0", zIndex: 1000, marginTop: "-60px", marginLeft: "3%", maxWidth: "100px", maxHeight: "35px", border: "1px solid #DDDDDD" }} src={(profile.shpDetails.prfileImgPath != null ? profile.shpDetails.prfileImgPath : '../../assets/logo/seller-page-logo-img.png')} /> */}
                {(isTabletOrMobile ?
                  <img style={{ width: "100%", height: "auto", left: "3%", marginTop: "-60px", position: "absolute", maxWidth: "90px", maxHeight: "auto", border: "1px solid #DDDDDD" }} src={(profile.shpDetails.prfileImgPath != null ? profile.shpDetails.prfileImgPath : '../../assets/logo/seller-page-logo-img.png')} />
                  : <img style={{ position: "absolute", width: "100%", bottom: "-16%", left: "4%", maxWidth: "200px", maxHeight: "135px", border: "1px solid #DDDDDD" }} src={(profile.shpDetails.prfileImgPath != null ? profile.shpDetails.prfileImgPath : '../../assets/logo/seller-page-logo-img.png')} />
                )}
              </Grid>
              <Grid item lg={9} md={9} xs={12}>
                {(isTabletOrMobile ? null :
                  <Paper component="form" style={{
                    padding: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: "98%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "5px"
                  }}>

                    <InputBase
                      style={{ flex: 1, marginLeft: theme.spacing(1), }}
                      placeholder="Search ads"
                      inputProps={{ 'aria-label': 'search ads' }}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          setSearching(true);
                        }
                        posts.map((item: any) => {
                          if (item.title.includes(e.target.value)) {

                            searchedPostsArray.push(item);

                          }
                        }
                        )
                        setSearchedPosts(searchedPostsArray);
                      }

                      }
                    />
                    <IconButton type="submit" aria-label="search" style={{ padding: 10, }}>
                      <SearchIcon />
                    </IconButton>

                  </Paper>
                )}

              </Grid>
            </Grid>
          </Grid>
        </Grid>


        : null}

      <br />
      <Hidden mdUp={true} lgUp={true}>
        <SellerSidebarComponent />
      </Hidden>
      <div id="account-list-header-wrapper">
        <div className="pre-post-bar-seller">
          <div>All Ads from <span style={{ fontWeight: 700 }}>{profile?.name}</span> - (Posted {adCount} Ads)</div>
        </div>
      </div>

      <div>{
        (searching ? loadSearchedCardView() : loadCardView())



      }</div>

      {(busy ? <Box display="flex" justifyContent="center">
        <PulseLoader color="#0080ff" loading={true} />
      </Box> : null)}
      {posts.length > 0 ? (
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px"

            }}
          >
            {busy ? null : ((end ?
              <Alert severity="info" color="info" style={{ marginTop: "10px" }}>
                You have seen all
              </Alert>
              : <Button
                color="primary"
                id="btn-load-more"
                onClick={() => {
                  setPage(page + 1);
                  loadData(page + 1)


                }}
                className={styles.btnApply}
              >
                <span>Load More</span>
              </Button>)

            )}
          </Grid>
        </Grid>
      ) : null}



      {/* 
      {page > 0 ? (
        <div
          style={{
            position: "sticky",
            bottom: "100px",
            zIndex: 999,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginLeft: "340px",
            pointerEvents: "none",
          }}
        >
          {showScrollTop ? (
            <Fab
              color="primary"
              aria-label="add"
              onClick={handleScrollToTopRequest}
              style={{ pointerEvents: "all" }}
            >
              <ArrowUpward></ArrowUpward>
            </Fab>
          ) : null}
        </div>
      ) : null} */}


      <div style={{ height: 50 }}></div>

    </>
  );
};

export default SellerPage;
