import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { selectQuickSell, setAddOnCodesQuickSell, setQuickSell } from '../features/addOnServices';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuickSellValues } from './AddOnValues';


const useStyles = makeStyles({

    root: {
        minWidth: 275,
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none",
        padding: 0,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },

    large: {
        // width: theme.spacing(7),
        // height: theme.spacing(7),
    },
    jumpAd: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    f3d: {
        fontSize: 12,
    },
    rs200: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    lableTitle: {
        fontSize: 12,

    },
    lablePrice: {
        fontSize: 15,
        fontWeight: 500
    },
    descp: {
        fontSize: 14
    }
});

export default function QuickSell(props: any) {

    const classes = useStyles();
    const quickSellValues = useSelector(selectQuickSellValues);
    const dispatch = useDispatch();

    const QuickSell = useSelector(selectQuickSell);
    const [day3, setDay3] = useState(false);
    const [day7, setDay7] = useState(false);
    const [day15, setDay15] = useState(false);


    function isQuickSell() {
        if (QuickSell == 0) {
            return false;
        }
        return true;
        // if (QuickSell == 1) {
        //     return true;
        // }
        // return false;
    }



    const isQuickSellValueNull = () => {


        if (quickSellValues.length > 0) {
            return false;
        }
        return true;
    }
    const setQuickSellValue = (price: string) => {
        dispatch(setQuickSell(parseInt(price)));
    }
    const setQuickSellCode = (code: string) => {
        dispatch(setAddOnCodesQuickSell(code));
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} md={12} lg={5}>
                        <Grid container xs={12}>
                            <Grid item xs={3} md={3} lg={2}>

                                <Avatar alt="Bundle Pack" src="../../assets/addOn/icon_quick-sale-lable.png" className={classes.large} variant="rounded" />
                            </Grid>
                            <Grid item xs={9} md={9} lg={10}>
                                <div >
                                    <Typography className={classes.jumpAd} >
                                        Quick Sale
                                    </Typography>
                                    <Typography className={classes.descp} >
                                        Make your ad more brighter than the other ads with a bright red label.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={12} md={12} lg={6}>
                        <Grid container xs={12}>
                            {/* <Grid item xs={12} >
                                <Grid container direction="row" style={{ justifyContent: "center" }}> */}
                            <FormControl component="fieldset" disabled={props.available}>

                                <RadioGroup row aria-label="position" name="position" defaultValue="top" >
                                    <Grid item xs={4}>
                                        <FormControlLabel checked={(isQuickSell() ? (day3 ? true : false) : false)} value="for3days" style={(String((isQuickSellValueNull() ? "400" : quickSellValues[0].price)).length >= 6 ? { paddingRight: 20 } : { paddingRight: 55 })} control={<Radio color="primary" />}

                                            onChange={() => {
                                                setDay3(true);
                                                setDay7(false);
                                                setDay15(false);
                                                setQuickSellValue((isQuickSellValueNull() ? "400" : quickSellValues[0].price))
                                                setQuickSellCode(quickSellValues[0].code);

                                            }
                                            }
                                            label={
                                                <div>


                                                    {

                                                        (isQuickSellValueNull() ?
                                                            <Typography className={classes.lablePrice} >
                                                                Rs. 400
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.lablePrice} >
                                                                Rs.{quickSellValues[0].price}

                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography className={classes.lableTitle}>
                                                        For 3 days
                                                    </Typography>

                                                </div>
                                            }

                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel checked={(isQuickSell() ? (day7 ? true : false) : false)} value="for7days" style={(String((isQuickSellValueNull() ? "600" : quickSellValues[1].price)).length >= 6 ? { paddingRight: 20 } : { paddingRight: 20, marginRight: 10 })} control={<Radio color="primary" />}
                                            onChange={() => {
                                                setDay3(false);
                                                setDay7(true);
                                                setDay15(false);
                                                setQuickSellValue((isQuickSellValueNull() ? "600" : quickSellValues[1].price))
                                                setQuickSellCode(quickSellValues[1].code);
                                                // setDay3(false);
                                                // setDay7(false);
                                                // setDay15(false);


                                            }
                                            }
                                            label={
                                                <div>

                                                    {

                                                        (isQuickSellValueNull() ?
                                                            <Typography className={classes.lablePrice} >
                                                                Rs. 600
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.lablePrice} >
                                                                Rs.{quickSellValues[1].price}
                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography className={classes.lableTitle}>
                                                        For 7 days
                                                    </Typography>
                                                </div>
                                            } />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel checked={(isQuickSell() ? (day15 ? true : false) : false)} value="for15days" control={<Radio color="primary" />}
                                            onChange={() => {
                                                setDay3(false);
                                                setDay7(false);
                                                setDay15(true);
                                                setQuickSellValue((isQuickSellValueNull() ? "900" : quickSellValues[2].price))
                                                setQuickSellCode(quickSellValues[2].code);

                                            }
                                            }
                                            label={
                                                <div>

                                                    {

                                                        (isQuickSellValueNull() ?
                                                            <Typography className={classes.lablePrice} >
                                                                Rs. 900
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.lablePrice} >
                                                                Rs.{quickSellValues[2].price}
                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography className={classes.lableTitle}>
                                                        For 15 days
                                                    </Typography>
                                                </div>
                                            } />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {/* </Grid>
                </Grid> */}


            </CardContent>

        </Card>
    );
}

