import { Avatar, Button, Card, CardActionArea, CardMedia, CircularProgress, CssBaseline, Grid, Hidden, makeStyles, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentLocation } from "../../../features/navigation/navigationSlice";
import { PAYMENT_SUMMERY } from "../../../providers/NavigationProvider";
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import axios from "axios";
import { frontend, MEMBERSHIP_PAYMENT_DETAILS, PAYMENT_SUMMERY_DETAILS } from "../../../config/urls";
import { setRef } from "../../../config/locations";
import { setCategory, setRefNo, setMembershipType, setDiscount, setMembershipDiscountedAmount, setMembershipActualAmount, setMembershipUserEmail, setStatusText } from "../../features/membershipPayment";
import PayementMembershipSideBar from "../../components/PayementMembershipSideBar";


const useStyles = makeStyles({
    cardroot: {
        width: "60%",
        minWidth: 345,
        margin: "auto"
    },
    media: {
        height: 400,
    },

});
var ref = '';
const PaymentMembership = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [view, setView] = useState(false);
    const [date, setDate] = useState('');
    const [membershipId, setMembershipId] = useState('');
    const [userHasMembershipId, setUserHasMembershipId] = useState('');
    const [salesReferenceId, setSalesReferenceId] = useState('');
    const [membershipType, setMembershipTypee] = useState('');
    const [membershipName, setMembershipName] = useState('');
    const [membershipDisplayName, setMembershipDisplayName] = useState('');
    const [daysCount, setDaysCount] = useState('');
    const [adCount, setAdCount] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [voucherTotalAmount, setVoucherTotalAmount] = useState('');
    const [voucherAmount, setVoucherAmount] = useState('');
    const [voucherParentAmount, setVoucherParentAmount] = useState('');
    const [membershipActualAmount, setMembershipActualAmountt] = useState('');
    const [membershipDiscountedAmount, setMembershipDiscountedAmountt] = useState('');
    const [discountRate, setDiscountRate] = useState('');
    const [membershipStatus, setMembershipStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [state, setState] = useState('');
    const [discount, setDiscountt] = useState('');
    const [applicableCategories, setApplicableCategories] = useState({} as any);
    const [amount, setAmount] = useState(0);

    const [categories, setCategoryy] = useState([]);
    const [clientRef, setClientRef] = useState('');
    const [transactionRef, setTransactionRef] = useState('');
    const refId = useLocation().pathname.split("/")[2];





    useEffect(() => {

        dispatch(setCurrentLocation(PAYMENT_SUMMERY));
        getSummeryDetails();
    }, []);


    const getSummeryDetails = () => {
        // axios.get(PAYMENT_SUMMERY_DETAILS + `/${paymentId}/`)
        //     .then((response) => {
        //         // data=JSON.stringify(response.data);
        //     });



        if (refId !== '') {
            axios.get(MEMBERSHIP_PAYMENT_DETAILS + refId)
                // axios.get(PAYMENT_SUMMERY_DETAILS + `/${paymentId}`)
                .then((response) => {
                    // alert(response.data.id);
                    setTransactionRef(response.data.transactionRef);
                    setClientRef(response.data.clientRef);
                    ref = response.data.clientRef;

                    setDate(response.data.date);
                    setMembershipId(response.data.membershipId);
                    setUserHasMembershipId(response.data.userHasMembershipId);
                    setSalesReferenceId(response.data.salesReferenceId);
                    setMembershipTypee(response.data.membershipType);
                    setMembershipName(response.data.membershipName);
                    setMembershipDisplayName(response.data.membershipDisplayName);
                    setDaysCount(response.data.daysCount);
                    setAdCount(response.data.adCount);
                    setUserEmail(response.data.userEmail);
                    setUserName(response.data.userName);
                    setMobile(response.data.mobile);
                    setAddress(response.data.address);
                    setCity(response.data.city);
                    setVoucherTotalAmount(response.data.voucherTotalAmount);
                    setVoucherAmount(response.data.voucherAmount);
                    setVoucherParentAmount(response.data.voucherParentAmount);
                    setMembershipActualAmountt(response.data.membershipActualAmount);
                    setMembershipDiscountedAmountt(response.data.membershipDiscountedAmount);
                    setDiscountRate(response.data.discountRate);
                    setApplicableCategories(response.data.applicableCategories);
                    setMembershipStatus(response.data.membershipStatus);
                    setPaymentStatus(response.data.paymentStatus);
                    setState(response.data.state);
                    setDiscountt(response.data.discount);
                    setCategoryy(response.data.cats);

                    dispatch(setRefNo(response.data.salesReferenceId));
                    dispatch(setCategory(response.data.cats));
                    dispatch(setMembershipType(response.data.membershipType));
                    dispatch(setDiscount(response.data.discount));
                    dispatch(setMembershipDiscountedAmount(response.data.membershipDiscountedAmount));
                    dispatch(setMembershipActualAmount(response.data.membershipActualAmount));
                    dispatch(setMembershipUserEmail(response.data.userEmail));
                    dispatch(setStatusText(response.data.membershipStatus));


                    setView(true);


                });
        }
    }





    return (
        <React.Fragment>
            <CssBaseline />
            <>
                {(view ?
                    <div>
                        <Typography style={{ fontSize: 17, fontWeight: 'bold', paddingBottom: '12px' }}>
                            Membership Payments
                        </Typography>
                        <Divider></Divider>


                        {


                            <>
                                <div style={{ border: "1px solid #DDDDDD", borderRadius: "8px", marginTop: "5px", paddingTop: "20px", paddingBottom: "20px" }}>
                                    <Grid container>
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Avatar src="../../assets/icon/icon-membership.svg" variant="square" style={{ width: '56px', height: "56px", marginLeft: "auto", marginRight: "auto" }} />
                                            <Typography style={{ fontWeight: 600, fontSize: "17px" }}>Business {membershipType[0].toUpperCase() + membershipType.substring(1).toLowerCase()} Membership </Typography>
                                            <Typography style={{ fontSize: "15px", marginTop: "15px" }}>Thank you for choosing a Membership package with Saruwata.lk </Typography>

                                            <Typography style={{ fontSize: "15px", color: "#FF0000", paddingTop: "5px" }}>Reference No.: {refId} </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ backgroundColor: "#F5F6F8", padding: "1px 5px", marginTop: "5px" }}>
                                    <Typography style={{ padding: "3px 20px", color: "#5A5A5A" }}>
                                        Here is a summary of your selected membership package.
                                    </Typography>
                                    <div style={{ backgroundColor: "#FFFF", margin: "10px 0" }}>

                                        <Grid container>

                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>
                                                <Typography style={{ color: "#0080FF" }}>
                                                    Customer Details
                                                </Typography>
                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Customer Name</div>
                                                        <div>{userName}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Customer Email</div>
                                                        {/* <div>Payment Success</div> */}
                                                        <div>{userEmail}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Address</div>
                                                        {/* <div>Visa</div> */}
                                                        <div>{address}</div>
                                                    </div>
                                                </div>

                                            </Grid>

                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>

                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}></div>
                                                        {/* <div>2021-08-31 | 11:12:10 AM</div> */}

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Contact No.:</div>
                                                        {/* <div>Payment Success</div> */}
                                                        <div>{mobile}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%" }}>
                                                        <div style={{ fontWeight: "bold" }}>City</div>
                                                        {/* <div>Visa</div> */}
                                                        <div>{city}</div>
                                                    </div>
                                                </div>

                                                <Divider variant="middle" />
                                            </Grid>
                                        </Grid>


                                        {/* ///////////////// */}
                                        <Grid container>
                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>
                                                <Typography style={{ color: "#0080FF" }}>
                                                    Package Details
                                                </Typography>
                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Membership Category</div>
                                                        {/* <div>2021-08-31 | 11:12:10 AM</div> */}
                                                        <div>{membershipDisplayName}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Membership Type</div>
                                                        {/* <div>Payment Success</div> */}
                                                        <div> {membershipType[0].toUpperCase() + membershipType.substring(1).toLowerCase()} Package</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Membership Period</div>
                                                        {/* <div>Visa</div> */}
                                                        <div>{daysCount} days</div>
                                                    </div>
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>

                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div>

                                                            <Tooltip title={categories.map((item: any) => applicableCategories[item].map((i: any) => <Typography style={{ fontSize: "12px" }}>- {i}</Typography>))} placement="right-start" arrow>
                                                                <div style={{ fontSize: "12px", color: "#0080FF", cursor: "pointer", width: "fit-content" }}>APPLICABLE CATEGORIES</div>

                                                            </Tooltip>
                                                        </div>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Ad Limit</div>
                                                        {/* <div>Payment Success</div> */}
                                                        <div>{adCount}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Free Promotion Value (Voucher)</div>
                                                        {/* <div>Visa</div> */}
                                                        <div>Rs. {voucherAmount}</div>
                                                    </div>
                                                </div>

                                                <Divider variant="middle" />
                                            </Grid>
                                        </Grid>



                                        {/* ///////////////////// */}

                                        <Grid container>

                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>
                                                <Typography style={{ color: "#0080FF" }}>
                                                    Payment Details
                                                </Typography>


                                            </Grid>

                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>

                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Membership Type</div>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>{membershipType[0].toUpperCase() + membershipType.substring(1).toLowerCase()} Package</div>
                                                        {/* <div>Payment Success</div> */}

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%", textAlign: "right" }}>
                                                        <div>Rs. {membershipActualAmount}</div>

                                                    </div>
                                                </div>

                                            </Grid>

                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>

                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Membership Period</div>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%" }}>
                                                        <div style={{ fontWeight: "bold" }}>
                                                            {
                                                                (daysCount == '30' ? "Discounted" : (daysCount == '90' ? " Quarterly (3 mos) Discounted" : "Annual Discounted"))
                                                            }

                                                        </div>
                                                        {/* <div>Payment Success</div> */}

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "20%", textAlign: "right" }}>
                                                        <div>Rs. {discount}</div>

                                                    </div>
                                                </div>

                                            </Grid>

                                            <Grid item xs={12} style={{ padding: "0px 20px" }}>

                                                <Divider variant="middle" />
                                                <div style={{ display: 'flex', width: "100%", padding: "10px 0" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "60%" }}>
                                                        <div style={{ fontWeight: "bold" }}>Total Amount</div>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: "40%", textAlign: "right" }}>
                                                        {/* <div style={{ color: "#0080FF", fontWeight: 600 }}>Rs. 1,825</div> */}
                                                        <div style={{ color: "#0080FF", fontWeight: 600 }}>Rs. {membershipDiscountedAmount}</div>

                                                    </div>

                                                </div>

                                            </Grid>
                                        </Grid>

                                    </div>
                                </div>

                            </>


                        }




                    </div>
                    :
                    <>
                        <CircularProgress style={{ position: "absolute", left: "45%", top: "30%" }} />
                    </>
                )}
                <Hidden lgUp={true}>
                    <PayementMembershipSideBar />
                </Hidden>
            </>
        </React.Fragment>
    );
}

export default PaymentMembership;