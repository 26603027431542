import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ADMIN_POSTS,
  ADMIN_POST_GET_ALL,
  GET_ACCOUNT_LIKED_POSTS,
  GET_ACCOUNT_POSTED_ADS,
  GET_SPOTLIGHTS,
  POSTS,
  POST_CREATE,
  POST_EDIT,
  POST_GET_ALL,
  POST_GET_SINGLE,
} from "../../config/urls";
import { setBusy } from "../navigation/navigationSlice";
import { Post } from "./Post";
import { Spec } from "./Spec";

export const slice = createSlice({
  name: "post",
  initialState: {

    selectedImages: [],
    posts: [] as Post[],
    lastUpdate: new Date().getTime(),
    selectedPost: {} as any,
    feedPage: 0,
    newPost: {
      index: [] as number[],
      step: 1,
      title: "",
      description: "",
      price: "",
      images: [] as string[],
      rawImages: [] as string[],
      imgFolder: "",
      mobile: "+94",
      whatsapp: "+94",
      district: "",
      specs: [] as Spec[],
      hideFooter: "",
      coverImgIndex: 0,
      coverImgName: '',

    },
    saveImage: [] as any,
    myads: [] as Post[],
    likedPosts: [] as Post[],
    editPost: {
      id: 0,
      title: "",
      description: "",
      price: "",
      mobile: "",
      whatsapp: "",
    },
    spotlights: []
  },

  reducers: {
    setFeedPage: (state, action) => {
      state.feedPage = action.payload;
    },
    savePosts: (state, action) => {
      const posts = state.posts
        .concat(action.payload)
        .filter((obj: any, pos: any, arr: any) => {
          return (
            arr.map((mapObj: any) => mapObj["id"]).indexOf(obj["id"]) === pos
          );
        });

      // posts.sort(function (a: any, b: any) {
      //   return b.live_time - a.live_time;
      // });
      state.posts = posts;
      state.lastUpdate = new Date().getTime();
    },
    clearAndSavePosts: (state, action) => {
      state.posts = action.payload;
      state.lastUpdate = new Date().getTime();
    },
    makePostLiked: (state, action) => {
      const id = action.payload;
      state.posts = state.posts = state.posts.map((i: Post) => {
        if (i.id === id) {
          i.liked = true;
          i.like_count += 1;
        }
        return i;
      });
    },
    makeCurrentPostLiked: (state, action) => {
      state.selectedPost = {
        ...state.selectedPost,
        liked: true,
        like_count: state.selectedPost.like_count + 1,
      };
    },
    makePostDisliked: (state, action) => {
      const id = action.payload;
      state.posts = state.posts = state.posts.map((i: Post) => {
        if (i.id === id) {
          i.liked = false;
          i.like_count -= 1;
          state.likedPosts = [...state.likedPosts].filter((l) => l.id !== id);
        }
        return i;
      });
    },
    makeCurrentPostDisliked: (state, action) => {
      state.selectedPost = {
        ...state.selectedPost,
        liked: false,
        like_count: state.selectedPost.like_count - 1,
      };
      state.likedPosts = [...state.likedPosts].filter(
        (l) => l.id !== state.selectedPost.id
      );
    },
    removePostById: (state, action) => {
      const id = action.payload;
      state.posts = state.posts.map((item) => {
        if (item.id === id) item.approval = 2;
        return item;
      });
    },
    setApproval: (state, action) => {
      const id = action.payload;
      state.posts = state.posts.map((item) => {
        if (item.id === id) item.approval = 1;
        return item;
      });
    },

    addPlaceholderToSlider: (state, action) => {
      const images = action.payload;
      state.newPost.images = [...state.newPost.images, images];
    },

    addImagesToBNewPost: (state, action) => {
      const images = action.payload;
      state.newPost.images = [...state.newPost.images, images];

      if (state.newPost.images.length > 10) {
        state.newPost.images = state.newPost.images.slice(0, 10);
      }
    },

    addNewPostTitle: (state, action) => {
      const title = action.payload;
      state.newPost = { ...state.newPost, title: title };
    },
    addNewPostDesciption: (state, action) => {
      const description = action.payload;
      state.newPost = { ...state.newPost, description: description };
    },

    addNewPostPrice: (state, action) => {
      const price = action.payload;
      state.newPost = { ...state.newPost, price: price };
    },
    addNewPostDistrict: (state, action) => {
      const district = action.payload;
      state.newPost = { ...state.newPost, district: district };
    },

    addNewPostRawImages: (state, action) => {
      const url = action.payload;
      state.newPost.rawImages = [...state.newPost.rawImages, url];
      state.newPost.rawImages.sort();
    },
    // addNewPostRawImages: (state, action) => {
    //   const url = action.payload;
    //   state.newPost.rawImages = url;
    // },
    addNewPostMobile: (state, action) => {
      const mobile = action.payload;
      // console.log(mobile);
      state.newPost = { ...state.newPost, mobile: mobile };
    },
    addNewPostWhatsapp: (state, action) => {
      const whatsapp = action.payload;
      state.newPost = { ...state.newPost, whatsapp: whatsapp };
    },
    setPostStep: (state, action) => {
      const step = action.payload;
      state.newPost = { ...state.newPost, step: step };
    },
    setPostIndex: (state, action) => {
      const index = action.payload;
      state.newPost.index = [...state.newPost.index, index];
    },
    setPostSpecs: (state, action) => {
      const spec = action.payload;
      state.newPost.specs = [...state.newPost.specs, spec];
    },
    setCoverImgIndex: (state, action) => {
      // console.log("cover image index is = " + action.payload);
      state.newPost.coverImgIndex = action.payload;
      // alert(state.newPost.coverImgIndex);
      // console.log(state.newPost.coverImgIndex);
    },
    setCoverImgName: (state, action) => {

      state.newPost.coverImgName = action.payload;

    },

    clearNewPost: (state, action) => {
      state.newPost = {
        index: [] as number[],
        step: 1,
        title: "",
        description: "",
        price: "",
        images: [] as string[],
        rawImages: [] as string[],
        imgFolder: "",
        mobile: "+94",
        whatsapp: "+94",
        district: "",
        specs: [],
        hideFooter: "",
        coverImgIndex: 0,
        coverImgName: '',

      };
    },

    clearImgFromSlider: (state, action) => {
      const index = action.payload;
      state.newPost = {
        ...state.newPost,
        images: state.newPost.images.filter(
          (img: any, i: number) => i != index
        ),
      };
    },

    clearAllPosts: (state, action) => {
      state.posts = [];
      state.myads = [];
    },

    clearLikedPosts: (state, action) => {
      state.likedPosts = [];
    },

    // clearCity:(state,action)=>{
    //   state.newPost.district=action.payload;
    // }
    // ,
    setImgFolder: (state, action) => {
      const folder = action.payload;
      state.newPost = { ...state.newPost, imgFolder: folder };
    },
    setSelectedPost: (state, action) => {
      const item = action.payload;
      state.selectedPost = item;
    },
    setSaveImage: (state, action) => {
      state.saveImage = [...state.saveImage, action.payload];
    },
    deleteSaveImage: (state, action) => {
      const index = action.payload;
      state.saveImage = state.saveImage.filter(
        (img: any, i: number) => i != index
      );
    },
    setHideFooter: (state, action) => {
      state.newPost.hideFooter = action.payload;
    },

    setLikedPosts: (state, action) => {
      const posts = state.likedPosts
        .concat(action.payload)
        .filter((obj: any, pos: any, arr: any) => {
          return (
            arr.map((mapObj: any) => mapObj["id"]).indexOf(obj["id"]) === pos
          );
        });
      // posts.sort(function (a: any, b: any) {
      //   return b.live_time - a.live_time;
      // });
      state.likedPosts = posts;
    },

    processing: (state, action) => {
      const proc: boolean = action.payload.processing;
      const post: Post = action.payload.post;
      state.posts = state.posts.map((item) => {
        if (item.id === post.id) item.processing = proc;
        return item;
      });
    },

    saveMyAds: (state, action) => {
      const posts = action.payload
        .concat(state.myads)
        .filter((obj: any, pos: any, arr: any) => {
          return (
            arr.map((mapObj: any) => mapObj["id"]).indexOf(obj["id"]) === pos
          );
        });

      posts.sort(function (a: any, b: any) {
        return b.created_at - a.created_at;
      });
      state.myads = posts;
    },
    addEditPostId: (state, action) => {
      const id = action.payload;
      state.editPost = { ...state.editPost, id: id };
    },

    addEditPostTitle: (state, action) => {
      const title = action.payload;
      state.editPost = { ...state.editPost, title: title };
    },
    addEditPostDesciption: (state, action) => {
      const description = action.payload;
      state.editPost = { ...state.editPost, description: description };
    },

    addEditPostPrice: (state, action) => {
      const price = action.payload;
      state.editPost = { ...state.editPost, price: price };
    },
    addEditPostMobile: (state, action) => {
      const mobile = action.payload;
      state.editPost = { ...state.editPost, mobile: mobile };
    },
    addEditPostWhatsapp: (state, action) => {
      const whatsapp = action.payload;
      state.editPost = { ...state.editPost, whatsapp: whatsapp };
    },

    setSpotlight: (state, action) => {
      const spotlights = action.payload;
      state.spotlights = spotlights;
    },
    setSelectedImages: (state, action) => {
      const images = action.payload;



    },
    clearImages: (state, action) => {
      state.newPost.images = action.payload;
    }

  },
});

export const {
  addEditPostId,
  addEditPostTitle,
  addEditPostDesciption,
  addEditPostPrice,
  addEditPostMobile,
  addEditPostWhatsapp,
  setHideFooter,
  setLikedPosts,
  setPostSpecs,
  addNewPostDistrict,
  deleteSaveImage,
  setSaveImage,
  setPostIndex,
  setPostStep,
  addNewPostMobile,
  addNewPostWhatsapp,
  clearImgFromSlider,
  addPlaceholderToSlider,
  savePosts,
  setImgFolder,
  clearNewPost,
  makePostLiked,
  makePostDisliked,
  removePostById,
  setApproval,
  makeCurrentPostLiked,
  makeCurrentPostDisliked,
  clearAllPosts,
  processing,
  addImagesToBNewPost,
  clearAndSavePosts,
  addNewPostTitle,
  addNewPostDesciption,
  addNewPostPrice,
  addNewPostRawImages,
  setSelectedPost,
  saveMyAds,
  setCoverImgIndex,
  setCoverImgName,
  setFeedPage,
  clearLikedPosts,
  setSpotlight,
  setSelectedImages,
  clearImages
} = slice.actions;



export const getAllAvailablePosts =
  (cleaner: Function, all: boolean, lastUpdate: number, page: number = 0) =>
    (dispatch: any) => {


      axios.get(GET_SPOTLIGHTS).then(
        (d) => {
          dispatch(setSpotlight(d.data));
        }

      ).catch(e => console.log(e));



      const lastUpdatedTime = all ? 0 : lastUpdate;
      axios
        .get(POST_GET_ALL, {
          params: { "last-updated-time": lastUpdatedTime, page: page },
        })
        .then((result) => {
          if (result.status === 200) {

            dispatch(savePosts(result.data));
          }
        })
        .finally(() => cleaner());


    };

export const getSelectedPost = (id: any) => (dispatch: any) => {
  dispatch(setBusy(true));
  axios
    .get(POST_GET_SINGLE + id)
    .then((result) => {
      if (result.status === 200) {
        dispatch(setSelectedPost(result.data));
      }
    })
    .finally(() => dispatch(setBusy(false)));
};

export const getAccountLikedPosts =
  (page: number = 0, completed: any) =>
    (dispatch: any) => {
      // dispatch(setBusy(true));
      axios
        .get(GET_ACCOUNT_LIKED_POSTS, {
          params: { page: page },
        })
        .then((result) => {

          dispatch(setLikedPosts(result.data))
        })
        .catch((err) => { })
        .finally(() => {
          completed();
          // dispatch(setBusy(false));
        });
    };

export const getMyAds =
  (page: number = 0, completed: any) =>
    (dispatch: any) => {
      dispatch(setBusy(true));
      axios
        .get(GET_ACCOUNT_POSTED_ADS, { params: { page: page } })
        .then((result) => dispatch(saveMyAds(result.data)))
        .catch((err) => { })
        .finally(() => {
          dispatch(setBusy(false));
          completed();
        });
    };

export const sendPostLike =
  (id: number, callback: Function) => (dispatch: any) => {
    axios
      .post(POSTS + `/${id}/likes`, {})
      .then((response: any) => {
        dispatch(makePostLiked(id));
      })
      .finally(() => callback());
  };

export const sendPostDislike =
  (id: number, callback: Function) => (dispatch: any) => {
    axios
      .delete(POSTS + `/${id}/likes`, {})
      .then((response: any) => {
        dispatch(makePostDisliked(id));
      })
      .finally(() => callback());
  };

export const sendPostClick = (id: number) => (dispatch: any) => {
  axios.post(POSTS + `/${id}/clicks`, {}).then(() => { });
};

export const rejectPostByAdmin = (id: number) => (dispatch: any) => {
  axios.put(`${ADMIN_POSTS}/${id}/approval/0`, {}).then((response) => {
    if (response.data.response) {
      dispatch(removePostById(id));
    }
  });
};

export const acceptPostByAdmin = (id: number) => (dispatch: any) => {
  axios.put(`${ADMIN_POSTS}/${id}/approval/1`, {}).then((response) => {
    if (response.data.response) {
      dispatch(setApproval(id));
    }
  });
};
function st() {
  alert("Please remain until the images upload");

}
export const pushPost =
  (post: any, success: Function, error: Function, final: Function) =>
    (dispatch: any) => {
      dispatch(setBusy(true));

      if (post.rawImages.length == post.images.length) {


        axios
          .post(POST_CREATE, {
            images: post.rawImages,
            title: post.title,
            description: post.description,
            price: post.price,
            mobile: post.mobile,
            img_folder: post.imgFolder,
            whatsapp: post.whatsapp,
            district: post.district,
            specs: post.specs,
            cover_index: post.coverImgIndex,
          })
          .then((respData: any) => {
            // console.log(">>>>>>" + JSON.stringify(respData));

            dispatch(clearNewPost({}));
            if (respData !== undefined) {
              success(respData);
            }
          }).catch((er) => console.log(er))
          .finally(() => {
            dispatch(setBusy(false));
            final();
          });
      } else {
        st();
      }

    };
export const pushPostWithoutPayment =
  (post: any, success: Function, error: Function, final: Function) =>
    (dispatch: any) => {
      dispatch(setBusy(true));
      axios
        .post(POST_CREATE, {
          images: post.rawImages,
          title: post.title,
          description: post.description,
          price: post.price,
          mobile: post.mobile,
          img_folder: post.imgFolder,
          whatsapp: post.whatsapp,
          district: post.district,
          specs: post.specs,
          cover_index: post.coverImgIndex,
        })
        .then((data: any) => {
          dispatch(clearNewPost({}));
          success();
        })
        .catch((er) => error(er.response.data))
        .finally(() => {
          dispatch(setBusy(false));
          final();
        });
    };

var newImageArray = [] as any;
export const EditPost =
  (post: any, images: any, hash: any, success: Function, error: Function, final: Function) =>
    (dispatch: any) => {

      images.forEach((element: any) => {

        newImageArray.push({
          imageUrl: element,
          status: 0
        })
      });

      axios
        .post(POST_EDIT,

          {
            seoTag: hash,
            title: post.title,
            description: post.description,
            mobile: post.mobile,
            whatsapp: post.whatsapp,
            city: post.district,
            coverIndex: post.coverImgIndex,
            amount: post.price,
            images: newImageArray,
            props: post.specs
            // {
            //     "propertyName":"Prop Name",
            //     "propertyValue":"Prop Value",
            //     "propertyStatus":0
            // }

          }



        )
        .then(() => {
          // success();
          dispatch(clearNewPost({}));
        })
        .catch((er) => error(er.response.data))
        .finally(() => {
          final();
        });
    };

// export const EditPost =
//   (post: any, hash: any, success: Function, error: Function, final: Function) =>
//     (dispatch: any) => {
//       axios
//         .put(POST_EDIT + hash, {
//           hash: hash,
//           title: post.title,
//           description: post.description,
//           price: post.price,
//           mobile: post.mobile,
//           whatsapp: post.whatsapp,
//         })
//         .then(() => {
//           success();
//           dispatch(clearNewPost({}));
//         })
//         .catch((er) => error(er.response.data))
//         .finally(() => {
//           final();
//         });
//     };

export const selectPosts = (state: any) => state.post.posts;
export const selectNewPosts = (state: any) => state.post.newPost;
export const selectLastUpdateTime = (state: any) => state.post.lastUpdate;
export const selectNewPostRawImages = (state: any) =>
  state.post.newPost.rawImages;
export const selectedPost = (state: any) => state.post.selectedPost;
export const postStep = (state: any) => state.post.newPost.step;
export const savedImages = (state: any) => state.post.saveImage;
export const postDistrict = (state: any) => state.post.newPost.district;
export const postSpecs = (state: any) => state.post.newPost.specs;
export const selectMyAds = (state: any) => state.post.myads;
export const likedPosts = (state: any) => state.post.likedPosts;
export const editPost = (state: any) => state.post.editPost;
export const selectCoverImgIndex = (state: any) => state.post.newPost.coverImgIndex;
export const selectCoverImgName = (state: any) => state.post.newPost.coverImgName;
export const selectFeedPage = (state: any) => state.post.feedPage;
export const selectSpotlights = (state: any) => state.post.spotlights;
export const selectSelectedImages = (state: any) => state.selectedImages;

export default slice.reducer;
