// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  #addpost-slide{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
.image-number-sc{
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

#img-count-sc{
  position: absolute;
  left: 15px;  
  background-color: rgba(0, 0, 0, 0.67);
  border-radius: 11px;
  width: 42px;
  height: 22px;
  bottom: 14px;
}

#coverimg-info{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#coverimg-info-txt{
  padding: 20px;
  font-size: 18px;
}
  
`, "",{"version":3,"sources":["webpack://./src/components/AddPost/Images/AddImages.css"],"names":[],"mappings":";;EAEE;IACE,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;EAC9B;AACF;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,qCAAqC;EACrC,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":["\n\n  #addpost-slide{\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n  }\n.image-number-sc{\n  font-size: 12px;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n#img-count-sc{\n  position: absolute;\n  left: 15px;  \n  background-color: rgba(0, 0, 0, 0.67);\n  border-radius: 11px;\n  width: 42px;\n  height: 22px;\n  bottom: 14px;\n}\n\n#coverimg-info{\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n#coverimg-info-txt{\n  padding: 20px;\n  font-size: 18px;\n}\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
