import { logEvent } from "firebase/analytics"

export const LOGIN = 'login';
export const LOGOUT = "logout";
export const SIGNUP = 'signup';

export const CLICK_ON_POST_IMAGE = "click_on_post_image";

export const VIEW_POST_DETAIL = "view_post_detail";

export const VIEW_LANDING_PAGE = "view_landing_page";
export const VIEW_HOME_PAGE = "view_home_page";
export const VIEW_SEARCH_PAGE = "view_search_page";
export const VIEW_MYADS_PAGE = "view_myads_page";
export const VIEW_MYMEMEBERSHIP_PAGE = "view_mymembership_page";
export const VIEW_MYLIKES_PAGE = "view_mylikes_page";
export const VIEW_PROFILE_PAGE = "view_profile_page";
export const VIEW_SELLER_PAGE = "view_seller_page";
export const VIEW_MEMBERSHIP_PAGE = "view_membership_page";

export const LIKE_POST = "like_post";
export const SHARE = 'share';
export const REMOVE_POST_LIKE = "remove_post_like";
export const SHARE_POST = "share_post";
export const SHARE_POST_CANCEL = "share_post_cancel";
export const MAKE_CALL = "make_call";
export const SEND_MESSAGE = "send_message";

// add post funnel
export const CLICK_NEW_AD = "click_new_ad";
export const CHOOSE_CATEGORY = "choose_category";
export const CHOOSE_SUB_CATEGORY = "choose_sub_category";
export const INPUT_IMAGES = "input_images";
export const INPUT_DETAILS = "input_details";
export const INPUT_CONTACT = "input_contact";
export const SUBMIT_NEW_AD = "submit_new_ad";

// search funnel
export const SEARCH = 'search';
export const SELECT_TAG = "select_tag";
export const KEYWORD_SEARCH_ALL = "keyword_search_all";
export const VIEW_SEARCH_RESULTS = "view search result";
export const KEYWORD_SEARCH_WITH_TAG = "keyword_search_with_tag";
export const ADD_FILTER = "add_filter";
export const SORT_RESULT = "sort_result";

// banner
export const CLICK_ON_HERO_BANNER = "click_on_hero_banner";
export const CLICK_ON_DEALS_BANNER = "click_on_deals_banner";
export const CLICK_ON_LEFT_BANNER = "click_on_left_banner";
export const CLICK_ON_FOOTER_BANNER = "click_on_footer_banner";
export const CLICK_ON_DETAIL_PAGE_BANNER = "click_on_detail_page_banner";
export const CLICK_ON_SIDEBAR_BANNER = "click_on_sidebar_banner";
