import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "scroll",
  initialState: {
    scroll : false,
    position : 0,
  },

  reducers: {
    setScroll : (state, action) => {
      state.scroll = action.payload;
      
    },
    setPosition : (state, action) => {
      state.position = action.payload;   
      
    },
    resetPosition : (state, action) => {
      state.position = 0;
    }

  },
});

export const {setScroll, setPosition, resetPosition} = slice.actions;

export const selectScroll = (state: any) => state.scroll.scroll;
export const selectPosition = (state: any) => state.scroll.position;

export default slice.reducer;