// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #account-list-header-wrapper{
    margin-bottom:10px;
} */

#account-list-header-wrapper > h5{
    font-weight: 700;
    font-size: 15px;
}

#account-list-header-wrapper .pre-post-bar{
    background-color: #0080ff;
    color: #fff;
    padding:15px 20px;
    font-size: 13px;
}
#account-list-header-wrapper .pre-post-bar-seller{
    background-color: #0080ff;
    color: #fff;
    padding:15px 20px;
    font-size: 13px;
    margin-bottom: 22px;
}
#account-header-filter{
    height: 45px;
    background-color: #EBEEF1;
    width: 100%;
    margin-bottom: 26px;
    border-bottom: 2px solid #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#text-filter{
    font-size: 12px;
    color: #5A5A5A;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
}

#switch-wrapper{
    border-right: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/ProfilePage.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;IAC/B,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":["/* #account-list-header-wrapper{\n    margin-bottom:10px;\n} */\n\n#account-list-header-wrapper > h5{\n    font-weight: 700;\n    font-size: 15px;\n}\n\n#account-list-header-wrapper .pre-post-bar{\n    background-color: #0080ff;\n    color: #fff;\n    padding:15px 20px;\n    font-size: 13px;\n}\n#account-list-header-wrapper .pre-post-bar-seller{\n    background-color: #0080ff;\n    color: #fff;\n    padding:15px 20px;\n    font-size: 13px;\n    margin-bottom: 22px;\n}\n#account-header-filter{\n    height: 45px;\n    background-color: #EBEEF1;\n    width: 100%;\n    margin-bottom: 26px;\n    border-bottom: 2px solid #DDDDDD;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n#text-filter{\n    font-size: 12px;\n    color: #5A5A5A;\n    font-weight: 600;\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n#switch-wrapper{\n    border-right: 1px solid #DDDDDD;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
