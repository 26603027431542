// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#likeicon{
    height: 22px;
    width: 22px;
    margin-top: 1px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/LikeButton/LikeButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,eAAe;AACnB","sourcesContent":["#likeicon{\n    height: 22px;\n    width: 22px;\n    margin-top: 1px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
