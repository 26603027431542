// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .search-tag-section-wrapper{
    padding-top: 10px;
} */

.search-tag-section-wrapper > header{
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-top: 11px;
    border: 1px solid #DDDDDD;
}

.search-tag-section-title{
    margin-left: 10px;
    color:#000;
    font-size: 14px;
    font-weight: bold;
}

.search-tag-section-icon{
    height:18px;
    width: 18px;
}

.search-tag-section-body-wrapper{
    padding-top: 6px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #DDDDDD;
}

.wider-icon{
    width:23px;
    height: 16px;
}

.tag-selected{
    background: #00AEFF !important;
    color:#fff !important;
}

.tag-name{
    font-size: 1.1em;
    font-weight: 600;
    padding: 0 10px;
}

#tag-title-header{
    width: 100%;
    padding-bottom: 7px;
    border-bottom: 2px solid #0080FF;
    font-size: 15px;
    color: #000000;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/SearchPage/SearchTagCloud/SearchTagCloud.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,gCAAgC;IAChC,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["/* .search-tag-section-wrapper{\n    padding-top: 10px;\n} */\n\n.search-tag-section-wrapper > header{\n    padding-bottom: 11px;\n    display: flex;\n    align-items: center;\n    background-color: #fff;\n    padding-top: 11px;\n    border: 1px solid #DDDDDD;\n}\n\n.search-tag-section-title{\n    margin-left: 10px;\n    color:#000;\n    font-size: 14px;\n    font-weight: bold;\n}\n\n.search-tag-section-icon{\n    height:18px;\n    width: 18px;\n}\n\n.search-tag-section-body-wrapper{\n    padding-top: 6px;\n    padding-bottom: 5px;\n    display: flex;\n    flex-wrap: wrap;\n    border-bottom: 1px solid #DDDDDD;\n}\n\n.wider-icon{\n    width:23px;\n    height: 16px;\n}\n\n.tag-selected{\n    background: #00AEFF !important;\n    color:#fff !important;\n}\n\n.tag-name{\n    font-size: 1.1em;\n    font-weight: 600;\n    padding: 0 10px;\n}\n\n#tag-title-header{\n    width: 100%;\n    padding-bottom: 7px;\n    border-bottom: 2px solid #0080FF;\n    font-size: 15px;\n    color: #000000;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
