import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "filter",
  initialState: {
    price: {},
    mileage: {},
    engineCapacity: {},
    landArea: {},
    floorArea: {},
    bedroom: {},
    parking: {},
    bathroom: {},
    propertyPerches: {},
  },

  reducers: {
    savePrice: (state, action) => {
      state.price = action.payload;
    },
    saveMileage: (state, action) => {
      state.mileage = action.payload;
    },
    saveEngineCapacity: (state, action) => {
      state.engineCapacity = {'min':action.payload, 'max':action.payload};
    },
    saveLandArea: (state, action) => {
      state.landArea = action.payload;
    },
    saveFloorArea: (state, action) => {
      state.floorArea = action.payload;
    },
    savePropertyPerches: (state, action) => {
      state.propertyPerches= action.payload;
    },
    saveBedroom: (state, action) => {
      state.bedroom = {'min':action.payload, 'max':action.payload};
    },
    saveBathroom: (state, action) => {
      state.bathroom = {'min':action.payload, 'max': action.payload};
    },
    saveParking: (state, action) => {
      state.parking = {'min':action.payload, 'max':action.payload};
    },
    clearFilters : (state, action) => {
      state.mileage = {};
      state.engineCapacity = {};
      state.landArea={};
      state.price = {};
      state.parking = {};
      state.landArea = {};
      state.bathroom = {};
      state.bedroom = {};
      state.propertyPerches = {};
    },
  },
});

export const {
  savePrice,
  saveMileage,
  saveEngineCapacity,
  saveLandArea,
  saveFloorArea,
  saveBedroom,
  saveBathroom,
  saveParking,
  clearFilters,
  savePropertyPerches,
} = slice.actions;


export const selectPrice = (state: any) => state.filter.price;
export const selectMileage = (state: any) => state.filter.mileage;
export const selectEngineCapacity = (state: any) => state.filter.engineCapacity;
export const selectLandArea = (state: any) => state.filter.landArea;
export const selectFloorArea = (state: any) => state.filter.floorArea;
export const selectBedroom = (state: any) => state.filter.bedroom;
export const selectBathroom = (state: any) => state.filter.bathroom;
export const selectParking = (state: any) => state.filter.parking;
export const selectPropertyPerches = (state: any) => state.filter.propertyPerches;

export default slice.reducer;
