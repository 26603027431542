import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { logEvent } from "firebase/analytics"
import { NAV_MEMBERSHIPS } from "../../providers/NavigationProvider";
import { VIEW_MEMBERSHIP_PAGE } from "../../config/events";
import { ACTION_VIEW } from "../../config/actions";
import { returnUpBackOutline } from "ionicons/icons";
import { Avatar, Button, Grid, Hidden, MenuItem, TextField, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import AdPromotionTabs from "./AdPromotionsTabs";
import AdPromotionTabsH from "./AdPromotionsTabsHorizontal";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            color: "#000000",
            fontSize: "32px",
            fontWeight: 600
        },
        headingParagraph: {
            color: "#5A5A5A",
            margingTop: "20px"
        },
        headingButton: {
            backgroundColor: "#0080FF",
            color: "#FFFFFF",
            borderRadius: "8px",
            padding: "15px 35px 15px 35px",
            fontSize: "16px",
            width: "100%",
            marginTop: "20px",




        },
        headingImage: {

        },
        benefitPanel: {
            backgroundImage: "url(../assets/images/shadow-away.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "150px",
        },
        benefitPanelBackground: {
            backgroundImage: "linear-gradient(180deg, #fcfcfc, #ffffff)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
        },
        benefictHeadings: {
            color: "#000000",
            fontSize: "17px",
            fontWeight: 600
        },
        benefictDetails: {
            color: "#5A5A5A",
            fontSize: "14px"
        },
        benefictImages: {
            maxWidth: "70px",
            maxHeight: "65px",
            minWidth: "60px",
            minHeight: "55px",
        },
        callBackImage: {
            maxWidth: "158px",
            maxHeight: "152px"
        },
        callBackPanelIntro: {
            color: "#0080FF",
            fontSize: "24px"
        },
        callBackPanel: {
            backgroundImage: "url(../assets/images/rounded_rectangle_1260x330.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            marginBottom: "20px"

        },
        callBackInnerPanel: {
            padding: "10px 10px"

        },
        contactFields: {
            width: "100%"
        },
        contactSubmit: {
            width: "100%",
            backgroundColor: "#0080FF",
            color: "#FFFFFF",
            borderRadius: "6px",
            float: "right"
        }
    })
);

export function AdPromotions() {

    const [requestEligible, setRequestEligible] = useState(false);
    const dispatch = useDispatch();
    const scrollContent = document.getElementById('scrollable-root');

    const [page, setPage] = useState(1);
    const classes = useStyles();



    useEffect(() => {
        setRequestEligible(true);
        dispatch(setCurrentLocation(NAV_MEMBERSHIPS));
        scrollContent?.scrollTo(0, 0);
        logEvent(analytics, VIEW_MEMBERSHIP_PAGE, { action: ACTION_VIEW });
        return () => {
            setRequestEligible(false);
            setPage(1);
        };
    }, []);

    return (
        <>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container >
                        <Hidden lgUp={true} mdUp={true}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <img className={classes.headingImage} src="../assets/images/promotions-slider-img-442x340px.png" />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <Grid container>
                                {/* style={{ backgroundImage: "url(../assets/images/header_bg-img.png)", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", }} */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className={classes.heading}>Drive more traffic and Promote your Ad with Saruwata.lk</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <p className={classes.headingParagraph}>
                                        Sell your items quickly at the best price by making your ads stand out on Saruwata.lk - The new marketplace for modern Sri Lankans!
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <p className={classes.headingParagraph}>
                                        While it's free to post ads on Saruwata.lk, Ad Promotions is a paid toolthat gets you more responses on your ads and helps you sell faster.  </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <a href="#form"> <button className={classes.headingButton}>PROMOT YOUR AD</button></a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden xsDown={true} smDown={true}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <img style={{ width: "400px", marginLeft: "150px" }} className={classes.headingImage} src="../assets/images/promotions-slider-img-442x340px.png" />
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Hidden lgUp={true} mdUp={true}>
                        <AdPromotionTabsH />
                    </Hidden>
                    <Hidden xsDown={true} smDown={true}>
                        <AdPromotionTabs />

                    </Hidden>
                </Grid>
            </Grid>
            <div style={{ height: "30px" }}></div>
        </>

    )
}