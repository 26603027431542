import { createStyles, FormControlLabel, Grid, makeStyles, Switch, TextField, Theme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { walkOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../config/locations";
import {
  selectedCategories,
  selectLocation,
  getBrandsByCategory,
  getCities,
  selectCity,
  selectDistrict,
  getDistricts,
} from "../../../../../features/addPost/addPostSlice";
import { currencyConverter } from "../../../../../features/helper";
import { selectLanguage } from "../../../../../features/language/language_slice";
import {
  selectNewPosts,
  addNewPostTitle,
  addNewPostPrice,
  addNewPostDistrict,
  setHideFooter,
  addNewPostDesciption,

} from "../../../../../features/post/postSlice";
import { Language } from "../../../../../language/Language";
import ListSelectModal from "../../Modals/ListSelectModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switch: {
      marginRight: '4px'
    },
  })
);

const FillDetailsRoomForRentMobile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selctedCat = useSelector(selectedCategories);
  const location = useSelector(selectLocation);
  const newPost = useSelector(selectNewPosts);
  const locs = useSelector(selectLocations);


  const [fillDetailsDisable, setFillDetailsDisable] = useState(true);

  const [values, setValues] = useState({} as any);
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const cities = useSelector(selectCity);
  const district = useSelector(selectDistrict);
  const [sugesstedTitile, setsugesstedTitile] = useState({} as any);

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  useEffect(() => {
    dispatch(getDistricts());
    setFillDetailsDisable(false);
  }, []);

  useEffect(() => {
    const subcat = sugesstedTitile?.subcat ? sugesstedTitile.subcat : '';
    const location = sugesstedTitile?.location ? sugesstedTitile.location : '';

    const title = subcat + ' in ' + location;
    dispatch(addNewPostTitle(title));

  }, [sugesstedTitile]);

  useEffect(() => {
    dispatch(getBrandsByCategory(selctedCat.level2.id));
    setsugesstedTitile((v: any) => {
      return { ...v, subcat: 'Room for rent' };
    });
  }, []);

  const onDistrictSelect = (location: any) => {
    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });
  };
  const onCitySelect = (location: any) => {
    setValues((v: any) => {
      return { ...v, city: location };
    });
    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

    setsugesstedTitile((v: any) => {
      // return { ...v, location: location.name.split('-')[0].charAt(0).toUpperCase() + location.name.split('-')[0].slice(1).toLowerCase() };
      return { ...v, location: isSavedDistrict() ? location.split('-')[0].charAt(0).toUpperCase() + location.split('-')[0].slice(1).toLowerCase() : location.name.split('-')[0].charAt(0).toUpperCase() + location.name.split('-')[0].slice(1).toLowerCase() };

    });
  };
  const isSavedDistrict = () => {

    if (Object.keys(locs).length > 0) {
      return true;
    }
    return false;
  }


  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} className="filldetails-grid-item" >
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="district"
            options={district}
            disableClearable
            onChange={(event: any, district: any) => {
              onDistrictSelect(district);

            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="city"
            options={cities}
            disableClearable
            disabled={!values.district}

            onChange={(event: any, location: any) => {
              onCitySelect(location);
            }}
            getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="district"
                label={lan.CITY}
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item" style={{ width: "100%" }}>
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-furnished-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <div className='switch-wrapper'>
            <div className='switch-text'>{lan.FURNISHED}</div>
            <Switch
              checked={checked || false}
              onChange={(e: any) => setChecked(e.target.checked)}
              name="Property_Furnished"
              color="primary"
              className={classes.switch}
            />
          </div>
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item" style={{ width: "100%" }}>
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-private-enter-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <div className='switch-wrapper'>
            <div className='switch-text'>Private Entrance</div>
            <Switch
              checked={checked1 || false}
              onChange={(e: any) => setChecked1(e.target.checked)}
              name="Property_Private_Entrance"
              color="primary"
              className={classes.switch}
            />
          </div>
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item" style={{ width: "100%" }}>
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-bathrooms-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <div className='switch-wrapper'>
            <div className='switch-text'>Private Bathroom</div>
            <Switch
              checked={checked2 || false}
              onChange={(e: any) => setChecked2(e.target.checked)}
              name="Property_Private_Bathroom"
              color="primary"
              className={classes.switch}
            />
          </div>
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item" style={{ width: "100%" }}>
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-car-parking-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <div className='switch-wrapper'>
            <div className='switch-text'>Parking Space</div>
            <Switch
              checked={checked3 || false}
              onChange={(e: any) => setChecked3(e.target.checked)}
              name="Property_Parking_Space"
              color="primary"
              className={classes.switch}
            />
          </div>
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-fullwidth-icon-wrapper">
            <img src="assets/addpost/icon-title-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="title"
            label={lan.TITTLE}
            placeholder={lan.MAX_72_CHARACTERS}
            name="title"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: 72 }}
            variant="outlined"
            value={newPost.title || ""}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            // label="Description"
            label={lan.DESCRIPTION}
            multiline
            placeholder={lan.MAX_4000_CHARACTERS}
            name="description"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.description || ""}
            inputProps={{ maxLength: 4000 }}
            style={{ width: "100%" }}
            onChange={(e: any) =>
              dispatch(addNewPostDesciption(e.target.value))
            }
            onInput={(e: any) => dispatch(addNewPostDesciption(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-price-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="price"
            label={lan.PRICE}
            placeholder="2500000"
            name="price"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.price || ""}
            inputProps={{ pattern: "^\\d{1,12}(\\.\\d{1,2})?$", maxLength: 15 }}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            required
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          {
            (newPost.price == 0) ?
              <span className="price" >{lan.ASK_FOR_PRICE}</span>
              :
              <div color="primary" className="price">
                <span className="ru-in-price">{lan.RS}. </span>{" "}
                {newPost?.price === "" || !isNaN(newPost?.price)
                  ? currencyConverter().format(newPost?.price)
                  : "Invalid"}
              </div>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default FillDetailsRoomForRentMobile;
