import { Container, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { logEvent } from "firebase/analytics"
import { ACTION_CLICK } from "../../config/actions";
import {
  CLICK_ON_HERO_BANNER,
  CLICK_ON_DEALS_BANNER,
} from "../../config/events";
import { land_sale } from "../../constants/categories";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";


const HeroSection = (props: any) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));

  const [c1, setC1] = useState("");

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    setC1(
      url
        .get("c1")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || ""
    );
  }, [window.location.search]);

  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };





  const handleHeroBannerClick = () => {
    logEvent(analytics, CLICK_ON_HERO_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };

  const handleDealsBannerClick = () => {
    logEvent(analytics, CLICK_ON_DEALS_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };



  return (
    <Hidden xsDown={true} smDown={true}>
      <div style={{ backgroundColor: '#FFF', padding: '0 10px' }}>
        <div
          style={{
            height: `${!smallViewPort ? "auto" : "250px"}`,
            // marginTop: "30px",
            // marginBottom: "30px",

            maxWidth: "1272px",
            marginLeft: "auto",
            marginRight: "auto",

          }}
        >
          <Container
            maxWidth="lg"
            style={{
              display: "flex",
              justifyContent: `${!smallViewPort ? "center" : ""}`,
              alignItems: "center",
              padding: '10px 10px',
              backgroundColor: 'white'
            }}
          >
            <div style={{ marginRight: "40px" }} onClick={handleHeroBannerClick}>
              {isMotor() ? (

                <iframe scrolling="no"
                  style={{ width: "970px", height: "250px", overflowX: "hidden", border: "none" }}
                  src="https://services.saruwata.lk/AdManager/L1/L1-Motors-Desktop-Billboard-970x250/index.html" />

              ) : isProperty() ? (

                <iframe scrolling="no"
                  style={{ width: "970px", height: "250px", overflowX: "hidden", border: "none" }}
                  src="https://services.saruwata.lk/AdManager/L1/L1-Property-Sale-Desktop-Billboard-970x250/index.html" />


              ) : isPropertyRent() ?


                <iframe scrolling="no"
                  style={{ width: "970px", height: "250px", overflowX: "hidden", border: "none" }}
                  src="https://services.saruwata.lk/AdManager/L1/L1-Property-Rent-Desktop-Billboard-970x250/index.html" />

                : isElectronic() ?

                  <iframe scrolling="no"
                    style={{ width: "970px", height: "250px", overflowX: "hidden", border: "none" }}
                    src="https://services.saruwata.lk/AdManager/L1/L1-Mobiles-and-Electronics-Desktop-Billboard-970x250/index.html" />


                  : isClassified() ?

                    <iframe scrolling="no"
                      loading='lazy'
                      style={{ width: "970px", height: "250px", overflowX: "hidden", border: "none" }}
                      src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Desktop-Billboard-970x250/index.html" />

                    : (


                      <iframe scrolling="no"
                        style={{ width: "970px", height: "250px", overflowX: "hidden", border: "none" }}
                        src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Billboard-970x250/index.html" />




                    )}
            </div>
            {smallViewPort && (
              <div onClick={handleDealsBannerClick}>
                <img
                  src="assets/walls/Membership-Banner-4.gif"
                  style={{ width: "250px", height: "250px", cursor: "pointer" }}
                  alt=""
                  onClick={() => navigate("/membership")}
                ></img>
              </div>

            )}
          </Container>
        </div>
      </div>
    </Hidden>
  );
};

export default HeroSection;
