import { IonItem, IonSkeletonText, IonThumbnail } from "@ionic/react";
import React from "react";

const PostSkelton = (props: any) => {
  return (
    <IonItem style={{ display: "flex", flexDirection: "column" }}>
      <IonThumbnail style={{ height: "300px", width: "100%" }}>
        <IonSkeletonText animated />
      </IonThumbnail>
    </IonItem>
  );
};

export default PostSkelton;
