import {
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../../config/locations";
import {
  getBrandsByCategory,
  getCities,
  getDistricts,
  getModelsByBrand,
  getYears,
  selectBrands,
  selectCity,
  selectDistrict,
  selectedCategories,
  selectLocation,
  selectModels,
  selectYears,
} from "../../../../../../features/addPost/addPostSlice";
import { currencyConverter } from "../../../../../../features/helper";
import { selectLanguage } from "../../../../../../features/language/language_slice";
import {
  addNewPostDesciption,
  addNewPostDistrict,
  addNewPostPrice,
  addNewPostTitle,

  selectNewPosts,
  setHideFooter,
} from "../../../../../../features/post/postSlice";
import { Language } from "../../../../../../language/Language";
import ListSelectModal from "../../../Modals/ListSelectModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderColor: {
      "& .MuiInput-underline:before": {
        borderBottom: "1px solid #DDDDDD",
      },
    },
    lineRadio: {
      "& .MuiFormGroup-root": {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row !important',
        marginLeft: '8px'
      },
      "& .MuiFormControlLabel-root": {
        marginRight: '30px'
      },
      "& .MuiButtonBase-root.MuiRadio-root": {
        padding: '5px 7px 7px 0 !important'
      }
    }
  })
);

const FillDetailsBikes = () => {
  const classes = useStyles();
  const [showListModal, setShowListModal] = useState({
    status: false,
    placeholder: "",
    list: [],
    ref: "",
  });
  const dispatch = useDispatch();
  const selctedCat = useSelector(selectedCategories);
  const brands = useSelector(selectBrands);
  const models = useSelector(selectModels);
  const location = useSelector(selectLocation);
  const years = useSelector(selectYears);
  const newPost = useSelector(selectNewPosts);
  const locs = useSelector(selectLocations);


  const [fillDetailsDisable, setFillDetailsDisable] = useState(true);

  const [values, setValues] = useState({} as any);

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;


  const condition: any = [
    { id: 0, name: "Brand New", label: lan.BRAND_NEW },
    { id: 1, name: "Registered", label: lan.REGISTERED },
    { id: 2, name: "Unregistered", label: lan.UNREGISTERED },
  ];

  const [year, setYear] = useState();
  const [selectedCondition, setSelectedCondition] = useState();

  const [mileage, setMileage] = useState<any>(0);
  const [engCap, setEngCap] = useState<any>(0);
  const [brandValidate, setBrandValidate] = useState(false);
  const [modelValidate, setModelValidate] = useState(false);
  const [dateValidate, setDateValidate] = useState(false);
  const [conditionValidate, setConditionValidate] = useState(false);
  const [engCapValidate, setEngCapValidate] = useState(false);

  const cities = useSelector(selectCity);
  const district = useSelector(selectDistrict);
  const [sugesstedTitile, setsugesstedTitile] = useState({} as any);


  const openLocation = () => {
    setShowListModal({
      list: location,
      placeholder: "Select Location",
      status: true,
      ref: "location",
    });
  };
  const openModel = () => {
    setShowListModal({
      list: models,
      placeholder: "Select Model",
      status: true,
      ref: "model",
    });
  };

  const openBrand = () => {
    setShowListModal({
      list: brands,
      placeholder: "Select Brand",
      status: true,
      ref: "brand",
    });
  };

  useEffect(() => {
    dispatch(getBrandsByCategory(selctedCat.level2.id));
    dispatch(getYears());
    dispatch(getDistricts());
  }, []);

  useEffect(() => {
    const brand = sugesstedTitile?.brand ? sugesstedTitile.brand : '';
    const model = sugesstedTitile?.model ? sugesstedTitile.model : '';
    const year = sugesstedTitile?.year ? sugesstedTitile.year : '';

    if (model?.split(' ')?.length > 1 && brand == model.split(' ')[0]) {
      const title = brand + ' ' + model.split(' ')[1] + ' ' + year;
      dispatch(addNewPostTitle(title));

    } else {
      const title = brand + ' ' + model + ' ' + year;
      dispatch(addNewPostTitle(title));
    }

  }, [sugesstedTitile]);

  const onBrandSelect = (brand: any) => {
    setValues((v: any) => {
      return { ...v, brand: brand };
    });
    dispatch(getModelsByBrand(brand.id, selctedCat.level2.id));
    setFillDetailsDisable(false);
    setValues((v: any) => {
      return { ...v, model: "" };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, brand: brand.name.charAt(0).toUpperCase() + brand.name.slice(1).toLowerCase() };
    });
  };
  const onModelSelect = (model: any) => {
    setValues((v: any) => {
      return { ...v, model: model };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, model: model.name.charAt(0).toUpperCase() + model.name.slice(1).toLowerCase() };
    });
  };
  const onDistrictSelect = (location: any) => {

    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });


  };
  const onCitySelect = (location: any) => {
    setValues((v: any) => {
      return { ...v, city: location };
    });
    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

  };

  const onYearsSelect = (years: any) => {
    setYear(years);
    setValues((v: any) => {
      return { ...v, years: years };
    });
    setsugesstedTitile((v: any) => {
      return { ...v, year: years.name };
    });
  };

  const onConditionSelect = (condition: any) => {
    setSelectedCondition(condition);
    setValues((v: any) => {
      return { ...v, condition: condition };
    });
  };
  const isSavedDistrict = () => {

    if (Object.keys(locs).length > 0) {
      return true;
    }
    return false;
  };
  const customAlertYear = {
    subHeader: "Select Model Year",
    translucent: true,
  };
  const customAlertCondition = {
    subHeader: "Select {lan.CONDITION}",
    translucent: true,
  };


  return (
    <div>
      <Grid container spacing={4}>

        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="district"
            options={district}
            disableClearable
            onChange={(event: any, district: any) => {

              onDistrictSelect(district);
            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="city"
            options={cities}
            disabled={!values.district}
            disableClearable

            onChange={(event: any, location: any) => {
              onCitySelect(location);
            }}
            getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="district"
                label={lan.CITY}
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-brand-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="brands"
            options={brands}
            disableClearable
            onChange={(event: any, brand: any) => {
              onBrandSelect(brand);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.BRAND_NAME}
                name="Bikes_Brand"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-model-16x16.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="models"
            options={models}
            disableClearable
            disabled={!values.brand}
            onChange={(event: any, model: any) => {
              onModelSelect(model);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.MODEL}
                name="Bikes_Model"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-year-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="year"
            options={years}
            disableClearable
            onChange={(event: any, year: any) => {
              onYearsSelect(year);
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={lan.MODEL_YEAR}
                name="Year"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-km-18x14.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="mileage"
            label={lan.MILEAGE}
            name="Motors_Mileage"
            placeholder="100000"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ pattern: "^\\d{1,7}$" }}
            variant="outlined"
            value={mileage || ""}
            style={{ width: "100%" }}
            required
            onChange={(e: any) => setMileage(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item" style={{ paddingLeft: "16px", paddingTop: "5px" }}>
          <div className="filldetails-radioline-icon-wrapper">
            <img src="assets/addpost/icon-condition-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <FormControl component="fieldset" className={classes.lineRadio}>
            <FormLabel component="legend">{lan.CONDITION}</FormLabel>
            <RadioGroup
              aria-label={lan.CONDITION}
              name="Motor_Condition"
              value={selectedCondition}
              onChange={(e: any) => onConditionSelect(e.target.value)}
            >
              {condition.map((item: any) => (
                <FormControlLabel
                  value={item.name}
                  key={item.id}
                  control={<Radio color="primary" size="small" required />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-engin-capacity-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="engine-capacity"
            label={lan.ENGINE_CAPACITY}
            name="Bike_Engine_Capacity"
            placeholder="150"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={engCap || ""}
            inputProps={{ pattern: "^[1-9]\\d{1,3}$" }}
            style={{ width: "100%" }}
            required
            onChange={(e: any) => setEngCap(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-fullwidth-icon-wrapper">
            <img src="assets/addpost/icon-title-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="title"
            label={lan.TITTLE}
            placeholder={lan.MAX_72_CHARACTERS}
            name="title"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            required
            inputProps={{ maxLength: 72 }}
            variant="outlined"
            value={newPost.title || ""}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostTitle(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <TextField
            id="description"
            // label="Description"
            label={lan.DESCRIPTION}
            multiline
            placeholder={lan.MAX_4000_CHARACTERS}
            name="description"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.description || ""}
            inputProps={{ maxLength: 4000 }}
            style={{ width: "100%" }}
            onChange={(e: any) =>
              dispatch(addNewPostDesciption(e.target.value))
            }
            onInput={(e: any) => dispatch(addNewPostDesciption(e.target.value))}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-price-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="price"
            label={lan.PRICE}
            multiline
            placeholder="350000"
            name="price"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            required
            variant="outlined"
            value={newPost.price || ""}
            inputProps={{ pattern: "^\\d{1,12}(\\.\\d{1,2})?$", maxLength: 15 }}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostPrice(e.target.value))}
          />
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          {
            (newPost.price == 0) ?
              <span className="price" >{lan.ASK_FOR_PRICE}</span>
              :
              <div color="primary" className="price">
                <span className="ru-in-price">{lan.RS}. </span>{" "}
                {newPost?.price === "" || !isNaN(newPost?.price)
                  ? currencyConverter().format(newPost?.price)
                  : "Invalid"}
              </div>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default FillDetailsBikes;
