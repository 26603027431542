import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailLandsMobile.css';

const FullDetailLandsMobile = (props: any) => {
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-brands-16x16.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.LAND_SIZE}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">

                        <span className="brand-name-english">{(props.item.specs.Land_Area == undefined ? props.item.specs.Land_Area_Perch : props.item.specs.Land_Area)}  {lan.PERCHES}</span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>

            <Grid item xs={12}>

            </Grid>
        </Grid>
    )
}

export default FullDetailLandsMobile;