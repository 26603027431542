import {
  Button,
  Container,
  createStyles,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentStep } from "../../features/addPost/addPostSlice";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import { selectLanguage } from "../../features/language/language_slice";
import {
  BEHAVE_LOGIN_REQUIRED,
  NavigationContext,
  NAV_ADD_POST,
  NAV_LANDING,
} from "../../providers/NavigationProvider";
import { UserContext } from "../../providers/UserProvider";
import "./Footer.css";
import { Language } from "../../language/Language";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: {
      [theme.breakpoints.up("md")]: {
        width: "100%",

      }, [theme.breakpoints.down("md")]: {
        visibility: "hidden",
        position: "relative",
        height: "120px",
        overflow: "auto"
      }
    },
    rootm: {
      [theme.breakpoints.down("md")]: {

        height: "0px"
      }
    }
    ,
    width: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: "1280px",
        padding: 0,
      },
    },
    catLink: {
      "&:hover": {
        color: "#0080FF",
      },
    },
  })
);

const PAGE_ALL = 0;
const PAGE_MOTORS = 1;
const PAGE_MOBILE_ELECTRONICS = 2;
const PAGE_PROPERTY_SALE = 3;
const PAGE_PROPERTY_RENT = 4;
const PAGE_CLASSIFIEDS = 5;
const PAGE_OTHER = -1;

const FooterNew = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }
  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  const step = useSelector(selectCurrentStep);
  const scrollContent = document.getElementById("scrollable-root");
  const dispatch = useDispatch();
  const location = useLocation();
  const [mainNavPage, setmainNavPage] = useState(PAGE_ALL);

  useEffect(() => {
    const path = location.pathname;
    const url = new URLSearchParams(window.location.search);
    const c1 =
      url
        .get("c1")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || "";
    switch (decodeURIComponent(c1)) {
      case "motors":
        setmainNavPage(PAGE_MOTORS);
        break;
      case "mobiles and electronics":
        setmainNavPage(PAGE_MOBILE_ELECTRONICS);
        break;
      case "property for sale":
        setmainNavPage(PAGE_PROPERTY_SALE);
        break;
      case "property for rent":
        setmainNavPage(PAGE_PROPERTY_RENT);
        break;
      case "classifieds":
        setmainNavPage(PAGE_CLASSIFIEDS);
        break;
      default:
        if (path === "/search" || path === "/home") setmainNavPage(PAGE_ALL);
        else setmainNavPage(PAGE_OTHER);
        break;
    }
  }, [window.location.search, location.pathname]);

  const checkNav = (cat: any) => {
    scrollContent?.scrollTo(0, 0);
    setmainNavPage(cat);
    navigate(`/search?c1=${encodeURIComponent(cat)}`);
  };

  const handleLogin = () => {
    dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
  };

  const handleNewMembership = (user: any) => {
    if (user == null || user.email === "") {
      handleLogin();
      return;
    }
    navigate("/membership");
  };

  return (
    <NavigationContext.Consumer>
      {(nav: any) => (
        <>
          <Grid container className={classes.root} style={{ backgroundColor: "#222" }}>
            <Grid item lg={1} md={12}></Grid>
            <Grid item lg={10} md={12}>
              <Grid container style={{ paddingTop: "40px", paddingBottom: "40px" }}>

                <Grid item lg={4} md={12}>
                  <div>
                    <img src="../../../assets/logo/sarauwata-footer-logo.png"></img>
                  </div>
                  <div style={{ paddingBottom: "16px" }}>
                    <span id="footer-text" style={{ lineHeight: "18px" }}>
                      {/* Welcome to the new marketplace designed for modern Sri
                      Lankans. Find the best deals on Vehicles, Property,
                      Electronics and much more. Choose from a wide range of
                      high quality ADs from trusted sellers. */}
                      {lan.FOOTER_WELCOME}
                    </span>
                  </div>
                  <span id="footer-text">
                    {/* Download the app to upgrade your life! */}
                    {lan.FOOTER_DOWNLOAD_APP}
                  </span>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=lk.hiruads.aphrodite"
                      target="_blank"
                    >
                      <img
                        src="assets/logo/btn-google-app-2.svg"
                        style={{ paddingRight: "16px", cursor: "pointer" }}
                      ></img>
                    </a>
                    <a
                      href="https://apps.apple.com/lk/app/saruwata-lk/id1565804524"
                      target="_blank"
                    >
                      <img
                        src="assets/logo/btn-appstore-app.svg"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </a>
                  </div>
                </Grid>
                <Grid item lg={4} md={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={5}>
                      <span id="footer-text-header">
                        {/* CATEGORIES */}
                        {lan.CATEGORIES}
                      </span>
                      <div>
                        <ul id="footer-links">
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                              onClick={() => checkNav("motors")}
                            >
                              {/* Motors */}
                              {lan.MOTORS}
                            </Link>
                          </li>
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                              onClick={() => checkNav("mobiles and electronics")}
                            >
                              {/* Mobile & Electronics */}
                              {lan.MOBILE_AND_ELECTRONICS}
                            </Link>
                          </li>
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                              onClick={() => checkNav("property for sale")}
                            >
                              {/* Property for Sale */}
                              {lan.PROPERTY_FOR_SALE}
                            </Link>
                          </li>
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                              onClick={() => checkNav("property for rent")}
                            >
                              {/* Property for Rent */}
                              {lan.PROPERTY_FOR_RENT}
                            </Link>
                          </li>
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                              onClick={() => checkNav("classifieds")}
                            >
                              {/* Classifieds */}
                              {lan.CLASSIFIEDS}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    {/* <Grid item lg={5}></Grid> */}
                  </Grid>
                </Grid>
                <Grid item lg={4} md={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={5}>
                      <span id="footer-text-header">Saruwata.lk</span>
                      <div>
                        <ul id="footer-links">
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              onClick={() => navigate("/support/faq")}
                              className={classes.catLink}
                              underline="none"
                            >
                              {/* FAQ */}
                              {lan.FAQ}
                            </Link>
                          </li>
                          <li>
                            <ArrowRight />
                            <Link
                              color="inherit"
                              target="_blank"
                              href="https://blog.saruwata.lk/"
                              className={classes.catLink}
                              underline="none"
                            >
                              {/* Blog */}
                              {lan.BLOG}
                            </Link>
                          </li>
                          <UserContext.Consumer>
                            {(user: any) => (
                              <li>

                                <ArrowRight />
                                <Link
                                  color="inherit"
                                  target="_blank"
                                  onClick={() => handleNewMembership(user)}
                                  className={classes.catLink}
                                  underline="none"
                                >
                                  {/* Membership */}
                                  {lan.MEMBERSHIP}
                                </Link>
                              </li>
                            )}
                          </UserContext.Consumer>
                          <li>
                            <ArrowRight />
                            <Link
                              onClick={() => navigate("/privacy-policy")}
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                            >
                              {/* Privacy Policy */}
                              {lan.PRIVACY_POLICY}
                            </Link>
                          </li>
                          <li>
                            <ArrowRight />
                            <Link
                              onClick={() => navigate("/terms-conditions")}
                              color="inherit"
                              className={classes.catLink}
                              underline="none"
                            >
                              {/* Terms & Conditions */}
                              {lan.TERMS_AND_CONDITIONS}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid item lg={5}>
                      <div style={{ paddingBottom: "30px" }}>
                        <span id="footer-text-header">
                          {/* CONTACT */}
                          {lan.CONTACT}
                        </span>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <span style={{ color: "#999999", fontSize: "13px" }}>
                            {/* Hotline */}
                            {lan.HOTLINE}
                          </span>
                          <span
                            style={{
                              color: "#FFFFFF",
                              fontSize: "18px",
                              marginBottom: "28px",
                            }}
                          >
                            +94 117 455 955
                          </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <span style={{ color: "#999999", fontSize: "13px" }}>
                            {/* Email */}
                            {lan.EMAIL}
                          </span>
                          <span style={{ color: "#FFFFFF", fontSize: "18px" }}>
                            info@saruwata.lk
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={1} md={12}></Grid>

            <Grid container>
              <Grid item lg={1} md={12}></Grid>
              <Grid item lg={10} md={12}>
                <div style={{ display: "flex", paddingBottom: "20px" }}>
                  <div>
                    <span id="footer-text">
                      {/* Copyright © 2021 Saruwata.lk - All Rights Reserved */}
                      {lan.COPYRIGHTS}
                    </span>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <span id="footer-text" style={{ marginRight: "16px" }}>
                        {/* Connect with us: */}
                        {lan.CONNECT_WITH_US}
                      </span>
                      <img
                        src="assets/icon/icon-facebook.svg"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        alt=""
                        onClick={() =>
                          window.open("https://www.facebook.com/saruwata.lk")
                        }
                      />
                      <img
                        src="assets/icon/icon-instagram.svg"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        alt=""
                        onClick={() =>
                          window.open("https://www.instagram.com/saruwata.lk/")
                        }
                      />
                      <img
                        src="assets/icon/icon-youtube.svg"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        alt=""
                        onClick={() =>
                          window.open("https://www.youtube.com/c/SaruwataLK")
                        }
                      />
                      <img
                        src="assets/icon/icon-tiktok.svg"
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={() =>
                          window.open("https://www.tiktok.com/@saruwata.lk")
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={1} md={12}></Grid>
            </Grid>
          </Grid>
        </>
      )}
    </NavigationContext.Consumer>
  );
};

export default FooterNew;
