import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { logEvent } from "firebase/analytics"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_INPUT } from "../../../config/actions";
import { CURRENT_BUILD } from "../../../config/build_config";
import { INPUT_IMAGES } from "../../../config/events";
import {
  addImagesToBNewPost,
  addNewPostRawImages,
  clearImages,
  clearImgFromSlider,
  selectCoverImgIndex,
  selectNewPosts,
  setCoverImgIndex,
  setCoverImgName,
  setImgFolder,
  setSelectedImages,
} from "../../../features/post/postSlice";
import "./AddImages.css";
import CheckIcon from "@material-ui/icons/Check";
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { setCurrentStep } from "../../../features/addPost/addPostSlice";
import { setProgress } from "../../loader/LineProgress";
import { setfilldetailsopenn, setimagesSelectedd } from "../../../pages/AddPost/AddPost";
import { selectImagesCount, selectImageUrlArray, setImageUrl, setProg, setSaveImagesCount } from "../../../features/post/imageUpload";

import { setFinish, setIsComplete, setProgr } from "../../loader/LinearProgress";
import { Image } from "@material-ui/icons";

import AWS from 'aws-sdk';
import Compressor from 'compressorjs';
import Uploader from "../../Uploader";
import Preview from "../../Preview";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { analytics } from "../../..";
import { generatePresignedS3Url } from "../../../config/urls";
import axios from "axios";





const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
    itemfilled: {
      height: 100,
      width: 160,
      borderRadius: 10,
      border: "1px solid #DDDDDD",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
    noImage: {
      width: "100%",
      height: "100%",
    },
    hasImage: {
      height: "100%",
      objectFit: "cover",
      width: "100%",
      objectPosition: "center",
      backgroundRepeat: "no-repeat",
      borderRadius: 10,
    },
    titleImage: {
      height: "100%",
      objectFit: "cover",
      width: "100%",
      objectPosition: "center",
      backgroundRepeat: "no-repeat",
      borderRadius: 10,
      border: "2px solid #0080FF",
    },
    deletebtn: {
      width: 23,
      height: 23,
      position: "absolute",
      top: 12,
      right: 12,
    },
    coverText: {
      width: 95,
      height: 23,
      backgroundColor: "#0080FF",
      position: "absolute",
      bottom: 0,
      left: 0,
      color: "#fff",
      fontSize: 12,
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0 10px 0 10px",
    },
  })
);
const AddImagesS3Edit = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const newPost = useSelector(selectNewPosts);
  const dispatch = useDispatch();
  const [saveImage, setSaveImage] = useState<any[]>([]);
  const imgBtn: any = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);

  const [coverImgSelected, setcoverImgSelected] = useState(false);
  const coverImgindex = useSelector(selectCoverImgIndex);

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const [imagesPreviewUrls, setPreviewUrls] = useState([] as any);
  const ipu = [] as any;

  const ID = 'AKIAQ2KCF3STN6DPFLUA';
  const SECRET = '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';

  // The name of the bucket that you have created
  const BUCKET_NAME = 'saruwata-bucket';
  const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET
  });

  const imageUrls = useSelector(selectImageUrlArray);
  const imageCount = useSelector(selectImagesCount);

  useEffect(() => {
    dispatch(clearImages([]))
    props.images.map((item: any) => dispatch(addImagesToBNewPost(item)))
    // props.images.map((item: any) => dispatch(addNewPostRawImages(item)))
    makeCoverImg(props.images[props.index], props.index);

  }, [])


  useImperativeHandle(
    ref,
    () => ({
      trigger() {
        imgBtn.current.click();
      },
    }),
    []
  );
  const addimagesToview = (img: any) => {
    const reader: any = new FileReader();

    reader.onloadend = function () {
      dispatch(addImagesToBNewPost(reader.result));
    };
    try {
      reader.readAsDataURL(img);
      // props.setInteracted(true);
    } catch (error) {

      return;
    }

    setSaveImage((images: any) => [...images, img]);

    // console.log(saveImage);
    // dispatch(setSelectedImages(saveImage))

  };

  const deleteImage = (id: any) => {

    if (imagesPreviewUrls.length > 0) {
      const filterImages = imagesPreviewUrls.filter((image: any) => image.id !== id);
      setImagesPreviewUrls(filterImages);
      // this.setState({
      //   imagesPreviewUrls: filterImages,
      // });
    }
  };




  const showImage = (e: any) => {
    e.persist();
    const images = Array.from<any>(e.target.files);



    images.forEach((img) => {

      //20mb
      if (img.size > 20971520) {
        setShowAlert1(true);
      } else {
        if (img.type == "image/jpeg" || img.type == "image/png") {

          addimagesToview(img);
          // console.log("img to view>>" + img.name);
        } else {
          setShowAlert(true);
          e.target.value = null;
        }
      }
    });
    e.target.value = null;
  };
  const openImagePicker = () => {
    document.getElementById("image-picker")?.click();
  };
  const deleteImgFromSlider = (e: any, img: any, index: any) => {
    e.stopPropagation();
    if (index == coverImgindex) {
      setShowAlert3(true);
    } else {
      dispatch(clearImgFromSlider(index));
      setSaveImage(saveImage.filter((img: any, i: number) => i != index));
      // dispatch(setSelectedImages(saveImage.filter((img: any, i: number) => i != index)));
      if (saveImage.length == 1) {
        props.setInteracted(false);
      }
    }
    if (coverImgSelected && index < coverImgindex) {
      dispatch(setCoverImgIndex(coverImgindex - 1));
    }
  };
  const makeCoverImg = (img: any, index: any) => {
    // console.log(index );

    dispatch(setCoverImgName(img.name));
    dispatch(setCoverImgIndex(index));
    props.setEditedImageIndex(index);
    setcoverImgSelected(true);

  };
  const checkSelectedImages = () => {

    if (saveImage.length > 10) {
      const filteredImages = saveImage.slice(0, 10);
      setSaveImage(filteredImages);
      // dispatch(setSelectedImages(filteredImages));
      // setShowAlert2(true);
      createPost();
      logEvent(analytics, INPUT_IMAGES, { action: ACTION_INPUT, platform: 'DESKTOP' });
    } else {

      createPost();
      logEvent(analytics, INPUT_IMAGES, { action: ACTION_INPUT, platform: 'DESKTOP' });
    }
  };



  var i = 0;
  function setProgress(total: number, up: number) {
    // console.log("Total" + total);
    // console.log("up" + up);
    var oneSlice = 100 / saveImage.length;
    var upx = oneSlice * i + (oneSlice * ((up / total) * 100) / 100);
    // console.log(upx);
    if ((up / total) == 1) {
      i = i + 1;

    }
    setProgr(upx);
  }
  async function createPost() {
    setimagesSelectedd(true);
    setfilldetailsopenn(true);
    var ts: any;
    if (props.images.length > 0) {
      var img = props.images[0] as string;

      if (img.includes('https://saruwata-bucket.s3.ap-southeast-1.amazonaws.com/liveNew/posts/')) {
        var domain = img.split('https://saruwata-bucket.s3.ap-southeast-1.amazonaws.com/liveNew/posts/')[0];
        var path = img.split('https://saruwata-bucket.s3.ap-southeast-1.amazonaws.com/liveNew/posts/')[1];
        // console.log(domain);

        ts = path.split("/")[0];
      } else {
        ts = new Date().getTime();

      }


    } else {

      ts = new Date().getTime();
    }

    //    setProgress(true);

    if (saveImage.length == 0) {
      props.update();

      return;
    }
    // alert(ts)
    for (let index = 0; index < saveImage.length; index++) {

      let image = saveImage[index];

      // let url = await uploadToFirebase(image, index, ts);

      handleCompressedUpload(image, index + props.images.length, ts);
    }
    dispatch(setImgFolder(ts));
  }



  const setImagesPreviewUrls = (data: any) => {

    ipu.push(data);
    setPreviewUrls(ipu);
    // alert(imagesPreviewUrls.length)
    // imagesPreviewUrls = [...imagesPreviewUrls, data];


  }

  var urlArray = [] as string[];
  const uploadToS3 = async (image: any, index: number, folder: number) => {

    const response = await axios.get(
      `${generatePresignedS3Url}?bucketName=${BUCKET_NAME}&key=${CURRENT_BUILD}/posts/${folder}/${folder}_${index}.${image.name.split('.')[1]}`
    );

    const presignedUrl = response.data;

    axios.put(presignedUrl, image, {
      headers: {
        "Content-Type": image?.type,
        skipAuth: true
      },
    })
      .then((d) => {
        if (d.status == 200) {

          const fileName = presignedUrl.split("?")[0];
          dispatch(addNewPostRawImages(fileName));
          urlArray.push(fileName);
         
          
          if (urlArray.length === saveImage.length) {
            setProgr(100)
            setIsComplete(true);
          }
        }
      })
      .catch(e => {
        console.log(e);

      });


    // Uploading files to the bucket
    // const ss = s3.upload(params).on('httpUploadProgress', function (evt) {

    //   setProgress(evt.total, evt.loaded);


    // }).promise();

    // ss.then((d: any) => {
    //   dispatch(addNewPostRawImages(d.Location));
    //   urlArray.push(d.Location);
    //   if (urlArray.length === saveImage.length) {
    //     setIsComplete(true);
    //   }
    // });

  }



  const handleCompressedUpload = (image: any, index: number, folder: number) => {

    var quality = 1;
    if (image.size >= 10485760) {
      quality = 0.5;
    } else if (image.size > 1572864) {

      quality = 0.6;
    } else {

      quality = 0.7;
    }
    new Compressor(image, {
      quality: quality, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {

        uploadToS3(compressedResult, index, folder);
        // setCompressedFile(compressedResult)
      },
    });
  };

  return (
    <div>
      <input
        type="file"
        name="imagePicker"
        multiple
        onChange={showImage}
        accept="image/*"
        id="image-picker"
        onSelect={(e: any) => e.preventDefault()}
        hidden
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "26px",
        }}
      >
        <div style={{ color: "#000000", fontSize: "12px" }}>
          <span style={{ color: "#000000", fontSize: "14px", fontWeight: 600 }}>
            Edit Images upto 10 photos{" "}
            {/* {lan.ADD_UPTO_10_PHOTOS} */}
          </span>
          {/* - You must upload at least one photo* */}
        </div>
        <div style={{ color: "#5A5A5A", fontSize: "12px" }}>
          you can add new images and change title image.if you want to remove any image please contact our help service +94 117 455 955
          {/* {lan.CLICK_UPLOADED_IMAGE_TO_CHANGE_TITTLE} */}
        </div>
      </div>



      {/* <div>
        <Uploader imagesPreviewUrls={setImagesPreviewUrls} />
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignContent="space-between"
          height={220}
        >

          {imagesPreviewUrls.length > 0 ? (

            <Preview
              imagesPreviewUrls={imagesPreviewUrls}
              deleteImage={deleteImage}
            />




          ) : null}
        </Box>
      </div> */}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignContent="space-between"
        height={220}
      >


        {Array.from(Array(10), (e: any, i: number) => (
          <div
            key={i}
            className={classes.itemfilled}
            onClick={() =>
              newPost.images[i]
                ? makeCoverImg(newPost.images[i], i)
                : openImagePicker()
            }
          >

            <img
              src={
                newPost.images[i]
                  ? newPost.images[i]
                  : "assets/images/uploadimage.svg"
              }
              className={
                newPost.images[i]
                  ? coverImgindex == i
                    ? classes.titleImage
                    : classes.hasImage
                  : classes.noImage
              }
            ></img>
            {/* {newPost.images[i] ? (
              <div>
                {(i == coverImgindex) ? null :
                  <div
                    className={classes.deletebtn}
                    onClick={(e) => deleteImgFromSlider(e, newPost.images[i], i)}
                  >
                    <img src="assets/icon/icon-delete-ad-post.svg"></img>
                  </div>
                }
              </div>
            ) : null} */}
            {coverImgindex == i && newPost.images[0] ? (
              <div className={classes.coverText}>
                <CheckIcon
                  style={{ width: "16px", height: "14px", marginRight: "5px" }}
                ></CheckIcon>
                <div>
                  {/* Title Image */}
                  {lan.TITTLE_IMAGE}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </Box>
      <div id="footer-btn-sc">
        <Button
          onClick={checkSelectedImages}
          ref={imgBtn}

          style={{ visibility: "hidden" }}
        >
          ඊලග / Next
        </Button>
      </div>
      <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
        <DialogTitle id="alert-dialog-title">
          Please select JPG or PNG
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert1} onClose={() => setShowAlert1(false)}>
        <DialogTitle id="alert-dialog-title">
          Maximum image size is 15MB
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setShowAlert1(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert2} onClose={() => { setShowAlert2(false) }}>
        <DialogTitle id="alert-dialog-title">Image count exceeded</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Maximum image count exeeded using first 10 images
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => { setShowAlert2(false); setShowAlert4(true) }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert3} onClose={() => setShowAlert3(false)}>
        <DialogTitle id="alert-dialog-title">
          Change cover image first
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setShowAlert3(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert4} onClose={() => setShowAlert4(false)}>
        <DialogTitle id="alert-dialog-title">
          Continue Image Procesing
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => { setShowAlert4(false); createPost(); }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
export default AddImagesS3Edit;