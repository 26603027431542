// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-tag-section-wrapper-m{
    padding:20px;
}

.search-tag-section-wrapper-m > header{
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-bottom: 5px;
    display: flex;
    align-items: center;
}

.search-tag-section-title-m{
    margin-left: 10px;
    color:#000;
    font-size: 18px;
    font-weight: 600;
}

.search-tag-section-icon-m{
    height:24px;
    width: 24px;
}

.search-tag-section-body-wrapper-m{
    padding-top: 18px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
}

.wider-icon-m{
    width:28px;
    height:20px;
}

.tag-selected-m{
    background: #00AEFF !important;
    color:#fff !important;
}

.tag-name-m{
    font-size: 1.1em;
    font-weight: 400;
    padding: 0 2px;
    padding-right: 6px;
}
#search-tag-searchbar-form .sc-ion-searchbar-md-h {
    --box-shadow: none !important;
}

.searchbar-custom div{
    border: 1px solid #DDDDDD;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SearchPage/SearchTagCloud/SearchTagCloudMobile.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,wCAAwC;IACxC,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".search-tag-section-wrapper-m{\n    padding:20px;\n}\n\n.search-tag-section-wrapper-m > header{\n    border-bottom: 1px solid rgba(0,0,0,0.2);\n    padding-bottom: 5px;\n    display: flex;\n    align-items: center;\n}\n\n.search-tag-section-title-m{\n    margin-left: 10px;\n    color:#000;\n    font-size: 18px;\n    font-weight: 600;\n}\n\n.search-tag-section-icon-m{\n    height:24px;\n    width: 24px;\n}\n\n.search-tag-section-body-wrapper-m{\n    padding-top: 18px;\n    padding-bottom: 5px;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.wider-icon-m{\n    width:28px;\n    height:20px;\n}\n\n.tag-selected-m{\n    background: #00AEFF !important;\n    color:#fff !important;\n}\n\n.tag-name-m{\n    font-size: 1.1em;\n    font-weight: 400;\n    padding: 0 2px;\n    padding-right: 6px;\n}\n#search-tag-searchbar-form .sc-ion-searchbar-md-h {\n    --box-shadow: none !important;\n}\n\n.searchbar-custom div{\n    border: 1px solid #DDDDDD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
