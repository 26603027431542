// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#icon-section div{
    display: flex;
    align-items: center;
}

#icon-milage{
    width: 20px;
    height: auto;
    margin-bottom: -3px;

}
#icon-year{
    width: 15px;
    height: 15px;
}
#icon-location{
    width: 15px;
    height: 15px;
}
#txt-location{
    margin-left: 6px;
    margin-right: 20px;
    font-size: 14px;
    color: #5A5A5A;
}
#txt-year{
     margin-left: 8px;
    margin-right: 20px;
    font-size: 14px;
    color: #5A5A5A;
}
#txt-milage{
     margin-left: 8px;
    margin-right: 20px;
    font-size: 14px;
    color: #5A5A5A;
}
`, "",{"version":3,"sources":["webpack://./src/components/Post/PostCardFixed/DetailSection/Motors/Cars/cars.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;;AAEvB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB;AACA;KACK,gBAAgB;IACjB,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB;AACA;KACK,gBAAgB;IACjB,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB","sourcesContent":["\n\n#icon-section div{\n    display: flex;\n    align-items: center;\n}\n\n#icon-milage{\n    width: 20px;\n    height: auto;\n    margin-bottom: -3px;\n\n}\n#icon-year{\n    width: 15px;\n    height: 15px;\n}\n#icon-location{\n    width: 15px;\n    height: 15px;\n}\n#txt-location{\n    margin-left: 6px;\n    margin-right: 20px;\n    font-size: 14px;\n    color: #5A5A5A;\n}\n#txt-year{\n     margin-left: 8px;\n    margin-right: 20px;\n    font-size: 14px;\n    color: #5A5A5A;\n}\n#txt-milage{\n     margin-left: 8px;\n    margin-right: 20px;\n    font-size: 14px;\n    color: #5A5A5A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
