import { IonAlert } from "@ionic/react";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectedCategories,
    setCurrentStep,
} from "../../../features/addPost/addPostSlice";
import {
    addNewPostDesciption,
    postSpecs,
    selectNewPosts,
    setHideFooter,
    setPostSpecs,
} from "../../../features/post/postSlice";
import "./FillDetails.css";
import FillDetailsCars from "./FillSection/Motors/Cars/FillDetailsCars";
import FillDetailsMobiles from "./FillSection/MobileAndElectronics/Mobiles/FillDetailsMobiles";
import FillDetailsBikes from "./FillSection/Motors/Bikes/FillDetailsBikes";
import FillDetailsMotorsGeneral from "./FillSection/Motors/MotorsGeneral/FillDetailsMotorsGeneral";
import FillDetailsElectronicGeneral from "./FillSection/MobileAndElectronics/ElectronicGenaral/FillDetailsElectronicGeneral";
import FillDetailsApartmentAndHouses from "./FillSection/PropertyForSale/ApartmentsAndHouses/FillDetailsApartmentAndHouses";
import FillDetailsCommercialForSale from "./FillSection/PropertyForSale/CommercialAndLands/FillDetailsCommercialForSale";
import FillDetailsLandsForSale from "./FillSection/PropertyForSale/CommercialAndLands/FillDetailsLandsForSale";
import FillDetailsApartmentHouseRent from "./FillSection/PropertyForRent/FillDetailsApartmentHouseRent";
import FillDetailsShorttermRentDaily from "./FillSection/PropertyForRent/FillDetailsShorttermRentDaily";
import FillDetailsShorttermRent from "./FillSection/PropertyForRent/FillDetailsShorttermRent";
import FillDetailsRoomForRent from "./FillSection/PropertyForRent/FillDetailsRoomForRent";
import FillDetailsCommercialRent from "./FillSection/PropertyForRent/FillDetailsCommercialRent";
import { ACTION_INPUT } from "../../../config/actions";
import { INPUT_DETAILS } from "../../../config/events";
import { apartment_rent, apartment_sale, audio_speakers, bikes, cameras, cars, commercial_rent, commercial_sale, commercial_sale_, computers, daily, house_rent, house_sale, land_rent, land_sale, mobiles, mobile_parts, monthly, other_electronics, room_rent, tab, tv, vans } from "../../../constants/categories";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import FillDetailsLandsForRent from "./FillSection/PropertyForRent/FillDetailsLandForRent";
import FillDetailsApartments from "./FillSection/PropertyForSale/ApartmentsAndHouses/FilldetailsApartments";
import FillDetailsApartmentRent from "./FillSection/PropertyForRent/FillDetailsApartmentRent";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../..";


var subCat = '';
var Mvalues = {};

export function getMandatoryDetails() {
    return [subCat, Mvalues];
}
export function setSubCatMandatory(val: string) {
    subCat = val;
}
export function setValuesMandatory(val: {}) {
    Mvalues = val;
}


const FillDetails = forwardRef((props: any, ref: any) => {
    const dispatch = useDispatch();
    const selectedCat = useSelector(selectedCategories);
    const newPost = useSelector(selectNewPosts);
    const [showAlert1, setShowAlert1] = useState(false);

    const formRef: any = useRef();
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    const renderSwitch = () => {
        // console.log("--" + selectedCat.level2.name);
        switch (selectedCat.level2.name) {
            case cars:
                return <FillDetailsCars />;
            case bikes:
                return <FillDetailsBikes />;

            case mobiles:
                return <FillDetailsMobiles />;
            case mobile_parts:
                return <FillDetailsElectronicGeneral />;
            case tab:
                return <FillDetailsElectronicGeneral />;
            case audio_speakers:
                return <FillDetailsElectronicGeneral />;
            case tv:
                return <FillDetailsElectronicGeneral />;
            case computers:
                return <FillDetailsElectronicGeneral />;
            case cameras:
                return <FillDetailsElectronicGeneral />;
            case other_electronics:
                return <FillDetailsElectronicGeneral />;

            case apartment_sale:
                return <FillDetailsApartments />;
            case house_sale:
                return <FillDetailsApartmentAndHouses />;
            case commercial_sale_:
                return <FillDetailsCommercialForSale />;
            case land_sale:
                return <FillDetailsLandsForSale />;

            case apartment_rent:
                return <FillDetailsApartmentRent />;
            case land_rent:
                return <FillDetailsLandsForRent />;
            case house_rent:
                return <FillDetailsApartmentHouseRent />;
            case daily:
                return <FillDetailsShorttermRentDaily />;
            case monthly:
                return <FillDetailsShorttermRent />;
            case room_rent:
                return <FillDetailsRoomForRent />;
            case commercial_rent:
                return <FillDetailsCommercialRent />;
            default:
                return <FillDetailsMotorsGeneral />;

        }
    };

    useEffect(() => {
        props.setInteracted(true);
    }, []);

    const validate = () => {
        if (newPost.description.length >= 10) {
            props.setInteracted(true);
        } else {
            props.setInteracted(false);
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            trigger() {
                return handleSubmit();
            },
        }),
        []
    );

    const handleSubmit = (): boolean => {
        const formVal = formRef.current.reportValidity();
        if (!formVal) {
            return false;
        }
        else {
            const form: any = document.getElementById("form");
            const inputs: [] = form.querySelectorAll(
                ".MuiTextField-root input[name], .Mui-checked input[name], input[name].MuiSelect-nativeInput"
            );
            // console.log("..." + inputs);
            [...inputs]
                .filter((inp: any) => inp.name != "title")
                .filter((inp: any) => inp.name != "price")
                .filter((inp: any) => inp.name != "district")
                .filter((inp: any) => inp.name != "default")
                .filter((inp: any) => inp.value || inp.checked)
                .filter((inp: any) => inp.value != null)
                .filter((inp: any) => inp.value != "No")
                .filter((inp: any) => inp.value != "false")
                .forEach((input: any) => {
                    if (
                        input.name == "Motors_Mileage" ||
                        input.name == "Bike_Engine_Capacity" ||
                        input.name == "Property_Floor_Area" ||
                        input.name == "Property_Bedroom" ||
                        input.name == "Property_Parking_Space_Num" ||
                        input.name == "Land_Area" ||
                        input.name == "Land_Area_Perch" ||
                        input.name == "Property_Bathroom" ||
                        input.name == "Property_Perches"
                    ) {
                        if (input.value == "14+") {
                            dispatch(
                                setPostSpecs({ spec: input.name, value: "14+", comparable: true })
                            );
                        } else {
                            dispatch(
                                setPostSpecs({
                                    spec: input.name,
                                    value: input.value,
                                    comparable: true,
                                })
                            );
                        }
                    } else if (input.name == "Property_Furnished") {
                        dispatch(
                            setPostSpecs({
                                spec: input.name,
                                value: "FURNISHED",
                                comparable: false,
                            })
                        );
                    } else if (input.name == "Property_Parking_Space") {
                        dispatch(
                            setPostSpecs({
                                spec: input.name,
                                value: "PARKING AVAILABLE",
                                comparable: false,
                            })
                        );
                    } else if (input.name == "Property_Private_Bathroom") {
                        dispatch(
                            setPostSpecs({
                                spec: input.name,
                                value: "PRIVATE BATHROOM",
                                comparable: false,
                            })
                        );
                    } else if (input.name == "Property_Private_Entrance") {
                        dispatch(
                            setPostSpecs({
                                spec: input.name,
                                value: "PRIVATE ENTRANCE",
                                comparable: false,
                            })
                        );
                    } else if (input.value == "14+") {
                        dispatch(
                            setPostSpecs({ spec: input.name, value: "14+", comparable: true })
                        );
                    } else {
                        dispatch(
                            setPostSpecs({
                                spec: input.name,
                                value: input.value,
                                comparable: false,
                            })
                        );
                    }
                });

            dispatch(
                setPostSpecs({
                    spec: "Cat_Lev_1",
                    value: selectedCat.level1,
                    comparable: false,
                })
            );
            dispatch(
                setPostSpecs({
                    spec: "Cat_Lev_2",
                    value: selectedCat.level2.name,
                    comparable: false,
                })
            );
            dispatch(setCurrentStep(5));

            logEvent(analytics, INPUT_DETAILS, { action: ACTION_INPUT, platform: 'DESKTOP' });
            return true;
        }
    };

    return (
        <>
            <div></div>
            <form ref={formRef} id="form">
                <div>{renderSwitch()}</div>
                <button
                    type="submit"
                    style={{ visibility: "hidden" }}
                    id="btn-submit-hidden"
                ></button>
            </form>

            <Dialog open={showAlert1} onClose={() => setShowAlert1(false)}>
                <DialogTitle id="alert-dialog-title">Invalid Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>Description is too short</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowAlert1(false)}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
export default FillDetails;