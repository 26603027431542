// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soldOut{
    color:#F5222D;
  }
.reviewing{
    color:#13C2C2;
}
.approved{
    color:#52C41A;
}
.rejected{  
    color:#EC4CFF;
}
.paymentPending{  
    color:#FF9C00;
}
.mainflex{
    display: flex;
      
}
.mainflex>div{
    flex-basis: 200;
   
}

/* .mainflex>div:nth-child(1){
   flex-basis: 100;
}
.mainflex>div:nth-child(2){
   flex-basis: 200;
}
.mainflex>div:nth-child(3){
   flex-basis: 300;
} */
/* .mainflex>div:nth-child(4){
    margin-left: 50px;
   flex-basis: 200;
} */`, "",{"version":3,"sources":["webpack://./src/components/Post/MyAdPost/MyAdPost.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;AACF;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;;AAEjB;AACA;IACI,eAAe;;AAEnB;;AAEA;;;;;;;;GAQG;AACH;;;GAGG","sourcesContent":[".soldOut{\n    color:#F5222D;\n  }\n.reviewing{\n    color:#13C2C2;\n}\n.approved{\n    color:#52C41A;\n}\n.rejected{  \n    color:#EC4CFF;\n}\n.paymentPending{  \n    color:#FF9C00;\n}\n.mainflex{\n    display: flex;\n      \n}\n.mainflex>div{\n    flex-basis: 200;\n   \n}\n\n/* .mainflex>div:nth-child(1){\n   flex-basis: 100;\n}\n.mainflex>div:nth-child(2){\n   flex-basis: 200;\n}\n.mainflex>div:nth-child(3){\n   flex-basis: 300;\n} */\n/* .mainflex>div:nth-child(4){\n    margin-left: 50px;\n   flex-basis: 200;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
