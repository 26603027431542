import React, { useState } from "react";
import "./ProfilePage.css";
import { Button, createStyles, makeStyles, Switch, Theme, Hidden, Grid, TextField, MenuItem, Divider, InputAdornment, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checked from "@material-ui/icons/CheckCircle"
import { getCities, selectCity } from "../../features/addPost/addPostSlice";
import { addNewPostDistrict } from "../../features/post/postSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectDistricts, selectLocations } from "../../config/locations";
import { Language } from "../../language/Language";
import { selectLanguage } from "../../features/language/language_slice";

export default function ProfilePageEdit() {
    const dispatch = useDispatch();
    const [gender, setGender] = useState('');

    const [values, setValues] = useState({} as any);
    const locs = useSelector(selectLocations);
    const district = useSelector(selectDistricts);
    const cities = useSelector(selectCity);
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;



    const onDistrictSelect = (location: any) => {

        dispatch(getCities(location.id, location.name));
        setValues((v: any) => {
            return { ...v, district: location };
        });

    };
    const onCitySelect = (location: any) => {

        setValues((v: any) => {
            return { ...v, city: location };
        });
        (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

    };
    const isSavedDistrict = () => {

        if (Object.keys(locs).length > 0) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Grid container spacing={4} style={{ padding: "10px" }}>
                <Grid item lg={6} md={6} xs={12}>
                    <div>
                        <h5 >Personal Details</h5>
                        <Divider variant="fullWidth" />
                        <br />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <TextField label="Name*" variant="outlined" />
                            <br />
                            <TextField
                                id="date"
                                label="Birthday"
                                type="date"
                                variant="outlined"
                                defaultValue="2017-05-24"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <br />
                            <TextField

                                select

                                label="Gender"
                                value={gender}
                                onChange={(e: any) => { setGender(e.target.value) }}
                                variant="outlined"

                            >

                                <MenuItem key={1} value={"Male"}>
                                    Male
                                </MenuItem>
                                <MenuItem key={2} value={"Female"}>
                                    Female
                                </MenuItem>

                            </TextField>
                            {/* <br />
                            <Button variant="contained" color="primary">Update Details</Button> */}
                        </div>
                        <br />
                        <br />
                        <div>
                            <h5 >Location Details</h5>
                            <Divider variant="fullWidth" />
                            <br />
                            <Grid container spacing={4}>
                                <Grid item xs={12} className="filldetails-grid-item">

                                    <Autocomplete
                                        // className={classes.autoComplete}
                                        id="district"
                                        options={district}
                                        disableClearable
                                        onChange={(event: any, district: any) => {
                                            onDistrictSelect(district);

                                        }}
                                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                                        getOptionLabel={(option: any) => option.name}
                                        style={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} className="filldetails-grid-item">

                                    <Autocomplete
                                        // className={classes.autoComplete}
                                        id="city"
                                        options={cities}
                                        disableClearable
                                        disabled={!values.district}

                                        onChange={(event: any, location: any) => {
                                            onCitySelect(location);

                                        }}

                                        getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
                                        getOptionSelected={(f: any, s: any) => f.name === s.name}
                                        style={{ width: "100%" }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="district"
                                                label={lan.CITY}
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Address"
                                        multiline
                                        rows={4}
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                    />
                                </Grid>
                                {/* <Grid item xs={12} >

                                    <Button variant="contained" style={{ width: "100%" }} color="primary">Update Details</Button>

                                </Grid> */}
                            </Grid>
                        </div>

                    </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <h5 >Contact details</h5>
                    <Divider variant="fullWidth" />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>Email</label>
                        <TextField
                            disabled
                            variant="filled"

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Checked style={{ color: "green" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <br />
                    <label style={{ padding: "10px 0px", }}>Phone Number</label>
                    <Paper>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12} style={{ padding: "5px 20px", display: 'flex' }}>
                                <Checked />
                                <span style={{ marginLeft: '10px' }}>0712570600 Primary</span>
                            </Grid>
                            <Divider />
                            <div><p style={{ padding: "5px 20px" }}>To remove any above contact number please contact +94 117 455 955</p></div>
                        </Grid>


                        <Grid container>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <p>Add phone number and verify</p>
                                <p>Enter the phone numbers you want to display on your ad(max 5 numbers) and We’ll send you a verification code to your phone number.</p>

                            </Grid>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={9} md={9} lg={9}>
                                        <TextField fullWidth variant="outlined" placeholder="07X XXXXXXX"></TextField>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <Button variant="contained" style={{ marginTop: "7px" }}>Add</Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>



        </>
    )

}