import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailHousesMobile.css';

const FullDetailHousesMobile = (props: any) => {
    const item = props.item;
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    const renderField = (type: any): any => {
        const values = item.tags
            .filter((tag: any) => tag.types.includes(type));

        if (values.length <= 1) return (
            <span className="brand-name-english" key={values[0].id}>
                {values[0].name.charAt(0).toUpperCase() + values[0].name.slice(1).toLowerCase()}
            </span>
        );
        return values
            .filter((tag: any) => tag.name !== "(NA)")
            .map((object: any) => (
                <span className="brand-name-english" key={object.id}>
                    {object.name.charAt(0).toUpperCase() + object.name.slice(1).toLowerCase()}
                </span>
            ));
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-bedrooms.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}> {lan.BED_ROOMS}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {(item.specs.Property_Bedroom == "99") ? "4+" : item.specs.Property_Bedroom}
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-bathrooms.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.BATH_ROOMS}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {
                                (item.specs.Property_Bathroom == "99") ? "4+" : item.specs.Property_Bathroom
                            }
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-floor-size.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}> {lan.FLOOR_AREA}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            (item.specs.Property_Floor_Area == null || item.specs.Property_Floor_Area == undefined) ?
                                <span className="brand-name-english">
                                    (na)
                                </span>
                                :
                                <span className="brand-name-english">
                                    {

                                        item.specs.Property_Floor_Area
                                    }
                                    &nbsp;SqFt
                                </span>
                        }

                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-furnished.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.FURNISHED}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {
                                (item.tags
                                    .filter((tag: any) => tag.name !== "(NA)")
                                    .filter((tag: any) => tag.types.includes("Property_Furnished")).length > 0) ? "Yes" : "No"
                            }
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-car-parking.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '12px') }}> {lan.PARKING_SPACE}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {
                                (item.specs.Property_Parking_Space_Num == "99") ? "4+" : item.specs.Property_Parking_Space_Num
                            }
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-floor-size.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.LAND_SIZE}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            (item.specs.Property_Perches == null || item.specs.Property_Perches == undefined) ?
                                <span className="brand-name-english">
                                    (na)
                                </span>
                                :
                                <span className="brand-name-english">
                                    {

                                        item.specs.Property_Perches
                                    }
                                    &nbsp;{lan.PERCHES}
                                </span>
                        }

                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>

        </Grid>
    )
}

export default FullDetailHousesMobile;