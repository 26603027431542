import { logEvent } from "firebase/analytics"
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { sendPostClick, setSelectedPost } from "../../../../features/post/postSlice";
import '../PostCardMobile/PostCardMobile.css';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Button, Card, CardContent } from "@material-ui/core";
import { NavigationContext, NAV_LIKES } from "../../../../providers/NavigationProvider";
import { apartment_rent, apartment_sale, bikes, cars, commercial_rent, commercial_sale, daily, house_rent, house_sale, land_rent, land_sale, mobiles, monthly } from "../../../../constants/categories";

import { frontend } from "../../../../config/urls";
import { NoEncryption } from "@material-ui/icons";
import CarsMobile from "../DetailSection/Motors/Cars/carsMobile";
import MobileMobile from "../DetailSection/MobileElectronics/MobileMobile";
import ApartmentHouseMobile from "../DetailSection/Property/AprtmentHouseMobile";
import CommercialMobile from "../DetailSection/Property/CommercialMobile";
import LandCommercialMobile from "../DetailSection/Property/LandCommercialMobile";
import LandRentMobile from "../DetailSection/Property/LandRentMobile";
import CommercialRentMobile from "../DetailSection/Property/CommercialRentMobile";
import DefaultSerpMobile from "../DetailSection/DefaultSerpMobile";
import { useNavigate } from "react-router-dom";


export default function PostCardMobile(this: any, props: any) {

    const item = props.post;
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const sliderRef = useRef<any>(null);
    const [stopSlide, setStopSlide] = useState(true);

    useEffect(() => {
        sliderRef.current?.slideNext();
    }, []);

    setTimeout(() => {
        if (stopSlide) sliderRef.current?.slidePrev();
        setStopSlide(false);
    }, 3000);

    const postClick = (id: number) => {
        handleFullViewClick();
        dispatch(sendPostClick(id));
    };

    const handleFullViewClick = () => {
        dispatch(setSelectedPost(item));
        navigate(`/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`);
    }

    const openInNewTab = () => {
        dispatch(setSelectedPost(item));
        return `https://saruwata.lk/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`;
    }



    const renderSwitch = () => {

        switch (item?.sub_category?.name) {
            case cars:
                return <CarsMobile item={item}></CarsMobile>;
            case bikes:
                return <CarsMobile item={item}></CarsMobile>;
            case mobiles:
                return <MobileMobile item={item}></MobileMobile>;
            case apartment_sale:
                return <ApartmentHouseMobile item={item}></ApartmentHouseMobile>;
            case house_sale:
                return <ApartmentHouseMobile item={item}></ApartmentHouseMobile>;
            case commercial_sale:
                return <CommercialMobile item={item}></CommercialMobile>;
            case land_sale:
                return <LandCommercialMobile item={item}></LandCommercialMobile>;
            case land_rent:
                return <LandRentMobile item={item}></LandRentMobile>;
            case apartment_rent:
                return <ApartmentHouseMobile item={item}></ApartmentHouseMobile>;
            case house_rent:
                return <ApartmentHouseMobile item={item}></ApartmentHouseMobile>;
            case daily:
                return <ApartmentHouseMobile item={item}></ApartmentHouseMobile>;
            case monthly:
                return <ApartmentHouseMobile item={item}></ApartmentHouseMobile>;
            case commercial_rent:
                return <CommercialRentMobile item={item}></CommercialRentMobile>;
            default:
                return <DefaultSerpMobile item={item} />;

        }
    };



    const useStyles = makeStyles({

        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        button: {
            fontFamily: 'Titillium Web',
        }
    });

    const classes = useStyles();
    const link = `${frontend}/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`;

    return (
        <NavigationContext.Consumer>
            {(nav: any) => (
                <>
                    {/* <a href={link} style={{ textDecoration: 'none' }}> */}
                    <Card variant="outlined" style={{ borderTop: "1px solid #DDDDDD", borderRight: "none", borderLeft: "none", borderBottom: "none", borderRadius: 2 }}>
                        <CardContent id="card" style={{ cursor: "pointer" }}>

                            {/* onClick={() => postClick(item.id)} */}
                            <Box style={{ padding: "5px" }}>
                                <div id="imagem">
                                    <div>
                                        <div key={item.id} style={{ position: "relative" }}>
                                            <div >
                                                <img onClick={() => postClick(item.id)} src={item.desktop_images[item.cover_image]} className="slider-imagem" />

                                            </div>
                                            <div id="overlay-layer">
                                                {/* <div id="img-count">
                                                        <img src="../../assets/icon/icon-pic-cam.svg"></img>
                                                        <span id="img-count-number">{item.mobile_images.length}</span>
                                                    </div> */}

                                                {/* <div id="post-like" className="like-btn-back">
                                                        <LikeButton item={item} onLike={() => { }} onDislike={() => { }} />
                                                    </div> */}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </Box>
                            <Box id="detail-box" >
                                <div onClick={() => postClick(item.id)} style={{ height: '100%' }}>
                                    {
                                        renderSwitch()
                                    }
                                </div>
                                {/* <section id="details-bottom">

                                    <div id="details-bottom-left" onClick={() => postClick(item.id)}>
                                        <div id="avatar">
                                            <img src={item.author_avatar ? item.author_avatar : "assets/icon/img-blank-profile-avatar.jpg"} id="avatar-icon" />
                                        </div>

                                        <span id="posted-by">{item.posted_by}</span>
                                    </div>

                                    <div>
                                        <a href={openInNewTab()} target="_blank" style={{ textDecoration: 'none' }}>
                                            <Button id="more-btn" className={classes.button}>
                                                <span className="btn-txt">Open</span>
                                                <img src="assets/icon/icon-new-tab.svg"></img>
                                            </Button>
                                        </a>
                                    </div>

                                </section> */}
                            </Box>

                        </CardContent>
                    </Card>
                    {/* </a> */}
                    {/* <div style={{ borderBottom: "2px solid #DDDDDD", width: "100%", marginTop: "15px" }}></div> */}
                </>
            )}
        </NavigationContext.Consumer>
    );
}