import { IonIcon } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "../../features/post/Post";
import { logEvent } from "firebase/analytics"
import { processing, sendPostDislike, sendPostLike } from "../../features/post/postSlice";
import { LIKE_POST, REMOVE_POST_LIKE } from "../../config/events";
import { ACTION_INPUT } from "../../config/actions";
import { selectCurrentFBUser } from "../../features/session/firebaseSlice";
import "./LikeButton.css";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import { BEHAVE_LOGIN_REQUIRED } from "../../providers/NavigationProvider";
import { analytics } from "../..";

const LikeButton = (props: any) => {

  const dispatch = useDispatch();

  const currentFBUser = useSelector(selectCurrentFBUser);

  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (props.item.liked) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  }, [props.item.liked]);

  const likePost = (e: any, post: Post) => {
    if (!liked) {
      setLiked(true);
    } else {
      setLiked(false);
    }
    e.stopPropagation();
    if (!currentFBUser) {
      setLiked(false);
      dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
      return;
    }

    dispatch(processing({ post: post, processing: true }));

    if (post.liked) {
      props.onDislike();
      dispatch(
        sendPostDislike(post.id, () =>
          dispatch(processing({ post: post, processing: false }))
        )
      );
      logEvent(analytics, REMOVE_POST_LIKE, {
        item: props.item,
        action: ACTION_INPUT,
      });
    } else {
      props.onLike();
      dispatch(
        sendPostLike(post.id, () =>
          dispatch(processing({ post: post, processing: false }))
        )
      );
      logEvent(analytics, LIKE_POST, {
        item: props.item,
        action: ACTION_INPUT,
      });
    }
  };

  return (
    <IonIcon
      icon={
        liked
          ? "assets/icon/icon-fd-like-active.svg"
          : "assets/icon/icon-fd-like-normal.svg"
      }
      slot="icon-only"
      id="likeicon"
      onClick={(e) => likePost(e, props.item)}
    ></IonIcon>
  );
};

export default LikeButton;