import { AppBar, Container, Grid, Hidden, makeStyles, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomNav from "../components/BottomNav/BottomNav";
import Boxes from "../components/Boxes/Boxes";
import DetailPageBanner from "../components/DetailPageBanner/DetailPageBanner";
import Footer from "../components/Footer/Footer";
import FooterNew from "../components/Footer/FooterNew";
import HeroSection from "../components/HeroSection/HeroSection";
import MainNav from "../components/MainNav/MainNav";
import MainNavMobile from "../components/MainNav/MainNavMobile";
import Page from "../components/Page/Page";
import PageFooter from "../components/PageFooter/PageFooter";
import TitleBar from "../components/TitleBar/TitleBar";
import TitleBarMobile from "../components/TitleBar/TitleBarMobile";
import TitleBarMobileSecondary from "../components/TitleBar/TitleBarMobileSecondary";
import locations from "../config/locations";
import { selectPosition, setPosition, setScroll } from "../features/scroll/scrollSlice";
import safeAreaInsets from 'safe-area-insets';
import { pulltorefresh } from '@operato/pull-to-refresh';

import {
  NavigationContext,
  NAV_ACCOUNT,
  NAV_ADD_POST,
  NAV_BANK_DEPOSIT,
  NAV_FULL_DETAIL,
  NAV_HOME,
  NAV_LIKES,
  NAV_MEMBERSHIPS,
  NAV_MYADS,
  NAV_MY_MEMBERSHIP,
  NAV_PRIVACY_POLICY,
  NAV_SEARCH_RESULT,
  NAV_SELLER_PROFILE,
  NAV_TERMS_CONDITIONS,
  NOT_FOUND,
  PAYMENT,
  PAYMENT_SUMMERY,
  SELLER_PAGE_SETTINGS,
} from "../providers/NavigationProvider";
import "./Layout.css";

import ScrollRestore from "../components/ScrollRestore";
import { selectCurrentLocation, selectIsSideBarOpen, selectRefresh } from "../features/navigation/navigationSlice";
import { Capacitor } from "@capacitor/core";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      padding: 0,
    },
    [theme.breakpoints.down("md")]: {
      position: 'relative',

    },
  },
  shadow: {
    "& .MuiPaper-elevation1": {
      boxShadow:
        "0px -2px 1px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const Layout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollRef = useRef() as any;
  const [c1, setC1] = useState("");
  const [loc, setLoc] = useState("");
  const location = useLocation();
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));


  const isSidebarOpen = useSelector(selectIsSideBarOpen);

  const [locationKeys, setLocationKeys] = useState([])


  useEffect(() => {
    const url = location.search;
    setLoc(url);
    if (url == '') {
      setC1(location.pathname.split("/")[1]);

    } else {

      const ur = new URLSearchParams(window.location.search);
      setC1(
        ur
          .get("c1")
          ?.replace(/[^\w\s]/g, "")
          .replace(/\s\s+/g, " ")
          .trim() || ""
      );

    }


  }, [window.location.search]);





  useEffect(() => {


    pulltorefresh({
      container: document.body,
      scrollable: document.querySelector('#scrollable-root'),

      refresh: () => {

        window.location.reload()

      },

    })
  }, []);



  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };



  const topBannerHide = (location: string): boolean => {
    return (
      location === NAV_FULL_DETAIL ||
      // location === NAV_SEARCH_RESULT ||
      location == NAV_ADD_POST ||
      location == NAV_PRIVACY_POLICY ||
      location == NAV_TERMS_CONDITIONS ||
      location === NAV_ACCOUNT ||
      location === NAV_LIKES ||
      location === NAV_MYADS ||
      location === NAV_SELLER_PROFILE ||
      location === PAYMENT ||
      location === PAYMENT_SUMMERY ||
      location === NAV_PRIVACY_POLICY ||
      location === NAV_MEMBERSHIPS ||
      location === NOT_FOUND ||
      location === SELLER_PAGE_SETTINGS ||
      location === NAV_MY_MEMBERSHIP ||
      location === NAV_BANK_DEPOSIT


    );
  };
  const BottomBannerHide = (location: string): boolean => {
    return (
      location === NAV_FULL_DETAIL ||
      // location === NAV_SEARCH_RESULT ||
      location == NAV_ADD_POST ||
      location == NAV_PRIVACY_POLICY ||
      location == NAV_TERMS_CONDITIONS ||
      location === NAV_ACCOUNT ||
      location === NAV_LIKES ||
      location === NAV_MYADS ||
      location === NAV_SELLER_PROFILE ||
      location === PAYMENT ||
      location === PAYMENT_SUMMERY ||
      location === NAV_PRIVACY_POLICY ||
      location === NAV_MEMBERSHIPS ||
      location === NOT_FOUND ||
      location === SELLER_PAGE_SETTINGS ||
      location === NAV_MY_MEMBERSHIP ||
      location === NAV_BANK_DEPOSIT


    );
  };

  const SecondaryTitleBarHide = (location: string): boolean => {
    return (
      location === NAV_SEARCH_RESULT ||
      location === NAV_HOME


    );
  };


  const setScrollPosition = (location: string) => {

    const scrollContent: any = document.getElementById("scrollable-root");
    if (
      location === NAV_HOME ||
      location === NAV_SEARCH_RESULT ||
      location === NAV_MYADS ||
      location === NAV_LIKES ||
      location === NAV_SELLER_PROFILE
    ) {

      dispatch(setPosition(scrollContent?.scrollTop));
    }


  };



  return (
    // <Grid container style={{ backgroundColor: "white" }}>
    //   <Grid item lg={12} xs={12} md={12}>
    //     <Hidden xsDown={true} smDown={true}>
    //       <TitleBar></TitleBar>
    //     </Hidden>
    //     <Hidden lgUp={true} mdUp={true}>
    //       <TitleBarMobile></TitleBarMobile>
    //       <TitleBarMobileSecondary />
    //     </Hidden>
    //     <Hidden xsDown={true} smDown={true}>
    //       <MainNav></MainNav>
    //     </Hidden>
    //   </Grid>
    //   <Grid item lg={12} xs={12} md={12} style={{ overflowY: "scroll" }}>
    //     <NavigationContext.Consumer>
    //       {(nav: any) => (
    //         <>
    //           {topBannerHide(nav.current) ? null : (

    //             <HeroSection nav={nav.current}></HeroSection>

    //           )}
    //           {nav.current === NAV_FULL_DETAIL ?

    //             <Hidden xsDown={true} smDown={true}>
    //               <DetailPageBanner />
    //             </Hidden>


    //             : null}
    //           <div id="devider-line-main"></div>


    //           <Paper

    //             id="scrollable-root"
    //             onScroll={(e: any) => setScrollPosition(nav.current)}
    //             ref={scrollRef}
    //             className={classes.shadow}
    //             style={{ height: "auto" }}
    //             elevation={0}
    //             square
    //           >

    //             <Page scroll={scrollRef}></Page>

    //           </Paper>
    //         </>
    //       )}
    //     </NavigationContext.Consumer>
    //   </Grid>
    //   <Grid item lg={12} xs={12} md={12}>
    //     <NavigationContext.Consumer>
    //       {(nav: any) => (
    //         <>

    //           {BottomBannerHide(nav.current) ? null : (

    //               <PageFooter></PageFooter>


    //           )}


    //           <Footer></Footer>

    //         </>
    //       )}
    //     </NavigationContext.Consumer>
    //   </Grid>
    //   <Grid item lg={12} xs={12} md={12}>
    //     <Hidden lgUp={true} mdUp={true}>
    //       <div style={{ position: "absolute", bottom: "0", zIndex: 500, width: "100%" }}>
    //         <BottomNav />
    //       </div>
    //     </Hidden>
    //   </Grid>


    //   <Boxes />
    //   <ToastContainer />
    // </Grid>
    <>

      <AppBar position="static" className="appBar" style={{ height: (safeAreaInsets.support ? safeAreaInsets.top : "40"), boxShadow: "none", zIndex: 20000 }}></AppBar>

      <NavigationContext.Consumer>
        {(nav: any) => (

          <Paper
            id="scrollable-root"
            onScroll={(e: any) => setScrollPosition(nav.current)}
            ref={scrollRef}
            square
            style={{ display: "flex", flexDirection: "column", maxHeight: "100vh", maxWidth: "100%", width: "100%", overflowY: (isSidebarOpen ? 'hidden' : "scroll") }}>

            <Hidden xsDown={true} smDown={true}>
              <TitleBar></TitleBar>
            </Hidden>

            <Hidden lgUp={true} mdUp={true}>

              <TitleBarMobile></TitleBarMobile>
              {(SecondaryTitleBarHide(nav.current) ? <TitleBarMobileSecondary /> : null)}

            </Hidden>

            <Hidden xsDown={true} smDown={true}>
              <MainNav></MainNav>
            </Hidden>


            <>


              <Grid container>
                <Grid lg={12} md={12} item>

                  {topBannerHide(nav.current) ? null : (

                    <>
                    <HeroSection nav={nav.current}></HeroSection>
                    </>

                  )}
                  {nav.current === NAV_FULL_DETAIL ?

                    <Hidden xsDown={true} smDown={true}>
                      <DetailPageBanner />
                    </Hidden>

                    : null}
                </Grid>
                <Grid lg={12} md={12} xs={12} style={{ paddingLeft: (isTabletOrMobile ? "5px" : "auto"), paddingRight: (isTabletOrMobile ? "5px" : "auto") }} item>
                    
                  <Page scroll={scrollRef}></Page>

                </Grid>
                <Grid item lg={12} md={12} >

                  <Hidden xsDown={true} smDown={true}>

                    {/* footer banner */}
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "30px",
                          marginBottom: "40px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: 'white'
                        }}

                      >

                        {(loc == '' ? <>
                          {/* detail page footer */}

                          {isMotor() ? (

                            <iframe scrolling="no"
                              style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                              src="https://services.saruwata.lk/AdManager/DV/DV-Motors-Desktop-Footer-Large-Leaderboard-970x90/index.html" />

                          ) : isProperty() ? (

                            <iframe scrolling="no"
                              style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                              src="https://services.saruwata.lk/AdManager/DV/DV-Property-Sale-Desktop-Footer-Large-Leaderboard-970x90/index.html" />


                          ) : isPropertyRent() ?


                            <iframe scrolling="no"
                              style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                              src="https://services.saruwata.lk/AdManager/DV/DV-Property-Rent-Desktop-Footer-Large-Leaderboard-970x90/index.html" />

                            : isElectronic() ?

                              <iframe scrolling="no"
                                style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/DV/DV-Mobiles-and-Electronics-Footer-Large-Leaderboard-970x90/index.html" />


                              : isClassified() ?


                                <iframe scrolling="no"
                                  style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                  src="https://services.saruwata.lk/AdManager/DV/DV-Classifieds-Desktop-Footer-Large-Leaderboard-970x90/index.html" />

                                : (
                                  <>
                                    {

                                      BottomBannerHide(nav.current) ? null :
                                        <iframe scrolling="no"
                                          style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                          src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />

                                    }



                                  </>





                                )}




                        </> :
                          <>
                            {/* L1 footer */}


                            {isMotor() ? (

                              <iframe scrolling="no"
                                style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/L1-Motors-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />

                            ) : isProperty() ? (

                              <iframe scrolling="no"
                                style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/L1-Property-Sale-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />


                            ) : isPropertyRent() ?


                              <iframe scrolling="no"
                                style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/L1-Property-Rent-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />

                              : isElectronic() ?

                                <iframe scrolling="no"
                                  style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                  src="https://services.saruwata.lk/AdManager/L1/L1-Mobiles-and-Electronics-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />


                                : isClassified() ?


                                  <iframe scrolling="no"
                                    style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                    src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />

                                  : (

                                    null
                                    // <iframe scrolling="no"
                                    //   style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
                                    //   src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Pagination-Large-Leaderboard-970x90/index.html" />


                                  )}

                          </>)}

                      </Grid>
                    </Grid>

                  </Hidden>


                  <FooterNew />
                  <BottomNav />
                </Grid>

              </Grid>

            </>

            <Boxes />

            {/* <ToastContainer /> */}
            <ScrollRestore scroll={scrollRef} />
          </Paper>
        )}
      </NavigationContext.Consumer>
    </>
  );
};

export default Layout;
