import { createSlice } from "@reduxjs/toolkit";
import { NAV_FULL_DETAIL } from "../../providers/NavigationProvider";

export const slice = createSlice({
  name: "navigation",
  initialState: {
    current: "" as string,
    behavior:"" as string,
    previous:'' as string,
    busy: false,
    isSideBarOpen:false,
    refresh:true,
  },

  reducers: {
    setCurrentLocation: (state, action) => {
      state.previous = state.current;
      state.current = action.payload;
     
      if(state.current==NAV_FULL_DETAIL){
        state.refresh=false;
      }
      else{
        state.refresh=true;
      }
     
    },
    setCurrentBehavior: (state, action) => {
      state.behavior = action.payload;
      
    },
    setBusy: (state, action)=>{
      state.busy = action.payload;
    },
    setIsSideBarOpen:(state,action)=>{
      state.isSideBarOpen=action.payload;
    },
   

  },
});

export const { setCurrentLocation, setCurrentBehavior, setBusy,setIsSideBarOpen} = slice.actions;

export const selectCurrentLocation = (state: any) => state.navigation.current;
export const selectCurrentBehavior = (state: any) => state.navigation.behavior;
export const selectPreviousLocation = (state: any) => state.navigation.previous;
export const selectBusy = (state: any) => state.navigation.busy;
export const selectRefresh= (state: any) => state.navigation.refresh;
export const selectIsSideBarOpen = (state: any) => state.navigation.isSideBarOpen;

export default slice.reducer;
