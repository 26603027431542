import {
  AccordionActions,
  Button,
  Chip,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { logEvent } from "firebase/analytics"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CLICK } from "../../../config/actions";
import { CHOOSE_SUB_CATEGORY } from "../../../config/events";
import {
  selectedCategories,
  setCurrentStep,
  setSelectedSubCategory,
} from "../../../features/addPost/addPostSlice";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { setCatSelected } from "../../../pages/AddPost/AddPost";
import "./SubCategoryMobile.css";
import { analytics } from "../../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    click: {
      color: "#0080FF",
      borderColor: "#0080FF",
    },
  })
);

const SubCategoryMobile = (props: any) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const selectedCat = useSelector(selectedCategories);

  const [clicked, setclicked] = useState(false);
  const [key, setkey] = useState();
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const getSubVariableName = (name: string) => {
    return name.toUpperCase().replace(/ /g, "_").replace('&', 'AND');
  }


  useEffect(() => {
    setclicked(false);
    dispatch(setSelectedSubCategory(""));
  }, [selectedCat.level1]);

  const handleItemClick = (sub: any) => {

    setclicked(true);
    setkey(sub.id);
    dispatch(setSelectedSubCategory(sub));
    logEvent(analytics, CHOOSE_SUB_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
    setCatSelected(true);
    dispatch(setCurrentStep(3));



  };

  return (
    <div>
      {props.subCategories.length ? (
        <>
          <Divider style={{ margin: "20px 0px" }} />
          <Typography style={{ color: '#000', fontSize: (getLanguage() == 'english' ? '16px' : '14px'), fontWeight: 600 }}>{lan.SELECT_RIGHT_SUB_CATEGORY}
          </Typography>
        </>
      ) : null}
      <div style={{ height: "18px" }}></div>
      <div className={classes.root}>
        {props.subCategories.map((sub: any) => (
          <Chip
            onClick={() => handleItemClick(sub)}
            key={sub.id}
            // label={
            //   sub.name.charAt(0).toUpperCase() + sub.name.slice(1).toLowerCase()
            // }
            label={
              lan[getSubVariableName(sub.name)]

            }
            variant="outlined"
            clickable={true}
            className={clicked && sub.id == key ? classes.click : ""}
          />
        ))}
      </div>
      <div style={{ height: "15px" }}></div>
      <AccordionActions
        style={{ display: "flex", justifyContent: "flex-start" }}
      >

        {/* <Button
          size="small"
          style={{ marginLeft: 5 }}
          onClick={() => {
            setCatSelected(true);
            dispatch(setCurrentStep(3));
          }}
          variant="contained"
          id="continue-btn"
          disabled={
            selectedCat.level1 && selectedCat.level2 ? false : true
          }
        >
          <span id="continue-btn-txt">Continue</span>
        </Button> */}
      </AccordionActions>
    </div>
  );
};

export default SubCategoryMobile;
