import { Button, Divider, FormControlLabel, Grid, Hidden, MenuItem, Switch, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDistricts } from "../../config/locations";
import { GET_SHOP_DETAILS, UPDATE_BUSINESS_DESCRIPTION, UPDATE_BUSINESS_LOCATION, UPDATE_BUSINESS_MOBILE_NUMBER, UPDATE_BUSINESS_NAME, UPDATE_BUSINESS_UPDATE_TIME } from "../../config/urls";
import { getCities, selectCity } from "../../features/addPost/addPostSlice";
import { selectLanguage } from "../../features/language/language_slice";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { getVerifiedPhone, selectLoggedUser } from "../../features/session/sessionSlice";
import { Language } from "../../language/Language";
import { SELLER_PAGE_SETTINGS } from "../../providers/NavigationProvider";
import { CustomPhoneValidator } from "../ValidatedPhones/CustomPhoneValidator";
import { VerifyPhone } from "../ValidatedPhones/VerifyPhone";
import SellerPageSettingSideBar from "./SellerPageSettingSideBar"
import { useNavigate } from "react-router-dom";

export default function SellerPageSetting() {

    const dispatch = useDispatch();
    const user = useSelector(selectLoggedUser);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setCurrentLocation(SELLER_PAGE_SETTINGS));
    }
    );

    const [state, setState] = React.useState({
        checkedMonday: false,
        checkedTuesday: false,
        checkedWednesday: false,
        checkedThursday: false,
        checkedFriday: false,
        checkedSaturday: false,
        checkedSunday: false,
    });

    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const [businessName, setBusinessName] = useState("");
    const [businessPhoneNumber, setBusinessNumber] = useState("");
    const [businessEmail, setBusinessEmail] = useState("");
    const [businessDetails, setBusinessDetails] = useState("");
    const [businessDistrict, setBusinessDistrict] = useState("") as any;
    const [businessCity, setBusinessCity] = useState("");
    const [businessAddress, setBusinessAddress] = useState("");
    const [phoneVerify, showPhoneVerify] = useState(false);
    const [phone, setPhone] = useState("");
    const [shopData, setShopData] = useState("") as any;

    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    useEffect(() => {

        if (user.email != null && user.email != '') {

            axios.get(GET_SHOP_DETAILS + "?email=" + user.email).then(resp => {


                if (resp.data != null) {
                    setShopData(resp.data);
                    setBusinessName(resp.data.shopName);
                    setBusinessDetails(resp.data.description);
                    // setBusinessDistrict(resp.data.district);
                    // setBusinessCity(resp.data.city);
                    setBusinessAddress(resp.data.address);
                    setBusinessNumber(resp.data?.mobiles[0]);

                }


            }).catch(e => console.log(e));
        } else {

            navigate('/myads');
        }
    }, []);

    const handlePhoneVerify = (phone: any) => {
        showPhoneVerify(false);
        if (phone) getPhone();
    };

    const getPhone = () => {
        dispatch(
            getVerifiedPhone((phones: any) => {
                setPhone(phones?.phones[0] != "" ? phones.phones[0] : "");
                if (phones?.phones[0] != "") {
                    sendRequest();
                }
            })
        );
    };


    const district = useSelector(selectDistricts);
    const cities = useSelector(selectCity);
    const onDistrictSelect = (location: any) => {

        dispatch(getCities(location.id, location.name));


    };
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));




    const timeSlots = ["12.00 AM", "12.30 AM", "01.00 AM", "01.30 AM", "02.00 AM", "02.30 AM", "03.00 AM", "03.30 AM", "04.00 AM", "04.30 AM", "4.00 AM", "4.30 AM", "5.00 AM", "5.30 AM", "6.00 AM", "6.30 AM", "7.00 AM", "7.30 AM", "8.00 AM", "8.30 AM", "9.00 AM", "9.30 AM", "10.00 AM",
        "10.30 AM", "11.00 AM", "11.30 AM", "12.00 PM", "12.30 PM", "01.00 PM", "01.30 PM", "02.00 PM", "02.30 PM", "03.00 PM", "03.30 PM", "04.00 PM", "04.30 PM", "05.00 PM",
        "05.30 PM", "06.00 PM", "06.30 PM", "07.00 PM", "07.30 PM", "08.00 PM", "09.30 PM", "10.00 PM", "10.30 PM", "11.00 PM", "11.30 PM"]

    const updatedTime = {
        sun: {
            open: "-",
            close: "-",
            note: "-"
        },
        mon: {
            open: "-",
            close: "-",
            note: "-"
        },
        tue: {
            open: "-",
            close: "-",
            note: "-"
        },
        wed: {
            open: "-",
            close: "-",
            note: "-"
        },
        thu: {
            open: "-",
            close: "-",
            note: "-"
        },
        fri: {
            open: "-",
            close: "-",
            note: "-"
        },
        sat: {
            open: "-",
            close: "-",
            note: "-"
        }
    }
        ;

    const goHome = () => {
        navigate('/home')
    }

    const updateShopDetails = () => {

        if (businessName != "" && businessPhoneNumber != "" && businessDetails != "" && businessCity != "" && businessDistrict != "" && businessAddress != "") {
            showPhoneVerify(true);

        } else {
            alert("Please Fill Details")
        }



    }
    const sendRequest = () => {

        axios.put(UPDATE_BUSINESS_NAME, {
            "businessName": businessName
        }).then((data: any) => {
            if (data.data == "done") {

                setBusinessName('');
            }
        }).catch(err => {
            console.log(err)
        });

        axios.put(UPDATE_BUSINESS_DESCRIPTION, {
            "description": businessDetails
        }).then((data: any) => {
            if (data.data == "done") {
                setBusinessDetails('');
            }
        }).catch(err => {
            console.log(err)
        });

        axios.put(UPDATE_BUSINESS_LOCATION, {
            "location": businessAddress,
            "latitude": 0,
            "longitude": 0,
            "city": businessCity,
            "district": businessDistrict.name
        }).then((data: any): any => {
            if (data.data == "done") {
                setBusinessAddress('');
                setBusinessDistrict('');
                setBusinessCity('');
            }
        }).catch(err => {
            console.log(err)
        });


        axios.put(UPDATE_BUSINESS_UPDATE_TIME, updatedTime).then((data: any): any => {
            if (data.data == "done") {

            }
        }).catch(err => {
            console.log(err)
        });


        axios.put(UPDATE_BUSINESS_MOBILE_NUMBER, {
            "mobile": businessPhoneNumber
        }).then((data: any) => {
            if (data.data == "Done") {

                setBusinessNumber("")
            }
        }).catch(err => {
            console.log(err)
        });
    }

    return (
        <>
            {user == null || user.email === '' ? (goHome) : (
                <div >

                    <Grid container style={{ marginTop: "10px", paddingLeft: (isTabletOrMobile ? "10px" : "auto") }}>
                        <Grid item xs={12} >
                            <span style={{ fontSize: "20px", fontWeight: 600 }}>Seller Page Settings</span>
                            <Divider style={{ marginTop: "10px" }} />
                        </Grid>
                        <Hidden mdUp={true} lgUp={true}>
                            <Grid item xs={12} >
                                <SellerPageSettingSideBar />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12} >
                                    <div style={{ fontWeight: 600 }}>Customer details</div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={4} style={{ padding: "10px 20px 10px 0px" }}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="filled-required"
                                        label="Business Name"
                                        variant="outlined"
                                        placeholder="ABC (Pvt) Ltd"
                                        value={businessName}
                                        onChange={(e => setBusinessName(e.target.value))}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={4} style={{ padding: "10px 20px 10px 0px" }}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="tel"
                                        id="filled-required"
                                        label="Phone Number"
                                        variant="outlined"
                                        inputProps={{ maxLength: 9 }}
                                        placeholder="71 XXXXXXX"
                                        value={businessPhoneNumber}
                                        onChange={(e: any) => {
                                            const re = /^[0-9\b]+$/;

                                            if (!(e.target.value === '' || re.test(e.target.value))) {
                                                e.target.value = e.target.value.slice(0, -1);;
                                            }
                                            setBusinessNumber(e.target.value)


                                        }
                                        }


                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={4} style={{ padding: "10px 20px 10px 0px" }}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="filled-required"
                                        label="Email Address"
                                        variant="outlined"
                                        placeholder="abc@gmail.com"
                                        value={user.email}
                                        disabled
                                        onChange={(e => setBusinessEmail(e.target.value))}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >
                            <div style={{ fontWeight: 600 }}>About your business</div>
                            <div style={{ padding: "10px 20px 10px 0px" }}>

                                <TextField
                                    id="outlined-multiline-static"
                                    label="About"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    variant="outlined"
                                    value={businessDetails}
                                    onChange={(e => setBusinessDetails(e.target.value))}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} >
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12} >
                                    <div style={{ fontWeight: 600 }}>Location details</div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={4} style={{ padding: "10px 20px 10px 0px" }}>
                                    <TextField

                                        select
                                        required
                                        fullWidth
                                        label={lan.DISTRICT}
                                        variant="outlined"

                                        value={businessDistrict}
                                        onChange={(e: any) => { setBusinessDistrict(e.target.value); onDistrictSelect(e.target.value); }}

                                    >

                                        {district.map((m: any) => (
                                            <MenuItem key={m.id} value={m}>
                                                {m?.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={12} lg={4} style={{ padding: "10px 20px 10px 0px" }}>
                                    <TextField

                                        select
                                        required
                                        fullWidth
                                        label={lan.CITY}
                                        variant="outlined"

                                        value={businessCity}
                                        onChange={(e: any) => { setBusinessCity(e.target.value) }}
                                        disabled={businessDistrict == ''}
                                    >
                                        {cities.map((m: any) => (
                                            <MenuItem value={m}>
                                                {m.split('-')[0]}
                                            </MenuItem>

                                        ))}
                                    </TextField>

                                </Grid>
                                <Grid item xs={12} md={12} lg={4} style={{ padding: "10px 20px 10px 0px" }}>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={8} style={{ padding: "10px 20px 10px 0px" }} >
                                <TextField
                                    required
                                    fullWidth
                                    id="filled-required"
                                    label="Shop Address"
                                    variant="outlined"
                                    value={businessAddress}
                                    onChange={(e => setBusinessAddress(e.target.value))}

                                />

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Monday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedMonday}
                                                        onChange={handleChange}
                                                        name="checkedMonday"
                                                        color="primary"
                                                    />
                                                }
                                                label={(state.checkedMonday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.mon.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedMonday}

                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}



                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.mon.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedMonday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}



                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedMonday}

                                                onChange={(e: any) => {
                                                    updatedTime.mon.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Tuesday */}
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Tuesday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedTuesday}
                                                        onChange={handleChange}
                                                        name="checkedTuesday"
                                                        color="primary"
                                                    />
                                                }
                                                label={(state.checkedTuesday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.tue.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedTuesday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}




                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.tue.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedTuesday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}




                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedTuesday}
                                                onChange={(e: any) => {
                                                    updatedTime.tue.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Wednesday                                */}
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Wednesday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedWednesday}
                                                        onChange={handleChange}
                                                        name="checkedWednesday"
                                                        color="primary"
                                                    />
                                                }
                                                label={(state.checkedWednesday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.wed.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedWednesday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}



                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.wed.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedWednesday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}



                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedWednesday}
                                                onChange={(e: any) => {
                                                    updatedTime.wed.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        {/* Thursday                                */}
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Thursday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedThursday}
                                                        onChange={handleChange}
                                                        name="checkedThursday"
                                                        color="primary"
                                                    />
                                                }
                                                label={(state.checkedThursday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.thu.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedThursday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}




                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.thu.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedThursday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}


                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedThursday}
                                                onChange={(e: any) => {
                                                    updatedTime.thu.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>



                        {/* Friday                                */}
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Friday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedFriday}
                                                        onChange={handleChange}
                                                        name="checkedFriday"
                                                        color="primary"
                                                    />
                                                }
                                                label={(state.checkedFriday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.fri.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedFriday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}




                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.fri.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedFriday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}


                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField

                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedFriday}
                                                onChange={(e: any) => {
                                                    updatedTime.fri.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Saturday                                */}
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Saturday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedSaturday}
                                                        onChange={handleChange}
                                                        name="checkedSaturday"
                                                        color="primary"
                                                    />
                                                }
                                                label={(state.checkedSaturday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.sat.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedSaturday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}




                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.sat.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedSaturday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}


                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField

                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedSaturday}
                                                onChange={(e: any) => {
                                                    updatedTime.sat.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        {/* Sunday                                */}
                        <Grid item xs={12} style={{ padding: "10px 20px 10px 0px" }} >
                            <Grid container>
                                <Grid xs={12} md={12} lg={4}>
                                    <Grid container style={{ paddingTop: "8px" }}>
                                        <Grid item xs={4} lg={4} style={{ padding: "5px 0px 0px 5px" }}>
                                            Sunday
                                        </Grid>
                                        <Grid item xs={8} lg={8} style={{ paddingLeft: "65px" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={state.checkedSunday}
                                                        onChange={handleChange}
                                                        name="checkedSunday"
                                                        color="primary"

                                                    />
                                                }
                                                label={(state.checkedSunday ? "Open" : "Closed")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} lg={8}>
                                    <Grid container>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="Start time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.sun.open = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedSunday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}




                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField
                                                id="contact-category"
                                                select
                                                style={{ width: "100%" }}
                                                label="End time"
                                                // value={category}
                                                onChange={(e: any) => {
                                                    updatedTime.sun.close = e.target.value;
                                                }}
                                                variant="outlined"
                                                disabled={!state.checkedSunday}
                                            >
                                                {timeSlots.map((m: any) => (
                                                    <MenuItem key={m} value={m}>
                                                        {m}
                                                    </MenuItem>
                                                ))}


                                            </TextField>
                                        </Grid>
                                        <Grid xs={12} md={12} lg={4} style={{ paddingRight: "10px" }}>
                                            <TextField

                                                fullWidth
                                                id="filled-required"
                                                label="Special Note"
                                                variant="outlined"
                                                disabled={!state.checkedSunday}
                                                onChange={(e: any) => {
                                                    updatedTime.sun.note = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4} style={{ padding: "10px 20px 10px 0px" }} >
                            <Button disabled={((businessName != '' && businessPhoneNumber != '' && businessDetails != '' && businessCity != "" && businessDistrict != "" && businessAddress != "") ? false : true)} fullWidth variant="contained" color="primary" onClick={() => updateShopDetails()}>Update Details</Button>
                        </Grid>
                    </Grid>

                    {phoneVerify ? (
                        <VerifyPhone
                            show={phoneVerify}
                            phone={businessPhoneNumber}
                            sendRequest={sendRequest}
                            onDismiss={(phone: string = "") => handlePhoneVerify(phone)}
                        />
                    ) : null}
                </div>
            )
            }
        </>

    );
}