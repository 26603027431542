import { Accordion, Avatar, Button, Card, CardActionArea, CardContent, CardMedia, createStyles, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedCategories } from "../../features/addPost/addPostSlice";
import { postSpecs, selectNewPosts } from "../../features/post/postSlice";
import Link from '@material-ui/core/Link';
import { payByAmex, payxx, payxxMembership } from "../../config/func";
import { useNavigate } from 'react-router-dom';
import { getAddOnCodes, selectAddOns, selectAmount, selectBoostAd, selectBundlePack, selectClientRef, selectFeatureAd, selectImg, selectJumpAd, selectPrice, selectQuickSell, selectSeotag, selectTitle } from "../features/addOnServices";
import axios from "axios";
import { ZERO_PAYMENT } from "../../config/urls";
import { toast } from "react-toastify";
import QuickSell from "../AddOn/QuickSell";
import { selectCategory, selectDiscount, selectMembershipDiscountedAmount, selectMembershipEmail, selectMembershipType, selectRefNo, selectSetMembershipActualAmount, selectStatusText } from "../features/membershipPayment";




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 752,
            backgroundColor: "#F9F9F9",
            padding: "10px 20px"

        },
        cardroot: {
            maxWidth: 345,
        },
        media: {
            height: 140,
        },
        wrap: {
            backgroundColor: "#F9F9F9",
        },
        title: {
            margin: theme.spacing(4, 0, 2),
        },
        text: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineClamp: 4,
            boxOrient: 'vertical',
            display: '-webkit-box',
            '& .MuiTypography-body1': {
                fontSize: "12px",
                fontWeight: 600,
                color: "#000000"
            },
            '& .MuiTypography-body2': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#333333"
            }
        },
        paragraphs: {
            color: "#5A5A5A",
            fontSize: "14px"
        },
        multiline: {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiTypography-body1': {
                fontSize: "12px",
                fontWeight: 600,
                color: "#000000",
            },
            '& .MuiTypography-body2': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#333333"
            }
        },
        multilinewrapper: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiListItemText-multiline': {
                marginTop: '0px !important'
            }
        },
        header: {
            '& .MuiTypography-body1': {
                fontSize: "13px",
                color: "#000000",
                marginLeft: "4px"
            },
        },
        listItemText: {
            display: 'flex',
            alignItems: 'center'
        },
        listIcon: {
            minWidth: 36
        },
        cardHeader: {
            fontSize: 15,
            fontWeight: 'bold'
        },
        cardTitle: {
            fontSize: 16,
        },
        pos: {
            marginBottom: 12,
        },
        cardTotalAmount: {
            fontSize: 14,
            fontWeight: 'bold'
        },
        cardTotalAmountPrice: {
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: "right"

        },
        rounded: {
            color: '#fff',
            // backgroundColor: '#C0C0C0'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '90%',
            marginInlineStart: '15px'
        },
    }),
);



const PayementMembershipSideBar = () => {



    const classes = useStyles();
    const navigate = useNavigate();
    const categories = useSelector(selectCategory);
    const membershipType = useSelector(selectMembershipType);
    const refId = useSelector(selectRefNo);
    const discount = useSelector(selectDiscount);
    const membershipDiscountedAmount = useSelector(selectMembershipDiscountedAmount);
    const membershipActualAmount = useSelector(selectSetMembershipActualAmount);
    const userEmail = useSelector(selectMembershipEmail);
    const paymentStatus = useSelector(selectStatusText);

    const [paymentMethod, setPaymentMethod] = useState('');



    const setPaymentTotal = () => {


        return membershipDiscountedAmount * 100;

    }











    return (
        <div>
            <div style={{ height: "39px", borderBottom: "1px solid #0080FF" }}></div>


            <Grid container>

                <Grid item xs={12}>
                    <div className={classes.wrap}>
                        <List style={{ backgroundColor: "#F9F9F9", padding: "0" }}>

                            <div className={classes.root} >

                                <Typography className={classes.cardHeader} >
                                    Payment Summery
                                </Typography>

                                <Grid container style={{ marginTop: '10px' }}>
                                    <Grid item xs={8}>
                                        Reference No.:
                                    </Grid>


                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        {refId}
                                    </Grid>

                                </Grid>

                                <Grid container style={{ marginTop: '10px' }}>

                                    <Grid item xs={8}>
                                        Category:
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>

                                        {categories?.map((item: any) => <Typography style={{ fontSize: "13px" }}>{item}</Typography>)}

                                    </Grid>
                                </Grid>



                                <Grid container style={{ marginTop: '10px' }}>

                                    <Grid item xs={8}>
                                        Business {membershipType}
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        Rs.{membershipActualAmount}
                                    </Grid>
                                </Grid>



                                <Grid container style={{ marginTop: '10px' }}>

                                    <Grid item xs={8}>
                                        Discounted
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        Rs.{discount}
                                    </Grid>
                                </Grid>





                                <br></br>
                                <Divider></Divider>

                                <Grid container style={{ marginTop: '10px', paddingBottom: "10px", borderBottom: "0.5px solid #DDDDDD" }}>
                                    <Grid item xs={8} className={classes.cardTotalAmount}>
                                        Total Payable
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        Rs.{membershipDiscountedAmount}
                                    </Grid>


                                </Grid>



                            </div>
                            <br></br>

                            {
                                (paymentStatus === "Pending" ?
                                    <>

                                        <Card className={classes.cardroot} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>

                                            {(membershipDiscountedAmount == 0 ?
                                                null :
                                                <CardContent>
                                                    <Typography className={classes.cardHeader} >
                                                        Select a payment method
                                                    </Typography>
                                                    <Grid container direction="row" style={{ marginTop: '10px' }} spacing={2}>

                                                        {

                                                            (paymentMethod === 'Visa' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                                <Avatar variant="rounded" className={classes.rounded}
                                                                    alt='Visa'
                                                                    src='../../assets/addOn/icon-visa.svg'
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setPaymentMethod('Visa');

                                                                    }}
                                                                >

                                                                </Avatar>
                                                            </Grid> : <Grid item >
                                                                <Avatar variant="rounded" className={classes.rounded}
                                                                    alt='Visa'
                                                                    src='../../assets/addOn/icon-visa.svg'
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setPaymentMethod('Visa');

                                                                    }}
                                                                >

                                                                </Avatar>
                                                            </Grid>)
                                                        }

                                                        {

                                                            (paymentMethod === 'Master' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                                <Avatar variant="rounded" className={classes.rounded}
                                                                    alt='MasterCard'
                                                                    src='../../assets/addOn/icon-mastercard.svg'
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setPaymentMethod('Master');

                                                                    }}
                                                                >

                                                                </Avatar>
                                                            </Grid> : <Grid item >
                                                                <Avatar variant="rounded" className={classes.rounded}
                                                                    alt='MasterCard'
                                                                    src='../../assets/addOn/icon-mastercard.svg'
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setPaymentMethod('Master');

                                                                    }}
                                                                >

                                                                </Avatar>
                                                            </Grid>)
                                                        }
                                                        {

                                                            (paymentMethod === 'Amex' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                                <Avatar variant="rounded" className={classes.rounded}
                                                                    alt='Amex'
                                                                    src='../../assets/addOn/icon-amex.svg'

                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setPaymentMethod('Amex');

                                                                    }}
                                                                >

                                                                </Avatar>
                                                            </Grid> : <Grid item >
                                                                <Avatar variant="rounded" className={classes.rounded}
                                                                    alt='Amex'

                                                                    src='../../assets/addOn/icon-amex.svg'

                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setPaymentMethod('Amex');

                                                                    }}
                                                                >

                                                                </Avatar>
                                                            </Grid>)
                                                        }
                                                        {

                                                            (paymentMethod === 'Cargils' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                                <Grid item>
                                                                    <Avatar variant="square" className={classes.rounded}
                                                                        alt='Cargils Bank'

                                                                        src='../../assets/addOn/icon-cargils.png'
                                                                        onClick={() => {
                                                                            setPaymentMethod('Cargils');

                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                    </Avatar>
                                                                </Grid>
                                                            </Grid> : <Grid item >
                                                                <Grid item>
                                                                    <Avatar variant="square" className={classes.rounded}
                                                                        alt='Cargils Bank'

                                                                        src='../../assets/addOn/icon-cargils.png'
                                                                        onClick={() => {
                                                                            setPaymentMethod('Cargils');

                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                    </Avatar>
                                                                </Grid>
                                                            </Grid>)
                                                        }
                                                        {

                                                            (paymentMethod === 'Bank' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                                <Grid item>
                                                                    <Avatar variant="rounded" className={classes.rounded}
                                                                        alt='Bank'

                                                                        src='../../assets/addOn/icon-bank.png'
                                                                        onClick={() => {
                                                                            setPaymentMethod('Bank');

                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                    </Avatar>
                                                                </Grid>
                                                            </Grid> : <Grid item >
                                                                <Grid item>
                                                                    <Avatar variant="rounded" className={classes.rounded}
                                                                        alt='Bank'

                                                                        src='../../assets/addOn/icon-bank.png'
                                                                        onClick={() => {
                                                                            setPaymentMethod('Bank');

                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                    </Avatar>
                                                                </Grid>
                                                            </Grid>)
                                                        }



                                                    </Grid>

                                                </CardContent>)}


                                        </Card>
                                        {/* <Card className={classes.cardroot} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                                <CardContent>
                                    <Typography className={classes.cardHeader} >
                                        Pay by voucher
                                    </Typography>
                                </CardContent>
                                <TextField
                                    label="Voucher Code"
                                    defaultValue=""
                                    className={classes.textField}
                                    // helperText="Type your voucher code"
                                    margin="dense"
                                    variant="outlined"
                                />

                                <Link href="#" style={{ marginInlineStart: '15px' }}>
                                    Check balance or combine voucher
                                    {(total > 0)}
                                </Link>
                            </Card> */}


                                        <Typography align='center'>
                                            {

                                                (membershipDiscountedAmount > 0 ?
                                                    (paymentMethod === 'Visa' || paymentMethod === 'Master' ?

                                                        <Button disabled={(paymentMethod === 'Visa' || paymentMethod === 'Master' ? false : true)} variant="contained" onClick={() => payxxMembership(setPaymentTotal(), refId, 'Saruwata.lk Membership Transaction')} color="primary" style={{ width: '88%' }}>
                                                            Pay online
                                                        </Button>
                                                        :
                                                        (paymentMethod === 'Amex' ?
                                                            // onClick={() => payByAmex()}
                                                            <Button disabled={(paymentMethod === 'Amex' ? false : true)} variant="contained" color="primary" style={{ width: '88%' }}>
                                                                Pay online
                                                            </Button>
                                                            :
                                                            (paymentMethod === 'Cargils' ?
                                                                <Button disabled={(paymentMethod === 'Cargils' ? false : true)} variant="contained" onClick={() => navigate('/diectPayment/bankDeposit?price=' + membershipDiscountedAmount + '&ref=' + refId)} color="primary" style={{ width: '88%' }}>
                                                                    Pay By Cargils
                                                                </Button>
                                                                :
                                                                (paymentMethod === 'Bank' ? <Button disabled={(paymentMethod === 'Bank' ? false : true)} onClick={() => navigate('/diectPayment/bankDeposit?price=' + membershipDiscountedAmount + '&ref=' + refId)} variant="contained" color="primary" style={{ width: '88%' }}>
                                                                    Direct Bank Deposit
                                                                </Button> :
                                                                    <Button disabled variant="contained" color="primary" style={{ width: '88%' }}>
                                                                        Select Payment Method
                                                                    </Button>
                                                                )
                                                            )))

                                                    : null)}

                                        </Typography>
                                    </>
                                    :

                                    <Grid container style={{ padding: " 5px 20px" }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div style={{ display: "flex" }}>
                                                <img style={{ width: "100%" }} src="assets/icon/paid.png" />
                                            </div>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <br />
                            <Grid container style={{ padding: " 5px 20px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ display: "flex" }}>
                                        <img style={{ width: "36px", height: "25px", marginTop: "20px" }} src="assets/icon/icon_email.png" />
                                        {
                                            (paymentStatus === "Pending" ? <p className={classes.paragraphs} style={{ marginLeft: "10px" }}>You'll receive a confirmation payment email shortly to: <a style={{ color: "#0080FF" }}>{userEmail}</a></p>
                                                :
                                                <p className={classes.paragraphs} style={{ marginLeft: "10px" }}>We sent a payment invoice email to: <a style={{ color: "#0080FF" }}>{userEmail}</a></p>
                                            )}
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ display: "flex" }}>
                                        <img style={{ width: "36px", height: "36px", marginTop: "20px" }} src="assets/icon/icon-support.svg" />
                                        <p className={classes.paragraphs} style={{ marginLeft: "10px" }}>For inquiries please contact our hotline by dialing +94 117 455 955 from any network or write to us on <span style={{ color: "#0080FF" }}>service@saruwata.lk</span></p>
                                    </div>
                                </Grid>
                                <br />

                                <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto", marginTop: "20px", marginBottom: "20px" }}>
                                    <a href="https://trustlock.co" ><img alt="Trust Badges" style={{ border: 0 }} src="https://trustlock.co/wp-content/uploads/2019/01/guaranteed-safe-checkout-4.png" width="115" height="23" /></a>

                                    <a href="https://trustlock.co" ><img alt="Trust Badges" style={{ border: 0, marginLeft: "5px" }} src="https://trustlock.co/wp-content/uploads/2019/01/ssl-secure-trust-badge-free.png" width="115" height="23" /></a>
                                </div>

                                <br />
                            </Grid>


                            <Divider variant="middle"></Divider>

                            <Typography style={{ textAlign: 'left', fontSize: '12px', padding: "10px 20px" }}>
                                By confirming payment, you are approving our <span style={{ color: "#0080FF" }}>Terms & Conditions</span> and acknowledging our <span style={{ color: "#0080FF" }}>Privacy Policy</span>. Your payment information and details will remain confidential.   </Typography>


                            <br></br>

                        </List>
                    </div>
                </Grid>
            </Grid>
            <br></br>
            <br></br>

        </div >
    );
}
export default PayementMembershipSideBar;