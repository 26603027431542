import axios from "axios";
import { ENDPOINT, CONFIRM_PAYMENT, ENDPOINT_MEMBERSHIP_SAMPATH, CONFIRM_PAYMENT_MEMBER, CONFIRM_MEMBERSHIP_PURCHASE_PAYMENT } from "./urls";





function buildPayment(PA, CR, COM, MOBILE) {
  // Build the payment object

  return {
    clientId: 14004561,
    paymentAmount: PA,
    currency: 'LKR',
    returnUrl: ENDPOINT,
    clientRef: CR,
    tokenize: true,
    comment: COM,
    extraData: { "mobile": MOBILE }

  };

}
function buildPaymentMembership(PA, refId, COM) {
  // Build the payment object

  return {
    clientId: 14004561,
    paymentAmount: PA,
    currency: 'LKR',
    returnUrl: ENDPOINT_MEMBERSHIP_SAMPATH,
    clientRef: refId,
    tokenize: true,
    comment: COM,


  };

}


// export const payxx=function(data){

//     var btn=document.getElementById("abc");
//     btn.setAttribute('onClick','loadPaycorpPayment({"clientId": 14002582,"paymentAmount": 1010,"currency": "LKR","returnUrl": "http://localhost:3000/payment-responsesss","clientRef": "CREF-12345","tokenize": true,"comment": "This is a demo payment"});');
//     console.log('loadPaycorpPayment('+data+');')
//     btn.click();


// }
// export const payxx=function(PA, CR, COM,Mob){
//     var d=JSON.stringify(buildPayment(PA, CR, COM,Mob));
//     var dd=buildPayment(PA, CR, COM);

//     var btn=document.getElementById("abc");
//     // btn.setAttribute('onClick','loadPaycorpPayment({"clientId": 14002582,"paymentAmount": 1010,"currency": "LKR","returnUrl": "http://localhost:3000/payment-responsesss","clientRef": "CREF-12345","tokenize": true,"comment": "This is a demo payment"});');
//   var pay = Number(PA);
//   var data ="loadPaycorpPayment({clientId: 14004561,paymentAmount:"+pay+",currency: 'LKR',returnUrl: 'http://localhost:3000/paymentresp',clientRef: 'CREF-12345',tokenize: true,comment: 'This is a demo payment'});";
//   // var data ="loadPaycorpPayment({\"clientId\": 14004561,\"paymentAmount\":"+pay+",\"currency\": \"LKR\",\"returnUrl\": \"http://localhost:3000/paymentresp\",\"clientRef\": \"CREF-12345\",\"tokenize\": true,\"comment\": \"This is a demo payment\"});";
//   // var data1 ="loadPaycorpPayment({\"clientId\": 14004561,\"paymentAmount\":"+pay+",\"currency\": \"LKR\",\"returnUrl\": \"http://localhost:3000/paymentresp\",\"clientRef\": \"CREF-12345\",\"tokenize\": true,\"comment\": \"This is a demo payment\"});";
//   btn.setAttribute('onClick',data);

//     // btn.setAttribute('onClick','loadPaycorpPayment('+d+')');
//   //  console.log('loadPaycorpPayment('+d+');')
//     btn.click();

// }



export const payxx = function (PA, CR, COM, Mob, ac, seoTag) {

  var details = null;


  fetch(
    ' https://geolocation-db.com/json/4c5c79b0-f162-11eb-bd83-53373d3682fb'
  ).then(response => response.json())
    .then(data => {

      details = data.IP;
    });


  var addOnCodes = [];
  if (ac.bundlePack != '') {
    addOnCodes.push(ac.bundlePack);
  }
  if (ac.jumpAd != '') {
    addOnCodes.push(ac.jumpAd);
  }
  if (ac.boostAd != '') {
    addOnCodes.push(ac.boostAd);
  }
  if (ac.featureAd != '') {
    addOnCodes.push(ac.featureAd);
  }
  if (ac.quickSell != '') {
    addOnCodes.push(ac.quickSell);
  }

  //   var config = {
  //     headers: {'Access-Control-Allow-Origin': '*'}
  // };


  axios.post(CONFIRM_PAYMENT, {

    postId: "",
    seoTag: seoTag,
    ref: CR,
    amount: PA,
    payIp: details,
    addons: addOnCodes,
  })
    .then(value => {
      if (value.data === "Done") {

        confirmPayment(PA, CR, COM, Mob);
      }
    }).catch(error => console.log(error.data));


}
function confirmPayment(PA, CR, COM, Mob) {


  var btn = document.getElementById("abc");
  var d = JSON.stringify(buildPayment(PA, CR, COM, Mob));
  var data = "loadPaycorpPayment(" + d + ");";
  btn.setAttribute('onClick', data);
  btn.click();

}




export const payxxMembership = function (PA, refId, COM) {

  var details = null;


  fetch(
    ' https://geolocation-db.com/json/4c5c79b0-f162-11eb-bd83-53373d3682fb'
  ).then(response => response.json())
    .then(data => {

      details = data.IP;
    });



  axios.get(CONFIRM_MEMBERSHIP_PURCHASE_PAYMENT + "?salesId=" + refId + "&amount=" + PA)
    .then(value => {
      if (value.data === "Done") {

        confirmPaymentMembership(PA, refId, COM);
      }
    }).catch(error => console.log(error.data));


}

function confirmPaymentMembership(PA, refId, COM) {


  var btn = document.getElementById("abc");
  var d = JSON.stringify(buildPaymentMembership(PA, refId, COM));
  var data = "loadPaycorpPayment(" + d + ");";
  btn.setAttribute('onClick', data);
  btn.click();

}
export function payByAmex() {
  document.forms['amex'].submit();
}



//membership ad payment
export const payxx_Ad_Payement_Membership = function (PA, CR, COM, ac, seoTag, uhmId, voucherDiductAmount, extratAmount, callBack) {

  var details = null;


  // fetch(
  //   ' https://geolocation-db.com/json/4c5c79b0-f162-11eb-bd83-53373d3682fb'
  // ).then(response => response.json())
  //   .then(data => {

  //     details = data.IP;
  //   });


  var addOnCodes = [];
  if (ac.bundlePack != '') {
    addOnCodes.push(ac.bundlePack);
  }
  if (ac.jumpAd != '') {
    addOnCodes.push(ac.jumpAd);
  }
  if (ac.boostAd != '') {
    addOnCodes.push(ac.boostAd);
  }
  if (ac.featureAd != '') {
    addOnCodes.push(ac.featureAd);
  }
  if (ac.quickSell != '') {
    addOnCodes.push(ac.quickSell);
  }


  axios.post(CONFIRM_PAYMENT_MEMBER, {

    postId: "",
    seoTag: seoTag,
    ref: CR,
    amount: PA,
    payIp: details,
    addons: addOnCodes,
    uhmId: uhmId,
    voucherDiductAmount: voucherDiductAmount,
    extratAmount: extratAmount,
  })
    .then(value => {
      if (value.data === "Done") {
        callBack();
        // confirmPayment(PA, CR, COM);
      }
    }).catch(error => console.log(error.data));


}

