import { createSlice } from "@reduxjs/toolkit";
import React from "react";

export const slice = createSlice({
    name: "addOnValues",
    initialState: {
        bundlePack: [],
        jumpAd: [],
        boostAd: [],
        quickSell: [],
        featuredAd: [],
        voucherFreeAmount:0,
        reference:'',
        uhmid:'',
        isMember:false,
    },
    reducers: {
        setBundleValues: (state, action) => {

            state.bundlePack = action.payload;
        },
        setJumpAdValues: (state, action) => {

            state.jumpAd = action.payload;
        },
        setBoostAdValues: (state, action) => {

            state.boostAd = action.payload;
        },
        setQuickSellValues: (state, action) => {

            state.quickSell = action.payload;
        },
        setFeaturedAdValues: (state, action) => {

            state.featuredAd = action.payload;
        },
        setVoucherFreeAmount:(state,action)=>{
            state.voucherFreeAmount=action.payload;
        },
        setReferenceMember:(state,action)=>{
            state.reference=action.payload;
        },
        setUhmId:(state,action)=>{
            state.uhmid=action.payload;
        },
        setIsMember:(state,action)=>{
            state.isMember=action.payload;
        }

    }
});

export const {
    setBundleValues,
    setJumpAdValues,
    setBoostAdValues,
    setQuickSellValues,
    setFeaturedAdValues,
    setVoucherFreeAmount,
    setReferenceMember,
    setUhmId,
    setIsMember

} = slice.actions;

export const selectBundleValues = (state: any) => state.addOnValues.bundlePack;
export const selectJumpAdValues = (state: any) => state.addOnValues.jumpAd;
export const selectBoostAdValues = (state: any) => state.addOnValues.boostAd;
export const selectQuickSellValues = (state: any) => state.addOnValues.quickSell;
export const selectFeaturedAdValues = (state: any) => state.addOnValues.featuredAd;
export const selectVoucherFreeAmount=(state:any)=>state.addOnValues.voucherFreeAmount;
export const selectReferenceMember=(state:any)=>state.addOnValues.reference;
export const selectUhmId=(state:any)=>state.addOnValues.uhmid;
export const selectIsMember=(state:any)=>state.addOnValues.isMember;
export default slice.reducer;