import { IonCol, IonGrid, IonIcon, IonRow, useIonViewDidEnter } from "@ionic/react";
import { Avatar, Box, createStyles, Fade, FormControlLabel, LinearProgress, makeStyles, Paper, Slide, Switch, Theme } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CLICK } from "../../../config/actions";
import { CHOOSE_CATEGORY } from "../../../config/events";
import { selectedCategories, setCurrentStep, setSelectedCategories } from "../../../features/addPost/addPostSlice";
import { selectCategories } from "../../../features/tags/tagsSlice";
import SubCategoryMobile from "../SubCategory/SubCategoryMobile";
import './SelectCategoryMobile.css';
import BackIcon from "@material-ui/icons/ArrowBackSharp"
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../..";




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: "column",
            flexWrap: 'wrap',
            justifyContent: "space-between",
            '& > *': {
                margin: theme.spacing(2),
            },
            position: 'relative'
        },
        paper: {
            '&:hover': {
                boxShadow: "0px 0px 8px #00000026",
            },
        }
    }),
);
var status = 0;

export function isStatusOk() {
    return status;
}

const SelectCategoryMobile = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [hover, sethover] = useState(false);
    const selectedCat = useSelector(selectedCategories);
    const savedCat = useSelector(selectCategories);
    const [subCategories, setSubCategories] = useState([]);
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    useEffect(() => {

        setSubCategories(
            savedCat
                .filter((l1: any) => l1.name == selectedCat.level1)
                .flatMap((sub: any) => sub.sub_categories)
        );
    }, [selectedCat.level1]);


    if (props.isSubNull == "Okay") {
        status = 1;
    } else if (props.isSubNull == "Wait") {
        status = 0;

    }

    const icons = [
        "assets/icon/icon-motors.svg",
        "assets/icon/icon-electronics.svg",
        "assets/icon/icon-property-for-sale.svg",
        "assets/icon/icon-proerty-for-rent.svg",
        "assets/icon/icon-classifies.svg"
    ];




    const Motors = () => {

        dispatch(setSelectedCategories({ name: 'MOTORS', icon: icons[0] }));
        dispatch(setCurrentStep(2));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
        handleChange();
    };
    const MobileElectronics = () => {
        dispatch(setSelectedCategories({ name: 'MOBILES AND ELECTRONICS', icon: icons[1] }));
        dispatch(setCurrentStep(2));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
        handleChange();
    };
    const PropertySale = () => {
        dispatch(setSelectedCategories({ name: 'PROPERTY FOR SALE', icon: icons[2] }));
        dispatch(setCurrentStep(2));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
        handleChange();
    };
    const PropertyRent = () => {
        dispatch(setSelectedCategories({ name: 'PROPERTY FOR RENT', icon: icons[3] }));
        dispatch(setCurrentStep(2));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
        handleChange();
    };
    const Classifieds = () => {

        dispatch(setSelectedCategories({ name: 'CLASSIFIEDS', icon: icons[4] }));
        dispatch(setCurrentStep(2));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
        handleChange();
    };


    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    function isSaveCatNotNull() {
        if (savedCat.length > 0) {
            return true;
        }
        return false;
    }



    return (
        <>
            <div id="main-container-sc" >
                <Fade in={!checked}>
                    {
                        (isStatusOk() ?

                            <section className={classes.root} >

                                <Paper id={(selectedCat.level1 == "MOTORS") ? "btn-border-sc-active" : "btn-border-sc"} onClick={Motors} className={classes.paper} elevation={0}>
                                    <div id="btn-inner-sc">
                                        <div>
                                            <IonIcon id="icon-car-sc" src="assets/icon/icon-motors.svg"></IonIcon>
                                        </div>
                                        <div className="cat-title-text">
                                            <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>{lan.MOTORS}</span>
                                        </div>
                                    </div>
                                    {
                                        (selectedCat.level1 == "MOTORS") ?
                                            <div className="checkicon-category">
                                                <img
                                                    src='assets/icon/icon-selected-2.svg'
                                                    style={{ width: "22px", height: "22px" }}
                                                ></img>
                                            </div>
                                            :
                                            null
                                    }
                                </Paper>
                                <Paper id={(selectedCat.level1 == "MOBILES AND ELECTRONICS") ? "btn-border-sc-active" : "btn-border-sc"} onClick={MobileElectronics} className={classes.paper} elevation={0}>
                                    <div id="btn-inner-sc">
                                        <div>
                                            <IonIcon id="icon-mobile-sc" src="assets/icon/icon-electronics.svg"></IonIcon>

                                        </div>
                                        <div className="cat-title-text">
                                            <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}> {lan.MOBILE_AND_ELECTRONICS}</span>
                                        </div>
                                    </div>
                                    {
                                        (selectedCat.level1 == "MOBILES AND ELECTRONICS") ?
                                            <div className="checkicon-category">
                                                <img
                                                    src='assets/icon/icon-selected-2.svg'
                                                    style={{ width: "22px", height: "22px" }}
                                                ></img>
                                            </div>
                                            :
                                            null
                                    }
                                </Paper>
                                <Paper id={(selectedCat.level1 == "PROPERTY FOR SALE") ? "btn-border-sc-active" : "btn-border-sc"} onClick={PropertySale} className={classes.paper} elevation={0}>
                                    <div id="btn-inner-sc">
                                        <div>
                                            <IonIcon id="icon-car-sc" src="assets/icon/icon-property-for-sale.svg"></IonIcon>

                                        </div>
                                        <div className="cat-title-text">
                                            <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}> {lan.PROPERTY_FOR_SALE}</span>
                                        </div>
                                    </div>
                                    {
                                        (selectedCat.level1 == "PROPERTY FOR SALE") ?
                                            <div className="checkicon-category">
                                                <img
                                                    src='assets/icon/icon-selected-2.svg'
                                                    style={{ width: "22px", height: "22px" }}
                                                ></img>
                                            </div>
                                            :
                                            null
                                    }

                                </Paper>
                                <Paper id={(selectedCat.level1 == "PROPERTY FOR RENT") ? "btn-border-sc-active" : "btn-border-sc"} onClick={PropertyRent} className={classes.paper} elevation={0}>
                                    <div id="btn-inner-sc">
                                        <div >
                                            <IonIcon id="icon-car-sc" src="assets/icon/icon-proerty-for-rent.svg"></IonIcon>

                                        </div>
                                        <div className="cat-title-text">
                                            <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>{lan.PROPERTY_FOR_RENT}</span>
                                        </div>
                                    </div>
                                    {
                                        (selectedCat.level1 == "PROPERTY FOR RENT") ?
                                            <div className="checkicon-category">
                                                <img
                                                    src='assets/icon/icon-selected-2.svg'
                                                    style={{ width: "22px", height: "22px" }}
                                                ></img>
                                            </div>
                                            :
                                            null
                                    }
                                </Paper>
                                <Paper id={(selectedCat.level1 == "CLASSIFIEDS") ? "btn-border-sc-active" : "btn-border-sc"} onClick={Classifieds} className={classes.paper} elevation={0}>
                                    <div id="btn-inner-sc">
                                        <div>
                                            <IonIcon id="icon-car-sc" src="assets/icon/icon-classifies.svg"></IonIcon>
                                        </div>
                                        <div className="cat-title-text">
                                            <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>{lan.CLASSIFIEDS}</span>
                                        </div>
                                    </div>
                                    {
                                        (selectedCat.level1 == "CLASSIFIEDS") ?
                                            <div className="checkicon-category">
                                                <img
                                                    src='assets/icon/icon-selected-2.svg'
                                                    style={{ width: "22px", height: "22px" }}
                                                ></img>
                                            </div>
                                            :
                                            null
                                    }
                                </Paper>

                            </section>



                            :
                            <section>
                                <LinearProgress color="primary" />
                            </section>)

                    }


                </Fade>

                <Box
                    sx={{
                        height: "auto",
                        width: '100%',
                        position: "absolute",
                        top: "0",
                        display: 'flex',
                        padding: 2,
                        borderRadius: 1,
                        zIndex: (checked ? "100" : "-100"),
                        bgcolor: (theme: any) =>
                            theme.palette.mode === 'light' ? 'grey.100' : 'grey.900',
                        overflow: 'hidden',
                    }}
                // ref={document.getElementById('main-container-sc')}
                >
                    <Box >
                        {/* <FormControlLabel
                            control={<Switch checked={checked} onChange={handleChange} />}
                            label="Show from target"
                        /> */}
                        <Slide direction="left" in={checked} >

                            {(isSaveCatNotNull() ? <div>
                                <div style={{ display: 'flex', marginTop: "auto", marginBottom: "auto" }}>
                                    <Avatar style={{ width: "25px", height: "25px", marginRight: "10px" }} onClick={() => { handleChange(); dispatch(setCurrentStep(1)); }}> <BackIcon /></Avatar>{lan.BACK_TO_SELECTED_CATEGORY}
                                </div>
                                <SubCategoryMobile subCategories={subCategories} />
                            </div> : <></>)}

                        </Slide>
                    </Box>
                </Box >
            </div >

        </>

    )
}

export default SelectCategoryMobile;