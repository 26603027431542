// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#devider-line-main{
    height: 2px;
    border-bottom: 2px solid #EBEEF1;
    width: 100%;
    margin-bottom: 15px;
}
.appBar{
  
    padding-top: 0;
    padding-top: env(safe-area-inset-top);
  
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Layout.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gCAAgC;IAChC,WAAW;IACX,mBAAmB;AACvB;AACA;;IAEI,cAAc;IACd,qCAAqC;;AAEzC","sourcesContent":["#devider-line-main{\n    height: 2px;\n    border-bottom: 2px solid #EBEEF1;\n    width: 100%;\n    margin-bottom: 15px;\n}\n.appBar{\n  \n    padding-top: 0;\n    padding-top: env(safe-area-inset-top);\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
