import React, { useEffect, useState } from "react";
import "./CustomPhoneValidator.css";
import { addVerifiedPhone, selectLoggedUser } from "../../features/session/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import SlideUpTransition from "../../animations/SlideUpTransition";
import CloseIcon from "@material-ui/icons/Close";
import PuffLoader from "react-spinners/PuffLoader";
import { toast } from "react-toastify";
import axios from "axios";
import { selectVerification, setVerificationCode } from "../../features/sms/smsVerifications";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../..";
var phoneToken = require('generate-sms-verification-code')


const window = {
  recaptchaVerifier: undefined as any,
  confirmationResult: undefined as any,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
    },
    btns: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: "none",
    },
    errormsg: {
      marginBottom: theme.spacing(2),
      color: "red",
    },
    footer: {
      marginBottom: theme.spacing(2),
      textAlign: "center",
      color: "#5A5A5A",
      fontSize: 14,
    },
    closebtn: {
      right: 8,
      position: "absolute",
      top: 8,
    },
    loader: {
      position: "absolute",
      top: 50,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "#ffffff",
      zIndex: 9999,
    },
  })
);

export const CustomPhoneValidator = (props: any) => {
  const [phoneAdded, setPhoneAdded] = useState(false);
  const [error, setError] = useState<any>("");
  const [displayError, setDisplayError] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const [busy, setBusy] = useState(false);
  const verification = useSelector(selectVerification);
  const user = useSelector(selectLoggedUser);

  const classes = useStyles();

  const toastifyProperties: object = {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  const handleLoading = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response: any) => { },
        "expired-callback": () => { },
      }, auth
    );
  };

  useEffect(() => {
    if (error == "") {
      setDisplayError("");
      return;
    }
    const code = error?.code;
    switch (code) {
      case "auth/provider-already-linked":
        setDisplayError("Phone number already in use");
        break;
      case "auth/captcha-check-failed":
        setDisplayError("Try again after re-login");
        break;
      case "auth/invalid-phone-number":
        setDisplayError("Invalid phone number");
        break;
      case "auth/missing-phone-number":
        setDisplayError("Enter phone number");
        break;
      case "auth/user-disabled":
        setDisplayError("Your account is disabled, contact support");
        break;
      case "auth/invalid-verification-code":
        setDisplayError("OTP code is invalid");
        break;
      default:
        setDisplayError(code);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      window.confirmationResult = undefined;
      window.recaptchaVerifier = undefined;
    };
  }, []);

  function generateCode() {

    const tel = `94${phone}`;
    const c = String(phoneToken(6, "number"));
    dispatch(setVerificationCode(c))

    const link = `https://sms.saruwata.lk/OTP/send/jhbdfchsb8943oe88hdgwsu2837y498274383745r0345/${c}/${tel}`;
    axios.get(link).then(data => {
      setBusy(false);
      window.confirmationResult = data;
      setPhoneAdded(true);
      if (data.data == 'Invalid number') {
        setError("Invalid Phone Number");

      } else {

        // alert("Your Verification Number Sent")
      }
    }).catch(e => console.log(e));

  }
  const handleSendCode = () => {
    if (phone === "" || phone == null) {
      setDisplayError("Enter phone number");
      return;
    }
    // const tel = `+94${phone}`; 

    generateCode();


    setBusy(true);
    //   firebase
    //     .auth()
    //     .currentUser?.linkWithPhoneNumber(tel, window.recaptchaVerifier)
    //     .then((confirm: any) => {
    //       window.confirmationResult = confirm;
    //       setPhoneAdded(true);
    //       setError("");
    //       // setTimer(10);
    //     })
    //     .catch((error: any) => {
    //       setPhoneAdded(false);
    //       setError(error);
    //     })
    //     .finally(() => setBusy(false));


  };

  const handlesubmit = () => {
    if (code === "") {
      setDisplayError("Enter the OTP code");
      return;
    }

    setBusy(true);
    // window.confirmationResult
    //   .confirm(code)
    //   .then((res: any) => {
    //     props.onDismiss(phone);
    //     firebase
    //       .auth()
    //       .currentUser?.getIdToken()
    //       .then((token: any) => {
    //         dispatch(
    //           addVerifiedPhone(
    //             token,
    //             (phone: string) => {
    //               toast.success("Success", toastifyProperties);
    //               props.onDismiss(phone);
    //             },
    //             (err: any) => {
    //               toast.error("Error", toastifyProperties);
    //               props.onDismiss(null);
    //             }
    //           )
    //         );
    //       });
    //     setError("");
    //   })
    //   .catch((error: any) => {
    //     setError(error);
    //   })
    //   .finally(() => setBusy(false));

    if (code === verification) {
      props.onDismiss(phone);

      dispatch(
        addVerifiedPhone(
          user.token,
          (phone: string) => {
            toast.success("Success", toastifyProperties);
            props.onDismiss(phone);
          },
          (err: any) => {
            toast.error("Error", toastifyProperties);
            props.onDismiss(null);
          },
          phone
        )
      )

      setError("");
      setBusy(false)
    } else {
      alert("code error");
      setBusy(false);
    }

  };

  return (
    <Dialog
      open={props.show}
      TransitionComponent={SlideUpTransition}
      onExiting={() => props.onDismiss(null)}
      onEntering={() => handleLoading()}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle className={classes.title}>
        {phoneAdded ? "Verify phone number" : "Change phone number"}
        <IconButton
          className={classes.closebtn}
          onClick={() => props.onDismiss(null)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="inner-wrapper">
        {phoneAdded ? (
          <div className="input-wrapper">
            <TextField
              fullWidth={true}
              label={`OTP code for '${phone}'`}
              value={code || ""}
              variant="outlined"
              placeholder="XXXXXX"
              onChange={(e: any) => setCode(e.target.value)}
            />
          </div>
        ) : (
          <div>
            <TextField
              fullWidth={true}
              label="Phone number"
              value={phone || ""}
              variant="outlined"
              placeholder="7xx xxx xxx"
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </div>
        )}
        {phoneAdded ? (
          <Button
            onClick={handlesubmit}
            className={classes.btns}
            fullWidth={true}
            variant="contained"
            color="primary"
          >
            Verify
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.btns}
            color="primary"
            fullWidth={true}
            onClick={handleSendCode}
            id="sign-in-button"
          >
            Send Code
          </Button>
        )}
        <div className={classes.errormsg}>{displayError}</div>
        <div className={classes.footer}>
          If you can't add phone number successfully, please contact out support
        </div>
      </DialogContent>
      {busy ? (
        <div className={classes.loader}>
          <Box display="flex" justifyContent="center" padding={5}>
            <PuffLoader loading={true} color="#0080ff" />
          </Box>
        </div>
      ) : null}
    </Dialog>
  );
};
