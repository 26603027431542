import { logEvent } from "firebase/analytics"
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ACTION_CLICK } from "../../../config/actions";
import { CLICK_ON_POST_IMAGE } from "../../../config/events";
import { sendPostClick, setSelectedPost } from "../../../features/post/postSlice";
import DefaultSerp from "./DetailSection/DefaultSerp";
import Mobile from "./DetailSection/MobileElectronics/Mobile";
import Cars from "./DetailSection/Motors/Cars/cars";
import ApartmentHouse from "./DetailSection/Property/AprtmentHouse";
import Commercial from "./DetailSection/Property/Commercial";
import CommercialRent from "./DetailSection/Property/CommercialRent";
import LandCommercial from "./DetailSection/Property/LandCommercial";
import './PostCard.css';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Button, Card, CardContent } from "@material-ui/core";
import { NavigationContext, NAV_LIKES } from "../../../providers/NavigationProvider";
import LikeButton from "../../LikeButton/LikeButton";
import { apartment_rent, apartment_sale, bikes, cars, commercial_rent, commercial_sale, daily, house_rent, house_sale, land_rent, land_sale, mobiles, monthly } from "../../../constants/categories";
import LandRent from "./DetailSection/Property/LandRent";
import { frontend } from "../../../config/urls";
import { NoEncryption } from "@material-ui/icons";
import { getUrl } from "ionicons/dist/types/components/icon/utils";
import AWS from 'aws-sdk';
import { useNavigate } from "react-router-dom";

export default function PostCard(this: any, props: any) {

    const item = props.post;
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const sliderRef = useRef<any>(null);
    const [stopSlide, setStopSlide] = useState(true);

    const ID = 'AKIAQ2KCF3STN6DPFLUA';
    const SECRET = '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';

    // The name of the bucket that you have created
    const BUCKET_NAME = 'saruwata-bucket';
    const s3 = new AWS.S3({
        accessKeyId: ID,
        secretAccessKey: SECRET
    });

    useEffect(() => {
        sliderRef.current?.slideNext();
    }, []);

    setTimeout(() => {
        if (stopSlide) sliderRef.current?.slidePrev();
        setStopSlide(false);
    }, 3000);

    const postClick = (id: number) => {
        handleFullViewClick();
        dispatch(sendPostClick(id));
    };

    const handleFullViewClick = () => {
        dispatch(setSelectedPost(item));
        navigate(`/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`);
    }

    const openInNewTab = (itm: any) => {
        dispatch(setSelectedPost(itm));
        window.open(`${frontend}/${itm.main_category.slug}/${itm.sub_category.slug}/${itm.hash}`, "_blank")
        // return `https://saruwata.lk/${itm.main_category.slug}/${itm.sub_category.slug}/${itm.hash}`;
    }




    const renderSwitch = () => {

        switch (item?.sub_category?.name) {
            case cars:
                return <Cars item={item}></Cars>;
            case bikes:
                return <Cars item={item}></Cars>;
            case mobiles:
                return <Mobile item={item}></Mobile>;
            case apartment_sale:
                return <ApartmentHouse item={item}></ApartmentHouse>;
            case house_sale:
                return <ApartmentHouse item={item}></ApartmentHouse>;
            case commercial_sale:
                return <Commercial item={item}></Commercial>;
            case land_sale:
                return <LandCommercial item={item}></LandCommercial>;
            case land_rent:
                return <LandRent item={item}></LandRent>;
            case apartment_rent:
                return <ApartmentHouse item={item}></ApartmentHouse>;
            case house_rent:
                return <ApartmentHouse item={item}></ApartmentHouse>;
            case daily:
                return <ApartmentHouse item={item}></ApartmentHouse>;
            case monthly:
                return <ApartmentHouse item={item}></ApartmentHouse>;
            case commercial_rent:
                return <CommercialRent item={item}></CommercialRent>;
            default:
                return <DefaultSerp item={item} />;

        }
    };



    const useStyles = makeStyles({
        root: {
            "&:hover": {
                boxShadow: "0px 0px 8px #0000004D",
            },
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        button: {
            fontFamily: 'Titillium Web',
        }
    });

    const classes = useStyles();
    const link = `${frontend}/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`;

    return (
        <NavigationContext.Consumer>
            {(nav: any) => (
                <>
                    {/* <a href={link} style={{ textDecoration: 'none' }}> */}
                    <Card variant="outlined" className={classes.root} style={{ borderTop: "1px solid #DDDDDD", borderRight: "none", borderLeft: "none", borderBottom: "none", borderRadius: 2, position: "relative" }}>
                        <CardContent id="card" style={{ cursor: "pointer" }}>

                            {/* onClick={() => postClick(item.id)} */}
                            <Box style={{ padding: "5px" }}>
                                <div id="image">
                                    <div>
                                        <div key={item.id} style={{ position: "relative" }}>
                                            <div>
                                                {/* <img src={item.desktop_images[item.cover_image]} className="slider-image" /> */}
                                                <img onClick={() => postClick(item.id)} src={item.desktop_images[item.cover_image]} className="slider-image" />
                                            </div>
                                            <div id="overlay-layer">
                                                <div id="img-count">
                                                    <img src="../../assets/icon/icon-pic-cam.svg"></img>
                                                    <span id="img-count-number">{item.mobile_images.length}</span>
                                                </div>

                                                <div id="post-like" className="like-btn-back">
                                                    <LikeButton item={item} onLike={() => { }} onDislike={() => { }} />
                                                </div>

                                            </div>
                                        </div>
                                        <img src="../../assets/icon/icon_new_tab_1.svg" style={{ position: "absolute", top: "0", right: "0", zIndex: 500, cursor: "pointer" }} onClick={() => openInNewTab(item)} />
                                    </div>

                                </div>

                            </Box>
                            <Box id="detail-box" >
                                <div onClick={() => postClick(item.id)} style={{ height: '100%' }}>
                                    {
                                        renderSwitch()
                                    }
                                </div>
                                {/* <section id="details-bottom">

                                    <div id="details-bottom-left" onClick={() => postClick(item.id)}>
                                        <div id="avatar">
                                            <img src={item.author_avatar ? item.author_avatar : "assets/icon/img-blank-profile-avatar.jpg"} id="avatar-icon" />
                                        </div>

                                        <span id="posted-by">{item.posted_by}</span>
                                    </div>

                                    <div>
                                        <a href={openInNewTab()} target="_blank" style={{ textDecoration: 'none' }}>
                                            <Button id="more-btn" className={classes.button}>
                                                <span className="btn-txt">Open</span>
                                                <img src="assets/icon/icon-new-tab.svg"></img>
                                            </Button>
                                        </a>
                                    </div>

                                </section> */}
                            </Box>

                        </CardContent>
                    </Card>
                    {/* </a> */}
                    {/* <div style={{ borderBottom: "2px solid #DDDDDD", width: "100%", marginTop: "15px" }}></div> */}
                </>
            )}
        </NavigationContext.Consumer>
    );
}