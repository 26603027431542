import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { TOKEN, USER } from '../../config/constants';
import { GET_MY_AD_SIDEBAR_SUMMARY, GET_VERIFIED_PHONE, LOGIN, SELF_PHONE_VERIFY, SELF_PROFILE } from '../../config/urls';
import { setBusy } from '../navigation/navigationSlice';
import { clearAllPosts, saveMyAds } from '../post/postSlice';

export const slice = createSlice({
  name: 'session',
  initialState: {
    user: { name: '', gender: '', image: '', email: '', dob: '', token: '' },
    profile: null,
    filters: {},
    tags: [],
    authFreeze: false,
    myPostsSummary: {},
    userType: ''
  },

  reducers: {
    signInUser: (state, action) => {
      const { data, headers } = action.payload;
      state.user = data;
      state.user.token = headers.authorization;
      localStorage.setItem(TOKEN, headers.authorization);
      localStorage.setItem(USER, JSON.stringify(data));
    },
    onSignOut: (state, action) => {
      state.user = { name: '', gender: '', image: '', email: '', dob: '', token: '' };
      state.profile = null;
      localStorage.clear();
    },
    setAuthFreeze: (state, action) => {
      state.authFreeze = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    readUserFromLocalStorage: (state, action) => {
      state.user = JSON.parse(localStorage.getItem(USER)!);
    },
    addPostToProfile: (state, action) => {
      const profile_posts = action.payload;
      state.profile = profile_posts;
    },
    addFilterValues: (state, action) => {
      const filters = action.payload;
      // localStorage.setItem(FILTERS, JSON.stringify(filters));
    },
    addFilteredTags: (state, action) => {
      const tags = action.payload;
      // localStorage.setItem(TAGS, JSON.stringify(tags));
    },
    readTagsFromLocalStorage: (state, action) => {
      // state.tags = JSON.parse(localStorage.getItem(TAGS)!);
    },
    removeTagsLocalStorage: (state, action) => {
      // localStorage.removeItem(TAGS);
      // localStorage.removeItem(FILTERS);
    },
    setPostSummary: (state, action) => {
      const post_summary = action.payload;
      state.myPostsSummary = post_summary;
    }
  },
});

export const { signInUser, readUserFromLocalStorage, addPostToProfile, onSignOut, setAuthFreeze, setPostSummary, setUserType } = slice.actions;

export const login = (result: any, token: string, callback: Function) => (dispatch: any) => {

  dispatch(setBusy(true));

  axios.post(LOGIN, {
    token: token,
    user: {
      name: (result.displayName?result.displayName:result.name),
      gender: '',
      image: (result.imageUrl?result.imageUrl:result.photoURL),
      email: result.email,
      dob: ''
    }
  }).then(data => {
    
    dispatch(signInUser({ data: data.data, headers: data.headers }));
    if (data.status == 200) {
      dispatch(clearAllPosts({}));
      callback();
    }
  })
  .catch((e)=>{
    console.log(e);
    
  })
  .finally(() => dispatch(setBusy(false)))
}


export const loginNew = (displayName: any, photoURL: any, email: any, token: string, callback: Function) => (dispatch: any) => {
  // console.log(result, token);

  dispatch(setBusy(true));
  axios.post(LOGIN, {
    token: token,
    user: {
      name: displayName,
      gender: '',
      image: photoURL,
      email: email,
      dob: ''
    }
  }).then(data => {
    if (data.status == 200) {
      dispatch(signInUser({ data: data.data, headers: data.headers }));
      dispatch(clearAllPosts({}));
      callback();
    }
  }).finally(() => dispatch(setBusy(false)));
}

export const getSelfPosts = () => (dispatch: any) => {
  dispatch(setBusy(true));
  var t = localStorage.getItem(TOKEN);
  const headers = { Authorization: t };
  axios.get(SELF_PROFILE, { headers }).then(result => {
    if (result.status === 200) {
      // console.log(result);
      dispatch(addPostToProfile(result.data));
      dispatch(saveMyAds(result.data.posts));
      dispatch(setUserType(result.data.userType));
    }

  }).finally(() => dispatch(setBusy(false)));
}


export const getVerifiedPhone = (callback: Function) => (dispatch: any) => {
  axios.get(GET_VERIFIED_PHONE).then(result => {
    if (result.status === 200) {
      callback(result.data);
    }

  });
}

export const addVerifiedPhone = (token: string, callback: any, errCallback: any, mobile: any) => (dispatch: any) => {


  axios.post(SELF_PHONE_VERIFY, {
    token: token,
    mobile: mobile
  }).then((response: any) => {
    if (response.data.success) {
      callback(response.data.phone);
    } else errCallback('Failed, try again');
  }).catch(err => errCallback(err))
}

export const selectLoggedUser = (state: any) => state.session.user;
export const selectSelfProfile = (state: any) => state.session.profile;
export const selectTags = (state: any) => state.session.tags;
export const selectAuthFreeze = (state: any) => state.session.authFreeze;
export const selectUserType = (state: any) => state.session.userType;
export const selectMyPostsSummary = (state: any) => state.session.myPostsSummary;

export default slice.reducer;
