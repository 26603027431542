// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#filldetails-desctiption{
    min-height: 171px ;
}
#filldetails-desctiption textarea{
    min-height: 171px ;
}

#update-btn{
    text-align: center;
    padding: 20px 30px;
    width: 100%;
  }
  #update-btn ion-button{
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/components/EditPost/EditPost.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,WAAW;EACb","sourcesContent":["#filldetails-desctiption{\n    min-height: 171px ;\n}\n#filldetails-desctiption textarea{\n    min-height: 171px ;\n}\n\n#update-btn{\n    text-align: center;\n    padding: 20px 30px;\n    width: 100%;\n  }\n  #update-btn ion-button{\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
