// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sub-cat-icon-section{
    text-align: center;
}

.subcat-list{
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/AddPost/SubCategory/SubCategoryMobile.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["#sub-cat-icon-section{\n    text-align: center;\n}\n\n.subcat-list{\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
