// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mainnav-back {
  background-color: #fff;
  position: relative;
  /* box-shadow:0px 2px 0px #0000001A; */
  box-shadow: 0px 2px 4px #00000033;
  z-index: 999;
}

.btn-name {
  text-transform: capitalize;
  margin-left: 10px;
  font-family: inherit;
}

#mainnav-btns {
  display: flex;
  justify-content: space-between;
}

.mainnav-items {
  padding: 10px 25px !important;
  text-transform: none;
  padding-bottom: 8px !important;
}

.mainnav-items-mobile {
  padding: 10px 15px !important;
  text-transform: none;
  padding-bottom: 8px !important;
}

#active-tab {
  border-bottom: 3px solid #0080ff;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainNav/MainNav.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,sCAAsC;EACtC,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["#mainnav-back {\n  background-color: #fff;\n  position: relative;\n  /* box-shadow:0px 2px 0px #0000001A; */\n  box-shadow: 0px 2px 4px #00000033;\n  z-index: 999;\n}\n\n.btn-name {\n  text-transform: capitalize;\n  margin-left: 10px;\n  font-family: inherit;\n}\n\n#mainnav-btns {\n  display: flex;\n  justify-content: space-between;\n}\n\n.mainnav-items {\n  padding: 10px 25px !important;\n  text-transform: none;\n  padding-bottom: 8px !important;\n}\n\n.mainnav-items-mobile {\n  padding: 10px 15px !important;\n  text-transform: none;\n  padding-bottom: 8px !important;\n}\n\n#active-tab {\n  border-bottom: 3px solid #0080ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
