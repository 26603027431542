import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { currencyConverter } from "../../../../features/helper";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailBikesMobile.css';

const FullDetailBikesMobile = (props: any) => {
    const item = props.item;
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;
    const renderField = (type: any): any => {
        const values = item.tags
            .filter((tag: any) => tag.types.includes(type) && tag.name !== "(NA)");

        if (values.length == 0) return (
            <span className="brand-name-english">
                (na)
            </span>
        );
        return values
            .filter((tag: any) => tag.name !== "(NA)")
            .map((object: any) => (
                <span className="brand-name-english" key={object.id}>
                    {object.name.charAt(0).toUpperCase() + object.name.slice(1).toLowerCase()}
                </span>
            ));
    }
    return (
        <Grid container >
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-brands-16x16.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>
                            {/* Brand */}
                            {lan.BRAND}
                        </span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            renderField('Bikes_Brand')
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <IonIcon src="assets/icon/icon-model-16x16.svg" className="icons-fds"></IonIcon>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>
                            {/* Model */}
                            {lan.MODEL}
                        </span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            renderField('Bikes_Model')
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <IonIcon src="assets/icon/icon-calandar-year-16x16.svg" className="icons-fds"></IonIcon>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '13px') }}>
                            {/* Model Year */}
                            {lan.MODEL_YEAR}
                        </span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            renderField('Year')
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>

            </Grid>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <IonIcon src="assets/icon/icon-condtion-type-14x17.svg" className="icon-andama"></IonIcon>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.CONDITION}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            renderField('Motor_Condition')
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <IonIcon src="assets/icon/icon-km-18x14.svg" className="icon-milage"></IonIcon>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>
                            {/* Mileage */}
                            {lan.MILEAGE}
                        </span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {
                                currencyConverter().format(item.specs.Motors_Mileage)
                            }&nbsp;Km
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={8} className="col-left">
                        <IonIcon src="assets/icon/icon-engine.svg" className="icons-fds"></IonIcon>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '13px') }}>
                            {/* Engine Capacity */}
                            {lan.ENGINE_CAPACITY}
                        </span>
                    </Grid>
                    <Grid item xs={4} className="brand-names">
                        <span className="brand-name-english">
                            {
                                item.specs.Bike_Engine_Capacity
                            }
                            &nbsp;CC</span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>

            </Grid>

        </Grid>
    )
}

export default FullDetailBikesMobile;