import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const slice = createSlice({
    name: "upload",
    initialState: {
        imagesCount: 0,
        urlArray: [] as string[],
        prog: 0,
    },
    reducers: {
        setSaveImagesCount: (state, action) => {
            const imageCount = action.payload;
            state.imagesCount = imageCount;
        },
        setImageUrl: (state, action) => {
            const imageUrl = action.payload;
            state.urlArray = [...state.urlArray, imageUrl];
        },
        setProg: (state, action) => {
            state.prog = action.payload;
        }
    },
});
export const {
    setSaveImagesCount,
    setImageUrl,
    setProg,
} = slice.actions;
export const selectImagesCount = (state: any) => state.upload.imagesCount;
export const selectImageUrlArray = (state: any) => state.upload.urlArray;
export const getProg = (state: any) => state.upload.prog;
export default slice.reducer;