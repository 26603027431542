import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { Grid } from "@material-ui/core";
import { hardwareChipOutline } from "ionicons/icons";
import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailMobilesMobile.css';

const FullDetailElectronicsMobile = (props: any) => {
    const item = props.item;
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
      if (selectedLanguage == 'en') {
        return 'english';
      } else if (selectedLanguage == 'si') {
        return 'sinhala';
      } else if (selectedLanguage == 'ta') {
        return 'tamil';
      } else {
        return 'english';
      }
    }
  
    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    const renderField = (type: any): any => {
        const values = item.tags
            .filter((tag: any) => tag.types.includes(type) && tag.name !== "(NA)");

        if (values.length == 0) return (
            <span className="brand-name-english">
                (na)
            </span>
        );
        return values
            .filter((tag: any) => tag.name !== "(NA)")
            .map((object: any) => (
                <span className="brand-name-english" key={object.id}>
                    {object.name.charAt(0).toUpperCase() + object.name.slice(1).toLowerCase()}
                </span>
            ));
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <IonIcon src="assets/icon/icon-condtion-type-14x17.svg" className="icon-andama"></IonIcon>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.CONDITION}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            renderField('Electronic_Condition')
                        }
                    </Grid>
                </Grid>
            </Grid>
            <div className="row-devider"></div>
            <Grid item xs={6}>

            </Grid>

        </Grid>
    )
}

export default FullDetailElectronicsMobile;