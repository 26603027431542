import {
  IonIcon,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelfPosts,
  selectAuthFreeze,
  selectLoggedUser,
  selectSelfProfile,
} from "../../features/session/sessionSlice";
import "./ProfilePage.css";
import { ACTION_VIEW } from "../../config/actions";
import {
  setCurrentBehavior,
  setCurrentLocation,
} from "../../features/navigation/navigationSlice";
import {
  BEHAVE_LOGIN_REQUIRED,
  NAV_ACCOUNT, NAV_ADD_POST,
} from "../../providers/NavigationProvider";
import { UserContext } from "../../providers/UserProvider";
import {
  addNewPostDistrict,
  selectMyAds,
} from "../../features/post/postSlice";
import { Post } from "../../features/post/Post";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { VIEW_PROFILE_PAGE } from "../../config/events";
import MyAdPost from "../../components/Post/MyAdPost/MyAdPost";
import { Button, createStyles, makeStyles, Switch, Theme, Hidden, Grid, TextField, MenuItem, Divider, InputAdornment, Paper, Dialog, AppBar, Toolbar, IconButton, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { isPostResellable } from "../../features/helper";
import AddPostButton from "../../components/AddPost/AddPostButton/AddPostButton";
import ProfileSidebarComponent from "../../components/ProfileSidebarComponent/ProfileSidebarComponent"
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getCities, selectCity, selectDistrict } from "../../features/addPost/addPostSlice";
import { selectDistricts, selectLocations } from "../../config/locations";
import Checked from "@material-ui/icons/CheckCircle"
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import ProfilePageEdit from "./ProfilePageEdit";
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics, auth } from "../..";
import { onAuthStateChanged } from "firebase/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

function ProfilePage() {
  const classes = useStyles();

  const user = useSelector(selectLoggedUser);
  const dispatch = useDispatch();
  const [requestEligible, setRequestEligible] = useState(false);
  const [page, setPage] = useState(1);
  const selfPosts = useSelector(selectSelfProfile);
  const myads = useSelector(selectMyAds);
  const topref: any = useRef();
  const scrollContent = document.getElementById('scrollable-root');
  const [editProfile, setEditProfile] = useState(false);
  const [gender, setGender] = useState('');
  const navigate = useNavigate();
  const district = useSelector(selectDistricts);
  const cities = useSelector(selectCity);
  const locs = useSelector(selectLocations);
  const [values, setValues] = useState({} as any);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



  const [filteredItem, setfilteredItem] = useState([]);

  const authFreeze = useSelector(selectAuthFreeze);

  const [currentUser, setCurrentUser] = useState(Object);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    setRequestEligible(true);
    dispatch(setCurrentLocation(NAV_ACCOUNT));
    scrollContent?.scrollTo(0, 0);
    logEvent(analytics, VIEW_PROFILE_PAGE, { action: ACTION_VIEW });
    return () => {
      setRequestEligible(false);
      setPage(1);
    };
  }, []);

  useEffect(() => {
    setfilteredItem(myads);
  }, [myads]);


  useEffect(() => {
    if (requestEligible && user?.email && selfPosts == null) fetchUserProfile();
  }, [user, requestEligible]);

  const fetchUserProfile = () => {
    dispatch(getSelfPosts());
  };

  const onDistrictSelect = (location: any) => {


    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });

  };
  const onCitySelect = (location: any) => {

    setValues((v: any) => {
      return { ...v, city: location };
    });
    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

  };
  const isSavedDistrict = () => {

    if (Object.keys(locs).length > 0) {
      return true;
    }
    return false;
  }
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;


  const goHome = () => {
    navigate('/home')
  }
  const handleClose = () => {
    setEditProfile(false);
  };
  const Transition = React.forwardRef(function Transition(props: any, ref: any) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (

    // <AuthContext.Provider
    //   value={{ currentUser, isLoading }}
    // >
    //   {(currentUser == null ? (
    //     <Redirect to='/home' />

    //   ) : (

    //     <div ref={topref}>
    //       <div style={{ height: 50 }}></div>
    //     </div>
    //   ))
    //   }
    // </AuthContext.Provider>

    // <UserContext.Consumer>
    //   {(user: any) =>
    //     user == null || user?.email === '' ? (
    //       <Redirect to='/home' />

    //     ) : (

    //       <div ref={topref}>
    //         <Hidden xsDown={true} smDown={true} mdDown={true}>
    //           <div style={{ height: 50 }}></div>
    //         </Hidden>
    //         <Hidden lgUp={true} mdUp={true}>
    //           <ProfileSidebarComponent />
    //         </Hidden>



    //       </div>
    //     )
    //   }
    // </UserContext.Consumer>



    <>

      {
        user == null || user.email === '' ? (goHome) : (

          <div ref={topref}>

            <Hidden lgUp={true} mdUp={true}>
              <ProfileSidebarComponent />
            </Hidden>

            <div id="account-list-header-wrapper" style={{ marginBottom: 25 }}>
              <h5>My Account</h5>
              <Divider variant="fullWidth" color="primary"></Divider>
            </div>
            <Button variant='outlined' onClick={() => setEditProfile(true)}>Edit Profile</Button>
            <Dialog
              open={editProfile}
              onClose={handleClose}
              fullScreen={fullScreen}
              maxWidth="md"
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Profile
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handleClose}>
                    Save
                  </Button>
                </Toolbar>
              </AppBar>
              <ProfilePageEdit />
            </Dialog>


          </div>
        )
      }
      <br />
      <br />
    </>

  );
};

export default ProfilePage;
