
import "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import 'firebase/storage';

export const firebaseConfig = {
  apiKey: "AIzaSyAW7AuCe_bNnFYi-mKE4M5AX3ifT6JJBss",
  authDomain: "hiru-ads-app.firebaseapp.com",
  databaseURL: "https://hiru-ads-app.firebaseio.com",
  projectId: "hiru-ads-app",
  storageBucket: "hiru-ads-app.appspot.com",
  messagingSenderId: "357302555757",
  appId: "1:357302555757:web:e298251f414473e0775b64",
  measurementId: "G-EVLE5FEYNY"
};

export const oneTapConfig = {
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      clientId: '357302555757-s5h4d7ksm76le950a7h924n4mf5mjjkm.apps.googleusercontent.com'
    },
  ],

}

// export const storage = firebase.storage();










