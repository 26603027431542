import { logEvent } from "firebase/analytics"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyLikes from "../../components/Likes/MyLikes";
import ScrollRestore from "../../components/ScrollRestore";
import { ACTION_VIEW } from "../../config/actions";
import { VIEW_MYLIKES_PAGE } from "../../config/events";
import {
  setCurrentLocation,
} from "../../features/navigation/navigationSlice";
import {
  getAccountLikedPosts,
  likedPosts,
} from "../../features/post/postSlice";
import { selectPosition } from "../../features/scroll/scrollSlice";
import {
  readUserFromLocalStorage,
  selectAuthFreeze,
  selectLoggedUser,
} from "../../features/session/sessionSlice";
import { NAV_LIKES } from "../../providers/NavigationProvider";

import "./LikesPage.css";
import { analytics } from "../..";
import { Navigate } from "react-router-dom";

const LikesPage: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const items = useSelector(likedPosts);
  const user = useSelector(selectLoggedUser);
  const [requestEligible, setRequestEligible] = useState(false);

  const [busy, setBusy] = useState(false);
  const [showScrollTop, setshowScrollTop] = useState(false);
  const scrollPosition = useSelector(selectPosition);


  const scrollContent = document.getElementById("scrollable-root");

  const initialLoad = () => {
    dispatch(readUserFromLocalStorage({}));
  };

  useEffect(() => {
    setshowScrollTop(scrollPosition > 2000);
  }, [scrollPosition]);

  useEffect(initialLoad, []);

  useEffect(() => {
    setRequestEligible(true);
    dispatch(setCurrentLocation(NAV_LIKES));
    logEvent(analytics, VIEW_MYLIKES_PAGE, { action: ACTION_VIEW });
  }, []);

  useEffect(() => {
    if (requestEligible && user?.email)
      if (items?.length <= 0)
        dispatch(
          getAccountLikedPosts(page, () => {
            setRequestEligible(false);
            setBusy(false);
          })
        );
  }, [requestEligible]);

  const loadNewItems = (page: number = 0, infinite: any = null) => {
    setBusy(true);
    dispatch(
      getAccountLikedPosts(page, () => {
        setBusy(false);
        // console.log("completed");

      })
    );
  };

  const loadMorePosts = (e: any) => {
    loadNewItems(page + 1, e);
    setPage(page + 1);
  };

  const handleScrollToTopRequest = () => {
    setshowScrollTop(false);
    scrollContent?.scrollTo(0, 0);
  };

  return (

    // <UserContext.Consumer>
    //   {(user: any) =>
    //     user == null || user?.email === "" ? (
    //       <Redirect to="/home" />
    //     ) : (
    //       <>
    //         <div id="account-list-header-wrapper" style={{ marginBottom: 25 }}>
    //           <h5>My Favorites </h5>
    //           <div className="pre-post-bar">
    //             <span>Your Favorites  Ads</span>
    //           </div>
    //         </div>
    //         <div id="likes-page">
    //           <div id="likes-content">
    //             <div>
    //               <MyLikes
    //                 handleScrollToTopRequest={handleScrollToTopRequest}
    //                 showScrollTop={showScrollTop}
    //                 page={page}
    //                 busy={busy}
    //                 items={items}
    //                 loadMorePosts={loadMorePosts}
    //               ></MyLikes>
    //             </div>
    //             {items.length == 0 && !busy ? (
    //               <div className="no-text">
    //                 <img src="assets/images/no_likes.svg" className="cactus" />
    //                 <div>
    //                   ohh snap! <br></br> No Likes Yet
    //                 </div>
    //               </div>
    //             ) : (
    //               null
    //             )}
    //           </div>
    //           <ScrollRestore {...props} />
    //         </div>
    //         <div style={{ height: '100px' }}></div>
    //       </>
    //     )
    //   }
    // </UserContext.Consumer>

    <>

      {
        user == null || user?.email === "" ? (
          <Navigate to="/home" />
        ) : (
          <>
            <div id="account-list-header-wrapper" style={{ marginBottom: 25 }}>
              <h5>My Favorites </h5>
              <div className="pre-post-bar">
                <span>Your Favorites  Ads</span>
              </div>
            </div>
            <div id="likes-page">
              <div id="likes-content">
                <div>
                  <MyLikes
                    handleScrollToTopRequest={handleScrollToTopRequest}
                    showScrollTop={showScrollTop}
                    page={page}
                    busy={busy}
                    items={items}
                    loadMorePosts={loadMorePosts}
                  ></MyLikes>
                </div>
                {items.length == 0 && !busy ? (
                  <div className="no-text">
                    <img src="assets/images/no_likes.svg" className="cactus" />
                    <div>
                      ohh snap! <br></br> No Likes Yet
                    </div>
                  </div>
                ) : (
                  null
                )}
              </div>
              {/* <ScrollRestore {...props} /> */}
            </div>
            <div style={{ height: '100px' }}></div>
          </>
        )
      }
    </>
  );
};
export default LikesPage;
