import React, { useEffect } from "react";
import AddPost from "../../pages/AddPost/AddPost";
import DetailsPage from "../../pages/DetailsPage/DetailsPage";
import Home from "../../pages/Home/Home";
import LikesPage from "../../pages/LikesPage/LikesPage";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../../pages/PrivacyPolicy/TermsAndConditions";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import SearchResult from "../../pages/SearchPage/SearchResult/SearchResult";
import SellerPage from "../../pages/SellerPage/SellerPage";
import Support from "../Support/Support";
import PaymentOverview from "../../a_components/pages/payment/PaymentOverview";
import "./Content.css";
import Summery from "../../a_components/pages/paymentSummery/Summery";
import NotFound from "../../pages/ErrorPages/NotFound";
import MyAds from "../../pages/MyAds/MyAds";
import { Membership } from "../Membership/Membership";
import { Hidden, makeStyles } from "@material-ui/core";
import AddPostMobile from "../../pages/AddPost/AddPostMobile";
import { AdPromotions } from "../AdPromotion/AdPromotion";
import PaymentMembership from "../../a_components/pages/payment/PaymentMembership";
import MembershipSummery from "../../a_components/pages/paymentSummery/MembershipSummery";
import BankDeposit from "../../a_components/pages/deposit/BankDeposit";
import SellerPageSetting from "../SellerPageSetting/SellerPageSetting";
import MyMembership from "../Membership/MyMembership";
import SearchTagCloudMobile from "../../pages/SearchPage/SearchTagCloud/SearchTagCloudMobile";
import { Outlet, Route, Routes } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
      display: "block",
      position: "relative",

    }, [theme.breakpoints.down("md")]: {

    }
  },

}));
const Content = (props: any) => {
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
      <Outlet />

    </div >
  );
};

export default Content;
