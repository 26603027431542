import { Avatar, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { selectRef } from "../../config/locations";

var view = false;
var ref = '';

export default function PaymentSuccessSidebar() {

   
    const reff = useSelector(selectRef); 
    return (
        <div>
            <div style={{ height: "39px", borderBottom: "1px solid #0080FF" }}></div>
            <Grid container style={{ backgroundColor: "#F5F6F8" }}>

                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography style={{ fontWeight: "bold", paddingTop: "20px" }}>
                        Reference Number
                    </Typography>
                    <Typography style={{ paddingBottom: "20px", color: "#5A5A5A" }}>
                        {/* REF-POT-188536-1630387183332 */}
                        {
                            (reff!==''|| reff!==null ?reff:
                                <>
                                    <CircularProgress style={{ width: "20px", height: "20px", marginTop: "3px" }} />
                                </>
                            )

                        }
                    </Typography>
                    <Divider variant="middle"></Divider>
                </Grid>
                <Grid item xs={12}>

                    <div style={{ height: "330px" }}></div>
                </Grid>
                <Grid item xs={12}>
                    <Divider variant="middle" style={{ marginBottom: "10px" }}></Divider>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Avatar src="../../assets/payment/icon-email.svg" variant="square" style={{ width: '36px', height: "22px", margin: "10px auto" }} />
                        </Grid>
                        <Grid item xs={8}>
                            <span style={{ fontSize: "12px", color: "#5A5A5A" }}>You'll receive a confirmation email shortly to your email</span>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ marginTop: "10px" }}></Divider>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ margin: "10px 10px", fontSize: "12px", color: "#5A5A5A" }}>
                        For inquiries please contact our hotline by dialing <b>+94 117 455 955</b> from any network or write to us on <span style={{ color: "#0080FF" }}>service@saruwata.lk</span>
                    </div>
                </Grid>
            </Grid>

        </div >


    );
}



