// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer{
    height: 319px;
    background-color: #222222;
}
#footer-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#copyrights{
    height: 58px;
    background-color: #181818;
    display: flex;
    align-items: center;
}

#footer-text{
    color: #999999;
    font-size: 14px;
}

#footer-text-header{
    color: #FFFFFF;
    font-size: 16px;
}

#footer-links{
    color:#999999;
    font-size: 13px;
    list-style: none;
    padding: 0;
}

#footer-links li{
    display: flex;
    align-items: center;
    line-height: 38px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":["#footer{\n    height: 319px;\n    background-color: #222222;\n}\n#footer-container{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n#copyrights{\n    height: 58px;\n    background-color: #181818;\n    display: flex;\n    align-items: center;\n}\n\n#footer-text{\n    color: #999999;\n    font-size: 14px;\n}\n\n#footer-text-header{\n    color: #FFFFFF;\n    font-size: 16px;\n}\n\n#footer-links{\n    color:#999999;\n    font-size: 13px;\n    list-style: none;\n    padding: 0;\n}\n\n#footer-links li{\n    display: flex;\n    align-items: center;\n    line-height: 38px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
