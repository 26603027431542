import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import {
    createUserWithEmailPassword,
    signInWithFacebook,
    signInWithGoogle,

} from "../../features/session/firebaseSlice";
import { BEHAVE_LOGIN_REQUIRED, BEHAVE_SIGNUP_REQUIRED } from "../../providers/NavigationProvider";
import { login } from "../../features/session/sessionSlice";
import { getAllAvailablePosts } from "../../features/post/postSlice";
import "./LoginRequired.css";


import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    InputAdornment,
    Link,
    makeStyles,
    TextField,
    Theme,
} from "@material-ui/core";
import SlideUpTransition from "../../animations/SlideUpTransition";
import { CancelOutlined, CheckCircleOutline, Email, EmailOutlined, Facebook, Height, VisibilityOff, VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { SIGNUP } from "../../config/events";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics, auth } from "../..";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { googleSignIn } from "./googleService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            textAlign: "center",
            "& h2": {
                fontWeight: 700,
            },
            padding: '39px 24px 27px 24px'

        },
        tagline: {
            textAlign: "center",
            marginBottom: 30,
        },
        contentroot: {
            position: "relative",
            top: -25,
        },
        googlebtn: {
            width: "100% !important",
            backgroundColor: '#fff',
            height: 47,
            padding: '0 15px',
            textTransform: "capitalize",
            fontSize: 16,
            '& img': {
                width: 20
            },
            '& .MuiButton-label': {
                justifyContent: 'space-evenly',
                alignItems: 'center'
            }
        },
        fbbtn: {
            backgroundColor: "#4267B2",
            width: 165,
            height: 47,
            padding: '0 15px',
            fontSize: 16,
            "& span": {
                textTransform: "capitalize",
                color: "#fff",
            },
            '& .MuiButton-label': {
                justifyContent: 'space-evenly',
                alignItems: 'center'
            }
        },
        sepwrapper: {
            margin: '10px 15px 10px 15px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        secondoruiconwrapper: {
            margin: '0 15px 0 15px',
            '& button': {
                borderRadius: '10%',
                '& span': {
                    width: 80
                }
            }
        },
        footer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#5A5A5A',
            fontSize: 14,
            marginTop: 16
        },
        closebtn: {
            right: 0,
            position: 'absolute',
            top: 0
        },
        emailPasswordtxt: {
            marginBottom: '25px',
            width: "98%",
            maxWidth: '362px',
            height: '50px',
        }
    })
);

const SignUp = (props: any) => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);

    const [signupEmail, setsignupEmail] = useState('');
    const [signuppassword, setsignupPassword] = useState('');
    const [signupConfirmPassword, setsignupConfirmPassword] = useState('');
    const navigate = useNavigate();
    const [errorText, seterrorText] = useState('');
    const [pwVisibility, setpwVisibility] = useState(false);

    const formRef: any = useRef();

    useEffect(() => {
        setShowModal(props.navigation.behavior === BEHAVE_SIGNUP_REQUIRED);
    }, [props.navigation.behavior]);

    const signUpWithMail = () => {
        const formVal = formRef.current.reportValidity();
        if (!formVal) {
            return false;
        } else if (signuppassword != signupConfirmPassword) {
            seterrorText('Password does not match');
            return false;
        }
        else {
            dispatch(createUserWithEmailPassword(signupEmail.trim(), signuppassword, (user: any) => {
                if (user) {
                    handleDismiss();
                }
            }, (msg: any) => {
                seterrorText(msg);
            }));

        }
        logEvent(analytics, SIGNUP, { method: "onsite", from: "LoginPage", platform: 'DESKTOP' });
    }

    async function signIn(this: any) {


        let { user, idToken } = await googleSignIn()
     
      if (user!=null) {
        dispatch(
          login(user,idToken, () =>
            dispatch(getAllAvailablePosts(() => { }, true, 0))
          )
        );
      }

        setShowModal(false);
        // console.log(credential);

    }

    const loginWithGoogle = () => {
        // console.log("log line 1");
        dispatch(
            signInWithGoogle(
                (user: any, token: string) => {
                    // console.log("calling login");
                    dispatch(
                        login(user, token, () => {
                            dispatch(getAllAvailablePosts(() => { }, true, 0));
                        }
                        )
                    );
                },
                (error: any) => {
                    console.log("Error in login");
                }
            )
        );
        logEvent(analytics, SIGNUP, { method: "Google", from: "LoginPage", platform: 'DESKTOP' });
        setShowModal(false);
    };



    const handleDismiss = () => {
        setShowModal(false);
        dispatch(setCurrentBehavior(""));
        setsignupEmail("");
        setsignupPassword("");
        setsignupConfirmPassword("");
        seterrorText('');
    };

    return (
        <Dialog open={showModal} style={{ overflow: 'hidden' }}>
            <DialogTitle className={classes.title}>
                Sign Up
                <IconButton className={classes.closebtn} onClick={handleDismiss}><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentroot}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="login-btn-wrapper">
                        <Button
                            variant="contained"
                            onClick={() => signIn()}
                            className={classes.googlebtn}
                        >
                            <img src="assets/icon/google-icon.png"></img>
                            <span>Sign Up With Google</span>
                            <span></span>
                        </Button>
                    </div>
                    {/* <div className="login-btn-wrapper">
                        <Button
                            onClick={() => loginWithFacebook()}
                            variant="contained"
                            className={classes.fbbtn}
                        >
                            <Facebook htmlColor="white" />
                            <span>Facebook</span>
                            <span></span>
                        </Button>
                    </div> */}
                </div>
                <div className={classes.sepwrapper}>
                    <div style={{ height: '1px', width: '100%', backgroundColor: '#DDDDDD' }}></div>
                    <div style={{ margin: '0 15px' }}>or</div>
                    <div style={{ height: '1px', width: '100%', backgroundColor: '#DDDDDD' }}></div>
                </div>
                <div>
                    <form ref={formRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                        <TextField
                            label="Email Address"
                            variant="outlined"
                            className={classes.emailPasswordtxt}
                            required
                            onChange={(e: any) => setsignupEmail(e.target.value)
                            } value={signupEmail}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailOutlined></EmailOutlined>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Password"
                            variant="outlined"
                            type={(pwVisibility) ? "text" : "password"}
                            className={classes.emailPasswordtxt}
                            required
                            onChange={(e: any) => setsignupPassword(e.target.value)}
                            value={signuppassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" onClick={() => setpwVisibility(!pwVisibility)} style={{ cursor: 'pointer' }} >
                                        {
                                            (pwVisibility) ?
                                                <VisibilityOutlined></VisibilityOutlined>
                                                :
                                                <VisibilityOffOutlined></VisibilityOffOutlined>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            className={classes.emailPasswordtxt}
                            required
                            onChange={(e: any) => setsignupConfirmPassword(e.target.value)}
                            value={signupConfirmPassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            (signuppassword.length > 0 && signuppassword == signupConfirmPassword) ?
                                                <CheckCircleOutline color="primary"></CheckCircleOutline>
                                                :
                                                <CancelOutlined></CancelOutlined>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button style={{ height: '47px', width: '100%', backgroundColor: '#0080FF', color: '#fff', maxWidth: '362px', textTransform: 'capitalize', fontSize: 18 }} onClick={signUpWithMail}> Sign up</Button>
                    </form>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', fontSize: '14px', marginBottom: '20px' }}>
                    {errorText}
                </div>
                <div className={classes.footer}>
                    <div>By continuing you agree to the Saruwata.lk</div>
                    <span style={{ cursor: 'pointer' }}><Link onClick={() => { navigate('/terms-conditions'); handleDismiss(); }} >Terms & Conditions</Link>, <Link onClick={() => { navigate('/privacy-policy'); handleDismiss(); }}>Privacy Policy</Link></span>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default SignUp;
