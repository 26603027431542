import { Plugins } from "@capacitor/core";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonSearchbar,
  IonContent,
  IonIcon,
  IonChip,
  IonLabel,
  useIonViewDidEnter,
  isPlatform,
} from "@ionic/react";
import { Avatar, Chip, Typography } from "@material-ui/core";
import { logEvent } from "firebase/analytics"
import { addOutline } from "ionicons/icons";
import { platform } from "os";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeywordSearch from "../../../components/KeywordSearch/KeywordSearch";
import PostSkelton from "../../../components/PostSkelton/PostSkelton";
import { ACTION_CLICK, ACTION_INPUT } from "../../../config/actions";
import { KEYWORD_SEARCH_ALL, SEARCH, SELECT_TAG } from "../../../config/events";
import { selectLanguage } from "../../../features/language/language_slice";
import { setCurrentLocation } from "../../../features/navigation/navigationSlice";
import {

  saveSelectedTags,
  saveSuggestions,
  searchResultByTags,
  selectInSearch,
  selectSearcedPosts,
  selectSerpPage,
  setInSearch,
  setSelectedCategories,
  setSelectedSubCategory,
  setSerpPage,
} from "../../../features/search/searchSlice";
import {
  getAllCategories,
  selectCategories,
} from "../../../features/tags/tagsSlice";
import { Language } from "../../../language/Language";
import { NAV_SEARCH } from "../../../providers/NavigationProvider";
import "./SearchTagCloudMobile.css";
import { useNavigate } from "react-router-dom";
import { analytics } from "../../..";

const SearchTagCloudMobile = (props: any) => {

  const categories = useSelector(selectCategories);
  const inSearch = useSelector(selectInSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selection, setSelection] = useState("");
  const serpPage = useSelector(selectSerpPage);
  const results: any[] = useSelector(selectSearcedPosts);
  const [page, setPage] = useState(0);
  const [term, setterm] = useState("");

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }


  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const getSubVariableName = (name: string) => {
    return name.toUpperCase().replace(/ /g, "_").replace('&', 'AND');
  }

  useIonViewDidEnter(() => {
    dispatch(saveSelectedTags([]));
    dispatch(setCurrentLocation(NAV_SEARCH));
    dispatch(setInSearch(false));
    setSelection("");
    dispatch(saveSuggestions([]));

    logEvent(analytics, SEARCH, { action: ACTION_CLICK });
  });

  useEffect(() => {
    if (categories.length <= 0) dispatch(getAllCategories());
  }, [categories]);

  const buildTagSection = (
    tags: any[],
    title: string,
    icon: string,
    cls: string
  ) => {
    if (tags == undefined) return null;
    return (
      <section className="search-tag-section-wrapper-m" >
        <header>
          <img

            src={icon}
            style={{ marginLeft: '10px', width: '25px', height: '25px' }}
          />
          <span className="search-tag-section-title">
            {/* {lan.SEARCH}  */}
            {title}</span>
        </header>
        <div className="search-tag-section-body-wrapper-m" >
          {buildTag(tags[0], tags[0].name)}
          {tags.slice(1).map((tag) => buildTag(tag, tag.name))}

          {/* {tags
            .filter((tag) => tag.usage_count > 0)
            .sort((x: any, y: any) => y.usage_count - x.usage_count)
            .map((tag) => buildTag(tag, tag.name))} */}
        </div>
      </section>
    );
  };




  const buildTag = (tag: any, name: string) => {
    return (
      <>
        <Chip
          onClick={() => handleTagClick(tag)}
          key={tag?.id}
          className={tag?.selected ? "tag-selected-m" : ""}
          style={{
            background: "white",
            border: "1px solid #00AEFF",
            color: "black",
            textTransform: 'capitalize',
            margin: '3px 2px'
          }}
          label={lan[getSubVariableName(name)]}
        />
        {/* <IonLabel className="tag-name-m"> */}
        {/* {name[0].toUpperCase() + name.substring(1).toLowerCase()} */}
        {/* </IonLabel> */}
        {/* <IonIcon src={addOutline} color="primary" style={{ marginLeft: "-2px" }}></IonIcon> */}
      </>
    );
  };

  const handleTagClick = (tag: any) => {
    const cat = tag.name.toLowerCase();
    const res = categories.filter((c1: any) =>
      c1.sub_categories.map((sc: any) => sc.name).includes(tag.name)
    );
    navigate(`/search?c1=${res[0]?.name?.toLowerCase()}&c2=${encodeURIComponent(cat)}`);
    props.toggleDrawer();
    logEvent(analytics, SELECT_TAG, { action: ACTION_CLICK });
  };

  const handleKeywordSelect = (val: any) => {

    if (val.key === 'Enter') {
      setPage(0);
      if (val) {
        navigate(`/search?phrase=${term}`);
        logEvent(analytics, KEYWORD_SEARCH_ALL, { action: ACTION_INPUT, data: term });
      }
    }


  };



  const renderCategories = () => {
    return (
      <>
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "MOTORS")[0]
            ?.sub_categories,
          lan.MOTORS,
          "assets/icon/icon-motors.svg",
          "wider-icon"
        )}
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "PROPERTY FOR SALE")[0]
            ?.sub_categories,
          lan.PROPERTY_FOR_SALE,
          "assets/icon/icon-property-for-sale.svg",
          ""
        )}
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "PROPERTY FOR RENT")[0]
            ?.sub_categories,
          lan.PROPERTY_FOR_RENT,
          "assets/icon/icon-proerty-for-rent.svg",
          ""
        )}
        {buildTagSection(
          categories?.filter(
            (c: any) => c?.name === "MOBILES AND ELECTRONICS"
          )[0]?.sub_categories,
          lan.MOBILES_AND_ELECTRONICS,
          "assets/icon/icon-electronics.svg",
          ""
        )}
        {buildTagSection(
          categories?.filter((c: any) => c?.name === "CLASSIFIEDS")[0]
            ?.sub_categories,
          lan.CLASSIFIEDS,
          "assets/icon/icon-classifies.svg",
          ""
        )}
      </>
    );
  };

  return (
    <div style={{ background: "#F5F6F8" }}>
      {/* <IonHeader>
        <IonToolbar id="search-header" style={{ paddingTop: isPlatform('ios') ? "15px" : "" }}>
          <IonSearchbar
          className={isPlatform('ios') ? "full-text-search" : "searchbar-resultpage"}
          onIonChange={(e: any) => setterm(e.detail.value)}
          onKeyPress={handleKeywordSelect}
          inputMode="search"
          enterkeyhint="search"
          />
        </IonToolbar>
      </IonHeader> */}
      <Typography style={{ padding: '10px 30px', fontWeight: 600, fontSize: '20px' }}>{lan.SEARCH}</Typography>
      <div style={{ background: "#F5F6F8" }}>
        {
          renderCategories()
        }

        <div style={{ height: "90px" }}></div>
      </div>

    </div>
  );
};





export default SearchTagCloudMobile;
