import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { auth } from "../..";


export async function googleSignIn(): Promise<any> {
   
        // Attempt to sign in with Google
        const googleUser = await GoogleAuth.signIn();

        if (!googleUser || !googleUser.authentication?.idToken) {
            console.error('Google User or ID Token is missing');
            return;
        }

        return {
            user: googleUser, idToken: googleUser.authentication.idToken
        }

        // if (credential !== null) {
        //     // Sign in with the credential
        //     let data = await signInWithCredential(auth, credential)
        //         .catch((e) => {
        //             console.error('Error during signInWithCredential:', e);
        //             return {
        //                 user: null, idToken: null
        //             };
        //         });
        //     return {
        //         user: data.user, idToken: googleUser.authentication.idToken
        //     }
        // }



    
}
