// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#checkbox-addcontact{
    display: flex;
    align-items: center;
}
.contact-row{
    padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/AddPost/Contact/AddContact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,oBAAoB;AACxB","sourcesContent":["#checkbox-addcontact{\n    display: flex;\n    align-items: center;\n}\n.contact-row{\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
