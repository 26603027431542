import {
  Avatar,
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  Icon,
  makeStyles,
  Paper,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { currencyConverter, isPostResellable } from "../../../features/helper";
import moment from "moment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Resell from "../../Resell/Resell";
import SoldOutBtn from "../../SoldoutBtn/SoldoutBtn";
import EditPost from "../../EditPost/EditPost";
import { useDispatch } from "react-redux";
import { setSelectedPost } from "../../../features/post/postSlice";
import './MyAdPost.css';
import { isMobile } from "react-device-detect";
import AWS from 'aws-sdk';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 'auto',
      marginBottom: '5px',
      borderRadius: "0px"
    },
    thumb: {
      width: 170,
      height: 98,
      objectFit: "cover",
      objectPosition: "center",
      borderRadius: 4,
      border: '1px solid #DDDDDD',
      marginLeft: "6px",
      cursor: 'pointer'
    },
    thumbwrapper: {
      position: 'relative',

    },
    detailsection: {
      width: "217px",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    title: {
      fontWeight: 600,
      lineHeight: "20px",
      maxHeight: "24px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      marginRight: "25px",
      maxWidth: '200px',
      fontSize: 15,
      lineClamp: 2,
      boxOrient: 'vertical',
      display: '-webkit-box',

    },
    subCat: {
      fontSize: "14px",
      color: "#5A5A5A"
    },
    price: {
      color: "#0080FF",
      fontWeight: 700,
      padding: "5px 0 0 0",
      marginTop: "0px"
    },
    adcounts: {
      fontWeight: 600,
      lineHeight: "12px",
      fontSize: 15
    },
    analyticCube: {
      marginRight: "30px",
    },
    adcountdesc: {
      fontSize: 12,
    },
    buttonwrapper: {
      display: "flex",

    },
    editbtn: {
      color: "#00AEFF",
      border: '1px solid #00AEFF',
      width: 84,
      height: 22,
      textTransform: 'capitalize',
      '& .MuiButton-startIcon': {
        display: 'block'
      },
      '& .MuiButton-label': {
        alignItems: 'center'
      }
    },
    soldoutimg: {
      width: 72,
      height: 62,
      position: 'absolute',
      transform: 'translate(50%,-50%)',
      top: '50%',
      right: '50%'
    },
    statusSection: {
      display: 'flex',
      flexDirection: 'column',

    },
    cardSoldOut: {
      border: '1px solid #FFA39E',
      backgroundColor: '#FFFBFB',
      borderRadius: '8px'
    },
    cardPendingPayment: {
      border: '1px solid #FFE58F',
      backgroundColord: '#FFF8EC',
      borderRadius: '8px'
    },
    cardApproved: {
      border: '1px solid #DDDDDD',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px'
    },
    cardReviewing: {
      border: '1px solid #87E8DE',
      backgroundColor: '#F1FFFD',
      borderRadius: '8px'
    },
    cardRejecting: {
      border: '1px solid #FFBB96',
      backgroundColor: '#FFF8F3',
      borderRadius: '8px'
    }
  })
);
const MyAdPostNew = (props: any) => {
  const item = props.post;



  const unlimitedReselling = props.unlimitedReselling;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [sold, setSold] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setstatus] = useState('');
  const [colorName, setcolorName] = useState<any>('');


  const EditIconCustom = (
    <Icon>
      <img alt="edit" src="assets/icon/icon-edit.svg" />
    </Icon>
  );
  const handleSold = () => {
    setSold(true);
  };
  const handleRepost = () => {
    setSold(false);
  };
  const handleEdit = () => {
    setEdit(true);
    dispatch(setSelectedPost(item));

  }
  const ID = 'AKIAQ2KCF3STN6DPFLUA';
  const SECRET = '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';

  // The name of the bucket that you have created
  const BUCKET_NAME = 'saruwata-bucket';
  const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET
  });

  function getUrl(key: string) {
    if (key != undefined) {

      var params = { Bucket: BUCKET_NAME, Key: key.split('https://saruwata-bucket.s3.amazonaws.com/')[1] };
      var promise = s3.getSignedUrlPromise('getObject', params);
      promise.then(function (url) {
      }, function (err) { console.log('') });
    }
  }



  useEffect(() => {
    if (item) {
      setSold(item.sold);
    }
  }, [item]);
  useEffect(() => {
    if (item.sold) {
      setstatus('Sold');
      setcolorName('soldOut');
    } else {
      if (item.approval == 1 || item.approval == 0) {
        if (item.prevStatus == 6) {
          setstatus('Edited')
        } else {
          setstatus('Approved');
          setcolorName('approved');
        }
      } else if (item.approval == 4) {

        if (item.prevStatus == 6) {
          setstatus('Edited')
        } else {
          setstatus('Rejected');
          setcolorName('rejected');
        }


      } else if (item.approval == 10) {
        setstatus('Reviewing');
        setcolorName('reviewing');
      } else if (item.approval == 5) {
        setstatus('Pending Payment');
        setcolorName('paymentPending');
      }
    }
  }, [item]);

  function doPayment(seo: string) {
    // navigate(`/payment/${seo}`);
    window.location.href = (`/payment/${seo}`);
  }
  function doPromote(seo: string) {
    // navigate(`/payment/${seo}`);

    window.location.href = (`/promote/${seo}`);

  }
  function expiredTime(date: any) {

    var d = new Date(Number(date));
    d.setMonth(d.getMonth() + 2 + 1);
    return d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
  }
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Card style={{ width: (!isTabletOrMobile ? "100%" : "90%"), marginLeft: (!isTabletOrMobile ? "auto" : "auto"), marginRight: (!isTabletOrMobile ? "auto" : "auto") }} className={(status == "Sold") ? classes.cardSoldOut :
        (status == "Pending Payment") ? classes.cardPendingPayment :
          (status == "Approved") ? classes.cardApproved :
            (status == "Reviewing") ? classes.cardReviewing :
              (status == "Rejected") ? classes.cardRejecting : classes.root} variant="outlined"


      >
        <CardContent id="card">

          <Grid container>
            <Grid lg={5} md={12} xs={12} item>
              <div style={{ display: "flex" }}>
                <div style={{ position: "relative" }}>
                  {getUrl(item.thumb_images[item.cover_image])}
                  <img src={item.thumb_images[item.cover_image]} className={classes.thumb} onClick={() => window.location.href = (`/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`)} />
                  {
                    sold ? (<img src="assets/images/img-sold-out-lable.png" className={classes.soldoutimg} />) : null
                  }
                </div>
                <div style={{ paddingLeft: "10px", width: "100%" }}>
                  <div>
                    <div style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => window.location.href = (`/${item.main_category.slug}/${item.sub_category.slug}/${item.hash}`)}>{item.title.length > 28 ? item.title.substring(0, 27) + "..." : item.title}</div>
                    <div >{item.specs.Cat_Lev_2.charAt(0).toUpperCase() + item.specs.Cat_Lev_2.slice(1).toLowerCase()}</div>
                    <div >
                      {item.price == 0
                        ? "Contact Seller"
                        : <> <span style={{ fontSize: '14px' }}>Rs.</span> {currencyConverter().format(item.price)}</>}
                    </div>
                  </div>
                  <div style={{ marginBottom: '10px', fontSize: '12px', color: '#5A5A5A', marginTop: '2px' }}>Posted on {moment(item.created_at).fromNow()}</div>
                </div>
              </div>

            </Grid>
            <Grid lg={4} md={12} xs={12} style={{ paddingTop: "5px" }} item>

              <div style={{ paddingLeft: (isTabletOrMobile ? "10px" : "0px") }} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <div style={{ display: 'flex', flexGrow: 1 }}>
                    <div className={classes.analyticCube}>
                      <div className={classes.adcounts}>{item.like_count}</div>
                      <span className={classes.adcountdesc}>Favorites </span>
                    </div>
                    <div className={classes.analyticCube}>
                      <div className={classes.adcounts}>{item.click_count}</div>
                      <span className={classes.adcountdesc}>Views</span>
                    </div>
                    <div>
                      <div className={classes.adcounts}>{item.callCount}</div>
                      <span className={classes.adcountdesc}>Calls</span>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: 'flex', marginTop: '5px' }}>
                      {(item.isJump === "1" ?
                        <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                          <Avatar variant='circle' src="../../assets/addOn/icon-jump-up.svg" style={{ width: '22px', height: '22px' }}></Avatar>
                        </div> : null)
                      }
                      {(item.istop === "1" ?
                        <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                          <Avatar variant='circle' src="../../assets/addOn/icon_super-ad.png" style={{ width: '22px', height: '22px' }}></Avatar>
                        </div> : null)
                      }
                      {(item.isquick === "1" ?
                        <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                          <Avatar variant='rounded' src="../../assets/addOn/icon_quick-sale-lable.png" style={{ width: '22px', height: '22px' }}></Avatar>
                        </div> : null)
                      }
                      {(item.isSpot === "FEATURE" ?
                        <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                          <Avatar variant='circle' src="../../assets/addOn/icon_featured-ad.png" style={{ width: '22px', height: '22px' }}></Avatar>
                        </div> : null)

                      }

                    </div>
                  </div>
                  {((item.isJump === "1") || (item.istop === "1") || (item.isquick === "1") || (item.isSpot === "FEATURE") ?
                    <div style={{ display: 'flex', fontSize: '12px', lineHeight: '14px', marginTop: '15px' }}>
                      <span style={{ display: 'block', color: '#999999' }}>ID : {item.hash}</span>
                    </div>
                    :
                    <div style={{ display: 'flex', fontSize: '12px', lineHeight: '14px', marginTop: '36px' }}>
                      <span style={{ display: 'block', color: '#999999' }}>ID : {item.hash}</span>
                    </div>
                  )}

                </div>

              </div>

            </Grid>
            <Grid lg={3} md={12} xs={12} style={{ paddingTop: "5px" }} item>
              <div  >
                <div>
                  <div>
                    <Grid container>

                      <Grid item xs={12} direction='row'  >

                        <Grid container>

                          <Grid item xs={12} md={12} lg={12}>
                            <div style={{ width: (isTabletOrMobile ? "auto" : "fit-content"), marginRight: 'auto', marginLeft: (isTabletOrMobile ? '10px' : 'auto') }}>


                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={EditIconCustom}
                                className={classes.editbtn}
                                onClick={handleEdit}
                                disabled={status == "Reviewing" || status == "Sold" || item.prevStatus == 6}

                              >
                                Edit
                              </Button>


                              <SoldOutBtn item={item} sold={handleSold} enabled={!item.sold && !(status == "Pending Payment") && !(status == "Rejected") && !(status == "Reviewing")} />
                            </div>
                          </Grid>
                        </Grid>

                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '15px' }} >

                        <Grid container >

                          <Grid item xs={12} lg={12} >
                            <div style={{ marginRight: 'auto', marginLeft: (isTabletOrMobile ? '10px' : 'auto'), width: (isTabletOrMobile ? "auto" : "fit-content") }}>

                              <Button
                                style={{ color: (status == "Pending Payment" ? `#FFFFFF` : `#DDDDDD`), backgroundColor: (status == "Pending Payment" ? `#FF9C00` : `#FFFFFF`), borderColor: (status == "Pending Payment" ? `#FF9C00` : `#DDDDDD`) }}
                                variant="contained"
                                size="small"
                                disabled={(status !== "Pending Payment")}
                                className={classes.editbtn}
                                onClick={() => doPayment(item.hash)}
                              >
                                Payment
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                // style={{ marginLeft: 10, color: (status !== "Sold" ? `#FFFFFF` : `#DDDDDD`), backgroundColor: (status !== "Sold" ? `#0080FF` : `#FFFFFF`), borderColor: (status !== "Sold" ? `#0080FF` : `#DDDDDD`), boxShadow: "none" }}
                                style={{ marginLeft: 10, color: (status === "Sold" || status === "Pending Payment" || status == "Rejected" || item.prevStatus == 6 ? `#DDDDDD` : `#FFFFFF`), backgroundColor: (status === "Sold" || status === "Pending Payment" || status == "Rejected" || item.prevStatus == 6 ? `#FFFFFF` : `#0080FF`), borderColor: (status === "Sold" || status === "Pending Payment" || status == "Rejected" || item.prevStatus == 6 ? `#DDDDDD` : `#0080FF`), boxShadow: "none" }}
                                className={classes.editbtn}

                                disabled={(status === "Sold" || status == "Pending Payment" || status == "Rejected" || item.prevStatus == 6)}
                                onClick={() => doPromote(item.hash)}
                              >
                                Promote
                              </Button>
                            </div>
                          </Grid>
                        </Grid>

                      </Grid>



                    </Grid>
                  </div>
                  <div style={{ position: "relative", marginTop: "10px" }}>

                    <Grid style={{ textAlign: (isTabletOrMobile ? "left" : "right") }} container>
                      <Grid item xs={5} lg={5}>
                        <div style={{ fontSize: '12px', color: 'red' }}>
                          {((status === "Sold") || (status === "Pending Payment") || (status === "Rejected")) ? <></> :
                            <span >Expire in {expiredTime(item.created_at)}</span>
                          }
                        </div>

                      </Grid>
                      <Grid item xs={7} >
                        <div style={{ fontSize: '12px', color: '#5A5A5A', width: "100%", paddingRight: "16px" }}>Status:
                          <span style={{ fontWeight: 600 }} className={colorName}> {status}</span>
                        </div>

                      </Grid>
                    </Grid>
                  </div>

                </div>

              </div>

            </Grid>
          </Grid>


        </CardContent>
      </Card>
      <EditPost show={edit} images={item.images} isMember={item.isMembership} onDismiss={() => setEdit(false)} />
    </>
  );
};
export default MyAdPostNew;
