import {
  Button,
  Container,
  createStyles,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentStep } from "../../features/addPost/addPostSlice";
import {
  NavigationContext,
  NAV_ADD_POST,
  NAV_LANDING,
} from "../../providers/NavigationProvider";
import "./Footer.css";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: {
      [theme.breakpoints.up("md")]: {
        width: "100%",

      }, [theme.breakpoints.down("md")]: {
        visibility: "hidden"
      }
    },
    rootm: {
      [theme.breakpoints.down("md")]: {

        height: "0px"
      }
    }
    ,
    width: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: "px",
        padding: 0,
      },
    },
    catLink: {
      "&:hover": {
        color: "#0080FF",
      },
    },
  })
);

const PAGE_ALL = 0;
const PAGE_MOTORS = 1;
const PAGE_MOBILE_ELECTRONICS = 2;
const PAGE_PROPERTY_SALE = 3;
const PAGE_PROPERTY_RENT = 4;
const PAGE_CLASSIFIEDS = 5;
const PAGE_OTHER = -1;

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const step = useSelector(selectCurrentStep);
  const location = useLocation();
  const scrollContent = document.getElementById("scrollable-root");

  const [mainNavPage, setmainNavPage] = useState(PAGE_ALL);

  useEffect(() => {
    const path = location.pathname;
    const url = new URLSearchParams(window.location.search);
    const c1 =
      url
        .get("c1")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || "";
    switch (decodeURIComponent(c1)) {
      case "motors":
        setmainNavPage(PAGE_MOTORS);
        break;
      case "mobiles and electronics":
        setmainNavPage(PAGE_MOBILE_ELECTRONICS);
        break;
      case "property for sale":
        setmainNavPage(PAGE_PROPERTY_SALE);
        break;
      case "property for rent":
        setmainNavPage(PAGE_PROPERTY_RENT);
        break;
      case "classifieds":
        setmainNavPage(PAGE_CLASSIFIEDS);
        break;
      default:
        if (path === "/search" || path === "/home") setmainNavPage(PAGE_ALL);
        else setmainNavPage(PAGE_OTHER);
        break;
    }
  }, [window.location.search, location.pathname]);

  const checkNav = (cat: any) => {
    scrollContent?.scrollTo(0, 0);
    setmainNavPage(cat);
    navigate(`/search?c1=${encodeURIComponent(cat)}`);
  };

  return (
    <NavigationContext.Consumer>
      {(nav: any) => (
        <div className={(step == NAV_ADD_POST ? classes.root : classes.rootm)} style={{ marginBottom: "90px" }}>
          <div id="footer">
            <Container
              maxWidth="lg"
              style={{ paddingTop: "50px" }}
              className={classes.width}
            >
              <Grid container>
                <Grid item xs={5} style={{ paddingRight: "80px" }}>
                  <div>
                    <img src="assets/logo/sarauwata-footer-logo.png"></img>
                  </div>
                  <div style={{ paddingBottom: "16px" }}>
                    <span id="footer-text" style={{ lineHeight: "18px" }}>
                      Welcome to the new marketplace designed for modern Sri
                      Lankans. Find the best deals on Vehicles, Property,
                      Electronics and much more. Choose from a wide range of
                      high quality ADs from trusted sellers.
                    </span>
                  </div>
                  <span id="footer-text">
                    Download the app to upgrade your life!
                  </span>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=lk.hiruads.aphrodite"
                      target="_blank"
                    >
                      <img
                        src="assets/logo/btn-google-app-2.svg"
                        style={{ paddingRight: "16px", cursor: "pointer" }}
                      ></img>
                    </a>
                    <a
                      href="https://apps.apple.com/lk/app/saruwata-lk/id1565804524"
                      target="_blank"
                    >
                      <img
                        src="assets/logo/btn-appstore-app.svg"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </a>
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <span id="footer-text-header">CATEGORIES</span>
                  <div>
                    <ul id="footer-links">
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                          onClick={() => checkNav("motors")}
                        >
                          Motors
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                          onClick={() => checkNav("mobiles and electronics")}
                        >
                          Mobile & Electronics
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                          onClick={() => checkNav("property for sale")}
                        >
                          Property for Sale
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                          onClick={() => checkNav("property for rent")}
                        >
                          Property for Rent
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                          onClick={() => checkNav("classifieds")}
                        >
                          Classifieds
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <span id="footer-text-header">Saruwata.lk</span>
                  <div>
                    <ul id="footer-links">
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          onClick={() => navigate("/support/faq")}
                          className={classes.catLink}
                          underline="none"
                        >
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          color="inherit"
                          target="_blank"
                          href="https://blog.saruwata.lk/"
                          className={classes.catLink}
                          underline="none"
                        >
                          Blog
                        </Link>
                      </li>
                      <li>

                        <ArrowRight />
                        <Link
                          color="inherit"
                          target="_blank"
                          href="/membership"
                          className={classes.catLink}
                          underline="none"
                        >
                          Membership
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          onClick={() => navigate("/privacy-policy")}
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <ArrowRight />
                        <Link
                          onClick={() => navigate("/terms-conditions")}
                          color="inherit"
                          className={classes.catLink}
                          underline="none"
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div style={{ paddingBottom: "30px" }}>
                    <span id="footer-text-header">CONTACT</span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ color: "#999999", fontSize: "13px" }}>
                        Hotline
                      </span>
                      <span
                        style={{
                          color: "#FFFFFF",
                          fontSize: "18px",
                          marginBottom: "28px",
                        }}
                      >
                        +94 117 455 955
                      </span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ color: "#999999", fontSize: "13px" }}>
                        Email
                      </span>
                      <span style={{ color: "#FFFFFF", fontSize: "18px" }}>
                        info@saruwata.lk
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div id="copyrights">
            <Container
              maxWidth="lg"
              id="footer-container"
              className={classes.width}
            >
              <div>
                <span id="footer-text">
                  Copyright © 2024 Saruwata.lk - All Rights Reserved
                </span>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <span id="footer-text" style={{ marginRight: "16px" }}>
                    Connect with us:
                  </span>
                  <img
                    src="assets/icon/icon-facebook.svg"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    alt=""
                    onClick={() =>
                      window.open("https://www.facebook.com/saruwata.lk")
                    }
                  />
                  <img
                    src="assets/icon/icon-instagram.svg"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    alt=""
                    onClick={() =>
                      window.open("https://www.instagram.com/saruwata.lk/")
                    }
                  />
                  <img
                    src="assets/icon/icon-youtube.svg"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    alt=""
                    onClick={() =>
                      window.open("https://www.youtube.com/c/SaruwataLK")
                    }
                  />
                  <img
                    src="assets/icon/icon-tiktok.svg"
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={() =>
                      window.open("https://www.tiktok.com/@saruwata.lk")
                    }
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </NavigationContext.Consumer>
  );
};

export default Footer;
