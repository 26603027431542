import {
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Call, WhatsApp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectedPost } from "../../features/post/postSlice";
import "./DetailsSideBar.css";
import PhoneIcon from "@material-ui/icons/Phone";
import { IonItemDivider, isPlatform } from "@ionic/react";
import {
  MAKE_CALL,
  SEND_MESSAGE,
  CLICK_ON_SIDEBAR_BANNER,
} from "../../config/events";
import { logEvent } from "firebase/analytics"
import { WHATSAPP_MSG } from "../../config/constants";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ACTION_CLICK } from "../../config/actions";
import axios from "axios";
import { CallNumber } from "@ionic-native/call-number";
import { UPDATE_CALL_COUNT } from "../../config/urls";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      height: "60px",
    },
    phonewrapper: {
      margin: "20px 0",
      height: "60px",
      display: "flex",
      backgroundColor: "#F5F6F8",
      borderRadius: "6px",
      padding: "10px",
      color: "#000",
      alignItems: "flex-end",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: 600,
      border: "1px solid #DDDDDD",
      WebkitAlignItems: "center",
      boxShadow: "0px 2px 0px #DDDDDD",
    },
    phoneicon: {
      lineHeight: 0,
      margin: "0 20px 0 5px",
    },
    phonetext: {
      fontSize: "12px",
      color: "#5A5A5A",
      fontWeight: 400,
    },
    whatsapp: {
      width: "100%",
      marginBottom: "20px",
      backgroundColor: "#25D366",
      borderRadius: "6px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      color: "#fff",
      height: "40px",
      border: "1px solid #00BD47",
      boxShadow: "0px 2px 0px #00bd47",
      cursor: "pointer",
    },
    whatsapptext: {
      color: "#FFFFFF",
      fontSize: "16px",
      paddingBottom: "3px",
    },
  })
);

const DetailsSideBar = () => {
  const classes = useStyles();
  const item = useSelector(selectedPost);
  const navigate = useNavigate();
  const [phone, showPhone] = useState(false);

  const location = useLocation();

  const [c1, setC1] = useState("");
  const seo = location.pathname.split("/")[3];

  useEffect(() => {

    setC1(location.pathname.split("/")[1]);

  }, [window.location.pathname]);

  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };

  const handleWhatsappChatOpen = () => {
    var wn = ((item.whatsapp == '+94' || item.whatsapp == '' || item.whatsapp == null) ? item.mobile : item.whatsapp) as string;
    if (wn.length == 10) {
      if (!(wn == '+94' || wn == '' || wn == null)) {
        var x = wn.slice(1, 10);
        var wn = '+94' + x;
      }
    }

    window.open(
      `https://api.whatsapp.com/send?phone=${wn}&text=${WHATSAPP_MSG}`
    );

    logEvent(analytics, SEND_MESSAGE, { action: ACTION_CLICK });
  };

  const handleBannerClick = () => {
    logEvent(analytics, CLICK_ON_SIDEBAR_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };

  const handleCallClick = () => {

    axios.put(UPDATE_CALL_COUNT + seo).catch(e => console.log(e));


    logEvent(analytics, MAKE_CALL, { action: ACTION_CLICK });
  };

  const sendClick = () => {

    // axios.put("http://192.168.1.59:8080/saruwata-admin-panel-v1/CallButtonCountProfile",{Headers:{"Content-Type":"application/x-www-form-urlencoded","Accept":"*/*","Connection":"keep-alive","Origin":"origin:http://docs.google.com"}}

    // ).then(()=>{
    //     console.log("awa");
    // });
  }
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  return (
    <div >
      <div style={{ marginTop: "39px", borderBottom: "2px solid #DDDDDD" }}>
        <Grid container >
          <Grid item xs={12} style={{ width: "300px" }}>
            <Paper variant="outlined" style={{ borderRadius: "6px" }}>
              <div
                id="auther-container"
                onClick={() => navigate("/seller/" + item?.author_email)}
              >
                <div id="avatar-wrap">
                  <img
                    id="fd-avatar-icon-details"
                    src={
                      item.author_avatar ||
                      "assets/icon/img-blank-profile-avatar.jpg"
                    }
                    alt=""
                  ></img>
                  <div id="fd-meta-author-wrapper">
                    <span id="txt-postedby">
                      {/* Posted by */}
                      {lan.POSTED_BY}
                    </span>
                    <span id="txt-postedby-name">{item.posted_by}</span>
                  </div>
                </div>
              </div>
              <div
                id="see-seller"
                onClick={() => navigate("/seller/" + item?.author_email)}
              >
                <span
                  style={{ paddingLeft: "11px", cursor: "pointer" }}
                  id="txt-postedby"
                >
                  {lan.SEE_ALL_ADS}{" "}
                  <span style={{ marginLeft: 10, fontSize: 17 }}>›</span>
                </span>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ width: "300px" }}>
            <div
              className={classes.phonewrapper}
              onClick={() => {
                showPhone(true);
                sendClick();
                logEvent(analytics, MAKE_CALL, {
                  action: ACTION_CLICK,
                  platform: "DESKTOP",
                });
              }}
            >
              <span className={classes.phoneicon}>
                <img
                  src="assets/icon/icon-call-detail.svg"
                  style={{ width: "24px", height: "24px" }}
                />
              </span>
              <span>
                <div onClick={() => {
                  handleCallClick();
                  if (phone) {
                    CallNumber.callNumber(item.mobile, false)
                      .then()
                      .catch(() => {
                        window.open(
                          isPlatform("android")
                            ? `tel:${item.mobile}`
                            : `telprompt:${item.mobile}`,
                          "_self"
                        );
                      });
                  }

                }} >{phone ? item.mobile : "07XX XXX XXX"}</div>
                {phone ? (
                  <div className={classes.phonetext} >{lan.PHONE_NUMBER}</div>
                ) : (
                  <div className={classes.phonetext}>
                    {lan.CLICK_TO_SHOW_PHONE}
                  </div>
                )}
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.whatsapp} onClick={handleWhatsappChatOpen}>
              <span className={classes.phoneicon}>
                <WhatsApp style={{ width: "21px", height: "21px" }} />
              </span>
              <span className={classes.whatsapptext}>{lan.WHATSAPP}</span>
            </div>
          </Grid>
        </Grid>
      </div>

      <Hidden xsDown={true} smDown={true}>
        <div style={{ marginTop: "14px" }} onClick={handleBannerClick}>
          {/* <img src="assets/walls/electronics-300x250.png"></img> */}
          {isMotor() ? (

            <iframe scrolling="no"
              style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
              src="https://services.saruwata.lk/AdManager/DV/DV-Motors-Desktop-Medium-Rectangle-300x250/index.html" />

          ) : isProperty() ? (

            <iframe scrolling="no"
              style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
              src="https://services.saruwata.lk/AdManager/DV/DV-Property-Sale-Desktop-Medium-Rectangle-300x250/index.html" />


          ) : isPropertyRent() ?


            <iframe scrolling="no"
              style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
              src="https://services.saruwata.lk/AdManager/DV/DV-Property-Rent-Desktop-Medium-Rectangle-300x250/index.html" />

            : isElectronic() ?

              <iframe scrolling="no"
                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                src="https://services.saruwata.lk/AdManager/DV/DV-Mobiles-and-Electronics-Desktop-Medium-Rectangle-300x250/index.html" />


              : isClassified() ?


                <iframe scrolling="no"
                  style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                  src="https://services.saruwata.lk/AdManager/DV/DV-Classifieds-Desktop-Medium-Rectangle-300x250/index.html" />

                : null}

        </div>
      </Hidden>


      {/* mobile ad after descripn in detail page */}

      <Hidden lgUp={true} mdUp={true}>
        <div style={{ marginTop: "14px", display: "flex", justifyContent: "center" }} onClick={handleBannerClick}>
          {/* <img src="assets/walls/electronics-300x250.png"></img> */}
          {isMotor() ? (

            <iframe scrolling="no"
              style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
              src="https://services.saruwata.lk/AdManager/DV/DV-Motors-Mobile-Medium-Rectangle-300x250/index.html" />

          ) : isProperty() ? (

            <iframe scrolling="no"
              style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
              src="https://services.saruwata.lk/AdManager/DV/DV-Property-Sale-Mobile-Medium-Rectangle-300x250/index.html" />


          ) : isPropertyRent() ?


            <iframe scrolling="no"
              style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
              src="https://services.saruwata.lk/AdManager/DV/DV-Property-Rent-Mobile-Medium-Rectangle-300x250/index.html" />

            : isElectronic() ?

              <iframe scrolling="no"
                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                src="https://services.saruwata.lk/AdManager/DV/DV-Mobiles-and-Electronics-Mobile-Medium-Rectangle-300x250/index.html" />


              : isClassified() ?


                <iframe scrolling="no"
                  style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                  src="https://services.saruwata.lk/AdManager/DV/DV-Classifieds-Mobile-Medium-Rectangle-300x250/index.html" />

                : null}

        </div>
      </Hidden>


    </div >
  );
};

export default DetailsSideBar;
