import { createSlice } from "@reduxjs/toolkit";
import React, { useState } from "react";


export const slice = createSlice({
    name: "payment",
    initialState: {
        title: "",
        price: 0,
        seoTag: "",
        clientRef: "",
        img: [],
        addOns: {
            amount: 0,
            bundlePack: 0,
            boostAd: 0,
            featureAd: 0,
            jumpAd: 0,
            quickSell: 0,
        },
        addOnCodes: {

            bundlePack: '',
            boostAd: '',
            featureAd: '',
            jumpAd: '',
            quickSell: '',
        },
        isJump: '',
        isQuick: '',
        isTop: '',
        isFeature: '',
        postStatus: '',
    },
    reducers: {
        setTitle: (state, action) => {

            state.title = action.payload;
        },
        setPrice: (state, action) => {

            state.price = action.payload;

        },
        setSeotag: (state, action) => {
            state.seoTag = action.payload;
        },
        setClientRef: (state, action) => {
            state.clientRef = action.payload;
        },
        setImg: (state, action) => {
            state.img = action.payload;

        },
        setAmount: (state, action) => {
            const amount = action.payload;
            state.addOns = { ...state.addOns, amount: amount };
        },
        setBundlePack: (state, action) => {
            const bundlePack = action.payload;
            state.addOns = { ...state.addOns, bundlePack: bundlePack };
        },
        setBoostAd: (state, action) => {
            const boostAd = action.payload;
            state.addOns = { ...state.addOns, boostAd: boostAd };
        },
        setFeatureAd: (state, action) => {
            const featureAd = action.payload;
            state.addOns = { ...state.addOns, featureAd: featureAd };
        },
        setJumpAd: (state, action) => {
            const jumpAd = action.payload;
            state.addOns = { ...state.addOns, jumpAd: jumpAd };
        },
        setQuickSell: (state, action) => {
            const quickSell = action.payload;
            state.addOns = { ...state.addOns, quickSell: quickSell };
        },
        setAddOnCodesJumpAd: (state, action) => {
            const code = action.payload;
            state.addOnCodes = { ...state.addOnCodes, jumpAd: code };

        },

        setAddOnCodesFeatureAd: (state, action) => {
            const code = action.payload;
            state.addOnCodes = { ...state.addOnCodes, featureAd: code };

        },

        setAddOnCodesBoostAd: (state, action) => {
            const code = action.payload;
            state.addOnCodes = { ...state.addOnCodes, boostAd: code };

        },

        setAddOnCodesBundlePack: (state, action) => {
            const code = action.payload;
            state.addOnCodes = { ...state.addOnCodes, bundlePack: code };

        },

        setAddOnCodesQuickSell: (state, action) => {
            const code = action.payload;
            state.addOnCodes = { ...state.addOnCodes, quickSell: code };

        },
        setIsJump: (state, action) => {
            state.isJump = action.payload;
        },
        setIsQuick: (state, action) => {
            state.isQuick = action.payload;
        },
        setIsTop: (state, action) => {
            state.isTop = action.payload;
        },
        setIsFeature: (state, action) => {
            state.isFeature = action.payload;
        },
        setPostStatus: (state, action) => {
            state.postStatus = action.payload;
        },
    }


});


export const {
    setTitle,
    setAmount,
    setImg,
    setBoostAd,
    setBundlePack,
    setFeatureAd,
    setJumpAd,
    setPrice,
    setQuickSell,
    setSeotag,
    setClientRef,
    setAddOnCodesJumpAd,
    setAddOnCodesBoostAd,
    setAddOnCodesBundlePack,
    setAddOnCodesFeatureAd,
    setAddOnCodesQuickSell,
    setIsJump,
    setIsQuick,
    setIsTop,
    setIsFeature,
    setPostStatus

} = slice.actions;

export const selectTitle = (state: any) => state.payment.title;
export const selectPrice = (state: any) => state.payment.price;
export const selectSeotag = (state: any) => state.payment.seoTag;
export const selectClientRef = (state: any) => state.payment.clientRef;
export const selectImg = (state: any) => state.payment.img;
export const selectAddOns = (state: any) => state.payment.addOns;
export const selectAmount = (state: any) => state.payment.addOns.amount;
export const selectBundlePack = (state: any) => state.payment.addOns.bundlePack;
export const selectBoostAd = (state: any) => state.payment.addOns.boostAd;
export const selectFeatureAd = (state: any) => state.payment.addOns.featureAd;
export const selectJumpAd = (state: any) => state.payment.addOns.jumpAd;
export const selectQuickSell = (state: any) => state.payment.addOns.quickSell;
export const getAddOnCodes = (state: any) => state.payment.addOnCodes;
export const selectIsJump = (state: any) => state.payment.isJump;
export const selectIsQuick = (state: any) => state.payment.isQuick;
export const selectIsTop = (state: any) => state.payment.isTop;
export const selectIsFeature = (state: any) => state.payment.isFeature;
export const selectPostStatus = (state: any) => state.payment.postStatus;
export default slice.reducer;

