import { AppBar, Avatar, BottomNavigation, BottomNavigationAction, Box, Button, ButtonGroup, Drawer, Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer, TextField, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { styled } from '@material-ui/styles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPreviousLocation, setCurrentBehavior, setCurrentLocation, setIsSideBarOpen } from '../../features/navigation/navigationSlice';
import { getSelfPosts, login, loginNew, selectAuthFreeze, selectLoggedUser, selectSelfProfile, selectUserType } from '../../features/session/sessionSlice';
import { BEHAVE_LOGIN_REQUIRED, BEHAVE_SIGNUP_REQUIRED } from '../../providers/NavigationProvider';
import AddPostButtonMobile from '../AddPost/AddPostButton/AddPostButtonMobile';
import { setAmount, setBoostAd, setClientRef, setFeatureAd, setImg, setJumpAd, setPrice, setQuickSell, setSeotag, setTitle } from "../../a_components/features/addOnServices";
import { setJumpAdValues } from "../../a_components/AddOn/AddOnValues";
import { LOGIN, LOGOUT } from '../../config/events';
import { ACTION_CLICK } from '../../config/actions';
import { signInWithEmailPassword, signInWithGoogle, signOut } from '../../features/session/firebaseSlice';
import { UserContext } from '../../providers/UserProvider';
import { GoogleLogin } from 'react-google-login';
import { getAllAvailablePosts } from '../../features/post/postSlice';
import FacebookLogin from 'react-facebook-login';
import { frontend, MY_MEMBERSHIP_GET_USER_REQUEST, MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN } from '../../config/urls';
import { EmailOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { selectLanguage, setLanguage } from '../../features/language/language_slice';
import { Language } from '../../language/Language';
import axios from 'axios';
import { Plugins } from '@capacitor/core';
import SearchTagCloudMobile from '../../pages/SearchPage/SearchTagCloud/SearchTagCloudMobile';
import safeAreaInsets from 'safe-area-insets';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics, auth } from '../..';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';

// import FacebookLogin from 'react-facebook-login';
// import { GoogleSignin, GoogleSigninButton } from '@react-native-google-signin/google-signin';




const useStyles = makeStyles((theme) => ({
  fbButton: {
    backgroundColor: " #4267B2",
    padding: "10px 30px",


  },
  root: {
    [theme.breakpoints.up("md")]: {
      display: "none"

    }, [theme.breakpoints.down("md")]: {

      position: "absolute",
      bottom: "0",
      width: "100%",
      paddingBottom: "10px",


    }
  },
  emailPasswordtxt: {
    marginBottom: '29px',
    width: '100%',
    height: '50px',
  },

  sepwrapper: {
    margin: '10px 15px 10px 15px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(1),

  },
  googlebtn: {
    width: "100% !important",
    backgroundColor: '#fff',
    height: 47,
    padding: '0 15px',
    textTransform: "capitalize",
    fontSize: 16,
    '& img': {
      width: 20
    },
    '& .MuiButton-label': {
      justifyContent: 'space-evenly',
      alignItems: 'center'
    }
  }
}));


export default function BottomNav() {
  const authFreeze = useSelector(selectAuthFreeze);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const user = useSelector(selectLoggedUser);
  const [showModal, setShowModal] = React.useState(false);
  const userType = useSelector(selectUserType);
  const selfPosts = useSelector(selectSelfProfile);
  const previous = useSelector(selectPreviousLocation);
  const [membershipRequests, setMembershipRequests] = React.useState([]);
  const [requested, setRequested] = React.useState(false);


  const [state1, setState1] = React.useState({
    right: false
  });
  const [state2, setState2] = React.useState({
    right: false
  });


  React.useEffect(() => {
    if (user?.email && selfPosts == null) fetchUserProfile();
  }, [user]);

  React.useEffect(() => {
    if (!requested) {
      if (user?.email != undefined && user?.email != "") {

        axios.get(MY_MEMBERSHIP_GET_USER_REQUEST + "/" + user?.email + MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN).then((response) => {
          setMembershipRequests(response.data);
          setRequested(true);
        });
      }
    }
  })


  const fetchUserProfile = () => {
    dispatch(getSelfPosts());
  };

  const handleProfileMenuItemClick = (item: string) => {
    if (item === "profile") navigate("/profile");
    else if (item === "likes") navigate("/likes");
    else if (item === "myads") {

      handleMyAds(user);

      // dispatch(setSeotag(""));
      // dispatch(setTitle(""));
      dispatch(setPrice(0));
      // dispatch(setImg([]));
      dispatch(setAmount(0));
      dispatch(setJumpAd(0));
      dispatch(setFeatureAd(0));
      dispatch(setBoostAd(0));
      dispatch(setQuickSell(0));
      // dispatch(setClientRef(''));
      dispatch(setJumpAdValues([]));

    } else if (item === "shop-page") {

      navigate("/sellerPageSetting")

    } else if (item === "mymembership") {

      navigate("/my-membership")

    } else if (item == 'search') {
      dispatch(toggleDrawer2('right', true));

    }

    dispatch(toggleDrawer1('right', false));


  };

  const toggle2 = () => {
    dispatch(toggleDrawer2('right', false));

  }


  const handleLogin = () => {
    dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
  };

  const handleNewAd = (user: any) => {
    if (user == null || user.email === "") {
      handleLogin();
      return;
    }

    navigate("/add-post");
  };
  const handleMyAds = (user: any) => {
    if (user == null || user.email === '') {
      handleLogin();
      return;
    }
    navigate('/myads');
    // window.location.href=("/myads");
  };
  const [value, setValue] = React.useState('recents');

  const handleChange = (event: any, newValue: any) => {
    // setValue(newValue);
  };

  async function googleSignIn(this: any) {

    let googleUser = await Plugins.GoogleAuth.signIn();
    const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
    signInWithCredential(auth, credential).then((data: { user: any; }) => {
      dispatch(
        login(data.user, googleUser.authentication.idToken, () =>
          dispatch(getAllAvailablePosts(() => { }, true, 0))
        )
      );
    });

    // console.log(credential);

  }




  // const toggleDrawer1 =
  //   (anchor: string, open: boolean) =>
  //     (event: React.KeyboardEvent | React.MouseEvent) => {
  //       if (
  //         event.type === 'keydown' &&
  //         ((event as React.KeyboardEvent).key === 'Tab' ||
  //           (event as React.KeyboardEvent).key === 'Shift')
  //       ) {
  //         return;
  //       }

  //       setState1({ ...state1, [anchor]: open });
  //     };
  const toggleDrawer1 = (anchor: string, open: boolean) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState1({ ...state1, [anchor]: open });
    dispatch(setIsSideBarOpen(false));
  };
  const toggleDrawer2 = (anchor: string, open: boolean) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState2({ ...state2, [anchor]: open });
    dispatch(setIsSideBarOpen(false));
  };

  const onSignOut = () => {
    navigate("/home");
    logEvent(analytics, LOGOUT, { action: ACTION_CLICK });
  };

  function fbBack(data: any) {
    alert(data)
  }
  const responseGoogle = (response: any) => {

    dispatch(
      loginNew(response.getBasicProfile().getName(), response.getBasicProfile().getImageUrl(), response.getBasicProfile().getEmail(), response.getAuthResponse().id_token, () =>
        dispatch(getAllAvailablePosts(() => { }, true, 0))
      )
    );
    dispatch(toggleDrawer1('right', false));


  }
  const handleDismiss = () => {
    setShowModal(false);
    dispatch(setCurrentBehavior(""));
    setloginPassword('');
    setloginEmail('');
    seterrorText('');
    setsucessText('');
  };
  const loginWithMail = () => {
    const formVal = formRef.current.reportValidity();
    if (!formVal) {
      return false;
    }
    else {
      dispatch(toggleDrawer1('right', true))
      dispatch(signInWithEmailPassword(loginEmail.trim(), loginPassword, (user: any, token: string) => {
        dispatch(login({ ...user, displayName: user.email }, token, () => {
          window.location.reload();
        }));

        handleDismiss();
      }, (msg: any) => {
        seterrorText(msg);
      }));

    }
    logEvent(analytics, LOGIN, { method: "onsite", from: "LoginPage", platform: 'DESKTOP' });
  }

  const handleSignUp = () => {
    dispatch(toggleDrawer1('right', false));
    setShowModal(false);
    dispatch(setCurrentBehavior(BEHAVE_SIGNUP_REQUIRED));
  }

  const handleNewMembership = (user: any) => {

    if (user == null || user.email === "") {
      handleLogin();
      return;
    }
    navigate("/membership");
  };

  const [loginEmail, setloginEmail] = React.useState('');
  const [loginPassword, setloginPassword] = React.useState('');
  const formRef: any = React.useRef();
  const [errorText, seterrorText] = React.useState('');
  const [sucessText, setsucessText] = React.useState('');
  const [pwVisibility, setpwVisibility] = React.useState(false);
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  return (
    <>

      <BottomNavigation className={classes.root} value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Home"
          // label={lan.HOME}
          value="home"
          onClick={() => navigate("/home")}
          icon={<img style={{ width: "25px", height: "25px" }} src='../assets/icon/menu-icon-home-normal.svg' />}
        />
        <BottomNavigationAction
          label="Search"
          // label={lan.SEARCH}
          value="search"
          onClick={() => {

            handleProfileMenuItemClick("search");
            dispatch(setIsSideBarOpen(true));
          }}
          icon={<img style={{ width: "25px", height: "25px" }} src='../assets/icon/menu-icon-search-normal.svg' />}
        />
        {/* <BottomNavigationAction
          label="Add Post"
          value="add post"
          icon={<img style={{ width: "25px", height: "25px" }} src='../assets/icon/menu-icon-add-post.svg' />}
        /> */}
        <AddPostButtonMobile handleNewAd={handleNewAd} authFreeze={authFreeze} />
        <UserContext.Consumer>
          {(user: any) => (
            <BottomNavigationAction
              label="myAds"
              value="myAds"
              onClick={() => {

                handleProfileMenuItemClick("myads");
              }
              }
              icon={<img style={{ width: "25px", height: "25px" }} src='../assets/icon/icon-my-ads.svg' />}
            />
          )}
        </UserContext.Consumer>

        <BottomNavigationAction
          label="Profile"
          value="profile"
          onClick={() => {
            dispatch(toggleDrawer1('right', true));
            dispatch(setIsSideBarOpen(true));

          }
          }
          icon={<img style={{ width: "25px", height: "25px" }} src='../assets/icon/menu-icon-profile-normal-blue.svg' />}
        />

      </BottomNavigation>


      {/* profile Drawer */}

      <SwipeableDrawer
        SlideProps={{ style: { width: "85%", backgroundColor: "#F5F6F8", paddingTop: "35px", zIndex: 6000 } }}
        anchor={'right'}
        open={state1['right']}
        onClose={() => { dispatch(toggleDrawer1('right', false)); }}
        onOpen={toggleDrawer1('right', true)}
        disableSwipeToOpen={true}



      >
        <AppBar position="static" className="appBar" style={{ height: (safeAreaInsets.support ? safeAreaInsets.top : "40"), boxShadow: "none", zIndex: 20000, backgroundColor: "#F5F6F8", color: 'black' }}></AppBar>

        <div>
          {/* <div onClick={
              () => {
                dispatch(toggleDrawer1('right', false));
                dispatch(setIsSideBarOpen(false));
              }} 
              style={{position: "absolute", top: "50px", left: "10px", zIndex: 20000,display:"flex"}}
              >

            <Avatar style={{ width: "auto", height: "25px",  backgroundColor: 'transparent', color: 'black' }} component={CloseIcon} />
            <Avatar style={{ width: "auto", height: "25px",  backgroundColor: 'transparent', color: 'black',marginLeft:"-15px"}} component={CloseIcon} />

          </div> */}

          {/* <div onClick={
            () => {
              dispatch(toggleDrawer1('right', false));
              dispatch(setIsSideBarOpen(false));
            }}
            style={{ position: "absolute", top: "5px", left: "10px", zIndex: 20000, display: "flex", backgroundColor: 'transparent' }}
          >

            <Avatar style={{ width: "auto", height: "25px", zIndex: 20000 }} component={CloseIcon} />

          </div> */}
          <Grid container style={{ marginTop: '25px' }}>

            {(user !== null && user.email !== '' ?

              <Grid item xs={12}>
                {/* loged in */}
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"

                >
                  <ListItem button key={'username'}>

                    <Grid container>

                      <Grid >
                        <div style={{ display: "flex" }}>
                          <Avatar style={{ width: "90px", height: "90px" }} variant="circle" src={user.image} />
                          <div style={{ display: "flex", flexDirection: "column", paddingLeft: "20px" }}  >

                            <span
                              onClick={() => {
                                handleProfileMenuItemClick("profile");
                              }}
                              style={{ fontSize: "18px", fontWeight: 600, cursor: "pointer" }}>{user.name}</span>
                            <span style={{ fontSize: "16px", fontWeight: 400, }}>{user.email}</span>
                            <Button size="small" variant="outlined" color="primary" style={{ marginTop: "10px" }}>{lan.EDIT_PROFILE}</Button>

                            <br />


                          </div>

                        </div>
                      </Grid>

                    </Grid>
                    {/* <ListItemText style={{ fontSize: "20px", fontWeight: "bold" }} primary={user.name} /> */}


                  </ListItem>

                  <ListItem button key={'My Ads'} onClick={() => {
                    handleProfileMenuItemClick("myads");
                  }} style={{ borderBottom: "1px solid #ECEEF1" }}>
                    <ListItemIcon>
                      <img
                        src="assets/icon/icon-myads.svg"
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={lan.MY_ADS} />


                  </ListItem>
                  {(membershipRequests.length < 0 ? null :
                    <ListItem button key={'My Membership'}
                      onClick={() => {
                        handleProfileMenuItemClick("mymembership");
                      }}

                      style={{ borderBottom: "1px solid #ECEEF1" }}>
                      <ListItemIcon>
                        <img
                          src="assets/icon/icon-my-membership.svg"
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={lan.MY_MEMBERSHIP} />
                    </ListItem>
                  )}
                  {(userType == "listing" ? null :
                    <>


                      <ListItem button key={' Shop Page Setting'}
                        onClick={() => {
                          handleProfileMenuItemClick("shop-page");
                        }}
                        style={{ borderBottom: "1px solid #ECEEF1" }}>
                        <ListItemIcon>
                          <img
                            src="assets/icon/icon_seller_shop_settings.svg"
                            alt=""
                            style={{ width: "20px", height: "20px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={lan.SHOP_PAGE_SETTING} />
                      </ListItem>
                    </>


                  )}



                  <ListItem button key={'Favorites'} onClick={() => {
                    handleProfileMenuItemClick("likes");
                  }} style={{ borderBottom: "1px solid #ECEEF1" }}>
                    <ListItemIcon>
                      <img
                        src="assets/icon/icon-favorites.svg"
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={lan.FAVORITES} />

                  </ListItem>




                </List>

              </Grid> : <Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                <div>
                  <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Welcome to saruwata.lk
                  </Typography>
                  <span>
                    Log in to manage your account
                  </span>

                </div>
                <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>

                  {/* <GoogleLogin
                    clientId="357302555757-s5h4d7ksm76le950a7h924n4mf5mjjkm.apps.googleusercontent.com"
                    buttonText="Continue with Google"
                    disabled={false}

                    onSuccess={responseGoogle}
                    onFailure={(error) => ""}

                    // isSignedIn={true}
                    redirectUri={''}
                    cookiePolicy={'single_host_origin'}

                  /> */}
                  <div >
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => googleSignIn()}
                      startIcon={<img style={{ width: "20px" }} src="assets/icon/google-icon.png" />}
                    >
                      Sign In With Google
                    </Button>




                  </div>
                </div>
                {/* <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                  <FacebookLogin
                    appId="298348358483086"
                    autoLoad={false}
                    fields="name,email,picture"
                    // onClick={fbBack}
                    callback={fbBack} />
                </div> */}

                <div className={classes.sepwrapper}>
                  <div style={{ height: '1px', width: '100%', backgroundColor: '#DDDDDD' }}></div>
                  <div style={{ margin: '0 15px' }}>or</div>
                  <div style={{ height: '1px', width: '100%', backgroundColor: '#DDDDDD' }}></div>
                </div>


                <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px", width: "97%" }}>

                  <form ref={formRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px' }}>
                    <TextField
                      label={lan.EMAIL_ADDRESS}
                      variant="outlined"
                      className={classes.emailPasswordtxt}
                      required
                      onChange={(e: any) => setloginEmail(e.target.value)
                      } value={loginEmail}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailOutlined></EmailOutlined>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label={lan.PASSWORD}
                      variant="outlined"
                      type={(pwVisibility) ? "text" : "password"}
                      className={classes.emailPasswordtxt}
                      required
                      onChange={(e: any) => setloginPassword(e.target.value)}
                      value={loginPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={() => setpwVisibility(!pwVisibility)} style={{ cursor: 'pointer' }}>
                            {
                              (pwVisibility) ?
                                <VisibilityOutlined></VisibilityOutlined>
                                :
                                <VisibilityOffOutlined></VisibilityOffOutlined>
                            }

                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button style={{ height: '47px', width: '100%', backgroundColor: '#0080FF', color: '#fff', maxWidth: '362px', textTransform: 'capitalize', fontSize: 18 }} onClick={loginWithMail}> {lan.LOG_IN}</Button>
                  </form>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, marginLeft: '20px' }}>
                  <span>No Account?</span>
                  <Button color="primary" disableFocusRipple disableRipple disableTouchRipple style={{ textTransform: 'capitalize' }} onClick={handleSignUp}>Sign Up</Button>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>

              <List
                component="nav"
                aria-labelledby="nested-list-subheader"

              >

                <ListItem button onClick={() => {

                  dispatch(toggleDrawer1('right', false));

                }} style={{ borderBottom: "1px solid #ECEEF1", margin: '0 0 20 0', display: "flex", flexDirection: 'column', alignItems: 'start' }}>
                  <span style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Select Your Language </span>
                  <div>
                    <ButtonGroup variant="text" style={{ backgroundColor: "primary", marginRight: "10px", borderColor: "white" }} >
                      <Button variant='outlined' style={{ color: "#999999", fontSize: '14px' }} onClick={() => { dispatch(setLanguage('si')); }}>සිංහල</Button>
                      <Button variant='outlined' style={{ color: "#999999", fontSize: '12px' }} onClick={() => { dispatch(setLanguage('en')); }}>English</Button>
                      <Button variant='outlined' style={{ color: "#999999", fontSize: '12px' }} onClick={() => { dispatch(setLanguage('ta')); }}>தமிழ்</Button>
                    </ButtonGroup>
                  </div>

                </ListItem>
                <ListItem button key={'FAQ'} onClick={() => {
                  navigate('/support/faq');
                  dispatch(toggleDrawer1('right', false));

                }} style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-faq.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.FAQ} />


                </ListItem>

                <ListItem button key={'How to Sell Fast'} style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-how-to-sell.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.HOW_TO_SELL} />


                </ListItem>

                <ListItem button key={'Membership'}

                  onClick={() => {
                    handleNewMembership(user)
                    dispatch(toggleDrawer1('right', false));

                  }}
                  style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-mem.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.MEMBERSHIP} />


                </ListItem>

                <ListItem button
                  onClick={() => {
                    navigate('/adPromotions');
                    dispatch(toggleDrawer1('right', false));

                  }} key={'Ad Promotions'} style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-ad-promotion.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.AD_PROMOTION} />

                </ListItem>
                <ListItem button
                  onClick={() => {
                    navigate('/support/faq/#contact');
                    dispatch(toggleDrawer1('right', false));

                  }} key={'Contact Us'} style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-contact.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.CONTACT_US} />

                </ListItem>



                <ListItem button onClick={() => {
                  navigate("/terms-conditions");
                  dispatch(toggleDrawer1('right', false));
                }} key={'Terms and Conditions'} style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-terms-conditions.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.TERMS_AND_CONDITIONS} />


                </ListItem>

                <ListItem onClick={() => { navigate("/privacy-policy"); dispatch(toggleDrawer1('right', false)); }} button key={'Privacy Policy'} style={{ borderBottom: "1px solid #ECEEF1" }}>
                  <ListItemIcon>
                    <img
                      src="assets/icon/icon-privacy-policy.svg"
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lan.PRIVACY_POLICY} />


                </ListItem>


                {
                  (user !== null && user.email !== '' ?
                    <ListItem button key={'Sign Out'} onClick={() => {
                      dispatch(signOut(onSignOut));
                      dispatch(toggleDrawer1('right', false));
                    }} style={{ borderBottom: "1px solid #ECEEF1" }}>
                      <ListItemIcon>
                        <img
                          src="assets/icon/icon-log-out.svg"
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={lan.LOG_OUT} />


                    </ListItem>
                    : null)

                }



              </List>
            </Grid>
          </Grid>
        </div>


      </SwipeableDrawer>

      {/* search drawer */}
      <SwipeableDrawer
        SlideProps={{ style: { width: "95%", backgroundColor: "#F5F6F8", paddingTop: "35px", zIndex: 6000, } }}
        anchor={'right'}
        open={state2['right']}
        onClose={() => { dispatch(toggleDrawer2('right', false)); }}
        onOpen={toggleDrawer2('right', true)}
        disableSwipeToOpen={true}

      >
        <SearchTagCloudMobile toggleDrawer={toggle2} />

      </SwipeableDrawer>



    </>
  );
}
