import { Grid, Hidden, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  NavigationContext,
  NAV_ACCOUNT,
  NAV_ADD_POST,
  NAV_BANK_DEPOSIT,
  NAV_LIKES,
  NAV_MEMBERSHIPS,
  NAV_MYADS,
  NAV_MY_MEMBERSHIP,
  NAV_PRIVACY_POLICY,
  NAV_SELLER_PROFILE,
  NAV_TERMS_CONDITIONS,
  NOT_FOUND,
  PAYMENT,
  PAYMENT_SUMMERY,
  SELLER_PAGE_SETTINGS,
} from "../../providers/NavigationProvider";
import Content from "../Content/Content";
import SideBarContent from "../Content/SideBarContent";
import Loader from "../loader/Loader";
import "./Page.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { logEvent } from "firebase/analytics"
import { ACTION_CLICK } from "../../config/actions";
import { CLICK_ON_LEFT_BANNER } from "../../config/events";
import PageFooter from "../PageFooter/PageFooter";
import FooterNew from "../Footer/FooterNew";
import { Route, Routes, useLocation } from "react-router-dom";
import { analytics } from "../..";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      display: "flex",
      justifyContent: "center"
    }, [theme.breakpoints.down("md")]: {
      position: "relative",

    },

  },
  left: {
    [theme.breakpoints.up("md")]: {
      // width: "165px",
      maxWidth: "165px",
      // maxWidth: "206px",
      padding: '0 0 0 0px',
      backgroundColor: 'white'
    }, [theme.breakpoints.down("md")]: {

      padding: 0,
      display: "none",

    }
  },
  center: {
    [theme.breakpoints.up("md")]: {
      // maxWidth: "728px",
      maxWidth: "802px",
      padding: "20px 10px",
      // marginRight: "35px",
      // marginLeft: "35px",
      position: "relative",
      backgroundColor: 'white',
      overflow: 'hidden'

    }, [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: 0,
      marginRight: 0,
      position: "relative",
      marginBottom: "20px",
      backgroundColor: 'white',

    }
  },
  extcenter: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "924px",
      padding: 0,
      marginRight: 20,
      position: "relative",
      backgroundColor: 'white',
      overflow: 'hidden'
    }, [theme.breakpoints.down("md")]: {
      // width: "100%",
      // padding: 0,

      // position: "relative",
      marginBottom: "20px",
      backgroundColor: 'white'
    },
  },
  right: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "300px",
      padding: 0,
      backgroundColor: 'white'
    }, [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      padding: 0,
      backgroundColor: 'white'
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  centerEdit: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      padding: 0,
      position: "relative",
      backgroundColor: 'white',
      overflow: 'hidden'


    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: 0,
      position: "relative",
      marginBottom: "20px",
      backgroundColor: 'white'


    }
  }
}));

const Page = (props: any) => {
  const classes = useStyles();
  const [banner, setBanner] = useState(true);
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));


  const [c1, setC1] = useState("");
  const [loc, setLoc] = useState("");
  const location = useLocation();

  useEffect(() => {
    const url = location.search;
    setLoc(url);
    if (url == '') {
      setC1(location.pathname.split("/")[1]);

    } else {
      const ur = new URLSearchParams(window.location.search);
      setC1(
        ur
          .get("c1")
          ?.replace(/[^\w\s]/g, "")
          .replace(/\s\s+/g, " ")
          .trim() || ""
      );

    }

  }, [window.location.search]);

  // const [c1, setC1] = useState("");

  // useEffect(() => {
  //   const url = new URLSearchParams(window.location.search);
  //   setC1(
  //     url
  //       .get("c1")
  //       ?.replace(/[^\w\s]/g, "")
  //       .replace(/\s\s+/g, " ")
  //       .trim() || ""
  //   );
  // }, [window.location.search]);

  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };

  const leftBannerHide = (location: string): boolean => {
    return (
      location === NAV_ACCOUNT ||
      location === NAV_LIKES ||
      location === NAV_MYADS ||
      location === NAV_ADD_POST ||
      location == NAV_PRIVACY_POLICY ||
      location == NAV_TERMS_CONDITIONS ||
      location == PAYMENT ||
      location === PAYMENT_SUMMERY ||
      location === NAV_SELLER_PROFILE ||
      location === NAV_PRIVACY_POLICY ||
      location === NAV_MEMBERSHIPS ||
      location === NOT_FOUND ||
      location === SELLER_PAGE_SETTINGS ||
      location === NAV_MY_MEMBERSHIP ||
      location === NAV_BANK_DEPOSIT ||
      !smallViewPort
    );
  };
  const rightBannerHide = (location: string): boolean => {
    return (

      location === NAV_PRIVACY_POLICY ||
      location === NAV_MEMBERSHIPS ||
      location === NOT_FOUND ||
      location === NAV_MY_MEMBERSHIP ||
      location === NAV_BANK_DEPOSIT


    );
  };
  const BottomBannerHide = (location: string): boolean => {
    return (
      location === NAV_ACCOUNT ||
      location === NAV_TERMS_CONDITIONS ||
      location === NAV_PRIVACY_POLICY ||
      location === NAV_LIKES ||
      location === NAV_SELLER_PROFILE ||
      location === PAYMENT ||
      location === PAYMENT_SUMMERY ||
      location === NOT_FOUND ||
      location === NAV_MYADS ||
      location === NAV_MEMBERSHIPS ||
      location === NAV_MY_MEMBERSHIP ||
      location === NAV_PRIVACY_POLICY
    );
  };

  const handleBannerClick = () => {
    logEvent(analytics, CLICK_ON_LEFT_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };

  return (
    <NavigationContext.Consumer>
      {(nav: any) => (
        <div style={{ backgroundColor: '#FFF' }}>
          <Grid container className={classes.root}>
            {leftBannerHide(nav.current) ? null : (
              <Grid
                item
                className={classes.left}
                onClick={handleBannerClick}
                md={2}
              >
                <div style={{ marginTop: "30px", position: "sticky", top: "95px" }}>
                  {/* <img src="assets/walls/wall-cars.png" id="wall"></img> */}
                  {(loc == '' ?

                    <>
                      {isMotor() ? (

                        <iframe scrolling="no"
                          style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                          src="https://services.saruwata.lk/AdManager/DV/DV-Motors-Desktop-Skyscraper-160x600/index.html" />

                      ) : isProperty() ? (

                        <iframe scrolling="no"
                          style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                          src="https://services.saruwata.lk/AdManager/DV/DV-Property-Sale-Desktop-Skyscraper-160x600/index.html" />


                      ) : isPropertyRent() ?


                        <iframe scrolling="no"
                          style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                          src="https://services.saruwata.lk/AdManager/DV/DV-Property-Rent-Desktop-Skyscraper-160x600/index.html" />

                        : isElectronic() ?

                          <iframe scrolling="no"
                            style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                            src="https://services.saruwata.lk/AdManager/DV/DV-Mobiles-and-Electronics-Desktop-Skyscraper-160x600/index.html" />


                          : isClassified() ?


                            <iframe scrolling="no"
                              style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                              src="https://services.saruwata.lk/AdManager/DV/DV-Classifieds-Desktop-Skyscraper-160x600/index.html" />

                            : (


                              <iframe scrolling="no"
                                style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Skyscraper-160x600/index.html" />




                            )}


                    </>
                    :
                    <>
                      {isMotor() ? (

                        <iframe scrolling="no"
                          style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                          src="https://services.saruwata.lk/AdManager/L1/L1-Motors-Desktop-Skyscraper-160x600/index.html" />

                      ) : isProperty() ? (

                        <iframe scrolling="no"
                          style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                          src="https://services.saruwata.lk/AdManager/L1/L1-Property-Sale-Desktop-Skyscraper-160x600/index.html" />


                      ) : isPropertyRent() ?


                        <iframe scrolling="no"
                          style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                          src="https://services.saruwata.lk/AdManager/L1/L1-Property-Rent-Desktop-Skyscraper-160x600/index.html" />

                        : isElectronic() ?

                          <iframe scrolling="no"
                            style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                            src="https://services.saruwata.lk/AdManager/L1/L1-Mobiles-and-Electronics-Desktop-Skyscraper-160x600/index.html" />


                          : isClassified() ?


                            <iframe scrolling="no"
                              style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                              src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Desktop-Skyscraper-160x600/index.html" />

                            : (


                              <iframe scrolling="no"
                                style={{ width: "160px", height: "600px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Skyscraper-160x600/index.html" />




                            )}


                    </>

                  )}




                </div>
              </Grid>
            )}

            <Grid
              item

              xs={12}
              md={7}

              // style={{ paddingLeft: "10px", paddingRight: "10px" }}
              className={
                // leftBannerHide(nav.current) ? classes.extcenter : classes.center
                leftBannerHide(nav.current) ? (rightBannerHide(nav.current) ? classes.centerEdit : classes.extcenter) : classes.center
              }
            >
              <Loader />
              <Content scroll={props.scroll}></Content>

              {/* {BottomBannerHide(nav.current) ? null : (
                <Hidden xsDown={true} smDown={true}>

                  <PageFooter></PageFooter>
                </Hidden>
              )} */}

            </Grid>

            {rightBannerHide(nav.current) ? null : (
              // <Hidden xsDown={true} smDown={true}>
              <Grid item md={3} className={classes.right}>
                <SideBarContent />
              </Grid>
              // </Hidden>
            )}





            {/* 

          <Grid item xs className={classes.right}>
            <SideBarContent></SideBarContent>
          </Grid> */}
          </Grid>

        </div>
      )}
    </NavigationContext.Consumer>
  );
};

export default Page;
