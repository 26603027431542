import { Grid } from "@material-ui/core";
import { walkOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailRoomRentMobile.css';

const FullDetailRoomRentMobile = (props: any) => {

    const [item, setItem] = useState(props.item);
    const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={9} className="col-left">
                        <img src={walkOutline} className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.PRIVATE_ENTRANCE}</span>
                    </Grid>
                    <Grid item xs={3} className="brand-names">
                        <span className="brand-name-english">
                            {
                                (item.tags
                                    .filter((tag: any) => tag.name !== "(NA)")
                                    .filter((tag: any) => tag.types.includes("Property_Private_Entrance")).length > 0) ? "Yes" : "No"
                            }
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-bathrooms.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.BATH_ROOMS}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {
                                (item.tags
                                    .filter((tag: any) => tag.name !== "(NA)")
                                    .filter((tag: any) => tag.types.includes("Property_Private_Bathroom")).length > 0) ? "Yes" : "No"
                            }
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-furnished.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.FURNISHED}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            <span className="brand-name-english">
                                {
                                    (item.tags
                                        .filter((tag: any) => tag.name !== "(NA)")
                                        .filter((tag: any) => tag.types.includes("Property_Furnished")).length > 0) ? "Yes" : "No"
                                }
                            </span>
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-car-parking.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '12px') }}>{lan.PARKING_SPACE}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            <span className="brand-name-english">
                                {
                                    (item.tags
                                        .filter((tag: any) => tag.name !== "(NA)")
                                        .filter((tag: any) => tag.types.includes("Property_Parking_Space")).length > 0) ? "Yes" : "No"
                                }
                            </span>
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>

        </Grid>
    )
}

export default FullDetailRoomRentMobile;