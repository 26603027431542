// import React, { Component } from 'react';
// import { Text, View, Dimensions, StyleSheet } from 'react-native';

// import Carousel from 'react-native-snap-carousel'; // Version can be specified in package.json



// const SLIDER_WIDTH = Dimensions.get('window').width;
// const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 0.7);
// const ITEM_HEIGHT = Math.round(ITEM_WIDTH * 3 / 4);

// const DATA = [];
// for (let i = 0; i < 10; i++) {
//     DATA.push(i)
// }

// export default class MyCarousel extends Component {

//     state = {
//         index: 0
//     }

//     constructor(props) {
//         super(props);
//         this._renderItem = this._renderItem.bind(this)
//     }

//     _renderItem({ item }) {
//         return (
//             <View style={styles.itemContainer}>
//                 <Text style={styles.itemLabel}>{`Item ${item}`}</Text>
//             </View>
//         );
//     }

//     render() {
//         return (
//             <View>
//                 <Carousel
//                     ref={(c) => this.carousel = c}
//                     data={DATA}
//                     renderItem={this._renderItem}
//                     sliderWidth={SLIDER_WIDTH}
//                     itemWidth={ITEM_WIDTH}
//                     containerCustomStyle={styles.carouselContainer}
//                     inactiveSlideShift={0}
//                     onSnapToItem={(index) => this.setState({ index })}

//                     useScrollView={true}
//                 />

//             </View>
//         );
//     }
// }

// const styles = StyleSheet.create({
//     carouselContainer: {
//         marginTop: 50
//     },
//     itemContainer: {
//         width: ITEM_WIDTH,
//         height: ITEM_HEIGHT,
//         alignItems: 'center',
//         justifyContent: 'center',
//         backgroundColor: 'dodgerblue'
//     },
//     itemLabel: {
//         color: 'white',
//         fontSize: 24
//     },
//     counter: {
//         marginTop: 25,
//         fontSize: 30,
//         fontWeight: 'bold',
//         textAlign: 'center'
//     }
// });

import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Card, Typography, Avatar, Hidden } from '@material-ui/core'
import { imagesOutline } from 'ionicons/icons';
import { currencyConverter } from '../../../features/helper';
import { frontend } from '../../../config/urls';
import { Language } from '../../../language/Language';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../features/language/language_slice';
import { SDistricts } from '../../../language/sDistricts';

var lan;
var selectedLanguage = '';
export default function MyCarousel(props) {

    var items1 = props.items;
    selectedLanguage = useSelector(selectLanguage);
    const getLanguage = () => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    lan = l.getLan;



    return (
        <Carousel interval='8000' cycleNavigation={true} swipe={true} stopAutoPlayOnHover={false} animation='fade' indicators={false} >
            {
                items1.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}
const getSubVariableName = (name) => {
    return name.toUpperCase().replace(/ /g, "_").replace('&', 'AND');
}

var sDiscticts = new SDistricts();

function Item(props) {
    var loc = new String(props.item.specs.City);
    var city = loc.split('-')[1];
    const isDeal = () => {
        return props.item.discounted_price != 0 && props.item.discounted_price < props.item.price;
    }
    const link = `${frontend}/${props.item.main_category.slug}/${props.item.sub_category.slug}/${props.item.hash}`;
    return (
        <>

            <Hidden xsDown={true} smDown={true}>
                <a href={link}>
                    <Grid container >
                        <Grid item xs={7}>
                            <div style={{ height: "260px", position: "relative" }}>
                                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.item.desktop_images[props.item.cover_image]} />
                                {/* <img src="../../assets/images/saruwata-watermark.png" style={{ zIndex: 100, position: "absolute", top: 0 ,left:0}} /> */}

                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <div style={{ background: 'url("../../assets/images/bg-spotlight-img.webp")', height: "100%", backgroundRepeat: "no-repeat", backgroundPosition: "right", position: "relative" }}>
                                <Avatar style={{ position: "absolute", top: "10px", right: "10px" }} src='../../../assets/icon/icon_featured-ad.png' />

                                <div style={{ position: "absolute", fontSize: "20px", fontWeight: "bold", color: "black", top: "55px", left: '20px', }}>
                                    <Typography style={{ fontSize: "18px", fontWeight: "bold", paddingRight: "10px" }}>
                                        {(props.item.title.length > 60 ? props.item.title.substring(0, 60) + "..." : props.item.title)}
                                    </Typography>
                                    {/* <Typography style={{ fontSize: "20px", fontWeight: "400", color: "blue" }}>Rs.{props.item.price}</Typography> */}
                                    <Typography style={{ fontSize: "14px", color: "#5A5A5A" }}>
                                        {/* {props.item.specs.Cat_Lev_2.charAt(0).toUpperCase() + props.item.specs.Cat_Lev_2.slice(1).toLowerCase()} */}
                                        {lan[getSubVariableName(props.item.specs.Cat_Lev_2)]}
                                    </Typography>
                                    <Typography style={{ fontSize: "17px", fontWeight: "600", color: "#0080FF", marginTop: "10px" }}>{lan.RS} {currencyConverter().format((isDeal()) ? props.item.discounted_price : props.item.price)}</Typography>
                                    <Typography style={{ fontSize: "13px", color: "#5A5A5A" }} ><img style={{ height: "14px", color: "#5A5A5A" }} src='../../assets/icon/icon-location-pin.svg' />
                                        {/* {"   " + (city !== null || city !== "" ? city.trim().charAt(0).toUpperCase() + city.slice(2).toLowerCase() : city.charAt(0).toUpperCase() + city.slice(2).toLowerCase())} */}
                                        {(selectedLanguage == 'si' ?
                                            sDiscticts.getSinhalaDistrictName((city.toUpperCase()))
                                            : (selectedLanguage == 'ta' ? sDiscticts.getTamilDistrictName(city.toUpperCase()) :
                                                (city.trim().charAt(0).toUpperCase() + city.slice(2).toLowerCase())))}

                                    </Typography>
                                    <Typography style={{ fontSize: "13px", marginTop: "20px", color: "#5A5A5A", fontWeight: 500 }} > {props.item.posted_by}</Typography>

                                </div>
                            </div>
                        </Grid>

                        {/* <Grid item xs={7}>
                    <div style={{ height: "260px", position: "relative" }}>
                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.item.src} />
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div style={{ background: 'url("../../assets/images/bg-spotlight-img.png")', height: "100%", backgroundRepeat: "no-repeat", backgroundPosition: "center", position: "relative" }}>
                        <Avatar style={{ position: "absolute", top: "10px", right: "10px" }} src='../../../assets/icon/icon-featured-ad.png' />

                        <div style={{ position: "absolute", fontSize: "20px", fontWeight: "bold", color: "black", top: "62px", left: '30px', }}>
                            <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>{props.item.name}</Typography>
                            <Typography style={{ fontSize: "20px", fontWeight: "400", color: "blue" }}>{props.item.price}</Typography>
                            <br />

                            <Typography style={{ fontSize: "16px" }} ><img style={{ height: "14px" }} src='../../assets/icon/icon-location-pin.svg' />                   {props.item.location}</Typography>
                            <Typography style={{ fontSize: "16px", }} >   <img style={{ width: "15px", height: "15px" }} src='../../../assets/icon/icon-membership.svg' />   {props.item.postBy}</Typography>

                        </div>
                    </div>
                </Grid> */}


                    </Grid>
                </a>
            </Hidden>

            <Hidden lgUp={true} mdUp={true}>


                <a href={link}>
                    <Grid container >
                        <Grid item xs={12}>
                            <div style={{ height: "180px", position: "relative" }} >
                                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.item.desktop_images[props.item.cover_image]} />
                                <Avatar style={{ position: "absolute", top: "10px", right: "10px", width: "25px", height: "25px" }} src='../../../assets/icon/icon_featured-ad.png' />

                                <div style={{ position: "absolute", width: "100%", fontSize: "20px", fontWeight: "bold", bottom: "0", paddingBottom: "10px", color: "white", paddingLeft: '10px', background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%)" }}>
                                    <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>{props.item.title}</Typography>
                                    <Typography style={{ fontSize: "14px", fontWeight: "400", color: "yellow" }}>රු. {currencyConverter().format((isDeal()) ? props.item.discounted_price : props.item.price)}</Typography>
                                    <Typography style={{ fontSize: "14px", }} >   <img style={{ width: "13px", height: "13px" }} src='../../../assets/icon/icon-membership.svg' />   {props.item.posted_by}</Typography>

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </a>

            </Hidden>

        </>
    )
}
