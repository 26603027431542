import { IonCol, IonGrid, IonIcon, IonRow, useIonViewDidEnter } from "@ionic/react";
import { createStyles, LinearProgress, makeStyles, Paper, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CLICK } from "../../../config/actions";
import { CHOOSE_CATEGORY } from "../../../config/events";
import { selectedCategories, setCurrentStep, setSelectedCategories } from "../../../features/addPost/addPostSlice";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import './SelectCategory.css';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../..";




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            // justifyContent: "space-between",
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        paper: {
            '&:hover': {
                boxShadow: "0px 0px 8px #00000026",
            },
        }
    }),
);
var status = 0;

export function isStatusOk() {
    return status;
}

const SelectCategory = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [hover, sethover] = useState(false);
    const selectedCat = useSelector(selectedCategories);

    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;


    if (props.isSubNull == "Okay") {
        status = 1;
    } else if (props.isSubNull == "Wait") {
        status = 0;

    }

    const icons = [
        "assets/icon/icon-motors.svg",
        "assets/icon/icon-electronics.svg",
        "assets/icon/icon-property-for-sale.svg",
        "assets/icon/icon-proerty-for-rent.svg",
        "assets/icon/icon-classifies.svg"
    ];




    const Motors = () => {

        dispatch(setSelectedCategories({ name: 'MOTORS', icon: icons[0] }));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
    };
    const MobileElectronics = () => {
        dispatch(setSelectedCategories({ name: 'MOBILES AND ELECTRONICS', icon: icons[1] }));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
    };
    const PropertySale = () => {
        dispatch(setSelectedCategories({ name: 'PROPERTY FOR SALE', icon: icons[2] }));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
    };
    const PropertyRent = () => {
        dispatch(setSelectedCategories({ name: 'PROPERTY FOR RENT', icon: icons[3] }));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
    };
    const Classifieds = () => {
        dispatch(setSelectedCategories({ name: 'CLASSIFIEDS', icon: icons[4] }));
        logEvent(analytics, CHOOSE_CATEGORY, { action: ACTION_CLICK, platform: 'DESKTOP' });
    };







    return (

        <div id="main-container-sc" >
            {
                (isStatusOk() ?

                    <section className={classes.root} >

                        <Paper id={(selectedCat.level1 == "MOTORS") ? "btn-border-sc-active" : "btn-border-sc"} onClick={Motors} className={classes.paper} elevation={0}>
                            <div id="btn-inner-sc">
                                <div>
                                    <IonIcon id="icon-car-sc" src="assets/icon/icon-motors.svg"></IonIcon>
                                </div>
                                <div className="cat-title-text">
                                    <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Motors */}
                                        {lan.MOTORS}
                                    </span>
                                </div>
                            </div>
                            {
                                (selectedCat.level1 == "MOTORS") ?
                                    <div className="checkicon-category">
                                        <img
                                            src='assets/icon/icon-selected-2.svg'
                                            style={{ width: "22px", height: "22px" }}
                                        ></img>
                                    </div>
                                    :
                                    null
                            }
                        </Paper>
                        <Paper id={(selectedCat.level1 == "MOBILES AND ELECTRONICS") ? "btn-border-sc-active" : "btn-border-sc"} onClick={MobileElectronics} className={classes.paper} elevation={0}>
                            <div id="btn-inner-sc">
                                <div>
                                    <IonIcon id="icon-mobile-sc" src="assets/icon/icon-electronics.svg"></IonIcon>

                                </div>
                                <div className="cat-title-text">
                                    <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Mobile & Electronics */}
                                        {lan.MOBILE_AND_ELECTRONICS}
                                    </span>
                                </div>
                            </div>
                            {
                                (selectedCat.level1 == "MOBILES AND ELECTRONICS") ?
                                    <div className="checkicon-category">
                                        <img
                                            src='assets/icon/icon-selected-2.svg'
                                            style={{ width: "22px", height: "22px" }}
                                        ></img>
                                    </div>
                                    :
                                    null
                            }
                        </Paper>
                        <Paper id={(selectedCat.level1 == "PROPERTY FOR SALE") ? "btn-border-sc-active" : "btn-border-sc"} onClick={PropertySale} className={classes.paper} elevation={0}>
                            <div id="btn-inner-sc">
                                <div>
                                    <IonIcon id="icon-car-sc" src="assets/icon/icon-property-for-sale.svg"></IonIcon>

                                </div>
                                <div className="cat-title-text">
                                    <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Property for Sale */}
                                        {lan.PROPERTY_FOR_SALE}
                                    </span>
                                </div>
                            </div>
                            {
                                (selectedCat.level1 == "PROPERTY FOR SALE") ?
                                    <div className="checkicon-category">
                                        <img
                                            src='assets/icon/icon-selected-2.svg'
                                            style={{ width: "22px", height: "22px" }}
                                        ></img>
                                    </div>
                                    :
                                    null
                            }

                        </Paper>
                        <Paper id={(selectedCat.level1 == "PROPERTY FOR RENT") ? "btn-border-sc-active" : "btn-border-sc"} onClick={PropertyRent} className={classes.paper} elevation={0}>
                            <div id="btn-inner-sc">
                                <div >
                                    <IonIcon id="icon-car-sc" src="assets/icon/icon-proerty-for-rent.svg"></IonIcon>

                                </div>
                                <div className="cat-title-text">
                                    <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Property for Rent */}
                                        {lan.PROPERTY_FOR_RENT}
                                    </span>
                                </div>
                            </div>
                            {
                                (selectedCat.level1 == "PROPERTY FOR RENT") ?
                                    <div className="checkicon-category">
                                        <img
                                            src='assets/icon/icon-selected-2.svg'
                                            style={{ width: "22px", height: "22px" }}
                                        ></img>
                                    </div>
                                    :
                                    null
                            }
                        </Paper>
                        <Paper id={(selectedCat.level1 == "CLASSIFIEDS") ? "btn-border-sc-active" : "btn-border-sc"} onClick={Classifieds} className={classes.paper} elevation={0}>
                            <div id="btn-inner-sc">
                                <div>
                                    <IonIcon id="icon-car-sc" src="assets/icon/icon-classifies.svg"></IonIcon>
                                </div>
                                <div className="cat-title-text">
                                    <span className="btn-names-sc" style={{ fontSize: (getLanguage() == 'english' ? '14px' : '12px') }}>
                                        {/* Classifieds */}
                                        {lan.CLASSIFIEDS}
                                    </span>
                                </div>
                            </div>
                            {
                                (selectedCat.level1 == "CLASSIFIEDS") ?
                                    <div className="checkicon-category">
                                        <img
                                            src='assets/icon/icon-selected-2.svg'
                                            style={{ width: "22px", height: "22px" }}
                                        ></img>
                                    </div>
                                    :
                                    null
                            }
                        </Paper>

                    </section>



                    :
                    <section>
                        <LinearProgress color="primary" />
                    </section>)

            }




        </div>
    )
}

export default SelectCategory;