import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, createStyles, Divider, Grid, Hidden, LinearProgress, makeStyles, Snackbar, Switch, Theme, Typography, useMediaQuery, useTheme, withStyles, } from "@material-ui/core";
import MyAds from "../../pages/MyAds/MyAds";
import MyAdsSidebarComponent from "../MyAdsSidebarComponent/MyAdsSidebarComponent";
import { NAV_MY_MEMBERSHIP } from "../../providers/NavigationProvider";
import { VIEW_MYMEMEBERSHIP_PAGE } from "../../config/events";
import {
    setCurrentBehavior,
    setCurrentLocation,
} from "../../features/navigation/navigationSlice";
import { ACTION_VIEW } from "../../config/actions";
import { useDispatch, useSelector } from "react-redux";
import MyMembershipStepper from "./MyMembershipStepper";
import axios from "axios";
import { MY_MEMBERSHIP_DETAILS, MY_MEMBERSHIP_GET_USER_REQUEST, MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN } from "../../config/urls";
import { selectLoggedUser } from "../../features/session/sessionSlice";
import { UserContext } from "../../providers/UserProvider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            paddingLeft: '20px',
            paddingRight: '20px',
            marginBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            alignContent: 'right',
            borderRadius: '40px'
        },
        mainBackground: {
            backgroundColor: "#EBEEF1",
            borderRadius: "6px",
            padding: "10px 10px"

        },
        whiteBackground: {
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            border: '1px solid #DDDDDD',
            padding: '10px 30px'

        },
        innerWhiteBackground: {
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            border: '1px solid #DDDDDD',
            padding: '10px 10px'

        },
        title: { fontSize: '14px' },
        innerTitle: { fontSize: '14px', color: '#5A5A5A' },
        subAnswer: { fontSize: '14px', color: '#999999' },
        blueTitle: { fontSize: '14px', color: '#0080FF', float: 'right' },
        disabledATitle: { fontSize: '14px', color: '#DDDDDD', float: 'right' },
        activeATitle: { fontSize: '14px', color: '#0080FF', float: 'right' },
        subTitles: {
            color: '#000000',
            fontSize: '14px',
            fontWeight: 600
        },
        categoryFont: {
            color: '#43AB1C',
            fontSize: '14px',
            fontWeight: 600
        },
        draftedPaymentStatus: {
            color: '#0080FF',
            fontSize: '14px'
        },
        pendingPaymentStatus: {
            color: '#FF9C00',
            fontSize: '14px'
        },
        reviewingStatus: {
            color: '#1890FF',
            fontSize: '14px'
        },
        activeStatus: {
            color: '#43AB1C',
            fontSize: '14px'
        },
        disabledPaymentButton: {
            color: '#DDDDDD',
            fontSize: '14px',
            backgroundColor: '#F5F6F8',
            border: '1px solid #DDDDDD',
            padding: '5px 20px',
            borderRadius: '7px'
        },
        paymentButton: {
            color: '#FFFFFF',
            fontSize: '14px',
            backgroundColor: '#FF9C00',
            border: '1px solid #FF9C00',
            padding: '5px 20px',
            borderRadius: '7px'
        },
        viewInvoiceButton: {
            color: '#0080FF',
            fontSize: '14px',
            backgroundColor: '#F5F6F8',
            border: '1px solid #0080FF',
            padding: '5px 20px',
            borderRadius: '7px'
        },
        disabledUsageHistoryButton: {
            color: '#DDDDDD',
            fontSize: '14px',
            backgroundColor: '#F5F6F8',
            border: '1px solid #DDDDDD',
            padding: '5px 20px',
            borderRadius: '7px'
        },
        disabledCombineVoucherButton: {
            color: '#DDDDDD',
            fontSize: '14px',
            backgroundColor: '#F5F6F8',
            border: '1px solid #DDDDDD',
            padding: '5px 20px',
            borderRadius: '7px'
        },
        activeUsageHistoryButton: {
            color: '#FF9C00',
            fontSize: '14px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #FF9C00',
            padding: '5px 20px',
            borderRadius: '7px'
        },
        activeCombineVoucherButton: {
            color: '#0080FF',
            fontSize: '14px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #0080FF',
            padding: '5px 20px',
            borderRadius: '7px'
        }

    })
);
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);

const MyMembership = () => {

    const classes = useStyles();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const user = useSelector(selectLoggedUser);
    const [userEmail, setUserEmail] = useState('');
    const [page, setPage] = useState(1);
    const [requestEligible, setRequestEligible] = useState(false);
    const scrollContent = document.getElementById('scrollable-root');
    const [request, setRequest] = useState(false);

    ///////////////////////////
    const [membershipRequests, setMembershipRequests] = useState([]);
    const [membershipRequestId, setMembershipRequestId] = useState('');
    const [membershipName, setMembershipName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [membershipId, setMembershipId] = useState('');
    const [mainCategory, setMainCategory] = useState('');
    const [price, setPrice] = useState('');
    const [adCount, setAdCount] = useState('');
    const [usedAdCount, setUsedAdCount] = useState('');
    const [endDate, setEndDate] = useState('');
    const [statusText, setStatusText] = useState('');
    const [membershipCode, setMembershipCode] = useState('');
    const [voucherTotalAmount, setVoucherTotalAmount] = useState('');
    const [voucherUsedAmount, setVoucherUsedAmount] = useState('');
    const [voucherDuration, setVoucherDuration] = useState('');
    const [voucherStatus, setVoucherStatus] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [daysCount, setDaysCount] = useState('');
    const [membershiptype, setMembershiptype] = useState('');
    const [applicableCategories, setApplicableCategories] = useState('');
    const [reference, setReference] = useState('');
    const [viewSettings, setViewSettings] = useState(false);
    const [requested, setRequested] = useState(false);
    //////////////////////////


    useEffect(() => {
        setRequestEligible(true);
        dispatch(setCurrentLocation(NAV_MY_MEMBERSHIP));
        // getMembershipUserRequest();

        scrollContent?.scrollTo(0, 0);
        logEvent(analytics, VIEW_MYMEMEBERSHIP_PAGE, { action: ACTION_VIEW });
        return () => {
            setRequestEligible(false);
            setPage(1);
        };
    }, []);

    const setData = (u: any) => {
        setUserEmail(u.email);
        getMembershipUserRequest(u.email);
    }
    // const getMembershipUserRequest = (user: any) => {
    const getMembershipUserRequest = (uE: string) => {

        if (uE != '') {

            axios.get(MY_MEMBERSHIP_GET_USER_REQUEST +"/"+ uE + MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN).then((response) => {

                setMembershipRequests(response.data);
                setRequested(true);


            });


        }
    }
    const getMyMembershipDetails = (membershipRequestId: any, token: any) => {

        setRequest(true);
        axios.get(MY_MEMBERSHIP_DETAILS + membershipRequestId).then((response) => {
            setMembershipName(response.data.membershipName);
            setStartDate(response.data.startDate);
            setMembershipId(response.data.membershipId);

            setPrice(response.data.amount);
            setAdCount(response.data.adCount);
            setUsedAdCount(response.data.useCount);
            setEndDate(response.data.endDate);
            setStatusText(response.data.statusText);
            setMembershipCode(response.data.membershipCode);
            setVoucherTotalAmount(response.data.voucherTotalAmount);
            setVoucherUsedAmount(response.data.voucherUseAmount);
            setVoucherCode(response.data.voucherCode);
            setDaysCount(response.data.daysCount);
            setMembershiptype(response.data.membershiptype);
            setReference(response.data.reference);

            var cats = '';
            if (response.data.applicableCategories != null) {
                if (response.data.applicableCategories != []) {
                    var c = response.data.applicableCategories;

                    for (let index = 0; index < c.length; index++) {
                        cats += response.data.applicableCategories[index] + ' | ';

                    }
                }
            }

            setApplicableCategories(cats);
        });
    }


    return (
        // <>

        //     <Hidden mdUp={true} lgUp={true}>
        //         <MyAdsSidebarComponent />
        //     </Hidden>
        //     <div hidden>
        //         <MyAds />
        //     </div>
        // </>

        <>
            <UserContext.Consumer>
                {(u: any) => (
                    (u != null ?
                        <>

                            {
                                (requested ? null : setData(u))
                            }

                            {/* {getMembershipUserRequest(user)} */}

                            <Grid container spacing={2} className={classes.body}>
                                <Hidden xsDown={true} smDown={true}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <Grid container direction="row">
                                            <Grid item xs={6} sm={6} md={6} lg={6} direction="row">
                                                <h5 style={{ fontSize: "18px", padding: "0 10px", fontWeight: 600 }}>My Membership</h5>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} alignItems="flex-end" justifyContent="flex-end" direction="row">
                                                <div style={{ display: 'flex', float: 'right' }}>
                                                    {(viewSettings ?
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.button}
                                                            startIcon={<img src="./assets/icon/icon-seller-settings-button.svg" />}
                                                            onClick={() => navigate("/sellerPageSetting")}
                                                        >
                                                            Shop Page Setting &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '12px' }} />
                                                        </Button>
                                                        : null)}
                                                    {/* </div>
                                            <div style={{ float: 'right' }}> */}
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.button}
                                                        startIcon={<img src="./assets/icon/icon-my-ads.svg" />}
                                                        onClick={() => navigate("/myads")}

                                                    >
                                                        My adverts &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '12px' }} />
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                <Hidden lgUp={true} mdUp={true}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <Grid container direction="row">
                                            <Grid item xs={12} sm={12} md={12} lg={12} direction="row">
                                                <h5 style={{ fontSize: "18px", padding: "0 10px", fontWeight: 600 }}>My Membership</h5>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} alignItems="flex-end" justifyContent="flex-end" direction="row">
                                                <div style={{ display: 'flex', float: 'left' }}>
                                                    {(viewSettings ?
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.button}
                                                            startIcon={<img src="./assets/icon/icon-seller-settings-button.svg" />}
                                                            onClick={() => navigate("/sellerPageSetting")}
                                                        >
                                                            Shop Page Setting
                                                        </Button>
                                                        : null)}
                                                    {/* </div>
                                            <div style={{ float: 'right' }}> */}
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.button}
                                                        startIcon={<img src="./assets/icon/icon-my-ads.svg" />}
                                                        onClick={() => navigate("/myads")}

                                                    >
                                                        My adverts
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                {membershipRequests != [] ? membershipRequests?.map((item: any) => (
                                    <>
                                        {(item.requestStatus != '0' ?
                                            (!request ? getMyMembershipDetails(item.userHasMembershipId, u.token) : '') : ''
                                        )}
                                        {(item.requestStatus == '2' ? setViewSettings(true) : setViewSettings(false))}
                                        {(item.requestStatus != '2' ?
                                            <>
                                                <Hidden lgUp={true} mdUp={true}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} direction='row'>
                                                        <MyMembershipStepper orientation='vertical' activeStep={item.requestStatus}></MyMembershipStepper>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden xsDown={true} smDown={true}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} direction='row'>
                                                        <MyMembershipStepper orientation='horizontal' activeStep={item.requestStatus}></MyMembershipStepper>
                                                    </Grid>
                                                </Hidden>
                                            </> : null
                                        )}

                                        <Grid item xs={12} sm={12} md={12} lg={12} direction='row' className={classes.mainBackground}>
                                            <Grid container className={classes.whiteBackground} spacing={1}>
                                                <Hidden lgUp={true} mdUp={true}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <div style={{ display: 'flex' }}>
                                                            <img src="./assets/icon/icon-membership.svg" style={{ height: '22px', width: '22px' }} />
                                                            <Typography style={{ color: '#000000', fontWeight: 'bold', fontSize: '14px' }}>&nbsp;&nbsp; {(item.requestStatus != '0' ? membershipName : item.category)}</Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <div style={{ display: 'flex' }}>
                                                            <span className={classes.subTitles}>System:&nbsp;&nbsp;</span>
                                                            <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                        </div>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden xsDown={true} smDown={true}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <div style={{ display: 'flex' }}>
                                                            <img src="./assets/icon/icon-membership.svg" style={{ height: '22px', width: '22px' }} />
                                                            <Typography style={{ color: '#000000', fontWeight: 'bold' }}>&nbsp;&nbsp;{(item.requestStatus != '0' ? membershipName : item.category)}</Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <div style={{ display: 'flex', float: 'right' }}>
                                                            <span className={classes.subTitles}>System:&nbsp;&nbsp;</span>
                                                            <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                        </div>
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                            <Grid container className={classes.whiteBackground} spacing={1}>
                                                <Hidden xsDown={true} smDown={true}>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Name</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{item.name}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <div style={{ display: 'flex' }}>
                                                            <span className={classes.title}>Phone Number &nbsp;
                                                                <CheckCircle style={{ color: '#43AB1C', height: '14px', width: '14px' }} /></span>
                                                        </div>

                                                        <span className={classes.subTitles}>{item.mobile}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <div style={{ display: 'flex' }}>
                                                            <span className={classes.title}>Email &nbsp;
                                                                <CheckCircle style={{ color: '#43AB1C', height: '14px', width: '14px' }} /></span>
                                                        </div>

                                                        <span className={classes.subTitles}>{item.userEmail}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Location</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{item.city}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px' }}>
                                                            <Divider variant="fullWidth" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <span className={classes.title}>Requested Category:&nbsp;&nbsp;</span>
                                                        <span className={classes.categoryFont}>{item.category}</span>
                                                        <br />
                                                        <span className={classes.title}>Applicable Category:&nbsp;&nbsp;</span>
                                                        <span className={classes.subTitles}>
                                                            {(applicableCategories != '' ? applicableCategories : '-')}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Requested Date</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestTime != '' ? item.requestTime.split('T')[0] : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Activate Date</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestStatus == '2' ? (startDate != '' ? startDate?.split(' ')[0] : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px' }}>
                                                            <Divider variant="fullWidth" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Membership ID</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? membershipId : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Membership Type</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? membershiptype : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Main Category</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{item.category}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Price</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? 'Rs.' + price : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginTop: '10px' }}>
                                                        <div className={classes.innerWhiteBackground}>
                                                            <div>
                                                                <span className={classes.innerTitle}>Limited Ads</span>
                                                                <span className={classes.blueTitle}>{(item.requestStatus != '0' ? (usedAdCount != '' ? usedAdCount : '0') : '0')}/ {(item.requestStatus != '0' ? (adCount != '' ? adCount : '0') : '0')} Ads</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (item.requestStatus != '0' ? <BorderLinearProgress variant="determinate" value={(Number(usedAdCount) / Number(adCount)) * 100} /> :
                                                                        <BorderLinearProgress variant="determinate" value={(0.000) * 100} />)
                                                                }

                                                            </div>
                                                            <div>
                                                                <span className={classes.innerTitle}>Expires on: {(item.requestStatus != '0' ? ((endDate != '' || endDate != null) ? endDate : '-') : '-')}</span>
                                                                {(item.requestStatus == '2' ?
                                                                    <a className={classes.blueTitle} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        navigate("/add-post");
                                                                    }}><u>Post Ad</u></a>
                                                                    :
                                                                    <a className={classes.disabledATitle}>Post Ad</a>)
                                                                }
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={1} lg={1} style={{ marginTop: '10px' }}></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: '10px' }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                <div>
                                                                    <span className={classes.title}>Account Status: </span>
                                                                    <br />
                                                                    <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                {(item.requestStatus == '0' ? <button className={classes.disabledPaymentButton} disabled>Payment</button> :
                                                                    (item.requestStatus == '1' ? <button className={classes.paymentButton} onClick={() => { navigate('/paymentMembership/' + reference) }}>Payment</button> :
                                                                        (item.requestStatus == '2' ? <button className={classes.viewInvoiceButton} onClick={() => { navigate('/membershipPaymentSummery?msg=4-' + reference) }} >View Invoice</button> :
                                                                            (item.requestStatus == '3' ? <button className={classes.viewInvoiceButton} onClick={() => { navigate('/membershipPaymentSummery?msg=4-' + reference) }} >View Invoice</button> : null))))}

                                                            </Grid>
                                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <div style={{ display: 'float' }}>
                                                                    <span className={classes.title}>Membership code: </span>
                                                                    <span className={classes.subAnswer}>{(item.requestStatus != '0' ? (membershipCode != '' ? membershipCode : '-') : '-')}</span>
                                                                </div>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px' }}>
                                                            <Divider variant="fullWidth" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px', display: 'flex' }}>
                                                            <Avatar style={{ width: '24px', height: "18px" }} variant="square" src="../../../assets/icon/icon-voucher.svg" />
                                                            <span className={classes.pendingPaymentStatus}>&nbsp;&nbsp;Voucher</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Voucher Amount</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? (voucherTotalAmount != '' ? 'Rs.' + voucherTotalAmount : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Voucher Duration</span>
                                                        <br />
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? (daysCount != '' ? daysCount + ' days' : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                                        <span className={classes.title}>Voucher Status</span>
                                                        <br />
                                                        <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginTop: '10px' }}>
                                                        <div className={classes.innerWhiteBackground}>
                                                            <div>
                                                                <span className={classes.innerTitle}>Voucher Balance:</span>
                                                                <span className={classes.blueTitle}>{(item.requestStatus != '0' ? (voucherUsedAmount != '' ? 'Rs.' + voucherUsedAmount : '0') : '0')}/ {(item.requestStatus != '0' ? (voucherTotalAmount != '' ? 'Rs.' + voucherTotalAmount : '0') : '0')} </span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (item.requestStatus != '0' ? <BorderLinearProgress variant="determinate" value={(parseFloat(voucherUsedAmount) / parseFloat(voucherTotalAmount)) * 100} /> :
                                                                        <BorderLinearProgress variant="determinate" value={(0.000) * 100} />)
                                                                }
                                                            </div>
                                                            <div>
                                                                <span className={classes.innerTitle}>Expires on: {(item.requestStatus != '0' ? (endDate != '' ? endDate : '-') : '-')}</span>
                                                                {(item.requestStatus == '2' ?
                                                                    <a className={classes.blueTitle} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        navigate("/myads");
                                                                    }}><u>Promote Ad</u></a>
                                                                    :
                                                                    <a className={classes.disabledATitle}>Promote Ad</a>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={1} lg={1} style={{ marginTop: '10px' }}></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginTop: '10px' }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <div style={{ display: 'float' }}>
                                                                    {/* {(item.requestStatus == '0' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.activeUsageHistoryButton}>Usage History</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> : null))))}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {(item.requestStatus == '0' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.activeCombineVoucherButton}>Combine Voucher</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> : null))))} */}
                                                                    {(item.requestStatus == '0' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> : null))))}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {(item.requestStatus == '0' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> : null))))}
                                                                </div>
                                                            </Grid>
                                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <div style={{ display: 'float' }}>
                                                                    <span className={classes.title}>Voucher code:</span>
                                                                    <span className={classes.subAnswer}>{(item.requestStatus != '0' ? (voucherCode != '' ? voucherCode : '-') : '-')}</span>
                                                                </div>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>


                                                <Hidden lgUp={true} mdUp={true}>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Name</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{item.name}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <div style={{ display: 'flex' }}>
                                                            <span className={classes.title}>Phone Number &nbsp;
                                                                <CheckCircle style={{ color: '#43AB1C', height: '14px', width: '14px' }} /></span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{item.mobile}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <div style={{ display: 'flex' }}>
                                                            <span className={classes.title}>Email &nbsp;
                                                                <CheckCircle style={{ color: '#43AB1C', height: '14px', width: '14px' }} /></span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{item.userEmail}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Location</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{item.city}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px' }}>
                                                            <Divider variant="fullWidth" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Requested Category:&nbsp;&nbsp;</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.categoryFont}>{item.category}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Applicable Category:&nbsp;&nbsp;</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}> {(applicableCategories != '' ? applicableCategories : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Requested Date</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestTime != '' ? item.requestTime.split('T')[0] : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Activate Date</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestStatus == '2' ? (startDate != '' ? startDate?.split(' ')[0] : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px' }}>
                                                            <Divider variant="fullWidth" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Membership ID</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? membershipId : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Membership Type</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? membershiptype : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Main Category</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{item.category}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Price</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? 'Rs.' + price : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <div className={classes.innerWhiteBackground}>
                                                            <div>
                                                                <span className={classes.innerTitle}>Limited Ads</span>
                                                                <span className={classes.blueTitle}>{(item.requestStatus != '0' ? (usedAdCount != '' ? usedAdCount : '0') : '0')}/ {(item.requestStatus != '0' ? (adCount != '' ? adCount : '0') : '0')} Ads</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (item.requestStatus != '0' ? <BorderLinearProgress variant="determinate" value={(Number(usedAdCount) / Number(adCount)) * 100} /> :
                                                                        <BorderLinearProgress variant="determinate" value={(0.000) * 100} />)
                                                                }
                                                            </div>
                                                            <div>
                                                                <span className={classes.innerTitle}>Expires on: {(item.requestStatus != '0' ? (endDate != '' ? endDate : '-') : '-')}</span>
                                                                {(item.requestStatus == '2' ?
                                                                    <a className={classes.blueTitle} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        navigate("/add-post");
                                                                    }}><u>Post Ad</u></a>
                                                                    :
                                                                    <a className={classes.disabledATitle}>Post Ad</a>)
                                                                }

                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Account Status: </span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Membership code: </span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subAnswer} style={{ fontSize: '12px' }}>{(item.requestStatus != '0' ? (membershipCode != '' ? membershipCode : '-') : '-')}</span>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <div style={{ float: 'right' }}>
                                                            {(item.requestStatus == '0' ? <button className={classes.disabledPaymentButton} disabled>Payment</button> :
                                                                (item.requestStatus == '1' ? <button className={classes.paymentButton} onClick={() => { navigate('/paymentMembership/' + reference) }}>Payment</button> :
                                                                    (item.requestStatus == '2' ? <button className={classes.viewInvoiceButton} onClick={() => { navigate('/membershipPaymentSummery?msg=4-' + reference) }} >View Invoice</button> :
                                                                        (item.requestStatus == '3' ? <button className={classes.viewInvoiceButton} onClick={() => { navigate('/membershipPaymentSummery?msg=4-' + reference) }} >View Invoice</button> : null))))}
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px' }}>
                                                            <Divider variant="fullWidth" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div style={{ margin: '7px 0px', display: 'flex' }}>
                                                            <Avatar style={{ width: '24px', height: "18px" }} variant="square" src="../../../assets/icon/icon-voucher.svg" />
                                                            <span className={classes.pendingPaymentStatus}>&nbsp;&nbsp;Voucher</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Voucher Amount</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? (voucherTotalAmount != '' ? 'Rs.' + voucherTotalAmount : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Voucher Duration</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subTitles}>{(item.requestStatus != '0' ? (daysCount != '' ? daysCount + 'days' : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Voucher Status</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <div className={classes.innerWhiteBackground}>
                                                            <div>
                                                                <span className={classes.innerTitle}>Voucher Balance:</span>
                                                                {/* <span className={classes.blueTitle}>Rs. 0.00 / Rs. 6,000</span> */}
                                                                <span className={classes.blueTitle}>{(item.requestStatus != '0' ? (voucherUsedAmount != '' ? 'Rs.' + voucherUsedAmount : '0') : '0')}/ {(item.requestStatus != '0' ? (voucherTotalAmount != '' ? 'Rs.' + voucherTotalAmount : '0') : '0')}</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    (item.requestStatus != '0' ? <BorderLinearProgress variant="determinate" value={(parseFloat(voucherUsedAmount) / parseFloat(voucherTotalAmount)) * 100} /> :
                                                                        <BorderLinearProgress variant="determinate" value={(0.000) * 100} />)
                                                                }
                                                            </div>
                                                            <div>
                                                                <span className={classes.innerTitle}>Expires on: {(item.requestStatus != '0' ? (endDate != '' ? endDate : '-') : '-')}</span>
                                                                {(item.requestStatus == '2' ?
                                                                    <a className={classes.blueTitle} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        navigate("/myads");
                                                                    }}><u>Promote Ad</u></a>
                                                                    :
                                                                    <a className={classes.disabledATitle}>Promote Ad</a>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Voucher Status</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={(item.requestStatus == '0' ? classes.draftedPaymentStatus : (item.requestStatus == '1' ? classes.pendingPaymentStatus : (item.requestStatus == '2' ? classes.activeStatus : (item.requestStatus == '3' ? classes.reviewingStatus : ''))))}>{(item.requestStatus == '0' ? "Drafted" : (item.requestStatus == '1' ? "Payment Pending" : (item.requestStatus == '2' ? "Active" : (item.requestStatus == '3' ? "Reviewing" : ""))))}</span>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5}>
                                                        <span className={classes.title}>Voucher code:</span>
                                                    </Grid>
                                                    <Grid item xs={7} sm={7}>
                                                        <span className={classes.subAnswer}>{(item.requestStatus != '0' ? (voucherCode != '' ? voucherCode : '-') : '-')}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <div style={{ display: 'flex', float: 'right' }}>
                                                                    {/* {(item.requestStatus == '0' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.activeUsageHistoryButton}>Usage History</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> : null))))}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {(item.requestStatus == '0' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.activeCombineVoucherButton}>Combine Voucher</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> : null))))} */}
                                                                    {(item.requestStatus == '0' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledUsageHistoryButton} disabled>Usage History</button> : null))))}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {(item.requestStatus == '0' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                        (item.requestStatus == '1' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                            (item.requestStatus == '2' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> :
                                                                                (item.requestStatus == '3' ? <button className={classes.disabledCombineVoucherButton} disabled>Combine Voucher</button> : null))))}

                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    </>
                                )) : <LinearProgress color="primary" />}
                            </Grid>

                        </>
                        : <LinearProgress color="primary" />)
                )
                }
            </UserContext.Consumer>
        </>
    );
}
export default MyMembership;