import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { logEvent } from "firebase/analytics"
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_INPUT } from "../../../config/actions";
import { CURRENT_BUILD } from "../../../config/build_config";
import { INPUT_IMAGES } from "../../../config/events";
import {
  addImagesToBNewPost,
  addNewPostRawImages,
  clearImgFromSlider,
  selectCoverImgIndex,
  selectNewPosts,
  setCoverImgIndex,
  setCoverImgName,
  setImgFolder,
  setSelectedImages,
  updatePostImages,
} from "../../../features/post/postSlice";
import "./AddImages.css";
import CheckIcon from "@material-ui/icons/Check";
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { setCurrentStep } from "../../../features/addPost/addPostSlice";
import { setProgress } from "../../loader/LineProgress";
import { setfilldetailsopenn, setimagesSelectedd } from "../../../pages/AddPost/AddPost";
import { selectImagesCount, selectImageUrlArray, setImageUrl, setProg, setSaveImagesCount } from "../../../features/post/imageUpload";

import { setFinish, setIsComplete, setProgr } from "../../loader/LinearProgress";
import { Image } from "@material-ui/icons";

import AWS from 'aws-sdk';
import Compressor from 'compressorjs';
import Uploader from "../../Uploader";
import Preview from "../../Preview";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { analytics } from "../../..";
import axios from "axios";
import { generatePresignedS3Url } from "../../../config/urls";
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';


const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
    itemfilled: {
      height: 100,
      width: 160,
      borderRadius: 10,
      border: "1px solid #DDDDDD",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
    noImage: {
      width: "100%",
      height: "100%",
    },
    hasImage: {
      height: "100%",
      objectFit: "cover",
      width: "100%",
      objectPosition: "center",
      backgroundRepeat: "no-repeat",
      borderRadius: 10,
    },
    titleImage: {
      height: "100%",
      objectFit: "cover",
      width: "100%",
      objectPosition: "center",
      backgroundRepeat: "no-repeat",
      borderRadius: 10,
      border: "2px solid #0080FF",
    },
    deletebtn: {
      width: 23,
      height: 23,
      position: "absolute",
      top: 12,
      right: 12,
      zIndex: 1000
    },
    coverText: {
      width: 95,
      height: 23,
      backgroundColor: "#0080FF",
      position: "absolute",
      bottom: 0,
      left: 0,
      color: "#fff",
      fontSize: 12,
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0 10px 0 10px",
    },
  })
);



const AddImagesS3 = forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const newPost = useSelector(selectNewPosts);
  const dispatch = useDispatch();
  const [saveImage, setSaveImage] = useState<any[]>([]);

  const imgBtn: any = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);

  const [coverImgSelected, setcoverImgSelected] = useState(false);
  const coverImgindex = useSelector(selectCoverImgIndex);

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  const setNewImageArrayOrder = (newOrder: any) => {

    dispatch(updatePostImages(newOrder))


  };


  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    
    setNewImageArrayOrder(arrayMove(newPost.images, oldIndex, newIndex))
   
    
  };

  const SortableItem = SortableElement(({ i }: { i: number }) => <>

    <div
      key={i}
      className={classes.itemfilled}
      
    >

      <img
        src={
          newPost.images[i]
            ? newPost.images[i]
            : "assets/images/uploadimage.svg"
        }
        className={
          newPost.images[i]
            ? coverImgindex == i
              ? classes.titleImage
              : classes.hasImage
            : classes.noImage
        }
      ></img>
      {newPost.images[i] ? (
        <div>
          {(i == coverImgindex) ? null :
            <div
              className={classes.deletebtn}
              onClick={(e) => {
                e.stopPropagation();
                deleteImgFromSlider(e, newPost.images[i], i)

              }}
            >
              <img className="deleteimg" src="assets/icon/icon-delete-ad-post.svg"></img>
            </div>
          }
        </div>
      ) : null}
      {coverImgindex == i && newPost.images[0] ? (
        <div className={classes.coverText}>
          <CheckIcon
            style={{ width: "16px", height: "14px", marginRight: "5px" }}
          ></CheckIcon>
          <div>
            {/* Title Image */}
            {lan.TITTLE_IMAGE}
          </div>
        </div>
      ) : null}
    </div>
  </>);

  const SortableList = SortableContainer(({ items }: { items: any }) => {

    return (
      <div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap', rowGap: 10, columnGap: 10 }}>
        {items?.map((value: any, index: number) => (
          <SortableItem key={`item-${value}`} index={index} i={index} />
        ))}
      </div>
    );
  });


  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const [imagesPreviewUrls, setPreviewUrls] = useState([] as any);
  const ipu = [] as any;


  // The name of the bucket that you have created
  const BUCKET_NAME = 'saruwata';

  const imageUrls = useSelector(selectImageUrlArray);
  const imageCount = useSelector(selectImagesCount);
  useImperativeHandle(
    ref,
    () => ({
      trigger() {
        imgBtn.current.click();
      },
    }),
    []
  );
  const addimagesToview = (img: any) => {
    const reader: any = new FileReader();
    reader.onloadend = function () {
      dispatch(addImagesToBNewPost(reader.result));
    };
    try {
      reader.readAsDataURL(img);
      props.setInteracted(true);
    } catch (error) {
      return;
    }
    setSaveImage((images: any) => [...images, img]);

   

  };
  var i = 0;
  function setProgress(total: number, up: number) {
    // console.log("Total" + total);
    // console.log("up" + up);
    var oneSlice = 100 / saveImage.length;
    var upx = oneSlice * i + (oneSlice * ((up / total) * 100) / 100);
    // console.log(upx);
    if ((up / total) == 1) {
      i = i + 1;

    }
    setProgr(upx);
  }

  const showImage = (e: any) => {
    e.persist();
    const images = Array.from<any>(e.target.files);
    images.forEach((img) => {

      //20mb
      if (img.size > 20971520) {
        setShowAlert1(true);
      } else {
        if (img.type == "image/jpeg" || img.type == "image/png") {
          addimagesToview(img);
          // console.log("img to view>>" + img.name);
        } else {
          setShowAlert(true);
          e.target.value = null;
        }
      }
    });
    e.target.value = null;
  };
  const openImagePicker = () => {
    document.getElementById("image-picker")?.click();
  };
  const deleteImgFromSlider = (e: any, img: any, index: any) => {
    e.stopPropagation();
    if (index == coverImgindex) {
      setShowAlert3(true);
    } else {
      dispatch(clearImgFromSlider(index));
      setSaveImage(saveImage.filter((img: any, i: number) => i != index));
      // dispatch(setSelectedImages(saveImage.filter((img: any, i: number) => i != index)));
      if (saveImage.length == 1) {
        props.setInteracted(false);
      }
    }
    if (coverImgSelected && index < coverImgindex) {
      dispatch(setCoverImgIndex(coverImgindex - 1));
    }
  };
  const makeCoverImg = (img: any, index: any) => {
    // console.log(index );

    dispatch(setCoverImgName(img.name));
    dispatch(setCoverImgIndex(index));
    setcoverImgSelected(true);

  };




  const setImagesPreviewUrls = (data: any) => {

    ipu.push(data);
    setPreviewUrls(ipu);

  }

  return (
    <div>
      <input
        type="file"
        name="imagePicker"
        multiple
        onChange={showImage}
        accept="image/*"
        id="image-picker"
        onSelect={(e: any) => e.preventDefault()}
        hidden
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "26px",
        }}
      >
        <div style={{ color: "#000000", fontSize: "12px" }}>
          <span style={{ color: "#000000", fontSize: "14px", fontWeight: 600 }}>
            {/* Add upto 10 photos{" "} */}
            {lan.ADD_UPTO_10_PHOTOS}
          </span>
          {/* - You must upload at least one photo* */}
        </div>
        <div style={{ color: "#5A5A5A", fontSize: "12px" }}>
          {/* click on the uploaded image to change the title image */}
          {lan.CLICK_UPLOADED_IMAGE_TO_CHANGE_TITTLE}
        </div>
      </div>



      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignContent="space-between"
      // height={220}
      >

        {newPost.images?.length > 0 ?
          <>
            <SortableList items={newPost.images} onSortEnd={onSortEnd} axis={"xy"}
              shouldCancelStart={(e) => {
                // console.log(e.target.classList.contains('deletebtn'));

                return e.target instanceof HTMLElement && e.target.classList.contains('deleteimg');
              }}
              useDragHandle={false}
            />

          </> : <>

            <div

              className={classes.itemfilled}
              onClick={() => openImagePicker()
              }
            >

              <img
                src={"assets/images/uploadimage.svg"}
                className={
                  newPost.images[i]
                    ? coverImgindex == i
                      ? classes.titleImage
                      : classes.hasImage
                    : classes.noImage
                }
              ></img>

            </div>
          </>}

      </Box>
      <div
      style={{
        marginTop:20
      }}

      >
        {newPost.images?.length > 0 ?
          <Button
            onClick={() => openImagePicker()}
            
            style={{
              height: '40px',
              backgroundColor: '#1bb010',
              borderRadius: '6px',
              color:"white"
          
            }}
          >
            {lan.ADD_MORE_IMAGES}
          </Button>
          : <></>}
      </div>
      {/* <div id="footer-btn-sc">
        <Button
          onClick={checkSelectedImages}
          ref={imgBtn}

          style={{ display: "none" }}
        >
          ඊලග / Next
        </Button>
      </div> */}
      <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
        <DialogTitle id="alert-dialog-title">
          Please select JPG or PNG
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert1} onClose={() => setShowAlert1(false)}>
        <DialogTitle id="alert-dialog-title">
          Maximum image size is 15MB
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setShowAlert1(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert2} onClose={() => { setShowAlert2(false) }}>
        <DialogTitle id="alert-dialog-title">Image count exceeded</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Maximum image count exeeded using first 10 images
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => { setShowAlert2(false); setShowAlert4(true) }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert3} onClose={() => setShowAlert3(false)}>
        <DialogTitle id="alert-dialog-title">
          Change cover image first
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setShowAlert3(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAlert4} onClose={() => setShowAlert4(false)}>
        <DialogTitle id="alert-dialog-title">
          Continue Image Procesing
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => { 
              setShowAlert4(false); 
              // manageImagesAndUpload();
             }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
export default AddImagesS3;