import { IonAlert } from "@ionic/react";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectedCategories,
    setCurrentStep,
} from "../../../features/addPost/addPostSlice";
import {
    addNewPostDesciption,
    postSpecs,
    selectNewPosts,
    setHideFooter,
    setPostSpecs,
} from "../../../features/post/postSlice";
import "./FillDetailsMobile.css";
import { logEvent } from "firebase/analytics"
import { ACTION_INPUT } from "../../../config/actions";
import { INPUT_DETAILS } from "../../../config/events";
import { apartment_rent, apartment_sale, audio_speakers, bikes, cameras, cars, commercial_rent, commercial_sale, commercial_sale_, computers, daily, house_rent, house_sale, land_rent, land_sale, mobiles, mobile_parts, monthly, other_electronics, room_rent, tab, tv, vans } from "../../../constants/categories";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import FillDetailsCarsMobile from "./FillSection/Motors/Cars/FillDetailsCarsMobile";
import FillDetailsBikesMobile from "./FillSection/Motors/Bikes/FillDetailsBikesMobile";
import FillDetailsMobilesMobile from "./FillSection/MobileAndElectronics/Mobiles/FillDetailsMobilesMobile";
import FillDetailsElectronicGeneralMobile from "./FillSection/MobileAndElectronics/ElectronicGenaral/FillDetailsElectronicGeneralMobile";
import FillDetailsApartmentsMobile from "./FillSection/PropertyForSale/ApartmentsAndHouses/FilldetailsApartmentsMobile";
import FillDetailsApartmentAndHousesMobile from "./FillSection/PropertyForSale/ApartmentsAndHouses/FillDetailsApartmentAndHousesMobile";
import FillDetailsCommercialForSaleMobile from "./FillSection/PropertyForSale/CommercialAndLands/FillDetailsCommercialForSaleMobile";
import FillDetailsApartmentRentMobile from "./FillSection/PropertyForRent/FillDetailsApartmentRentMobile";
import FillDetailsLandsForSaleMobile from "./FillSection/PropertyForSale/CommercialAndLands/FillDetailsLandsForSaleMobile";
import FillDetailsLandsForRentMobile from "./FillSection/PropertyForRent/FillDetailsLandForRentMobile";
import FillDetailsApartmentHouseRentMobile from "./FillSection/PropertyForRent/FillDetailsApartmentHouseRent";
import FillDetailsShorttermRentDailyMobile from "./FillSection/PropertyForRent/FillDetailsShorttermRentDailyMobile";
import FillDetailsRoomForRentMobile from "./FillSection/PropertyForRent/FillDetailsRoomForRentMobile";
import FillDetailsCommercialRentMobile from "./FillSection/PropertyForRent/FillDetailsCommercialRentMobile";
import FillDetailsMotorsGeneralMobile from "./FillSection/Motors/MotorsGeneral/FillDetailsMotorsGeneralMobile";
import FillDetailsShorttermRentMobile from "./FillSection/PropertyForRent/FillDetailsShorttermRentMobile";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { analytics } from "../../..";


var subCat = '';
var Mvalues = {};

export function getMandatoryDetails() {
    return [subCat, Mvalues];
}
export function setSubCatMandatory(val: string) {
    subCat = val;
}
export function setValuesMandatory(val: {}) {
    Mvalues = val;
}


const FillDetailsMobile = forwardRef((props: any, ref: any) => {
    const dispatch = useDispatch();
    const selectedCat = useSelector(selectedCategories);
    const newPost = useSelector(selectNewPosts);
    const [showAlert1, setShowAlert1] = useState(false);


    const formRef: any = useRef();
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    const renderSwitch = () => {
        // console.log("--" + selectedCat.level2.name);
        switch (selectedCat.level2.name) {
            case cars:
                return <FillDetailsCarsMobile />;
            case bikes:
                return <FillDetailsBikesMobile />;

            case mobiles:
                return <FillDetailsMobilesMobile />;
            case mobile_parts:
                return <FillDetailsElectronicGeneralMobile />;
            case tab:
                return <FillDetailsElectronicGeneralMobile />;
            case audio_speakers:
                return <FillDetailsElectronicGeneralMobile />;
            case tv:
                return <FillDetailsElectronicGeneralMobile />;
            case computers:
                return <FillDetailsElectronicGeneralMobile />;
            case cameras:
                return <FillDetailsElectronicGeneralMobile />;
            case other_electronics:
                return <FillDetailsElectronicGeneralMobile />;

            case apartment_sale:
                return <FillDetailsApartmentsMobile />;
            case house_sale:
                return <FillDetailsApartmentAndHousesMobile />;
            case commercial_sale_:
                return <FillDetailsCommercialForSaleMobile />;
            case land_sale:
                return <FillDetailsLandsForSaleMobile />;

            case apartment_rent:
                return <FillDetailsApartmentRentMobile />;
            case land_rent:
                return <FillDetailsLandsForRentMobile />;
            case house_rent:
                return <FillDetailsApartmentHouseRentMobile />;
            case daily:
                return <FillDetailsShorttermRentDailyMobile />;
            case monthly:
                return <FillDetailsShorttermRentMobile />;
            case room_rent:
                return <FillDetailsRoomForRentMobile />;
            case commercial_rent:
                return <FillDetailsCommercialRentMobile />;
            default:
                return <FillDetailsMotorsGeneralMobile />;

        }
    };

    useEffect(() => {
        props.setInteracted(true);
    }, []);

    const validate = () => {
        if (newPost.description.length >= 10) {
            props.setInteracted(true);
        } else {
            props.setInteracted(false);
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            trigger() {
                return handleSubmit();
            },
        }),
        []
    );

    const handleSubmit = (): boolean => {
        // const formVal = formRef.current.reportValidity();
        // alert("ds");
        // if (!formVal) {
        //     return false;
        // }
        // else {
        //     const form: any = document.getElementById("form");
        //     const inputs: [] = form.querySelectorAll(
        //         ".MuiTextField-root input[name], .Mui-checked input[name], input[name].MuiSelect-nativeInput"
        //     );
        //     // console.log("..." + inputs);
        //     [...inputs]
        //         .filter((inp: any) => inp.name != "title")
        //         .filter((inp: any) => inp.name != "price")
        //         .filter((inp: any) => inp.name != "district")
        //         .filter((inp: any) => inp.name != "default")
        //         .filter((inp: any) => inp.value || inp.checked)
        //         .filter((inp: any) => inp.value != null)
        //         .filter((inp: any) => inp.value != "No")
        //         .filter((inp: any) => inp.value != "false")
        //         .forEach((input: any) => {
        //             if (
        //                 input.name == "Motors_Mileage" ||
        //                 input.name == "Bike_Engine_Capacity" ||
        //                 input.name == "Property_Floor_Area" ||
        //                 input.name == "Property_Bedroom" ||
        //                 input.name == "Property_Parking_Space_Num" ||
        //                 input.name == "Land_Area" ||
        //                 input.name == "Land_Area_Perch" ||
        //                 input.name == "Property_Bathroom" ||
        //                 input.name == "Property_Perches"
        //             ) {
        //                 if (input.value == "14+") {
        //                     dispatch(
        //                         setPostSpecs({ spec: input.name, value: "14+", comparable: true })
        //                     );
        //                 } else {
        //                     dispatch(
        //                         setPostSpecs({
        //                             spec: input.name,
        //                             value: input.value,
        //                             comparable: true,
        //                         })
        //                     );
        //                 }
        //             } else if (input.name == "Property_Furnished") {
        //                 dispatch(
        //                     setPostSpecs({
        //                         spec: input.name,
        //                         value: "FURNISHED",
        //                         comparable: false,
        //                     })
        //                 );
        //             } else if (input.name == "Property_Parking_Space") {
        //                 dispatch(
        //                     setPostSpecs({
        //                         spec: input.name,
        //                         value: "PARKING AVAILABLE",
        //                         comparable: false,
        //                     })
        //                 );
        //             } else if (input.name == "Property_Private_Bathroom") {
        //                 dispatch(
        //                     setPostSpecs({
        //                         spec: input.name,
        //                         value: "PRIVATE BATHROOM",
        //                         comparable: false,
        //                     })
        //                 );
        //             } else if (input.name == "Property_Private_Entrance") {
        //                 dispatch(
        //                     setPostSpecs({
        //                         spec: input.name,
        //                         value: "PRIVATE ENTRANCE",
        //                         comparable: false,
        //                     })
        //                 );
        //             } else if (input.value == "14+") {
        //                 dispatch(
        //                     setPostSpecs({ spec: input.name, value: "14+", comparable: true })
        //                 );
        //             } else {
        //                 dispatch(
        //                     setPostSpecs({
        //                         spec: input.name,
        //                         value: input.value,
        //                         comparable: false,
        //                     })
        //                 );
        //             }
        //         });

        //     dispatch(
        //         setPostSpecs({
        //             spec: "Cat_Lev_1",
        //             value: selectedCat.level1,
        //             comparable: false,
        //         })
        //     );
        //     dispatch(
        //         setPostSpecs({
        //             spec: "Cat_Lev_2",
        //             value: selectedCat.level2.name,
        //             comparable: false,
        //         })
        //     );
        //     dispatch(setCurrentStep(5));

        //     events.logEvent(INPUT_DETAILS, { action: ACTION_INPUT, platform: 'DESKTOP' });
        //     return true;
        // }


        const form: any = document.getElementById("form");
        const inputs: [] = form.querySelectorAll(
            ".MuiTextField-root input[name], .Mui-checked input[name], input[name].MuiSelect-nativeInput"
        );
        // console.log("..." + inputs);
        [...inputs]
            .filter((inp: any) => inp.name != "title")
            .filter((inp: any) => inp.name != "price")
            .filter((inp: any) => inp.name != "district")
            .filter((inp: any) => inp.name != "default")
            .filter((inp: any) => inp.value || inp.checked)
            .filter((inp: any) => inp.value != null)
            .filter((inp: any) => inp.value != "No")
            .filter((inp: any) => inp.value != "false")
            .forEach((input: any) => {
                if (
                    input.name == "Motors_Mileage" ||
                    input.name == "Bike_Engine_Capacity" ||
                    input.name == "Property_Floor_Area" ||
                    input.name == "Property_Bedroom" ||
                    input.name == "Property_Parking_Space_Num" ||
                    input.name == "Land_Area" ||
                    input.name == "Land_Area_Perch" ||
                    input.name == "Property_Bathroom" ||
                    input.name == "Property_Perches"
                ) {
                    if (input.value == "14+") {
                        dispatch(
                            setPostSpecs({ spec: input.name, value: "14+", comparable: true })
                        );
                    } else {
                        dispatch(
                            setPostSpecs({
                                spec: input.name,
                                value: input.value,
                                comparable: true,
                            })
                        );
                    }
                } else if (input.name == "Property_Furnished") {
                    dispatch(
                        setPostSpecs({
                            spec: input.name,
                            value: "FURNISHED",
                            comparable: false,
                        })
                    );
                } else if (input.name == "Property_Parking_Space") {
                    dispatch(
                        setPostSpecs({
                            spec: input.name,
                            value: "PARKING AVAILABLE",
                            comparable: false,
                        })
                    );
                } else if (input.name == "Property_Private_Bathroom") {
                    dispatch(
                        setPostSpecs({
                            spec: input.name,
                            value: "PRIVATE BATHROOM",
                            comparable: false,
                        })
                    );
                } else if (input.name == "Property_Private_Entrance") {
                    dispatch(
                        setPostSpecs({
                            spec: input.name,
                            value: "PRIVATE ENTRANCE",
                            comparable: false,
                        })
                    );
                } else if (input.value == "14+") {
                    dispatch(
                        setPostSpecs({ spec: input.name, value: "14+", comparable: true })
                    );
                } else {
                    dispatch(
                        setPostSpecs({
                            spec: input.name,
                            value: input.value,
                            comparable: false,
                        })
                    );
                }
            });

        dispatch(
            setPostSpecs({
                spec: "Cat_Lev_1",
                value: selectedCat.level1,
                comparable: false,
            })
        );
        dispatch(
            setPostSpecs({
                spec: "Cat_Lev_2",
                value: selectedCat.level2.name,
                comparable: false,
            })
        );
        dispatch(setCurrentStep(4));

        logEvent(analytics, INPUT_DETAILS, { action: ACTION_INPUT, platform: 'DESKTOP' });
        return true;
    };

    return (
        <>
            <div></div>
            <form onSubmit={(e) => {
                if (handleSubmit()) {
                    e.preventDefault();
                }
            }} id="form">
                <div>{renderSwitch()}</div>
                <button
                    type="submit"
                    style={{ visibility: "hidden" }}
                    id="btn-submit-hidden"
                // onClick={() => { alert(handleSubmit()) }}
                ></button>
            </form>

            <Dialog open={showAlert1} onClose={() => setShowAlert1(false)}>
                <DialogTitle id="alert-dialog-title">Invalid Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>Description is too short</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowAlert1(false)}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
export default FillDetailsMobile;