import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice(
    {
        name: "locations",
        initialState: {
            loc: {},
            districts: [],
            ref: ''
        },
        reducers: {
            setCityToDistricts: (state, action) => {

                let locationMap = new Map();

                action.payload.forEach((element: { name: string; }) => {

                    // var city = (element.name).split('-')[0];
                    var city = (element.name);
                    if ((element.name).split('-')[1] != undefined) {

                        var district = (element.name).split('-')[1].toString().trim();


                        if (locationMap.get(district) === undefined) {

                            let cities = [];
                            cities.push(city);
                            locationMap.set(district, cities);
                        } else {
                            let availableCities = locationMap.get(district) as string[];
                            availableCities.push(city);
                            locationMap.set(district.trim(), availableCities);
                        }
                    }


                });

                // state.districts = Array.from(districtsSet) as [];


                let locationsJson = {} as any;
                locationMap.forEach((value, key) => {

                    locationsJson[key] = value;

                });
                // console.log(locationsJson);
                state.loc = locationsJson;


            },
            setDistricts: (state, action) => {
                state.districts = action.payload;
               
            },
            setRef: (state, action) => {
                state.ref = action.payload;
            }
        }
    }
);

export const {
    setCityToDistricts,
    setDistricts,
    setRef
} = slice.actions;



export const selectDistricts = (state: any) => state.locations.districts;
export const selectLocations = (state: any) => state.locations.loc;
export const selectRef = (state: any) => state.locations.ref;
export default slice.reducer;