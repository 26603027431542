import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
// import "./TitleBar.css";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { selectCurrentLocation, selectIsSideBarOpen, setCurrentBehavior } from "../../features/navigation/navigationSlice";
import {
  BEHAVE_LOGIN_REQUIRED,
  NavigationContext,
  NAV_ACCOUNT,
  NAV_ADD_POST,
  NAV_HOME,
  NAV_LIKES,
  NAV_MYADS,
  NAV_SIDE_BAR,
} from "../../providers/NavigationProvider";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../providers/UserProvider";

import { logEvent } from "firebase/analytics"
import { LOGOUT } from "../../config/events";
import { ACTION_CLICK } from "../../config/actions";
import { selectAuthFreeze } from "../../features/session/sessionSlice";
import AddPostButton from "../AddPost/AddPostButton/AddPostButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { GET_MY_AD_SIDEBAR_SUMMARY } from "../../config/urls";
import { setAmount, setBoostAd, setClientRef, setFeatureAd, setImg, setJumpAd, setPrice, setQuickSell, setSeotag, setTitle } from "../../a_components/features/addOnServices";
import { setJumpAdValues } from "../../a_components/AddOn/AddOnValues";
import MainNavMobile from "../MainNav/MainNavMobile";
import BackArrow from "@material-ui/icons/ArrowBackIos"
import safeAreaInsets from 'safe-area-insets';
import { selectCurrentStep } from "../../features/addPost/addPostSlice";
import { ArrowBack } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   flexGrow: 1,
    // },
    // menuButton: {
    //   marginRight: theme.spacing(2),
    // },
    // title: {
    //   flexGrow: 1,
    // },
    // width: {
    //   [theme.breakpoints.up("lg")]: {
    //     maxWidth: "1260px",
    //     padding: 0,
    //   },
    // },
    // padding: {
    //   padding: 0,
    // },
    // menuitem: {
    //   height: "41px",
    //   transition: "all 0.5s ease",
    //   fontSize: "14px",
    //   color: "#5A5A5A",
    //   "&:hover": {
    //     color: "#0080FF",
    //   },
    // },
    // small: {
    //   width: theme.spacing(3),
    //   height: theme.spacing(3),
    //   marginRight: 10,
    //   border: "1px solid #fff",
    //   boxSizing: "border-box",
    //   background: "#fff",
    // },
    // logo: {
    //   cursor: "pointer",
    // },
    // menuIcon: {
    //   minWidth: 30,
    // },
    // menu: {
    //   "& .MuiList-padding": {
    //     paddingTop: 0,
    //     paddingBottom: 0,
    //   },
    //   "& .MuiPaper-elevation8": {
    //     boxShadow: "0px 0px 6px #0000001A",
    //   },
    // },
  })
);

export default function TitleBarMobile() {

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const authFreeze = useSelector(selectAuthFreeze);
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));
  const step = useSelector(selectCurrentLocation);
  const isSideBarOpen = useSelector(selectIsSideBarOpen);

  const handleLogin = () => {
    dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
  };

  const handleProfileMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuItemClick = (item: string) => {
    if (item === "profile") navigate("/profile");
    else if (item === "likes") navigate("/likes");
    else if (item === "myads") {
      // window.location.href = ("/myads");
      navigate("/myads");

      // dispatch(setSeotag(""));
      // dispatch(setTitle(""));
      dispatch(setPrice(0));
      // dispatch(setImg([]));
      dispatch(setAmount(0));
      dispatch(setJumpAd(0));
      dispatch(setFeatureAd(0));
      dispatch(setBoostAd(0));
      dispatch(setQuickSell(0));
      // dispatch(setClientRef(''));
      dispatch(setJumpAdValues([]));

    };
    setAnchorEl(null);
  };

  const handleNewAd = (user: any) => {
    if (user == null || user.email === "") {
      handleLogin();
      return;
    }

    navigate("/add-post");
  };

  const handleLogoClick = () => {
    // navigate("/start");
    navigate("/home");
  };

  return (
    <AppBar position="sticky" style={{ boxShadow: "none", zIndex: 5000, opacity: (isSideBarOpen ? '0' : '1'), transition: "opacity 0.4s ease" }}>
      <UserContext.Consumer>
        {(user: any) => (
          <Toolbar>
            <Grid container>

              <Grid item xs={12}>

                {(step == NAV_HOME || step == NAV_SIDE_BAR ? null :
                  <IconButton aria-label="delete" onClick={() => {
                    navigate(-1)
                  }} style={{ width: "30px", height: "30px", color: "white" }} >
                    <BackArrow />
                  </IconButton>
                  // <Button  onClick={() => {
                  //   history.goBack();
                  // }} style={{ width: "30px", height: "30px" }} component={BackArrow} />


                )}
                <div onClick={handleLogoClick} style={{ display: "flex", justifyContent: "center" }}>
                  <img src="assets/logo/saruwata-logo.png" id="logo"></img>
                </div>
              </Grid>

            </Grid>
          </Toolbar>
        )}
      </UserContext.Consumer>
    </AppBar >
  );
}
