import { Avatar, Container, CssBaseline, Typography } from "@material-ui/core";
import { url } from "inspector";
import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { NOT_FOUND } from "../../providers/NavigationProvider";

const NotFound = () => {
    const dispatch = useDispatch();
    dispatch(setCurrentLocation(NOT_FOUND));
    return (
        <React.Fragment>
            <CssBaseline />
            <Container style={{ display: 'flex', height: '100vh', padding: '50px', width: '100%', backgroundImage: `url(${"../../assets/error/img-404-error.png"})`, backgroundRepeat: "no-repeat", backgroundPosition: 'center', }} >

            </Container>

        </React.Fragment>
    );


}

export default NotFound;