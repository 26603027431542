import { IonIcon, IonText } from "@ionic/react";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { currencyConverter, isPostResellable, isPostResold } from "../../../../../features/helper";
import { selectLanguage } from "../../../../../features/language/language_slice";
import { Language } from "../../../../../language/Language";
import { SDistricts } from "../../../../../language/sDistricts";
import { translateTime } from "../../../../../language/sTime";
import '../DefaultSerp.css';


const LandRent = (props: any) => {
    const item = props.item;
    var sDiscticts = new SDistricts();
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    const isJumpAd = () => {

        if (item.isJump == null || item.isJump == "0") { return false; }
        return true;
    };

    const isBoostAd = () => {
        if (item.istop == null || item.istop == "0") { return false; }
        return true;
    };
    const isQuickAd = () => {

        if (item.isquick == null || item.isquick == "0") { return false; }
        return true;
    };
    const getSubVariableName = (name: string) => {
        return name.toUpperCase().replace(/ /g, "_").replace('&', 'AND');
    }
    return (

        <div className="devide-iconsection" style={{ position: "relative" }}>
            <section className="detail-section">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: "5px" }}>
                    <div className="type">{item.title}</div>
                    {
                        (isPostResold(item) && !isPostResellable(item, false)) ?
                            <div style={{ width: 20 }}>
                                <img src="assets/icon/icon-re-sell-active.svg"></img>
                            </div>
                            :
                            <></>

                    }
                </div>
                <div className="sub_cat">
                    <span>
                        {/* {(item.sub_category.name.charAt(0).toUpperCase()) + (item.sub_category.name.slice(1).toLowerCase())} */}
                        {lan[getSubVariableName(item.sub_category.name)]}

                    </span>
                    {(item.isMembership !== null ? <img style={{ height: "13px", marginLeft: "10px", paddingTop: "1px" }} src={(getLanguage() == 'english' ? "../../assets/icon/lable-premium-member.svg" : getLanguage() == 'sinhala' ? "../../assets/icon/lable-sn-premium-member-62x10.svg" : "../../assets/icon/lable-tm-premium-member-70x10.svg")} /> : null)}

                </div>
                {/* <div className="title">{item.description}</div> */}
                <div className="details-raw-one">
                    {
                        (item.price != 0) ?
                            <div color="primary" className="price">
                                <span className="ru-in-price">{lan.RS}. </span> {currencyConverter().format(item.price)}
                                <span style={{ fontSize: "14px", fontWeight: 600 }}> / Per Month</span>
                            </div>
                            :
                            <div color="primary" className="ask-price" >
                                {lan.ASK_FOR_PRICE}
                            </div>
                    }
                </div>


            </section>
            <section id="icon-section">
                <div style={{ display: 'flex', flexBasis: 1000 }}>
                    <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 150 }}>
                        <img src="assets/icon/icon-location-pin.svg" id="icon-location"  style={{ marginRight: '5px' }}></img>

                        {
                            (item.specs !== undefined && item.specs.City !== undefined && item.specs.City !== null ? <span id="txt-location">
                                {(getLanguage() == 'sinhala' ? sDiscticts.getSinhalaDistrictName((item.specs.City.split('-')[1]).toUpperCase()) : (getLanguage() == 'tamil' ? sDiscticts.getTamilDistrictName((item.specs.City.split('-')[1]).toUpperCase()) : (item.specs.City.split('-')[1]).trim().charAt(0).toUpperCase() + (item.specs.City.split('-')[1]).trim().slice(1).toLowerCase()))}

                                {/* {(item.specs.City.split('-')[1]).trim().charAt(0).toUpperCase() + (item.specs.City.split('-')[1]).trim().slice(1).toLowerCase()} */}

                            </span> : null)}
                        {/* {
                            item.tags.filter((tag: any) => tag.types.includes("Location")).map((location: any) => (
                                <span id="txt-location" key={location.id}>
                                    {(location.name.split('-')[1]).trim().charAt(0).toUpperCase() + (location.name.split('-')[1]).trim().slice(1).toLowerCase()}
                                </span>

                            ))
                        } */}
                    </div>
                    <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 210 }}>
                        <img src="assets/icon/icon-floor-size.svg" id="icon-year"></img>
                        <span id="txt-milage">
                            {
                                (item.specs.Land_Area == undefined) ? <span>(na)</span> : item.specs.Land_Area + " " + lan.PERCHES
                            }
                        </span>
                    </div>
                    <div style={{ flexGrow: 0, flexShrink: 0 }}>
                        {((isQuickAd() && isJumpAd()) ?
                            <div style={{ display: 'flex' }}>
                                <div id="quickSale">
                                    <div style={{ position: "absolute", top: "0", width: "15%", right: "0" }}>
                                        <img src="assets/addOn/lable_quick_sale.png"></img>
                                    </div>
                                </div>
                                <div id="jumpAd">
                                    <div style={{ width: 20, position: "absolute", right: 10 }}>
                                        <img src="assets/addOn/icon-jump-up.svg"></img>
                                    </div>
                                </div>
                            </div>
                            :
                            ((isJumpAd()) ?
                                <div id="jumpAd">
                                    <div style={{ width: 20, position: "absolute", right: 10 }}>
                                        <img src="assets/addOn/icon-jump-up.svg"></img>
                                    </div>
                                </div>
                                :
                                (isQuickAd() ?
                                    <>
                                        <div id="quickSale">
                                            <div style={{ position: "absolute", top: "0", width: "15%", right: "0" }}>
                                                <img src="assets/addOn/lable_quick_sale.png"></img>
                                            </div>
                                        </div>
                                        <div id="time">
                                            <span className="meta-timestamp">
                                                {(isPostResold(item) && !isPostResellable(item, false)) ? '' : translateTime(moment(item.created_at).fromNow(), getLanguage())}
                                            </span>
                                        </div>
                                    </>
                                    : <div id="time">
                                        <span className="meta-timestamp">
                                            {(isPostResold(item) && !isPostResellable(item, false)) ? '' : translateTime(moment(item.created_at).fromNow(), getLanguage())}
                                        </span>
                                    </div>)))
                        }
                    </div>
                </div>
            </section>
        </div>

    );
}
export default LandRent;