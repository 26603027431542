import { IonAlert } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectCategory, { isStatusOk } from "../../components/AddPost/Category/SelectCategory";
import AddContact from "../../components/AddPost/Contact/AddContact";
import FillDetails, { getMandatoryDetails } from "../../components/AddPost/FillDetails/FillDetails";
import SubCategory from "../../components/AddPost/SubCategory/SubCategory";
import {
  selectCurrentStep,
  selectedCategories,
  setCurrentStep,
  setSelectedCategories,
} from "../../features/addPost/addPostSlice";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { NAV_ADD_POST } from "../../providers/NavigationProvider";
import "./AddPostMobile.css";
import {
  addNewPostRawImages,
  clearAllPosts,
  clearImages,
  clearNewPost,
  getAllAvailablePosts,
  pushPost,
  pushPostWithoutPayment,
  selectNewPosts,
  selectSelectedImages,
  setImgFolder,
} from "../../features/post/postSlice";
import {
  getAllCategories,
  selectCategories,
} from "../../features/tags/tagsSlice";
import { CLICK_NEW_AD, SUBMIT_NEW_AD } from "../../config/events";
import { ACTION_CLICK, ACTION_SUBMIT } from "../../config/actions";
import {
  getVerifiedPhone,
  selectLoggedUser,
} from "../../features/session/sessionSlice";
import { UserContext } from "../../providers/UserProvider";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  makeStyles,
  Paper,
  setRef,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import { setAmount, setClientRef, setImg, setPrice, setSeotag, setTitle } from "../../a_components/features/addOnServices";
import { setBoostAdValues, setBundleValues, setFeaturedAdValues, setJumpAdValues, setQuickSellValues } from "../../a_components/AddOn/AddOnValues";

import LineProgress from "../../components/loader/LineProgress";
import { apartment_rent, apartment_sale, audio_speakers, bikes, cameras, cars, commercial_rent, commercial_sale, computers, daily, house_rent, house_sale, land_rent, land_sale, mobiles, mobile_parts, monthly, other_electronics, room_rent, tab, tv } from "../../constants/categories";
import PaymentOverview from "../../a_components/pages/payment/PaymentOverview";
import { CURRENT_BUILD } from "../../config/build_config";
import LinearProgressBar, { setProgr, getProgr, getFinish, getIsComplete } from "../../components/loader/LinearProgress";
import Slide from '@material-ui/core/Slide';
import SelectCategoryMobile from "../../components/AddPost/Category/SelectCategoryMobile";
import SubCategoryMobile from "../../components/AddPost/SubCategory/SubCategoryMobile";
import FillDetailsMobile from "../../components/AddPost/FillDetails/FillDetailsMobile";
import AddImagesMobile from "../../components/AddPost/Images/AddImagesMobile";
import AddContactMobile from "../../components/AddPost/Contact/AddContactMobile";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { logEvent } from "firebase/analytics";
import { analytics } from "../..";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordian: {
      "& .MuiAccordionDetails-root": {
        flexDirection: "column",
      },
      "& .MuiAccordionSummary-root": {
        borderBottom: "1px solid #DDDDDD",
        marginBottom: "20px",
        "& .MuiAccordion-root.Mui-disabled": {
          backgroundColor: "#DDD",
        },
      },
      boxShadow: "0px 0px 3px #00000026",
    },
    header: {
      color: "#000",
      fontSize: "16px",
      fontWeight: 600,
    },
    buttonDisbale: {
      backgroundColor: "black"
    }
  })
);


// var dispatch: any;
var filldetailsopen = false;
var imagesSelected = false;
var catSelected = false;
var uploading = false;



const AddPostMobile: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stepAddpost = useSelector(selectCurrentStep);
  const navigate = useNavigate();
  const [interacted, setInteracted] = useState(false);
  const [loader, setShowLoader] = useState(false);
  const [showAlertSlose, setShowAlertClose] = useState(false);
  const newPost = useSelector(selectNewPosts);
  const mainBtn: any = useRef();

  const user = useSelector(selectLoggedUser);
  const [hideFooter, setHideFooter] = useState(false);

  // const [changePhone, setChangePhone] = useState(false);
  const [changePhone, setChangePhone] = useState(false);


  const savedCat = useSelector(selectCategories);
  const selectedCat = useSelector(selectedCategories);
  const [subCategories, setSubCategories] = useState([]);
  // const [catSelected, setcatSelected] = useState(false);
  const [confirmImageUplaod, setconfirmImageUplaod] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [phone, setPhone] = useState("");
  const imgBtn: any = useRef();
  const formBtn: any = useRef();
  const [detailsFilled, setdetailsFilled] = useState(false);
  const [finish, setFinish] = useState(false);
  const [showToast, setShowToast] = useState({ message: "", show: false });

  // const [imagesSelected, setimagesSelected] = useState(false);
  // const [filldetailsopen, setfilldetailsopen] = useState(false);

  // const PostNAD = () => {

  //   dispatch(pushPost(newPost, () => { onPostingCompleted("Your Ad is Under Review"); }, () => onPostingCompleted("Error occured"), () => setShowLoader(false)));
  //   events.logEvent(SUBMIT_NEW_AD, { action: ACTION_SUBMIT, platform: "DESKTOP", });
  // }

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;



  const steps = [
    {
      label: lan.SELECT_APPROPRIATE_CATEGORY,

    },
    {
      label: lan.FILL_IN_THE_DETAILS,

    },
    {
      label: lan.CHECK_YOUR_PHOTOS_AND_CONFIRM,

    }, {

      label: lan.CONTACT_DETAILES,
    }
  ];

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(setCurrentLocation(NAV_ADD_POST));
    logEvent(analytics, CLICK_NEW_AD, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
    dispatch(
      getVerifiedPhone((phones: any) => {
        setPhone(phones?.phones[0] != "" ? phones.phones[0] : "");
      })
    );
    return () => {
      clearAndClose();
    };
  }, []);

  useEffect(() => {

    if (newPost.specs.length > 0) {
      // setfilldetailsopen(false);
      filldetailsopen = false;

    }
  }, [newPost.specs]);




  function isSaveCatNotNull() {
    if (savedCat.length > 0) {
      return true;
    }

    return false;
  }

  useEffect(() => {

    setSubCategories(
      savedCat
        .filter((l1: any) => l1.name == selectedCat.level1)
        .flatMap((sub: any) => sub.sub_categories)
    );
  }, [selectedCat.level1]);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleBack = () => {
    if (stepAddpost == 4) {
      dispatch(clearImages([]));
    }
    dispatch(setCurrentStep(stepAddpost - 1));
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleContinueClick = () => {

    if (stepAddpost === 3) {
      handleFilledDetails();
    } else if (stepAddpost === 4) {
      handleImageUploads();
    } else if (stepAddpost === 5) {
      handleClick();
    }

  }


  const renderSwitch = (step: any, ref: any) => {
    switch (step) {
      case 1:
        // { (isSaveCatNotNull() ? console.log("not null") : console.log("null")) }
        return <SelectCategoryMobile isSubNull={(isSaveCatNotNull() ? "Okay" : "Wait")} />;
      case 2:
        // { (isSaveCatNotNull() ? console.log("not null") : console.log("null")) }
        return (isSaveCatNotNull() ? <SubCategoryMobile subCategories={subCategories} /> : <></>);
      case 3:
        return (
          <FillDetailsMobile
            ref={ref}
            setInteracted={setInteracted}
            setHideFooter={setHideFooter}
          />
        );
      case 4:
        return <AddImagesMobile ref={ref} setInteracted={setInteracted} />;
      case 5:
        return (
          <AddContactMobile ref={ref} setInteracted={setInteracted} phone={phone} />
        );

    }
  };

  const handleClick = () => {
    if (user.skip_phone_verify && user.phone == "" && phone == "") {
      setChangePhone(true);
      return;
    }

    if (stepAddpost == 5) {
      // (getProgr() >= 100 ?
      //   dispatch(
      //     pushPost(
      //       newPost,
      //       (jsonData: any) => {

      //         onPostingCompleted("Your Ad is Under Review", jsonData);
      //       }
      //       ,
      //       () => onPostingCompleted("Error occured", {}),
      //       () => setShowLoader(false)
      //     )
      //   ) : st())

      if (newPost.images.length > 0) {

        (getProgr() >= 100 ?
          dispatch(
            pushPost(
              newPost,
              (jsonData: any) => {

                onPostingCompleted("Your Ad is Under Review", jsonData);
              }
              ,
              () => onPostingCompleted("Error occured", {}),
              () => setShowLoader(false)
            )
          ) : st())

      } else {
        alert("Sorry!, please re add your images and try again!");
        dispatch(clearImages([]))
      }

      logEvent(analytics, SUBMIT_NEW_AD, { action: ACTION_SUBMIT, platform: "DESKTOP" });

      return;
    }
    mainBtn.current?.trigger();
  };
  function st() {
    alert("Please remain until the images upload");
    // toast.success("Please remain until the images upload",
    //   {
    //     position: "bottom-center",
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: false,
    //     progress: undefined,
    //   });

  }




  // const handleClick = () => {
  //   if (user.skip_phone_verify && user.phone == "" && phone == "") {
  //     setChangePhone(true);
  //     return;
  //   }

  //   if (stepAddpost == 5) {

  //     dispatch(
  //       pushPostWithoutPayment(
  //         newPost,
  //         () => {
  //           onPostingCompletedWithoutPayment("Your Ad is Under Review");
  //         }
  //         ,
  //         (data: any) => { onPostingCompletedWithoutPayment("Error occured"); console.log(data) },
  //         () => setShowLoader(false)
  //       )
  //     );
  //     events.logEvent(SUBMIT_NEW_AD, {
  //       action: ACTION_SUBMIT,
  //       platform: "DESKTOP",
  //     });

  //     return;
  //   }
  //   mainBtn.current?.trigger();
  // };

  const clearAndClose = () => {
    dispatch(clearNewPost(newPost));
    dispatch(setCurrentStep(1));
    // setimagesSelected(false);
    imagesSelected = false;
    setInteracted(false);
    setShowLoader(false);
    setCatSelected(false);
    dispatch(setSelectedCategories({}));
    setdetailsFilled(false);
    // setfilldetailsopen(false);
    filldetailsopen = false;
    setShowAlertClose(false);
    setProgr(0);

  };

  const onPostingCompleted = (msg: string, jsonData: any) => {
    // history.replace("/home");


    clearAndClose();
    dispatch(setSeotag(jsonData.data.seoTag));
    dispatch(setTitle(jsonData.data.title));
    dispatch(setPrice(jsonData.data.price));
    dispatch(setImg(jsonData.data.img));
    dispatch(setAmount(jsonData.data.amount));
    dispatch(setClientRef(jsonData.data.ref));
    dispatch(setBundleValues(jsonData.data.addons.bundle));

    // console.log(jsonData.data);

    dispatch(setJumpAdValues(jsonData.data.addons.jump));
    dispatch(setBoostAdValues(jsonData.data.addons.boost));
    dispatch(setQuickSellValues(jsonData.data.addons.quik));
    dispatch(setFeaturedAdValues(jsonData.data.addons.feat));

    navigate(`/payment/${jsonData.data.seoTag}`);

    // dispatch(getAllAvailablePosts(() => { }, true, 0, 0));
    // history.go(-1);

    if (msg === 'Error occured') {

      toast.error(msg, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

    } else {

      // toast.success(msg, {
      //   position: "bottom-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   progress: undefined,
      // });
    }

  };
  const onPostingCompletedWithoutPayment = (msg: string) => {


    dispatch(getAllAvailablePosts(() => { }, true, 0, 0));
    clearAndClose();
    navigate("/home");
    // dispatch(clearAllPosts([]));
    // history.go(-1);

    if (msg === 'Error occured') {

      toast.error(msg, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

    } else {

      toast.success(msg, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }

  };

  const handleNoNeedPhoneVerify = () => {
    setChangePhone(false);
  };


  const handleImageUpload = () => {

    setconfirmImageUplaod(false);
    imgBtn.current?.trigger();

  }

  const handleImageUploads = () => {
    var d = document.getElementById("imgBtn");
    d?.click();
  }

  const handleFilledDetails = () => {
    // alert(formBtn.values);
    var f = document.getElementById("btn-submit-hidden");
    f?.click();
    // alert(f?.click());


    // const ret = formBtn.current?.trigger();
    // if (ret) {
    //   dispatch(setCurrentStep(4));
    //   setdetailsFilled(true);
    // }
  };

  function isMandatoryValuesAdded() {
    var c = getMandatoryDetails();
    if (c[0] === cars) {
      var val = c[1] as any;
      if (val.Mileage === '') {
        return false;
      }
    }
  }

  // const [activeStep, setActiveStep] = React.useState(0);
  var activeStep = stepAddpost - 1;



  const handleReset = () => {
    // setActiveStep(0);
    dispatch(setCurrentStep(1));
  };
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));






  return (
    <div>

      <div>
        {/* <Prompt
          when={!!selectedCat.level1}
          message={() => `You will loose all unsaved data, Are you sure ?`}
        /> */}

        <UserContext.Consumer>
          {(user: any) =>
            user != null &&
              user.skip_phone_verify &&
              user.phone == "" &&
              phone == "" ? (
              <div
                style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  zIndex: 9,
                }}
                onClick={() => setChangePhone(true)}
              // onClick={() => setChangePhone(false)}
              ></div>
            ) : null
          }
        </UserContext.Consumer>
        <div style={{ height: "10px" }}></div>
        <div style={{ color: "#000000", fontSize: (getLanguage() == 'english' ? '18px' : '15px'), fontWeight: 600 }}>
          {lan.POST_AD}
        </div>
        <div>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={(activeStep == 0 ? 0 : (activeStep == 1 ? 0 : activeStep - 1))} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                  // optional={
                  //   index === 2 ? (
                  //     <Typography variant="caption">Last step</Typography>
                  //   ) : null
                  // }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent style={{ position: "relative" }}>
                    {/* {stepAddpost} */}
                    <br />
                    {(stepAddpost < 3 ? renderSwitch(1, "") : (stepAddpost === 3 ? renderSwitch(3, formBtn) : stepAddpost === 4 ? renderSwitch(4, imgBtn) : stepAddpost === 5 ? renderSwitch(5, mainBtn) : null))}

                    <br />
                    <Box sx={{ mb: 2 }}>

                      {(stepAddpost === 1 || stepAddpost === 2 ?
                        null
                        : <div style={{ zIndex: 500 }}>

                          {activeStep === steps.length && (

                            <Typography style={{ paddingBottom: "15px", fontSize: (getLanguage() == 'english' ? '18px' : '15px') }}>
                              {/* All steps completed - Continue to Promotions */}
                              {lan.ALL_STEP_COMPLETED}
                            </Typography>

                          )}

                          <Button
                            variant="contained"
                            onClick={() => handleContinueClick()}
                            style={{ marginRight: "15px", backgroundColor: "#0080FF", color: "white", height: "45px", padding: "0 30px", fontWeight: 600 }}
                            disabled={
                              selectedCat.level1 && selectedCat.level2 ? false : true
                            }

                          >
                            {/* {index === steps.length - 1 ? 'Finish' : 'Continue'} */}
                            {lan.CONTINUE}
                          </Button>

                          <Button
                            disabled={stepAddpost === 1}
                            onClick={handleBack}
                            variant="outlined"
                            style={{ height: "45px", padding: "0 30px", fontWeight: 500, color: "#0080FF", borderColor: "#0080FF" }}
                          >
                            {lan.BACK}
                          </Button>


                        </div>)}

                    </Box>

                  </StepContent>

                </Step>
              ))}
            </Stepper>
            {/* {activeStep === steps.length && (
              <Paper square elevation={0}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} >
                  Reset
                </Button>
              </Paper>
            )} */}
          </Box>
        </div>


        <Dialog
          open={showAlertSlose}
          onClose={() => setShowAlertClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to exit from Ad post?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your Ad post will Discard
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.history.pushState(null, "", document.URL);
                setShowAlertClose(false);

              }}
              color="primary"
            >
              cancel
            </Button>
            <Button
              onClick={() => {
                clearAndClose();
                navigate(-1);
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* <IonAlert
          isOpen={showAlertSlose}
          onDidDismiss={() => setShowAlertClose(false)}
          header={"Do you want to exit from Ad post?"}
          message={"Your Ad post will Discard"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
               window.history.pushState(null, "", document.URL);
                setShowAlertClose(false);
              },
            },
            {
              text: "Yes",
              handler: () => {
                clearAndClose();
              },
            },
          ]}
        /> */}
        <Dialog
          open={showAlertSlose}
          onClose={() => setShowAlertClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to exit from Ad post?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your Ad post will Discard
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.history.pushState(null, "", document.URL);
                setShowAlertClose(false);
              }}
              color="primary"
            >
              cancel
            </Button>
            <Button
              onClick={() => {
                clearAndClose();
                navigate(-1);
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <IonAlert
          isOpen={changePhone}
          onDidDismiss={handleNoNeedPhoneVerify}
          header={"Phone number required"}
          message={"Add phone number now ?"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: handleNoNeedPhoneVerify,
            },
            {
              text: "Add Now",
              handler: () => navigate("/profile"),
            },
          ]}
        />
        <Dialog
          open={confirmImageUplaod}
          onClose={() => { setconfirmImageUplaod(false); setUploading(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm image upload"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The images you selected cannot be changed later..!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setconfirmImageUplaod(false);
                setUploading(false);


              }}
              color="secondary"
            >
              close
            </Button>
            <Button onClick={handleImageUpload} color="primary" autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );



};
export function setCatSelected(param: boolean) {
  catSelected = param;

}

export const setfilldetailsopenn = (bool: boolean) => {


  filldetailsopen = bool;
}
export const setimagesSelectedd = (bool: boolean) => {
  // dispatch(setimagesSelected(true));
  imagesSelected = bool;
}


export default AddPostMobile;
