import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectDistricts, selectLocations } from "../../config/locations";
import {
  BRANDS_GET_BY_CATEGORY,
  GET_CITIES,
  GET_DISTRICTS,
  GET_LOCATION,
  GET_MOBILE_MEMORY,
  GET_YEARS,
  MODELS_GET_BY_BRAND,
} from "../../config/urls";
import { setBusy } from "../navigation/navigationSlice";
// import { setBusy } from "../navigation/navigationSlice";

const years = [
  {
    "slug": null,
    "usageCount": null,
    "name": "2021",
    "id": 2904
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2020",
    "id": 2903
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2019",
    "id": 2902
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2018",
    "id": 2901
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2017",
    "id": 2900
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2016",
    "id": 2899
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2015",
    "id": 2898
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2014",
    "id": 2897
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2013",
    "id": 2896
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2012",
    "id": 2895
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2011",
    "id": 2894
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2010",
    "id": 2893
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2009",
    "id": 2892
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2008",
    "id": 2891
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2007",
    "id": 2890
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2006",
    "id": 2889
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2005",
    "id": 2888
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2004",
    "id": 2887
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2003",
    "id": 2886
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2002",
    "id": 2885
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2001",
    "id": 2884
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "2000",
    "id": 2883
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1999",
    "id": 2882
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1998",
    "id": 2881
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1997",
    "id": 2880
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1996",
    "id": 2879
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1995",
    "id": 2878
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1994",
    "id": 2877
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1993",
    "id": 2876
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1992",
    "id": 2875
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1991",
    "id": 2874
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1990",
    "id": 2873
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1989",
    "id": 10343
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1988",
    "id": 10387
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1987",
    "id": 10386
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1986",
    "id": 10385
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1985",
    "id": 10384
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1984",
    "id": 10383
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1983",
    "id": 10382
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1982",
    "id": 10381
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1981",
    "id": 10380
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1980",
    "id": 10379
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1979",
    "id": 10378
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1978",
    "id": 10344
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1977",
    "id": 10377
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1976",
    "id": 10376
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1975",
    "id": 10375
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1974",
    "id": 10374
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1973",
    "id": 10373
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1972",
    "id": 10372
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1971",
    "id": 10371
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1970",
    "id": 10370
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1969",
    "id": 10369
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1968",
    "id": 10368
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1967",
    "id": 10367
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1966",
    "id": 10366
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1965",
    "id": 10365
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1964",
    "id": 10364
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1963",
    "id": 10363
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1962",
    "id": 10362
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1961",
    "id": 10361
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1960",
    "id": 10360
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1959",
    "id": 10359
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1958",
    "id": 10358
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1957",
    "id": 10357
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1956",
    "id": 10356
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1955",
    "id": 10355
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1954",
    "id": 10354
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1953",
    "id": 10353
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1952",
    "id": 10352
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1951",
    "id": 10351
  },
  {
    "slug": null,
    "usageCount": null,
    "name": "1950",
    "id": 10350
  }
];

export const slice = createSlice({
  name: "addPost",
  initialState: {
    currentStep: 1,
    post: {} as any,
    selectedCategories: {
      level1: "",
      level2: "",
      icon: "",
    },
    brands: [],
    models: [],
    location: [],
    district: [],
    city: [],
    years: [],
    mobileMemory: [],
  },

  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setSelectedCategories: (state, action) => {
      const { name, icon } = action.payload;
      state.selectedCategories.level1 = name;
      state.selectedCategories.icon = icon;
    },
    setSelectedSubCategory: (state, action) => {
      state.selectedCategories.level2 = action.payload;
    },
    saveBrands: (state, action) => {
      state.brands = action.payload;
    },
    saveModels: (state, action) => {
      state.models = action.payload;
    },
    saveLocation: (state, action) => {

      state.location = action.payload;
    },
    saveDistricts: (state, action) => {

      state.district = action.payload;
    },
    saveCities: (state, action) => {

      state.city = action.payload;
    },
    saveYears: (state, action) => {
      state.years = action.payload;
    },
    saveMobileMemory: (state, action) => {
      state.mobileMemory = action.payload;
    },
  },
});

export const {
  saveMobileMemory,
  saveYears,
  saveLocation,
  saveModels,
  saveBrands,
  setCurrentStep,
  setSelectedCategories,
  setSelectedSubCategory,
  saveDistricts,
  saveCities,
} = slice.actions;

export const getBrandsByCategory = (category: any) => (dispatch: any) => {
  // dispatch(setBusy(true));
  // console.log(category);
  axios
    .get(BRANDS_GET_BY_CATEGORY + category)
    .then((result) => {
      if (result.status === 200) {
        var json = result.data;
        if (result.data != null || result.data!=undefined || result.data!="") {
         
          // var other = { slug: result.data[0].slug, usageCount: result.data[0].usageCount, name: "OTHER", id: 2847 }
          // json.push(other);
        }

        dispatch(saveBrands(json));
      }
    })
    .finally
    // ()=>dispatch(setBusy(false))
    ();
};

export const getModelsByBrand =
  (brandId: any, category: any) => (dispatch: any) => {
    // dispatch(setBusy(true));
    axios
      .get(MODELS_GET_BY_BRAND + category + "/brands/" + brandId)
      .then((result) => {
        if (result.status === 200) {
          var json = result.data;
          if (result.data != null) {
            var other = { slug: '', usageCount: null, name: "OTHER", id: 0 }
            json.push(other);
          }
          dispatch(saveModels(json));
        }
      })
      .finally
      // ()=>dispatch(setBusy(false))
      ();
  };

export const getYears = () => (dispatch: any) => {
  axios.get(GET_YEARS).then((result) => {
    if (result.status === 200) {
      dispatch(saveYears(result.data));
    }
  }).catch((e)=>{
    dispatch(saveYears(years))
  });

  // dispatch(saveYears(years));

};


export const getLocation = () => (dispatch: any) => {
  // axios.get(GET_LOCATION).then((result) => {
  //   if (result.status === 200) {
  //     if(result.data!==[]){
  //       dispatch(saveLocation(result.data)); 
  //     }else{
  //       console.log("JJJKKK");
  //         getLocation();    
  //    }

  //   }
  // });
  gL(dispatch);
};
async function gL(dispatch: any) {
  await axios.get(GET_LOCATION).then((result) => {
    if (result.status === 200) {
      if (result.data !== []) {
        // console.log(result.data);
        dispatch(saveLocation(result.data));
      } else {

        getLocation();
      }

    }
  });
}

export const getAllCities = () => (dispatch: any) => {
  axios.get(GET_CITIES).then((result) => {
    if (result.status === 200) {
     
      dispatch(saveLocation(result.data));
    }
  });
};



export const getDistricts = () => (dispatch: any, useSelector: any) => {
  // axios.get(GET_DISTRICTS).then((result) => {
  //   if (result.status === 200) {
  //     dispatch(saveDistricts(result.data));
  //   }
  // });


  // gD(dispatch);
  const districts = useSelector().locations.districts;

  dispatch(saveDistricts([{ slug: null, usageCount: null, name: "Loading...", id: 1 }]));


  if (districts.length > 0) {
    console.log("GSD");
    dispatch(saveDistricts(districts));

  } else {

    axios.get(GET_DISTRICTS).then((result) => {

      if (result.status === 200) {
        console.log("RD");
        dispatch(saveDistricts(result.data));
      }
    });

  }



};


export const getCities = (districtID: any, districtName: string) => (dispatch: any, useSelector: any) => {

  const locations = useSelector(selectLocations).locations.loc;
  
  // if (Object.keys(locations).length > 0) {

  //   dispatch(saveCities(locations[districtName]));

  // } else {

    dispatch(saveCities([{ slug: null, usageCount: null, name: "Loading...", id: 1 }]));

    axios.get(GET_DISTRICTS + `/${districtID}/` + "cities").then((result) => {
      if (result.status === 200) {
        // console.log(result.data);
        dispatch(saveCities(result.data));
      }else{
        dispatch(saveCities(locations[districtName]));
      }
    }).catch((e)=>{
      dispatch(saveCities(locations[districtName]));
    })
    ;
  // }


};
export const getMobileMemory = () => (dispatch: any) => {
  axios.get(GET_MOBILE_MEMORY).then((result) => {
    if (result.status === 200) {
      dispatch(saveMobileMemory(result.data));
    }
  });
};

export const selectCurrentStep = (state: any) => state.addPost.currentStep;
export const selectedCategories = (state: any) =>
  state.addPost.selectedCategories;
export const selectBrands = (state: any) => state.addPost.brands;
export const selectModels = (state: any) => state.addPost.models;
export const selectLocation = (state: any) => state.addPost.location;
export const selectYears = (state: any) => state.addPost.years;
export const selectDistrict = (state: any) => state.addPost.district;
export const selectCity = (state: any) => state.addPost.city;
export const selectMobileMemory = (state: any) => state.addPost.mobileMemory;

export default slice.reducer;
