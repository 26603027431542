import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailHousesMobile.css';

const FullDetailHousesRentMobile = (props: any) => {

    const [item, setItem] = useState(props.item);
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-bedrooms.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}> {lan.BED_ROOMS}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {(item.specs.Property_Bedroom == "99") ? "4+" : item.specs.Property_Bedroom}
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-bathrooms.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.BATH_ROOMS}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {
                                (item.specs.Property_Bathroom == "99") ? "4+" : item.specs.Property_Bathroom
                            }
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-floor-size.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}> {lan.FLOOR_AREA}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        <span className="brand-name-english">
                            {

                                item.specs.Property_Floor_Area
                            }
                            &nbsp;SqFt
                        </span>
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>
            <Grid item xs={12}>
                <Grid container className="row-header">
                    <Grid item xs={6} className="col-left">
                        <img src="assets/icon/icon-furnished.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.FURNISHED}</span>
                    </Grid>
                    <Grid item xs={6} className="brand-names">
                        {
                            <span className="brand-name-english">
                                {
                                    (item.tags
                                        .filter((tag: any) => tag.name !== "(NA)")
                                        .filter((tag: any) => tag.types.includes("Property_Furnished")).length > 0) ? "Yes" : "No"
                                }
                            </span>
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
                <Grid container className="row-header">
                    <Grid item xs={9} className="col-left">
                        <img src="assets/icon/icon-car-parking.svg" className="icons-fds"></img>
                        <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '13px') }}> {lan.PARKING_SPACE}</span>
                    </Grid>
                    <Grid item xs={3} className="brand-names">
                        {
                            <span className="brand-name-english">
                                {
                                    (item.tags
                                        .filter((tag: any) => tag.name !== "(NA)")
                                        .filter((tag: any) => tag.types.includes("Property_Parking_Space")).length > 0) ? "Yes" : "No"
                                }
                            </span>
                        }
                    </Grid>
                </Grid>
                <div className="row-devider"></div>
            </Grid>

        </Grid>
    )
}

export default FullDetailHousesRentMobile;