import {
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations } from "../../../../../../config/locations";
import {
  getBrandsByCategory,
  getCities,
  getDistricts,
  selectCity,
  selectDistrict,
  selectedCategories,
  selectLocation,
} from "../../../../../../features/addPost/addPostSlice";
import { currencyConverter } from "../../../../../../features/helper";
import { selectLanguage } from "../../../../../../features/language/language_slice";
import {
  addNewPostDesciption,
  addNewPostDistrict,
  addNewPostPrice,
  addNewPostTitle,

  selectNewPosts,
  setHideFooter,
} from "../../../../../../features/post/postSlice";
import { Language } from "../../../../../../language/Language";
import ListSelectModal from "../../../Modals/ListSelectModal";

const FillDetailsLandsForSaleMobile = () => {
  const dispatch = useDispatch();
  const selctedCat = useSelector(selectedCategories);
  const location = useSelector(selectLocation);
  const newPost = useSelector(selectNewPosts);

  const [fillDetailsDisable, setFillDetailsDisable] = useState(true);

  const [values, setValues] = useState({} as any);

  const [landSize, setLandSize] = useState<any>(0);

  const cities = useSelector(selectCity);
  const district = useSelector(selectDistrict);
  const [sugesstedTitile, setsugesstedTitile] = useState({} as any);
  const locs = useSelector(selectLocations);
  const [priceTotal, setPriceTotal] = useState(false);
  const [pricePerchase, setPricePerchase] = useState(false);
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  useEffect(() => {
    dispatch(getDistricts());
    setFillDetailsDisable(false);
  }, []);

  useEffect(() => {
    const subcat = sugesstedTitile?.subcat ? sugesstedTitile.subcat : '';
    const location = sugesstedTitile?.location ? sugesstedTitile.location : '';

    const title = subcat + ' in ' + location;
    dispatch(addNewPostTitle(title));

  }, [sugesstedTitile]);

  useEffect(() => {
    dispatch(getBrandsByCategory(selctedCat.level2.id));
    setsugesstedTitile((v: any) => {
      return { ...v, subcat: 'Land for sale' };
    });
  }, []);

  const onDistrictSelect = (location: any) => {
    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });
  };
  const onCitySelect = (location: any) => {
    setValues((v: any) => {
      return { ...v, city: location };
    });
    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));

    setsugesstedTitile((v: any) => {
      // return { ...v, location: location.name.split('-')[0].charAt(0).toUpperCase() + location.name.split('-')[0].slice(1).toLowerCase() };
      return { ...v, location: isSavedDistrict() ? location.split('-')[0].charAt(0).toUpperCase() + location.split('-')[0].slice(1).toLowerCase() : location.name.split('-')[0].charAt(0).toUpperCase() + location.name.split('-')[0].slice(1).toLowerCase() };

    });
  };
  const isSavedDistrict = () => {

    if (Object.keys(locs).length > 0) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="district"
            options={district}
            disableClearable
            onChange={(event: any, district: any) => {
              onDistrictSelect(district);

            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={lan.DISTRICT} variant="outlined" required />
            )}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-location-pin.svg" className="filldetails-icon"></img>
          </div>
          <Autocomplete
            id="city"
            options={cities}
            disableClearable
            disabled={!values.district}

            onChange={(event: any, location: any) => {
              onCitySelect(location);
            }}
            getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="district"
                label={lan.CITY}
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={6} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-land-size-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="landarea"
            label={lan.LAND_SIZE_PERCHES}
            placeholder="25"
            name="Land_Area"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={landSize || ""}
            inputProps={{ pattern: '^\\d{1,7}(\\.\\d{1,2})?$' }}
            style={{ width: "100%" }}
            onChange={(e) => setLandSize(e.target.value)}
            required
          />          
        </Grid> */}

        {(priceTotal ?

          <Grid item xs={12} className="filldetails-grid-item">
            <div className="filldetails-icon-wrapper">
              <img src="assets/addpost/icon-land-size-ad-post.svg" className="filldetails-icon"></img>
            </div>
            <TextField
              id="landarea"
              label={lan.LAND_SIZE_PERCHES}
              placeholder="25"
              name="Land_Area"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={landSize || ""}
              inputProps={{ pattern: '^\\d{1,7}(\\.\\d{1,2})?$' }}
              style={{ width: "100%" }}
              onChange={(e) => setLandSize(e.target.value)}
              required
            />
          </Grid>

          :

          <Grid item xs={12} className="filldetails-grid-item">
            <div className="filldetails-icon-wrapper">
              <img src="assets/addpost/icon-land-size-ad-post.svg" className="filldetails-icon"></img>
            </div>
            <TextField
              id="landarea"
              label={lan.LAND_SIZE_PERCHES}
              placeholder="25"
              name="Land_Area_Perch"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={landSize || ""}
              inputProps={{ pattern: '^\\d{1,7}(\\.\\d{1,2})?$' }}
              style={{ width: "100%" }}
              onChange={(e) => setLandSize(e.target.value)}
              required
            />
          </Grid>

        )}

        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-fullwidth-icon-wrapper">
            <img src="assets/addpost/icon-title-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="title"
            label={lan.TITTLE}
            placeholder={lan.MAX_72_CHARACTERS}
            name="title"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            required
            inputProps={{ maxLength: 72 }}
            variant="outlined"
            value={newPost.title || ""}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostTitle(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostTitle(e.target.value))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            // label="Description"
            label={lan.DESCRIPTION}
            multiline
            placeholder={lan.MAX_4000_CHARACTERS}
            name="description"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={newPost.description || ""}
            inputProps={{ maxLength: 4000 }}
            style={{ width: "100%" }}
            onChange={(e: any) =>
              dispatch(addNewPostDesciption(e.target.value))
            }
            onInput={(e: any) => dispatch(addNewPostDesciption(e.target.value))}
          />
        </Grid>
        <Grid item xs={10}>

          <FormControl component="fieldset" style={{ marginLeft: "15px" }}>
            <FormLabel component="legend" style={{ fontWeight: "bold", fontSize: "14px" }}>{lan.UNIT}</FormLabel>
            <RadioGroup row aria-label="position" name="position" defaultValue="perchase">

              <FormControlLabel onChange={() => { setPriceTotal(true) }} value="total" control={<Radio color="primary" required />} label={lan.TOTAL} />
              <FormControlLabel onChange={() => { setPriceTotal(false) }} value="perches" control={<Radio color="primary" required />} label={lan.PER_PERC} />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} className="filldetails-grid-item">
          <div className="filldetails-icon-wrapper">
            <img src="assets/addpost/icon-price-ad-post.svg" className="filldetails-icon"></img>
          </div>
          <TextField
            id="price"
            label={lan.PRICE}
            placeholder="2500000"
            name="price"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            required
            variant="outlined"
            value={newPost.price || ""}
            inputProps={{ pattern: "^\\d{1,12}(\\.\\d{1,2})?$", maxLength: 15 }}
            style={{ width: "100%" }}
            onChange={(e: any) => dispatch(addNewPostPrice(e.target.value))}
            onInput={(e: any) => dispatch(addNewPostPrice(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} className="filldetails-grid-item">
          {
            (newPost.price == 0) ?
              <span className="price" >{lan.ASK_FOR_PRICE}</span>
              :
              <div color="primary" className="price">
                <span className="ru-in-price">{lan.RS}. </span>{" "}
                {newPost?.price === "" || !isNaN(newPost?.price)
                  ? currencyConverter().format(newPost?.price)
                  : "Invalid"}
                {(priceTotal ?
                  <span style={{ fontSize: "14px", fontWeight: 600 }}> {lan.PER_TOTAL}</span>
                  :
                  <span style={{ fontSize: "14px", fontWeight: 600 }}>{lan.PER_PERCH}</span>)

                }
              </div>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default FillDetailsLandsForSaleMobile;
