import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Fab, LinearProgress, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

var progress = 0;
var isComplete = false;
var finish = false;
var error = false;
const useStyles = makeStyles({
    root: {
        width: '100%',
        position: "relative"
    },
});
export function imageUploadError() {
    error = true;
}
export function setProgr(prog: number) {

    progress = prog;
    // console.log("pro>>" + prog);

}
export function setIsComplete(isc: boolean) {

    isComplete = isc;

}
export function getProgr() {
    return progress;
}

export function getIsComplete() {
    return isComplete;
}

export const setFinish = (val: boolean) => {
    finish = val;

}
export const getFinish = () => {
    return finish;
}
export default function LinearProgressBar() {
    const classes = useStyles();

    const isError = () => {
        if (error) {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.root}>

            {/* <LinearProgress style={{ color: (progress == 100 ? "green" : "blue") }} variant="determinate" value={progress} /> */}
            <Box display="flex" alignItems="center" >
                <Box width="100%" mr={1}>

                    {

                        (progress >= 100 ?
                            (isError() ? <ErrorIcon style={{ marginLeft: "5px", marginTop: "5px", color: "red", width: "25px", height: "25px" }} />
                                : <CheckIcon style={{ marginLeft: "5px", marginTop: "5px", color: "green", width: "25px", height: "25px" }} />
                            )
                            // < CheckIcon style={{ marginLeft: "145px", marginTop: "5px", color: "green", width: "25px", height: "25px" }} />

                    :
                    <>
                        <CircularProgress variant="determinate" value={progress} style={{ width: "20px", height: "20px", marginLeft: "10px", marginTop: "5px" }} />
                        {(progress > 0 ? <span style={{ fontSize: "10px", position: "absolute", top: 5, left: 35 }}>Uploading..</span> : null)}
                    </>
                    )

                    }

                    {/* <LinearProgress variant="determinate" value={progress} style={{ color: (progress == 100 ? "green" : "blue") }} /> */}
                </Box>

            </Box>
        </div>
    );
}