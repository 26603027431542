import {
  Button,
  Container,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { logEvent } from "firebase/analytics"
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddPostButton from "../../components/AddPost/AddPostButton/AddPostButton";
import Boxes from "../../components/Boxes/Boxes";
import Footer from "../../components/Footer/Footer";
import KeywordSearch from "../../components/KeywordSearch/KeywordSearch";
import SelectCategoryLanding from "../../components/Landing/Categories/SelectCategoryLanding";
import TitleBar from "../../components/TitleBar/TitleBar";
import { ACTION_INPUT, ACTION_VIEW } from "../../config/actions";
import { KEYWORD_SEARCH_ALL, VIEW_LANDING_PAGE } from "../../config/events";
import {
  setCurrentBehavior,
  setCurrentLocation,
} from "../../features/navigation/navigationSlice";

import { selectAuthFreeze } from "../../features/session/sessionSlice";
import {
  BEHAVE_LOGIN_REQUIRED,
  NAV_LANDING,
} from "../../providers/NavigationProvider";

import MainNav from "../../components/MainNav/MainNav";
import "./LandingPage.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MainNavMobile from "../../components/MainNav/MainNavMobile";
import TitleBarMobile from "../../components/TitleBar/TitleBarMobile";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import FooterNew from "../../components/Footer/FooterNew";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";
import { url } from "inspector";

// import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: "1260px",
        padding: 0,
      },
    },
    btnAllads: {
      border: "2px solid #fff",
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: 600,
    },
  })
);

const LandingPage: React.FC = () => {
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  // var l = require('../../language/' + getLanguage());
  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authFreeze = useSelector(selectAuthFreeze);

  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    dispatch(setCurrentLocation(NAV_LANDING));
    logEvent(analytics, VIEW_LANDING_PAGE, {
      action: ACTION_VIEW,
      platform: "DESKTOP",
    });
  }, []);

  const handleKeywordSelect = (val: string) => {
    if (val) navigate(`/search?phrase=${val}`);
    logEvent(analytics, KEYWORD_SEARCH_ALL, {
      action: ACTION_INPUT,
      data: val,
      platform: "DESKTOP",
    });
  };
  const handleNewAd = (user: any) => {
    if (user == null || user.email === "") {
      handleLogin();
      return;
    }

    navigate("/add-post");
  };

  const handleLogin = () => {
    dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
  };
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      {(isTabletOrMobile ? window.location.href = ('/home') : null)}
      <Hidden xsDown={true} smDown={true}>
        <TitleBar></TitleBar>
      </Hidden>
      <Hidden lgUp={true} mdUp={true}>
        <TitleBarMobile></TitleBarMobile>
      </Hidden>
      <Hidden xsDown={true} smDown={true}>
        <MainNav></MainNav>
      </Hidden>

      <Boxes />
      <Hidden lgUp={true} mdUp={true}>
        <div
          style={{
            maxHeight: "100vh",
            overflowY: "scroll",
            background: "#ffffff",
          }}
        >

        </div>
      </Hidden>
      <Hidden xsDown={true} smDown={true}>
        <div
          style={{
            maxHeight: "100vh",
            overflowY: "scroll",
            background: "#ffffff",
          }}
        >
          <div id="landing-hero-back" >

          
            <div
              style={{
                display: "flex",
                alignItems: `${smallViewPort ? "flex-end" : "stretch"}`,
                marginRight: 30,
               
              }}
              className={`${!smallViewPort && "hero-image-left-wrapper"}`}
            >
              <img
                src="assets/images/narmada.png"
                className={`${!smallViewPort && "hero-image"}`}
                alt=""
              ></img>
            </div>
            <div id="hero-text-wrap" 
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  marginBottom: "25px",
                }}
              >
                <div
                  style={{ fontSize: (getLanguage() !== 'english' ? '23px' : '28px'), fontWeight: 600, color: "#FFFFFF" }}
                >
                  {/* Welcome to saruwata.lk */}
                  {lan.WELCOME_TO_SARUWATA}
                </div>
                <div
                  style={{ fontSize: (getLanguage() !== 'english' ? '16px' : '22px'), fontWeight: 500, color: "#FFFFFF" }}
                >
                  {/* The new marketplace for modern Sri Lankans. */}
                  {lan.THE_NEW_MARKETPLACE_FOR_MODERN_SRILANKANS}
                </div>
                {/* <div
                style={{
                  fontSize: "34px",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  fontFamily: "Abhaya Libre",
                  letterSpacing: "2px",
                }}
              >
                ජීවිතේ
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    fontFamily: "Titillium Web !important",
                  }}
                >
                  {" "}
                  UPGRADE{" "}
                </span>
                කරන්න!
              </div> */}
              </div>
              <div
                style={{
                  border: "5px solid #0080FF",
                  borderRadius: "8px",
                  width: "605px",
                  height: "67px",
                  marginBottom: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  paddingRight: 4,
                }}
              >
                <KeywordSearch
                  onSearchEnter={handleKeywordSelect}
                ></KeywordSearch>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: (getLanguage() !== 'english' ? '13px' : '15px'),
                    color: "#FFFFFF",
                    width: "444px",
                    textAlign: "left",
                  }}
                >
                  {/* Find the best deals on Vehicles, Property, Electronics and more.
                  Choose from a wide range of high quality ADs from trusted
                  sellers. */}
                  {lan.FIND_THE_BEST}
                </div>
                <Button
                  className={classes.btnAllads}
                  variant="outlined"
                  onClick={() => navigate("/home")}
                  style={{ color: "#fff", backgroundColor: "#0080FF", fontSize: (getLanguage() !== 'english' ? '13px' : '15px') }}
                >
                  {/* View All Ads */}
                  {lan.VIEW_ALL_ADS}
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: `${smallViewPort ? "flex-end" : "stretch"}`,
                marginLeft: 50,
              }}
              className={`${!smallViewPort && "hero-image-right-wrapper"}`}
            >
              <img
                src="assets/images/anetheki.png"
                className={`${!smallViewPort && "hero-image"}`}
                alt=""
                style={{
                  right: 0,
                }}
              ></img>
            </div>
          </div>

          <Container maxWidth="lg" className={classes.root}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginTop: "71px",
                marginBottom: "42px",
              }}
            >
              <div
                style={{ fontSize: "26px", color: "#000000", fontWeight: 700 }}
              >
                {/* Categories */}
                {lan.CATEGORIES}
              </div>
              <div style={{ fontSize: "18px", color: "#000000" }}>
                {/* Browse through some of our most popular categories */}
                {lan.BROWSE_THROUGH}
              </div>
            </div>

            <SelectCategoryLanding></SelectCategoryLanding>

            {/* <section className="deals-section">
              <div style={{ height: 80 }}>

                <Button
                  id="deals-btn"
                  onClick={() => navigate("/search?tags=deals")}
                >
                  {" "}
                  View All Deals
                </Button>
                <img
                  src="assets/images/deals-header.webp"
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                ></img>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: 3,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    border: "1px solid #DDDDDD",
                    width: 418,
                    marginRight: 2,
                  }}
                >
                  <img
                    src="assets/images/deal-001.webp"
                    style={{ width: "100%" }}
                    alt=""
                    onClick={() =>
                      navigate(
                        "/mobiles-and-electronics/laptop-desktop-tablets/1620890204108"
                      )
                    }
                  ></img>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <div style={{ display: "flex", flexGrow: 1 }}>
                    <div
                      style={{
                        border: "1px solid #DDDDDD",
                        flexGrow: 1,
                        marginRight: 2,
                      }}
                    >
                      <img
                        src="assets/images/deal-002.webp"
                        style={{ width: 419 }}
                        alt=""
                        onClick={() =>
                          navigate(
                            "/mobiles-and-electronics/cameras-camcorders/1620888308955"
                          )
                        }
                      ></img>
                    </div>
                    <div style={{ border: "1px solid #DDDDDD", flexGrow: 1 }}>
                      <img
                        src="assets/images/deal-003.webp"
                        style={{ width: 419 }}
                        alt=""
                        onClick={() =>
                          navigate(
                            "/mobiles-and-electronics/electronic-home-appliances/1620890906000"
                          )
                        }
                      ></img>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexGrow: 1, marginTop: 2 }}>
                    <div
                      style={{
                        border: "1px solid #DDDDDD",
                        flexGrow: 1,
                        marginRight: 2,
                      }}
                    >
                      <img
                        src="assets/images/deal-004.webp"
                        style={{ width: 419 }}
                        alt=""
                        onClick={() =>
                          navigate(
                            "/mobiles-and-electronics/electronic-home-appliances/1620888693921"
                          )
                        }
                      ></img>
                    </div>
                    <div style={{ border: "1px solid #DDDDDD", flexGrow: 1 }}>
                      <img
                        src="assets/images/deal-005.webp"
                        style={{ width: 419 }}
                        alt=""
                        onClick={() =>
                          navigate(
                            "/mobiles-and-electronics/electronic-home-appliances/1620895593280"
                          )
                        }
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="deals-list-section">
              <div
                style={{
                  height: 80,
                  borderBottom: "2px solid #0FADFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    color: "#333333",
                    fontSize: 22,
                    fontWeight: 600,
                    marginLeft: 23,
                  }}
                >
                  Best Trending Deals
                </span>
                <Button
                  onClick={() => navigate("/search?tags=deals")}
                  style={{
                    marginRight: 25,
                    width: 103,
                    height: 27,
                    border: "1px solid #0080FF",
                  }}
                >
                  <span
                    style={{ color: "#0080FF", fontSize: 14, fontWeight: 600 }}
                  >
                    View More
                  </span>
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexGrow: 1, cursor: "pointer" }}>
                  <img
                    src="assets/images/0001-trending-dealdeal-250x268.webp"
                    alt=""
                    onClick={() =>
                      navigate("/classifieds/home-garden/1620892778154")
                    }
                  ></img>
                </div>
                <div style={{ display: "flex", flexGrow: 1, cursor: "pointer" }}>
                  <img
                    src="assets/images/0002-trending-dealdeal-250x268.webp"
                    alt=""
                    onClick={() =>
                      navigate(
                        "/mobiles-and-electronics/mobile-accessories/1620893206150"
                      )
                    }
                  ></img>
                </div>
                <div style={{ display: "flex", flexGrow: 1, cursor: "pointer" }}>
                  <img
                    src="assets/images/0003-trending-dealdeal-250x268.webp"
                    alt=""
                    onClick={() =>
                      navigate("/classifieds/home-garden/1620893712275")
                    }
                  ></img>
                </div>
                <div style={{ display: "flex", flexGrow: 1, cursor: "pointer" }}>
                  <img
                    src="assets/images/0004-trending-dealdeal-250x268.webp"
                    alt=""
                    onClick={() =>
                      navigate(
                        "/mobiles-and-electronics/mobile-accessories/1620894382520"
                      )
                    }
                  ></img>
                </div>
                <div style={{ display: "flex", flexGrow: 1, cursor: "pointer" }}>
                  <img
                    src="assets/images/0005-trending-deal-250x268.webp"
                    alt=""
                    onClick={() =>
                      navigate("/classifieds/home-garden/1620894727281")
                    }
                  ></img>
                </div>
              </div>
            </section> */}

            <br />
            <br />
            <br />
            <br />
            <section className="postad-details-section">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "55%",
                }}
              >
                <div
                  style={{
                    fontSize: (getLanguage() !== 'english' ? '20px' : '32px'),
                    fontWeight: 600,
                    color: "#000000",
                    width: 317,
                    height: "auto",
                    marginBottom: 10,
                    lineHeight: "40px",
                  }}
                >
                  {/* Post your Ads */}
                  {lan.POST_YOUR_AD}
                  <br />
                  {/* just in few clicks. */}
                  {lan.JUST_IN_FEW}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 39,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src="assets/icon/icon-teck-123.svg"
                      alt=""
                      style={{ width: 18, height: 18 }}
                    ></img>
                    <span
                      style={{ color: "#5A5A5A", fontSize: (getLanguage() !== 'english' ? '14px' : '18px'), marginLeft: 15 }}
                    >
                      {/* Add up to 10 photos on each Ad */}
                      {lan.ADD_UPTO_10}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src="assets/icon/icon-teck-123.svg"
                      alt=""
                      style={{ width: 18, height: 18 }}
                    ></img>
                    <span
                      style={{ color: "#5A5A5A", fontSize: (getLanguage() !== 'english' ? '14px' : '18px'), marginLeft: 15 }}
                    >
                      {/* Choose from a wide range of categories */}
                      {lan.CHOOSE_FROM_A_WIDE}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src="assets/icon/icon-teck-123.svg"
                      alt=""
                      style={{ width: 18, height: 18 }}
                    ></img>
                    <span
                      style={{ color: "#5A5A5A", fontSize: (getLanguage() !== 'english' ? '14px' : '18px'), marginLeft: 15 }}
                    >
                      {/* Fill in available details only */}
                      {lan.FILL_IN_AVAILABLE}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src="assets/icon/icon-teck-123.svg"
                      alt=""
                      style={{ width: 18, height: 18 }}
                    ></img>
                    <span
                      style={{ color: "#5A5A5A", fontSize: (getLanguage() !== 'english' ? '14px' : '18px'), marginLeft: 15 }}
                    >
                      {/* Publish ads automatically without any delays */}
                      {lan.PUBLISH_ADS_AUTOMATICALLY}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src="assets/icon/icon-teck-123.svg"
                      alt=""
                      style={{ width: 18, height: 18 }}
                    ></img>
                    <span
                      style={{ color: "#5A5A5A", fontSize: (getLanguage() !== 'english' ? '14px' : '18px'), marginLeft: 15 }}
                    >
                      {/* Use different contact numbers on ads */}
                      {lan.USE_DIFFERENT_CONTACT}
                    </span>
                  </div>
                </div>
                <AddPostButton
                  handleNewAd={handleNewAd}
                  authFreeze={authFreeze}
                ></AddPostButton>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: `${smallViewPort ? "flex-start" : "center"}`,
                  marginLeft: 50,
                }}
              >
                <img src="assets/images/image-mobile-desktop.webp" alt=""></img>
              </div>
            </section>
          </Container>
          <section className="app-details-section">
            <div
              style={{
                display: "flex",
                borderBottom: "7px solid #0080FF",
                bottom: 0,
                maxWidth: "1260px",
              }}
            >
              <img
                src="assets/images/footer-app-promo-image-380x280.png"
                alt=""
                style={{
                  position: "absolute",
                  bottom: 7,
                  height: 280,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              ></img>
              <div style={{ width: 410 }}></div>
              <div
                style={{ display: "flex", flexDirection: "column", height: 203 }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ width: 600 }}>
                    <div
                      style={{
                        fontSize: (getLanguage() !== 'english' ? '19px' : '24px'),
                        fontWeight: 700,
                        color: "#000000",
                        width: 488,
                        marginBottom: 10,
                        marginTop: 35,
                        lineHeight: "28px",
                      }}
                    >
                      <span style={{ display: "block" }}>
                        {/* Buy and Sell on Saruwata App */}
                        {lan.BUY_AND_SELL_ON}
                      </span>
                      <span style={{ display: "block" }}>
                        {/* It’s time to UPGRADE your life! */}
                        {lan.ITS_TIME_TO}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: (getLanguage() !== 'english' ? '13px' : '16px'),
                        color: "#000000",
                        lineHeight: "20px",
                      }}
                    >
                      <span style={{ display: "block" }}>
                        {/* Take a careful look around! Car that you used, used phone,
                        a guitar that you never play, fancy chair or jewellery,
                        books and fashion items - Are you ready to post your ads */}
                        {lan.TAKE_CAREFUL_LOOK}
                      </span>
                      {(getLanguage() !== 'english' ? null : <span style={{ display: "block" }}>
                        on{" "}
                        <span style={{ fontWeight: 600 }}>saruwata.lk</span>
                      </span>)}

                    </div>
                  </div>
                  <div
                    style={{
                      borderRight: "2px solid #DDD",
                      height: 150,
                      marginLeft: 50,
                      marginRight: 40,
                      marginTop: 25,
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 30,
                    }}
                  >
                    <span
                      style={{
                        color: "#000000",
                        fontSize: (getLanguage() !== 'english' ? '13px' : '16px'),
                        fontWeight: 700,
                        marginTop: 24,
                      }}
                    >
                      {/* GET YOUR APP TODAY */}
                      {lan.GET_YOUR_APP_TODAY}
                    </span>
                    <a
                      href="https://apps.apple.com/lk/app/saruwata-lk/id1565804524"
                      target="_blank"
                    >
                      <img
                        src="assets/icon/btn-appstore-app-landing.svg"
                        alt=""
                        style={{
                          marginRight: 20,
                          width: 139,
                          height: 44,
                          marginTop: 15,
                          marginBottom: 5,
                          cursor: "pointer",
                        }}
                      ></img>
                    </a>
                    <a
                      href=" https://play.google.com/store/apps/details?id=lk.hiruads.aphrodite"
                      target="_blank"
                    >
                      <img
                        src="assets/icon/btn-google-app-landing.svg"
                        alt=""
                        style={{ width: 139, height: 44, cursor: "pointer" }}
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterNew></FooterNew>
        </div>
      </Hidden>
    </div>
  );
};

export default LandingPage;
