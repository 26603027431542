import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/language/language_slice";
import { Language } from "../../../../language/Language";
import './FullDetailCommercialsMobile.css';

const FullDetailCommercialsMobile = (props: any) => {
    const selectedLanguage = useSelector(selectLanguage);
    const getLanguage = (): string => {
        if (selectedLanguage == 'en') {
            return 'english';
        } else if (selectedLanguage == 'si') {
            return 'sinhala';
        } else if (selectedLanguage == 'ta') {
            return 'tamil';
        } else {
            return 'english';
        }
    }

    var l = new Language(getLanguage());
    var lan = l.getLan as any;
    return (
        <Grid container>
            <Grid container xs={12} className="row-header">
                <Grid item xs={6} className="col-left">
                    <img src="assets/icon/icon-floor-size.svg" className="icons-fds"></img>
                    <span className="brand-name-sinhala" style={{ fontSize: (getLanguage() == 'english' ? '16px' : '14px') }}>{lan.FLOOR_AREA}</span>
                </Grid>
                <Grid item xs={6} className="brand-names">
                    <span className="brand-name-english">{props.item.specs.Property_Floor_Area}  sqft</span>
                </Grid>
                <div className="row-devider"></div>
            </Grid>

            <Grid container>

            </Grid>

        </Grid>
    )
}

export default FullDetailCommercialsMobile;