import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_INPUT } from "../../../config/actions";
import { INPUT_CONTACT } from "../../../config/events";
import { land_rent } from "../../../constants/categories";
import { selectLanguage } from "../../../features/language/language_slice";
import {
  addNewPostDesciption,
  addNewPostDistrict,
  addNewPostMobile,
  addNewPostPrice,
  addNewPostTitle,
  addNewPostWhatsapp,
  selectNewPosts,
} from "../../../features/post/postSlice";
import { selectLoggedUser } from "../../../features/session/sessionSlice";
import { Language } from "../../../language/Language";
import "./AddContact.css";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../..";

const AddContact = forwardRef((props: any, ref: any) => {
  const [checked, setChecked] = useState(true);
  const newPost = useSelector(selectNewPosts);
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedUser);


  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  useEffect(() => {
    if (!newPost.title) {
      dispatch(addNewPostTitle("No Title"));
    }
    if (!newPost.district) {
      dispatch(addNewPostDistrict("(NA)"));
    }
    if (!newPost.price) {
      dispatch(addNewPostPrice(0));
    }
    if (!newPost.description) {
      dispatch(addNewPostDesciption("No Description Given"));
    }
  }, []);

  useEffect(() => {
    if (newPost.mobile) {
      props.setInteracted(true);
    }
  }, []);

  useEffect(() => {
    if (props.phone) {
      dispatch(addNewPostMobile(props.phone));
      logEvent(analytics, INPUT_CONTACT, { action: ACTION_INPUT, platform: 'DESKTOP' });
      props.setInteracted(true);
    }
  }, [props.phone]);

  useEffect(() => {
    if (!checked) dispatch(addNewPostWhatsapp(""));
    else dispatch(addNewPostWhatsapp(props.phone));
  }, [checked]);

  useImperativeHandle(
    ref,
    () => ({
      trigger() {
        handleSubmit();
      },
    }),
    []
  );

  const handleSubmit = () => { };

  return (
    <div>
      <Grid container spacing={4}>

        <Grid item xs={6} className="filldetails-grid-item">
          {user?.skip_phone_verify ? (
            <>
              <div className="filldetails-icon-wrapper">
                <img src="assets/addpost/icon-phone-no-ad-post.svg" className="filldetails-iconmbwt"></img>
              </div>
              <TextField
                label={lan.MOBILE_NUMBER}
                placeholder="+947xxxxxxxx"
                variant="outlined"
                type="text"
                onChange={(e: any) => {
                  if (e.target.value.length >= 12) {
                    dispatch(addNewPostMobile(e.target.value));
                    // props.setInteracted(true);
                  } else {
                    props.setInteracted(false);
                  }
                }
                }
                name="mobile"
                required={true}
                style={{ width: "100%" }}
              ></TextField>


            </>
          ) :
            <>
              <div className="filldetails-icon-wrapper">
                <img src="assets/addpost/icon-phone-no-ad-post.svg" className="filldetails-icon-mbwt"></img>
              </div>
              {/* <TextField
                placeholder="07xxxxxxx"
                required={true}
                value={props.phone}
                type="number"
                name="mobile"
                disabled
                inputMode="tel"
                variant="outlined"
                label="Mobile Number"
                style={{ width: "100%" }}
              >

              </TextField> */}



              <TextField
                placeholder="+947xxxxxxxx"
                required={true}
                type="text"
                name="mobile"
                value={newPost.mobile}
                inputProps={{ maxLength: 13, minLength: 10 }}
                onChange={(e: any) => {
                  const re = /^[0-9+\b]+$/;
                  // const re = /^(?:0|94|\\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|5|6|7|8)\\d)\\d{6}$/;

                  if (!(e.target.value === '' || re.test(e.target.value))) {
                    e.target.value = e.target.value.slice(0, -1);;
                  }

                  dispatch(addNewPostMobile(e.target.value));
                  if (checked) {
                    dispatch(addNewPostWhatsapp(e.target.value));
                  }

                  if (e.target.value.length >= 12) {
                    props.setInteracted(true);
                  }
                  else if (e.target.value.length == 10) {
                    if (e.target.value.slice(0, 1) == '0') {
                      props.setInteracted(true);
                    }
                  }
                  else {

                    props.setInteracted(false);

                  }
                }
                  // onChange={(e: any) => {
                  //   dispatch(addNewPostMobile(e.target.value));
                  // }
                }

                inputMode="tel"
                variant="outlined"
                label={lan.MOBILE_NUMBER}

                style={{ width: "100%" }}
              >

              </TextField>
            </>
          }
        </Grid>
        <Grid item xs={6} className="filldetails-grid-item">
          <>
            <div className={(checked) ? "filldetails-icon-wrapper" : "filldetails-icon-wrapper-whatsapp"}>
              <img src="assets/addpost/icon-whatsapp-ad-post.svg" className="filldetails-icon-mbwt"></img>
            </div>
            <TextField
              placeholder="+947xxxxxxxx (Optional)"
              required={false}
              inputProps={{ pattern: '+94\\d{9}', maxLength: 13 }}
              value={checked ? newPost.mobile : newPost.whatsapp}
              type="text"
              onChange={(e: any) => {
                dispatch(addNewPostWhatsapp(e.target.value));
              }}
              name="whatsapp"
              disabled={checked}
              label={lan.WHATSAPP_NUMBER}
              variant="outlined"
              style={{ width: "100%" }}

            >
            </TextField>
          </>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={(e: any) => setChecked(e.target.checked)}
                color="primary"
              />
            }
            label={lan.USE_SAME_NUMBER_FOR_WHATSAPP}
          />

        </Grid>

      </Grid>
    </div >
  );
});
export default AddContact;
