import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BankdepositTabs from "../../../components/Tabs/BankDepositTabPanel";

import { setCurrentLocation } from "../../../features/navigation/navigationSlice";
import { NAV_BANK_DEPOSIT } from "../../../providers/NavigationProvider";


const useStyles = makeStyles({
    heading: {
        color: "#000000",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "10PX"

    }

});
const BankDeposit = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
        dispatch(setCurrentLocation(NAV_BANK_DEPOSIT));
    });
    return (<>
        <Grid container style={{ maxWidth: "1260px", padding: (isTabletOrMobile ? "20px 5%" : "20px 0"), marginLeft: "auto", marginRight: "auto" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.heading}>Direct bank deposit</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <BankdepositTabs />
            </Grid>
        </Grid>
    </>)
}
export default BankDeposit;
