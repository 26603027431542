import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import {
  forgotPassword,
  signInWithEmailPassword,
} from "../../features/session/firebaseSlice";
import { BEHAVE_LOGIN_REQUIRED, BEHAVE_SIGNUP_REQUIRED } from "../../providers/NavigationProvider";
import { login, loginNew } from "../../features/session/sessionSlice";
import { getAllAvailablePosts } from "../../features/post/postSlice";
import "./LoginRequired.css";
import { GoogleLoginButton } from 'ts-react-google-login-component';


import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import SlideUpTransition from "../../animations/SlideUpTransition";
import { Email, EmailOutlined, Facebook, Height, Visibility, VisibilityOff, VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { LOGIN } from "../../config/events";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Plugins } from '@capacitor/core';
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics, auth } from "../..";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      "& h2": {
        fontWeight: 700,
      },
      padding: '39px 24px 27px 24px'

    },
    tagline: {
      textAlign: "center",
      marginBottom: 30,
    },
    contentroot: {
      position: "relative",
      top: -25,
    },
    googlebtn: {
      width: "100% !important",
      backgroundColor: '#fff',
      height: 47,
      padding: '0 15px',
      textTransform: "capitalize",
      fontSize: 16,
      '& img': {
        width: 20
      },
      '& .MuiButton-label': {
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }
    },
    fbbtn: {
      backgroundColor: "#4267B2",
      width: 165,
      height: 47,
      padding: '0 15px',
      fontSize: 16,
      "& span": {
        textTransform: "capitalize",
        color: "#fff",
      },
      '& .MuiButton-label': {
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }
    },
    sepwrapper: {
      margin: '10px 15px 10px 15px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    secondoruiconwrapper: {
      margin: '0 15px 0 15px',
      '& button': {
        borderRadius: '10%',
        '& span': {
          width: 80
        }
      }
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#5A5A5A',
      fontSize: 14,
      marginTop: 16
    },
    closebtn: {
      right: 0,
      position: 'absolute',
      top: 0
    },
    emailPasswordtxt: {
      marginBottom: '29px',
      width: '362px',
      height: '50px',
    },
    tf: {
      width: "100% !important",
      // padding: '0 15px',
    }
  })
);

const LoginRequired = (props: any) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  const [loginEmail, setloginEmail] = useState('');
  const [loginPassword, setloginPassword] = useState('');
  const navigate = useNavigate();
  const [errorText, seterrorText] = useState('');
  const [sucessText, setsucessText] = useState('');
  const [pwVisibility, setpwVisibility] = useState(false);

  const formRef: any = useRef();

  useEffect(() => {
    setShowModal(props.navigation.behavior === BEHAVE_LOGIN_REQUIRED);
  }, [props.navigation.behavior]);

  //signIn
  // Plugins.GoogleAuth.signIn();


  async function googleSignIn(this: any) {

    let googleUser = await Plugins.GoogleAuth.signIn();
    const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
    signInWithCredential(auth, credential).then((data: { user: any; }) => {
      // console.log(credential)
      // console.log("gt : " +googleUser.authentication.idToken)
      dispatch(
        login(data.user, googleUser.authentication.idToken, () =>
          dispatch(getAllAvailablePosts(() => { }, true, 0))
        )
      );
    });

    // console.log(credential);

  }


  const loginWithMail = () => {
    const formVal = formRef.current.reportValidity();
    if (!formVal) {
      return false;
    }
    else {
      dispatch(signInWithEmailPassword(loginEmail.trim(), loginPassword, (user: any, token: string) => {
        dispatch(login({ ...user, displayName: user.email }, token, () => {
          window.location.reload();
        }));

        handleDismiss();
      }, (msg: any) => {
        seterrorText(msg);
      }));

    }
    logEvent(analytics, LOGIN, { method: "onsite", from: "LoginPage", platform: 'DESKTOP' });
  }

  const loginWithGoogle = () => {

    googleSignIn();
    // then((data: any) => {
    //   // console.log(data);

    // }
    // ).catch(e => console.log(e));

    // dispatch(
    //   signInWithGoogle(
    //     (user: any, token: string) => {
    //       dispatch(
    //         login(user, token, () =>
    //           dispatch(getAllAvailablePosts(() => { }, true, 0))
    //         )
    //       );
    //     },
    //     (error: any) => { }
    //   )
    // );
    logEvent(analytics, LOGIN, { method: "Google", from: "LoginPage", platform: 'DESKTOP' });
    setShowModal(false);
  };


  const preLoginTracking = () => {
    console.log('Attemp to login with google');
  }

  const errorHandler = (error: string) => {
    // handle error if login got failed...
    console.error(error)
  }



  const handleDismiss = () => {
    setShowModal(false);
    dispatch(setCurrentBehavior(""));
    setloginPassword('');
    setloginEmail('');
    seterrorText('');
    setsucessText('');
  };

  const handleSignUp = () => {
    setShowModal(false);
    dispatch(setCurrentBehavior(BEHAVE_SIGNUP_REQUIRED));
  }

  const handleForgotPassword = () => {
    if (loginEmail == '') {
      seterrorText('Enter Valid Email');
      return;
    }
    dispatch(
      forgotPassword(
        loginEmail,
        (msg: any) => { setsucessText(msg); },
        (msg: any) => { seterrorText(msg) }
      )
    );
    return;
  }
  const responseGoogle = (response: any) => {
    // alert(JSON.stringify(response.getAuthResponse().id_token));
    // alert(JSON.stringify(response.getBasicProfile().getName()));
    // alert(JSON.stringify(response.getBasicProfile().getEmail()));
    // alert(JSON.stringify(response.getBasicProfile().getImageUrl()));


    dispatch(
      loginNew(response.getBasicProfile().getName(), response.getBasicProfile().getImageUrl(), response.getBasicProfile().getEmail(), response.getAuthResponse().id_token, () =>
        dispatch(getAllAvailablePosts(() => { }, true, 0))
      )
    );

    setShowModal(false);

  }
  const clientConfig = { client_id: '357302555757-s5h4d7ksm76le950a7h924n4mf5mjjkm.apps.googleusercontent.com' }
  return (
    <Dialog open={showModal} style={{ overflow: 'hidden' }}>
      <DialogTitle className={classes.title}>
        Sign In
        <IconButton className={classes.closebtn} onClick={handleDismiss}><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentroot}>
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between' }}>
          <div className="login-btn-wrapper">
            <Button
              variant="contained"
              onClick={() => loginWithGoogle()}
              className={classes.googlebtn}
            >
              <img src="assets/icon/google-icon.png"></img>
              <span>Google</span>
              <span></span>
            </Button>
          </div>
          {/* <div className="login-btn-wrapper">
            <Button
              variant="contained"
              onClick={() => loginWithFacebook()}
              className={classes.googlebtn}
            >
              <img src="assets/icon/facebook-icon.png"></img>
              <span>Facebook</span>
              <span></span>
            </Button>
          </div> */}

          {/* <div className="login-btn-wrapper">
            <GoogleLogin
              clientId="357302555757-s5h4d7ksm76le950a7h924n4mf5mjjkm.apps.googleusercontent.com"
              className={classes.googlebtn}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}

              // isSignedIn={true}
              cookiePolicy={'single_host_origin'}
            >

              <span> Login with Google</span>
            </GoogleLogin>

          </div> */}
          {/* <div className="login-btn-wrapper">
            <Button
              onClick={() => loginWithFacebook()}
              variant="contained"
              className={classes.fbbtn}
            >
              <Facebook htmlColor="white" />
              <span>Facebook</span>
              <span></span>
            </Button>
          </div>
          <div className="login-btn-wrapper">
            <GoogleLogin
              clientId="357302555757-s5h4d7ksm76le950a7h924n4mf5mjjkm.apps.googleusercontent.com"
              className={classes.googlebtn}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}

              // isSignedIn={true}
              cookiePolicy={'single_host_origin'}
            >

              <span> Login with Google</span>
            </GoogleLogin>

          </div> */}
        </div>
        <div className={classes.sepwrapper}>
          <div style={{ height: '1px', width: '100%', backgroundColor: '#DDDDDD' }}></div>
          <div style={{ margin: '0 15px' }}>or</div>
          <div style={{ height: '1px', width: '100%', backgroundColor: '#DDDDDD' }}></div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>

          <span style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px", marginTop: "10px", width: "100%", marginBottom: "20px" }}>Continue With Email</span>

        </div>
        <form ref={formRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            // className={classes.emailPasswordtxt}

            required
            onChange={(e: any) => setloginEmail(e.target.value)
            } value={loginEmail}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailOutlined></EmailOutlined>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type={(pwVisibility) ? "text" : "password"}
            // className={classes.emailPasswordtxt}
            style={{ marginTop: "10px", marginBottom: "15px" }}
            required
            onChange={(e: any) => setloginPassword(e.target.value)}
            value={loginPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => setpwVisibility(!pwVisibility)} style={{ cursor: 'pointer' }}>
                  {
                    (pwVisibility) ?
                      <VisibilityOutlined></VisibilityOutlined>
                      :
                      <VisibilityOffOutlined></VisibilityOffOutlined>
                  }

                </InputAdornment>
              ),
            }}
          />
          <Button style={{ height: '47px', width: '100%', backgroundColor: '#0080FF', color: '#fff', maxWidth: '362px', textTransform: 'capitalize', fontSize: 18 }} onClick={loginWithMail}> Log in</Button>
        </form>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'green', fontSize: '14px', marginTop: '20px' }}>
          {sucessText}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', fontSize: '14px', marginBottom: '20px' }}>
          {errorText}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #DDDDDD', paddingBottom: '20px' }}>
          <div>
            <Button color="primary" disableFocusRipple disableRipple disableTouchRipple style={{ textTransform: 'capitalize', fontSize: 14 }} onClick={handleForgotPassword}> Forgot Password?</Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
            <span>No Account?</span>
            <Button color="primary" disableFocusRipple disableRipple disableTouchRipple style={{ textTransform: 'capitalize' }} onClick={handleSignUp}>Sign Up</Button>
          </div>
        </div>
        <div className={classes.footer}>
          <div>By continuing you agree to the Saruwata.lk</div>
          <span style={{ cursor: 'pointer' }}><Link onClick={() => { navigate('/terms-conditions'); handleDismiss(); }} >Terms & Conditions</Link>, <Link onClick={() => { navigate('/privacy-policy'); handleDismiss(); }}>Privacy Policy</Link></span>
        </div>
      </DialogContent >
    </Dialog >
  );
};

export default LoginRequired;
