import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Theme,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { logEvent } from "firebase/analytics"
import { options } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CLICK, ACTION_INPUT } from "../../config/actions";
import { ADD_FILTER, CLICK_ON_SIDEBAR_BANNER } from "../../config/events";
import {
  apartment_rent,
  apartment_sale,
  audio_speakers,
  bikes,
  cameras,
  cars,
  commercial_rent,
  commercial_sale,
  computers,
  daily,
  house_rent,
  house_sale,
  land_sale,
  mobiles,
  mobile_parts,
  monthly,
  other_electronics,
  room_rent,
  tab,
  tv,
} from "../../constants/categories";
import {
  getCities,
  getDistricts,
  getLocation,
  getYears,
  selectCity,
  selectDistrict,
  selectLocation,
  selectYears,
} from "../../features/addPost/addPostSlice";
import {
  clearFilters,
  saveBathroom,
  saveBedroom,
  saveEngineCapacity,
  saveFloorArea,
  saveLandArea,
  saveMileage,
  saveParking,
  savePrice,
  savePropertyPerches,
  selectBathroom,
  selectBedroom,
  selectEngineCapacity,
  selectFloorArea,
  selectLandArea,
  selectMileage,
  selectParking,
  selectPrice,
  selectPropertyPerches,
} from "../../features/filter/filterSlice";
import { addBathRooms, addBedRooms, addBrand, addCategory, addengineCapacity, addFloorAreaMax, addFloorAreaMin, addFurnished, addLocation, addMaxLandSize, addMaxPrice, addMinLandSize, addMinPrice, addMotorMileageMax, addMobileCondition, addModal, addMotorCondition, addMotorMileageMin, addMotorTransmission, addParkingSpace, addSubCategory, addYear } from "../../features/search/FilterSlice";
import {
  getBrandsByCategory,
  getModelsByBrand,
  saveBrands,
  saveModels,
  selectBrands,
  selectedCategories,
  selectModels,
  selectSearcedPosts,
  setFilters,
} from "../../features/search/searchSlice";
import {
  getAllCategories,
  selectCategories,
} from "../../features/tags/tagsSlice";
import "./FiltersModal.css";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";
import { addNewPostDistrict } from "../../features/post/postSlice";




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgcolor: {
      backgroundColor: "#F5F6F8",
    },
    color: {
      "& .MuiFilledInput-root": {
        background: "#F5F6F8",
      },
    },
    select: {
      "&.MuiInputBase-root": {
        backgroundColor: "#F5F6F8",
      },
    },
    selectcontrol: {
      "&.MuiFormControl-root": {
        background: "#F5F6F8",
      },
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    radiolabel: {
      fontSize: 14,
    },
    commonlabel: {
      fontSize: 14,
    },
    radiogroup: {
      paddingBottom: 15,
      borderBottom: "1px solid #ddd",
    },
    switchgroup: {
      padding: "7px 0",
      borderBottom: "1px solid #ddd",
    },
    sale: {
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff",
      },
      marginRight: "20px",
      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#FF5454",
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FF5454",
        opacity: 1,
      },
    },
  })
);

const FiltersModal = () => {

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;


  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showListModal, setShowListModal] = useState({
    status: false,
    placeholder: "",
    list: [],
    ref: "",
  });
  const categories = useSelector(selectCategories);
  const selectedCat = useSelector(selectedCategories);
  const location = useSelector(selectLocation);


  const cities = useSelector(selectCity);
  const district = useSelector(selectDistrict);



  const results: any[] = useSelector(selectSearcedPosts);
  const [year, setYear] = useState();
  const years = useSelector(selectYears);
  const [selectedCondition, setSelectedCondition] = useState();
  const brands = useSelector(selectBrands);
  const models = useSelector(selectModels);
  const [selectedTransmission, setSelectedTransmission] = useState();
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [fillDetailsDisable, setFillDetailsDisable] = useState(true);
  const [values, setValues] = useState({} as any);
  const [filteredValues, setFilteredValues] = useState([] as any);
  const [typedValues, setTypedValues] = useState({} as any);
  const [subID, setSubID] = useState();
  const scrollContent = document.getElementById("scrollable-root");
  const [deal, setdeal] = useState(false);
  const [c1, setC1] = useState("");

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    setC1(
      url
        .get("c1")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || ""
    );
  }, [window.location.search]);

  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };


  const isSavedDistrict = () => {

    // if (Object.keys(location).length > 0) {

    //   return true;
    // }

    return false;
  }

  const onDistrictSelect = (location: any) => {

    dispatch(getCities(location.id, location.name));
    setValues((v: any) => {
      return { ...v, district: location };
    });
  };

  const onCitySelect = (location: any) => {
    setValues((v: any) => {
      return { ...v, city: location };
    });
    // dispatch(addNewPostDistrict(location.name));

    (isSavedDistrict() ? dispatch(addNewPostDistrict(location)) : dispatch(addNewPostDistrict(location.name)));
    //new concept with save data scenario

  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  const conditionMotors: any = [
    { id: 0, name: "Brand New", label: lan.BRAND_NEW },
    { id: 1, name: "Registered", label: lan.REGISTERED },
    { id: 2, name: "Unregistered", label: lan.UNREGISTERED },
  ];
  const conditionMobiles: any = [
    { id: 0, name: "New", label: lan.NEW },
    { id: 1, name: "Used", label: lan.USED },
  ];
  const transmission: any = [
    { id: 0, name: "Automatic", label: lan.AUTOMATIC },
    { id: 1, name: "Manual", label: lan.MANUAL },
    { id: 2, name: "Tiptronic", label: lan.TIPTONIC },
  ];
  const numbers: any = [
    { id: 0, name: "0" },
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
    { id: 13, name: "13" },
    { id: 14, name: "14" },
    { id: 99, name: "14+" },
  ];

  const price = useSelector(selectPrice);
  const mileage = useSelector(selectMileage);
  const engineCapacity = useSelector(selectEngineCapacity);
  const landArea = useSelector(selectLandArea);
  const floorArea = useSelector(selectFloorArea);
  const bedroom = useSelector(selectBedroom);
  const bathroom = useSelector(selectBathroom);
  const parkingNum = useSelector(selectParking);
  const propertyPerches = useSelector(selectPropertyPerches);
  const [filerClicked, setFilerClicked] = useState({
    state: false,
    event: null,
  });
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [sale, setsale] = useState("");

  // console.log(">>>>>" + new URLSearchParams(window.location.search));
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const c1 = url
      .get("c1")
      ?.replace(/[^\w\s]/g, "")
      .replace(/\s\s+/g, " ")
      .trim()
      .toUpperCase();
    const c2 = url
      .get("c2")
      ?.replace(/[^\w\(\)\s^\&]/g, "")
      .replace(/\s\s+/g, " ")
      .trim()
      .toUpperCase();
    if (c1 != mainCategory || c2 != subCategory) restoreOnRefresh(c1, c2);
    setMainCategory(
      categories.filter((main: any) => main.name == c1)[0]?.name || ""
    );
    setSubCategory(
      categories
        .filter((main: any) => main.name == c1)[0]
        ?.sub_categories.filter((sub: any) => sub.name == c2)[0]?.name || ""
    );
  }, [categories, window.location.search]);

  const restoreOnRefresh = (c1: any, c2: any) => {
    if (c1) onMainCategorySelect(c1);
    if (c2) onSubCategorySelect(c2);
  };

  useEffect(() => {
    if (categories.length <= 0) dispatch(getAllCategories());
    if (location.length <= 0) dispatch(getLocation());
    if (years.length <= 0) dispatch(getYears());
  }, []);

  useEffect(() => {
    if (filerClicked.state) {
      saveData();
    }
  }, [filteredValues]);

  useEffect(() => {
    if (results.length <= 0) clearFields();
    categories
      .filter((main: any) => main.name == mainCategory)[0]
      ?.sub_categories.filter((sub: any) => sub.name == subCategory)
      .forEach((sub: any) => {
        if (sub.name === cars || sub.name === bikes || sub.name === mobiles)
          dispatch(getBrandsByCategory(sub.id));
        setSubID(sub.id);
      });
  }, [subCategory, categories]);

  useEffect(() => {
    if (checked) {
      setValues((v: any) => {
        return { ...v, furnished: "FURNISHED" };
      });
    } else {
      setValues((v: any) => {
        return { ...v, furnished: "" };
      });
    }
    if (checked1) {
      setValues((v: any) => {
        return { ...v, parking: "PARKING AVAILABLE" };
      });
    } else {
      setValues((v: any) => {
        return { ...v, parking: "" };
      });
    }
    if (checked2) {
      setValues((v: any) => {
        return { ...v, privateEntrance: "PRIVATE ENTRANCE" };
      });
    } else {
      setValues((v: any) => {
        return { ...v, privateEntrance: "" };
      });
    }
    if (checked3) {
      setValues((v: any) => {
        return { ...v, privateBathroom: "PRIVATE BATHROOM" };
      });
    } else {
      setValues((v: any) => {
        return { ...v, privateBathroom: "" };
      });
    }
  }, [checked, checked1, checked2, checked3]);

  useEffect(() => {
    if (deal) {
      setsale("deals");
    } else {
      setsale("");
    }
  }, [deal]);
  useEffect(() => {
    dispatch(getDistricts());
   
  }, []);

  const clearFields = () => {
    setValues((v: any) => {
      return {};
    });
    dispatch(saveBrands([]));
    dispatch(saveModels([]));
    setChecked(false);
    setChecked1(false);
    setChecked2(false);
    setChecked3(false);
    setSelectedCondition(undefined);
    setTypedValues((v: any) => {
      return {};
    });
    dispatch(clearFilters({}));
    setFillDetailsDisable(true);
  };

  const onLocationSelect = (location: any) => {
    dispatch(addLocation(location));
    setValues((v: any) => {
      return { ...v, location: location };
    });
  };

  const onMainCategorySelect = (e: any) => {
    if (e == null || e == undefined) return;
    // setSubCategory("");
    setMainCategory(e);
    dispatch(addCategory(e));
    if (e != "") {
      setSubCategories(
        categories.filter((main: any) => main.name == e)[0]?.sub_categories
      );
      setSubCategory("");
    }
  };

  const onSubCategorySelect = (e: any) => {
    if (e == null || e == undefined) return;
    setSubCategory(e);
    if (results.length <= 0) clearFields();
  };

  const onBrandSelect = (brand: any) => {
    if (!brand) return;
    setFillDetailsDisable(false);
    setValues((v: any) => {
      return { ...v, brand: brand?.name !== "Any" ? brand : "" };
    });
    dispatch(saveModels([]));
    if (brand.name !== "Any") dispatch(getModelsByBrand(brand.id, subID));
    setValues((v: any) => {
      return { ...v, model: "" };
    });
  };

  const onModelSelect = (model: any) => {
    setValues((v: any) => {

      return { ...v, model: model?.name !== "Any" ? model : "" };
    });
    setShowListModal({ status: false, list: [], placeholder: "", ref: "" });
  };
  const onYearsSelect = (years: any) => {
    setValues((v: any) => {
      return { ...v, years: years?.name || "" };
    });
  };
  const onConditionSelect = (condition: any) => {
    setSelectedCondition(condition);
    setValues((v: any) => {
      return { ...v, condition: condition === "Any" ? "" : condition };
    });
  };
  const onTransmissionSelect = (transmission: any) => {
    setSelectedTransmission(transmission);
    setValues((v: any) => {
      return { ...v, transmission: transmission === "Any" ? "" : transmission };
    });
  };

  const loadBrandModel = (subcat: any) => {
    if (subcat == cars || subcat == bikes || subcat == mobiles) {
      return (
        <>
          <Grid item xs={12} >
            <Autocomplete
              className={classes.color}
              id="brands"
              options={[{ name: "Any" }, ...brands]}
              value={values?.brand || { name: "Any" }}
              onChange={(event: any, brand: any, options: any) => {
                if (brand !== null) {
                  dispatch(addBrand(brand.name));
                }
                onBrandSelect(brand);
              }}
              getOptionSelected={(o: any, a: any) => o.name === a.name}
              getOptionLabel={(option: any) => option.name}
              style={{ width: "auto" }}
              renderInput={(params) => (
                <TextField {...params} label={lan.BRAND_NAME} variant="filled" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={classes.color}
              id="models"
              options={[{ name: "Any" }, ...models]}
              value={values?.model || { name: "Any" }}
              disabled={values.brand ? false : true}
              onChange={(event: any, model: any) => {
                if (model !== null) {
                  dispatch(addModal(model.name));
                }
                onModelSelect(model);
              }}
              getOptionSelected={(o: any, a: any) => o.name === a.name}
              getOptionLabel={(option: any) => option.name}
              style={{ width: "auto" }}
              renderInput={(params) => (
                <TextField {...params} label={lan.MODEL} variant="filled" />
              )}
            />
          </Grid>
        </>
      );
    } else {
      return;
    }
  };

  const loadYear = (subcat: any) => {
    if (subcat == cars || subcat == bikes) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              className={classes.color}
              id="year"
              options={years}
              value={year}
              onChange={(event: any, year: any) => {
                if (year !== null) {
                  dispatch(addYear(year.name));
                }
                onYearsSelect(year);
              }}
              getOptionLabel={(option: any) => option.name}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params}
                  // label={lan.MODEL_YEAR} 
                  label={lan.MODEL_YEAR}
                  variant="filled" />
              )}
            />
          </Grid>
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadMilage = (subcat: any) => {
    if (subcat == cars || subcat == bikes) {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="filled-number"
              // label="Mileage Min"
              label={lan.MILEAGE_MIN}

              type="number"
              variant="filled"
              value={mileage.min || ""}
              onChange={(e: any) => {
                dispatch(addMotorMileageMin(e.target.value));
                dispatch(
                  saveMileage({ ...mileage, min: e.target.value as any })
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="filled-number"
              // label="Mileage Max"
              label={lan.MILEAGE_MAX}
              type="number"
              variant="filled"
              value={mileage.max || ""}
              onChange={(e: any) => {
                dispatch(addMotorMileageMax(e.target.value));
                dispatch(
                  saveMileage({ ...mileage, max: e.target.value as any })
                )
              }}
            />
          </Grid>
        </>
      );
    }
  };
  const getSubVariableName = (name: string) => {
    return name.toUpperCase().replace(/ /g, "_").replace('&', 'AND');
  }

  const loadSubCategories = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "#F5F6F8",
          paddingLeft: "10px",
          paddingTop: "10px",
        }}
        className={classes.radiogroup}
      >
        <FormControl
          component="fieldset"
          style={{ marginTop: 5, marginLeft: 10 }}
        >
          <FormLabel component="legend">
            {/* Sub-Categories */}
            {lan.SUB_CATEGORY}
          </FormLabel>
          <RadioGroup
            name="subcategories"
            style={{ marginTop: 10 }}
            value={subCategory}
            onChange={(e: any) => {
              dispatch(addSubCategory(e.target.value));
              onSubCategorySelect(e.target.value)
            }}
          >
            <FormControlLabel
              value={""}
              key={0}
              control={<Radio color="primary" size="small" />}
              label={<span className={classes.radiolabel}>

                {/* All */}
                {lan.ALL}
              </span>}
            />
            {mainCategory != ""
              ? subCategories?.map((item: any) => (
                <FormControlLabel
                  value={item.name}
                  key={item.id}
                  control={<Radio size="small" color="primary" />}
                  label={
                    <span className={classes.radiolabel}>
                      {/* {item.name.charAt(0).toUpperCase() +
                        item.name.slice(1).toLowerCase()} */}

                      {/* item.name.toUpperCase().replace(/ /g, "_").replace('&','AND') */}
                      {lan[getSubVariableName(item.name)]}
                    </span>
                  }
                />
              ))
              : null}

          </RadioGroup>
        </FormControl>
      </Grid>
    );
  };

  const loadCondition = (subcat: any) => {
    if (subcat == cars || subcat == bikes) {
      return (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#F5F6F8",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
          className={classes.radiogroup}
        >
          <FormControl
            component="fieldset"
            style={{ marginTop: 5, marginLeft: 10 }}
          >
            <FormLabel component="legend">
              {/* {lan.CONDITION} */}
              {lan.CONDITION}
            </FormLabel>
            <RadioGroup
              aria-label={lan.CONDITION}
              name="Motor_Condition"
              style={{ marginTop: 10 }}
              value={selectedCondition || "Any"}
              onChange={(e: any) => {
                dispatch(addMotorCondition(e.target.value));
                onConditionSelect(e.target.value)
              }}
            >
              <FormControlLabel
                value={"Any"}
                key={0}
                control={<Radio color="primary" size="small" />}
                label={<span className={classes.radiolabel}>{lan.ANY}</span>}
              />
              {conditionMotors.map((item: any) => (
                <FormControlLabel
                  value={item.name}
                  key={item.id}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <span className={classes.radiolabel}>{item.label}</span>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      );
    } else if (
      subcat == mobiles ||
      subcat == tv ||
      subcat == mobile_parts ||
      subcat == tab ||
      subcat == audio_speakers ||
      subcat == computers ||
      subcat == cameras ||
      subcat == other_electronics
    ) {
      return (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#F5F6F8",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
          className={classes.radiogroup}
        >
          <FormControl
            component="fieldset"
            style={{ marginTop: 5, marginLeft: 10 }}
          >
            <FormLabel component="legend">
              {/* {lan.CONDITION} */}
              {lan.CONDITION}
            </FormLabel>
            <RadioGroup
              aria-label={lan.CONDITION}
              name="Mobile_Condition"
              style={{ marginTop: 10 }}
              value={selectedCondition || "Any"}
              onChange={(e: any) => {
                dispatch(addMobileCondition(e.target.value));
                onConditionSelect(e.target.value)
              }}
            >
              <FormControlLabel
                value={"Any"}
                key={0}
                control={<Radio color="primary" size="small" />}
                label={<span className={classes.radiolabel}>
                  {/* Any */}
                  {lan.ANY}
                </span>}
              />
              {conditionMobiles.map((item: any) => (
                <FormControlLabel
                  value={item.name}
                  key={item.id}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <span className={classes.radiolabel}>{item.label}</span>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadTransmission = (subcat: any) => {
    if (subcat == cars) {
      return (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#F5F6F8",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
          className={classes.radiogroup}
        >
          <FormControl
            component="fieldset"
            style={{ marginTop: 5, marginLeft: 10 }}
          >
            <FormLabel component="legend">
              {/* Transmission */}
              {lan.TRANSMISSION}
            </FormLabel>
            <RadioGroup
              aria-label={lan.CONDITION}
              name="Motors_Transmission"
              style={{ marginTop: 10 }}
              value={selectedTransmission || "Any"}
              onChange={(e: any) => {
                dispatch(addMotorTransmission(e.target.value));
                onTransmissionSelect(e.target.value)
              }}
            >
              <FormControlLabel
                value={"Any"}
                key={0}
                control={<Radio color="primary" size="small" />}
                label={<span className={classes.radiolabel}>
                  {/* Any */}
                  {lan.ANY}
                </span>}
              />
              {transmission.map((item: any) => (
                <FormControlLabel
                  value={item.name}
                  key={item.id}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <span className={classes.radiolabel}>{item.label}</span>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      );
    }
  };

  const loadEngineCapacity = (subcat: any) => {
    if (subcat == bikes) {
      return (
        <Grid item xs={12} style={{ width: "300px" }}>
          <TextField
            className={classes.color}
            id="filled-number"
            // label={lan.ENGINE_CAPACITY}
            label={lan.ENGINE_CAPACITY}
            type="number"
            variant="filled"
            value={engineCapacity.min || ""}
            onChange={(e: any) => {
              dispatch(addengineCapacity(e.target.value));
              dispatch(saveEngineCapacity(e.target.value))
            }
            }
            style={{ width: "300px" }}
          />
        </Grid>
      );
    } else {
      return;
    }
  };

  const laodBedRooms = (subcat: any) => {
    if (
      subcat == apartment_sale ||
      subcat == house_sale ||
      subcat == apartment_rent ||
      subcat == house_rent ||
      subcat == daily ||
      subcat == monthly
    ) {
      return (
        <Grid item xs={12} style={{ width: "300px" }}>
          <FormControl variant="filled" style={{ width: "300px" }}>
            <InputLabel id="select-lbl">
              {/* Bed Rooms */}
              {lan.BED_ROOMS}
            </InputLabel>
            <Select
              className={classes.select}
              labelId="select-lbl"
              id="bed-rooms"
              value={bedroom.min || "Any"}
              onChange={(e: any) => {
                dispatch(addBedRooms(e.target.value));
                dispatch(
                  saveBedroom(e.target.value === "Any" ? "" : e.target.value)
                )
              }
              }
            >
              <MenuItem key={0} value={"Any"}>
                {/* Any */}
                {lan.ANY}
              </MenuItem>
              {numbers.map((item: any) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadBathRooms = (subcat: any) => {
    if (
      subcat == apartment_sale ||
      subcat == house_sale ||
      subcat == apartment_rent ||
      subcat == house_rent ||
      subcat == daily ||
      subcat == monthly
    ) {
      return (
        <Grid item xs={12} style={{ width: "300px" }}>
          <FormControl variant="filled" style={{ width: "300px" }}>
            <InputLabel id="select-lbl">
              {/* Bath Rooms */}
              {lan.BATH_ROOMS}
            </InputLabel>
            <Select
              className={classes.select}
              labelId="select-lbl"
              id="bath-rooms"
              value={bathroom.min || "Any"}
              onChange={(e: any) => {
                dispatch(addBathRooms(e.target.value));
                dispatch(
                  saveBathroom(e.target.value === "Any" ? "" : e.target.value)
                )
              }
              }
            >
              <MenuItem key={0} value={"Any"}>
                {/* Any */}
                {lan.any}
              </MenuItem>
              {numbers.map((item: any) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadFloorArea = (subcat: any) => {
    if (
      subcat == apartment_sale ||
      subcat == house_sale ||
      subcat == commercial_sale ||
      subcat == apartment_rent ||
      subcat == house_rent ||
      subcat == commercial_rent ||
      subcat == daily ||
      subcat == monthly
    ) {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="Property_Floor_Area"
              // label="Floor Area Min"
              label={lan.FLOOR_AREA_MIN}
              type="number"
              variant="filled"
              value={floorArea.min || ""}
              onChange={(e: any) => {
                dispatch(addFloorAreaMin(e.target.value));
                dispatch(
                  saveFloorArea({ ...floorArea, min: e.target.value as any })
                )
              }
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="Property_Floor_Area_Max"
              // label="Floor Area Max"
              label={lan.FLOOR_AREA_MAX}
              type="number"
              variant="filled"
              value={floorArea.max || ""}
              onChange={(e: any) => {
                dispatch(
                  saveFloorArea({ ...floorArea, max: e.target.value as any })
                )
              }
              }
            />
          </Grid>
        </>
      );
    } else {
      return;
    }
  };

  const loadParkingSpaceNumber = (subcat: any) => {
    if (
      subcat == apartment_sale ||
      subcat == house_sale ||
      subcat == daily ||
      subcat == monthly
    ) {
      return (
        <Grid item xs={12} style={{ width: "300px" }}>
          <FormControl variant="filled" style={{ width: "300px" }}>
            <InputLabel id="select-lbl">
              {/* Parking Space */}
              {lan.PARKING_SPACE}
            </InputLabel>
            <Select
              className={classes.select}
              labelId="select-lbl"
              id="bath-rooms"
              value={parkingNum.min || "Any"}
              onChange={(e: any) =>
                dispatch(
                  saveParking(e.target.value === "Any" ? "" : e.target.value)
                )
              }
            >
              <MenuItem key={0} value={"Any"}>
                {/* Any */}
                {lan.ANY}
              </MenuItem>
              {numbers.map((item: any) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadParkingSpace = (subcat: any) => {
    if (
      subcat == apartment_rent ||
      subcat == house_rent ||
      subcat == room_rent
    ) {
      return (
        <Grid
          item
          xs={12}
          style={{
            width: "300px",
            backgroundColor: "#F5F6F8",
            paddingLeft: "20px",
          }}
          className={classes.switchgroup}
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked1}
                onChange={(e: any) => {
                  setChecked1(e.target.checked);
                  dispatch(addParkingSpace(e.target.checked));
                }}
                name="Property_Parking_Space"
                color="primary"
              />
            }
            // label={lan.PARKING_SPACE}
            label={lan.PARKING_SPACE}
          />
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadFurnished = (subcat: any) => {
    if (
      subcat == commercial_sale ||
      subcat == apartment_rent ||
      subcat == apartment_sale ||
      subcat == house_sale ||
      subcat == house_rent ||
      subcat == commercial_rent ||
      subcat == daily ||
      subcat == monthly ||
      subcat == room_rent
    ) {
      return (
        <Grid
          item
          xs={12}
          style={{
            width: "300px",
            backgroundColor: "#F5F6F8",
            paddingLeft: "20px",
          }}
          className={classes.switchgroup}
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={(e: any) => {
                  setChecked(e.target.checked);
                  dispatch(addFurnished(e.target.checked));
                }
                }
                name="Property_Furnished"
                color="primary"
              />
            }
            // label="{lan.FURNISHED}"
            label={lan.FURNISHED}
          />
        </Grid>
      );
    } else {
      return;
    }
  };

  const loadPrivateEntranceBathroom = (subcat: any) => {
    if (subcat == room_rent) {
      return (
        <>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#F5F6F8", paddingLeft: "20px" }}
            className={classes.switchgroup}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={checked2}
                  onChange={(e: any) => setChecked2(e.target.checked)}
                  name="Property_Private_Entrance"
                  color="primary"
                />
              }
              // label="Private Entrance"
              label={lan.PRIVATE_ENTRANCE}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#F5F6F8", paddingLeft: "20px" }}
            className={classes.switchgroup}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={checked3}
                  onChange={(e: any) => setChecked3(e.target.checked)}
                  name="Property_Private_Bathroom"
                  color="primary"
                />
              }
              // label="Private Bathroom"
              label={lan.PRIVATE_BATHROOM}
            />
          </Grid>
        </>
      );
    } else {
      return;
    }
  };

  const loadLandSize = (subcat: any) => {
    if (subcat == land_sale) {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="filled-number"
              // label="Land Size Min"
              label={lan.LAND_SIZE_MIN}
              type="number"
              variant="filled"
              value={landArea.min || ""}
              onChange={(e: any) => {
                dispatch(addMinLandSize(e));
                dispatch(
                  saveLandArea({ ...landArea, min: e.target.value as any })
                )
              }
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="filled-number"
              // label="Land Size Max"
              label={lan.LAND_SIZE_MAX}
              type="number"
              variant="filled"
              value={landArea.max || ""}
              onChange={(e: any) => {
                dispatch(addMaxLandSize(e));
                dispatch(
                  saveLandArea({ ...landArea, max: e.target.value as any })
                )
              }
              }
            />
          </Grid>
        </>
      );
    } else {
      return;
    }
  };

  const loadHouseLandSize = (subcat: any) => {
    if (subcat == house_sale) {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="filled-number"
              // label="Land Size Min"
              label={lan.LAND_SIZE_MIN}
              type="number"
              variant="filled"
              value={propertyPerches.min || ""}
              onChange={(e: any) => {
                dispatch(addMinLandSize(e));
                dispatch(
                  savePropertyPerches({
                    ...propertyPerches,
                    min: e.target.value as any,
                  })
                )
              }
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.color}
              id="filled-number"
              // label="Land Size Max"
              label={lan.LAND_SIZE_MAX}
              type="number"
              variant="filled"
              value={propertyPerches.max || ""}
              onChange={(e: any) => {

                dispatch(addMaxLandSize(e));
                dispatch(
                  savePropertyPerches({
                    ...propertyPerches,
                    max: e.target.value as any,
                  })
                )
              }
              }
            />
          </Grid>
        </>
      );
    } else {
      return;
    }
  };

  const getValues = (e: any) => {
    setFilerClicked({ state: true, event: e });
    const location = values.location == undefined ? "" : values.location;
    const brand = values.brand == undefined ? "" : values.brand.name;
    const model = values.model == undefined ? "" : values.model.name;
    const condition = values.condition == undefined ? "" : values.condition;
    const year = values.years == undefined ? "" : values.years;
    const transmission =
      values.transmission == undefined ? "" : values.transmission;
    const furnished = values.furnished == undefined ? "" : values.furnished;
    const parking = values.parking == undefined ? "" : values.parking;
    const privateBathroom =
      values.privateBathroom == undefined ? "" : values.privateBathroom;
    const privateEntrance =
      values.privateEntrance == undefined ? "" : values.privateEntrance;

    setTypedValues((v: any) => {
      return { ...v, price: price };
    });

    if (subCategory == cars) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        brand,
        model,
        year,
        condition,
        transmission,
      ]);
      setTypedValues((v: any) => {
        return { ...v, Motors_Mileage: mileage };
      });
    } else if (subCategory == bikes) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        brand,
        model,
        year,
        condition,
      ]);
      setTypedValues((v: any) => {
        return {
          ...v,
          Motors_Mileage: mileage,
          Bike_Engine_Capacity: engineCapacity,
        };
      });
    } else if (subCategory == mobiles) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        brand,
        model,
        condition,
        sale,
      ]);
    } else if (
      subCategory == commercial_sale ||
      subCategory == commercial_rent
    ) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        furnished,
      ]);
      setTypedValues((v: any) => {
        return { ...v, Property_Floor_Area: floorArea };
      });
    } else if (
      subCategory == house_sale ||
      subCategory == apartment_sale ||
      subCategory == daily ||
      subCategory == monthly
    ) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        furnished,
      ]);
      setTypedValues((v: any) => {
        return {
          ...v,
          Property_Bedroom: bedroom,
          Property_Bathroom: bathroom,
          Property_Floor_Area: floorArea,
          Property_Parking_Space_Num: parkingNum,
          Property_Perches: propertyPerches,
        };
      });
    } else if (
      subCategory == apartment_rent ||
      subCategory == apartment_sale ||
      subCategory == house_rent
    ) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        furnished,
        parking,
      ]);
      setTypedValues((v: any) => {
        return {
          ...v,
          Property_Bedroom: bedroom,
          Property_Bathroom: bathroom,
          Property_Floor_Area: floorArea,
        };
      });
    } else if (subCategory == room_rent) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        furnished,
        parking,
        privateBathroom,
        privateEntrance,
      ]);
    } else if (
      subCategory == tv ||
      subCategory == mobile_parts ||
      subCategory == computers ||
      subCategory == tab ||
      subCategory == audio_speakers ||
      subCategory == cameras ||
      subCategory == other_electronics
    ) {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        condition,
        sale,
      ]);
    } else if (subCategory == land_sale) {
      setFilteredValues([selectedCat.level1, selectedCat.level2, location]);
      setTypedValues((v: any) => {
        return { ...v, Land_Area: landArea };
      });
    } else {
      setFilteredValues([
        selectedCat.level1,
        selectedCat.level2,
        location,
        sale,
      ]);
    }

    setFilerClicked({ state: true, event: e });

  };

  const saveData = () => {
    const filteredTags = filteredValues.filter(
      (inp: string) => inp != "" && inp != undefined
    );
    const filters = Object.keys(typedValues)
      .map((k: string) => {
        return { name: k, min: typedValues[k].min, max: typedValues[k].max };
      })
      .filter(
        (vals: any) =>
          !(
            (vals.min == "" || vals.min == 0 || vals.min == undefined) &&
            (vals.max == "" || vals.max == 0 || vals.max == undefined)
          )
      )
      .map((vals) =>
        vals.min == "" || vals.min == undefined ? { ...vals, min: 0 } : vals
      )
      .map((vals) =>
        vals.max == "" || vals.max == undefined ? { ...vals, max: 0 } : vals
      )
      .map((vals) => vals.name + ":" + vals.min + "$" + vals.max)
      .join("|");
    makeTheSearch(filteredTags, filters);
  };

  const makeTheSearch = (tags: string[], filters: string) => {
    const url = new URLSearchParams(window.location.search);
    const phrase =
      url
        .get("phrase")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, "")
        .trim() || "";
    const c2 = encodeURIComponent(
      subCategory
        .replace(/[^\w\(\)\s^\&]/g, "")
        .replace(/\s\s+/g, "")
        .trim()
        .toLowerCase()
    );
    const c1 = mainCategory
      .replace(/[^\w\s]/g, "")
      .replace(/\s\s+/g, "")
      .trim()
      .toLowerCase();
    const tagsurl = tags.length ? `&tags=${tags.join(",")}` : "";
    navigate(
      `/search?${phrase ? `phrase=${phrase.trim()}` : ""}${mainCategory ? `&c1=${c1}` : ""
      }${subCategory ? `&c2=${c2}` : ""}${tagsurl}${filters ? `&filters=${filters}` : ""
      }`
    );
    scrollContent?.scrollTo(0, 0);
    logEvent(analytics, ADD_FILTER, { action: ACTION_INPUT, platform: "DESKTOP" });
  };

  const handleBannerClick = () => {
    logEvent(analytics, CLICK_ON_SIDEBAR_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };

  return (
    <div style={{ position: 'relative', paddingTop: '30px' }}>
      <div id="filters-header">
        <div>
          {/* Filters */}
          {lan.FILTERS}
        </div>
      </div>
      <Grid container className={classes.bgcolor}>
        <Grid item xs={12}>
          {/* <img src="assets/icon/icon-location-pin.svg" className="icon-center"/> */}

          <Autocomplete
            id="district"
            options={district}
            disableClearable
            onChange={(event: any, district: any) => {
              // console.log(district);
              onDistrictSelect(district);
             
            }}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={lan.DISTRICT}  />
            )}
          />

          {/* <Autocomplete
            filterOptions={filterOptions}
            className={classes.color}
            id="location"
            options={location}
            inputValue={values?.location?.name}
            onInputChange={(event: any, location: string | null) => {
              
              onLocationSelect(location);
            }}
            getOptionLabel={(option: any) => option.name}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.commonlabel}
                // label="Location"
                label={lan.LOCATION}
                variant="filled"
              />
            )}
          /> */}
        </Grid>
        <Grid item xs={12}>
         
          <Autocomplete
            id="city"
            options={cities}
            disableClearable
            disabled={!values.district}
          
            onChange={(event: any, location: any) => {
             
              onCitySelect(location);
              onLocationSelect(location.name);
            }}
            // getOptionLabel={(option: any) => option.name}
            getOptionLabel={(option: any) => (isSavedDistrict() ? option.split("-")[0] : option.name.split("-")[0])}
            getOptionSelected={(f: any, s: any) => f.name === s.name}
            style={{ width: "100%"}}
            renderInput={(params) => (
              <TextField
                {...params}
                name="city"
                label={lan.CITY}
                style={{textAlign:'center'}}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.color}
            id="filled-number"
            // label="Price Min"
            label={lan.PRICE_MIN}
            type="number"
            variant="filled"
            value={price.min || ""}
            onChange={(e: any) => {
              dispatch(addMinPrice(e.target.value));
              dispatch(savePrice({ ...price, min: e.target.value as any }))
            }
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.color}
            id="filled-number"
            // label="Price Max"
            label={lan.PRICE_MAX}
            type="number"
            variant="filled"
            value={price.max || ""}
            onChange={(e: any) => {
              dispatch(addMaxPrice(e.target.value));
              dispatch(savePrice({ ...price, max: e.target.value as any }))
            }
            }
          />
        </Grid>
        <Grid item xs={12} style={{ width: "300px" }}>
          <FormControl variant="filled" style={{ width: "100%" }}>
            <InputLabel shrink htmlFor="main-category">
              {/* Main Category */}
              {lan.MAIN_CATEGORY}
            </InputLabel>
            <Select
              className={classes.select}
              native
              inputProps={{ id: "main-category" }}
              value={mainCategory}
              onChange={(e) => onMainCategorySelect(e.target.value)}
            >
              <option value={""}>
                {/* All */}
                {lan.ALL}
              </option>
              {categories.map((m: any) => (
                <option key={m.id} value={m.name}>
                  {/* {m?.name} */}
                  {
                    (m?.name == 'MOTORS' ? lan.MOTORS : (m?.name == 'MOBILES AND ELECTRONICS' ? lan.MOBILE_AND_ELECTRONICS : (m?.name == 'PROPERTY FOR RENT' ? lan.PROPERTY_FOR_RENT : (m?.name == 'PROPERTY FOR SALE' ? lan.PROPERTY_FOR_SALE : (m?.name == 'CLASSIFIEDS' ? lan.CLASSIFIEDS : lan.OTHERS)))))
                  }
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {loadSubCategories()}
        {loadBrandModel(subCategory)}
        {loadYear(subCategory)}
        {loadMilage(subCategory)}
        {loadCondition(subCategory)}
        {loadTransmission(subCategory)}
        {loadEngineCapacity(subCategory)}
        {laodBedRooms(subCategory)}
        {loadBathRooms(subCategory)}
        {loadFloorArea(subCategory)}
        {loadParkingSpaceNumber(subCategory)}
        {loadParkingSpace(subCategory)}
        {loadFurnished(subCategory)}
        {loadPrivateEntranceBathroom(subCategory)}
        {loadLandSize(subCategory)}
        {loadHouseLandSize(subCategory)}

        {/* {mainCategory == "CLASSIFIEDS" ||
          mainCategory == "MOBILES AND ELECTRONICS" ? (
          <Grid
            item
            xs={12}
            style={{
              width: "300px",
              backgroundColor: "#F5F6F8",
              paddingLeft: "20px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  className={classes.sale}
                  checked={deal}
                  onChange={() => setdeal(!deal)}
                />
              }
              label="Deals"
            />
          </Grid>
        ) : (
          <></>
        )} */}

        <Grid item xs={12} id="btn-apply-cover">
          <Button
            variant="contained"
            color="primary"
            onClick={getValues}
            id="btn-apply"
          >
            <span id="btn-apply-name">
              {/* Apply Filter */}
              {lan.APPLY_FILTER}
            </span>
          </Button>
        </Grid>

        <Grid item xs={12} >

          <div style={{ marginTop: "30px" }} onClick={handleBannerClick}>
            {/* <img src="assets/walls/electronics-300x250.png" alt=""></img> */}

            {isMotor() ? (

              <iframe scrolling="no"
                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                src="https://services.saruwata.lk/AdManager/L1/L1-Motors-Desktop-Medium-Rectangle-300x250/index.html" />

            ) : isProperty() ? (

              <iframe scrolling="no"
                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                src="https://services.saruwata.lk/AdManager/L1/L1-Property-Sale-Desktop-Medium-Rectangle-300x250/index.html" />


            ) : isPropertyRent() ?


              <iframe scrolling="no"
                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                src="https://services.saruwata.lk/AdManager/L1/L1-Property-Rent-Desktop-Medium-Rectangle-300x250/index.html" />

              : isElectronic() ?

                <iframe scrolling="no"
                  style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                  src="https://services.saruwata.lk/AdManager/L1/L1-Mobiles-and-Electronics-Desktop-Medium-Rectangle-300x250/index.html" />


                : isClassified() ?


                  <iframe scrolling="no"
                    style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                    src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Desktop-Medium-Rectangle-300x250/index.html" />

                  : (


                    <iframe scrolling="no"
                      style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                      src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Medium-Rectangle-300x250/index.html" />




                  )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersModal;
