// import "./Home.css";
// import React, { useEffect, useRef, useState } from "react";
// import { LazyLoadComponent } from "react-lazy-load-image-component";
// import {
//   Box,
//   Button,
//   createStyles,
//   Fab,
//   Grid,
//   Hidden,
//   IconButton,
//   makeStyles,
//   Theme,
// } from "@material-ui/core";

// import { Post } from "../../features/post/Post";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllAvailablePosts,
//   selectFeedPage,
//   selectPosts,
//   setFeedPage,
// } from "../../features/post/postSlice";

// import { NAV_HOME } from "../../providers/NavigationProvider";
// import { setCurrentLocation } from "../../features/navigation/navigationSlice";
// import KeywordSearch from "../../components/KeywordSearch/KeywordSearch";
// import { useHistory } from "react-router";
// import PulseLoader from "react-spinners/PulseLoader";
// import InfiniteScroll from "react-infinite-scroll-component";
// import Alert from "@material-ui/lab/Alert";
// import { ArrowDropUp, ArrowUpward, Height } from "@material-ui/icons";
// import { selectPosition } from "../../features/scroll/scrollSlice";
// import ScrollRestore from "../../components/ScrollRestore";
// import { KEYWORD_SEARCH_ALL, VIEW_HOME_PAGE } from "../../config/events";
// import { logEvent } from "firebase/analytics"
// import { ACTION_INPUT, ACTION_VIEW } from "../../config/actions";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useTheme } from "@material-ui/core/styles";
// import Spotlight from "../../components/Post/SpotlightAd/Spotlight";
// import { addTittle } from "../../features/search/FilterSlice";
// import PostCard from "../../components/Post/PostCardFixed/PostCard";
// import PostCardMobile from "../../components/Post/PostCardFixed/PostCardMobile/PostCardMobile";





// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     tagline: {
//       fontWeight: 700,
//       paddingBottom: 7,
//       paddingLeft: 5,
//       paddingRight: 5,
//     },
//     btnApply: {
//       "&:hover": {
//         boxShadow: "0px 0px 8px #00000026",
//       },
//     },
//   })
// );

// const Home: React.FC = (props: any) => {
//   let items: any = useSelector(selectPosts);

//   const dispatch = useDispatch();
//   const [loadingTries, setLoadingTries] = useState(0);
//   const [page, setPage] = useState(0);
//   const history = useHistory();
//   const topref = useRef<any>();
//   const styles = useStyles();
//   const [busy, setbusy] = useState(false);
//   const scrollContent = document.getElementById("scrollable-root");
//   const [showScrollTop, setshowScrollTop] = useState(false);
//   const scrollPosition = useSelector(selectPosition);
//   
//   const theme = useTheme();
//   const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));

//   useEffect(() => {
//     dispatch(setCurrentLocation(NAV_HOME));

//     events.logEvent(VIEW_HOME_PAGE, {
//       action: ACTION_VIEW,
//       platform: "DESKTOP",
//     });
//   }, []);

//   useEffect(() => {
//     setshowScrollTop(scrollPosition > 2000);
//   }, [scrollPosition]);

//   useEffect(() => {
//     if (loadingTries > 0) return;
//     if (items.length === 0) loadNewItems(true, 0);
//     setLoadingTries(loadingTries + 1);
//   }, [items]);

//   useEffect(() => {
//     dispatch(setFeedPage(page));
//   }, [page]);

//   const loadMorePosts = () => {
//     setbusy(true);
//     loadNewItems(true, 0, page + 1);
//     setPage(page + 1);
//     setshowScrollTop(true);
//   };

//   const loadNewItems = (all: boolean, lastTime: number, page: number = 0) => {
//     dispatch(
//       getAllAvailablePosts(
//         () => {
//           setbusy(false);
//         },
//         all,
//         lastTime,
//         page
//       )
//     );
//   };

//   const loadPosts = () => {
//     return <></>;
//   };

//   // for (let index = 0; index < items.length; index++) {

//   //   if (index % 15 == 0) {
//   //     items.splice(index + 1, 0, 'div> Nipun </div>');

//   //   }
//   //   const element = items[index];

//   // }

//   const loadSpotLight = () => {

//     return (
//       // <></>
//       <Spotlight />

//     );
//   };
//   const loadCardView = () => {
//     return (
//       <>
//         {items.length > 0 ? (
//           <InfiniteScroll

//             hasMore={true}
//             loader={
//               <Box display="flex" justifyContent="center">
//                 {busy ? <PulseLoader color="#0080ff" loading={true} /> : null}
//               </Box>
//             }
//             next={() => { }}
//             dataLength={items.length}
//             scrollableTarget="scrollable-root"
//             endMessage={
//               <Alert severity="success" color="info">
//                 You have seen all
//               </Alert>
//             }
//           >
//             <div style={{ display: "block" }}>
//               <Hidden xsDown={true} smDown={true}>
//                 {items.map((item: Post, index: number) => (
//                   <div key={item.id} style={{ padding: "1px 5px", position: "relative" }}>
//                     <LazyLoadComponent
//                       placeholder={
//                         <img
//                           src="assets/images/Place-holder-listing.png"
//                           style={{ width: "707px", height: "194px" }}
//                         ></img>
//                       }
//                       key={item.id}
//                     >
//                       <PostCard
//                         post={item}
//                         position={index + 1}
//                         detailsAction={() => { }}
//                       ></PostCard>

//                     </LazyLoadComponent>
//                   </div>
//                 ))}
//               </Hidden>
//             </div>

//             <Hidden lgUp={true} mdUp={true}>
//               {items.map((item: Post, index: number) => (
//                 <div key={item.id} style={{ padding: "5px 0px" }}>
//                   <LazyLoadComponent
//                     placeholder={
//                       <img
//                         src="assets/images/Place-holder-listing.png"
//                         style={{ width: "auto", height: "100px" }}
//                       ></img>
//                     }
//                     key={item.id}
//                   >
//                     <PostCardMobile
//                       post={item}
//                       position={index + 1}
//                       detailsAction={() => { }}
//                     ></PostCardMobile>
//                   </LazyLoadComponent>
//                 </div>
//               ))}
//             </Hidden>



//           </InfiniteScroll>
//         ) : (
//           <Box display="flex" justifyContent="center">
//             <PulseLoader color="#0080ff" loading={true} />
//           </Box>
//         )
//         }
//       </>
//     );
//   };
//   // const loadCardView = () => {
//   //   return (
//   //     <>
//   //       {items.length > 0 ? (
//   //         <InfiniteScroll
//   //           hasMore={true}
//   //           loader={
//   //             <Box display="flex" justifyContent="center">
//   //               {busy ? <PulseLoader color="#0080ff" loading={true} /> : null}
//   //             </Box>
//   //           }
//   //           next={() => { }}
//   //           dataLength={items.length}
//   //           scrollableTarget="scrollable-root"
//   //           endMessage={
//   //             <Alert severity="success" color="info">
//   //               You have seen all
//   //             </Alert>
//   //           }
//   //         >
//   //           {items.map((item: Post, index: number) => (
//   //             <div key={item.id} style={{ padding: "10px 5px" }}>
//   //               <LazyLoadComponent
//   //                 placeholder={
//   //                   <img
//   //                     src="assets/images/Plase-holder-listing.png"
//   //                     style={{ width: "707px", height: "194px" }}
//   //                   ></img>
//   //                 }
//   //                 key={item.id}
//   //               >
//   //                 <PostCard
//   //                   post={item}
//   //                   position={index + 1}
//   //                   detailsAction={() => { }}
//   //                 ></PostCard>
//   //               </LazyLoadComponent>
//   //             </div>
//   //           ))}
//   //         </InfiniteScroll>
//   //       ) : (
//   //         <Box display="flex" justifyContent="center">
//   //           <PulseLoader color="#0080ff" loading={true} />
//   //         </Box>
//   //       )}
//   //     </>
//   //   );
//   // };

//   const handleKeywordSelect = (val: string) => {
//     setPage(0);
//     if (val) {
//       navigate(`/search?phrase=${val}`);
//       dispatch(addTittle(val));
//       events.logEvent(KEYWORD_SEARCH_ALL, {
//         action: ACTION_INPUT,
//         data: val,
//         platform: "DESKTOP",
//       });
//     }
//   };

//   const handleScrollToTopRequest = () => {
//     setshowScrollTop(false);
//     scrollContent?.scrollTo(0, 300);
//   };

//   return (
//     <div ref={topref} style={{ position: "relative", backgroundColor: "red", height: '100vh' }}>
//       <div className={styles.tagline}>
//         {"Buy & sell anything online in Sri Lanka"}
//       </div>
//       <Grid container style={{ padding: "0 5px" }}>
//         <Grid
//           item
//           xs={12}
//           id="search-tabbar"
//           style={{ maxWidth: `${!smallViewPort && "unset"}` }}
//         >
//           <KeywordSearch onSearchEnter={handleKeywordSelect}></KeywordSearch>
//         </Grid>
//       </Grid>
//       <div style={{ height: "10px" }}></div>
//       {loadSpotLight()}

//       <div style={{ position: "relative", backgroundColor: "red", height: '100vh' }}>
//         {loadCardView()}


//         {items.length > 0 ? (
//           <Grid container>
//             <Grid
//               item
//               xs={12}
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 padding: "50px 50px 0 50px",
//                 marginBottom: -53,
//               }}
//             >
//               {busy ? null : (
//                 <Button
//                   color="primary"
//                   id="btn-load-more"
//                   onClick={loadMorePosts}
//                   className={styles.btnApply}
//                 >
//                   <span>Load More</span>
//                 </Button>
//               )}
//             </Grid>
//           </Grid>
//         ) : null}
//         {page > 0 ? (
//           <div
//             style={{
//               position: "sticky",
//               bottom: "100px",
//               zIndex: 999,
//               display: "flex",
//               justifyContent: "flex-end",
//               width: "100%",
//               marginLeft: "340px",
//               pointerEvents: "none",
//             }}
//           >
//             {showScrollTop ? (
//               <Fab
//                 color="primary"
//                 aria-label="add"
//                 onClick={handleScrollToTopRequest}
//                 style={{ pointerEvents: "all" }}
//               >
//                 <ArrowUpward></ArrowUpward>
//               </Fab>
//             ) : null}
//           </div>
//         ) : null}
//       </div>
//       <ScrollRestore {...props} />

//     </div >
//   );
// };

// export default Home;

import "./Home.css";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
  Box,
  Button,
  createStyles,
  Fab,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { Post } from "../../features/post/Post";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAvailablePosts,
  selectFeedPage,
  selectPosts,
  setFeedPage,
} from "../../features/post/postSlice";
import PostCard from "../../components/Post/PostCardFixed/PostCard";
import { NAV_HOME } from "../../providers/NavigationProvider";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import KeywordSearch from "../../components/KeywordSearch/KeywordSearch";

import PulseLoader from "react-spinners/PulseLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@material-ui/lab/Alert";
import { ArrowDropUp, ArrowUpward } from "@material-ui/icons";
import { selectPosition } from "../../features/scroll/scrollSlice";
import ScrollRestore from "../../components/ScrollRestore";
import { KEYWORD_SEARCH_ALL, VIEW_HOME_PAGE } from "../../config/events";
import { logEvent } from "firebase/analytics"
import { ACTION_INPUT, ACTION_VIEW } from "../../config/actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Spotlight from "../../components/Post/SpotlightAd/Spotlight";
import { addTittle } from "../../features/search/FilterSlice";
import PostCardMobile from "../../components/Post/PostCardFixed/PostCardMobile/PostCardMobile";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagline: {
      fontWeight: 700,
      paddingBottom: 7,
      paddingLeft: 5,
      paddingRight: 5,
      // paddingTop: "12px"
    },
    btnApply: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
  })
);

const Home: React.FC = (props: any) => {
  let items: any = useSelector(selectPosts);
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const dispatch = useDispatch();
  const [loadingTries, setLoadingTries] = useState(0);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const topref = useRef<any>();
  const styles = useStyles();
  const [busy, setbusy] = useState(false);
  const scrollContent = document.getElementById("scrollable-root");
  const [showScrollTop, setshowScrollTop] = useState(false);
  const scrollPosition = useSelector(selectPosition);

  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));


  useEffect(() => {
    dispatch(setCurrentLocation(NAV_HOME));

    logEvent(analytics, VIEW_HOME_PAGE, {
      action: ACTION_VIEW,
      platform: "DESKTOP",
    });
  }, []);

  useEffect(() => {

    setshowScrollTop(scrollPosition > 2000);
  }, [scrollPosition]);

  useEffect(() => {
    if (loadingTries > 0) return;
    if (items.length === 0) loadNewItems(true, 0);
    setLoadingTries(loadingTries + 1);
  }, [items]);

  useEffect(() => {
    dispatch(setFeedPage(page));
  }, [page]);

  useEffect(()=>{
    console.log("home");
    
  },[])


  const loadMorePosts = () => {
    setbusy(true);
    loadNewItems(true, page, page + 1);
    setPage(page + 1);
    setshowScrollTop(true);
  };

  const loadNewItems = (all: boolean, lastTime: number, page: number = 0) => {
    dispatch(
      getAllAvailablePosts(
        () => {
          setbusy(false);
        },
        all,
        lastTime,
        page
      )
    );
  };

  const loadPosts = () => {
    return <></>;
  };

  // for (let index = 0; index < items.length; index++) {

  //   if (index % 15 == 0) {
  //     items.splice(index + 1, 0, 'div> Nipun </div>');

  //   }
  //   const element = items[index];

  // }

  const loadSpotLight = () => {

    return (
      // <></>
      <Spotlight />

    );
  };
  const refresh = () => {
    alert('hgh');
  }
  const loadCardView = () => {
    return (
      <>
        {items.length > 0 ? (
          <InfiniteScroll
            hasMore={true}
            style={{ overflowX: 'hidden' }}
            loader={
              <Box display="flex" justifyContent="center">
                {busy ? <PulseLoader color="#0080ff" loading={true} /> : null}
              </Box>
            }
            next={() => { }}
            dataLength={items.length}
            scrollableTarget="scrollable-root"
            endMessage={
              <Alert severity="success" color="info">
                You have seen all
              </Alert>
            }

          >

            {/* {items.map((item: Post, index: number) => (
              <div key={item.id} style={{ padding: "10px 5px" }}>
                <LazyLoadComponent
                  placeholder={
                    <img
                      src="assets/images/Place-holder-listing.png"
                      style={{ width: "707px", height: "194px" }}
                    ></img>
                  }
                  key={item.id}
                >
                  <PostCard
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                  ></PostCard>
                </LazyLoadComponent>
              </div>
            ))} */}

            <Hidden xsDown={true} smDown={true}>
              {items.map((item: Post, index: number) => (
                <>
                  <div key={item.id} style={{ padding: "1px 5px", position: "relative" }}>
                    <LazyLoadComponent
                      placeholder={
                        <img
                          src="assets/images/Place-holder-listing.png"
                          style={{ width: "707px", height: "194px" }}
                        ></img>
                      }
                      key={item.id}
                    >
                      <PostCard
                        post={item}
                        position={index + 1}
                        detailsAction={() => { }}
                      ></PostCard>

                    </LazyLoadComponent>

                  </div>
                  {
                    (index > 1 ?

                      (index % 14 == 0 ? <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "30px",
                            marginBottom: "30px"
                          }}


                        >

                          <iframe scrolling="no"
                            style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                            src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Listing-15-Leaderboard-728x90/index.html" />


                        </Grid>
                      </Grid> : null)
                      : null)
                  }

                </>
              ))}


            </Hidden>


            <Hidden lgUp={true} mdUp={true}>
              {items.map((item: Post, index: number) => (
                <>
                  <div key={item.id} style={{ padding: "5px 0px" }}>
                    <LazyLoadComponent
                      placeholder={
                        <img
                          src="assets/images/Place-holder-listing.png"
                          style={{ width: "auto", height: "100px" }}
                        ></img>
                      }
                      key={item.id}
                    >
                      <PostCardMobile
                        post={item}
                        position={index + 1}
                        detailsAction={() => { }}
                      ></PostCardMobile>
                    </LazyLoadComponent>
                  </div>


                  {
                    (index > 1 ?

                      (index % 14 == 0 ? <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "30px",
                            marginBottom: "30px",
                            display: "flex",
                            justifyContent: "center"
                          }}


                        >

                          <iframe scrolling="no"
                            style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                            src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Mobile-Listing-15-Medium-Rectangle-300x250/index.html" />


                        </Grid>
                      </Grid> : null)
                      : null)
                  }
                </>
              ))}
            </Hidden>
          </InfiniteScroll>
        ) : (
          <Box display="flex" justifyContent="center">
            <PulseLoader color="#0080ff" loading={true} />
          </Box>
        )}
      </>
    );
  };
  // const loadCardView = () => {
  //   return (
  //     <>
  //       {items.length > 0 ? (
  //         <InfiniteScroll
  //           hasMore={true}
  //           loader={
  //             <Box display="flex" justifyContent="center">
  //               {busy ? <PulseLoader color="#0080ff" loading={true} /> : null}
  //             </Box>
  //           }
  //           next={() => { }}
  //           dataLength={items.length}
  //           scrollableTarget="scrollable-root"
  //           endMessage={
  //             <Alert severity="success" color="info">
  //               You have seen all
  //             </Alert>
  //           }
  //         >
  //           {items.map((item: Post, index: number) => (
  //             <div key={item.id} style={{ padding: "10px 5px" }}>
  //               <LazyLoadComponent
  //                 placeholder={
  //                   <img
  //                     src="assets/images/Plase-holder-listing.png"
  //                     style={{ width: "707px", height: "194px" }}
  //                   ></img>
  //                 }
  //                 key={item.id}
  //               >
  //                 <PostCard
  //                   post={item}
  //                   position={index + 1}
  //                   detailsAction={() => { }}
  //                 ></PostCard>
  //               </LazyLoadComponent>
  //             </div>
  //           ))}
  //         </InfiniteScroll>
  //       ) : (
  //         <Box display="flex" justifyContent="center">
  //           <PulseLoader color="#0080ff" loading={true} />
  //         </Box>
  //       )}
  //     </>
  //   );
  // };

  const handleKeywordSelect = (val: string) => {
    setPage(0);
    if (val) {
      navigate(`/search?phrase=${val}`);
      dispatch(addTittle(val));
      logEvent(analytics, KEYWORD_SEARCH_ALL, {
        action: ACTION_INPUT,
        data: val,
        platform: "DESKTOP",
      });
    }
  };



  return (
    <div ref={topref}>
     
      <Hidden mdDown={true}>
        <div className={styles.tagline}>
          {/* {"Buy & sell anything online in Sri Lanka"} */}
          {lan.BUY_AND_SELL_ANYTHING}
        </div>
      </Hidden>
      <Grid container style={{ padding: "5px 5px", marginTop: "2px", marginBottom: "2px" }}>
        <Grid
          item
          xs={12}
          id="search-tabbar"
          style={{ maxWidth: `${!smallViewPort && "unset"}`, padding: "0px 0px" }}
        >
          <KeywordSearch onSearchEnter={handleKeywordSelect}></KeywordSearch>
        </Grid>
      </Grid>
      <div style={{ height: "10px" }}></div>
      {loadSpotLight()}
      {loadCardView()}




      {items.length > 0 ? (
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px"

            }}
          >
            {busy ? null : (
              <Button
                color="primary"
                id="btn-load-more"
                onClick={loadMorePosts}
                className={styles.btnApply}
                style={{ fontSize: (getLanguage() == 'sinhala' ? "13px" : "16px") }}
              >
                <span>

                  {/* Load More */}
                  {lan.LOAD_MORE}
                </span>
              </Button>
            )}
          </Grid>
        </Grid>
      ) : null}



      {/* 
      {page > 0 ? (
        <div
          style={{
            position: "sticky",
            bottom: "100px",
            zIndex: 999,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            // marginLeft: "340px",
            right: '10px',
            top: '90vh',
            pointerEvents: "none",
          }}
        >
          {showScrollTop ? (
            <Fab
              color="primary"
              aria-label="add"
              onClick={handleScrollToTopRequest}
              style={{ pointerEvents: "all" }}
            >
              <ArrowUpward></ArrowUpward>
            </Fab>
          ) : null}
        </div>
      ) : null} */}

      {/* <ScrollRestore {...props} /> */}

    </div>
  );
};

export default Home;


