import { Avatar, Container, CssBaseline, Typography } from "@material-ui/core";
import { url } from "inspector";
import React from "react";

const TimeOut = () => {

    return (
        <React.Fragment>
            <CssBaseline />
            <Container  style={{  display:'flex',height: '100vh',padding:'50px',width:'100%',backgroundImage: `url(${"../../assets/error/img-404-error.png"})`, backgroundRepeat: "no-repeat" ,backgroundPosition:'center', }} >
                
            </Container>
            
        </React.Fragment>
    );


}

export default TimeOut;