import {
  Avatar,
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVerifiedPhone,
  selectSelfProfile,
} from "../../features/session/sessionSlice";
import { UserContext } from "../../providers/UserProvider";
import PhoneIcon from "@material-ui/icons/Phone";
import "./ProfileSidebarComponent.css";
import * as moment from "moment";
import { signOut } from "../../features/session/firebaseSlice";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import { LOGOUT } from "../../config/events";
import { BEHAVE_LOGIN_REQUIRED } from "../../providers/NavigationProvider";
import { ACTION_CLICK } from "../../config/actions";
import { logEvent } from "firebase/analytics"
import { CustomPhoneValidator } from "../ValidatedPhones/CustomPhoneValidator";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { clearAllPosts, clearLikedPosts } from "../../features/post/postSlice";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    largeavatar: {
      width: "98px",
      height: "98px",
      border: 1,
    },
    changephonebtn: {
      color: "#0080ff",
      borderColor: "#0080ff",
      "& .MuiButton-label": {
        justifyContent: "flex-start",
      },
    },
    disablebtn: {
      color: "#ccc",
      borderColor: "#ccc",
    },
    topbar: {
      marginRight: "21px",
      "& svg": {
        color: "#ff7675",
      },
      "& #logoutbtn": {
        cursor: "pointer",
      },
    },
  })
);

const ProfileSidebarComponent = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const selfPosts = useSelector(selectSelfProfile);
  const [totalViews, setTotalViews] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [phoneVerify, showPhoneVerify] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout, setLogout] = useState(false);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!selfPosts) return;
    calTotalLikes();
    calTotalViews();
    getPhone();
  }, [selfPosts]);

  const getPhone = () => {
    dispatch(
      getVerifiedPhone((phones: any) => {
        setPhone(phones?.phones[0] != "" ? phones.phones[0] : "");
      })
    );
  };

  const calTotalViews = () => {
    setTotalViews(
      selfPosts.posts
        .map((p: any) => p.click_count)
        .reduce((a: any, b: any) => a + b, 0)
    );
  };

  const calTotalLikes = () => {
    setTotalLikes(
      selfPosts.posts
        .map((p: any) => p.like_count)
        .reduce((a: any, b: any) => a + b, 0)
    );
  };

  const handlePhoneVerify = (phone: any) => {
    showPhoneVerify(false);
    if (phone) getPhone();
  };

  const handleLogout = () => {
    dispatch(signOut(onSignOut));
    dispatch(clearAllPosts([]));
    dispatch(clearLikedPosts([]));
  };

  const onSignOut = () => {
    navigate("/home");
    logEvent(analytics, LOGOUT, { action: ACTION_CLICK });
  };
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <UserContext.Consumer>
      {(user: any) =>
        user == null || user?.email === "" ? null : (
          <>
            {/* <div
              style={{
                height: "50px",
                marginTop: "45px",
                borderBottom: "1px solid #DDDDDD",
                background: "#ebeef1",
                borderTop: "2px solid #0080FF",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div className={classes.topbar}>
                <Box
                  id="logoutbtn"
                  display="flex"
                  alignItems="center"
                  onClick={() => setLogout(true)}
                >
                  <img src="assets/icon/icon-log-out.svg"></img>
                  <span
                    style={{
                      marginLeft: 10,
                      color: "#ff7675",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      fontWeight: 600,
                    }}
                  >
                    Log out
                  </span>
                </Box>
              </div>
            </div> */}
            <section id="profile-sidebar-wrapper" style={{ width: (isTabletOrMobile ? "100%" : "300px"), marginTop: "45px", borderTop: "1px solid #0080FF" }}>
              <div>
                <Avatar
                  alt="Profile Image"
                  src={
                    user.image == null || user.image == undefined
                      ? "assets/icon/img-blank-profile-avatar.jpg"
                      : user.image
                  }
                  className={classes.largeavatar}
                ></Avatar>
              </div>
              <div>
                <div id="account-name">{user.name}</div>
                <div id="account-email">{user.email}</div>
                {selfPosts?.joinedDate != 0 ? (
                  <span id="account-joineddate">
                    Since{" "}
                    {selfPosts
                      ? moment
                        .unix(selfPosts?.joinedDate / 1000)
                        .format("MMM YYYY")
                      : "Loading..."}
                  </span>
                ) : null}
              </div>
              <div>
                <div>
                  <span>
                    <div className="account-sidebar-analyt-no">
                      {totalLikes}
                    </div>
                    <div style={{ fontSize: "12px", color: "#5A5A5A" }}>
                      Total Favorites
                    </div>
                  </span>
                  <span></span>
                  <span>
                    <div className="account-sidebar-analyt-no">
                      {totalViews}
                    </div>
                    <div style={{ fontSize: "12px", color: "#5A5A5A" }}>
                      Total Views
                    </div>
                  </span>
                </div>
              </div>
              <div>
                {(selfPosts && selfPosts.skip_phones_verify) ||
                  (selfPosts?.phone === "" && phone === "") ? null : (
                  <>
                    <span id="account-phone-icon-wrapper">
                      <img
                        style={{ width: "100%", height: "100%", padding: 5 }}
                        src="assets/icon/icon-phone-number.svg"
                      />
                    </span>
                    <span id="account-phone-details">
                      <div>{phone ? phone : "Add Your Phone Number"}</div>
                      <div>Phone number</div>
                    </span>
                  </>
                )}
              </div>
              <div>
                <Button
                  variant="outlined"
                  className={
                    selfPosts?.skip_phones_verify
                      ? classes.disablebtn
                      : classes.changephonebtn
                  }
                  style={{ width: "100%", textTransform: "none" }}
                  disabled={selfPosts?.skip_phones_verify}
                  onClick={() => showPhoneVerify(true)}
                >
                  <span>{(phone ? "Change phone number" : "Add phone number")}</span>
                  <img
                    src="assets/icon/icon-arrow-button.svg"
                    style={{
                      position: "absolute",
                      top: 14,
                      right: 20,
                      width: 5,
                      height: 5,
                    }}
                  />
                </Button>
              </div>
              {phoneVerify ? (
                <CustomPhoneValidator
                  show={phoneVerify}
                  onDismiss={(phone: string = "") => handlePhoneVerify(phone)}
                />
              ) : null}
              <Dialog
                open={logout}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={() => setLogout(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Log out</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You are about to log out from Saruwata.lk, Are you sure ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setLogout(false)}
                    color="primary"
                    autoFocus
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleLogout} color="secondary">
                    Log out
                  </Button>
                </DialogActions>
              </Dialog>
            </section>
            <div style={{ height: 50 }}></div>
          </>
        )
      }
    </UserContext.Consumer>
  );
};

export default ProfileSidebarComponent;
